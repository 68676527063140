import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Moment from "react-moment";
import { CloseOutlined } from "@ant-design/icons";
import Select from "react-select";
import "./UserInfoDialog.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import i18n from "../../../../i18n";
import { PatchUserAuthChange } from "../../../../Api/Admin/User/user";
import { withSnackbar } from "notistack";
import { GetUserToken } from "../../../../modules/userAuth";
import ProfilePhoto from "../../../../Component/ProfilePhoto/ProfilePhoto";
class UserInfoDialog extends Component {
  state = {
    menuName: "defalut",
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthSm: {
            minWidth: 250,
            maxWidth: 588,
            width: "100%",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });
    const { open, onClose, userInfo } = this.props;

    const options = [
      { value: "admin", label: i18n.t("admin.filter.account.admin") },
      { value: "user", label: i18n.t("admin.filter.account.member") },
    ];

    const optionIndex = options.findIndex(
      (option) => option.value === userInfo.authority
    );

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted pink',
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "black" : "white",
        // padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        border: "1px solid #efefef",
        width: "100%",
        height: "40px",

        // "&:hover": {
        // display: "flex",
        // border: "1px solid #070707;",
        // width: "100%",
        // height: "40px",
        // },
      }),
      input: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        alignItems: "center",
        visibility: "visible",
        color: "hsl(0, 0%, 20%)",
        boxSizing: "border-box",
      }),
      valueContainer: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        width: "100%",
        height: "40px",
        padding: "0 15px",
      }),
    };

    const handleUserAuthority = async (e) => {
      const authority = e.value;

      const token = GetUserToken();
      // const authLocale =
      //   e.value === "admin"
      //     ? i18n.t("admin.filter.account.admin")
      //     : i18n.t("admin.filter.account.member");

      if (userInfo.authority === authority) {
        // alert(
        //   i18n.t("admin.user.authChangeInvalid", {
        //     authLocale: authLocale,
        //   })
        // );
        this.props.enqueueSnackbar("message blah blah 저장 되었습니다", {
          variant: "success",
          key: "uppoer.accept",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
      confirmAlert({
        title:
          authority === "admin"
            ? i18n.t("admin.users.permitChange")
            : i18n.t("admin.users.permitChange"),
        message:
          authority === "admin"
            ? i18n.t("admin.users.toAdminMsg", {
                email: userInfo.email,
              })
            : i18n.t("admin.users.toMemberMsg", {
                email: userInfo.email,
              }),
        overlayClassName: "comfirm-overlay",
        buttons: [
          {
            label: i18n.t("global.change"),
            onClick: async () => {
              if (!token) {
                this.props.history.push("/admin");
                return;
              }

              try {
                const response = await PatchUserAuthChange(
                  userInfo._id,
                  authority
                );

                if (response.message === "success") {
                  // alert("권한 변경 완료");
                  this.handleAlert("권한 변경 완료");
                }
              } catch (e) {
                console.dir(e);
              }
            },
          },
          {
            label: i18n.t("global.cancel"),
            onClick: () => {
              return;
            },
          },
        ],
      });
    };

    return (
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
          {userInfo ? (
            <div className="UserInfoDialog-container">
              <div className="closeBtnWrap" onClick={onClose}>
                <CloseOutlined />
              </div>
              <div className="content-container">
                <div className="modalTitle">
                  {/* {i18n.t('admin.users.detail.admin')} */}
                </div>
                <div className="AvatarImg">
                  <ProfilePhoto src={userInfo.profilePhoto} size={84.8} />
                </div>
                <div className="formTitle">{i18n.t("login.email")}</div>
                <div className="formContents" id="email">
                  {userInfo.email}
                </div>
                <div className="formTitle">{i18n.t("admin.filter.ID")}</div>
                <div className="formContents" id="id">
                  {userInfo._id}
                </div>
                <div className="formTitle">
                  {i18n.t("indivi.signup.userName")}
                </div>
                <div className="formContents" id="userName">
                  {userInfo.userName}
                </div>
                <div className="formTitle">
                  {i18n.t("admin.filter.authority")}
                </div>
                <div style={{ width: "100%" }}>
                  <Select
                    styles={customStyles}
                    options={options}
                    defaultValue={options[optionIndex]}
                    onChange={handleUserAuthority}
                  />
                </div>
                {/* <select className="formContents" defaultValue ={userInfo.authority} id="userAuthority"></select> */}
                {/* <div className="formTitle">User Type</div>
                                <div className="formContents" id="userType">
                                    {userInfo.userType}
                                </div> */}
                <div className="formTitle">
                  {i18n.t("admin.filter.accountType")}
                </div>
                <div className="formContents" id="account">
                  {userInfo.type}
                </div>
                <div className="formTitle">
                  {i18n.t("admin.filter.Join Date")}
                </div>
                <div className="formContents" id="createdAt">
                  {userInfo.createdAt ? (
                    <Moment format="YYYY/MM/DD HH:MM">
                      {userInfo.createdAt}
                    </Moment>
                  ) : (
                    <div>{i18n.t("global.noData")}</div>
                  )}
                </div>
                <div className="formTitle">
                  {i18n.t("admin.filter.lastLogin")}
                </div>
                <div className="formContents" id="lastLoginDate">
                  {userInfo.lastLoginDate ? (
                    <Moment format="YYYY/MM/DD HH:MM">
                      {userInfo.lastLoginDate}
                    </Moment>
                  ) : (
                    <div>{i18n.t("admin.signin.nodata")}</div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withSnackbar(UserInfoDialog);
