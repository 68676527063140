import React, { Component } from "react";
import "./CropDialog.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "../Button/Button";
import Crop from "react-easy-crop";
import { BottomSheet } from "react-spring-bottom-sheet";
import Slider from "@material-ui/core/Slider";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class CropDialog extends Component {
  state = {
    fullWidth: false,
    crop: {},
    zoom: 1,
  };
  setZoom = (zoom) => {
    this.setState({ zoom });
  };
  componentDidMount = () => {
    if (window.innerWidth < 700) {
      this.setState({ fullWidth: true });
    }
    window.addEventListener("resize", (e) => {
      this.handleResize(e.target.innerWidth);
    });
  };
  handleResize = (width) => {
    if (width < 700) {
      this.setState({ fullWidth: true });
    } else {
      this.setState({ fullWidth: false });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };
  renderDialog = () => {
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthFalse: {
            maxWidth: "450px !important",
            width: "100%",
          },
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });
    const {
      onClose,
      crop,
      open,
      src,
      onCropChange,
      onCropComplete,
      onImageLoaded,
      showCroppedImage,
    } = this.props;
    if (!this.state.fullWidth) {
      return (
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={true}
            className={""}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </div>
            <div className="crop-dilaogContainer">
              <div className="crop-container">
                <Crop
                  image={src}
                  crop={crop}
                  aspect={this.props.ratio}
                  locked={true}
                  onCropChange={onCropChange}
                  onImageLoaded={onImageLoaded}
                  onCropComplete={onCropComplete}
                  zoom={this.state.zoom}
                  onZoomChange={this.setZoom}
                ></Crop>
              </div>
              <div className="crop-slider">
                <Slider
                  value={this.state.zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => this.setZoom(zoom)}
                />
              </div>
              <div className="crop-btns">
                <Button
                  text={i18n.t("global.back")}
                  btnClick={onClose}
                ></Button>
                <Button
                  text={i18n.t("global.complete")}
                  btnClick={() => showCroppedImage(src)}
                  backColor="white"
                ></Button>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      );
    } else {
      return (
        <BottomSheet open={open} onDismiss={onClose}>
          <div className="crop-dilaogContainer">
            <div className="crop-container">
              <Crop
                image={src}
                crop={crop}
                aspect={this.props.ratio}
                locked={true}
                onCropChange={onCropChange}
                onImageLoaded={onImageLoaded}
                onCropComplete={onCropComplete}
                zoom={this.state.zoom}
                onZoomChange={this.setZoom}
              ></Crop>
            </div>
            <div className="crop-slider">
              <Slider
                value={this.state.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.setZoom(zoom)}
              />
            </div>
            <div className="bottomSheet_btns">
              <Button
                backColor="white"
                text={i18n.t("global.back")}
                btnClick={onClose}
              ></Button>
              <Button
                text={i18n.t("global.complete")}
                btnClick={() => showCroppedImage(src)}
                backColor="white"
              ></Button>
            </div>
          </div>
        </BottomSheet>
      );
    }
  };
  render() {
    return <>{this.renderDialog()}</>;
  }
}

export default withStyles(styles)(CropDialog);
