import React, { Component } from "react";
import "./MobileDNDModal.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CheckIcon from "../../image/red-check-icon.svg";
import CloseIcon from "../../Mobile/Project-upload//top_back_black_icon.svg";
import DragIcon from "../../Mobile/change-location-icon.svg";

import i18n from "../../i18n";
import VideoIcon from "../../Mobile/Project-upload/dragIcon/circle_video_icon.svg";
import TextIcon from "../../Mobile/Project-upload/dragIcon/circle_text_icon.svg";
import SoundIcon from "../../Mobile/Project-upload/dragIcon/circle_audio_icon.svg";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class MobileDNDModal extends Component {
  state = {
    content: [],
  };
  // componentDidUpdate = (prevProps) => {
  //   if (this.state.content !== prevProps.dragContent) {
  //     this.setState({ content: prevProps.dragContent });
  //   }
  // };
  componentDidMount = () => {
    this.setState({ content: this.props.dragContent });
  };

  handelRemoveContent = (index) => {
    let content = this.state.content;
    content.splice(index, 1);
    this.setState({ content });
  };
  handleOnDragEnd = (result) => {
    console.log(result, "끝");
    if (!result.destination) return;
    const currnetTag = this.state.content;
    const drggingItemIndex = result.source.index;
    const afterDragItemIndex = result.destination.index;
    const removeTag = currnetTag.splice(drggingItemIndex, 1);
    currnetTag.splice(afterDragItemIndex, 0, removeTag[0]);
    this.setState({ content: currnetTag });
  };
  renderText = (html) => {
    if (html && html !== "<p><br></p>") {
      let text = html.replace(/(<([^>]+)>)/gi, "");
      if (text.length > 20) {
        text = text.substring(0, 20) + "...";
        return text;
      } else {
        return text;
      }
    } else {
      return "여기에 텍스트를 입력해주세요";
    }
  };
  render() {
    const { onClose, open } = this.props;

    return (
      <DragDropContext onDragEnd={this.handleOnDragEnd}>
        <Droppable droppableId="tags">
          {(provided) => (
            <Dialog
              fullWidth={this.props.fullWidth}
              className={"dndDialog-container"}
              maxWidth={"sm"}
              open={open}
              onClose={onClose}
            >
              <div className="one-button-dialog-container dndDialog">
                <div className="dndDialog-box">
                  <div className="dndDialog-title">
                    <img src={CloseIcon} alt="" onClick={onClose} />
                    <span>{i18n.t("project.upload.dndTitle")}</span>
                    <img
                      src={CheckIcon}
                      alt="saveBtn"
                      onClick={() =>
                        this.props.handleContentSet(this.state.content)
                      }
                    />
                  </div>
                  <div
                    className="content-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {this.state.content[0]
                      ? this.state.content.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={`${item.id || item._id}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="dragContentBox"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div className="remove_content">
                                    <img
                                      src={closeIcon}
                                      alt="contentRemove"
                                      onClick={() =>
                                        this.handelRemoveContent(index)
                                      }
                                    />
                                  </div>

                                  {item.type === "image" ? (
                                    <>
                                      <div className="dragContent">
                                        <div
                                          style={{
                                            backgroundImage: `url(${
                                              item.item.file || item.item.data
                                            })`,
                                          }}
                                        ></div>
                                      </div>
                                      <span>
                                        {item.data.name
                                          ? item.data.name.length > 20
                                            ? item.data.name.substr(0, 20) +
                                              "..."
                                            : item.data.name
                                          : item.type}
                                      </span>
                                    </>
                                  ) : item.type === "audio" ? (
                                    <>
                                      <div className="dragContent">
                                        <img src={SoundIcon} alt="projectImg" />
                                      </div>
                                      <span>
                                        {item.data.name
                                          ? item.data.name.length > 20
                                            ? item.data.name.substr(0, 20) +
                                              "..."
                                            : item.data.name
                                          : item.type}
                                      </span>
                                    </>
                                  ) : item.type === "video" ? (
                                    <>
                                      <div className="dragContent">
                                        <img src={VideoIcon} alt="projectImg" />
                                      </div>
                                      <span>{item.type}</span>
                                    </>
                                  ) : (
                                    <>
                                      <div className="dragContent">
                                        <img src={TextIcon} alt="projectImg" />
                                      </div>
                                      <span>
                                        {this.renderText(
                                          item.item.text || item.item.file
                                        )}
                                      </span>
                                      {/* <div
                                            className="textBox ql-editor"
                                            // dangerouslySetInnerHTML={{
                                            //   __html:
                                            //     item.item.file ||
                                            //     item.item.text,
                                            // }}
                                          ></div> */}
                                    </>
                                  )}
                                  <img
                                    {...provided.dragHandleProps}
                                    className="DragIcon"
                                    src={DragIcon}
                                    alt="DragIcon"
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                    {provided.placeholder}
                  </div>

                  {/* <div className="dndDialog-btns">
                        <button onClick={onClose}>
                          {i18n.t("global.cancel")}
                        </button>
                        <button
                          onClick={() =>
                            this.props.handleContentSet(this.state.content)
                          }
                        >
                          {i18n.t("global.submit")}
                        </button>
                      </div> */}
                </div>
              </div>
            </Dialog>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default withStyles(styles)(MobileDNDModal);
