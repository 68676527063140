import React, { Component } from "react";
import "./ProjectDialog.scss";
import MobileSelector from "../MobileSelector/MobileSeletor";
import { NavLink } from "react-router-dom";
import { ClickAwayListener, Dialog } from "@material-ui/core";
import ArrowIcon from "../../image/line-black-arrow-up-icon.svg";
import { connect } from "react-redux";
import EditIcon from "../../image/job/edit-profile-icon.svg";
import likeIcon from "../../image/like-icon.svg";
import viewsIcon from "../../image/view-icon.svg";
import CollectionDialog from "../CollectionDialog/CollectionDialog";
import "moment/locale/ko";
import CommentDelDialog from "../DelConfirmDialog/DelConfirmDialog";
import Media from "react-media";
import LocationIcon from "../../image/location-icon.svg";
import CommentIcon from "../../image/stateIcon/small-comments-icon.svg";
import donwArrow from "../../image/down-enter-comments-icon.svg";
import ReportDialog from "../ReportDialog/ReportDialog";
import RightArrowIcon from "../../image/ProjectDialog/r-white-line-arrow.svg";
import LeftArrowIcon from "../../image/ProjectDialog/l-white-line-arrow.svg";
import OffCommentEditIcon from "../../image/ProjectDialog/gray-upload-modify-icon.svg";
import CommentDelIcon from "../../image/ProjectDialog/upload-delete-icon.svg";
import CommentReportIcon from "../../image/ProjectDialog/upload-report-icon.svg";
import ReportIcon from "../../image/stateIcon/report-icon.svg";
import OnLikeBtn from "../../image/OnIcon/white-like-button-icon.svg";
import RedLikeBtn from "../../image/red-like-icon.svg";
import OffLikeBtn from "../../image/OffIcon/like-button-icon.svg";
import OffAddBtn from "../../image/OffIcon/add-button-icon.svg";
import CommentEnter from "../../image/enter-comments-icon.svg";
import shareBtn from "../../image/job/share-black-icon.svg";
import BY_icon from "../../image/license/BY_license.svg";
import CC_icon from "../../image/license/CC_license.svg";
import NC_icon from "../../image/license/NC_license.svg";
import ND_icon from "../../image/license/ND_license.svg";
import SA_icon from "../../image/license/SA_license.svg";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
import i18n from "../../i18n";
import { Tooltip } from "antd";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import {
  searchContent,
  projectEdit,
  langChange,
} from "../../Redux/action/index";
import M_EditIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_1.svg";
import M_PageIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_2.svg";
import M_ShareIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_3.svg";
import M_LikeIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_4.svg";
import M_AddIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_5.svg";
import M_ReportIcon from "../../Mobile/Proejct-dialog/popup_menu_icon_6.svg";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Spin } from "antd";
import Header from "../MobileHeader/MobileHeader";
import { LoadingOutlined } from "@ant-design/icons";
import { GetMyProjectList, ViewCountCheck } from "../../Api/Project/project";
import { PostReport } from "../../Api/Report/Report";
import { withSnackbar } from "notistack";
import {
  CreateComment,
  DeleteCommnet,
  GetCommentList,
  PatchComment,
} from "../../Api/Comment/Comment";
import ShareDialog from "../../Component/ShareDialog/ShareDialog";
import { SetLike, SetUnLike } from "../../Api/Like/Like";
import { GetUserInfo } from "../../Api/User/user";
import Flickity from "react-flickity-component";
import Moment from "react-moment";
import loadable from "@loadable/component";
import { nFormatter } from "../../Component/functions";
import { compose } from "redux";
import { GetUserToken } from "../../modules/userAuth";
import ImgsViewer from "react-images-viewer";
import { ProjectDetail_GAEvent } from "../../modules/gaEvents";
const Project = loadable(() => import("../../Component/Project/Project"));

class ProjectDialog extends Component {
  state = {
    shareDialogOpen: false,
    isNewProjLoading: false,
    isPause: false,
    tickerSpeed: 0.5,
    slideshowEl: document.querySelector(".js-slideshow"),
    isCateClicked: false,
    render: false,
    userInfo: "",
    toPage: false,
    isLoading: false,
    isLike: false,
    likeMembers: 0,
    mobileSelecOpen: false,
    collectionDialogOpen: false,
    commentMenuOpen: false,
    reportDialogOepn: false,
    ownerListOpen: false,
    flickityOptions: {
      prevNextButtons: true,
      pageDots: false,
      draggable: ">1",
      selectedAttraction: 0.015,
      friction: 0.25,
      initialIndex: 0,
      cellAlign: "left",
      contain: true,
      groupCells: true,
    },
    fullWidth: false,
    imgOpen: false,
    license: [],
    images: [],
    currImg: 0,
    selfList: [
      {
        id: 1,
        text: i18n.t("project.selector.edit"),
        icon: M_EditIcon,
      },
      {
        id: 2,
        text: i18n.t("project.selector.share"),
        icon: M_ShareIcon,
      },
    ],
    otherList: [
      {
        id: 1,
        text: i18n.t("project.selector.share"),
        icon: M_ShareIcon,
      },
      {
        id: 2,
        text: i18n.t("콜렉션에 추가"),
        icon: M_AddIcon,
      },
      {
        id: 3,
        text: i18n.t("project.selector.report"),
        icon: M_ReportIcon,
      },
    ],
    commentInput: false,
    modifyComment: "",
    commentSelec: "",
    isLogined: false,
    commentMenu: [
      {
        id: 1,
        icon: CommentDelIcon,
      },
      {
        id: 2,
        icon: OffCommentEditIcon,
      },
      {
        id: 3,
        icon: CommentReportIcon,
      },
    ],
    selectedDelete: "",
    commentDelDialogOpen: false,
    reportSelected: "",
    orderSelecList: [
      {
        id: 1,
        text: i18n.t("project.page"),
        icon: M_PageIcon,
      },
      {
        id: 2,
        text: i18n.t("project.like"),
        icon: M_LikeIcon,
        //this.props.projInfo.isLike ? RedLikeBtn : M_LikeIcon,
      },
      {
        id: 3,
        text: "Add",
        icon: M_AddIcon,
      },
    ],
    commentModify: "",
    userProjectList: [],
    projCommentList: [],
    more: true,
    items: 0,
    commentCount: 0,
    commentIndex: "",
    commentLoading: false,
  };
  timer; //view 카운트 타이머

  isLoginedCheck = () => {
    const token = GetUserToken();
    if (token) {
      this.setState({ isLogined: true });
    } else {
      this.setState({ isLogined: false });
    }
  };

  LoadComment = async () => {
    const loadLimit = 10;
    let items = this.state.projCommentList.length;
    try {
      const response = await GetCommentList(
        this.props.projInfo._id,
        items,
        loadLimit
      );
      let projCommentList = this.state.projCommentList;
      let moreComment = projCommentList.concat(response);
      this.setState({ projCommentList: moreComment }, () => {
        if (response && response.length < 9) {
          this.setState({ more: false });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleCommentEnter = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.setState({ commentLoading: true }, () => {
        this.handleCommentEnterClicked();
      });
    }
  };

  handleCommentEnterClicked = async () => {
    const commentField = document.getElementById("commentInputBox");
    const commentTagRegex = /[<][^>]*[>]/gi;
    this.setState({ commentLoading: true });
    if (commentField.value) {
      if (!commentTagRegex.test(commentField.value)) {
        if (commentField.value.length <= 800) {
          try {
            await CreateComment(this.props.projInfo._id, commentField.value);
            commentField.value = "";
            ProjectDetail_GAEvent("comment");
            let commentCount = this.state.commentCount + 1;
            await this.LoadComment();
            this.setState({
              commentCount,
              commentInput: false,
              commentLoading: false,
            });
          } catch (e) {
            console.dir(e);
          }
        } else {
          this.handleAlert("최대 800자 까지 입력 가능합니다");
        }
      } else {
        this.handleAlert("댓글에 HTML 태그형식은 작성이 불가합니다");
      }
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleDeleteComment = async () => {
    try {
      await DeleteCommnet(this.state.selectedDelete);
      this.LoadComment();
      let commentCount = this.state.commentCount - 1;
      this.setState({
        commentCount,
        selectedDelete: "",
        commentDelDialogOpen: false,
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleCollectioDialogOpen = () => {
    const token = GetUserToken();

    if (token) {
      this.setState({
        collectionDialogOpen: true,
      });
      ProjectDetail_GAEvent("clickColl");
    } else {
      // alert("로그인 후 이용 가능한 서비스 입니다.");
      this.handleAlert("로그인 후 이용 가능한 서비스 입니다.");
    }
  };
  handleCollectioDialogClose = () => {
    this.setState({
      collectionDialogOpen: false,
    });
  };

  handleSearchTag = (tag) => {
    window.scrollTo(0, 0); // 스크롤 포지션 초기화
    if (this.props.history.location.pathname !== "/discover") {
      this.props.searchContent(tag);
      this.props.history.push("/discover");
    } else {
      this.props.searchContent(tag);
      this.props.onClose();
    }
  };

  handleSearchCategory = (category) => {
    window.scrollTo(0, 0);
    if (this.props.history.location.pathname !== "/discover") {
      this.props.history.push(`/discover?c=${category}`);
    } else {
      this.props.history.push(`/discover?c=${category}`);
      this.props.onClose();
    }
  };
  handleEditProject = () => {
    const token = GetUserToken();
    if (token) {
      this.props.projectEdit(this.props.projInfo);
      this.props.history.push("/project-upload?mode=Edit");
    }
  };

  handleLike = async (id) => {
    const token = GetUserToken();

    if (token) {
      this.setState({ isLike: !this.state.isLike }, () =>
        this.setState({
          likeMembers: this.state.isLike
            ? this.state.likeMembers + 1
            : this.state.likeMembers - 1,
        })
      );
      this.setState({ isLoading: true });
      if (this.state.isLike) {
        try {
          await SetUnLike(id);
          ProjectDetail_GAEvent("like");
          this.setState({ isLoading: false }, () =>
            this.setState({ isLike: false })
          );
        } catch (e) {
          console.dir(e);
          this.setState({ isLike: true, isLoading: false });
        }
      } else if (!this.state.isLike) {
        try {
          await SetLike(id);
          ProjectDetail_GAEvent("unlike");
          this.setState({ isLoading: false }, () =>
            this.setState({ isLike: true })
          );
        } catch (e) {
          this.setState({ isLike: false, isLoading: false });
          console.dir(e);
        }
      }
    } else {
      // alert("로그인 후 이용 가능한 서비스 입니다.");
      this.handleAlert("로그인 후 이용 가능한 서비스 입니다.");
    }
  };
  licenseSplit = () => {
    let license = this.props.projInfo.license;
    var re = /\s*(?:;| |-)\s*/;
    if (license !== "All Rights Reserved") {
      license = license.split(re);

      this.setState({ license });
    } else {
      this.setState({ license });
    }
  };
  onClickCommentAway = (e) => {
    if (this.clickedComment) {
      if (this.clickedComment.contains(e.target)) {
        this.setState({ commentInput: true });
      } else {
        this.setState({ commentInput: false });
      }
    }
  };
  handleResize = (width) => {
    if (width < 1025) {
      this.setState({ fullWidth: true });
    } else {
      this.setState({ fullWidth: false });
    }
  };
  componentDidMount = () => {
    window.addEventListener("click", this.onClickCommentAway, true);
    if (window.innerWidth < 1025) {
      this.setState({ fullScreen: true });
    }
    window.addEventListener("resize", (e) => {
      this.handleResize(e.target.innerWidth);
    });
    this.setState(
      {
        isLike: this.props.projInfo.isLike ? this.props.projInfo.isLike : false,
        likeMembers: this.props.projInfo.likeMembers,
      },
      () => {
        this.isLoginedCheck();
        this.ownersProjCheck();
        this.LoadComment();
        this.handleUserInfoLoad();
      }
    );
    this.getImages();
    if (!this.props.admin) {
      this.timer = setInterval(this.setTime, 1000);
      this.props.timerSet(this.timer);
    }
  };
  getImages = () => {
    let images = [];
    this.props.projInfo.contents
      .filter((item) => item.type === "image")
      .map((val) => images.push({ src: val.item.data }));

    this.setState({ images });
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("click", this.onClickCommentAway, false);
  };
  handleKeyDownEvent = (e) => {
    if (e.key === "ArrowLeft" && this.props.prevDataCheck && !e.isComposing) {
      this.props.toPrevBtn(this.props.projInfo._id);
    } else if (
      e.key === "ArrowRight" &&
      this.props.nextDataCheck &&
      !e.isComposing
    ) {
      this.props.toNextBtn(this.props.projInfo._id);
    } else return;
  };
  handleClearTimer = () => {
    clearInterval(this.timer);
  };
  Date = new Date().getSeconds();
  setTime = () => {
    let startDate = new Date().getSeconds();
    const endDate = this.Date + 3;
    if (startDate === endDate) {
      this.viewCount();
    }
  };
  loadProjectInfo = (projectId) => {
    const container = document.getElementById("project-dialog-container");
    container.scrollTo(0, 0);

    this.props.loadProjectInfo(projectId, true);
  };
  viewCount = async () => {
    try {
      await ViewCountCheck(this.props.projInfo._id);
      ProjectDetail_GAEvent("stay");
      this.handleClearTimer();
    } catch (e) {
      this.handleClearTimer();
      console.log(e);
    }
  };
  renderLicense = (license) => {
    if (license === "CC BY") {
      return (
        <Tooltip
          placement="top"
          color="white"
          overlayClassName="featureTooltipContainer"
          title={
            <>
              <div className="flagTooltipFeature">CC BY(저작자표시)</div>
            </>
          }
        >
          <img src={CC_icon} alt="license_icon" />
          <img src={BY_icon} alt="license_icon" />
        </Tooltip>
      );
    } else if (license === "CC BY-SA") {
      return (
        <Tooltip
          placement="top"
          color="white"
          overlayClassName="featureTooltipContainer"
          title={
            <>
              <div className="flagTooltipFeature">
                CC BY-SA(저작자표시-동일조건변경허락)
              </div>
            </>
          }
        >
          <img src={CC_icon} alt="license_icon" />
          <img src={BY_icon} alt="license_icon" />
          <img src={SA_icon} alt="license_icon" />
        </Tooltip>
      );
    } else if (license === "CC BY-NC") {
      return (
        <Tooltip
          placement="top"
          color="white"
          overlayClassName="featureTooltipContainer"
          title={
            <>
              <div className="flagTooltipFeature">
                CC BY-NC(저작자표시-비열리)
              </div>
            </>
          }
        >
          <img src={CC_icon} alt="license_icon" />
          <img src={BY_icon} alt="license_icon" />
          <img src={NC_icon} alt="license_icon" />
        </Tooltip>
      );
    } else if (license === "CC BY-NC-SA") {
      return (
        <Tooltip
          placement="top"
          color="white"
          overlayClassName="featureTooltipContainer"
          title={
            <>
              <div className="flagTooltipFeature">
                CC BY-NC-SA(저작자표시-비영리동일조건-변경허락)
              </div>
            </>
          }
        >
          <img src={CC_icon} alt="license_icon" />
          <img src={BY_icon} alt="license_icon" />
          <img src={NC_icon} alt="license_icon" />
          <img src={SA_icon} alt="license_icon" />
        </Tooltip>
      );
    } else if (license === "CC BY-NC-ND") {
      return (
        <Tooltip
          placement="top"
          color="white"
          overlayClassName="featureTooltipContainer"
          title={
            <>
              <div className="flagTooltipFeature">
                CC BY-NC-SA(저작자표시-비영리-변경금지)
              </div>
            </>
          }
        >
          <img src={CC_icon} alt="license_icon" />
          <img src={BY_icon} alt="license_icon" />
          <img src={NC_icon} alt="license_icon" />
          <img src={ND_icon} alt="license_icon" />
        </Tooltip>
      );
    }
  };
  handleReportDialogOepn = (reportInfo) => {
    const token = GetUserToken();

    if (token) {
      this.setState({ reportSelected: reportInfo, reportDialogOepn: true });
    } else {
      this.handleAlert("로그인이 필요한 서비스입니다");
    }
  };
  handleReportDialogClose = () => {
    this.setState({ reportDialogOepn: false });
  };
  handleReportComment = async (reportText) => {
    try {
      await PostReport(this.state.reportSelected, reportText);

      this.handleReportDialogClose();
    } catch (e) {
      console.dir(e);
    }
  };

  handleCommentModify = async () => {
    try {
      await PatchComment(this.state.commentSelec, this.state.modifyComment);
      this.setState({ commentSelec: "", modifyComment: "" }, () =>
        this.LoadComment()
      );
    } catch (e) {
      console.dir(e);
    }
  };
  toOther = async (id) => {
    try {
      const response = await GetUserInfo(id);

      this.props.history.push(`/@${response.linkURL}`);
      window.location.reload();
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert(i18n.t("global.error.server.msg"));
      console.log(e);
    }
  };
  handleCommentSelec = (commentId) => {
    this.setState({ commentSelec: commentId });
  };
  handleModifyCommentEnter = (e) => {
    if (e.keyCode === 13)
      if (!e.shiftKey && e.target.value) {
        e.preventDefault();
        this.handleCommentChage();
      }
  };
  handleCommentChage = () => {
    const input = document.getElementById("comment_modify-inputField");
    if (input.value) {
      this.setState({ modifyComment: input.value }, () =>
        this.handleCommentModify()
      );
    }
  };
  handleDeleteDialogOpen = (commentId) => {
    this.setState({ commentDelDialogOpen: true, selectedDelete: commentId });
  };
  handleMenuSelec = (commentInfo, menu) => {
    switch (menu) {
      case 1:
        this.handleDeleteDialogOpen(commentInfo._id);
        break;
      case 2:
        this.handleCommentSelec(commentInfo._id);
        break;
      case 3:
        this.handleReportDialogOepn(commentInfo._id);
        break;
      default:
        break;
    }
  };
  ownersProjCheck = () => {
    if (this.props.projInfo.creators.length !== 0) {
      for (let i = 0; i < this.props.projInfo.creators.length; i++) {
        if (
          this.props.projInfo.creators[i]._id === this.props.userId ||
          this.props.projInfo.uploaderInfo._id === this.props.userId
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (this.props.projInfo.uploaderInfo._id === this.props.userId) {
        return true;
      } else return false;
    }
  };
  commentMenuRender = (commentInfo, userId) => {
    return (
      <div className="comment-menuList">
        {this.state.commentMenu
          .filter(
            (item) =>
              (this.props.userId === userId && item.id === 3) ||
              (this.props.userId !== userId && item.id === 2)
          )
          .map((item) => {
            // if (this.props.userId === userId && item.id === 3) {
            //   return;
            // } else if (
            //   !this.ownersProjCheck() &&
            //   this.props.userId !== userId &&
            //   item.id === 1
            // ) {
            //   return;
            // } else if (this.props.userId !== userId && item.id === 2) {
            //   return;
            // } else {
            // }
            return (
              <div
                className="comment-menu"
                onClick={() => this.handleMenuSelec(commentInfo, item.id)}
              >
                <img src={item.icon} alt="icon" />
              </div>
            );
          })}
      </div>
    );
  };
  handleOwnerListOpen = () => {
    this.setState({ ownerListOpen: true });
  };
  userProjectListLoad = async () => {
    try {
      const response = await GetMyProjectList(
        this.props.projInfo.uploaderInfo._id
      );

      const userProjectList = response.filter(
        (item) => item._id !== this.props.projInfo._id
      );

      this.setState({ userProjectList, render: true });
    } catch (e) {
      console.dir(e);
    }
  };

  handleUserInfoLoad = async () => {
    try {
      const response = await GetUserInfo(this.props.projInfo.uploaderInfo._id);
      this.setState({ userInfo: response }, () => {
        this.userProjectListLoad();
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleMobileSelecClose = () => {
    this.setState({ mobileSelecOpen: false });
  };

  renderOwnerList = (owners) => {
    return (
      <>
        <div className="ownerInfo-profile">
          <NavLink
            to={{ pathname: `/@${this.props.projInfo.uploaderInfo.linkURL}` }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ProfilePhoto
                src={this.props.projInfo.uploaderInfo.profilePhoto}
                size={40}
              />
              <div className="owner-info">
                <span>
                  {!this.props.projInfo.uploaderInfo.userName
                    ? null
                    : this.props.projInfo.uploaderInfo.userName}
                </span>
                <div className="profile-location">
                  <img src={LocationIcon} alt="location" />
                  <span>
                    {this.props.projInfo.uploaderInfo.countryInfo
                      ? this.props.projInfo.uploaderInfo.countryInfo.countryName
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>
          <div className="profile-title">
            {this.props.projInfo.uploaderInfo._id ===
            this.props.userId ? null : (
              <button
                onClick={
                  this.props.projInfo.uploaderInfo.isFollowing
                    ? () =>
                        this.props.handleUnFollow(
                          this.props.projInfo.uploaderInfo._id
                        )
                    : () =>
                        this.props.handleFollow(
                          this.props.projInfo.uploaderInfo._id
                        )
                }
                className={
                  this.props.projInfo.uploaderInfo.isFollowing
                    ? "following-sm"
                    : "follow-sm"
                }
              >
                {this.props.projInfo.uploaderInfo.isFollowing
                  ? i18n.t("mypage.profile.following")
                  : i18n.t("mypage.profile.follow")}
              </button>
            )}
          </div>
        </div>
        {owners.map((item, index) => {
          return (
            <div className="ownerInfo-profile">
              <NavLink to={{ pathname: `/@${item.linkURL}` }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProfilePhoto src={item.profilePhoto} size={40} />
                  <div className="owner-info">
                    <span>{!item.userName ? null : item.userName}</span>
                    <div className="profile-location">
                      <img src={LocationIcon} alt="location" />
                      <span>
                        {item.countryInfo ? item.countryInfo.countryName : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </NavLink>
              <div className="profile-title">
                {this.props.projInfo.uploaderInfo._id ===
                this.props.userId ? null : (
                  <button
                    onClick={
                      item.isFollowing
                        ? () => this.props.handleUnFollow(item._id, true, index)
                        : () => this.props.handleFollow(item._id, true, index)
                    }
                    className={item.isFollowing ? "following-sm" : "follow-sm"}
                  >
                    {item.isFollowing
                      ? i18n.t("mypage.profile.following")
                      : i18n.t("mypage.profile.follow")}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };
  handleShareDialogOpen = () => {
    this.setState({ shareDialogOpen: true });
  };
  handleShareDialogClose = () => {
    this.setState({ shareDialogOpen: false });
  };
  // props.handleUnFollow = async (id) => {
  //   try {
  //     this.isFollowCheck(id, false);
  //     //this.isAllFollowCheck();
  //     this.setState({ isFollowLoading: false });
  //     if (!this.state.isCreators) {
  //       this.setState({ isFollow: false });
  //     }
  //     await SetUnFollow(id);
  //   } catch (e) {
  //     console.dir(e);
  //     this.setState({ isFollowLoading: false });
  //   }
  // };

  handleGoToUserPage = () => {
    this.props.history.push(`/@${this.state.userInfo.linkURL}`);
  };
  toLogin = () => {
    this.props.history.push(`/login`);
  };

  handleSelec = (id) => {
    if (this.props.projInfo.uploaderInfo._id === this.props.userId) {
      switch (id) {
        case 1:
          this.handleEditProject();
          this.handleMobileSelecClose();
          break;
        case 2:
          // alert("준비중인 기능입니다");
          this.handleShareDialogOpen();
          this.handleMobileSelecClose();
          break;
        default:
        // do nothing
      }
    } else {
      const orderSelecList = this.state.orderSelecList;
      if (id === 2) {
        orderSelecList[id - 1].icon = this.state.isLike
          ? RedLikeBtn
          : M_LikeIcon;
        this.setState({ orderSelecList });
      }
      switch (id) {
        case 1:
          this.handleShareDialogOpen();
          this.handleMobileSelecClose();
          break;
        case 2:
          this.handleCollectioDialogOpen();
          this.handleMobileSelecClose();
          break;
        case 3:
          // alert("준비중인 기능입니다");
          this.handleReportDialogOepn(this.props.projInfo._id);
          this.handleMobileSelecClose();
          break;
        default:
        // do nothing
      }
    }
  };
  renderCreators = (creators) => {
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ ownerListOpen: false })}
      >
        <div className={`creators-listBox`}>
          <NavLink
            to={{
              pathname: `/@${this.props.projInfo.uploaderInfo.linkURL}`,
            }}
          >
            <div className="creators-userInfo">
              <ProfilePhoto
                src={this.props.projInfo.uploaderInfo.profilePhoto}
                size={16}
              />
              <span>{this.props.projInfo.uploaderInfo.userName}</span>
            </div>
          </NavLink>
          {creators.map((item) => {
            return (
              <NavLink
                to={{
                  pathname: `/@${item.linkURL}`,
                }}
              >
                <div
                  className="creators-userInfo"
                  onClick={() => this.toOther(item._id)}
                >
                  <ProfilePhoto src={item.profilePhoto} size={16} />
                  <span>{item.userName}</span>
                </div>
              </NavLink>
            );
          })}
        </div>
      </ClickAwayListener>
    );
  };
  handleFollowSet = () => {
    if (this.props.isFollowLoading) {
      return;
    } else if (this.props.isCreators) {
      if (this.props.isFollow) {
        this.props.handleAllUnFollow();
      } else {
        this.props.handleAllFollow();
      }
    } else {
      if (this.props.projInfo.uploaderInfo.isFollowing) {
        this.props.handleUnFollow(this.props.projInfo.uploaderInfo._id);
      } else {
        this.props.handleFollow(this.props.projInfo.uploaderInfo._id);
      }
    }
  };
  handleImgOpen = (src) => {
    const images = this.state.images;

    let index = images.findIndex((item) => item.src === src);

    this.setState({ currImg: index, imgOpen: true });
  };
  handleNext = () => {
    this.setState({ currImg: this.state.currImg + 1 });
  };
  handlePrev = () => {
    this.setState({ currImg: this.state.currImg - 1 });
  };
  render() {
    const { HeaderOnClose, onClose, open, projInfo } = this.props;
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthXl: {
            minWidth: 250,
            width: "80vw",
            maxWidth: 1500,
            height: "100%",
            zIndex: 9999,
          },
          container: {
            // height: "unset",
          },
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });
    console.log(this.props.history);
    return (
      <Spin
        indicator={antIcon}
        spinning={this.state.isNewProjLoading}
        delay={500}
      >
        <ShareDialog
          fullWidth={this.state.fullWidth}
          open={this.state.shareDialogOpen}
          onClose={this.handleShareDialogClose}
          projLink={`projectId=${projInfo._id}`}
        />
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            mobileTablet: "(max-width: 1024px)",
            tablet: "(min-width: 800px) and (max-width: 1024px)",
            desktop: "(min-width: 1025px)",
          }}
        >
          {(matches) => (
            <ThemeProvider theme={theme}>
              <Dialog
                disableEnforceFocus
                // id="project_dialog_container"
                id={"dialog_container"}
                className={
                  this.props.isPage ? "project project_page" : "project"
                }
                fullWidth={this.props.fullWidth}
                maxWidth={"xl"}
                open={open}
                onClose={onClose}
              >
                {this.state.images[0] && this.state.images.length && (
                  <ImgsViewer
                    // srcSet={this.state.selectedImg}
                    currImg={this.state.currImg}
                    onClickNext={this.handleNext}
                    onClickPrev={this.handlePrev}
                    imgs={this.state.images}
                    backdropCloseable={true}
                    onClose={() =>
                      this.setState({
                        currImg: 0,
                        selectedImg: null,
                        imgOpen: false,
                      })
                    }
                    imgCountSeparator="/"
                    isOpen={this.state.imgOpen}
                  />
                )}
                <CommentDelDialog
                  open={this.state.commentDelDialogOpen}
                  onClose={() => this.setState({ commentDelDialogOpen: false })}
                  dialogHeader={i18n.t("comment.delete.header")}
                  dialogText={i18n.t("comment.delete.text")}
                  buttonClick={this.handleDeleteComment}
                />
                {this.state.collectionDialogOpen ? (
                  <CollectionDialog
                    open={this.state.collectionDialogOpen}
                    onClose={this.handleCollectioDialogClose}
                    thumbnail={projInfo.thumbnail}
                    creatorInfo={projInfo.creators}
                    projId={projInfo._id}
                  />
                ) : null}
                <ReportDialog
                  open={this.state.reportDialogOepn}
                  onClose={this.handleReportDialogClose}
                  reportId={this.state.reportSelected}
                  handleReportComment={this.handleReportComment}
                />
                <MobileSelector
                  open={this.state.mobileSelecOpen}
                  onClose={this.handleMobileSelecClose}
                  selecList={
                    this.ownersProjCheck()
                      ? this.state.selfList
                      : this.state.otherList
                  }
                  handleSelec={this.handleSelec}
                ></MobileSelector>
                {/* {matches.tablet ? (
                  <div
                    id="project_dialog-close"
                    className="closeBtn"
                    onClick={onClose}
                  >
                    <img src={closeImg} alt="close" />
                  </div>
                ) : null} */}

                <div
                  className="one-button-dialog-container proj"
                  id="project-dialog-container"
                >
                  {matches.mobileTablet && (
                    <Header
                      onClose={!this.props.isPage ? onClose : HeaderOnClose}
                      //isLogined={this.props.isLogined}
                      //history={this.props.history}
                      isBottomSheet={true}
                      selectOpen={() =>
                        this.setState({
                          mobileSelecOpen: !this.state.mobileSelecOpen,
                        })
                      }
                      location={this.props.history.location.pathname}
                      //dialog={true}
                    ></Header>
                  )}
                  {!matches.mobile && (
                    <>
                      {this.props.prevDataCheck ? (
                        <div
                          className="left-arrowBtn"
                          onClick={() => this.props.toPrevBtn(projInfo._id)}
                        >
                          <img
                            src={LeftArrowIcon}
                            draggable={false}
                            alt="prevButton"
                          />
                        </div>
                      ) : null}
                      {this.props.nextDataCheck ? (
                        <div
                          className="right-arrowBtn"
                          onClick={() => this.props.toNextBtn(projInfo._id)}
                        >
                          <img
                            src={RightArrowIcon}
                            draggable={false}
                            alt="nextButton"
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                  {this.ownersProjCheck() ? null : (
                    <div
                      className="actionBtn"
                      style={{ dislay: "flex", alignItems: "center" }}
                    >
                      <div
                        className={this.state.toPage ? "checkedBtn" : null}
                        style={{ position: "relative" }}
                      >
                        <>
                          <ProfilePhoto
                            border={true}
                            size={45}
                            src={projInfo.uploaderInfo.profilePhoto}
                          />
                          <Tooltip
                            placement="bottom"
                            color="white"
                            overlayClassName="featureTooltipContainer"
                            title={
                              <>
                                <div className="flagTooltipFeature">
                                  {this.props.isCreators
                                    ? "모두 팔로우"
                                    : "팔로우"}
                                </div>
                              </>
                            }
                          >
                            <div
                              onClick={this.handleFollowSet}
                              style={
                                this.props.isCreators
                                  ? !this.props.isFollow
                                    ? {
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "0",
                                        backgroundColor: "#ff2e43",
                                        width: "15px",
                                        height: "15px",
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }
                                    : {
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "0",
                                        backgroundColor: "white",
                                        width: "15px",
                                        height: "15px",
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }
                                  : !this.props.projInfo.uploaderInfo
                                      .isFollowing
                                  ? {
                                      position: "absolute",
                                      bottom: "0px",
                                      right: "0",
                                      backgroundColor: "#ff2e43",
                                      width: "15px",
                                      height: "15px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                                  : {
                                      position: "absolute",
                                      bottom: "0px",
                                      right: "0",
                                      backgroundColor: "white",
                                      width: "15px",
                                      height: "15px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                              }
                            >
                              {this.props.isCreators ? (
                                this.props.isFollow ? (
                                  <CheckOutlined
                                    style={{ fontSize: "8px", color: "black" }}
                                  />
                                ) : (
                                  <PlusOutlined
                                    style={{ fontSize: "10px", color: "white" }}
                                  />
                                )
                              ) : this.props.projInfo.uploaderInfo
                                  .isFollowing ? (
                                <CheckOutlined
                                  style={{ fontSize: "8px", color: "black" }}
                                />
                              ) : (
                                <PlusOutlined
                                  style={{ fontSize: "10px", color: "white" }}
                                />
                              )}
                            </div>
                          </Tooltip>
                        </>
                      </div>
                      {/* ) : null} */}
                      <span className="avatarTitle">
                        {this.props.isFollow
                          ? i18n.t("mypage.profile.following")
                          : i18n.t("mypage.profile.follow")}
                      </span>

                      <div
                        className={this.state.isLike ? "heartIcon" : null}
                        onClick={
                          this.ownersProjCheck()
                            ? null
                            : this.state.isLoading
                            ? null
                            : () => this.handleLike(projInfo._id)
                        }
                      >
                        <img
                          src={this.state.isLike ? likeIcon : OffLikeBtn}
                          className={
                            this.state.isLoading & this.state.isLike
                              ? "likeIcon-animate"
                              : null
                          }
                          alt="likeButton"
                        />

                        {nFormatter(
                          this.state.isLike ? this.state.likeMembers : null
                        )}
                      </div>
                      <span>{i18n.t("project.like")} </span>
                      <div onClick={this.handleCollectioDialogOpen}>
                        <img
                          src={OffAddBtn}
                          style={{ marginBottom: "5px" }}
                          alt="addbtn"
                        />
                      </div>
                      <span>{i18n.t("global.save")}</span>
                      <div onClick={this.handleShareDialogOpen}>
                        <img src={shareBtn} alt="sharebtn" />
                      </div>
                      <span>{i18n.t("project.selector.share")}</span>
                    </div>
                  )}

                  <div className="projDialog-container">
                    {matches.desktop && (
                      <div className="proj-header">
                        {this.props.projInfo.curationDatas ? (
                          <div className="flagsContainer">
                            {this.props.projInfo.curationDatas.map((item) => {
                              return (
                                <Tooltip
                                  overlayClassName="featureTooltipContainer"
                                  placement="bottomRight"
                                  color="white"
                                  title={
                                    <>
                                      <div className="flagTooltipFeature">
                                        {i18n.t("project.tooltip.feature")}
                                      </div>
                                      <div className="flagTooltipFeatureContent">
                                        <div className="flagTooltipTitle">
                                          {item.flagInfo.name}
                                        </div>
                                        <span>{" - "} </span>
                                        <Moment
                                          className="flagTooltipDate"
                                          locale={this.props.lang}
                                          format="YYYY/MM/DD"
                                        >
                                          {item.curatedAt}
                                        </Moment>
                                      </div>
                                    </>
                                  }
                                  // color="white"
                                >
                                  <img
                                    className="flagImg"
                                    alt="flagImage"
                                    src={item.flagInfo.flagImg}
                                  ></img>
                                </Tooltip>
                              );
                            })}
                          </div>
                        ) : null}
                        <div
                          className="header-profile"
                          onClick={this.handleGoToUserPage}
                        >
                          <ProfilePhoto
                            src={projInfo.uploaderInfo.profilePhoto}
                            size={50}
                          />
                        </div>
                        <div
                          className={
                            this.ownersProjCheck()
                              ? "header-titleBox ownaccount"
                              : "header-titleBox"
                          }
                        >
                          <div>
                            <div className="titleBox-header">
                              <span>{projInfo.title}</span>
                            </div>
                            <div
                              className="titleBox-ownersName"
                              style={{
                                marginBottom: this.ownersProjCheck()
                                  ? "0"
                                  : null,
                              }}
                            >
                              <div
                                className="creators-box"
                                onClick={this.handleOwnerListOpen}
                              >
                                {projInfo.creators.length === 0 ? (
                                  <NavLink
                                    to={{
                                      pathname: `/@${projInfo.uploaderInfo.linkURL}`,
                                    }}
                                  >
                                    <span className="creatorName">
                                      {projInfo.uploaderInfo.userName}
                                    </span>
                                  </NavLink>
                                ) : (
                                  <span className="creatorName">
                                    {i18n.t("project.multipleOwners")}
                                  </span>
                                )}
                                {projInfo.creators.length !== 0 ? (
                                  <>
                                    <img
                                      style={{
                                        transform: this.state.ownerListOpen
                                          ? null
                                          : "rotate(180deg)",
                                      }}
                                      src={ArrowIcon}
                                      alt="owners list Open"
                                    />

                                    {this.state.ownerListOpen
                                      ? this.renderCreators(projInfo.creators)
                                      : null}
                                  </>
                                ) : null}
                              </div>
                              <span
                                className="project-category"
                                onClick={() =>
                                  this.handleSearchCategory(
                                    projInfo.category._id
                                  )
                                }
                              >
                                {projInfo.category
                                  ? this.props.lang === "en"
                                    ? projInfo.category.name.en
                                    : projInfo.category.name.ko
                                  : null}
                              </span>
                            </div>
                          </div>
                          {this.ownersProjCheck() ? (
                            <img
                              className={"editBtn"}
                              src={EditIcon}
                              alt="editIcon"
                              onClick={this.handleEditProject}
                            ></img>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {matches.mobileTablet && (
                      <>
                        <div className="proj-header">
                          {this.props.projInfo.curationDatas ? (
                            <div className="flagsContainer">
                              {this.props.projInfo.curationDatas.map((item) => {
                                return (
                                  <Tooltip
                                    overlayClassName="featureTooltipContainer"
                                    placement="bottomRight"
                                    color="white"
                                    title={
                                      <>
                                        <div className="flagTooltipFeature">
                                          {i18n.t("project.tooltip.feature")}
                                        </div>
                                        <div className="flagTooltipFeatureContent">
                                          <div className="flagTooltipTitle">
                                            {item.flagInfo.name}
                                          </div>
                                          <span>{" - "} </span>
                                          <Moment
                                            className="flagTooltipDate"
                                            locale={this.props.lang}
                                            format="YYYY/MM/DD"
                                          >
                                            {item.curatedAt}
                                          </Moment>
                                        </div>
                                      </>
                                    }
                                    // color="white"
                                  >
                                    <img
                                      className="flagImg"
                                      alt="flagImage"
                                      src={item.flagInfo.flagImg}
                                    ></img>
                                  </Tooltip>
                                );
                              })}
                            </div>
                          ) : null}
                          <div className="header-titleBox">
                            <div className="titleBox-header">
                              <span>{projInfo.title}</span>
                            </div>

                            <div className="titleBox-ownersName">
                              <div
                                // onClick={this.handleGoToUserPage}
                                className="creators-box"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  onClick={
                                    projInfo.creators.length === 0
                                      ? this.handleGoToUserPage
                                      : this.handleOwnerListOpen
                                  }
                                >
                                  {projInfo.creators.length === 0 ? (
                                    <div className="header-profile">
                                      <ProfilePhoto
                                        src={projInfo.uploaderInfo.profilePhoto}
                                        size={30}
                                      />
                                    </div>
                                  ) : null}

                                  {/* <NavLink
                                  to={{
                                    pathname: "/courses",
                                    search: "?sort=name",
                                    hash: "#the-hash",
                                    state: { fromDashboard: true },
                                  }}
                                > */}
                                  {/* <span className="creatorName">
                                    {projInfo.creators.length !== 0
                                      ? i18n.t("project.multipleOwners")
                                      : projInfo.uploaderInfo.userName}
                                  </span> */}
                                  {projInfo.creators.length === 0 ? (
                                    <NavLink
                                      to={{
                                        pathname: `/@${projInfo.uploaderInfo.linkURL}`,
                                      }}
                                    >
                                      <span className="creatorName">
                                        {projInfo.uploaderInfo.userName}
                                      </span>
                                    </NavLink>
                                  ) : (
                                    <span className="creatorName">
                                      {i18n.t("project.multipleOwners")}
                                    </span>
                                  )}
                                  {projInfo.creators.length !== 0 ? (
                                    <>
                                      <img
                                        style={{
                                          transform: this.state.ownerListOpen
                                            ? null
                                            : "rotate(180deg)",
                                        }}
                                        src={ArrowIcon}
                                        alt="owners list Open"
                                      />

                                      {this.state.ownerListOpen
                                        ? this.renderCreators(projInfo.creators)
                                        : null}
                                    </>
                                  ) : null}
                                </div>
                                <span
                                  className="project-category"
                                  onClick={() =>
                                    this.handleSearchCategory(
                                      projInfo.category._id
                                    )
                                  }
                                >
                                  {this.props.lang === "en"
                                    ? projInfo.category.name.en
                                    : projInfo.category.name.ko}
                                </span>
                                {/* </NavLink> */}
                              </div>
                              {this.props.projInfo.uploaderInfo._id ===
                              this.props.userId ? null : (
                                <button
                                  className={`${
                                    this.props.projInfo.uploaderInfo.isFollowing
                                      ? "following-md"
                                      : "follow-md"
                                  }`}
                                  type="button"
                                  onClick={
                                    this.handleFollowSet
                                    // this.props.projInfo.uploaderInfo.isFollowing
                                    //   ? () =>
                                    //       this.props.handleUnFollow(
                                    //         this.props.projInfo.uploaderInfo._id
                                    //       )
                                    //   : () =>
                                    //       this.props.handleFollow(
                                    //         this.props.projInfo.uploaderInfo._id
                                    //       )
                                  }
                                >
                                  {this.props.projInfo.uploaderInfo.isFollowing
                                    ? i18n.t("mypage.profile.unfollow")
                                    : i18n.t("project.follow")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="proj-container">
                      <div className="proj-updateImg">
                        {projInfo.contents.map((data) => {
                          return data.item.data ||
                            data.item.text ||
                            data.item.src ? (
                            <>
                              <div className="updateList">
                                {data.type === "image" ? (
                                  <img
                                    onClick={() =>
                                      this.handleImgOpen(data.item.data)
                                    }
                                    src={data.item.data}
                                    alt="img"
                                  />
                                ) : data.type === "audio" ? (
                                  <audio
                                    style={{
                                      outline: "none",
                                      width: "100%",
                                      padding: "0 30px",
                                    }}
                                    controlsList="nodownload"
                                    src={data.item.data}
                                    controls="controls"
                                  ></audio>
                                ) : data.type === "text" ? (
                                  <div
                                    className="textBox ql-editor"
                                    dangerouslySetInnerHTML={{
                                      __html: data.item.text,
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    className="iframeBox"
                                    dangerouslySetInnerHTML={{
                                      __html: data.item.src,
                                    }}
                                  ></div>
                                )}
                              </div>
                            </>
                          ) : null;
                        })}
                      </div>
                      {matches.mobile && (
                        <div className="m-proj-info">
                          <div className="infoBox">
                            <div className="infoBox-title">
                              <span>{projInfo.title}</span>
                            </div>
                            <div className="infoBox-row">
                              <span>{projInfo.createdAt.substring(0, 10)}</span>
                              <div className="infoBox-icons">
                                <div className="infoIcon">
                                  <img id="like" src={likeIcon} alt="like" />
                                  <span>
                                    {nFormatter(this.state.likeMembers)}
                                  </span>
                                </div>
                                <div className="infoIcon">
                                  <img id="views" src={viewsIcon} alt="views" />
                                  <span>{nFormatter(projInfo.views)}</span>
                                </div>
                                <div className="infoIcon">
                                  <img src={CommentIcon} alt="Comment" />
                                  <span>
                                    {nFormatter(
                                      projInfo.commentCount +
                                        this.state.commentCount
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {this.ownersProjCheck() ? null : (
                              <div className="infoBox-btns">
                                <button
                                  className={
                                    this.state.isLike ? "heartIcon" : null
                                  }
                                  type="button"
                                  onClick={
                                    this.ownersProjCheck()
                                      ? null
                                      : this.state.isLoading
                                      ? null
                                      : () => this.handleLike(projInfo._id)
                                  }
                                >
                                  <img
                                    src={
                                      this.state.isLike ? OnLikeBtn : OffLikeBtn
                                    }
                                    alt="button"
                                    className={
                                      this.state.isLoading & this.state.isLike
                                        ? "likeIcon-animate"
                                        : null
                                    }
                                  />

                                  {this.state.isLike
                                    ? nFormatter(this.state.likeMembers)
                                    : i18n.t("project.like")}
                                </button>
                                <button
                                  type="button"
                                  onClick={this.handleCollectioDialogOpen}
                                >
                                  <img src={OffAddBtn} alt="button" />
                                  컬렉션에 저장
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {matches.mobile && (
                        <>
                          {projInfo.tags.length > 0 ? (
                            <div className="tagsList">
                              <span># {i18n.t("project.tagsTitle")}</span>
                              <div className="ownerInfo-interset">
                                {projInfo.tags.map((item) => {
                                  return (
                                    <span
                                      onClick={() => this.handleSearchTag(item)}
                                    >
                                      {item}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                          <div className="ownerInfo-reportBtn">
                            {projInfo.license === "All Rights Reserved" ? (
                              <Tooltip
                                placement="topLeft"
                                color="white"
                                overlayClassName="featureTooltipContainer"
                                title={
                                  <>
                                    <div className="flagTooltipFeature">
                                      저작권 정보
                                    </div>
                                    <div className="flagTooltipFeatureContent">
                                      <span className="falgToolText">
                                        소유자의 명시적인 허용 없이는 사용할 수
                                        없습니다.
                                      </span>
                                    </div>
                                  </>
                                }
                              >
                                <span>
                                  <span>Copyright </span>
                                  &copy;
                                  {` ${projInfo.uploaderInfo.userName} ${projInfo.license}`}
                                </span>
                              </Tooltip>
                            ) : (
                              <div className="licese_wrapper">
                                {this.renderLicense(projInfo.license)}
                              </div>
                            )}
                            {this.ownersProjCheck() ? null : (
                              <div
                                className="report_btn"
                                onClick={() =>
                                  this.handleReportDialogOepn(projInfo._id)
                                }
                              >
                                <img src={ReportIcon} alt="report" />
                                {i18n.t("project.selector.report")}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {matches.tablet && (
                      <div className="proj-info">
                        {this.ownersProjCheck() ? null : (
                          <div className="infoBox-btns">
                            <button
                              className={this.state.isLike ? "heartIcon" : null}
                              type="button"
                              onClick={
                                this.ownersProjCheck()
                                  ? null
                                  : this.state.isLoading
                                  ? null
                                  : () => this.handleLike(projInfo._id)
                              }
                            >
                              <img
                                src={this.state.isLike ? OnLikeBtn : OffLikeBtn}
                                alt="button"
                                className={
                                  this.state.isLoading & this.state.isLike
                                    ? "likeIcon-animate"
                                    : null
                                }
                              />

                              {this.state.isLike
                                ? nFormatter(this.state.likeMembers)
                                : i18n.t("project.like")}
                            </button>
                            <button
                              type="button"
                              onClick={this.handleCollectioDialogOpen}
                            >
                              <img src={OffAddBtn} alt="button" />
                              컬렉션에 저장
                            </button>
                          </div>
                        )}
                        <div className="infoBox">
                          {/* <div className="infoBox-title">
                            <span>{projInfo.title}</span>
                          </div> */}
                          <div className="infoBox-icons">
                            <div className="infoIcon">
                              <img id="like" src={likeIcon} alt="like" />
                              <span>{nFormatter(this.state.likeMembers)}</span>
                            </div>
                            <div className="infoIcon">
                              <img id="views" src={viewsIcon} alt="views" />
                              <span>{nFormatter(projInfo.views)}</span>
                            </div>
                            <div className="infoIcon">
                              <img src={CommentIcon} alt="follow" />
                              <span style={{ margin: "unset" }}>
                                {nFormatter(
                                  projInfo.commentCount +
                                    this.state.commentCount
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="infoBox-date">
                            <span>
                              {i18n.t("admin.projects.publishDate")} :{" "}
                              {projInfo.createdAt.substring(0, 10)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.render && this.state.userProjectList[0] ? (
                      <div className="proj-myProjList">
                        <div className="proj-userInfo">
                          <NavLink
                            to={{
                              pathname: `/@${projInfo.uploaderInfo.linkURL}`,
                            }}
                          >
                            <ProfilePhoto
                              src={projInfo.uploaderInfo.profilePhoto}
                              size={50}
                            />
                          </NavLink>
                          <div className="uploaderInfo">
                            <NavLink
                              to={{
                                pathname: `/@${projInfo.uploaderInfo.linkURL}`,
                              }}
                            >
                              <span>{projInfo.uploaderInfo.userName}</span>
                            </NavLink>
                            {projInfo.uploaderInfo._id ===
                            this.props.userId ? null : (
                              <button
                                onClick={
                                  projInfo.uploaderInfo.isFollowing
                                    ? () =>
                                        this.props.handleUnFollow(
                                          this.props.projInfo.uploaderInfo._id
                                        )
                                    : () =>
                                        this.props.handleFollow(
                                          projInfo.uploaderInfo._id
                                        )
                                }
                                className={
                                  projInfo.uploaderInfo.isFollowing
                                    ? "following-sm"
                                    : "follow-sm"
                                }
                              >
                                {projInfo.uploaderInfo.isFollowing
                                  ? i18n.t("mypage.profile.following")
                                  : i18n.t("mypage.profile.follow")}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="myProjList-userProj">
                          <Flickity
                            style={{ cursor: "pointer" }}
                            flickityRef={(c) => (this.flkty = c)}
                            className={"slideshow js-slideshow"}
                            options={this.state.flickityOptions}
                          >
                            {this.state.userProjectList.map((item, index) => {
                              return (
                                <div
                                  className="myProejctBox"
                                  value={item._id}
                                  onClick={() => this.loadProjectInfo(item._id)}
                                >
                                  <Project
                                    projectList={this.state.userProjectList}
                                    views={item.views}
                                    location={this.props.loacation}
                                    projectReLoad={this.projectLoad}
                                    projLink={this.state.userInfo.linkURL}
                                    thumbnail={item.thumbnail}
                                    title={item.title}
                                    creators={item.creators}
                                    uploaderInfo={item.uploaderInfo}
                                    likeMembers={item.likeMembers}
                                    mobile={matches.mobile ? true : false}
                                    dialogProject={true}
                                    userInfoId={this.state.userInfo._id}
                                    history={this.props.history}
                                    projectId={item._id}
                                    toOther={() => this.toOther}
                                    projectDetail={true}
                                  ></Project>
                                </div>
                              );
                            })}
                          </Flickity>
                        </div>
                      </div>
                    ) : null}

                    <div className="proj-footerContainer">
                      <div className="proj-comments">
                        {this.state.isLogined ? (
                          <div className="comments-input-container">
                            <span>{i18n.t("project.comments")}</span>

                            <div
                              className="comments-inputBox"
                              //onClick={this.onClickCommentAway}
                              ref={(e) => (this.clickedComment = e)}
                            >
                              {this.state.commentInput ? (
                                <div className="comment_input-area">
                                  <textarea
                                    id="commentInputBox"
                                    type="text"
                                    autoComplete={undefined}
                                    wrap="hard"
                                    cols="20"
                                    onKeyDown={
                                      !this.state.commentLoading
                                        ? this.handleCommentEnter
                                        : null
                                    }
                                    placeholder={i18n.t(
                                      "project.comment.placeholder"
                                    )}
                                  ></textarea>
                                  <div
                                    className="comments-enter"
                                    onClick={
                                      !this.state.commentLoading
                                        ? this.handleCommentEnterClicked
                                        : null
                                    }
                                  >
                                    <img src={CommentEnter} alt="Enter" />
                                  </div>
                                </div>
                              ) : (
                                <div className="comments-click">
                                  <span>
                                    {i18n.t("project.comment.placeholder")}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="comments-input-container comment-isLogined">
                            <span>
                              {i18n.t("project.comments.login.title")}
                            </span>
                            <span>{i18n.t("project.comments.login.text")}</span>
                            <button
                              type="button"
                              className="login-button"
                              onClick={this.toLogin}
                            >
                              {i18n.t("global.login")}
                            </button>
                          </div>
                        )}

                        <div className="comments-list">
                          {this.state.projCommentList.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="comments-infoBox"
                                  key={`comments_${item.userId}`}
                                >
                                  <div className="comments-userInfo">
                                    <NavLink
                                      to={{ pathname: `/@${item.linkURL}` }}
                                    >
                                      <ProfilePhoto
                                        src={item.profilePhoto}
                                        size={50}
                                      />
                                    </NavLink>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="userInfoBox">
                                          <NavLink
                                            to={{
                                              pathname: `/@${item.linkURL}`,
                                            }}
                                          >
                                            <span>{item.userName}</span>
                                          </NavLink>
                                          <Moment
                                            locale={this.props.lang}
                                            fromNow
                                          >
                                            {item.createdAt}
                                          </Moment>
                                        </div>

                                        {this.commentMenuRender(
                                          item,
                                          item.userId
                                        )}
                                      </div>
                                      {this.state.commentSelec === item._id ? (
                                        <div className="comment_modify-field">
                                          <textarea
                                            type="text"
                                            onKeyDown={
                                              this.handleModifyCommentEnter
                                            }
                                            defaultValue={item.comment}
                                            id="comment_modify-inputField"
                                            wrap="hard"
                                            cols="20"
                                          />
                                          <button
                                            type="button"
                                            onClick={this.handleCommentChage}
                                          >
                                            {i18n.t("project.comment.modify")}
                                          </button>
                                        </div>
                                      ) : (
                                        <span
                                          className="comment"
                                          dangerouslySetInnerHTML={{
                                            __html: item.comment.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "<br/>"
                                            ),
                                          }}
                                        ></span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {this.state.more ? (
                          <div className="comments_moreBtn">
                            <button onClick={this.LoadComment}>
                              {i18n.t("project.moreComments")}
                              <img src={donwArrow} alt="more" />
                            </button>
                          </div>
                        ) : null}
                      </div>
                      {!matches.mobile && (
                        <div className="proj-ownerInfo">
                          <div className="ownerInfo-profileBox">
                            <span>{i18n.t("project.owner")}</span>
                            {this.renderOwnerList(projInfo.creators)}
                          </div>
                          <div className="ownerInfo-contentInfo">
                            <div className="contentInfo-subject">
                              <span>{projInfo.title}</span>
                            </div>
                            <div className="contentInfo-icons">
                              <img id="like" src={likeIcon} alt="like" />
                              <span>{this.state.likeMembers}</span>
                              <img id="views" src={viewsIcon} alt="view" />
                              <span>{projInfo.views}</span>
                              <img src={CommentIcon} alt="comment" />
                              <span>
                                {projInfo.commentCount +
                                  this.state.commentCount}
                              </span>
                            </div>
                            <div className="contentInfo-date">
                              <span>
                                {`${i18n.t("admin.projects.publishDate")} : `}
                                {projInfo.createdAt.substring(0, 10)}
                              </span>
                            </div>
                          </div>
                          {/* <div className="ownerInfo-creativeTool">
                          <span>Creative field</span>
                         
                        </div> */}
                          {projInfo.tags[0] ? (
                            <div className="ownerInfo-interset">
                              {projInfo.tags.map((item) => {
                                return (
                                  <>
                                    <span
                                      onClick={() => this.handleSearchTag(item)}
                                    >
                                      {item}
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          ) : null}

                          <div className="ownerInfo-reportBtn">
                            {projInfo.license === "All Rights Reserved" ? (
                              <Tooltip
                                placement="topLeft"
                                color="white"
                                overlayClassName="featureTooltipContainer"
                                title={
                                  <>
                                    <div className="flagTooltipFeature">
                                      저작권 정보
                                    </div>
                                    <div className="flagTooltipFeatureContent">
                                      <span className="falgToolText">
                                        소유자의 명시적인 허용 없이는 사용할 수
                                        없습니다.
                                      </span>
                                    </div>
                                  </>
                                }
                              >
                                <span>
                                  <span>Copyright </span>
                                  &copy;
                                  {` ${projInfo.uploaderInfo.userName} ${projInfo.license}`}
                                </span>
                              </Tooltip>
                            ) : (
                              <div className="licese_wrapper">
                                {this.renderLicense(projInfo.license)}
                              </div>
                            )}
                            {this.ownersProjCheck() ? null : (
                              <div
                                className="report_btn"
                                onClick={() =>
                                  this.handleReportDialogOepn(projInfo._id)
                                }
                              >
                                <img src={ReportIcon} alt="report" />
                                {i18n.t("project.selector.report")}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog>
            </ThemeProvider>
          )}
        </Media>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  searchTitle: state.searchKeyword.searchTitle,
  project: state.projectEditStatus.project,
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  searchContent: (value) => dispatch(searchContent(value)),
  projectEdit: (value) => dispatch(projectEdit(value)),
  langChange: (value) => dispatch(langChange(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(ProjectDialog);
