import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const PostAddCollection = async (id, projectId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/add-project`;
  let body = {
    id,
    projectId,
  };
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const PostCreateCollection = async (name, isHidden, projectId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/create`;
  let body = {
    name,
    isHidden,
    projectId,
  };

  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const PatchCollectionList = async (collectionList, projectId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/modify-list`;
  let body = {
    collectionList,
    projectId,
  };
  await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const PatchColletionViewCount = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/count`;
  await axios.patch(
    url,
    { id },
    token
      ? {
          headers: { Authorization: `Bearer ${token}` },
        }
      : null
  );
};

export const PatchCollection = async (id, name, isHidden) => {
  const token = GetUserToken();

  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/modify`;
  let body = {
    id,
    name,
    isHidden,
  };

  const res = await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
};
export const GetCollectionList = async (userId, limit, skip, projectId) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/list?userId=${
    userId ? userId : ""
  }&projectId=${projectId}`;

  if (skip) {
    url = url + `&skip=${skip}`;
  }
  if (limit) {
    url = url + `&limit=${limit}`;
  }
  const response = await axios.get(
    url,
    token
      ? {
          headers: { Authorization: `Bearer ${token}` },
        }
      : null
  );

  return response.data.data.list;
};
export const GetCollectionInfo = async (collectionId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/info?id=${collectionId}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const PostDeleteCollection = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/delete?id=${id}`;
  await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const DeleteCollectionItem = async (id, projectId) => {
  const token = GetUserToken();
  let body = {
    id,
    projectId,
  };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/collection/remove-project`;
  await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
