import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const SetLike = async (id) => {
  const token = GetUserToken();
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/like`;
    await axios.patch(
      url,
      { id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (e) {
    return e.response;
  }
};
export const SetUnLike = async (id) => {
  try {
    const token = GetUserToken();
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/unlike`;
    await axios.patch(
      url,
      { id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (e) {
    return e.response;
  }
};
