import React, { Component } from "react";
import "./Tutorial.scss";
import mainVideo from "../../image/main-banner-img.png";
import playBtn from "../../image/play-icon.svg";
import MainPlayBtn from "../../image/play-icon-2.svg";
import rightBtn from "../../image/r-white-circle-arrow.svg";
import leftBtn from "../../image/l-white-circle-arrow.svg";
import tImg from "../../image/tutorial-img-1.png";
import LiveIcon from "../../image/live-box.svg";

import program from "../../image/adobe-xd-icon.svg";
import CategorySlider from "../../Component/CategorySlider/CategorySlider";
class Tutorial extends Component {
  state = {
    tutorialData: [
      {
        video: tImg,
        btn: playBtn,
        title:
          "Let's look at 7 ways to use motion trend more effectively in the first tutorial. Let's look at 7 ways to use motion trend......",
    
        videoName: "First tutorial",
        program: program,
        playTime: "02:33",
      },
      {
        video: tImg,
        btn: playBtn,
        title:
          "Let's look at 7 ways to use motion trend more effectively in the first tutorial. Let's look at 7 ways to use motion trend......",
       
        videoName: "First tutorial",
        program: program,
        playTime: "02:33",
      },
    ],
  };

  render() {
    return (
      <div className="main-container">
        <div className="tutorial-container">
          <CategorySlider />
          <div className="tutorialBanner-screen">
            <img id="mainScreen" src={mainVideo} alt="MainScreen" />
            <img id="play" src={MainPlayBtn} alt="Play" />
            <img id="rt" src={rightBtn} alt="Right" />
            <img id="lt" src={leftBtn} alt="Left" />
          </div>
          <div className="tutorialContent-container">
            <div className="tutorial-contentBox">
              <div className="tutorialTitle">
                <span>NEW</span>
                <span>
                  Let's look at 7 ways to use motion trend more effectively in
                  the first tutorial.
                </span>
                <div className="livetitle">
                  <div id="liveIcon"></div>
                  <span>Live in progress!</span>
                </div>
              </div>
              <div className="tutorial-list">
                <img src={rightBtn} alt="Right" />
                <img src={leftBtn} alt="Left" />
                <div className="tutorial-screenBox">
                  <div className="tutorial-screen">
                    <img id="screen" src={tImg} alt="screen" />
                    <img id="live" src={LiveIcon} alt="Live" />
                    <img id="playBtn" src={playBtn} alt="Play" />
                    <span id="playTime">02:33</span>
                  </div>
                  <div className="tutorial-titleBox">
                    <span>First tutorial</span>
                    <span>
                      Let's look at 7 ways to use motion trend more effectively
                      in the first tutorial. Let's look at 7 ways to use motion
                      trend......
                    </span>
                    <div className="tutorial-profile">
                      <div className="profile-myInfo">
                        <img src={program} alt="Profile" />
                        <span>Kim Aeyong</span>
                      </div>
                      <img src={program} alt="program"></img>
                    </div>
                  </div>
                </div>

                {this.state.tutorialData.map((item) => {
                  return (
                    <>
                      <div className="tutorial-screenBox">
                        <div className="tutorial-screen">
                          <img id="screen" src={item.video} alt="screen" />
                          <img id="playBtn" src={item.btn} alt="Play" />
                          <span id="playTime">{item.playTime}</span>
                        </div>
                        <div className="tutorial-titleBox">
                          <span>{item.videoName}</span>
                          <span>{item.title}</span>
                          <div className="tutorial-profile">
                            <div className="profile-myInfo">
                             
                              <span>Kim Aeyong</span>
                            </div>
                            <img src={item.program} alt="program"></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="tutorial-contentBox gray">
              <div className="tutorialTitle">
                <span>CUSTOM RECOMMENDATION</span>
                <span>
                  I will show you the best mage. Get
                  <br /> to know the motion trend more easily.
                </span>
                <div className="moreBtn">
                  <button type="button">more view</button>
                </div>
              </div>
              <div className="tutorial-list">
                <img src={rightBtn} alt="Right" />
                <img src={leftBtn} alt="Left" />
                <div className="tutorial-screenBox ">
                  <div className="tutorial-screen">
                    <img id="screen" src={tImg} alt="screen" />
                    <img id="live" src={LiveIcon} alt="Live" />
                    <img id="playBtn" src={playBtn} alt="Play" />
                    <span id="playTime">02:33</span>
                  </div>
                  <div className="tutorial-titleBox">
                    <span>First tutorial</span>
                    <span>
                      Let's look at 7 ways to use motion trend more effectively
                      in the first tutorial. Let's look at 7 ways to use motion
                      trend......
                    </span>
                    <div className="tutorial-profile">
                      <div className="profile-myInfo">
                        <img src={program} alt="Profile" />
                        <span>Kim Aeyong</span>
                      </div>
                      <img src={program} alt="program"></img>
                    </div>
                  </div>
                </div>

                {this.state.tutorialData.map((item) => {
                  return (
                    <>
                      <div className="tutorial-screenBox">
                        <div className="tutorial-screen">
                          <img id="screen" src={item.video} alt="screen" />
                          <img id="playBtn" src={item.btn} alt="Play" />
                          <span id="playTime">{item.playTime}</span>
                        </div>
                        <div className="tutorial-titleBox">
                          <span>{item.videoName}</span>
                          <span>{item.title}</span>
                          <div className="tutorial-profile">
                            <div className="profile-myInfo">
                              <img src={item.profile} alt="Profile" />
                              <span>Kim Aeyong</span>
                            </div>
                            <img src={item.program} alt="program"></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="tutorial-contentBox">
              <div className="tutorialTitle">
                <span>PHOTOSHOP</span>
                <span>
                  Use Photoshop with the statue and design app to create
                  creative works.
                </span>
                <div className="moreBtn">
                  <button type="button">more view</button>
                </div>
              </div>
              <div className="tutorial-list">
                <img src={rightBtn} alt="Right" />
                <img src={leftBtn} alt="Left" />
                <div className="tutorial-screenBox">
                  <div className="tutorial-screen">
                    <img id="screen" src={tImg} alt="screen" />
                    <img id="live" src={LiveIcon} alt="Live" />
                    <img id="playBtn" src={playBtn} alt="Play" />
                    <span id="playTime">02:33</span>
                  </div>
                  <div className="tutorial-titleBox">
                    <span>First tutorial</span>
                    <span>
                      Let's look at 7 ways to use motion trend more effectively
                      in the first tutorial. Let's look at 7 ways to use motion
                      trend......
                    </span>
                    <div className="tutorial-profile">
                      <div className="profile-myInfo">
                        <img src={program} alt="Profile" />
                        <span>Kim Aeyong</span>
                      </div>
                      <img src={program} alt="program"></img>
                    </div>
                  </div>
                </div>
                {this.state.tutorialData.map((item) => {
                  return (
                    <>
                      <div className="tutorial-screenBox">
                        <div className="tutorial-screen">
                          <img id="screen" src={item.video} alt="screen" />
                          <img id="playBtn" src={item.btn} alt="Play" />
                          <span id="playTime">{item.playTime}</span>
                        </div>
                        <div className="tutorial-titleBox">
                          <span>{item.videoName}</span>
                          <span>{item.title}</span>
                          <div className="tutorial-profile">
                            <div className="profile-myInfo">
                              <img src={item.profile} alt="Profile" />
                              <span>Kim Aeyong</span>
                            </div>
                            <img src={item.program} alt="program"></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="tutorial-contentBox gray">
              <div className="tutorialTitle">
                <span>ILLUSTARTOR</span>
                <span>
                  Try the optimized illustrator that creates the vector image
                  directly.
                </span>
                <div className="moreBtn">
                  <button type="button">more view</button>
                </div>
              </div>
              <div className="tutorial-list">
                <img src={rightBtn} alt="Right" />
                <img src={leftBtn} alt="Left" />
                <div className="tutorial-screenBox ">
                  <div className="tutorial-screen">
                    <img id="screen" src={tImg} alt="screen" />
                    <img id="live" src={LiveIcon} alt="Live" />
                    <img id="playBtn" src={playBtn} alt="Play" />
                    <span id="playTime">02:33</span>
                  </div>
                  <div className="tutorial-titleBox">
                    <span>First tutorial</span>
                    <span>
                      Let's look at 7 ways to use motion trend more effectively
                      in the first tutorial. Let's look at 7 ways to use motion
                      trend......
                    </span>
                    <div className="tutorial-profile">
                      <div className="profile-myInfo">
                        <img src={program} alt="Profile" />
                        <span>Kim Aeyong</span>
                      </div>
                      <img src={program} alt="program"></img>
                    </div>
                  </div>
                </div>
                {this.state.tutorialData.map((item) => {
                  return (
                    <>
                      <div className="tutorial-screenBox">
                        <div className="tutorial-screen">
                          <img id="screen" src={item.video} alt="screen" />
                          <img id="playBtn" src={item.btn} alt="Play" />
                          <span id="playTime">{item.playTime}</span>
                        </div>
                        <div className="tutorial-titleBox">
                          <span>{item.videoName}</span>
                          <span>{item.title}</span>
                          <div className="tutorial-profile">
                            <div className="profile-myInfo">
                              <img src={item.profile} alt="Profile" />
                              <span>Kim Aeyong</span>
                            </div>
                            <img src={item.program} alt="program"></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="tutorial-contentBox">
              <div className="tutorialTitle">
                <span>FRESCO</span>
                <span>
                  Use the different vines of Photoshop to express everything you
                  can imagine.
                </span>
                <div className="moreBtn">
                  <button type="button">more view</button>
                </div>
              </div>
              <div className="tutorial-list">
                <img src={rightBtn} alt="Right" />
                <img src={leftBtn} alt="Left" />
                <div className="tutorial-screenBox ">
                  <div className="tutorial-screen">
                    <img id="screen" src={tImg} alt="screen" />
                    <img id="live" src={LiveIcon} alt="Live" />
                    <img id="playBtn" src={playBtn} alt="Play" />
                    <span id="playTime">02:33</span>
                  </div>
                  <div className="tutorial-titleBox">
                    <span>First tutorial</span>
                    <span>
                      Let's look at 7 ways to use motion trend more effectively
                      in the first tutorial. Let's look at 7 ways to use motion
                      trend......
                    </span>
                    <div className="tutorial-profile">
                      <div className="profile-myInfo">
                        <img src={program} alt="Profile" />
                        <span>Kim Aeyong</span>
                      </div>
                      <img src={program} alt="program"></img>
                    </div>
                  </div>
                </div>
                {this.state.tutorialData.map((item) => {
                  return (
                    <>
                      <div className="tutorial-screenBox">
                        <div className="tutorial-screen">
                          <img id="screen" src={item.video} alt="screen" />
                          <img id="playBtn" src={item.btn} alt="Play" />
                          <span id="playTime">{item.playTime}</span>
                        </div>
                        <div className="tutorial-titleBox">
                          <span>{item.videoName}</span>
                          <span>{item.title}</span>
                          <div className="tutorial-profile">
                            <div className="profile-myInfo">
                              <img src={item.profile} alt="Profile" />
                              <span>Kim Aeyong</span>
                            </div>
                            <img src={item.program} alt="program"></img>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tutorial;
