import React, { Component } from "react";
import "./SoundUploadDialog.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import SoundIcon from "../../Mobile/gray_sound_icon_1.svg";
import Media from "react-media";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Fragment } from "react";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class SoundUploadDialog extends Component {
  state = {
    file: this.props.file ? this.props.file : false,
    preview: this.props.data ? this.props.data : false,
    iframeMsg: "",
    check: false,
  };

  sendData = async () => {
    await this.props.load(this.state.file, this.state.preview);
    this.setState({ file: "", preview: "", check: false });
  };
  handleFileOnChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const fileSize = file.size;
    const maxSize = 10 * 1024 * 1024; //100MB용량 제한
    const fileName = file.name
      .slice(file.name.lastIndexOf(".") + 1)
      .toLowerCase();

    if (file) {
      if (fileSize > maxSize || (fileName !== "wav" && fileName !== "mp3")) {
        alert("10mb 미만, wav,mp3 파일만 등록가능합니다");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState(
            {
              file,
              preview: reader.result,
            },
            () => {
              e.target.files = null;
            }
          );
        };
      }
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.file && prevProps.preview) {
      if (
        prevProps.file !== prevState.file &&
        prevProps.data !== prevState.preview
      ) {
        console.log("ss");
        this.setState({ file: prevProps.file, preview: prevProps.data });
      }
    } else {
      return;
    }
  };
  handleChegeCheck = (e) => {
    if (e.target.checked) {
      this.setState({
        check: true,
      });
    } else {
      this.setState({
        check: false,
      });
    }
  };
  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 599px)",
            desktop: "(min-width: 600px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.desktop && (
                <Dialog
                  fullWidth={this.props.fullWidth}
                  className={"soundeDialog-container"}
                  maxWidth={"sm"}
                  open={open}
                  onClose={onClose}
                >
                  <div className="closeBtn" onClick={onClose}>
                    <img src={closeIcon} alt="close" />
                  </div>
                  <div className="one-button-dialog-container soundDialog">
                    <div className="soundDialog-box">
                      <div className="soundDialog-title">
                        <span>{i18n.t("audioUpload.dialog.title")}</span>
                      </div>
                      <>
                        <span className="uploadMsg">
                          {i18n.t("audioUpload.dialog.uploadRule")}
                        </span>
                        <div className="file-box">
                          <div className="fileInfo">
                            {this.state.file.name || this.props.file.name}
                          </div>
                          <input
                            type="file"
                            accept="audio/*"
                            id="audioFileUpload"
                            onChange={this.handleFileOnChange}
                          />
                          <label htmlFor="audioFileUpload">
                            {i18n.t("audioUpload.dialog.findBtn")}
                          </label>
                        </div>
                        <div className="checkPublic">
                          <input
                            type="checkbox"
                            id="copyLightCheck"
                            onChange={this.handleChegeCheck}
                          />
                          <label htmlFor="copyLightCheck">
                            <span></span>
                            {i18n.t("audioUpload.dialog.copyText")}
                          </label>
                        </div>
                        <div className="soundDialog-btns">
                          <button className="closeButton" onClick={onClose}>
                            {i18n.t("global.close")}
                          </button>
                          <button
                            className={
                              this.state.check && this.state.file
                                ? null
                                : "activeClick"
                            }
                            onClick={
                              this.state.check && this.state.file
                                ? this.sendData
                                : null
                            }
                          >
                            {i18n.t("global.upload")}
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </Dialog>
              )}
              {matches.mobile && (
                <BottomSheet
                  open={open}
                  onDismiss={onClose}
                  // blocking={false}
                  // header={
                  // }
                  // snapPoints={({ maxHeight }) => [
                  //   maxHeight / 4,
                  //   maxHeight * 0.6,
                  // ]}
                >
                  <div className="one-button-dialog-container soundDialog">
                    <div className="soundDialog-box">
                      <div className="soundDialog-title">
                        <span>{i18n.t("audioUpload.dialog.title")}</span>
                      </div>
                      <>
                        <div className="mobile-sound-container">
                          <div className="sound-fileBox">
                            <img src={SoundIcon} alt="Sound File" />
                            <span>{this.props.file.name}</span>
                          </div>
                        </div>
                        <div className="audioRule">
                          <span>{i18n.t("audioUpload.dialog.uploadRule")}</span>
                        </div>
                        <div className="checkPublic">
                          <input
                            type="checkbox"
                            id="copyLightCheck"
                            accept="audio/*"
                            onChange={this.handleChegeCheck}
                          />
                          <label htmlFor="copyLightCheck">
                            <span></span>
                            {i18n.t("audioUpload.dialog.copyText")}
                          </label>
                          <button
                            type="button"
                            className={
                              this.state.check && this.state.file
                                ? "activeClick"
                                : "disableClick"
                            }
                            onClick={
                              this.state.check && this.state.file
                                ? this.sendData
                                : null
                            }
                          >
                            {i18n.t("global.confirm")}
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </BottomSheet>
              )}
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}

export default withStyles(styles)(SoundUploadDialog);
