import Cookies from "universal-cookie";
const cookies = new Cookies();

export const SetUserToken = (token, isAutoLogin, firebaseToken) => {
  const expiresDate = new Date();
  expiresDate.setHours(expiresDate.getHours() + 24 * 1);
  if (isAutoLogin) {
    cookies.set("token", token);
  } else {
    cookies.set("token", token, { expires: expiresDate });
  }

  cookies.set("firebaseToken", firebaseToken);
};

export const GetUserToken = () => {
  return cookies.get("token");
};
export const RemoveUserToken = () => {
  cookies.remove("token");
  cookies.remove("firebaseToken");
};
