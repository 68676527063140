import React, { Component } from "react";
import "./CollectionDialog.scss";
import { Dialog } from "@material-ui/core";
import AddIcon from "../../image/Collection/add-black-icon.svg";
import AddCollectionDialog from "./AddColletionDialog/AddCollectionDialog";
import i18n from "../../i18n";
import SuccessDialog from "./SaveSuccessDialog/SaveSuccessDialog";
import {
  PatchCollectionList,
  GetCollectionList,
  PostAddCollection,
  PostCreateCollection,
} from "../../Api/Collection/Collection";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import checkIcon from "../../image/check-icon-white.svg";
import { ProjectDetail_GAEvent } from "../../modules/gaEvents";
const override = css`
  display: flex;
  justify-content: center;
`;
const color = "#070707";
class CollectionDialog extends Component {
  state = {
    successDialogOpen: false,
    collectionList: [],
    overCheck: false,
    addCollectionDialogOpen: false,
    search: "",
    isLoading: false,
    isChanged: false,
  };
  collectionLimit = 20;

  handleMouseOver = () => {
    this.setState({ overCheck: true });
  };
  handleMouseOut = () => {
    this.setState({ overCheck: false });
  };
  handleAddCollectionOpen = () => {
    this.setState({ addCollectionDialogOpen: true }, () => this.props.onClose);
  };
  handleAddCollectionClose = () => {
    this.setState({ addCollectionDialogOpen: false });
  };
  componentDidMount = () => {
    this.getCollections();
  };

  handleAddCollection = async (id) => {
    try {
      await PostAddCollection(this.state.collectionList, this.props.projId);
      ProjectDetail_GAEvent("saveColl");
      this.setState({ successDialogOpen: true });
    } catch (e) {
      console.dir(e);
    }
  };
  getCollections = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await GetCollectionList(
        null,
        this.collectionLimit,
        null,
        this.props.projId
      );
      this.setState({
        collectionList: response ? response : [],
        isLoading: false,
      });
    } catch (e) {
      console.dir(e);
      this.setState({ isLoading: false });
    }
  };
  handleCreateCollection = async (collectionName, isHidden) => {
    try {
      await PostCreateCollection(collectionName, isHidden, this.props.projId);
      this.handleAddCollectionClose();
      this.getCollections();
    } catch (e) {
      console.dir(e);
    }
  };
  handlePatchCollectionList = async () => {
    const list = this.state.collectionList.filter((item) => item.isModify);

    try {
      await PatchCollectionList(list, this.props.projId);
      this.props.onClose();
    } catch (e) {
      console.dir(e);
    }
  };
  handleChangeAddRemove = (id, index) => {
    let List = this.state.collectionList.map((item) => ({ ...item }));
    if (List) {
      List[index].isContained = !List[index].isContained;
      List[index].isModify = !List[index].isModify;
      this.setState({ collectionList: List, isChanged: true });
    }
  };
  render() {
    const { onClose, open } = this.props;

    return (
      <Dialog
        fullWidth={this.props.fullWidth}
        className={"collection-dialog"}
        maxWidth={false}
        open={open}
        onClose={onClose}
      >
        <SuccessDialog
          dialogHeadText={i18n.t("collection.dialog.title")}
          dialogBodyText={i18n.t("collection.dialog.success")}
          open={this.state.successDialogOpen}
          onClose={() => this.setState({ successDialogOpen: false })}
        />
        {this.state.addCollectionDialogOpen ? (
          <AddCollectionDialog
            handleCreateCollection={this.handleCreateCollection}
            open={this.state.addCollectionDialogOpen}
            onClose={this.handleAddCollectionClose}
            thumbnail={this.props.thumbnail}
            creatorInfo={this.props.creatorInfo}
            projId={this.props.projId}
          />
        ) : null}

        <div className="collection-container">
          <div className="collection-box">
            <div className="collection-title">
              <span>{i18n.t("collection.dialog.title")}</span>
            </div>

            <div className="collection-fileList">
              <div className="fileList-container">
                <div
                  className={`fileList-box ${
                    this.state.collectionList.length === 0 ? "emptyList" : ""
                  }`}
                  style={
                    this.state.collectionList.length === 0
                      ? {
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }
                      : null
                  }
                >
                  {this.state.isLoading ? (
                    <FadeLoader
                      color={color}
                      loading={this.state.isLoading}
                      css={override}
                      size={10}
                    />
                  ) : this.state.collectionList.length !== 0 ? (
                    this.state.collectionList.map((item, index) => {
                      return (
                        <div
                          className="file_wrapper"
                          key={`${item.name}_${item._id}`}
                          onClick={() =>
                            this.handleChangeAddRemove(item._id, index)
                          }
                        >
                          <div className="file_thumbnail">
                            <div
                              style={{
                                backgroundImage: `url(${item.projects[0].thumbnail})`,
                              }}
                            />
                            {item.isContained && (
                              <>
                                <div className="contained_wrapper"></div>
                                <img src={checkIcon} alt="checkBox" />
                              </>
                            )}
                          </div>
                          <span
                          // onClick={() => this.handleAddCollection(item._id)}
                          >
                            {item.name}
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <span>생성된 컬렉션이 없습니다</span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="collection-addBox"
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
            >
              <div
                className="collection-addButton"
                onClick={this.handleAddCollectionOpen}
              >
                <img alt="collection-add" src={AddIcon} />
                <span>{i18n.t("collection.dialog.addCollection")}</span>
              </div>
              <button
                onClick={
                  this.state.isChanged
                    ? () => this.handlePatchCollectionList()
                    : null
                }
                disabled={this.state.isChanged ? false : true}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default CollectionDialog;
