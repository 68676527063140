import React, { Component } from "react";
import "./AddInfo.scss";
import CountrySelect from "../../../SignUp/UserInfo/CountrySelect";
import { RegionDropdown } from "react-country-region-selector";
import i18n from "../../../i18n";
import { TeamLinkExistCheck } from "../../../Api/Team/Team";
class AddInfo extends Component {
  state = {
    file: "",
    profilePhoto: "",
    teamName: "",
    teamPageDefaultUrl: "http://motiontrend.tv/team/",
    teamPageUrl: "",
    snsUrl: "",
    coutryListOpen: false,
    countryName: "",
    countryCode: "",
    region: "",
    nameMsg: "",
    urlMsg: "",
    nameValid: "",

    urlAvailable: "",
    urlOverlab: false,
  };
  componentDidMount = () => {
    this.infoSet();
  };
  infoSet = () => {
    const setInfo = this.props.infoSet();
    this.setState({
      teamName: setInfo.teamName,
      teamPageUrl: setInfo.teamPage,
      profilePhoto: setInfo.preview,
      snsUrl: setInfo.snsUrl,
      countryName: setInfo.countryInfo.countryName,
      countryCode: setInfo.countryInfo.countryCode,
      region: setInfo.countryInfo.region,
    });
  };
  // handleTeamUrlChange = (e) => {
  //   this.setState({ teamPageUrl: e.target.value }, () =>
  //     this.props.handleSetInfo(this.state)
  //   );
  // };
  handleTeamUrlChange = (e) => {
    const regType1 = /^[a-z0-9+]*$/; // 알파벳과 숫자만 있는지 확인하는 정규식
    const regType2 = /\s/g; //공백 확인 정규식
    const teamPageUrl = e.target.value.trim();
    if (!teamPageUrl || teamPageUrl.length === 0) {
      this.setState(
        {
          teamPageUrl,
          urlMsg: i18n.t("userSetting.requiredUrl"),
          urlAvailable: false,
          urlOverlab: false,
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else if (!regType1.test(teamPageUrl)) {
      this.setState(
        {
          teamPageUrl,
          urlMsg: i18n.t("userSetting.regexCheck1"),
          urlAvailable: false,
          urlOverlab: false,
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else if (regType2.test(e.target.value)) {
      this.setState(
        {
          teamPageUrl,
          urlMsg: i18n.t("userSetting.regexCheck2"),
          urlAvailable: false,
          urlOverlab: false,
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else if (teamPageUrl.length < 2 || teamPageUrl.length > 16) {
      this.setState({
        teamPageUrl,
        urlMsg: i18n.t("team.create.urlLength"),
        urlAvailable: false,
        urlOverlab: false,
      });
    } else {
      this.setState({ teamPageUrl }, () =>
        this.setState(
          {
            urlMsg: i18n.t("userSetting.regexCheck3"),
            urlAvailable: true,
            urlOverlab: false,
          },
          () => this.props.handleSetInfo(this.state)
        )
      );
    }
  };
  handleUrlOverlapCheck = async () => {
    const teamPageUrl = this.state.teamPageUrl;
    this.setState({ btnActive: false });
    if (teamPageUrl.length === 0) {
      this.setState({
        urlMsg: i18n.t("userSetting.requiredUrl"),
        urlAvailable: false,
      });
      return;
    } else {
      try {
        const response = await TeamLinkExistCheck(teamPageUrl);

        if (!response.data) {
          this.setState(
            {
              urlMsg: i18n.t("all.signup.Available"),
              urlOverlab: true,
              urlAvailable: true,
            },
            () => this.props.handleSetInfo(this.state)
          );
        } else if (response.data === true) {
          this.setState(
            {
              urlMsg: i18n.t("userSetting.usedUrl"),
              urlOverlab: false,
              urlAvailable: false,
            },
            () => this.props.handleSetInfo(this.state)
          );
        }
      } catch (e) {
        this.setState(
          {
            urlMsg: i18n.t("global.error.server.title"),
            urlOverlab: false,
          },
          () => this.props.handleSetInfo(this.state)
        );
      }
    }
  };
  handleTeamNameChange = (e) => {
    const teamName = e.target.value.trim();
    const spcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자
    // const nickSpcialRegex = /_-/;
    if (teamName.length === 0) {
      this.setState(
        {
          nameValid: false,
          teamName,
          nameMsg: i18n.t("userInfo.nickName"),
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else if (spcialRegex.test(teamName)) {
      this.setState(
        {
          nameValid: false,
          teamName,
          nameMsg: i18n.t("global.alert.specialCharacter"),
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else if (teamName.length < 2 || teamName.length > 16) {
      this.setState(
        {
          nameValid: false,
          teamName,
          nameMsg: i18n.t("userInfo.nickNameCondition"),
        },
        () => this.props.handleSetInfo(this.state)
      );
    } else {
      this.setState(
        {
          nameValid: true,
          teamName,
          nameMsg: "",
        },
        () => this.props.handleSetInfo(this.state)
      );
    }
  };

  handleSnsUrlChange = (e) => {
    this.setState({ snsUrl: e.target.value }, () =>
      this.props.handleSetInfo(this.state)
    );
  };
  handleChangeProfile = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState(
        {
          file,
          profilePhoto: reader.result,
        },
        () => this.props.handleSetInfo(this.state)
      );
    };
  };
  // getGeoInfo = () => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       this.setState({
  //         countryName: data.country_name,
  //         countryCode: data.country_code,
  //       });
  //     })
  //     .catch((error) => {
  //       console.dir(error);
  //     });
  // };
  // handleTeamNameExist = async () => {
  //   try {
  //     const response = await TeamNameExistCheck(this.state.teamName);
  //     this.setState({ nameOverlab: response }, () => {
  //       this.props.handleSetInfo(this.state);
  //       if (!this.state.nameOverlab) {
  //         this.setState({ nameMsg: i18n.t("all.signup.Available") });
  //       } else {
  //         this.setState({ nameMsg: i18n.t("team.create.main.usedName") });
  //       }
  //     });
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };

  handleChangeCountry = (e) => {
    this.setState(
      {
        countryCode: e.value,
        countryName: e.label,
        region: null,
      },
      () => this.props.handleSetInfo(this.state)
    );
  };
  handleCoutryOpen = () => {
    this.setState({ coutryListOpen: true });
  };
  handleCoutryClose = () => {
    this.setState({ coutryListOpen: false });
  };
  handleRegionChange = (val) => {
    if (val !== "") {
      this.setState({ region: val }, () =>
        this.props.handleSetInfo(this.state)
      );
    } else {
      this.setState({ region: null }, () =>
        this.props.handleSetInfo(this.state)
      );
    }
  };
  handleProfileClick = () => {
    this.profileInput.click();
  };
  render() {
    return (
      <div className="container addInfo-container">
        <div className="addInfo-box">
          {/* <div className="profile-upload-box">
            <div
              className="team-profilePhoto"
              onClick={this.handleProfileClick}
              style={{
                backgroundImage: `url(${this.state.profilePhoto})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img src={PlusIcon} alt="ProfilePhotoSetting" />
            </div>
            <input
              type="file"
              hidden
              onChange={this.handleChangeProfile}
              ref={(ref) => (this.profileInput = ref)}
            />
          </div> */}
          <div className="addInfo-teamName addInfo-columnBox">
            <span>
              {i18n.t("team.create.addInfo.name")}
              <span className="info-necessary">
                *{/* {i18n.t("global.necessary")} */}
              </span>
            </span>
            <div className="teamName-inputField">
              <input
                type="text"
                className="addInfo-input"
                placeholder={i18n.t("team.create.addInfo.name")}
                onChange={this.handleTeamNameChange}
                value={this.state.teamName}
              />
              {/* <button
                className={`${this.state.nameValid ? "" : "disabled-button"}`}
                type="button"
                onClick={this.state.nameValid ? this.handleTeamNameExist : null}
              >
                {i18n.t("global.check")}
              </button> */}
              <span
                style={{
                  color: !this.state.nameValid ? "#ff2e43" : "green",
                }}
              >
                {this.state.nameMsg}
              </span>
            </div>
          </div>
          <div className="addInfo-teamUrl addInfo-columnBox">
            <span>
              {i18n.t("teamSetting.account.url.title")}
              <span className="info-necessary">
                *{/* {i18n.t("global.necessary")} */}
              </span>{" "}
            </span>
            <div className="teamUrl-inputBox">
              <div className="teamUrl-defaultUrl">
                {this.state.teamPageDefaultUrl}
              </div>
              <div className="teamUrl-urlInput">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",

                    flex: "0.5",
                    height: "40px",
                    marginRight: "5px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "5px",
                      fontSize: "12px",
                    }}
                  >
                    @
                  </div>
                  <input
                    defaultValue={this.state.teamPageUrl}
                    onChange={this.handleTeamUrlChange}
                    type="text"
                    className="addInfo-input"
                  />
                </div>
              </div>

              <button
                className={`${
                  this.state.urlAvailable ? "" : "disabled-button"
                }`}
                onClick={
                  !this.state.urlAvailable ? null : this.handleUrlOverlapCheck
                }
              >
                {i18n.t("global.check")}
              </button>
              <span
                className="url-alertMsg"
                style={{
                  color:
                    this.state.urlAvailable === false
                      ? "#ff2e43"
                      : this.state.urlOverlab
                      ? "green"
                      : null,
                }}
              >
                {this.state.urlMsg}
              </span>
            </div>
          </div>
          <div className="addInfo-locationBox addInfo-columnBox">
            <span>
              {i18n.t("job.upload_1.location")}{" "}
              <span className="info-necessary"></span>{" "}
            </span>
            <div className="locationBox-row">
              <CountrySelect
                defaultValue={this.state.countryCode}
                handleChangeCountry={this.handleChangeCountry}
                countryCode={this.state.countryCode}
                placeholder="국가를 선택하세요"
              />
              {this.state.countryCode ? (
                <RegionDropdown
                  style={{
                    padding: "0 15px",
                    borderRadius: "unset",
                  }}
                  countryValueType="short"
                  country={this.state.countryCode}
                  value={this.state.region}
                  className="region-input"
                  defaultOptionLabel={i18n.t("job.upload_1.region")}
                  onChange={(val) => this.handleRegionChange(val)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddInfo;
