import React, { Component } from "react";
import { Table, Button, Menu, Dropdown } from "antd";
import "antd/dist/antd.css";
import "./SearchResult.scss";
import Content from "../../Content/Content";
import Moment from "react-moment";
import "moment/locale/ko";
import NumberFormat from "react-number-format";

import i18n from "../../../../../i18n";
import OnListIcon from "../../../../../image/AdminIcon/change-red-list-icon.svg";
import OffListIcon from "../../../../../image/AdminIcon/change-gray-list-icon.svg";
import OnTileIcon from "../../../../../image/AdminIcon/change-red-icon.svg";
import OffTileIcon from "../../../../../image/AdminIcon/change-gray-icon.svg";
import imgPlaceholder from "../../../../../image/Admin/placeholder.gif";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import Pagination from "../../../../../Component/Pagination/Pagination";
import { langChange } from "../../../../../Redux/action/index";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PostDeleteProject } from "../../../../../Api/Admin/Project/project";
import { withSnackbar } from "notistack";
import { compose } from "redux";
class SearchResultArea extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    arrayType: "list",
  };
  limit = 10;
  handleDetailPostPopUp = () => {
    this.props.getProjInfo(this.state.selectedPostId, "project");
  };

  handleTagsAndCatePopUp = () => {
    this.props.getProjInfo(this.state.selectedPostId, "tags");
  };

  handleTagsAndCatePopUpFromGrid = (e) => {
    this.setState({ selectedPostId: e.item.props.value }, () =>
      this.props.getProjInfo(this.state.selectedPostId, "tags")
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  checkSorterType = (sorter) => {
    if (sorter === "title") {
      return 1;
    } else if (sorter === "createdAt") {
      return 2;
    } else if (sorter === "userName") {
      return 3;
    } else if (sorter === "views") {
      return 4;
    } else if (sorter === "likeMembers") {
      return 5;
    } else if (sorter === "category") {
      return 6;
    } else {
      return null;
    }
  };
  handleArrayReset = (arrayType) => {
    if (arrayType === "list") {
      this.setState({ arrayType: "list" });
    } else {
      this.setState({ arrayType: "tile" });
    }
  };
  handleTableSortChange = async (a, b, sorter) => {
    let orderType = this.checkSorterType(sorter.field);

    if (sorter.order === "ascend") {
      this.props.handleSorter(orderType, true);
    } else if (sorter.order === "descend") {
      this.props.handleSorter(orderType, false);
    } else {
      this.props.handleSorter(null, null);
    }
  };

  // start = () => {
  //     // ajax request after empty completing
  //     setTimeout(() => {
  //         this.setState({
  //             selectedRowKeys: [],
  //             loading: false,
  //         })
  //     }, 1000)
  // }

  handleDeleteContents = async () => {
    this.setState({ loading: true });

    confirmAlert({
      title: "게시물 삭제 확인",
      message: `선택된 ${this.state.selectedRowKeys.length}개의 게시물을 정말로 삭제하시겠습니까 ? 삭제후에는 복원이 불가합니다.`,

      afterClose: () => {
        this.setState({ loading: false });
      },

      buttons: [
        {
          label: "삭제",
          onClick: async () => {
            try {
              const response = await PostDeleteProject(
                this.state.selectedRowKeys
              );

              if (response.message === "success") {
                this.setState(
                  { loading: false },
                  () => this.props.getProjList() // 컨텐츠 정보 다시 가져옴
                );
                // alert(`게시물이 삭제 되었습니다.`);
                this.props.enqueueSnackbar("message blah blah", {
                  variant: "success",
                  key: "uppoer.accept",
                  autoHideDuration: 5000,
                  preventDuplicate: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                });
              }
            } catch (e) {
              console.dir(e);
            }
          },
        },
        {
          label: "취소",
          onClick: () => {
            this.setState({ loading: false });
            return;
          },
        },
      ],
    });
  };

  handleGridViewProjPopUp = (e) => {
    const id = e.item.props.value;
    this.setState(
      {
        selectedPostId: id,
      },
      () => this.handleDetailPostPopUp()
    );
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        dataIndex: "thumbnail",
        width: 100,
        ellipsis: true,
        render: (thumbnail) => {
          if (thumbnail) {
            return (
              <div
                style={{
                  backgroundImage: `url(${thumbnail})`,
                  width: "70px",
                  height: "70px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                src={thumbnail}
              ></div>
            );
          } else {
            return (
              <div
                style={{
                  backgroundImage: `url(${imgPlaceholder})`,
                  backgroundColor: "gray",
                  width: "70px",
                  height: "70px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                src={thumbnail}
              ></div>
            );
          }
        },
      },

      {
        title: i18n.t("admin.projects.title"),
        dataIndex: "title",
        ellipsis: true,
        width: 300,
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        // onFilter: (value, record) => record.userType.indexOf(value) === 0,
        // sorter: (a, b) => a.userType.length - b.userType.length,
        // sortDirections: ["descend"],
        sorter: true,
        align: "center",
        render: (title) => {
          if (title) {
            return (
              <div
                className="postTitle"
                style={{ cursor: "pointer" }}
                onClick={this.handleDetailPostPopUp}
              >
                {title}
              </div>
            );
          } else {
            return <div>{i18n.t("global.noData")}</div>;
          }
        },
      },
      {
        title: i18n.t("admin.projects.publishDate"),
        dataIndex: "createdAt",
        ellipsis: true,
        // sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        sorter: true,
        align: "center",
        render: (createdAt) => {
          if (createdAt) {
            return (
              <Moment locale={this.props.lang} fromNow>
                {createdAt}
              </Moment>
            );
          } else {
            return <div>{i18n.t("global.noData")}</div>;
          }
        },
      },
      {
        title: i18n.t("indivi.signup.userName"),
        ellipsis: true,
        sorter: true,
        dataIndex: "userName",
        align: "center",
        render: (userName) => {
          if (userName) {
            return <span>{userName}</span>;
          } else {
            return <div>{i18n.t("global.noData")}</div>;
          }
        },
      },
      {
        title: i18n.t("mypage.profile.views"),
        width: 100,
        ellipsis: true,
        dataIndex: "views",
        sorter: true,
        align: "center",
        // sorter: (a, b) => a.views - b.views,
        render: (views) => {
          if (views) {
            return <span>{views}</span>;
          } else {
            return <div>{i18n.t("global.noData")}</div>;
          }
        },
      },
      {
        title: i18n.t("admin.projects.likes"),
        width: 100,
        ellipsis: true,
        dataIndex: "likeMembers",
        sorter: true,
        align: "center",
        // sorter: (a, b) => a.likeMembers - b.likeMembers,
        render: (likeMembers) => {
          if (likeMembers) {
            return <span>{likeMembers}</span>;
          } else {
            return <span>0</span>;
          }
        },
      },
      // {
      //     title: 'E-mail',
      //     dataIndex: 'creatorInfo',
      //     render: (creatorInfo) => {
      //         if (creatorInfo) {
      //             return <span>{creatorInfo.creatorEmail}</span>
      //         } else {
      //             return <div>No Data</div>
      //         }
      //     },
      // },

      {
        title: i18n.t("job.searchFilter.field"),
        ellipsis: true,
        dataIndex: "category",
        width: 150,
        sorter: true,
        // filters,
        align: "center",
        filters:
          this.props.lang === "ko"
            ? this.props.categoriesKor.map((item) => ({
                value: item.value,
                text: item.label,
              }))
            : this.props.categoriesEng.map((item) => ({
                value: item.value,
                text: item.label,
              })),
        // sorter: (a, b) => a.category - b.category,
        render: (category) => {
          if (category) {
            if (i18n.language === "en") {
              return <span>{category.name.en}</span>;
            } else {
              return <span>{category.name.ko}</span>;
            }
          } else {
            return <span>{i18n.t("global.noData")}</span>;
          }
        },
      },
      {
        // title: '',
        dataIndex: "_id",
        width: 100,
        fixed: "right",
        render: (_id) => {
          const menu = (
            <Menu>
              <Menu.Item onClick={this.handleDetailPostPopUp}>
                <div>{i18n.t("게시글 보기")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("레퍼런스 조정으로 복사")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("깃발 분류")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("게시물 제한")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  this.setState({ selectedRowKeys: [_id] }, () =>
                    this.handleDeleteContents()
                  )
                }
              >
                <div>{i18n.t("게시물 삭제")}</div>
              </Menu.Item>
              <Menu.Item onClick={this.handleTagsAndCatePopUp}>
                <div>{i18n.t("카테고리 및 태그 수정")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("작성자에게 메시지 보내기")}</div>
              </Menu.Item>
            </Menu>
          );
          return (
            <Dropdown overlay={menu} placement="bottomRight" arrow>
              {/* <Button>bottomCenter</Button> */}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                // onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Dropdown>
          );
        },
      },
    ];

    return (
      <div className="SearchResultArea-container">
        <div className="resultheader">
          <div className="title">
            {i18n.t("admin.projects.all.subTitle")}
            <NumberFormat
              className="title"
              disabled
              value={this.props.totalItems}
              thousandSeparator={true}
            />
          </div>

          <div className="deleteBtnContainer">
            <div className="listArraySort-container">
              <img
                src={this.state.arrayType === "list" ? OnListIcon : OffListIcon}
                alt="listIcon"
                onClick={() => this.handleArrayReset("list")}
              />
              <img
                src={this.state.arrayType === "tile" ? OnTileIcon : OffTileIcon}
                alt="tileIcon"
                onClick={() => this.handleArrayReset("tile")}
              />
            </div>
            <span className="selectedCount" style={{ margin: "0 8px" }}>
              {hasSelected ? ` ${selectedRowKeys.length} items` : ""}
            </span>
            <Button
              type="primary"
              onClick={this.handleDeleteContents}
              disabled={!hasSelected}
              loading={loading}
            >
              {i18n.t("global.delete")}
            </Button>
          </div>
        </div>
        <div
          className="functionBtnsContainer"
          style={{ marginBottom: 16 }}
        ></div>
        {this.state.arrayType === "list" ? (
          <>
            <Table
              className="modal-table"
              // shorthand
              // rowKey
              rowKey="_id"
              //rowKey={(obj) => obj.id}
              pagination={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.props.list}
              onChange={this.handleTableSortChange}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: (event) => {
                    this.setState({
                      selectedPostId: record._id,
                    });
                  }, // click row
                };
              }}
              // 번역
              locale={{
                filterConfirm: i18n.t("global.ok"),
                filterReset: i18n.t("global.reset"),
                triggerDesc: i18n.t("admin.sort.triggerDesc"),
                triggerAsc: i18n.t("admin.sort.triggerAsc"),
                cancelSort: i18n.t("admin.sort.cancelSort"),
                emptyText: i18n.t("global.noData"),
              }}
            />
            {/* <Pagination
              currentPage={this.props.currentPage}
              totalCount={this.props.totalItems}
              limit={this.props.limit}
              handleActivePage={this.props.handlePageChange}
            /> */}
          </>
        ) : (
          <div>
            <div className="reference-container">
              {this.props.list.map((item) => {
                return (
                  <div
                    key={`refernce_${item._id}`}
                    className="reference-content"
                    // onClick={() => {
                    //   this.setState(
                    //     {
                    //       selectedPostId: item._id,
                    //     },
                    //     () => this.handleDetailPostPopUp()
                    //   );
                    // }}
                  >
                    <Content
                      key={item._id}
                      thumbnail={item.thumbnail}
                      title={item.title}
                      // creatorPhoto={
                      //     item.creatorInfo.creatorPhoto
                      // }
                      postId={item._id}
                      handleGridViewProjPopUp={() =>
                        this.handleGridViewProjPopUp
                      }
                      handleTagsAndCatePopUpFromGrid={() =>
                        this.handleTagsAndCatePopUpFromGrid
                      }
                      creatorName={item.userName}
                      likeMembers={item.likeMembers.length}
                      createdAt={item.createdAt}
                      views={item.views}
                    ></Content>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Pagination
          currentPage={this.props.currentPage}
          totalCount={this.props.totalItems}
          limit={this.props.limit}
          handleActivePage={this.props.handlePageChange}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(SearchResultArea);
