import React, { Component } from "react";
import "./chatTest.scss";
import axios from "axios";
import { connect } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import UnCheckedBox from "../image/check-off-box-icon.svg";
import CheckedBox from "../image/check-on-box-icon.svg";
import i18n from "../i18n";
import DeleteIcon from "../image/Message/message-delete-icon.svg";
import editIcon from "../image/edit.svg";
import UserDeleteIcon from "../image/Message/to-delete-icon.svg";
import submitIcon from "../image/enter-comments-icon.svg";
import imageSendIcon from "../image/Message/message-function-icon-1.svg";
import queryString from "query-string";
import * as firebaseChat from "../firebase";
import Cookies from "universal-cookie";
import groupIcon from "../image/Group.svg";
import checkIcon from "../image/black-check-icon.svg";
import { withSnackbar } from "notistack";
import { changeSocket } from "../Redux/action/index";
import { GetUserToken } from "../modules/userAuth";
import ProfilePhoto from "../Component/ProfilePhoto/ProfilePhoto";
import { timeBefore } from "../Component/functions";
import mailIcon from "../image/mail.svg";
// import ImgsViewr from "react-images-viewer";
import ImgsViewer from "react-images-viewer";
import { GetUserInfo } from "../Api/User/user";
class ChatTest extends Component {
  state = {
    chats: [],
    userList: [],
    messages: [],
    isSearching: false,
    messageInput: null,
    selectedRoom: null,
    selectedUser: null,
    selectedChatRoom: null,
    unreadCount: 0,
    roomInfo: null,
    isJobChecked: false,
    category: "all",
    imgOpen: false,
    selectedImg: null,
    images: [],
    currImg: 0,
    isFirstLoading: true,
  };

  scrollRef = null;
  socket = null;

  componentDidMount = () => {
    this.initializeChatEvent();
    const code = window.location.search;
    const parseCode = queryString.parse(code);

    if (this.props.socket) {
      this.props.socket.on("read", (data) => {
        this.handleGetChatList();
      });
      if (
        parseCode.userId &&
        parseCode.userId.length &&
        parseCode.message &&
        parseCode.message.length
      ) {
        this.handleJobMessageOpen(parseCode.userId, parseCode.message);
      }
    } else {
      this.props.history.push("/login");
      this.handleAlert(i18n.t("global.alert.login"));
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  newMsgAlert = () => {
    const input = document.getElementById("new-message-search");
    input.style.border = "1px solid red";
    this.handleAlert(i18n.t("chats.alert.newMsg"), {
      variant: "error",
      autoHideDuration: 2000,
    });
    setTimeout(() => {
      input.style.border = "1px solid #efefef";
      input.style.borderBottom = "none";
      input.style.borderTop = "none";
    }, 2000);
  };
  handleJobMessageOpen = async (userId, messageInput) => {
    const token = GetUserToken();
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/info?id=${userId}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    this.props.history.replace("/chats");
    this.setState(
      { selectedUser: response.data.data, messageInput, isJobChecked: true },
      this.handleOpenNewChatRoom
    );
  };

  initializeChatEvent = async () => {
    const cookies = new Cookies();
    const firebaseToken = cookies.get("firebaseToken");

    if (firebaseToken) {
      const chats = await this.handleGetChatList(0);

      const code = window.location.search;
      const parseCode = queryString.parse(code);

      firebaseChat.database
        .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
        .on("child_added", () => this.handleGetChatList(1));
      firebaseChat.database
        .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
        .on("child_changed", () => this.handleGetChatList(2));
      chats.map((item) => {
        if (parseCode && parseCode.id) {
          if (item.roomId === parseCode.id) {
            this.handleOpenChatRoom(item);
            this.props.history.replace("/chats");
          }
        }
        firebaseChat.database
          .ref(
            "UserRooms/" + firebaseChat.auth.currentUser.uid + "/" + item.roomId
          )
          .on("child_added", () => this.handleGetChatList(3));
        firebaseChat.database
          .ref(
            "UserRooms/" + firebaseChat.auth.currentUser.uid + "/" + item.roomId
          )
          .on("child_changed", () => this.handleGetChatList(4));
      });
    }
  };

  handleGetChatList = async (snapShot) => {
    const chats = await firebaseChat.handleGetRoomList();

    if (chats && chats.length) {
      let selectedRoom = null;
      if (this.state.selectedRoom) {
        selectedRoom = chats.find(
          (item) => item.roomId === this.state.selectedRoom.roomId
        );
      }

      this.setState({ chats, selectedRoom, isFirstLoading: false });
    } else {
      this.setState({ chats: [], isFirstLoading: false });
    }

    return chats;
  };
  newFileSend = (e) => {
    if (!this.state.selectedUser) {
      this.newMsgAlert();
    } else {
      this.handleOpenNewChatRoom(e.target.files);
    }
  };
  sendMessage = (file) => {
    if (file) {
      firebaseChat.uploadFiles(file, this.state.selectedRoom, this.cbProgress);
      this.handleGetChatList();
    } else if (this.state.messageInput && this.state.messageInput.length) {
      firebaseChat.sendMessage(
        this.state.messageInput,
        this.state.selectedRoom,
        false
      );
      this.setState({ messageInput: "" });
      this.handleGetChatList();
    }
  };
  onChangeFile = (e) => {
    firebaseChat.uploadFiles(
      e.target.files,
      this.state.selectedRoom,
      this.cbProgress
    );
  };

  handleOpenNewChatRoom = async (file) => {
    this.time = null;
    if (!this.state.selectedUser) {
      this.newMsgAlert();
      return;
    }

    const userInfo = this.state.selectedUser;
    const checkChatExist = this.checkChatExists([
      userInfo._id,
      firebaseChat.auth.currentUser.uid,
    ]);

    this.setState({ message: [] }, () => {
      if (!checkChatExist) {
        const selectedRoom = firebaseChat.handleOpenNewRoom(
          userInfo,
          this.state.isJobChecked
        );

        firebaseChat.messageRef.on("child_added", (snapShot) =>
          this.handleGetMessage(snapShot, selectedRoom.roomId)
        );

        this.setState(
          { selectedRoom, isJobChecked: false, selectedUser: null },
          () => this.sendMessage(file)
        );
      } else {
        const selectedRoom = this.state.chats.find(
          (item) => item.roomId === checkChatExist
        );

        // firebaseChat.getMessages(selectedRoom.roomId);

        this.setState(
          {
            isJobChecked: false,
            selectedUser: null,
          },
          () => {
            this.handleOpenChatRoom(selectedRoom, file);
          }
        );
      }
    });
  };

  handleOpenChatRoom = async (selectedRoom, file) => {
    this.time = null;
    if (
      !this.state.selectedRoom ||
      selectedRoom.roomId !== this.state.selectedRoom.roomId
    ) {
      // this.setState({ messages: [] }, () => {
      // });
      firebaseChat.handleRead(selectedRoom.roomId);
      firebaseChat.handleOpenRoom(selectedRoom);

      // const messageRef = await firebaseChat.messageRef
      //   .limitToLast(50)
      //   .once("value");

      const messages = [];

      // messageRef.forEach((data) => {
      //   messages.push({
      //     ...data.val(),
      //     isDateFirst: this.timeSeparator(new Date(data.val().timestamp)),
      //   });
      // });
      firebaseChat.messageRef.on("child_added", (snapShot) =>
        this.handleGetMessage(snapShot, selectedRoom.roomId)
      );
      this.setState({ selectedRoom }, () => {
        if (this.state.messageInput) {
          this.sendMessage();
        } else if (file) {
          this.sendMessage(file);
        }
      });
    }
  };

  checkChatExists = (userIdList) => {
    userIdList = userIdList.sort();
    const chats = this.state.chats.find((item) => {
      item = item.users.map((data) => data._id).sort();
      if (JSON.stringify(item) === JSON.stringify(userIdList)) {
        return true;
      } else {
        return false;
      }
    });

    if (chats) {
      return chats.roomId;
    } else {
      return false;
    }
  };

  handleGetMessage = (snapShot, roomId) => {
    var val = snapShot.val();

    const messages = this.state.messages;
    messages.push({
      key: snapShot.key,
      uid: val.uid,
      profileImg: val.profileImg,
      userName: val.userName,
      timestamp: new Date(val.timestamp),
      isImage: val.isImage,
      message: val.message,
      isDateFirst: this.timeSeparator(new Date(val.timestamp)),
    });

    firebaseChat.handleRead(roomId);
    this.handleGetChatList();

    if (this.isScrollPositionEnd()) {
      this.setState({ messages }, () => {
        this.getMessageImages();
        if (this.scrollRef) {
          this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
        }
      });
    } else {
      this.setState(
        {
          messages,
        },
        () => {
          this.getMessageImages();
        }
      );
    }
  };
  getMessageImages = () => {
    let images = [];
    this.state.messages
      .filter((item) => item.isImage)
      .map((item) => {
        images.push({ src: item.message });
      });

    this.setState({ images });
  };

  time = null;
  timeSeparator = (date) => {
    if (this.time === null) {
      this.time = date.toISOString().substring(0, 10);

      return true;
    }
    if (this.time !== date.toISOString().substring(0, 10)) {
      let time = date.toISOString().substring(0, 10);
      this.time = time;

      return true;
    } else {
      return false;
    }
    // return date.toISOString().substring(0, 10);
  };

  cbProgress = (snapshot) => {
    return (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  };

  handleCloseChatRoom = () => {
    if (firebaseChat.messageRef) {
      firebaseChat.messageRef.off();
    }
    this.setState({ messages: [], selectedRoom: null });
  };

  isScrollPositionEnd = () => {
    if (this.scrollRef) {
      let scrollLocation =
        this.scrollRef.scrollTop + this.scrollRef.clientHeight;
      let fullHeight = this.scrollRef.scrollHeight;

      if (scrollLocation >= fullHeight) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  scrollPositionCheck = () => {
    if (this.scrollRef) {
      let scrollLocation =
        this.scrollRef.scrollTop + this.scrollRef.clientHeight;
      let fullHeight = this.scrollRef.scrollHeight;

      if (scrollLocation >= fullHeight) {
        this.setState({ unreadCount: 0 });
      }
    } else {
      this.setState({ unreadCount: 0 });
    }
  };

  renderMessageTime = (date) => {
    const time = new Date(date);

    return `${
      time.getHours() < 10 ? "0" + time.getHours() : time.getHours()
    } : ${
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()
    } ${time.getHours() > 11 ? "PM" : "AM"}`;
  };

  resize = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage();
    } else {
      e.target.style.height = "1px";
      e.target.style.height = 2 + e.target.scrollHeight + "px";
    }
  };
  newInputResize = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (this.state.selectedUser) {
        e.preventDefault();
        this.handleOpenNewChatRoom();
      } else if (e._reactName !== "onKeyUp") {
        this.newMsgAlert();
      }
    } else {
      e.target.style.height = "1px";
      e.target.style.height = 2 + e.target.scrollHeight + "px";
    }
  };

  selectedCheck = (item) => {
    if (this.state.selectedChatRoom) {
      if (this.state.selectedChatRoom._id === item._id) {
        return true;
      }
    } else {
      return false;
    }
  };

  searchUsers = async (e) => {
    if (e.target.value && e.target.value.length) {
      const token = GetUserToken();
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/chat-list?searchString=${e.target.value}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      this.setState({ userList: response.data.data });
    }
  };

  handleSelectedRoom = (item) => {
    this.time = null;

    this.setState({
      chats: this.state.chats.map((data) => {
        if (data.roomId === item.roomId) {
          data.checked = !data.checked;
        }
        return data;
      }),
    });
  };

  isCheckedAll = () => {
    if (this.state.chats && this.state.chats.length) {
      if (this.state.chats.find((item) => !item.checked)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  isChecked = () => {
    if (this.state.chats && this.state.chats.length) {
      if (this.state.chats.find((item) => item.checked)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  handleCheckAll = () => {
    if (this.isCheckedAll()) {
      this.setState({
        chats: this.state.chats.map((item) => {
          item.checked = false;
          return item;
        }),
      });
    } else {
      this.setState({
        chats: this.state.chats.map((item) => {
          item.checked = true;
          return item;
        }),
      });
    }
  };

  handleQuit = () => {
    const chats = this.state.chats.filter((item) => item.checked);
    for (let i = 0; i < chats.length; i++) {
      firebaseChat.handleQuit(chats[i].roomId);
    }
    this.handleGetChatList();
  };

  cancelJobOffer = () => {
    firebaseChat.cancelJobOffer(this.state.selectedRoom);
    this.handleGetChatList();
  };

  isJobOffer = () => {
    for (let i = 0; i < this.state.selectedRoom.users.length; i++) {
      if (
        this.state.selectedRoom.users[i]._id ===
          firebaseChat.auth.currentUser.uid &&
        this.state.selectedRoom.users[i].isJobOffer
      ) {
        return true;
      }
    }
    return false;
  };
  isChatsJobOffer = (chats) => {
    for (let i = 0; i < chats.users.length; i++) {
      if (
        chats.users[i]._id === firebaseChat.auth.currentUser.uid &&
        chats.users[i].isJobOffer
      ) {
        return true;
      }
    }
    return false;
  };
  handleMsgInput = (e) => {
    this.setState({ messageInput: e.target.value });
  };

  handleSelectCategory = (category) => {
    if (category === "job") {
      this.setState({
        chats: this.state.chats.filter((item) => item.isJob),
        category,
      });
    } else {
      this.setState({ category }, () => this.handleGetChatList());
    }
  };
  handleDeleteUser = () => {
    this.setState({ selectedUser: null });
  };
  renderNewMessageSend = () => {
    if (!this.state.selectedRoom) {
      return (
        <div className="new-message-container">
          <div className="new-message-userSearch" id="new-message-search">
            <span>{i18n.t("chat.receiver")}</span>
            {!this.state.selectedUser ? (
              <>
                <input
                  type="text"
                  onFocus={() => this.setState({ isSearching: true })}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isSearching: true });
                  }}
                  onChange={this.searchUsers}
                />
                {this.renderSearch()}
              </>
            ) : (
              <div className="selected_user-box">
                <span>{this.state.selectedUser.userName}</span>
                <img
                  src={UserDeleteIcon}
                  onClick={this.handleDeleteUser}
                  alt="removeIcon"
                />
              </div>
            )}
          </div>
          <div className="new-message-textarea">
            <div
            //   onChange={(e) => this.setState({ messageInput: e.target.value })}
            //   type="text"
            //   placeholder="Message"
            />
          </div>
          <div className="messageInput">
            <div className="new-message-isJob">
              <input
                type="checkbox"
                onChange={(e) =>
                  this.setState({ isJobChecked: e.target.checked })
                }
              />
              <span>{i18n.t("chats.job.msg")}</span>
            </div>
            <div className="messageInput-input">
              <div className="message-functions">
                <div className="function-btn">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.newFileSend}
                  />
                  <img src={imageSendIcon} alt="imageSendIcon" />
                </div>
                {/* <div className="function-btn">
                <img src={videoSendIcon} alt="videoSendIcon" />
              </div>
              <div className="function-btn">
                <img src={imojiSendIcon} alt="imojiSendIcon" />
              </div> */}
              </div>

              <textarea
                type="text"
                onChange={this.handleMsgInput}
                onKeyDown={this.newInputResize}
                onKeyUp={this.newInputResize}
                value={this.state.messageInput}
                placeholder="Write a comment now....."
              />
              <div
                className="message-submit"
                onClick={() => this.handleOpenNewChatRoom()}
              >
                <img src={submitIcon} alt="submitIcon" />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  handleNext = () => {
    this.setState({ currImg: this.state.currImg + 1 });
  };
  handlePrev = () => {
    this.setState({ currImg: this.state.currImg - 1 });
  };
  handleImgOpen = (src) => {
    const images = this.state.images;

    let index = images.findIndex((item) => item.src === src);

    this.setState({ selectedImg: src, currImg: index, imgOpen: true });
  };
  renderMessageDate = (date) => {
    const time = new Date(date);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    const fullDate = `${year}년 ${month < 10 ? "0" + month : month}월 ${
      day < 10 ? "0" + day : day
    }일`;

    return fullDate;
  };
  handleGoToUserPage = async (id) => {
    try {
      const userInfo = await GetUserInfo(id);

      this.props.history.push(`/@${userInfo.linkURL}`);
    } catch (e) {
      console.log(e);
    }
  };
  redenOpenChatRoom = () => {
    if (this.state.selectedRoom) {
      return (
        <div className="message-list-container">
          <div
            className="message-list"
            onScroll={this.scrollPositionCheck}
            ref={(ref) => {
              this.scrollRef = ref;
            }}
          >
            {this.state.messages.map((item, index) => {
              return (
                <>
                  {item.isDateFirst && (
                    <div className="date-separator">
                      <div>{this.renderMessageDate(item.timestamp)}</div>
                    </div>
                  )}
                  <div
                    className={
                      item.uid === firebaseChat.auth.currentUser.uid
                        ? "message-column myMessage"
                        : "message-column"
                    }
                    key={item.uid}
                    // id={this.timeSeparator(item.timestamp)}
                  >
                    <div
                      className="message-column-photo"
                      onClick={() => this.handleGoToUserPage(item.uid)}
                    >
                      <ProfilePhoto src={item.profileImg} size={55} />
                    </div>
                    <div className="messege-column-message">
                      <div
                        className="messege-column-info"
                        onClick={() => this.handleGoToUserPage(item.uid)}
                      >
                        <span>{item.userName}</span>
                      </div>
                      <div className="message-text">
                        {item.isImage ? (
                          <>
                            <img
                              onClick={() => this.handleImgOpen(item.message)}
                              className="messege-column-image"
                              src={item.message}
                              alt="messageImage"
                            />
                          </>
                        ) : (
                          <span className="messege-column-text">
                            {item.message}
                          </span>
                        )}
                        <span className="message-time">
                          {this.renderMessageTime(item.timestamp)}
                        </span>
                        {item.readCount > 0 ? (
                          <span className="messege-readcount">
                            {item.readCount}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {this.state.images[0] && this.state.images.length && (
              <ImgsViewer
                // srcSet={this.state.selectedImg}
                currImg={this.state.currImg}
                onClickNext={this.handleNext}
                onClickPrev={this.handlePrev}
                imgs={this.state.images}
                backdropCloseable={true}
                onClose={() =>
                  this.setState({
                    currImg: 0,
                    selectedImg: null,
                    imgOpen: false,
                  })
                }
                imgCountSeparator="/"
                isOpen={this.state.imgOpen}
              />
            )}
          </div>
          <div className="messageInput">
            {this.state.unreadCount > 0 ? (
              <div
                className="unread-notice"
                onClick={() => {
                  this.setState({ unreadCount: 0 }, () => {
                    if (this.scrollRef) {
                      this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
                    }
                  });
                }}
              >
                <span>
                  {this.state.unreadCount}개의 읽지않은 메세지가 있습니다.
                </span>
              </div>
            ) : null}
            <div className="message-functions">
              <div className="function-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onChangeFile}
                />
                <img src={imageSendIcon} alt="imageSendIcon" />
              </div>
              {/* <div className="function-btn">
                <img src={videoSendIcon} alt="videoSendIcon" />
              </div>
              <div className="function-btn">
                <img src={imojiSendIcon} alt="imojiSendIcon" />
              </div> */}
            </div>
            <div className="messageInput-input">
              <textarea
                // type="text"
                onChange={this.handleMsgInput}
                onKeyDown={this.resize}
                onKeyUp={this.resize}
                value={this.state.messageInput}
                placeholder="Write a comment now....."
              />
              <div
                className="message-submit"
                onClick={() => this.sendMessage()}
              >
                <img src={submitIcon} alt="submitIcon" />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderSearch = () => {
    if (this.state.isSearching) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ isSearching: false })}
        >
          <div className="search_container boxshadow">
            <div className="seach_box">
              {this.state.userList && this.state.userList.length ? (
                this.state.userList.map((item, index) => {
                  return (
                    <div
                      className="search-column"
                      onClick={() => this.setState({ selectedUser: item })}
                      key={index}
                    >
                      <ProfilePhoto src={item?.profilePhoto} size={50} />
                      <div className="seach-column-userInfo">
                        <span className="seach-column-userName">
                          {item.userName}
                        </span>
                        <span className="seach-column-job">
                          @{item.linkURL}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="search-empty">
                  <span>검색 결과가 없습니다. 😢</span>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  };

  render() {
    return (
      <div className="chat-container">
        <div className="chats-container">
          <div className="chats_list-container">
            <div className="chats_list-title">
              <span>{i18n.t("chat.title")}</span>
              <div
                className="new-message-btn"
                onClick={this.handleCloseChatRoom}
              >
                <img src={editIcon} alt="newmessage" />
              </div>
            </div>
            <div className="chats-categories">
              <div
                className={
                  this.state.category === "all"
                    ? "chats-category selected"
                    : "chats-category"
                }
                onClick={() => this.handleSelectCategory("all")}
              >
                <span>{i18n.t("chats.tab.all")}</span>
              </div>
              <div
                className={
                  this.state.category === "job"
                    ? "chats-category selected"
                    : "chats-category"
                }
                onClick={() => this.handleSelectCategory("job")}
              >
                <span>{i18n.t("chats.tab.job")}</span>
              </div>
            </div>
            <div className="chats_list-selctor">
              <div className="selectorBox">
                <img
                  src={this.isCheckedAll() ? CheckedBox : UnCheckedBox}
                  onClick={this.handleCheckAll}
                  alt="checkIcon"
                />
                <span>{i18n.t("global.selectAll")}</span>
              </div>
              {this.isChecked() ? (
                <div className="delete-btn" onClick={this.handleQuit}>
                  <img src={DeleteIcon} alt="deleteIcon" />
                </div>
              ) : null}
            </div>

            <div className="chats_list">
              <div className="chats-wrapper">
                {this.state.chats[0] && this.state.chats.length
                  ? this.state.chats.map((item, index) => {
                      const user = item.users.find(
                        (item) => item._id !== firebaseChat.auth.currentUser.uid
                      );
                      return (
                        <div
                          className="chats_list-column"
                          onDoubleClick={() => this.handleOpenChatRoom(item)}
                          key={index}
                        >
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleSelectedRoom(item);
                            }}
                            onDoubleClick={(e) => e.stopPropagation()}
                            src={item.checked ? CheckedBox : UnCheckedBox}
                            alt="checkBtn"
                          />

                          <ProfilePhoto src={user?.profilePhoto} size={50} />
                          <div className="chats_list-chatInfo">
                            <span>{user ? user.userName : null}</span>
                            <span>
                              {item.lastMessage
                                ? item.isLastMessageImage
                                  ? "Image file"
                                  : item.lastMessage
                                : ""}
                            </span>
                          </div>
                          {!item.isReaded ? (
                            <div className="isUnread"></div>
                          ) : null}
                          <div className="chats_list-time">
                            <span>{timeBefore(new Date(item.time))}</span>
                            {item.isJob &&
                              (this.isChatsJobOffer(item) ? (
                                <div className="chats_list-job job-offer">
                                  <img src={checkIcon} alt="" />
                                  <span>{i18n.t("chats.job.collers")}</span>
                                </div>
                              ) : (
                                <div className="chats_list-job">
                                  <img src={groupIcon} alt="" />
                                  <span>{i18n.t("chats.job.recipients")}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    })
                  : !this.state.isFirstLoading && (
                      <div className="chats-empty_wrapper">
                        <img src={mailIcon} alt="chat-empty" />
                        <span>{i18n.t("chats.list.empty")}</span>
                        <span>{i18n.t("chats.list.emptyText")}</span>
                      </div>
                    )}
              </div>
            </div>
          </div>
          <div className="message-container">
            <div className="message-title">
              <div className="message-title-left">
                <span>
                  {this.state.selectedRoom
                    ? this.state.selectedRoom.roomTitle
                    : i18n.t("chat.newMessage")}
                </span>
                {this.state.selectedRoom && this.state.selectedRoom.isJob ? (
                  this.isJobOffer() ? (
                    <div className="message-job job-offer">
                      <img src={checkIcon} alt="" />
                      <span>{i18n.t("chats.job.collers")}</span>
                    </div>
                  ) : (
                    <div className="message-job">
                      <img src={groupIcon} alt="" />
                      <span>{i18n.t("chats.job.recipients")}</span>
                    </div>
                  )
                ) : null}
              </div>
              <div className="message-setting-btn">
                {this.state.selectedRoom &&
                this.state.selectedRoom.isJob &&
                this.isJobOffer() ? (
                  <div
                    className="isJobCancel"
                    onClick={() => this.cancelJobOffer()}
                  >
                    <span>{i18n.t("chats.job.unmark")}</span>
                  </div>
                ) : null}
                {/* <img src={SettingIcon} alt="SettingIcon"></img> */}
              </div>
            </div>
            {this.state.selectedRoom
              ? this.redenOpenChatRoom()
              : this.renderNewMessageSend()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  socket: state.socketStatus.socket,
  isLogined: state.loginStatus.isLogined,
});

const mapDispatchToProps = (dispatch) => ({
  changeSocket: (value) => dispatch(changeSocket(value)),
});

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ChatTest)
);
