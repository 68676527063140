import React, { Component } from "react";
import "./JoinComplete.scss";
import i18n from "../../../i18n";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
class JoinComplete extends Component {
  render() {
    return (
      <div className="container addInfo-container">
        <div className="addInfo-box">
          <div className="join-complete-body">
            <ProfilePhoto src={this.props.teamInfo.teamPhoto} size={100} />
            <span>{i18n.t("team.join.text")}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinComplete;
