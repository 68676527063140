import React, { Component } from "react";
import "./Section1.scss";
import bannerImgDesktop from "../../../src/image/Faq/faq-banner-img@2x.png";
import downArrowIcon from "../../../src/image/Faq/faq-down-arrow-icon.svg";
import upArrowIcon from "../../../src/image/Faq/faq-up-arrow-icon.svg";
import styled from "styled-components";
import { Collapse, Space } from "antd";

const { Panel } = Collapse;

const TitleContainer = styled.div`
  background-image: url(${bannerImgDesktop});
  width: 100vw;
  height: 400px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 30px;
`;

class Section1 extends Component {
  state = {
    faqList: [
      {
        title:
          "What are the criteria for the order of recommendation for the project of Motiontrend?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "Can I download other people's images and videos?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "Can I delete my account?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "What's the Follow function?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "How are copyrights protected on my work?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "Can I download other people's images and videos?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "Can I delete my account?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "What's the Follow function?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
      {
        title: "How are copyrights protected on my work?",
        msg: "Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation. Motiontrend basically protects other people's copyrights and intellectual property rights, making it impossible to copy and capture any media that is irrelevant. If necessary, it is recommended that you contact the publisher directly and receive it under consultation.",
      },
    ],
    activePanel: "",
  };

  callback = (key) => {
    const prev_all_div_class = document.getElementsByClassName(
      "collapse-visibility"
    );
    this.setState({ activePanel: key }); // 현재 활성화 패널 설정 아코디언 모드
    if (!key) {
      // 모듈 이용의 한계로 Border DOM의 동적 Manipulating 을 위해 특정 Div 에 클래스 추가 및 삭제 로직
      for (let i = 0; i < prev_all_div_class.length; i++) {
        prev_all_div_class[i].classList.remove("collapse-visibility");
      }
      return;
    } else if (key >= 1) {
      const prev_div_id = document.querySelector(`#item_` + key).previousSibling
        .id;
      const prev_div_target = document.querySelector(
        "#" + prev_div_id + "> div > div"
      );

      for (let i = 0; i < prev_all_div_class.length; i++) {
        prev_all_div_class[i].classList.remove("collapse-visibility");
      }
      if (prev_div_target) {
        prev_div_target.classList.add("collapse-visibility");
      }
      //   console.log("테스트", document.getElementsByClassName("additional"));
    }
  };

  render() {
    return (
      <>
        <div className="faq-section1-container">
          <TitleContainer>FAQ</TitleContainer>
          <div
            style={{
              padding: "0 30px",
              paddingBottom: "150px",
              paddingTop: "50px",
            }}
          >
            {this.state.faqList.map((item, index) => {
              // FAQ 리스트 출력
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  id={`item_` + index}
                  className="faq-item_Wrapper"
                >
                  <div
                    style={{
                      maxWidth: "1000px",
                      width: "100%",
                    }}
                  >
                    <Collapse
                      className="faq-collapse-container"
                      // defaultActiveKey={[index]}
                      onChange={this.callback}
                      expandIconPosition={"right"}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <img src={upArrowIcon} alt="upArrowIcon" />
                        ) : (
                          <img src={downArrowIcon} alt="downArrowIcon" />
                        )
                      }
                      accordion
                      activeKey={this.state.activePanel}
                    >
                      <Panel
                        header={
                          <Space>
                            <div style={{ marginRight: "13px" }}>Q</div>
                            <div className="bar">{item.title}</div>
                          </Space>
                        }
                        key={index}
                        onClick={() => this.test(index)}
                      >
                        <p>{item.msg}</p>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Section1;
