import React, { Component } from "react";
import "./PreviewDialog.scss";
import closeImg from "../../image/close-white-icon.svg";
import { Dialog } from "@material-ui/core";
import Media from "react-media";
import i18n from "../../i18n";
import OffLikeBtn from "../../image/OffIcon/like-button-icon.svg";
import { UserOutlined, PlusOutlined, CheckOutlined } from "@ant-design/icons";
import OffAddBtn from "../../image/OffIcon/add-button-icon.svg";
import shareBtn from "../../image/job/share-black-icon.svg";
import { Avatar } from "antd";
import LocationIcon from "../../image/location-icon.svg";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import likeIcon from "../../image/like-icon.svg";
import viewsIcon from "../../image/view-icon.svg";
import CommentIcon from "../../image/stateIcon/small-comments-icon.svg";
class PreviewDialog extends Component {
  state = {};

  renderOwnerList() {
    return (
      <div className="ownerInfo-profile">
        <div style={{ display: "flex", alignItems: "center" }}>
          {!this.props.userInfo.profilePhoto ? (
            <Avatar
              className="projectDetailAvatar"
              size={40}
              icon={<UserOutlined style={{ fontSize: "15px" }} />}
            />
          ) : (
            <img
              className="profilePhotoBox"
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
                border: "1px solid #efefef",
              }}
              src={this.props.userInfo.profilePhoto}
              alt="profilePhoto"
            ></img>
          )}
          <div className="owner-info">
            <span>
              {!this.props.userInfo.userName
                ? null
                : this.props.userInfo.userName}
            </span>
            <div className="profile-location">
              <img src={LocationIcon} alt="location" />
              <span>
                {this.props.userInfo.countryInfo
                  ? this.props.userInfo.countryInfo.countryName
                  : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="profile-title">
          <button className="follow-sm">
            {i18n.t("mypage.profile.follow")}
          </button>
        </div>
      </div>
    );
  }
  render() {
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthXl: {
            minWidth: 250,
            width: "80vw",
            maxWidth: 1500,
            height: "100%",
            zIndex: 9999,
          },
          container: {
            // height: "unset",
          },
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });
    const { onClose, open, userInfo, tags, projInfo, title } = this.props;

    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            tablet: "(min-width: 800px)",
          }}
        >
          {(matches) => (
            <ThemeProvider theme={theme}>
              <Dialog
                className="project"
                fullWidth={this.props.fullWidth}
                maxWidth={"xl"}
                open={open}
                onClose={onClose}
              >
                <div
                  className={`${matches.tablet ? "closeBtn" : "m_closeBtn"}`}
                  onClick={onClose}
                >
                  <img src={closeImg} alt="close" />
                </div>

                <div className="one-button-dialog-container proj">
                  <div
                    className="actionBtn"
                    style={{ dislay: "flex", alignItems: "center" }}
                  >
                    <div
                      className="checkedBtn"
                      style={{ position: "relative" }}
                    >
                      <>
                        {!userInfo.profilePhoto ? (
                          <div
                            style={{
                              borderRadius: "50%",
                              width: "45px",
                              height: "45px",
                              outline: "unset",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <UserOutlined
                              style={{ fontSize: "15px", color: "black" }}
                            />
                          </div>
                        ) : (
                          <img
                            style={{
                              // backgroundRepeat: "no-repeat",
                              width: "45px",
                              height: "45px",
                              outline: "unset",
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid #efefef",
                            }}
                            src={userInfo.profilePhoto}
                            alt="profilePhoto"
                          ></img>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            right: "0",
                            backgroundColor: "#ff2e43",
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {this.state.isFollow ? (
                            <CheckOutlined
                              style={{ fontSize: "8px", color: "black" }}
                            />
                          ) : (
                            <PlusOutlined
                              style={{ fontSize: "10px", color: "white" }}
                            />
                          )}
                        </div>
                      </>
                    </div>
                    {/* ) : null} */}
                    <span className="avatarTitle">
                      {this.state.isFollow
                        ? i18n.t("mypage.profile.following")
                        : i18n.t("mypage.profile.follow")}
                    </span>

                    <div className={"heartIcon"}>
                      <img
                        src={OffLikeBtn}
                        // className={
                        //   this.state.isLoading & this.state.isLike
                        //     ? "likeIcon-animate"
                        //     : null
                        // }
                        alt="likeButton"
                      />
                    </div>
                    <span>{i18n.t("project.like")} </span>
                    <div onClick={this.handleCollectioDialogOpen}>
                      <img
                        src={OffAddBtn}
                        style={{ marginBottom: "5px" }}
                        alt="addbtn"
                      />
                    </div>
                    <span>{i18n.t("global.save")}</span>
                    <div onClick={this.handleShareDialogOpen}>
                      <img src={shareBtn} alt="sharebtn" />
                    </div>
                    <span>{i18n.t("project.selector.share")}</span>
                  </div>
                  <div className="projDialog-container">
                    {matches.tablet && (
                      <div className="proj-header">
                        <div
                          className="header-profile"
                          onClick={this.handleGoToUserPage}
                        >
                          {!userInfo.profilePhoto ? (
                            <Avatar size={50} icon={<UserOutlined />} />
                          ) : (
                            <div
                              style={{
                                borderRadius: "50%",
                                backgroundImage: `url(${userInfo.profilePhoto})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                width: "50px",
                                height: "50px",
                              }}
                            ></div>
                          )}
                        </div>
                        <div className={"header-titleBox"}>
                          <div>
                            <div className="titleBox-header">
                              <span>{title}</span>
                            </div>
                            <div
                              className="titleBox-ownersName"
                              style={{
                                marginBottom: null,
                              }}
                            >
                              <div className="creators-box">
                                <span className="creatorName">
                                  {userInfo.userName}
                                </span>
                              </div>
                              {/* <span
                              className="project-category"
                             
                            >
                              {projInfo.category
                                ? this.props.lang === "en"
                                  ? projInfo.category.name.en
                                  : projInfo.category.name.ko
                                : null}
                            </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {matches.mobile && (
                      <>
                        <div className="proj-header">
                          <div className="header-titleBox">
                            <div className="titleBox-header">
                              <span>{title}</span>
                            </div>
                            {/* <span className="project-category">
                              {this.props.lang === "en"
                                ? projInfo.category.name.en
                                : projInfo.category.name.ko}
                            </span> */}
                            <div className="titleBox-ownersName">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="header-profile">
                                  {!userInfo.profilePhoto ? (
                                    <Avatar size={30} icon={<UserOutlined />} />
                                  ) : (
                                    <div
                                      style={{
                                        borderRadius: "50%",
                                        backgroundImage: `url(${userInfo.profilePhoto})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></div>
                                  )}
                                </div>

                                <span className="creatorName">
                                  {userInfo.userName}
                                </span>
                              </div>
                              <button className="m-followBtn">
                                {i18n.t("project.follow")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="proj-container">
                      <div className="proj-updateImg">
                        {projInfo
                          ? projInfo.map((data) => {
                              return (
                                <>
                                  <div className="updateList">
                                    {data.type === "image" ? (
                                      <img
                                        src={data.item.file || data.item.data}
                                        alt="img"
                                      />
                                    ) : data.type === "audio" ? (
                                      <audio
                                        style={{
                                          outline: "none",
                                          width: "100%",
                                          padding: "0 30px",
                                        }}
                                        controlsList="nodownload"
                                        src={data.item.data}
                                        controls="controls"
                                      ></audio>
                                    ) : data.type === "text" ? (
                                      <div
                                        className="textBox ql-editor"
                                        dangerouslySetInnerHTML={{
                                          __html: data.item.text,
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        className="iframeBox"
                                        dangerouslySetInnerHTML={{
                                          __html: data.item.src,
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </>
                              );
                            })
                          : null}
                      </div>

                      {/* <div className="tagsList">
                        <span># {i18n.t("project.tagsTitle")}</span>
                        <div className="ownerInfo-interset">
                          {tags
                            ? tags.map((item) => {
                                return (
                                  <span style={{ cursor: "unset" }}>
                                    {item}
                                  </span>
                                );
                              })
                            : null}
                        </div>
                      </div> */}
                    </div>
                    <div className="proj-footerContainer">
                      <div className="proj-comments">
                        <div className="comments-input-container">
                          <span>
                            {i18n.language === "en" ? "Comments" : "댓글"}
                          </span>

                          <div className="comments-inputBox">
                            <div className="comments-click">
                              <span>
                                {i18n.language === "en"
                                  ? "write a comment now..."
                                  : "댓글을 작성해주세요"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="comments-list"></div>
                      </div>
                      {matches.tablet && (
                        <div className="proj-ownerInfo">
                          <div className="ownerInfo-profileBox">
                            <span>{i18n.t("project.owner")}</span>
                            {this.renderOwnerList()}
                          </div>
                          <div className="ownerInfo-contentInfo">
                            <div className="contentInfo-subject">
                              <span>{title}</span>
                            </div>
                            <div className="contentInfo-icons">
                              <img id="like" src={likeIcon} alt="like" />
                              <span>0</span>
                              <img id="views" src={viewsIcon} alt="view" />
                              <span>0</span>
                              <img src={CommentIcon} alt="comment" />
                              <span>0</span>
                            </div>
                            <div className="contentInfo-date">
                              <span>
                                {`${i18n.t("admin.projects.publishDate")} : `}
                                {new Date().toISOString().substring(0, 10)}
                              </span>
                            </div>
                          </div>
                          {/* <div className="ownerInfo-creativeTool">
                          <span>Creative field</span>
                         
                        </div> */}
                          {tags[0] ? (
                            <div className="ownerInfo-interset">
                              {tags.map((item) => {
                                return (
                                  <>
                                    <span
                                      onClick={() => this.handleSearchTag(item)}
                                    >
                                      {item}
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          ) : null}

                          {/* <div className="ownerInfo-reportBtn">
                            {projInfo.license === "All Rights Reserved" ? (
                              <Tooltip
                                placement="topLeft"
                                color="white"
                                overlayClassName="featureTooltipContainer"
                                title={
                                  <>
                                    <div className="flagTooltipFeature">
                                      저작권 정보
                                    </div>
                                    <div className="flagTooltipFeatureContent">
                                      <span className="falgToolText">
                                        소유자의 명시적인 허용 없이는 사용할 수
                                        없습니다.
                                      </span>
                                    </div>
                                  </>
                                }
                              >
                                <span>
                                  <span>Copyright </span>
                                  &copy;
                                  {` ${projInfo.uploaderInfo.userName} ${projInfo.license}`}
                                </span>
                              </Tooltip>
                            ) : (
                              <div className="licese_wrapper">
                                {this.renderLicense(projInfo.license)}
                              </div>
                            )}
                            {this.ownersProjCheck() ? null : (
                              <div
                                className="report_btn"
                                onClick={() =>
                                  this.handleReportDialogOepn(projInfo._id)
                                }
                              >
                                <img src={ReportIcon} alt="report" />
                                {i18n.t("project.selector.report")}
                              </div>
                            )}
                          </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog>
            </ThemeProvider>
          )}
        </Media>
      </>
    );
  }
}

export default PreviewDialog;
