import React, { Component } from "react";
import "./ContentDialog.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class ContentDialog extends Component {
  state = {};
  render() {
    const { onClose, open } = this.props;
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthFalse: {
            maxWidth: "450px !important",
            width: "100%",
          },
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            maxWidth={false}
            className={""}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </div>
            <div className="term-button-dialog-container content">
              <div className="terms_container">
                <div className="terms_header">
                  <span>{this.props.title}</span>
                </div>
                <div
                  className="terms_title"
                  style={{
                    overflowY: "unset",
                    marginBottom: "unset",
                  }}
                >
                  {this.props.msg ? <p>{this.props.msg}</p> : null}
                  {this.props.multiMsg
                    ? this.props.multiMsg.map((item) => {
                        return (
                          <React.Fragment key={`${item.title}_${item.msg}`}>
                            <div
                              style={{
                                fontFamily: "Montserrat-Medium",
                                fontsize: "30px",
                                fontWeight: 600,
                                marginBottom: "5px",
                              }}
                            >
                              {item.title}
                            </div>
                            <p>{item.msg}</p>
                          </React.Fragment>
                        );
                      })
                    : null}
                </div>

                <div className="termsBtn">
                  <button onClick={onClose} type="button">
                    {i18n.t("global.ok")}
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(ContentDialog);
