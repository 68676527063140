import React, { Component } from "react";
import "./Account.scss";
import i18n from "../../i18n";
import { TeamLinkExistCheck } from "../../Api/Team/Team";

class Account extends Component {
  state = {
    linkUrl: "",
    mag: "",
    urlAvailable: false,
    btnActive: false,
  };

  componentDidMount = async () => {
    try {
      const linkUrl = this.props.teamInfo.linkURL;
      if (linkUrl) {
        this.setState({
          linkUrl: this.props.teamInfo.linkURL,
        });
      }
    } catch (e) {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.teamInfo.linkURL !== this.props.teamInfo.linkURL) {
      this.setState({
        linkUrl: this.props.teamInfo.linkURL,
      });
    }
  }

  handleUrlOverlapCheck = async () => {
    const linkURL = this.state.linkUrl;
    this.setState({ btnActive: false });
    if (linkURL.length === 0) {
      this.setState({
        msg: i18n.t("teamSetting.requiredUrl"),
        urlAvailable: false,
      });
      return;
    } else if (this.state.linkUrl === this.props.teamInfo.originLinkURL) {
      this.setState(
        {
          msg: i18n.t("teamSetting.yourUrl"),
          urlAvailable: true,
        },
        () => this.props.handleUserLinkSet(this.state)
      );
      return;
    } else {
      try {
        const response = await TeamLinkExistCheck(linkURL);

        if (response.data === false) {
          this.setState({
            msg: i18n.t("all.signup.Available"),
            urlAvailable: true,
          });
        } else if (response.data === true) {
          this.setState({
            msg: i18n.t("teamSetting.usedUrl"),
            urlAvailable: false,
          });
        }
      } catch (e) {
        this.setState({
          msg: i18n.t("global.error.server.title"),
          urlAvailable: false,
        });
      }
    }
    this.setState({ btnActive: false }, () =>
      this.props.handleUserLinkSet(this.state)
    );
  };
  handleUrlChange = (e) => {
    const regType1 = /^[a-z0-9+]*$/; // 알파벳과 숫자만 있는지 확인하는 정규식
    const regType2 = /\s/g; //공백 확인 정규식
    this.setState({ btnActive: false });
    if (!e.target.value || e.target.value.length === 0) {
      this.setState(
        {
          linkUrl: e.target.value,
          msg: i18n.t("teamSetting.requiredUrl"),
          btnActive: false,
        },
        () => this.props.handleUserLinkSet(this.state)
      );
    } else if (!regType1.test(e.target.value)) {
      this.setState(
        {
          linkUrl: e.target.value,
          msg: i18n.t("teamSetting.regexCheck1"),
          btnActive: false,
        },
        () => this.props.handleUserLinkSet(this.state)
      );
    } else if (regType2.test(e.target.value)) {
      this.setState(
        {
          linkUrl: e.target.value,
          msg: i18n.t("teamSetting.regexCheck2"),
          btnActive: false,
        },
        () => this.props.handleUserLinkSet(this.state)
      );
    } else {
      this.setState({ linkUrl: e.target.value, btnActive: true }, () =>
        this.setState(
          {
            msg: i18n.t("teamSetting.regexCheck3"),
            urlAvailable: false,
          },
          () => this.props.handleUserLinkSet(this.state)
        )
      );
    }
  };
  render() {
    return (
      <div className="AccountContainer">
        <div className="AccountMain">
          <div className="Title">{i18n.t("teamSetting.account.title")}</div>
          {/* <div className="InputBox Name">
            <div className="InputTilte">Edit your Nickname</div>
            <div className="InputBtnBox">
              <input className="InputField" placeholder="Edit your Nickname" />
              <div className="CheckBtn">Check</div>
            </div>
          </div> */}
          <div className="InputBox Address">
            <div className="InputTilte urlInputTitle">
              {i18n.t("teamSetting.account.url.title")}
              <div>{this.state.msg}</div>
            </div>
            <div className="InputBtnBox ">
              <div className="MainURL">www.motiontrend.tv/team/</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #efefef",
                  flex: "0.5",
                  height: "40px",
                  marginRight: "5px",
                }}
              >
                <div
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                  }}
                >
                  @
                </div>
                <input
                  className="InputField"
                  onKeyUp={this.handleUrlChange}
                  placeholder={i18n.t("teamSetting.account.url.title")}
                  defaultValue={this.state.linkUrl}
                />
              </div>
              <div
                className={
                  this.state.btnActive ? "CheckBtn" : "disabledCheckBtn"
                }
                onClick={
                  this.state.btnActive ? this.handleUrlOverlapCheck : null
                }
              >
                {i18n.t("global.check")}
              </div>
            </div>
          </div>
          {/* <div className='CheckBox'>
                        <img className='CheckIcon' src={checkicon} ait='이미지'/>
                        <div className='CheckText'>
                            Blocking adult content in motiontrend posts
                        </div>
                    </div>
                    <div className='CheckBox'>
                        <img className='CheckIcon' src={checkicon} ait='이미지'/>
                        <div className='CheckText'>
                            Block receiving recommendation notifications based on My Project View and Assessment
                        </div>
                    </div>
                    <div className='CheckBox'>
                        <img className='CheckIcon' src={checkicon} ait='이미지'/>
                        <div className='CheckText'>
                            Do not display EXIF data on my image
                        </div>
                    </div> */}
        </div>
      </div>
    );
  }
}

export default Account;
