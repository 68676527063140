// 여기서 흔히 컴포넌트라면 스테이트를 선언해주는데 특이한 게 있다면
// 데이터가 하나의 함수이자 스테이트임.
// 함수의 이름 = 스테이트 이름

import { combineReducers } from "redux";
import {
  LANG,
  GET_MY_INFO,
  LOGIN_STATUS,
  TYPE_STATUS,
  SEARCH_LIST,
  SET_IS_OUT,
  PROJECT_EDIT,
  SOCKET_STATUS,
} from "../action";

import i18n from "../../i18n";
import { GetUserToken } from "../../modules/userAuth";

let lang = i18n.language;
switch (lang.toLowerCase()) {
  case "ko":
  case "ko-kr":
    lang = "ko";
    break;

  case "en":
  case "en-us":
  default:
    lang = "en";
    break;
}

const languageValue = { lang: lang };
const language = (state = languageValue, action) => {
  // 기본 형태는 es6 화살표 함수인데 매개변수로 설정하는게 곧 스테이트임.
  // 정확히 말 하면 language라는 함수의 스테이트를 선언해주는건데 꺼내 쓸 땐 함수 이름으로 꺼내쓰니까 그냥 하나라고 이해하면 편할듯.

  // 매개변수로는 스테이트와 action을 받음.
  // 여기서 액션이 방금 폴더에서 정의했던 그 action임
  switch (action.type) {
    case LANG:
      return {
        ...state,
        lang: action.value,
      };
    default:
      return state;
  }
};

const myInfo = (
  state = {
    createdAt: null,
    email: null,
    nickname: null,
    terms_agreement: false,
    type: null,
    _id: null,
  },
  action
) => {
  switch (action.type) {
    case GET_MY_INFO:
      return {
        ...state,
        myInfo: action.value,
      };
    default:
      return state;
  }
};

const token = GetUserToken();
let logged;
if (token) logged = true;
else logged = false;

// 초기상태 정의
const loginInitialState = {
  isLogined: logged,
};

// 리듀서 작성
export const loginStatus = (state = loginInitialState, action) => {
  switch (action.type) {
    case LOGIN_STATUS:
      return {
        ...state,
        isLogined: action.value,
      };
    default:
      return state;
  }
};
export const searchKeyword = (state = { searchTitle: "" }, action) => {
  switch (action.type) {
    case SEARCH_LIST:
      return {
        ...state,
        searchTitle: action.value,
      };
    default:
      return state;
  }
};

export const typeStatus = (state = { type: "local" }, action) => {
  switch (action.type) {
    case TYPE_STATUS:
      return {
        ...state,
        type: action.value,
      };
    default:
      return state;
  }
};

const isOut = (state = { isOut: false }, action) => {
  switch (action.type) {
    case SET_IS_OUT:
      return {
        ...state,
        isOut: action.value,
      };
    default:
      return state;
  }
};

export const projectEditStatus = (state = { project: {} }, action) => {
  switch (action.type) {
    case PROJECT_EDIT:
      return {
        ...state,
        project: action.value,
      };
    default:
      return state;
  }
};

export const socketStatus = (state = { socket: null }, action) => {
  switch (action.type) {
    case SOCKET_STATUS:
      return {
        ...state,
        socket: action.value,
      };
    default:
      return state;
  }
};

const MotionTrend = combineReducers({
  myInfo,
  searchKeyword,
  language,
  loginStatus,
  typeStatus,
  isOut,
  projectEditStatus,
  socketStatus,
});

export default MotionTrend;
