import React, { Component } from "react";
import "./CurationList.scss";
import i18n from "../../../../i18n";
import ReportDeleteDialog from "../CurationDialog/ReportDeleteDialog/ReportDeleteDialog";
import Moment from "react-moment";

import "moment/locale/ko";
import { Table, Tag, Space, Button, Dropdown, Menu, DatePicker } from "antd";
import CurationDialog from "../CurationDialog/CurationDialog";
import CompleteDialog from "../../../../Component/CompleteDialog/CompleteDialog";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import imgPlaceholder from "../../../../image/Admin/placeholder.gif";
import moment from "moment";
import { withSnackbar } from "notistack";
class CurationList extends Component {
  state = {
    reportSelected: "",
    reportTable: [
      {
        id: 1,
        text: "미선정",
      },
      {
        id: 2,
        text: "선정됨",
      },
    ],
    selectedMenu: 1,
    isLoading: false,
    curationDialogOpen: false,
    reportDeleteDialogOpen: false,
    reportedId: "",
    completeDialogOpen: false,
    startValue: null,
    endValue: null,
    endOpen: false,
    selectedIds: [],
    selectedPostId: "",
    selectedProjectObj: {},
  };

  // 날짜 설정 버튼 함수
  handleDateGrp = (type) => {
    const todayStart = moment.utc().startOf("day");
    const todayEnd = moment.utc().endOf("day");
    const from_date = moment.utc().startOf("isoWeek");
    const to_date = moment.utc().endOf("isoWeek");

    if (type === "reset") {
      this.setState({ startValue: null, endValue: null }, () =>
        this.props.handleDatesChange("", "")
      );
    } else if (type === "today") {
      this.setState({ startValue: todayStart, endValue: todayEnd }, () =>
        this.props.handleDatesChange(this.state.startValue, this.state.endValue)
      );
    } else if (type === "week") {
      this.setState({ startValue: from_date, endValue: to_date }, () =>
        this.props.handleDatesChange(this.state.startValue, this.state.endValue)
      );
    }
  };

  // 시작일 Validation 함수
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  // 종료일 Validation 함수
  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  // 시작일 , 종료일 변경 State Change 함수
  onChange = (field, value) => {
    // console.log("onChange 함수 호충", field, value);

    this.setState(
      {
        [field]: value,
      },
      () =>
        this.props.handleDatesChange(this.state.startValue, this.state.endValue)
    );
  };

  // 시작일 변경 콜백 함수
  onStartChange = (value) => {
    // console.log("시작일 변경 : ", value);
    this.onChange("startValue", value);
  };

  // 종료일 변경 콜백 함수
  onEndChange = (value) => {
    // console.log("종료일 변경 : ", value);
    this.onChange("endValue", value);
  };

  // 시작일 변경 팝업 상태 변경
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  // 종료일  변경 팝업 상태 변경
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  // 탭 변경 함수
  handleSelectedMenu = (menu) => {
    this.setState({ selectedMenu: menu });
    this.props.handleSelectedMenu(menu);
  };

  handleCurationModalClose = async () => {
    await this.props.handleSelectedMenu(this.state.selectedMenu);
    this.setState({ isLoading: false });
  };

  // 테이블 페이지네이션, 필터 , 소팅 변경 콜백 함수
  handleTableChange = (pagination, filters, sorter) => {
    const categories = filters.category;
    const sortType = this.checkSorterType(sorter.columnKey);
    const isASC = sorter.order === "ascend" ? true : false;

    // console.log({ sortType: sortType, isASC: isASC });

    // 카테고리 필터 변경 함수
    this.props.handleCategoryChange(categories);

    // Sorting 변경 함수
    this.props.handleSorter(sortType, isASC);
  };

  checkSorterType = (type) => {
    if (type === "title") {
      return 1;
    } else if (type === "createdAt") {
      return 2;
    } else if (type === "userName") {
      return 3;
    } else if (type === "views") {
      return 4;
    } else if (type === "likes") {
      return 5;
    } else if (type === "comments") {
      return 6;
    } else {
      return null;
    }
  };

  handleCurationModalOpen = (e) => {
    const projectId = e.target.parentElement.id;
    const projectObj = this.props.list.find((item) => item._id === projectId);

    this.setState({ selectedProjectObj: projectObj }, () =>
      this.setState({ curationDialogOpen: true })
    );
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  // 큐레이션 리스트 출력
  renderList = () => {
    // Tab 1 컬럼
    const Tab1Columns = [
      {
        title: "게시글 제목",
        dataIndex: ["title", "thumbnail"],
        key: "title",
        ellipsis: true,
        sorter: true,
        width: 300,
        render: (text, data) => (
          <Space>
            <img
              style={{
                width: "100px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "2px",
              }}
              alt="projectThumbnail"
              src={data.thumbnail ? data.thumbnail : imgPlaceholder}
            ></img>

            <div>{data.title}</div>
          </Space>
        ),
      },
      {
        title: i18n.t("admin.projects.publishDate"),
        key: "createdAt",
        dataIndex: "createdAt",
        ellipsis: true,
        sorter: true,
        render: (createdAt) => {
          if (createdAt) {
            return (
              <Moment locale={this.props.lang} fromNow>
                {createdAt}
              </Moment>
            );
          } else {
            return (
              <Tag color={"default"} key={createdAt}>
                {i18n.t("global.noData")}
              </Tag>
            );
          }
        },
      },
      {
        title: i18n.t("indivi.signup.userName"),
        key: "userName",
        dataIndex: "uploaderInfo",
        ellipsis: true,
        sorter: true,

        render: (uploaderInfo) => {
          if (uploaderInfo.userName) {
            return <span key={"lsdkjjdslkf"}>{uploaderInfo.userName}</span>;
          } else {
            return (
              <Tag color={"default"} key={uploaderInfo.userName}>
                {i18n.t("global.noData")}
              </Tag>
            );
          }
        },
      },
      {
        title: "갤러리 (분야)",
        key: "category",
        dataIndex: "category",
        ellipsis: true,

        render: (category) => {
          let color = !category ? "default" : "red";
          if (category) {
            if (this.props.lang === "en") {
              return (
                <Tag color={color} key={category}>
                  {category.name.en.toUpperCase()}
                </Tag>
              );
            } else {
              return (
                <Tag color={color} key={category}>
                  {category.name.ko}
                </Tag>
              );
            }
          } else {
            return (
              <Tag color={color} key={category}>
                {i18n.t("global.noData")}
              </Tag>
            );
          }
        },

        filters:
          i18n.language === "ko"
            ? this.props.categoriesKor.map((item) => ({
                value: item.value,
                text: item.label,
              }))
            : this.props.categoriesEng.map((item) => ({
                value: item.value,
                text: item.label,
              })),
      },
      {
        title: i18n.t("mypage.profile.views"),
        ellipsis: true,
        key: "views",
        dataIndex: "views",
        sorter: true,
        width: 80,
        render: (views) => {
          if (views) {
            return <span>{views}</span>;
          } else {
            return <div>0</div>;
          }
        },
      },
      {
        title: i18n.t("admin.projects.likes"),
        ellipsis: true,
        key: "likes",
        dataIndex: "likeMembers",
        sorter: true,
        width: 80,
        render: (likeMembers) => {
          if (likeMembers) {
            return <span>{likeMembers}</span>;
          } else {
            return <span>0</span>;
          }
        },
      },
      {
        title: "댓글수",
        width: 80,
        dataIndex: "comments",
        key: "comments",
        ellipsis: true,
        sorter: true,
      },
      // 기능 컬럼
      {
        title: "",
        key: "action",
        dataIndex: "_id",
        fixed: "right",
        align: "right",
        render: (_id) => {
          const menu = (
            <Menu>
              <Menu.Item onClick={() => this.props.getProjInfo(_id)}>
                <div>{i18n.t("게시글 보기")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  this.setState({ selectedIds: [_id] }, () => {
                    this.props.handleDeleteContents(this.state.selectedIds);
                  })
                }
              >
                <div>{i18n.t("게시글 삭제")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("분야 및 태그 수정")}</div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleAlert("준비중인 기능입니다.")}
              >
                <div>{i18n.t("게시자에게 메시지 보내기")}</div>
              </Menu.Item>
            </Menu>
          );
          return (
            <Space>
              <Button
                type="primary"
                size="middle"
                style={{ backgroundColor: "black", borderColor: "unset" }}
                onClick={this.handleCurationModalOpen}
                id={_id}
              >
                선정 / 철회
              </Button>
              <Dropdown overlay={menu} placement="bottomRight" arrow>
                {/* <Button>bottomCenter</Button> */}
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  // onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Dropdown>
            </Space>
          );
        },
      },
    ];

    return (
      <Space className="curation_list-container" align="start">
        <Space className="curation_list-tableHeader">
          <Space className="curation_list-table">
            {this.state.reportTable.map((item) => {
              return (
                <div className="table-item" key={`report_${item._id}`}>
                  <span
                    className={`${
                      this.state.selectedMenu === item.id ? "selectedMenu" : ""
                    }`}
                    onClick={() => this.handleSelectedMenu(item.id)}
                  >
                    {item.text}
                  </span>
                </div>
              );
            })}
          </Space>
          <Space>
            <Button.Group>
              <Button block onClick={() => this.handleDateGrp("today")}>
                오늘
              </Button>
              <Button block onClick={() => this.handleDateGrp("week")}>
                이번주
              </Button>
              <Button block onClick={() => this.handleDateGrp("reset")}>
                초기화
              </Button>
            </Button.Group>

            <DatePicker
              disabledDate={this.disabledStartDate}
              // showTime
              format="YYYY-MM-DD"
              value={this.state.startValue}
              placeholder="시작일"
              onChange={this.onStartChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <DatePicker
              disabledDate={this.disabledEndDate}
              // showTime
              format="YYYY-MM-DD"
              value={this.state.endValue}
              placeholder="종료일"
              onChange={this.onEndChange}
              open={this.state.endOpen}
              onOpenChange={this.handleEndOpenChange}
            />
          </Space>
        </Space>
        <Table
          columns={Tab1Columns}
          rowKey="_id"
          pagination={{
            total: this.props.totalItems,
            current: this.props.currentPage,
            onChange: this.props.handlePageChange,
            pageSize: this.props.setPageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
            onShowSizeChange: this.props.handlePageSizeChange,
            simple: true,
            hideOnSinglePage: true,
          }}
          tableLayout="auto"
          dataSource={this.props.list}
          // 번역
          locale={{
            filterConfirm: i18n.t("global.ok"),
            filterReset: i18n.t("global.reset"),
            triggerDesc: i18n.t("admin.sort.triggerDesc"),
            triggerAsc: i18n.t("admin.sort.triggerAsc"),
            cancelSort: i18n.t("admin.sort.cancelSort"),
            emptyText: i18n.t("global.noData"),
          }}
          onChange={this.handleTableChange}
        />
      </Space>
    );
  };

  render() {
    return (
      <div className="hidden-container">
        {this.state.curationDialogOpen ? (
          <CurationDialog
            onClose={() =>
              this.setState(
                { curationDialogOpen: false, isLoading: true },
                () => this.handleCurationModalClose()
              )
            }
            open={this.state.curationDialogOpen}
            curationInfo={this.state.selectedProjectObj}
            handleReportDeleteDialogOpen={this.handleReportDeleteDialogOpen}
            selectedMenu={this.state.selectedMenu}
          />
        ) : null}

        <ReportDeleteDialog
          onClose={() => this.setState({ reportDeleteDialogOpen: false })}
          open={this.state.reportDeleteDialogOpen}
          reportedId={this.state.reportedId}
          handleDelteReportedContent={this.handleDelteReportedContent}
        />
        <CompleteDialog
          onClick={this.state.completeDialogOpen}
          onClose={() => this.setState({ completeDialogOpen: false })}
        />
        <div className="hidden-title">
          <span>{i18n.t("큐레이션")}</span>
        </div>
        <div className="hidden-list">{this.renderList()}</div>
      </div>
    );
  }
}

export default withSnackbar(CurationList);
