import React, { Component } from "react";
import "./BasicInfo.scss";
import i18n from "../../i18n";
//import RedArrow from "../../image/ProjectUpload/line-red-arrow-down-icon.svg";
import AddIcon from "../../image/plus-icon.svg";
import CountrySelect from "../../SignUp/UserInfo/CountrySelect";
import { RegionDropdown } from "react-country-region-selector";
//import { ClickAwayListener } from "@material-ui/core";
import axios from "axios";
import { PostUserPhotoSet } from "../../Api/User/user";
import { getMyInfo } from "../../Redux/action/index";
import { connect } from "react-redux";
import CheckBoxDropDown from "../../Component/CheckBoxDropDown/CheckBoxDropDown";

import { compose } from "redux";
import { withSnackbar } from "notistack";
import CropDialog from "../../Component/CropDialog/CropDialog";
import { GetCategories } from "../../Api/Category/Category";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
class BasicInfo extends Component {
  state = {
    userName: "",
    nameValid: false,
    userMsg: "",
    job: "",
    jobValid: false,
    jobMsg: "",
    profile: "",
    file: "",
    selecCountry: "",
    region: null,
    webValid: false,
    website: "",
    webMsg: "",
    coutryListOpen: false,
    dropdownOpen: false,
    countryName: "",
    countryCode: "",
    favoriteList: [],
    selectedFavorites: [],
    cropDialogOpen: false,
    cropSrc: "",
    croppedAreaPixels: "",
    fileName: "",
    fileType: "",
    crop: {
      x: 0,
      y: 0,
    },
    zoom: 1,
  };
  ratio = 1;
  componentDidMount = () => {
    try {
      const userInfo = this.props.userInfo;

      if (userInfo) {
        this.setState(
          {
            userName: userInfo.userName,
            selectedFavorites: userInfo.favorites ? userInfo.favorites : [],
            profile: userInfo.profilePhoto,
            website: userInfo.website,
            countryName: userInfo.locationInfo.countryName,
            countryCode: userInfo.locationInfo.countryCode,
            region: userInfo.locationInfo.region,
          },
          async () => {
            if (!this.state.countryCode) {
              this.getGeoInfo();
            }
            await this.getCategories();
            this.props.handleBasicInfoSet(this.state);
          }
        );
      }
    } catch (e) {
      console.dir(e);
    }
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };
  onImageLoaded = (image) => {
    this.imgRef = image;
  };
  createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });
  };
  getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }
  getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    canvas.width = safeArea;
    canvas.height = safeArea;
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(this.getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          blob.name = this.state.fileName;
          resolve(blob);
        },
        this.state.fileType,
        1
      );
    });
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };
  showCroppedImage = async (img) => {
    try {
      const croppedImage = await this.getCroppedImg(
        img,
        this.state.croppedAreaPixels,
        0
      );
      const file = new File([croppedImage], this.state.fileName, {
        type: this.state.fileType,
      });
      const url = URL.createObjectURL(croppedImage);

      this.setState(
        {
          file,
          fileName: "",
          fileType: "",
          profile: url,
          cropDialogOpen: false,
        },
        () => this.handleProfilePhotoUpload()
      );
    } catch (e) {
      console.error(e);
    }
  };
  getCategories = async () => {
    try {
      const favoriteList = await GetCategories();

      this.setState({ favoriteList });
    } catch (e) {
      console.dir(e);
    }
  };
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState(
          {
            countryName: data.country_name,
            countryCode: data.country_code,
          },
          () => this.props.handleBasicInfoSet(this.state)
        );
      })
      .catch((error) => {
        console.dir(error);
      });
  };
  handleSelectFavorite = (e, value) => {
    let selectedFavorites = this.state.selectedFavorites;

    if (
      e.target.checked &&
      !this.state.selectedFavorites.find((item) => item._id === value._id)
    ) {
      if (selectedFavorites.length < 2) {
        selectedFavorites.push(value);

        this.setState({ selectedFavorites }, () => {
          this.props.handleBasicInfoSet(this.state);
        });
      } else {
        selectedFavorites.push(value);
        selectedFavorites.shift();
        this.setState({ selectedFavorites }, () => {
          this.props.handleBasicInfoSet(this.state);
        });
      }
    } else {
      selectedFavorites = selectedFavorites.filter((item) => item !== value);

      this.setState({ selectedFavorites }, () =>
        this.props.handleBasicInfoSet(this.state)
      );
    }
  };
  handleChangeCountry = (e) => {
    this.setState(
      {
        countryCode: e.value,
        countryName: e.label,
        region: null,
      },
      () => {
        this.props.handleBasicInfoSet(this.state);
      }
    );
  };

  handleProfilePhotoUpload = async () => {
    try {
      await PostUserPhotoSet(this.state.file);
      const myInfo = this.props.myInfo;
      myInfo.profilePhoto = this.state.profile;
      this.props.getMyInfo(myInfo);
      this.test.click();
      this.props.enqueueSnackbar(i18n.t("userSetting.profileUpload"), {
        variant: "success",
        key: "userSetting.profileUpload",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.dir(e);
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleCoutryOpen = () => {
    this.setState({ coutryListOpen: true });
  };
  handleCoutryClose = () => {
    this.setState({ coutryListOpen: false });
  };

  handleUserNameChange = (e) => {
    const userName = e.target.value.trim();
    const spcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자
    if (userName.length === 0) {
      this.setState(
        {
          nameValid: false,
          userName: userName,
          userNameMsg: i18n.t("userInfo.nickName"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else if (spcialRegex.test(userName)) {
      this.setState(
        {
          nameValid: false,
          userName: userName,
          userNameMsg: i18n.t("global.alert.specialCharacter"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else if (userName.length < 1 || userName.length > 20) {
      this.setState(
        {
          nameValid: false,
          userName: userName,
          userNameMsg: i18n.t("userInfo.nickNameCondition"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else {
      this.setState(
        {
          nameValid: true,
          userName: userName,
          userNameMsg: i18n.t("all.signup.Available"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    }
  };

  handleJobChange = (e) => {
    const job = e.target.value.trim();
    const spcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자

    if (spcialRegex.test(job)) {
      // 특수문자 검사
      this.setState({
        jobValid: false,
        job: job,
        jobMsg: i18n.t("global.alert.specialCharacter"),
      });
    } else if (job.length > 40) {
      const maxLength = 40;
      this.setState({
        jobValid: false,
        job: job,
        jobMsg: i18n.t("global.alert.maxlength", { maxLength }),
      });
    } else {
      this.setState(
        {
          jobValid: true,
          job: job,
          jobMsg: i18n.t("all.signup.Available"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    }
  };

  handleRegionChange = (val) => {
    if (val !== "") {
      this.setState({ region: val }, () => {
        this.props.handleBasicInfoSet(this.state);
      });
    } else {
      this.setState({ region: null }, () => {
        this.props.handleBasicInfoSet(this.state);
      });
    }
  };

  handleWebsiteChage = (e) => {
    const url = e.target.value.trim();
    const urlRegex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim;
    if (!urlRegex.test(url)) {
      // Url 유효성 검사
      this.setState({
        webValid: false,
        website: url,
        webMsg: i18n.t("웹사이트 주소를 다시 확인해 주세요."),
      });
    } else {
      this.setState(
        {
          webValid: true,
          website: url,
          webMsg: "",
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    }
    // this.setState({ website: e.target.value }, () =>
    //   this.props.handleBasicInfoSet(this.state)
    // );
  };

  handleChangeProfile = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState(
        {
          cropSrc: reader.result,
          cropDialogOpen: true,
          fileName: file.name,
          fileType: file.type,
          // file,
          // profile: reader.result,
        },
        () => {
          this.props.handleBasicInfoSet(this.state);
          // this.handleProfilePhotoUpload();
        }
      );
    };
  };

  render() {
    return (
      <div
        className="basicInfo-container setting-container"
        ref={(ref) => (this.test = ref)}
      >
        <CropDialog
          zoom={this.state.zoom}
          setZoom={this.handleSetZoom}
          ratio={this.ratio}
          showCroppedImage={this.showCroppedImage}
          onImageLoaded={this.onImageLoaded}
          onCropChange={this.onCropChange}
          crop={this.state.crop}
          src={this.state.cropSrc}
          open={this.state.cropDialogOpen}
          onCropComplete={this.onCropComplete}
          onClose={() => this.setState({ cropDialogOpen: false })}
        />
        <div className="basicInfo-box">
          <div className="Title settingSub-title">
            {i18n.t("setting.profile.title")}
          </div>
          <div className="userProfileBox">
            <div className="profileBox ellipse">
              <ProfilePhoto size={141} src={this.state.profile} />
            </div>
            <input
              type="file"
              accept="image/*"
              id="profile-img"
              onChange={this.handleChangeProfile}
            />
            <label htmlFor="profile-img">
              <img src={AddIcon} alt="profileAdd" />
            </label>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("indivi.signup.name")}
            </div>
            <div className="userInfo-inputBox">
              <input
                onKeyUp={this.handleUserNameChange}
                className="InputField"
                defaultValue={this.state.userName}
                placeholder={i18n.t("indivi.signup.name")}
              />
              <div
                style={{
                  color: this.state.nameValid ? "green" : "#ff2e43",
                }}
                className="notValidMsg"
              >
                {this.state.userNameMsg}
              </div>
            </div>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("userSetting.field.title")}
            </div>
            <div className="favorites_dropBox"></div>

            <CheckBoxDropDown
              open={this.state.dropdownOpen}
              onOpen={() =>
                this.setState({ dropdownOpen: !this.state.dropdownOpen })
              }
              onClose={() => {
                this.setState({ dropdownOpen: false });
              }}
              checked={this.state.selectedFavorites}
              handleChange={this.handleSelectFavorite}
              text={
                !this.state.selectedFavorites[0]
                  ? i18n.t("userSetting.field.placeholder")
                  : this.state.selectedFavorites
                      .map((item) => {
                        return i18n.language === "en"
                          ? item.name?.en
                          : item.name?.ko;
                      })
                      .join(",")
              }
              list={this.state.favoriteList}
            />
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("job.searchFilter.location")}
            </div>
            <div className="userInfo-locationBox">
              <CountrySelect
                handleChangeCountry={this.handleChangeCountry}
                countryCode={this.state.countryCode}
              />
              <RegionDropdown
                style={{
                  padding: "0 15px",
                  borderRadius: "unset",
                }}
                countryValueType="short"
                country={this.state.countryCode}
                value={this.state.region}
                className="region-input grayBorder"
                defaultOptionLabel={i18n.t("job.upload_1.region")}
                onChange={(val) => this.handleRegionChange(val)}
              />
            </div>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("userSetting.website")}
            </div>
            <div className="userInfo-inputBox">
              <input
                type="url"
                className="InputField"
                maxLength="50"
                defaultValue={this.state.website}
                onKeyUp={this.handleWebsiteChage}
                placeholder={i18n.t("userSetting.website")}
                required
              />
              <div
                style={{
                  color: this.state.webValid ? "green" : "#ff2e43",
                }}
                className="notValidMsg"
              >
                {this.state.webMsg}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(BasicInfo);
