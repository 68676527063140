import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./BurgerMenu.scss";
import burgerMenuImg from "../../Mobile/hamburger_white_icon.svg";
import closeIcon from "../../Mobile/gray_close_icon.svg";
// import profileImg from "../../Mobile/menu_profile_img.jpg";
import checkIcon from "../../image/arrowIcon/red_40_px_check_icon.svg";
import LocationIcon from "../../image/location-icon.svg";
import arrowIcon from "../../image/line-red-down-arrow-icon.svg";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import TermsDialog from "../../SignUp/TermsDialog/TermsDialog";
import PersonalDialog from "../../SignUp/PersonalDialog/PersonalDialog";
import {
  getMyInfo,
  langChange,
  changeLoginStatus,
  searchContent,
} from "../../Redux/action/index";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: false,
      menuOpen: false,
      isLanguageMenuOpen: false,
      termsDialogOpen: false,
      personalDialogOpen: false,
    };
  }

  lang = [
    {
      title: "English",
      id: "en",
    },
    {
      title: "한국어",
      id: "ko",
    },
  ];

  handleStateChange(state) {
    if (state.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    this.setState({ menuOpen: state.isOpen }, () =>
      setTimeout(() => {
        this.setState({ isLanguageMenuOpen: false });
      }, 1000)
    );
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
      isLanguageMenuOpen: false,
      submenuOpen: false,
    });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  handleLanguageMenuOpen() {
    this.setState({
      isLanguageMenuOpen: true,
      submenuOpen: false,
    });
  }

  handleLangChange = (lang) => {
    // window.location.reload();
    i18n.changeLanguage(lang);
    this.props.langChange(lang);
    this.setState({ menuOpen: false });
  };

  handleUploadProject() {
    this.setState({ menuOpen: false }, this.props.uploadProject);
  }
  handleTermsOpen = () => {
    this.setState({ menuOpen: false }, () =>
      this.setState({
        termsDialogOpen: true,
      })
    );
  };
  handleTermsClose = () => {
    this.setState({ termsDialogOpen: false });
  };
  handlePersonalClose = () => {
    this.setState({ personalDialogOpen: false });
  };
  handleSubmenuOpen = () => {
    this.setState({ submenuOpen: !this.state.submenuOpen });
  };

  handlePersonalOpen = () => {
    this.setState({ menuOpen: false }, () =>
      this.setState({
        personalDialogOpen: true,
      })
    );
  };
  render() {
    return (
      <div>
        <TermsDialog
          open={this.state.termsDialogOpen}
          onClose={this.handleTermsClose}
        />
        <PersonalDialog
          open={this.state.personalDialogOpen}
          onClose={this.handlePersonalClose}
        />
        <Menu
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          customBurgerIcon={<img src={burgerMenuImg} alt="burgerMenuImg" />}
          customCrossIcon={<img src={closeIcon} alt="closeIcon" />}
          // crossButtonClassName={ "crossButton" }s
        >
          {!this.state.isLanguageMenuOpen ? (
            <div className="burgerMenu-container">
              {!this.props.isLogined ? (
                <>
                  <div className="nav-container">
                    <NavLink
                      className="nav-link"
                      activeClassName="activeRoute"
                      exact
                      to={"/login"}
                      onClick={() => this.closeMenu()}
                    >
                      {i18n.t("header.login")}
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      activeClassName="activeRoute"
                      to={"/sign-up"}
                      onClick={() => this.closeMenu()}
                    >
                      {i18n.t("header.signup")}
                    </NavLink>
                  </div>
                </>
              ) : null}

              <div className="burgerMenu-container">
                {this.props.isLogined ? (
                  <>
                    <div
                      className="userInfo-listContainer"
                      onClick={() => {
                        this.props.toMypage();
                        this.closeMenu();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <ProfilePhoto
                        src={this.props.myInfo?.profilePhoto}
                        size={60}
                      />
                      <div>
                        <div
                          className="userName"
                          onClick={() => this.closeMenu()}
                        >
                          {this.props.myInfo && this.props.myInfo.userName
                            ? this.props.myInfo.userName
                            : null}
                        </div>
                        <div className="place-container">
                          <img src={LocationIcon} alt="placeIcon"></img>
                          <div className="placeName">
                            {this.props.myInfo &&
                            this.props.myInfo.countryInfo.countryName
                              ? this.props.myInfo.countryInfo.countryName
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.props.isLogined ? (
                      <div className="logOut" onClick={this.props.isLogOut}>
                        {i18n.t("global.logout")}
                      </div>
                    ) : null}
                    <button
                      className="uploadButton"
                      type="button"
                      onClick={() => this.handleUploadProject()}
                    >
                      {i18n.t("header.upload")}
                    </button>
                  </>
                ) : null}
                <div className="nav-container">
                  <NavLink
                    className="nav-link"
                    activeClassName="activeRoute"
                    exact
                    to={"/"}
                    onClick={() => this.closeMenu()}
                  >
                    {i18n.t("header.discover")}
                  </NavLink>
                  {/* <NavLink
                    className="nav-link"
                    activeClassName="activeRoute"
                    to={"/discover"}
                    onClick={() => this.closeMenu()}
                  >
                    {i18n.t("header.discover")}
                  </NavLink> */}
                  <NavLink
                    className="nav-link"
                    activeClassName="activeRoute"
                    to={"/job"}
                    onClick={() => this.closeMenu()}
                  >
                    {i18n.t("header.job")}
                  </NavLink>
                </div>
                <div className="nav-container">
                  {this.props.isLogined ? (
                    <>
                      <NavLink
                        className="nav-link"
                        activeClassName="activeRoute"
                        to={"/setting"}
                        onClick={() => this.closeMenu()}
                      >
                        {i18n.t("header.settings")}
                      </NavLink>
                      <NavLink
                        to={`/team/list`}
                        className="nav-link"
                        activeClassName="activeRoute"
                        onClick={() => this.closeMenu()}
                      >
                        {i18n.t("header.team")}
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        activeClassName="activeRoute"
                        to={`/job-management`}
                        onClick={() => this.closeMenu()}
                      >
                        {i18n.t("header.job.management")}
                      </NavLink>
                    </>
                  ) : null}
                  <div
                    className="langDiv"
                    onClick={() => this.handleLanguageMenuOpen()}
                  >
                    <div className="langBtn">{i18n.t("header.lang")}</div>
                    <img
                      className="arrowIcon"
                      src={arrowIcon}
                      alt="arrowIcon"
                    ></img>
                  </div>
                </div>

                <div className="nav-container">
                  <div
                    className="nav-submenu_open"
                    onClick={this.handleSubmenuOpen}
                  >
                    {i18n.t("jobInfo.otherjobs.more")}
                    <img
                      style={
                        this.state.submenuOpen
                          ? { transform: "rotate(180deg)" }
                          : null
                      }
                      className="arrowIcon"
                      src={arrowIcon}
                      alt="arrowIcon"
                    ></img>
                  </div>
                  <div
                    className={`nav-submenu sub-first ${
                      this.state.submenuOpen ? "sub-open" : "sub-close"
                    }`}
                  >
                    <NavLink
                      className="nav-link"
                      activeClassName="activeRoute"
                      onClick={() => this.closeMenu()}
                      to={`/faq`}
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      activeClassName="activeRoute"
                      onClick={() => this.closeMenu()}
                      to={`/intro`}
                    >
                      {i18n.t("footer.introdution")}
                    </NavLink>
                    <div
                      className="nav-link"
                      activeClassName="activeRoute"
                      onClick={() => this.handleTermsOpen()}
                    >
                      {i18n.t("footer.terms")}
                    </div>
                    <div
                      className="nav-link"
                      activeClassName="activeRoute"
                      onClick={() => this.handlePersonalOpen()}
                    >
                      {i18n.t("footer.privacy")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div draggable={false} className="langList">
              <img
                className="arrowIcon"
                src={arrowIcon}
                alt="arrowIcon"
                onClick={() =>
                  this.setState({
                    isLanguageMenuOpen: false,
                    submenuOpen: false,
                  })
                }
              ></img>
              {this.lang.map((item, index) => {
                return (
                  <React.Fragment className="langsList" key={`langs_${index}`}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "2em",
                      }}
                    >
                      <input
                        type="radio"
                        name="selLang"
                        id={`langs_${index}`}
                        style={{ display: "none" }}
                      ></input>
                      <label
                        className={this.props.lang === item.id ? "active" : ""}
                        htmlFor={`langs_${index}`}
                        onClick={() => this.handleLangChange(item.id)}
                      >
                        {item.title}
                      </label>
                      <img
                        style={
                          this.props.lang === item.id
                            ? {
                                width: "14px",
                                height: "14px",
                                display: "block",
                              }
                            : { display: "none" }
                        }
                        src={checkIcon}
                        alt="checkIcon"
                      ></img>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
  lang: state.language.lang,
  searchTitle: state.searchKeyword.searchTitle,
});

const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
  searchContent: (value) => dispatch(searchContent(value)),
  langChange: (value) => dispatch(langChange(value)),
  changeLoginStatus: (value) => dispatch(changeLoginStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
