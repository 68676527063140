import React, { Component } from "react";
import "./MemoDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../i18n";
import Button from "../../../Component/Button/Button";
import { timeBefore } from "../../../Component/functions";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
class MemoDialog extends Component {
  state = {
    modify: false,
  };

  renderBody = () => {
    if (!this.props.memoInfo.text) {
      return (
        <div className="memo-wrapper">
          <div className="memo-top memo-noContents">
            <span>
              {this.props.userName} 사용자에 대해 작성한 메모가 없습니다.
            </span>
          </div>
          <div className="memo_input">
            <span className="momo-auth">
              메모는 팀의 관리자에게만 표시됩니다.
            </span>
            <textarea
              placeHolder="메모 내용을 입력해 주세요."
              onChange={this.props.handleMemoChange}
            ></textarea>
          </div>
          <div className="memo_btns">
            <Button
              backColor="white"
              text="취소"
              btnClick={this.props.onClose}
            />
            <Button
              text={i18n.t("global.registration")}
              btnClick={this.props.handleMemoRegistration}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="memo-wrapper">
          <span className="momo-auth">
            메모는 팀의 관리자에게만 표시됩니다.
          </span>
          {!this.props.modify ? (
            <>
              <div className="memo-top memo-history">
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.props.memoInfo.text.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br/>"
                    ),
                  }}
                ></span>
                <span>{timeBefore(this.props.memoInfo.modifiedAt)}</span>
              </div>
              <div className="memo_modifier">
                {/* <span>마지막 수정</span> */}
                <div className="modifier_info">
                  {this.props.memoInfo.modifier.profilePhoto ? (
                    <div
                      className="userPhoto"
                      style={{
                        backgroundImage: `url(${this.props.memoInfo.modifier.profilePhoto})`,
                      }}
                    ></div>
                  ) : (
                    <Avatar size={40} icon={<UserOutlined />} />
                  )}

                  <div className="modifier-detail">
                    <span>{this.props.memoInfo.modifier.userName}</span>
                    <span>{timeBefore(this.props.memoInfo.modifiedAt)}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="memo_input">
              <textarea
                defaultValue={this.props.memoInfo.text}
                onChange={this.props.handleMemoChange}
              ></textarea>
            </div>
          )}

          <div className="memo_btns">
            <Button
              backColor="white"
              text="취소"
              btnClick={this.props.onClose}
            />
            <Button
              text={i18n.t("teamPage.recruitment.header4")}
              btnClick={
                !this.props.modify
                  ? this.props.modifyChange
                  : this.props.handleMemoRegistration
              }
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const color = "black";
    const { onClose, open, userName } = this.props;
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    `;
    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"memo-dialog"}
          open={open}
          onClose={onClose}
        >
          {JSON.stringify(this.props.memoInfo) !== "{}" &&
          !this.props.memoLoading ? (
            <div className="memo_dialog-container">
              <div
                className={
                  !this.props.memoInfo.text
                    ? "memo-header no-border"
                    : "memo-header"
                }
              >
                <span>메모 : {userName}</span>
              </div>
              {this.renderBody()}
            </div>
          ) : (
            <div className="memo_dialog-container memoLoader">
              <FadeLoader
                color={color}
                loading={this.state.bannerLoading}
                css={override}
                size={10}
              ></FadeLoader>
            </div>
          )}
        </Dialog>
      </>
    );
  }
}

export default MemoDialog;
