import React, { Component } from "react";
import "./Notification.scss";
import notifyIcon from "../../../image/loginIcon/top-notification-icon.svg";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
import { GetUserToken } from "../../../modules/userAuth";
import axios from "axios";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import { withSnackbar } from "notistack";
import { isLoginCheck, timeBefore } from "../../../Component/functions";
import { GetProjectInfo } from "../../../Api/Project/project";
import ProjectDialog from "../../../Component/ProjectDialog/ProjectDialog";
import { compose } from "redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { renderNotifyMsg } from "../../../modules/notify";
import {
  DeleteNotifyAll,
  PatchNotifyAccept,
  PatchNotifyReject,
} from "../../../Api/Notify/Notify";
class Notification extends Component {
  state = {
    isLoading: false,
    notifyList: [],
    isUnread: false,
    isNotifyLoadDisable: false,
    timer: false,
    userLink: "",
    isFirstLoading: true,
  };
  notifySkip = 0;
  notifyLoadMore = () => {
    this.notifySkip += 20;
    this.getNotify();
  };
  documentHideenCheck = (pageHidden) => {
    this.setState({ pageHidden });
  };
  getNotify = async () => {
    const token = GetUserToken();
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/list?skip=${this.notifySkip}&limit=20`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let notifyList = response.data.data.list;

      let isUnread = null;
      if (notifyList && notifyList.find((item) => item.isRead === false)) {
        isUnread = "unread";
      }

      if (notifyList && notifyList.length >= response.data.data.count) {
        notifyList = notifyList.map((item) => {
          return renderNotifyMsg(item);
        });
        this.setState({
          notifyList,
          isUnread,
          isNotifyLoadDisable: true,
          isLoading: false,
          isFirstLoading: false,
        });
      } else {
        this.setState({
          notifyList: [],
          isUnread,
          isLoading: false,
          isFirstLoading: false,
        });
      }
    } catch (e) {
      console.dir(e);
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  loadProjectInfo = async (projectId) => {
    this.setState({ isLoading: true });
    try {
      const response = await GetProjectInfo(projectId);
      this.setState({ projectInfo: response }, () =>
        this.setState({
          isLoading: false,
          projInfoRender: true,
          projectDialogOpen: true,
        })
      );
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleAlert(i18n.t("global.error.server.msg"));
      console.dir(e);
    }
  };
  handleClickedNotify = (item) => {
    if (!item.isConfirm) {
      if (item.notifyType === "FOLLOW_NEW") {
        //this.handleUserPage(item.mainTargetId, item.mainUserLink);
        this.props.history.push(`/@${item.mainUserLink}`);
      } else if (
        item.notifyType === "PROJECT_NEW" ||
        item.notifyType === "PROJECT_LIKE" ||
        item.notifyType === "PROJECT_COMMENT"
      ) {
        this.loadProjectInfo(item.subTargetId);
      } else if (item.notifyType === "TEAM_JOINED") {
        this.props.history.push(`/@${item.mainUserLink}`);
      } else if (
        item.notifyType === "JOB_NEW" ||
        item.notifyType === "JOB_MODIFIED" ||
        item.notifyType === "JOB_MODIFIED" ||
        item.notifyType === "JOB_LEFT_ONEDAY"
      ) {
      }
      // switch (item.type) {
      //   case "follow":
      //     this.handleUserPage(item.mainTargetId);
      //     break;
      //   case "project":
      //     this.loadProjectInfo(item.subTargetId);
      //     break;
      //   case "team":
      //     this.handleTeamAccept(item._id);
      //     break;
      //   default:
      //     break;
      // }
    }
  };
  handleUserPage = async (id, url) => {
    if (id) {
      try {
        // const response = await GetUserInfo(id);
        // this.setState({ userLink: response.linkURL }, () => {
        // });
        this.props.history.push(`/@${url}`);
      } catch (e) {
        this.handleAlert(i18n.t("global.error.server.msg"));
        console.log(e);
      }
    }
  };
  notifyLoadMore = () => {
    this.notifySkip += 20;
    this.getNotify();
  };
  handleNotifyRead = async () => {
    const token = GetUserToken();
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/read-all`;
      await axios.patch(url, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (e) {
      console.log(e);
    }
  };
  timerSet = (timer) => {
    this.setState({ timer });
  };
  componentDidMount = () => {
    if (!isLoginCheck()) {
      return this.props.history.push("/login");
    }
    this.setState({ isLoading: true });
    document.addEventListener("visibilitychange", () =>
      this.documentHideenCheck(document.hidden)
    );
    this.handleNotifyRead();
    const token = GetUserToken();
    if (token && this.props.socket) {
      this.props.socket.emit("authenticate", { token });
      this.props.socket.on("notify", (data) => {
        this.getNotify();
      });
    }
    this.getNotify();
  };
  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };
  handleButtonAccept = async (notifyId, item, index) => {
    const notifyList = this.state.notifyList;
    try {
      item.isConfirm = false;
      await PatchNotifyAccept(notifyId);
      notifyList[index].isConfirm = false;
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 ${notifyList[index].subTargetName}팀 가입 요청을 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `${notifyList[index].mainTargetName} team join request has been accepted`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName} 팀 초대를 수락하였습니다`;
        notifyList[
          index
        ].message.en = `I've accepted your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `I accepted Lee Hyun-kyu's ${notifyList[index].mainTargetName} as a collaborator.`;
      }
      this.setState({ notifyList });
    } catch (e) {
      item.isConfirm = false;
      notifyList[index].isConfirm = false;
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `이미 다른 관리자가 수락하였거나 취소된 요청입니다.`;
        notifyList[
          index
        ].message.en = `Requests already accepted or canceled by another administrator.`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}팀 초대를 이미 수락하였습니다`;
        notifyList[
          index
        ].message.en = `I've accepted your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 이미 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `You have already accepted the invitation of collaborator ${notifyList[index].mainTargetName}`;
      }
    }
    const selectNotifyList = notifyList.filter((item) => item.isConfirm);
    this.setState({ notifyList, selectNotifyList });
  };
  handleButtonDeclineClick = async (notifyId, index) => {
    const notifyList = this.state.notifyList;
    try {
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 ${notifyList[index].subTargetName}팀 가입 요청을 거절하였습니다.`;
        notifyList[
          index
        ].message.en = `${notifyList[index].mainTargetName} team join request has been declined`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName} 팀 초대를 거절하였습니다`;
        notifyList[
          index
        ].message.en = `I've declined your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `I declined ${notifyList[index].mainTargetName} as a collaborator.`;
      }
      notifyList[index].isConfirm = false;
      this.setState({ notifyList });
      await PatchNotifyReject(notifyId);
    } catch (e) {
      console.dir(e);
    }
  };
  handleNotifyAllDelete = async () => {
    try {
      this.setState({ notifyList: [] });
      await DeleteNotifyAll();
      this.getNotify();
    } catch (e) {
      console.dir(e);
    }
  };
  antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
  );
  render() {
    return (
      <Spin
        indicator={this.antIcon}
        spinning={this.state.isLoading}
        delay={500}
      >
        <div className="notify_center-container">
          {this.state.projInfoRender ? (
            <ProjectDialog
              timerSet={this.timerSet}
              userId={this.props.myInfo._id}
              open={this.state.projectDialogOpen}
              onClose={this.handleProjectDialogClose}
              projInfo={this.state.projectInfo}
              history={this.props.history}
            ></ProjectDialog>
          ) : null}
          <div className="notify-wrapper">
            <div className="notify-columns">
              {this.state.notifyList[0] && this.state.notifyList.length
                ? this.state.notifyList.map((item, index) => {
                    return (
                      <div
                        key={`notify-list_${item._id}`}
                        className="notify-column"
                        onClick={() => this.handleClickedNotify(item)}
                      >
                        <div className="column-box">
                          <div className="column-left">
                            <div className="column-img">
                              <ProfilePhoto src={item.photo} size={36} />
                              <div
                                className={`unReadDot ${
                                  item.isRead ? null : "unread"
                                }`}
                              ></div>
                            </div>

                            <div className="notify-text">
                              <span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      i18n.language === "en"
                                        ? item.message.en
                                        : item.message.ko,
                                  }}
                                ></span>
                                {item.isConfirm ? (
                                  <div className="notify-btns">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.handleButtonDeclineClick(
                                          item._id,
                                          index
                                        )
                                      }
                                    >
                                      {i18n.t("header.notify.btn.decline")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.handleButtonAccept(
                                          item._id,
                                          item,
                                          index
                                        )
                                      }
                                    >
                                      {i18n.t("header.notify.btn.accept")}
                                    </button>
                                  </div>
                                ) : null}
                              </span>
                              <span className="notify-time">
                                {timeBefore(item.createdAt)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : !this.state.isFirstLoading && (
                    <div className="notify-empty">
                      <img src={notifyIcon} alt="empty-notification" />
                      <span>There is no message</span>
                    </div>
                  )}
            </div>
            {this.state.notifyList ? (
              <div
                className={
                  this.state.isNotifyLoadDisable
                    ? "notify-more disable"
                    : "notify-more"
                }
                onClick={
                  this.state.isNotifyLoadDisable ? null : this.notifyLoadMore
                }
              >
                <span>{i18n.t("header.notify.more")}</span>
              </div>
            ) : null}
          </div>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  socket: state.socketStatus.socket,
  myInfo: state.myInfo.myInfo,
});

export default compose(
  connect(mapStateToProps, null),
  withSnackbar
)(Notification);
