import React, { Component } from "react";
import "./Introduction.scss";
import IntroBox from "./IntroBox_1/IntroBox_1";
import PursuitBox from "./PursuitBox/PursuitBox";
import CriteriaBox from "./CriteriaBox/CriteriaBox";
import BtIntroBox from "./IntroBox_2/IntroBox_2";

import i18n from "../i18n";

class Intorduction extends Component {
  render() {
    return (
      <>
        <div className="introdution-container">
          <IntroBox />
          <PursuitBox />
          <CriteriaBox />
          <BtIntroBox />
        </div>
      </>
    );
  }
}

export default Intorduction;
