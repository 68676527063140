import React, { Component } from "react";
import "./NotFound.scss";
import NotFoundIcon from "../image/page-not-found-icon.svg";
import { isMobile } from "react-device-detect";
import i18n from "../i18n";
class NotFound extends Component {
  componentDidMount = () => {
    console.log(this.props.history.location.pathname);
    if (this.props.history.location.pathname === "/not-found/") {
      this.props.history.push("/not-found");
    }
  };
  renderContenet = () => {
    if (!isMobile) {
      return (
        <div className="main-container not-foundPage">
          <div className="not-foundBox">
            <div className="not-foundTitle">
              <span>404</span>
              <span>{i18n.t("notFound.title")}</span>
            </div>
            <img src={NotFoundIcon} alt="Not_Found_Page" />
            <div className="not-foundText">
              {i18n.language === "en" ? (
                <span>
                  The page you are looking for doesn't exist
                  <br />
                  or an other error occurred.
                </span>
              ) : (
                <span>
                  찾고있는 페이지가 존재하지 않거나
                  <br />
                  다른 오류가 발생했습니다.
                </span>
              )}
            </div>
            <div className="not-foundReturn">
              <button
                className="not-foundReturnBtn"
                onClick={this.handleToBack}
              >
                {i18n.t("global.return")}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-container not-foundPage">
          <div className="not-foundBox">
            <img src={NotFoundIcon} alt="Not_Found_Page" />
            <div className="not-foundTitle">
              <span>404</span>
              <span>{i18n.t("notFound.title")}</span>
            </div>
          </div>
        </div>
      );
    }
  };
  handleToBack = () => {
    this.props.history.push("/");
  };

  render() {
    return this.renderContenet();
  }
}

export default NotFound;
