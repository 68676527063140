import React, { Component } from "react";
import "./Contents.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import Pages from "./Pages";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
class Contents extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <div className="contents-container">
        <Switch>
          {Pages().map((item) => {
            return (
              <Route
                key={item.path}
                exact={item.exact}
                path={`/admin${item.path}`}
                component={item.component}
              ></Route>
            );
          })}
          <Redirect to="/not-found" />
        </Switch>
      </div>
    );
  }
}

export default Contents;
