import React, { Component } from "react";
import "../EmbedModal/EmbedModal.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import Media from "react-media";
import i18n from "../../i18n";
// import urlEmbed from "url-embed";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Fragment } from "react";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
// let EmbedEngine = urlEmbed.EmbedEngine;
// let Embed = urlEmbed.Embed;
// let engine = new EmbedEngine({
//   timeoutMs: 2000,
//   referrer: "www.example.com",
// });
// engine.registerDefaultProviders();

// let embedOptions = {
//   maxHeight: 300,
// };
class UrlModal extends Component {
  state = {
    url: "",
    alertMsg: "",
    code: true,
  };
  sendData = async () => {
    const url = this.convertEmbedMedia(this.state.url);
    console.log(url);
    if (!url) {
      this.setState(
        {
          alertMsg: i18n.t("urlConvert.notSupportAlert"),
          code: true,
        },
        () => console.log(this.state)
      );
    } else {
      await this.props.load(url);
      this.setState({ url: "", code: true });
    }
  };

  handleEmbedInput = (e) => {
    const url = e.target.value.trim();
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!regex.test(url)) {
      this.setState({ alertMsg: i18n.t("urlConvert.alertValid"), code: true });
    } else {
      this.setState({ url, code: false });
    }
  };
  convertEmbedMedia = (html) => {
    const pattern1 =
      /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:.*\/)?(.+)/g;
    const pattern2 =
      /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    const pattern3 =
      /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;
    let embed = "";
    if (pattern1.test(html)) {
      let replacement =
        '<iframe width="490" height="240" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
      embed = html.replace(pattern1, replacement);
    } else if (pattern2.test(html)) {
      let replacement =
        '<iframe width="490" height="240" src="http://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
      embed = html.replace(pattern2, replacement);
    } else if (pattern3.test(html)) {
      let replacement =
        '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
      embed = html.replace(pattern3, replacement);
    }
    return embed;
  };

  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 599px)",
            desktop: "(min-width: 600px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.desktop && (
                <Dialog
                  fullWidth={this.props.fullWidth}
                  className={"embedDialog-container"}
                  maxWidth={"sm"}
                  open={open}
                  onClose={onClose}
                >
                  <div className="closeBtn" onClick={onClose}>
                    <img src={closeIcon} alt="close" />
                  </div>
                  <div className="one-button-dialog-container embedDialog">
                    <div className="embedDialog-box">
                      <div className="embedDialog-title">
                        <span>{i18n.t("urlConvert.header")}</span>
                      </div>
                      <div className="embedInputField">
                        <input
                          type="text"
                          onChange={this.handleEmbedInput}
                          defaultValue={this.state.url}
                          placeholder={i18n.t("urlConvert.header.placerholder")}
                        ></input>
                        <span className="errorMsg">
                          {this.state.code ? this.state.alertMsg : null}
                        </span>
                      </div>
                      <div className="embedExText" spellCheck="false">
                        {/* {i18n.t("embedUpload.dialog.ex_1")} */}
                        <span>{i18n.t("urlConvert.ex")}</span>
                      </div>

                      <div className="embedDialog-btns">
                        <button className="closeButton" onClick={onClose}>
                          {i18n.t("global.close")}
                        </button>

                        <button
                          disabled={this.state.code}
                          className={
                            !this.state.code ? "activeClick" : "disableClick"
                          }
                          onClick={!this.state.code ? this.sendData : null}
                        >
                          {i18n.t("global.complete")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog>
              )}
              {matches.mobile && (
                <BottomSheet
                  open={open}
                  onDismiss={onClose}
                  // blocking={false}
                  // header={
                  // }
                  // snapPoints={({ maxHeight }) => [
                  //   maxHeight / 4,
                  //   maxHeight * 0.6,
                  // ]}
                >
                  <div className="one-button-dialog-container embedDialog">
                    <div className="embedDialog-box">
                      <div className="embedDialog-title">
                        <span>{i18n.t("urlConvert.ex")}</span>
                      </div>
                      {/* <div className="embedExText" spellCheck="false">
                        {/* {i18n.t("embedUpload.dialog.ex_1")} 
                        <span>{i18n.t("urlConvert.ex")}</span>
                      </div> */}
                      <div className="embedInputField">
                        <input
                          type="text"
                          onChange={this.handleEmbedInput}
                          defaultValue={this.state.url}
                          placeholder={i18n.t("urlConvert.header.placerholder")}
                        ></input>
                        <span className="errorMsg">
                          {this.state.code ? this.state.alertMsg : null}
                        </span>
                      </div>

                      <div className="embedDialog-btns">
                        <button
                          disabled={this.state.code}
                          className={
                            !this.state.code ? "activeClick" : "disableClick"
                          }
                          onClick={!this.state.code ? this.sendData : null}
                        >
                          {i18n.t("global.complete")}
                        </button>
                      </div>
                    </div>
                  </div>
                </BottomSheet>
              )}
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}

export default withStyles(styles)(UrlModal);
