import axios from "axios";

import { GetUserToken } from "../../../modules/userAuth";

export const GetAllUserList = async (
  searchString,
  skip,
  limit,
  filter,
  order,
  isASC,
  sttDate,
  endDate
) => {
  const token = GetUserToken();
  try {
    let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-users?searchString=${searchString}`;
    if (skip) url += `&skip=${skip}`;
    if (limit) url += `&limit=${limit}`;
    if (filter) url += `&filter=${filter}`;
    if (order) url += `&order=${order}`;
    if (isASC) url += `&isASC=${isASC}`;
    if (sttDate) url += `&sttDate=${sttDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data.data);
    return response.data.data;
  } catch (e) {
    return e.response;
  }
};
export const GetAdminUserInfo = async (userId) => {
  try {
    const token = GetUserToken();
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/user-info?id=${userId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    return e.response;
  }
};
export const PatchUserAuthChange = async (userId, auth) => {
  try {
    const token = GetUserToken();
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/to-admin`,
      { id: userId, authority: auth },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (e) {}
};
export const PostUserRemove = async (ids) => {
  try {
    const token = GetUserToken();
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users-delete`;
    const response = await axios.patch(
      url,
      {
        ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
};
