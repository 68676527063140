import React, { Component } from "react";
import "./FindNewPW.scss";

import i18n from "../../i18n";
import {
  PatchNewPassword,
  PostPWResetCodeCheck,
} from "../../Api/Account/account";
import { withSnackbar } from "notistack";
class FindNewPW extends Component {
  state = {
    pwSucess: false,
    password: "",
    email: "",
    pwMsg: "",
    confirmPassword: "",
    titleMsg: "",
  };
  handlePasswordInput = (e) => {
    const password = e.target.value.trim();
    const passwordLowerRegex = /[a-z]/;
    const passwordUpperRegex = /[A-Z]/;
    const passwordNumberRegex = /[0-9]/;
    const passwordSpcialRegex = /[~!@#$%^&*()_+|<>?:{}]/;
    if (this.state.isCapsLockOn) {
      this.setState({
        password,
        pwMsg: i18n.t("userInfo.passwordCapsLock"),
      });
    } else if (password.length === 0) {
      this.setState({
        password,
        pwMsg: i18n.t("userInfo.passwordInput"),
      });
    } else if (password.length < 8 || password.length > 32) {
      this.setState({
        password,
        pwMsg: i18n.t("userInfo.passwordLength"),
      });
    } else if (
      !passwordLowerRegex.test(password) ||
      !passwordUpperRegex.test(password) ||
      !passwordNumberRegex.test(password) ||
      !passwordSpcialRegex.test(password)
    ) {
      this.setState({
        password,
        pwMsg: i18n.t("userInfo.passwordCondition"),
      });
    } else if (password !== this.state.confirmPassword) {
      this.setState({
        password,
        pwMsg: i18n.t("userInfo.confirmPassword"),
        pwSucess: false,
      });
    } else {
      if (password === this.state.confirmPassword) {
        this.setState({
          password,
          pwMsg: "",
          pwSucess: true,
        });
      } else {
        this.setState({
          password,
          pwMsg: "",
        });
      }
    }
  };
  handleChangeConfirmPW = (e) => {
    const password = this.state.password;
    const confirmPassword = e.target.value.trim();
    if (password !== confirmPassword) {
      this.setState({
        pwMsg: i18n.t("userInfo.confirmPassword"),
        pwSucess: false,
      });
    } else {
      this.setState({
        confirmPassword,
        pwSucess: true,
        pwMsg: "",
      });
    }
  };
  constructor() {
    super();
    this.state = {
      titleMsg: i18n.t("findPassword.title"),
    };
  }
  componentDidMount = async () => {
    const code = window.location.search;

    try {
      const authority = await PostPWResetCodeCheck(code);

      this.setState({
        email: authority.data,
      });

      if (authority.message === "sucess") {
        return;
      }
    } catch (e) {
      // alert(i18n.t("global.expired.link"));
      this.handleAlert(i18n.t("global.expired.link"));
      this.props.history.push("/");

      console.log(e);
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };

  toComplete = async () => {
    try {
      const response = await PatchNewPassword(
        this.state.email,
        this.state.password
      );

      if (response.message === "success") {
        this.props.history.push("/findPWComplete");
        this.setState({ titleMsg: i18n.t("findPassword.completeMsg") });
      }
    } catch (e) {
      console.log(e);
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert(i18n.t("global.error.server.msg"));
    }
  };
  toLogin = () => {
    this.props.history.push("/login");
    // window.location.reload();
  };
  render() {
    return (
      <div className="newPW_container">
        <div className="newPW_box">
          <div className="newPW_header">
            <span>{this.state.titleMsg}</span>
          </div>
          <div className="newPW_input">
            <>
              <span style={{ alignSelf: "start" }}>
                {i18n.t("findPassword.input")}
              </span>
              <input
                type="password"
                placeholder={i18n.t("findPassword.input")}
                onChange={this.handlePasswordInput}
              ></input>
              <span style={{ alignSelf: "start" }}>
                {i18n.t("findPassword.confirm")}
              </span>
              <input
                type="password"
                placeholder={i18n.t("findPassword.confirm")}
                onChange={this.handleChangeConfirmPW}
              ></input>
              <span className="pwAlertMsg">{this.state.pwMsg}</span>
              {this.state.pwSucess ? (
                <button
                  className={"activeBtn"}
                  id="nextBtn"
                  onClick={this.state.pwSucess ? this.toComplete : null}
                  type="button"
                >
                  {i18n.t("global.change")}
                </button>
              ) : null}
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(FindNewPW);
