import React, { Component } from "react";
//import EmptyIcon from "../../image/Empty/non-existent-image-icon.svg";
import EmptyIcon from "../../image/no-result-icon.svg";

import "./EmptyStates.scss";
import i18n from "../../i18n";

class EmptyStates extends Component {
  render() {
    return (
      <div className="emptyStates-container">
        <img src={EmptyIcon} alt="EmptySearchResult" />
        <div className="emptyStates-title">
          {i18n.language === "en" ? (
            <span>No search result</span>
          ) : (
            <span>검색결과가 존재하지 않습니다.</span>
          )}
        </div>
      </div>
    );
  }
}

export default EmptyStates;
