import axios from "axios";
import { GetUserToken } from "../../../modules/userAuth";

export const PatchProject = async (id, category, tags) => {
  try {
    const token = GetUserToken();
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/modify-project`;
    const body = {
      id,
      category,
      tags,
    };
    const response = await axios.patch(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (e) {
    return e.response;
  }
};

export const PostDeleteProject = async (ids) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/projects-delete`;
  const response = await axios.patch(
    url,
    {
      ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const GetAllProjectList = async (
  filter,
  searchString,
  limit,
  skip,
  order,
  isAsc,
  sttDate,
  endDate
) => {
  try {
    const token = GetUserToken();
    const body = {
      filter,
      searchString,
      limit,
      skip,
      order,
      isAsc,
      sttDate,
      endDate,
    };
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-projects`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data.response;
  } catch (e) {
    return e.response;
  }
};
