export const renderNotifyMsg = (notifyInfo) => {
  if (notifyInfo.notifyType === "TEAM_APPLIED") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>
      님이 <span class="notify-bold">${notifyInfo.subTargetName}</span>팀 가입을 요청했습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> applied to your team.`;
  } else if (notifyInfo.notifyType === "TEAM_JOINED") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>팀 참여 요청이 승인되어 팀에 참여 되었습니다.`;
    notifyInfo.message.en = `Your request to join <span class="notify-bold">${notifyInfo.mainTargetName}</span> team has been approved.`;
  } else if (notifyInfo.notifyType === "TEAM_INVITE") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>
      팀에서 귀하를 초대했습니다.`;
    notifyInfo.message.en = `Your request to join <span class="notify-bold">${notifyInfo.mainTargetName}</span> team has been approved.`;
  } else if (notifyInfo.notifyType === "TEAM_DROPPED") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>팀에서 제외되었습니다.`;
    notifyInfo.message.en = `You were dropped by <span class="notify-bold">${notifyInfo.mainTargetName}</span>team.`;
  } else if (notifyInfo.notifyType === "TEAM_AUTHORITY") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>팀에서의 권한이 <span class="notify-bold">${notifyInfo.subTargetName}</span>로 변경되었습니다.`;
    notifyInfo.message.en = `The authority of your <span class="notify-bold">${notifyInfo.mainTargetName}</span> team has been changed to <span class="notify-bold">${notifyInfo.subTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "TEAM_JOIN") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>팀에 <span class="notify-bold">${notifyInfo.subTargetName}</span>님이 신규로 가입되었습니다.`;
    notifyInfo.message.en = `New member <span class="notify-bold">${notifyInfo.subTargetName}</span> joined in <span class="notify-bold">${notifyInfo.mainTargetName}</span> team.`;
  } else if (notifyInfo.notifyType === "FOLLOW_NEW") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>님이 나를 팔로우하기 시작했습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> is starting to follow me.`;
  } else if (notifyInfo.notifyType === "PROJECT_NEW") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>님이 새로운 프로젝트 <span class="notify-bold">${notifyInfo.subTargetName}</span>를 업로드 하였습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> uploaded a new project <span class="notify-bold">${notifyInfo.subTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "PROJECT_COLLAB") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>님이 <span class="notify-bold">${notifyInfo.subTargetName}</span>의 공동작업자로 귀하를 초대하였습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> has invited you as a collaborator for <span class="notify-bold>${notifyInfo.subTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "PROJECT_LIKE") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>님이 <span class="notify-bold">${notifyInfo.subTargetName}</span>에 좋아요를 남겼습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> liked your post <span class="notify-bold>${notifyInfo.subTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "PROJECT_COMMENT") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.mainTargetName}</span>님이 <span class="notify-bold">${notifyInfo.subTargetName}</span>에 댓글을 남겼습니다.`;
    notifyInfo.message.en = `<span class="notify-bold">${notifyInfo.mainTargetName}</span> commented to your post <span class="notify-bold>${notifyInfo.subTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "JOB_NEW") {
    notifyInfo.message.ko = `<span class="notify-bold">${notifyInfo.subTargetName}</span>팀에서 신규 채용 <span class="notify-bold>${notifyInfo.mainTargetName}</span>가 등록되었습니다.`;
    notifyInfo.message.en = `New recruitment <span class="notify-bold">${notifyInfo.subTargetName}</span> has been registered for team <span class="notify-bold>${notifyInfo.mainTargetName}</span>.`;
  } else if (notifyInfo.notifyType === "JOB_MODIFIED") {
    notifyInfo.message.ko = `${
      true ? "북마크한" : "지원한"
    }<span class="notify-bold>${
      notifyInfo.subTargetName
    }</span>팀의 채용 <span class="notify-bold>${
      notifyInfo.mainTargetName
    }</span> 상세정보가 수정되었습니다.`;
    notifyInfo.message.en = `The details of the recruitment <span class="notify-bold>${
      notifyInfo.subTargetName
    }</span> of the ${
      true ? "bookmarked" : "applied"
    } <span class="notify-bold>${
      notifyInfo.mainTargetName
    }</span> team have been modified.`;
  } else if (notifyInfo.notifyType === "JOB_ENDED") {
    notifyInfo.message.ko = `<span class="notify-bold>${notifyInfo.mainTargetName}</span>팀에서 <span class="notify-bold>${notifyInfo.subTargetName}</span> 채용을 조기 마감 하였습니다.`;
    notifyInfo.message.en = `The <span class="notify-bold>${notifyInfo.mainTargetName}</span> team closed the hiring of <span class="notify-bold>${notifyInfo.subTargetName}</span> early.`;
  } else if (notifyInfo.notifyType === "JOB_LEFT_ONEDAY") {
    notifyInfo.message.ko = `${
      true ? "북마크한" : "지원한"
    } <span class="notify-bold>${
      notifyInfo.mainTargetName
    }</span>팀의 채용 <span class="notify-bold>${
      notifyInfo.subTargetName
    }</span> 마감 1일전 입니다.`;
    notifyInfo.message.en = `1 day before the deadline for hiring <span class="notify-bold>${
      notifyInfo.subTargetName
    }</span> teams that ${
      true ? "bookmarked" : "applied"
    } <span class="notify-bold>${notifyInfo.mainTargetName}</span>.`;
  }

  return notifyInfo;
};
