import React from "react";
import CheckBox from "../../image/check-off-box-icon.svg";
import CheckedBox from "../../image/check-on-box-icon.svg";
import RemoveIcon from "../../image/close-icon.svg";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
function Memberlist({
  members,
  memberAuth,
  handleRemoveUser,
  handleClickedAuth,
  userAuth,
}) {
  return (
    <div className="members_list-container">
      <div className="body-list">
        {members.map((member, key) => (
          <div className="body-items" key={key}>
            <div className="item-userInfo">
              <ProfilePhoto src={member.profilePhoto} size={40} />
              <span>{member.userName}</span>
            </div>
            <div className="item-userAuth">
              {/* <span>{item.authority}</span> */}
              <div className="auth-list">
                {userAuth === "member" ? (
                  <>
                    {memberAuth.slice(0, -1).map((item, idx) => {
                      return (
                        <div
                          className="auth-checkBox"
                          onClick={() =>
                            handleClickedAuth(item.auth, member._id)
                          }
                          key={idx}
                          style={{ marginRight: "unset" }}
                        >
                          <img
                            src={
                              item.auth === member.authority
                                ? CheckedBox
                                : CheckBox
                            }
                            alt="text"
                          />
                          <span>{item.authName}</span>
                        </div>
                      );
                    })}{" "}
                  </>
                ) : (
                  <>
                    {memberAuth.map((item, idx) => {
                      return (
                        <div
                          className="auth-checkBox"
                          onClick={() =>
                            handleClickedAuth(item.auth, member._id)
                          }
                          key={idx}
                        >
                          <img
                            src={
                              item.auth === member.authority
                                ? CheckedBox
                                : CheckBox
                            }
                            alt="text"
                          />
                          <span>{item.authName}</span>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div
              className="item-userRemove"
              onClick={() => handleRemoveUser(member._id)}
            >
              <img
                style={{ width: "15px", height: "15px" }}
                src={RemoveIcon}
                alt="invite-member-remove"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Memberlist;
