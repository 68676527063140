import React, { Component } from "react";
import "./JobUpload.scss";
import LoaderDialog from "../../../Component/UploadLoaderDialog/UploadLoaderDialog";
import JobPostingPage from "./JobInfo/JobInfo";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import quertString from "query-string";
import i18n from "../../../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  GetJobDetailInfo,
  PostCreateJob,
  PostCreateJobFailed,
  PostCreateJobFinish,
} from "../../../Api/Job/job";
import { GetTeamInfo } from "../../../Api/Team/Team";
import { convertURLtoFileObj } from "../../../Component/functions";
class JobUpload extends Component {
  state = {
    isLoading: false,
    teamId: "",
    jobId: "",
    position: "",
    payInfo: {
      currency: "KRW",
      minimum: 0,
      maximum: 0,
      isPrivate: false,
    },
    workingHours: "",
    tags: [],
    periodInfo: {
      strDate: "",
      endDate: "",
      regularBasis: false,
    },
    countryInfo: {
      countryName: "",
      countryCode: "",
      region: "",
    },
    selectedCareer: [],
    selectedWorkTime: [],
    mainTask: "",
    detailTask: "",
    selectedCategory: "",
    fileList: [],
    loaderDialogOpen: false,
    isUpload: false,
    remoteWork: false,
    linkURL: "",
    isModify: false,
  };
  qs = quertString.parse(this.props.history.location.search);
  componentDidMount = () => {
    const param = this.props.history.location.state;

    if (this.qs.mode !== "Edit") {
      if (this.props.history.location.state) {
        this.setState(
          { teamId: param.teamId },
          async () => await this.getTeamInfo()
        );
      } else {
        this.props.history.push("/not-found");
      }
    } else {
      this.setState(
        { teamId: param.teamId, jobId: param.jobId, isModify: true },
        async () => {
          await this.getJobInfo();
          this.getTeamInfo();
        }
      );
    }
  };
  getTeamInfo = async () => {
    try {
      const response = await GetTeamInfo(this.state.teamId);
      this.setState({ linkURL: response.linkURL });
    } catch (e) {
      console.log(e);
    }
  };
  getJobInfo = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await GetJobDetailInfo(this.state.jobId);
      this.setEditJobInfo(response);
    } catch (e) {}
  };

  setEditJobInfo = (data) => {
    this.setState(
      {
        payInfo: {
          //...this.state.payInfo,
          currency: data.payInfo.currency,
          minimum: data.payInfo.minimum,
          maximum: data.payInfo.maximum,
          isPrivate: data.isPrivate,
        },
        countryInfo: {
          // ...this.state.countryInfo,
          countryName: data.countryInfo.countryName,
          countryCode: data.countryInfo.countryCode,
          region: data.countryInfo.region,
        },
        periodInfo: {
          //...this.state.periodInfo,
          strDate: !data.periodInfo.regularBasis ? data.periodInfo.strDate : "",
          endDate: !data.periodInfo.regularBasis ? data.periodInfo.endDate : "",
          regularBasis: data.periodInfo.regularBasis,
        },
        selectedCategory: data.category,
        selectedWorkTime: data.workingHours,
        selectedCareer: data.careerStatus,
        remoteWork: data.remoteAvailable,
        position: data.position,
        mainTask: data.keyTasks,
        detailTask: data.jobDetail,
        tags: data.tags ? data.tags : [],
      },
      async () => {
        try {
          if (data.images && data.images.length) {
            let fileList = this.state.fileList;
            data.images.map(async (src, index) => {
              const data = {
                fileObj: "",
                preview: src,
              };
              const file_object = await convertURLtoFileObj(src, "image");
              data.fileObj = file_object;
              fileList.push(data);
              this.setState({ fileList, isLoading: false }, () => {
                this.infoSet();
              });
            });
          }
        } catch (e) {
          console.log(e);
        }
        this.setState({ isLoading: false });
      }
    );
  };
  infoSet = () => {
    return this.state;
  };
  handleJobUpload = async () => {
    this.setState({ loaderDialogOpen: true });
    try {
      if (!this.state.jobId && this.qs.mode !== "Edit") {
        const createId = await PostCreateJob(this.state.teamId);

        this.setState({ jobId: createId });
      }

      await PostCreateJobFinish(
        this.state.jobId,
        this.state.payInfo,
        this.state.selectedWorkTime,
        this.state.selectedCareer,
        this.state.selectedCategory,
        this.state.countryInfo,
        this.state.periodInfo,
        this.state.position,
        this.state.mainTask,
        this.state.detailTask,
        this.state.remoteWork,
        this.state.tags,
        this.state.fileList,
        this.state.isModify
      );

      this.setState({ loaderDialogOpen: false }, () => {
        this.handleAlert("업로드 성공");
        this.props.history.push(`/team/@${this.state.linkURL}?tab=3`);
      });
    } catch (e) {
      console.dir(e);
      if (e.response) {
        await PostCreateJobFailed(this.state.jobId);
      }
      this.setState({ loaderDialogOpen: false, jobId: "" }, () => {
        this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
          variant: "warning",
          key: "global.error.server.msg",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleUploadInfoSet = (data) => {
    this.setState(
      {
        payInfo: {
          ...this.state.payInfo,
          currency: data.payType,
          minimum: data.minSalary,
          maximum: data.maxSalary,
          isPrivate: data.payVisible,
        },
        position: data.position,
        countryInfo: {
          ...this.state.countryInfo,
          countryCode: data.countryCode,
          countryName: data.countryName,
          region: data.region,
        },
        periodInfo: data.period,
        fileList: data.fileList,
        mainTask: data.mainTask,
        detailTask: data.detailTask,
        remoteWork: data.remoteWork,
        tags: data.tags,
        selectedCategory: data.selectedCategory,
        selectedCareer: data.selectedCareer,
        selectedWorkTime: data.selectedWorkTime,
      },
      () => {
        let fileList = this.state.fileList;
        let images = [];
        for (let i = 0; i < this.state.fileList.length; i++) {
          images.push(fileList[i]);
        }
        this.setState({ fileList: images }, () => {
          this.handleJobUpload();
        });
      }
    );
  };

  toBack = () => {
    this.props.history.goBack();
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );

    return (
      <Spin
        indicator={antIcon}
        spinning={this.state.isLoading}
        delay={500}
        wrapperClassName={"main-spin"}
      >
        <LoaderDialog
          dialogHeadText={i18n.t("upload.loader.title")}
          dialogBodyText1={i18n.t("upload.loader.text")}
          open={this.state.loaderDialogOpen}
          onClose={this.state.isUpload ? this.handleLoaderDialogClose : null}
        />
        {!this.state.isLoading ? (
          <JobPostingPage
            mode={this.qs.mode}
            toBack={this.toBack}
            info={this.infoSet}
            handleInfoSet={this.handleUploadInfoSet}
          />
        ) : null}
      </Spin>
    );
  }
}

export default compose(withSnackbar)(JobUpload);
