import React, { Component } from "react";
import i18n from "../../i18n";
import {
  renderPayInfo,
  renderCareer,
  renderDate,
  numberWithCommas,
  nFormatter,
} from "../functions";
import "./Job.scss";
import calendarIcon from "../../image/job/black-date-icon.svg";
import bookmarkIcon from "../../image/job/bookmark-button-icon.svg";
import G_BookmarkIcon from "../../image/job/gray-bookmark-icon.svg";
import B_BookmarkIcon from "../../image/job/black-bookmark-icon.svg";
import { Tooltip } from "antd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import MobileSeletor from "../MobileSelector/MobileSeletor";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
class Job extends Component {
  state = {
    menuOpen: false,
    selectedJob: {},
    actionBtnList: [
      { id: 1, text: "채용 정보 확인" },
      { id: 2, text: "채용 정보 수정" },
      { id: 3, text: "조기마감" },
      { id: 4, text: "기간연장" },
      { id: 5, text: "삭제" },
    ],
  };
  renderPeriod = (date) => {
    if (date) {
      return date.substring(0, 10).replaceAll("-", ".");
    } else {
      return "";
    }
  };
  renderPayInfo = (payInfo) => {
    if (payInfo.isPrivate) {
      return i18n.t("jobInfo.payment.consultation");
    } else {
      if (i18n.language === "ko" || i18n.language === "ko_KR") {
        if (payInfo.currency === "KRW") {
          return `${numberWithCommas(payInfo.minimum).toLocaleString(
            "ko-KR"
          )} - ${numberWithCommas(payInfo.maximum).toLocaleString(
            "ko-KR"
          )} 만원`;
        } else {
          return `${numberWithCommas(payInfo.minimum).toLocaleString(
            "ko-KR"
          )} - ${numberWithCommas(payInfo.maximum).toLocaleString(
            "ko-KR"
          )} 만원`;
        }
      } else {
        if (payInfo.currency === "USD") {
          return `${nFormatter(payInfo.minimum).toLocaleString(
            "ko-KR"
          )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
        } else {
          return `${nFormatter(payInfo.minimum).toLocaleString(
            "ko-KR"
          )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
        }
      }
    }
  };
  handleDialogOpen = (id, teamId) => {
    console.log(this.props, this.props.history);
    this.props.handleJobDetailDialogOpen(id, teamId);
  };
  handleMenuClick = (id) => {
    const item = this.state.selectedJob;
    switch (id) {
      case 1:
        // this.props.history.push(`${this.props`);
        this.handleDialogOpen(item._id, item.teamInfo._id);
        this.handleMenuClose();
        break;
      case 2:
        this.props.toJobEdit(item._id, item.teamInfo._id);
        this.handleMenuClose();
        break;
      case 3:
        this.props.handleJobDeadLine(item._id);
        this.handleMenuClose();
        break;
      case 4:
        this.props.handleCalendarOpen(
          item._id,
          item.periodInfo.endDate,
          item.index
        );
        this.handleMenuClose();
        break;
      case 5:
        this.props.handleDeleteDialogOpen(item._id, item.teamInfo._id);
        this.handleMenuClose();
        break;
      default:
    }
  };
  renderMenu = (item, index) => {
    return (
      <div
        style={{
          color: "black",
          cursor: "auto",
          display: "flex",
          flexDirection: "column",
        }}
        className="tooltip-menu"
      >
        <button
          onClick={() => this.handleDialogOpen(item._id, item.teamInfo._id)}
        >
          <span>채용 정보 확인</span>
        </button>
        <button
          onClick={() => this.props.toJobEdit(item._id, item.teamInfo._id)}
        >
          <span>채용 정보 수정</span>
        </button>
        <button onClick={() => this.props.handleJobDeadLine(item._id)}>
          <span>조기마감</span>
        </button>
        <button
          onClick={() =>
            this.props.handleCalendarOpen(
              item._id,
              item.periodInfo.endDate,
              index
            )
          }
        >
          <span>기간연장</span>
        </button>
        <button
          onClick={() =>
            this.props.handleDeleteDialogOpen(item._id, item.teamInfo._id)
          }
        >
          <span>삭제</span>
        </button>
      </div>
    );
  };
  renderDeadEnd = (date) => {
    if (date) {
      return `${i18n.t("jobInfo.otherjobs.deadend")} ${renderDate(date, true)}`;
    } else {
      return "";
    }
  };
  toTeamPage = (url) => {
    this.props.history.push(`/team/@${url}`);
  };
  handleMenuOpen = (item, index) => {
    this.setState({ menuOpen: true, selectedJob: { ...item, index } });
  };
  handleMenuClose = () => {
    this.setState({ menuOpen: false, selectedJob: {} });
  };
  hadleDialogOpen = (id) => {};
  renderItem = (jobInfo, key) => {
    if (this.props.row) {
      return this.props.management ? (
        <div
          className="other_jobs"
          key={`${key}_jobs`}
          onClick={() =>
            this.handleDialogOpen(jobInfo._id, jobInfo.teamInfo._id)
          }
        >
          <div className="other_jobs-left nowrap">
            <span className="other_jobs-name">{jobInfo.teamInfo?.name}</span>
            <span className="other_job-title">{jobInfo.position}</span>
            <div className="other_jobs-info">
              <span>{renderPayInfo(jobInfo.payInfo)}</span>
              <span>
                {i18n.language === "en"
                  ? renderCareer(jobInfo.careerStatus, " and ")
                  : renderCareer(jobInfo.careerStatus, " · ")}
              </span>
            </div>
            <div className="other_jobs-tags">
              {jobInfo.tags ? jobInfo.tags.join(" · ") : ""}
            </div>
            <div className="other_jobs-date">
              <img src={calendarIcon} alt="calendarIcon"></img>
              <span>
                {jobInfo.periodInfo.regularBasis
                  ? i18n.t("jobInfo.period.regularbasis")
                  : this.renderDeadEnd(jobInfo.periodInfo.endDate)}
              </span>
              <span>
                {jobInfo.remoteAvailable
                  ? i18n.t("jobInfo.otherjobs.remote")
                  : i18n.t("jobInfo.otherjobs.Residential")}
              </span>
              <span>
                {i18n.t("jobInfo.otherjobs.updatedAt")}{" "}
                {this.renderPeriod(jobInfo.updatedAt)}
              </span>
            </div>
          </div>
          {this.props.bookmark ? (
            <div className="job_big-btns">
              <div
                className="job_big-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.handleDelConfirmDialogOpen(jobInfo._id);
                }}
              >
                <img src={bookmarkIcon} alt="bookmarkBtn" />

                <span>{i18n.t("jobInfo.unmarkBtn")}</span>
              </div>

              <div
                className="job_big-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.handleApply(jobInfo._id, jobInfo.teamInfo._id);
                }}
              >
                {jobInfo.isApplied ? (
                  <span>{i18n.t("jobInfo.cancel.btn")}</span>
                ) : (
                  <span>{i18n.t("jobInfo.btn")}</span>
                )}
              </div>
            </div>
          ) : (
            <div className="job_big-btns">
              <div
                className="job_big-btn"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   this.props.handleApply(jobInfo._id, jobInfo.teamInfo._id);
                // }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.handleDelConfirmDialogOpen(jobInfo._id);
                }}
              >
                <span>{i18n.t("jobInfo.cancel.btn")}</span>
              </div>
            </div>
          )}
        </div>
      ) : this.props.memberMode ? (
        <div
          className="other_jobs"
          key={`${key}_jobs`}
          // onClick={() =>
          //   this.handleJobDetailDialogOpen(item._id, item.teamInfo._id)
          // }
        >
          <div className="other_jobs-left nowrap">
            <span className="other_job-title">{jobInfo.position}</span>
            <div className="other_jobs-info">
              <span>{this.renderPayInfo(jobInfo.payInfo)}</span>
              {/* <span>5,000~8,000만원/</span> */}
              <span>{renderCareer(jobInfo.careerStatus, " · ")}</span>
            </div>

            <div className="other_jobs-date">
              <img src={calendarIcon} alt="calendarIcon"></img>
              <span>
                {jobInfo.periodInfo.regularBasis
                  ? i18n.t("jobInfo.period.regularbasis")
                  : this.renderDeadEnd(jobInfo.periodInfo.endDate)}
              </span>

              {jobInfo.remoteAvailable ? (
                <span>{i18n.t("jobInfo.otherjobs.remote")}</span>
              ) : null}

              <span>{renderDate(jobInfo.updatedAt)} 수정</span>
            </div>
          </div>
          <div className="other_jobs-rtInfo">
            <div className="other_jobs-status">
              <div
                className="top-info"
                onClick={
                  jobInfo.applicant !== 0
                    ? () => this.props.handleApplicantsView(jobInfo._id)
                    : null
                }
              >
                <span>
                  {i18n.t("teamPage.recruitment.header2")}{" "}
                  {nFormatter(jobInfo.applicant)}
                </span>
                <span className="unread-applicants">
                  {i18n.t("teamPage.applicants.unidentified")}{" "}
                  {nFormatter(jobInfo.unCheckedApplicant)}
                </span>
              </div>
              <div className="bt-info">
                <span>
                  {i18n.t("mypage.profile.views")} {nFormatter(jobInfo.views)}
                  {/* {` · `} */}
                </span>
                <span className="bt-separator" style={{ margin: "0 3px" }}>
                  ·
                </span>
                <span>
                  {" "}
                  {i18n.t("jobInfo.bookmarkBtn")}{" "}
                  {nFormatter(jobInfo.bookmarkCount)}
                </span>
              </div>
            </div>
            <div className="more_btn">
              <Tooltip
                overlay={this.renderMenu(jobInfo, key)}
                trigger="click"
                placement="leftTop"
                arrow
                color="white"
              >
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon style={{ fontSize: "1.5rem" }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className="more_btn-m">
              <IconButton
                onClick={() => this.handleMenuOpen(jobInfo, key)}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
              >
                <MoreVertIcon style={{ fontSize: "1.5rem" }} />
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="other_jobs"
          key={`${key}_jobs`}
          //   style={
          //     this.props.list.length === 1 ? { borderBottom: "none" } : null
          //   }
          onClick={() =>
            this.handleDialogOpen(jobInfo._id, jobInfo.teamInfo._id)
          }
        >
          <div className="other_jobs-left nowrap">
            <span className="other_job-title nowrap">{jobInfo.position}</span>
            <div className="other_jobs-info nowrap">
              <span>{this.renderPayInfo(jobInfo.payInfo)}</span>
              <span>
                {i18n.language === "en"
                  ? renderCareer(jobInfo.careerStatus, " and ")
                  : renderCareer(jobInfo.careerStatus, " · ")}
              </span>
            </div>

            <div className="other_jobs-date nowrap">
              <img src={calendarIcon} alt="calendarIcon"></img>
              <span>
                {jobInfo.periodInfo.regularBasis
                  ? i18n.t("jobInfo.period.regularbasis")
                  : this.renderDeadEnd(jobInfo.periodInfo.endDate)}
              </span>

              {jobInfo.remoteAvailable ? (
                <span>{i18n.t("jobInfo.otherjobs.remote")} </span>
              ) : null}

              <span>
                {i18n.t("jobInfo.otherjobs.updatedAt")}{" "}
                {jobInfo.updatedAt.substring(5, 10).replaceAll("-", ".")}
              </span>
            </div>
          </div>
          <div className="job_big-btns">
            <div
              className="job_big-btn"
              onClick={(e) => {
                e.stopPropagation();
                this.props.handleBookMark(jobInfo._id);
              }}
            >
              <img src={bookmarkIcon} alt="bookmarkBtn" />
              {jobInfo.isFavorite ? (
                <span>{i18n.t("jobInfo.unmarkBtn")}</span>
              ) : (
                <span>{i18n.t("jobInfo.bookmarkBtn")}</span>
              )}
            </div>
            <div
              className="job_big-btn"
              onClick={(e) => {
                e.stopPropagation();
                this.handleDialogOpen(jobInfo._id, jobInfo.teamInfo._id);
              }}
            >
              {jobInfo.isApplied ? (
                <span>{i18n.t("jobInfo.cancel.btn")}</span>
              ) : (
                <span>{i18n.t("jobInfo.btn")}</span>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={key}
          className="jobInfo-contentBox"
          onClick={() =>
            this.handleDialogOpen(jobInfo._id, jobInfo.teamInfo._id)
          }
        >
          <div className="jobInfo-screen">
            {jobInfo.thumbnail ? (
              <div
                className="jobInfo-thumbnail"
                style={{
                  backgroundImage: `url(${jobInfo.thumbnail})`,
                }}
              ></div>
            ) : (
              <div className="placeholder"></div>
            )}
            <div className="hidden-screen"></div>
          </div>
          <div className="jobInfo-title">
            <div className="jobInfo-titleHeader">
              {jobInfo.category ? (
                <div className="redTag nowrap">
                  <span>
                    {i18n.language === "en"
                      ? jobInfo.category.name.en
                      : jobInfo.category.name.ko}
                  </span>
                </div>
              ) : null}

              <div className="jobInfo-career nowrap">
                <span className="">
                  {renderCareer(jobInfo.careerStatus, "/")}
                </span>
              </div>
            </div>
            <div className="jobInfo-position">
              <span>
                {jobInfo.position.length >= 30
                  ? jobInfo.position.substring(0, 30) + "..."
                  : jobInfo.position}
              </span>
            </div>
            <div className="jobInfo-bottomInfo">
              <div
                className="bottom_teamInfo"
                onClick={(e) => {
                  e.preventDefault();
                  this.toTeamPage(jobInfo.teamInfo.linkURL);
                }}
              >
                <ProfilePhoto src={jobInfo.teamInfo.teamPhoto} size={24} />
                <span className="teamName">{jobInfo.teamInfo.name}</span>
              </div>
              <button
                type="button"
                id={`bookmark_btn-${key}`}
                // disabled={true}

                onClick={(e) => {
                  this.props.handleJobListBookmark(jobInfo._id, key);
                  e.stopPropagation();
                }}
              >
                <img
                  src={jobInfo?.isFavorite ? B_BookmarkIcon : G_BookmarkIcon}
                  alt="bookmark"
                />
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  render() {
    const { jobInfo, index } = this.props;

    return (
      <>
        <MobileSeletor
          open={this.state.menuOpen}
          onClose={this.handleMenuClose}
          selecList={this.state.actionBtnList}
          handleSelec={this.handleMenuClick}
        />
        {this.renderItem(jobInfo, index)}
      </>
    );
  }
}
export default Job;
