import React, { Component } from "react";
import Select, { components } from "react-select";
import SearchInput from "react-search-input";
import "./FilterArea.scss";
// import upArrowIcon from "../../../../image/Admin/line-red-arrow-up-icon.svg";
import downArrowIcon from "../../../../image/Admin/line-red-arrow-up-icon.svg";
// import searchIcon from "../../../../image/search-red-icon.svg";
import { DatePicker } from "antd";
import moment from "moment";
import i18n from "../../../../i18n";

const { RangePicker } = DatePicker;
// const KEYS_TO_FILTERS = ["user.name", "subject", "dest.name"];

class FilterArea extends Component {
  onDateChange = async (dates, dateStrings) => {
    const strDate = new Date(dates[0]).toISOString();
    const endDate = new Date(dates[1]).toISOString();

    // console.log('From: ', dates[0], ', to: ', dates[1]);
    // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    // console.log("날짜 객체 : ", strDate, endDate)
    this.props.handleSearchDates(strDate, endDate);
  };

  render() {
    const themeColor = "#ff2e43";
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? "white" : "black",
        backgroundColor: state.isFocused ? themeColor : "none",
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        border: "solid 1px #e8e8e8",
        display: "flex",
      }),
      // dropdownIndicator: () => ({
      // })
    };

    const customStyles2 = {
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? "white" : "black",
        backgroundColor: state.isFocused ? themeColor : "none",
      }),
      container: () => ({
        maxHeight: "37px",
        flex: "2 1",
        height: "100%",
        minWidth: "100px",
        position: "relative",
        boxSizing: "border-box",
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }),
    };

    const options = [
      { value: 1, label: i18n.t("admin.projects.title") },
      { value: 2, label: i18n.t("project.tagsTitle") },
      { value: 3, label: i18n.t("global.email") },
      { value: 4, label: i18n.t("admin.pages.project.publishDate") },
      { value: 5, label: i18n.t("admin.pages.project.category") },
      { value: 6, label: i18n.t("admin.pages.project.postStatus") },
    ];

    const postStatusOptions = [
      { value: true, label: i18n.t("Draft") },
      { value: false, label: i18n.t("Public") },
    ];

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img
            alt="downArrowIcon"
            src={downArrowIcon}
            className={!props.selectProps.menuIsOpen ? "closed" : "opened"}
          />
        </components.DropdownIndicator>
      );
    };

    return (
      <>
        <div className="FilterArea-container">
          <div className="FilterArea-box">
            <div className="searchTitle">{i18n.t("global.search")}</div>
            <Select
              defaultValue={options[this.props.searchFilter]}
              menuColor={themeColor}
              className="FilterDropDown"
              styles={customStyles}
              placeholder={i18n.t("global.filter")}
              options={options}
              onChange={this.props.searchFilterUpdated}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
            />
            <div className="searchContainer">
              {/* <img
                className="searchIcon"
                src={searchIcon}
                alt="searchIcon"
              ></img> */}
              {this.props.searchFilter <= 3 ? ( // String 입력이 필요한 검색
                <SearchInput
                  placeholder={i18n.t("global.search")}
                  className="search-input"
                  onChange={this.props.searchStringUpdated}
                />
              ) : null}

              {this.props.searchFilter === 5 ? ( // 분야 선택
                <Select
                  menuColor={themeColor}
                  className="FilterDropDown"
                  styles={customStyles2}
                  placeholder={i18n.t("분야")}
                  options={
                    this.props.lang === "ko"
                      ? this.props.categoriesKor
                      : this.props.categoriesEng
                  }
                  onChange={this.props.searchStringSelectionTypeUpdated}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              ) : null}

              {this.props.searchFilter === 6 ? ( // 분야 선택
                <Select
                  menuColor={themeColor}
                  className="FilterDropDown"
                  styles={customStyles2}
                  placeholder={i18n.t("분야")}
                  options={postStatusOptions}
                  onChange={this.props.searchStringSelectionTypeUpdated}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              ) : null}

              {this.props.searchFilter === 4 ? ( // Date Type 검색
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  bordered={false}
                  showTime={false}
                  format={"YYYY/MM/DD"}
                  onChange={this.onDateChange}
                  style={{ outline: "unset" }}
                  placeholder={[
                    i18n.t("admin.placeholder.startDate"),
                    i18n.t("admin.placeholder.endDate"),
                  ]}
                />
              ) : null}
            </div>
            <button onClick={this.props.handleSearch} className="searchBtn">
              {i18n.t("global.search")}
            </button>
          </div>
        </div>
      </>
    );
  }

  // searchUpdated(term) {
  //   this.setState({ searchTerm: term }, ()=> console.log(this.state.searchTerm));
  // }
}

export default FilterArea;
