import React, { Component } from "react";
import "./Header.scss";
import logo from "../../image/logo.svg";
// import { NavLink } from "react-router-dom";
// import i18n from "../../i18n";

class Header extends Component {
  onClickLogo = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="header-container">
        <img src={logo} alt="logo" onClick={this.onClickLogo} />
      </div>
    );
  }
}

export default Header;
