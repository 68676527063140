import React, { Component } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { BottomSheet } from "react-spring-bottom-sheet";
import profileEditBtn from "../../../image/job/edit-profile-icon.svg";
import supportCompleteIcon from "../../../image/job/support-complete-icon.svg";
import XboxIcon from "../../../image/no-result-icon.svg";
import { Spin } from "antd";
import i18n from "../../../i18n";
import { LoadingOutlined } from "@ant-design/icons";
import "./applyDialog.scss";
import BlackButton from "../../../Component/Button/Button";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class ApplyDialog extends Component {
  renderSpin = (size = 24) => {
    return (
      <div className="spin-wrapper">
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: size, color: "#ff2e43" }}
              spin
            />
          }
          spinning={true}
          delay={500}
        ></Spin>
      </div>
    );
  };

  render() {
    const { onClose, open } = this.props;
    const resume = this.props.resume;

    return (
      <BottomSheet
        className={resume === -1 ? "resume-dialog loading" : "resume-dialog"}
        open={open}
        onDismiss={onClose}
      >
        {resume === -1 ? (
          this.renderSpin(50)
        ) : this.props.supported && !resume ? (
          <>
            <span className="supported_title">
              {i18n.t("jobInfo.applied.title")}
            </span>
            <div className="supported_text">
              <img src={supportCompleteIcon} alt="supportCompleteIcon" />
              <span>{i18n.t("jobInfo.applied.text")}</span>
            </div>
            <div className="supported_btn-container">
              <div className="supported_btn" onClick={this.props.supportDone}>
                <span>{i18n.t("global.back")}</span>
              </div>
            </div>
          </>
        ) : this.props.applyCancel ? (
          <>
            {/* <span className="supported_title">
              {i18n.t("jobInfo.cancel.btn")}
            </span> */}
            <div className="supported_text">
              <img src={XboxIcon} alt="errIcon" />
              <span>정말 지원을 취소하시겠습니까?</span>
            </div>
            <div className="apply_btns">
              <button
                type="button"
                className="supported_btn"
                onClick={this.props.handleCancelApply}
              >
                <span>{i18n.t("jobInfo.cancel.btn")}</span>
              </button>
              <button
                type="button"
                className="supported_btn"
                onClick={this.props.applyCancelBack}
              >
                <span>{i18n.t("global.back")}</span>
              </button>
            </div>
          </>
        ) : this.props.err ? (
          <>
            <div className="supported_title">
              {i18n.t("jobInfo.impossible")}
            </div>

            <div className="supported_text">
              <img src={XboxIcon} alt="errIcon" />
              <span> {this.props.errMsg}</span>
            </div>
            <div className="supported_btn-container">
              <BlackButton
                text={i18n.t("project.upload.back")}
                width={"100%"}
                btnClick={onClose}
              />
            </div>
          </>
        ) : (
          <>
            <div className="resume_header">
              <span>{i18n.t("job.resume.title")}</span>
              <div
                className="profile_edit-btn"
                onClick={() =>
                  this.props.history.push(`/setting?profileEdit=user`)
                }
              >
                <img src={profileEditBtn} alt="profileEditBtn" />
              </div>
            </div>
            <div className="resume_info-container">
              <div className="resume_info name">
                <span>{i18n.t("global.name")}</span>
                <span>{resume && resume.userName ? resume.userName : ""}</span>
              </div>
              <div className="resume_info email">
                <span>{i18n.t("global.email")}</span>
                <span>{resume && resume.email ? resume.email : ""}</span>
              </div>
            </div>
            {!resume || !resume.projects || resume.projects.length === 0 ? (
              <div className="resume_empty">
                <span>{i18n.t("jobInfo.project.empty.text")}</span>
                <div
                  className="upload_btn"
                  onClick={() => this.props.history.push("/project-upload")}
                >
                  <span>{i18n.t("global.upload")}</span>
                </div>
              </div>
            ) : (
              <div className="resume_projects-list">
                {resume.projects
                  .filter((item, index) => index < 4)
                  .map((item) => {
                    return <img src={item.thumbnail} alt="project" />;
                  })}
              </div>
            )}
            <div className="apply_btns">
              <button
                type="button"
                className="support_btn"
                onClick={() => onClose()}
              >
                <span>{i18n.t("global.back")}</span>
              </button>
              <button
                type="button"
                className="support_btn support"
                onClick={
                  !resume || !resume.projects || resume.projects.length === 0
                    ? null
                    : this.props.handleApply
                }
                style={
                  !resume || !resume.projects || resume.projects.length === 0
                    ? {
                        backgroundColor: "#efefef",
                        border: "1px solid #efefef",
                        cursor: "default",
                      }
                    : null
                }
              >
                <span>{i18n.t("jobInfo.btn")}</span>
              </button>
            </div>
          </>
        )}
      </BottomSheet>
    );
  }
}
// calendarIcon
export default compose(withStyles(styles))(ApplyDialog);
