import React, { Component } from "react";
import { Table, Button, Menu, Dropdown } from "antd";
import "antd/dist/antd.css";
import "./SearchResultArea.scss";
import Moment from "react-moment";
import "moment/locale/ko";
import NumberFormat from "react-number-format";
import i18n from "../../../../../i18n";

import { confirmAlert } from "react-confirm-alert";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { langChange } from "../../../../../Redux/action/index";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PostUserRemove } from "../../../../../Api/Admin/User/user";
import { withSnackbar } from "notistack";
class SearchResultArea extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
    selectedUserId: "",
  };
  handleUserDetailPopUp = () => {
    // 유저 정보 상세 모달 열기
    this.props.onClickUserInfoDialogOpen(this.state.selectedUserId);
  };
  // alert("준비중인 기능입니다.")
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  menu = (
    <Menu>
      <Menu.Item onClick={this.handleUserDetailPopUp}>
        <div>{i18n.t("admin.users.detailInfo")}</div>
      </Menu.Item>
      <Menu.Item onClick={() => this.handleAlert("준비중인 기능입니다.")}>
        <div>{i18n.t("admin.users.posts")}</div>
      </Menu.Item>
      <Menu.Item onClick={() => this.handleAlert("준비중인 기능입니다.")}>
        <div>{i18n.t("admin.users.comments")}</div>
      </Menu.Item>
      <Menu.Item onClick={() => this.handleAlert("준비중인 기능입니다.")}>
        <div>{i18n.t("admin.users.sendEmail")}</div>
      </Menu.Item>
    </Menu>
  );
  columns = [
    {
      dataIndex: "profilePhoto",
      width: 80,
      // sorter: (a, b) => a.email.length - b.email.length,
      // sorter: true,
      align: "center",
      render: (profilePhoto) => {
        if (profilePhoto) {
          return (
            <div
              style={{
                display: "inline-block",
                backgroundImage: `url(${profilePhoto})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
              }}
            />
          );
        } else {
          return <Avatar size={60} icon={<UserOutlined />} />;
        }
      },
    },
    {
      width: 100,
      title: i18n.t("indivi.signup.userName"),
      dataIndex: "userName",
      ellipsis: true,
      align: "center",
    },

    {
      width: 150,
      title: i18n.t("login.email"),
      dataIndex: "email",
      // sorter: (a, b) => a.email.length - b.email.length,
      sorter: true,
      ellipsis: true,
      align: "center",
    },
    {
      width: 100,
      title: i18n.t("admin.filter.authority"),
      ellipsis: true,
      dataIndex: "authority",
      align: "center",
      filters: [
        {
          text: i18n.t("admin.filter.account.member"),
          value: "user",
        },
        {
          text: i18n.t("admin.filter.account.admin"),
          value: "admin",
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      // onFilter: (value, record) => record.authority.indexOf(value) === 0,
      // sorter: (a, b) => a.userType.length - b.userType.length,
      // sorter: true,
      sortDirections: ["descend"],
      render: (authority) => {
        if (authority === "admin") {
          return <div>{i18n.t("admin.filter.account.admin")}</div>;
        } else {
          return <div>{i18n.t("admin.filter.account.member")}</div>;
        }
      },
    },

    {
      width: 100,
      title: i18n.t("admin.filter.Join Date"),
      ellipsis: true,
      dataIndex: "createdAt",
      // sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sorter: true,
      align: "center",
      render: (createdAt) => {
        if (createdAt) {
          // return <Moment format="YYYY/MM/DD">{createdAt}</Moment>
          return (
            <Moment locale={this.props.lang} fromNow>
              {createdAt}
            </Moment>
          );
        } else {
          return <div>No Data</div>;
        }
      },
    },
    {
      width: 100,
      title: i18n.t("admin.filter.lastLogin"),
      ellipsis: true,
      dataIndex: "lastLoginDate",
      // sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sorter: true,
      align: "center",
      render: (lastLoginDate) => {
        if (lastLoginDate) {
          return (
            <Moment locale={this.props.lang} fromNow>
              {lastLoginDate}
            </Moment>
          );
        } else {
          return <div>{i18n.t("admin.signin.nodata")}</div>;
        }
      },
    },
    {
      // title: '',
      width: 100,
      fixed: "right",
      ellipsis: true,
      render: () => {
        return (
          <Dropdown overlay={this.menu} placement="bottomRight" arrow>
            {/* <Button>bottomCenter</Button> */}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              // onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Dropdown>
        );
      },
    },
  ];

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  checkSorterType = (sorter) => {
    if (sorter === "email") {
      return 1;
    } else if (sorter === "createdAt") {
      return 2;
    } else if (sorter === "lastLoginDate") {
      return 3;
    } else {
      return null;
    }
  };

  handleTableChange = async (a, filters, sorter) => {
    let orderType = this.checkSorterType(sorter.field);
    // console.log(filters.authority[0])
    if (filters.authority) {
      this.props.handleFilterChange(filters.authority[0]);
    } else {
      this.props.handleFilterChange("");
    }

    if (sorter.order === "ascend") {
      this.props.handleSorter(orderType, true);
    } else if (sorter.order === "descend") {
      this.props.handleSorter(orderType, false);
    } else {
      this.props.handleSorter(null, null);
    }
  };

  handleDeleteUsers = async () => {
    this.setState({ loading: true });

    confirmAlert({
      title: "유저 삭제 확인",
      message: `선택된 ${this.state.selectedRowKeys.length}명의 유저를 정말로 삭제하시겠습니까 ? 삭제후에는 복원이 불가합니다.`,

      afterClose: () => {
        this.setState({ loading: false });
      },

      buttons: [
        {
          label: "삭제",
          onClick: async () => {
            try {
              const response = await PostUserRemove(this.state.selectedRowKeys);

              if (response.message === "success") {
                this.setState(
                  { loading: false },
                  () => this.props.getUserList() // 유저 정보 다시 가져옴
                );
                // alert(`유저가 삭제 되었습니다.`);
                this.handleAlert("유저가 삭제 되었습니다.");
              }
            } catch (e) {
              console.dir(e);
            }
          },
        },
        {
          label: "취소",
          onClick: () => {
            this.setState({ loading: false });
            return;
          },
        },
      ],
    });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div className="SearchResultArea-container">
        <div className="resultheader">
          <div className="title">
            {i18n.t("admin.users.all.subtitle")}
            <NumberFormat
              className="title"
              disabled
              value={this.props.totalItems}
              thousandSeparator={true}
            />
          </div>
          <div className="deleteBtnContainer">
            <span className="selectedCount" style={{ marginRight: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span>
            <Button
              type="primary"
              onClick={this.handleDeleteUsers}
              disabled={!hasSelected}
              loading={loading}
            >
              {i18n.t("global.delete")}
            </Button>
          </div>
        </div>
        <div
          className="functionBtnsContainer"
          style={{ marginBottom: 16 }}
        ></div>
        <Table
          className="modal-table"
          // shorthand rowKey
          rowKey="_id"
          // rowKey={obj => obj.id}
          pagination={{
            align: "left",
            total: this.props.totalItems,
            current: this.props.currentPage,
            onChange: this.props.handlePageChange,
            defaultPageSize: this.props.setPageSize,
            // hideOnSinglePage: true,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
            onShowSizeChange: this.props.handlePageSizeChange,
          }}
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={this.props.list}
          onChange={this.handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (event) => {
                this.setState({ selectedUserId: record._id });
              }, // click row
            };
          }}
          // 번역
          locale={{
            filterConfirm: i18n.t("global.ok"),
            filterReset: i18n.t("global.reset"),
            triggerDesc: i18n.t("admin.sort.triggerDesc"),
            triggerAsc: i18n.t("admin.sort.triggerAsc"),
            cancelSort: i18n.t("admin.sort.cancelSort"),
            emptyText: i18n.t("global.noData"),
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(SearchResultArea)
);
