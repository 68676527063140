import React, { Component } from "react";
import TextColorIcon from "../../Mobile/Project-upload/textEditorTool/edit_text_icon_1.svg";
import OnBoldIcon from "../../Mobile/Project-upload/textEditorTool/onIcons/red_edit_text_icon_1.svg";
import UnderLineIcon from "../../Mobile/Project-upload/textEditorTool/edit_text_icon_2.svg";
import OnUnderLineIcon from "../../Mobile/Project-upload/textEditorTool/onIcons/red_edit_text_icon_2.svg";
import AlignIcon from "../../Mobile/Project-upload/textEditorTool/edit_text_icon_3.svg";
import OnAlignIcon from "../../Mobile/Project-upload/textEditorTool/onIcons/red_edit_text_icon_3.svg";
import CenterAlignIcon from "../../Mobile/Project-upload/textEditorTool/edit_text_icon_4.svg";
import OnCenterAlignIcon from "../../Mobile/Project-upload/textEditorTool/onIcons/red_edit_text_icon_4.svg";
import RightAlignIcon from "../../Mobile/Project-upload/textEditorTool/edit_text_icon_5.svg";
import OnRightAlignIcon from "../../Mobile/Project-upload/textEditorTool/onIcons/red_edit_text_icon_5.svg";
import TextBoldIcon from "../../image/ProjectUpload/text-bold-icon.svg";

import "./MobileTextTool.scss";

class MobileTextTool extends Component {
  state = {
    colorListOpen: false,
    color: "",
    align: "",
    clickedItem: "",
  };

  handleColorListClose = () => {
    this.setState({ colorListOpen: false });
  };

  render() {
    return (
      <div id="toolbar" className="customTool">
        <div>
          <span
            className="ql-formats"
            style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "15px",
              height: "15px",
              // backgroundColor: `${color ? color : "#070707"}`,
              // borderRadius: "50%",
              backgroundImage: `url(${TextColorIcon})`,
              position: "relative",
            }}
            id="color-picker"
            onClick={this.props.handleColorListOpen}
            value="color-picker"
          >
            {/* <div
              className="colorBar"
              style={{ backgroundColor: `${color ? color : "#070707"}` }}
            ></div> */}
            <select className="ql-color ql-picker">
              <span
                className="ql-color-picker"
                tabIndex="0"
                aria-controls="ql-picker-options-1"
                role="button"
                value="color-picker"
              ></span>
            </select>
          </span>
          <span
            onClick={() => this.props.handleEffectSet("bold", !this.props.bold)}
            className={`ql-font ql-picker `}
          >
            <span
              className="ql-formats"
              style={{
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "14px",
                height: "14px",
                backgroundImage: `url(${
                  this.props.bold ? OnBoldIcon : TextBoldIcon
                })`,
              }}
            ></span>
          </span>

          <span
            onClick={() =>
              this.props.handleEffectSet("underline", !this.props.underLine)
            }
            className="ql-formats"
            style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "15px",
              height: "15px",
              backgroundImage: `url(${
                this.props.underLine ? OnUnderLineIcon : UnderLineIcon
              })`,
            }}
          ></span>

          <span
            className="ql-formats"
            style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "15px",
              height: "15px",
              backgroundImage: `url(${
                this.props.align === "" ? OnAlignIcon : AlignIcon
              })`,
            }}
            onClick={() => this.props.handleAlignSet("")}
          >
            <span className={`ql-align ql-picker ql-icon-picker `}></span>
          </span>
          <span
            className="ql-formats"
            style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "15px",
              height: "15px",
              backgroundImage: `url(${
                this.props.align === "center"
                  ? OnCenterAlignIcon
                  : CenterAlignIcon
              })`,
            }}
            onClick={() => this.props.handleAlignSet("center")}
          >
            <span className={`ql-align ql-picker ql-icon-picker`}></span>
          </span>
          <span
            className="ql-formats"
            style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "15px",
              height: "15px",
              backgroundImage: `url(${
                this.props.align === "right" ? OnRightAlignIcon : RightAlignIcon
              })`,
            }}
            onClick={() => this.props.handleAlignSet("right")}
          >
            <span className={`ql-align ql-picker ql-icon-picker `}></span>
          </span>
        </div>
      </div>
    );
  }
}

export default MobileTextTool;
