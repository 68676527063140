import React, { Component } from "react";
import "./ReportList.scss";
import i18n from "../../../../i18n";
import ReportDeleteDialog from "../ReportDialog/ReportDeleteDialog/ReportDeleteDialog";
import { ClickAwayListener } from "@material-ui/core";
import MoreIcon from "../../../../Mobile/Proejct-dialog/project_menu_icon.svg";
import SearchIcon from "../../../../image/Admin/small-more-view-icon.svg";
import ReportDialog from "../ReportDialog/ReportDialog";

import CompleteDialog from "../../../../Component/CompleteDialog/CompleteDialog";
import { PostDelteContent } from "../../../../Api/Admin/Report/report";
import ProfilePhoto from "../../../../Component/ProfilePhoto/ProfilePhoto";
class ReportList extends Component {
  state = {
    selectedMenu: 1,
    isLoading: false,
    reportDialogOpen: false,
    reportDeleteDialogOpen: false,
    reportedId: "",
    completeDialogOpen: false,
  };
  reportDetail = {};
  renderDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
  };
  handleContentInfo = (
    defendantInfo,
    data,
    commentInfo,
    reporterInfo,
    reportedId
  ) => {
    this.reportDetail.defendantInfo = defendantInfo;
    this.reportDetail.reporterInfo = reporterInfo;
    this.reportDetail.data = data;
    this.reportDetail.commentInfo = commentInfo;
    this.reportDetail.reportedId = reportedId;
    this.handleReportDialogOpen();
  };
  handleReportDeleteDialogOpen = (reportedId) => {
    this.setState({ reportDialogOpen: false }, () => {
      this.setState({ reportedId, reportDeleteDialogOpen: true });
    });
  };
  handleMenuOpen = (reportId) => {
    this.setState({ reportSelected: reportId });
  };
  handleMenuClose = () => {
    this.setState({ reportSelected: "" });
  };
  handleReportDialogOpen = () => {
    this.setState({ reportDialogOpen: true });
  };
  handleCompleteDialogOpen = () => {
    this.setState({ completeDialogOpen: true });
    let timer = setTimeout(2000, () =>
      this.setState({ completeDialogOpen: false })
    );
  };
  handleDelteReportedContent = async (id, reason) => {
    try {
      await PostDelteContent(id, reason, true);

      this.setState({ reportDeleteDialogOpen: false }, () =>
        this.handleCompleteDialogOpen()
      );
    } catch (e) {
      console.dir(e);
    }
  };
  renderDateFilter = () => {};

  renderReportType = (type) => {
    if (type === "project") {
      return i18n.t("admin.report.type_1");
    } else if (type === "comment") {
      return i18n.t("admin.report.type_2");
    }
  };
  // handleSelectedMenu = (menu) => {
  //   this.setState({ selectedMenu: menu }, () => {
  //     this.props.handleSelectedMenu(menu);
  //   });
  // };
  renderList = () => {
    if (this.props.list[0]) {
      return (
        <>
          <div className="report_list-header">
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header1")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header2")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header3")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header4")}</span>
            </div>
          </div>
          <div className="report_list-list">
            {this.props.list.map((item) => {
              return (
                <div className="report_list-columns" key={`report_${item._id}`}>
                  <div className="list-column">
                    <span>{this.renderReportType(item.data.type)}</span>
                  </div>
                  <div className="list-column">
                    <span>{this.renderDate(item.createdAt)}</span>
                  </div>
                  <div className="list-column">
                    <div className="column-defendantInfo">
                      <ProfilePhoto
                        src={item.defendant.profilePhoto}
                        size={50}
                      />
                      <span>{item.defendant.userName}</span>
                    </div>
                  </div>
                  <div className="list-column">
                    <div className="column-reporterInfo">
                      <div className="reporterInfo-row">
                        <span className="row-title">
                          {i18n.t("admin.report.declarers")}
                        </span>
                        <div className="repoerterInfo">
                          <ProfilePhoto
                            size={16}
                            src={item.reporter.profilePhoto}
                          />
                          <span>{item.reporter.userName}</span>
                        </div>
                      </div>
                      <div className="reporterInfo-row">
                        <span
                          className="row-title"
                          style={{ paddingRight: "12px" }}
                        >
                          {i18n.t("admin.report.declarers.content")}
                        </span>
                        <div
                          className="report-content"
                          onClick={() =>
                            this.handleContentInfo(
                              item.defendant,
                              item.data,
                              item.commentInfo,
                              item.reporter,
                              item._id
                            )
                          }
                        >
                          <span>{item.data.text}</span>
                          <img src={SearchIcon} alt="searchIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-column report-list-btns">
                    {item.solveData.solved ? (
                      <button type="button" className="completed-btn">
                        {i18n.t("admin.report.resolved")}
                      </button>
                    ) : (
                      <button type="button" className="completed-btn">
                        {i18n.t("admin.report.unresolved")}
                      </button>
                    )}

                    <div
                      className="report-list_moreBtn"
                      onClick={() => this.handleMenuOpen(item._id)}
                    >
                      <img src={MoreIcon} alt="More Icon" />
                      {this.state.reportSelected === item._id ? (
                        <ClickAwayListener onClickAway={this.handleMenuClose}>
                          <div className="report-menu">
                            <span
                              onClick={() =>
                                this.handleContentInfo(
                                  item.defendant,
                                  item.data,
                                  item.commentInfo,
                                  item.reporter,
                                  item._id
                                )
                              }
                            >
                              게시글 삭제
                            </span>
                          </div>
                        </ClickAwayListener>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="report_list-header">
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header1")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header2")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header3")}</span>
            </div>
            <div className="header-column">
              <span>{i18n.t("admin.report.adjust.header4")}</span>
            </div>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <div className="hidden-container">
        {JSON.stringify(this.reportDetail) !== "{}" ? (
          <ReportDialog
            onClose={() => this.setState({ reportDialogOpen: false })}
            open={this.state.reportDialogOpen}
            reportInfo={this.reportDetail}
            handleReportDeleteDialogOpen={this.handleReportDeleteDialogOpen}
          />
        ) : null}
        <ReportDeleteDialog
          onClose={() => this.setState({ reportDeleteDialogOpen: false })}
          open={this.state.reportDeleteDialogOpen}
          reportedId={this.state.reportedId}
          handleDelteReportedContent={this.handleDelteReportedContent}
        />
        <CompleteDialog
          onClick={this.state.completeDialogOpen}
          onClose={() => this.setState({ completeDialogOpen: false })}
        />

        {this.renderList()}
      </div>
    );
  }
}

export default ReportList;
