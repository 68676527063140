import React, { Component } from "react";
import "./MyIntro.scss";
import i18n from "../../i18n";

class MyIntro extends Component {
  state = {
    title: "",
    explanation: "",
  };

  componentDidMount() {
    if (this.props.teamInfo.teamInfo) {
      this.setState({
        title: this.props.teamInfo.teamInfo.title,
        explanation: this.props.teamInfo.teamInfo.explanation,
      });
    }
  }

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value }, () =>
      this.props.handleMyIntroSet(this.state)
    );
  };
  handleExplanChange = (e) => {
    this.setState({ explanation: e.target.value }, () =>
      this.props.handleMyIntroSet(this.state)
    );
  };
  render() {
    //const userInfo = this.props.userInfo
    return (
      <div className="basicInfo-container setting-container">
        <div className="basicInfo-box">
          <div className="Title settingSub-title">
            {i18n.t("teamSetting.userInfo.title")}
          </div>

          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("teamSetting.userInfo.subTitle")}
            </div>
            <div className="userInfo-inputBox">
              <input
                type="text"
                maxLength="40"
                onKeyUp={this.handleTitleChange}
                defaultValue={this.state.title}
                className="InputField"
                placeholder={i18n.t("teamSetting.userInfo.subTitle")}
              />
            </div>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("teamSetting.userInfo.expaln")}
            </div>
            <div className="userInfo-inputBox">
              <textarea
                type="text"
                maxLength="1000"
                defaultValue={this.state.explanation}
                onKeyUp={this.handleExplanChange}
                placeholder={i18n.t("teamSetting.userInfo.expaln")}
              ></textarea>
              {/* <input
                className="InputField"
                placeholder={i18n.t("userSetting.userInfo.expaln")}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyIntro;
