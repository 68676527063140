import React, { Component } from "react";
import "./OccupationField.scss";
import { withStyles } from "@material-ui/core/styles";
import { GetOccupationField } from "../../Api/Job/job";
import i18n from "../../i18n";
import { ClickAwayListener } from "@material-ui/core";
import dropDownIcon from "../../image/line-black-down-arrow-icon.svg";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class OccupationField extends Component {
  state = {
    designList: [],
    mediaList: [],
  };
  componentDidMount = async () => {
    await this.getOccupationList();
  };
  getOccupationList = async () => {
    try {
      let designList = this.state.designList;
      let mediaList = this.state.mediaList;
      const response = await GetOccupationField();

      response.map((item) => {
        if (item.category === "design") {
          designList.push(item);
        } else {
          mediaList.push(item);
        }
      });
      this.setState({ designList, mediaList });
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    const {
      onClose,
      open,
      onOpen,
      title,
      checked,
      handleChange,
      selectedCount,
    } = this.props;

    return (
      <div className="occupation-field_contianer">
        <div
          className="occpation-field_wrapper"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <div className="filter_box">
            <div className="filterName">
              <span>{title}</span>
              {selectedCount ? (
                <span className="selectedCount">{`+ ${selectedCount}`}</span>
              ) : null}
            </div>
            <img
              src={dropDownIcon}
              style={open ? { transform: "rotate(180deg)" } : null}
              alt="open"
            />
          </div>
          <ClickAwayListener onClickAway={onClose}>
            <ul
              className={`occpation-field ${
                // "occupation-open"
                open ? "occupation-open" : "occupation-close"
              }`}
            >
              <div className="design-field_wrapper">
                <span>디자인</span>
                {this.state.designList.map((item, key) => {
                  return (
                    <li
                      key={`occupation_${item._id}`}
                      data-checked={
                        checked &&
                        checked.length !== 0 &&
                        checked.find((data) => data === item._id)
                          ? true
                          : false
                      }
                    >
                      <div
                        className="checkBox"
                        style={{ zIndex: "100" }}
                        onClick={(e) => handleChange(e, item)}
                      >
                        <input
                          checked={
                            checked && checked.length !== 0
                              ? checked.find((data) => data === item._id)
                              : false
                          }
                          type="checkBox"
                          //   checked={checked}
                          id={`design_${key}`}
                        />
                        <label htmlFor={`design_${key}`}>
                          {/* <span></span> */}
                          {i18n.language === "en" ? item.name.en : item.name.ko}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </div>
              <div className="design-field_wrapper">
                <span>미디어</span>
                {this.state.mediaList.map((item, key) => {
                  return (
                    <li
                      key={`occupation_${item._id}`}
                      data-checked={
                        checked &&
                        checked.length !== 0 &&
                        checked.find((data) => data === item._id)
                          ? true
                          : false
                      }
                    >
                      <div
                        className="checkBox"
                        onClick={(e) => handleChange(e, item)}
                      >
                        <input
                          checked={
                            checked && checked.length !== 0
                              ? checked.find((data) => data === item._id)
                              : false
                          }
                          type="checkBox"
                          id={`media_${key}`}
                        />
                        <label htmlFor={`media_${key}`}>
                          {/* <span></span> */}
                          {i18n.language === "en" ? item.name.en : item.name.ko}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </div>
            </ul>
          </ClickAwayListener>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(OccupationField);
