import React, { Component } from "react";
import "./InviteMemberDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../i18n";
import RemoveIcon from "../../image/close-icon.svg";
import UseList from "./UseList";
import Memberlist from "./Memberlist";
import { GetInviteMemberList } from "../../Api/Team/Team";
class InviteMemberDialog extends Component {
  state = {
    userList: [],
    searchUser: "",
    selectedUser: [],
    userListOpen: false,
    memberAuth: [
      {
        authName: i18n.t("team.create.addMember.auth.member"),
        auth: "member",
      },
      {
        authName: i18n.t("team.create.addMember.auth.manager"),
        auth: "admin",
      },
    ],
    members: [],
  };

  hanldeSelecUser = (user) => {
    let selectedUser = this.state.selectedUser;
    selectedUser.push(user);
    this.setState({
      selectedUser,
      searchUser: "",
    });
  };
  handleUserListSearch = (e) => {
    this.setState({ searchUser: e.target.value }, () => {
      if (e.target.value) {
        this.handleUserListLoad();
      }
    });
  };
  handleUserListLoad = async () => {
    const response = await GetInviteMemberList(
      this.state.searchUser,
      this.props.teamId
    );
    if (response && typeof response === "object") {
      let userList = response;
      this.setState({ userList });
    }
  };

  handleUserListOpen = () => {
    this.setState({ userListOpen: true });
    this.handleUserListLoad();
  };
  handleuserListClose = () => {
    if (this.state.isInputFocused) {
      return;
    }
    this.setState({ userListOpen: false });
  };
  handleClickedAuth = (auth, id) => {
    let newMember = this.state.members;
    newMember = newMember.map((item) => {
      if (item._id === id) {
        item.authority = auth;
      }
      return item;
    });
    this.setState({ members: newMember });
  };

  handleInviteMember = (data) => {
    // arry to oject {key:id,value:data}

    data = data.map((item) => {
      item.authority = "member";
      return item;
    });
    const members = this.state.members.concat(data);

    this.setState({
      members,
      selectedUser: [],
      searchUser: "",
    });
  };
  renderAuthName = (auth) => {
    if (auth === "admin") {
      return i18n.t("team.create.addMember.auth.manager");
    } else if (auth === "member") {
      return i18n.t("team.create.addMember.auth.member");
    }
  };
  handleRemoveUser = (id) => {
    let members = this.state.members.filter((item) => item._id !== id);
    this.setState({ members });
  };
  handleRemoveSelectedUser = (id) => {
    let selectedUser = this.state.selectedUser;
    selectedUser = selectedUser.filter((item) => item._id !== id);
    this.setState({ selectedUser });
  };

  render() {
    const { onClose, open } = this.props;
    const { members, memberAuth } = this.state;
    return (
      <>
        <Dialog
          // fullWidth={ this.props.fullWidth }
          className={"inviteMember-dialog"}
          open={open}
          onClose={onClose}
        >
          <div className="inviteMember-dialog-container addInfo-container">
            <div className="addInfo-box">
              <>
                <div className="membersFind-input addInfo-columnBox">
                  <span>{i18n.t("team.create.addMember.title")}</span>
                  <div className="membersFind-inputField">
                    {this.state.selectedUser[0]
                      ? this.state.selectedUser.map((user, idx) => {
                          return (
                            <div className="selecUserBox" key={idx}>
                              <span>{user.userName}</span>
                              <img
                                src={RemoveIcon}
                                alt="selected-user-remove"
                                onClick={() =>
                                  this.handleRemoveSelectedUser(user._id)
                                }
                              />
                            </div>
                          );
                        })
                      : null}
                    <input
                      id="invite_user-searchBar"
                      type="search"
                      autoComplete="off"
                      placeholder={i18n.t("global.input.placeholder.neme")}
                      onChange={this.handleUserListSearch}
                      onClick={this.handleUserListOpen}
                      value={this.state.searchUser}
                      onFocus={() =>
                        this.setState(
                          { isInputFocused: true }
                          // () => this.handleUserListLoad
                        )
                      }
                      onBlur={() =>
                        this.setState(
                          { isInputFocused: false }
                          // () => this.handleUserListLoad
                        )
                      }
                    />
                  </div>

                  <div className="userSearchResultContainer">
                    {this.state.userListOpen && (
                      <UseList
                        handleuserListClose={this.handleuserListClose}
                        userList={this.state.userList}
                        hanldeSelecUser={this.hanldeSelecUser}
                        selectedUser={this.state.selectedUser}
                        searchUser={this.state.searchUser}
                        members={members}
                      />
                    )}
                  </div>
                  <div className="members-auth">
                    <div className="members_invite-btns">
                      <button
                        // disabled={true}
                        className="members_invite-btns__add"
                        type="button"
                        onClick={() =>
                          this.handleInviteMember(this.state.selectedUser)
                        }
                      >
                        {i18n.t("global.add")}
                      </button>
                    </div>
                  </div>
                  <div className="membersFind-auth addInfo-columnBox">
                    {/* <span>{ i18n.t("team.create.addMember.auth") }</span> */}
                    <div className="members_selected-list">
                      {/* <span>{ i18n.t("team.invite.title") }</span> */}
                      {members[0] ? (
                        <Memberlist
                          members={members}
                          memberAuth={memberAuth}
                          handleRemoveUser={this.handleRemoveUser}
                          handleClickedAuth={this.handleClickedAuth}
                          userAuth={this.props.userAuth}
                        />
                      ) : (
                        <div
                          className="members-selected-empty"
                          style={{ textAlign: "center", marginTop: "30px" }}
                        >
                          🔍 {i18n.t("team.invite.ex")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div className="invite-members-btns">
                <button type="button" onClick={onClose}>
                  {i18n.t("global.cancel")}
                </button>
                <button
                  className={`${
                    this.state.members && this.state.members.length === 0
                      ? "disabledBtn"
                      : ""
                  }`}
                  type="button"
                  onClick={
                    this.state.members === 0
                      ? null
                      : () => this.props.handleInvite(this.state.members)
                  }
                >
                  {i18n.t("team.invite.btn")}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default InviteMemberDialog;
