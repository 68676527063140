import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const GetMemoInfo = async (jobId, userId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/memo-info?id=${jobId}&userId=${userId}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const JobViewCount = async (jobId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/count`;

  await axios.patch(
    url,
    { id: jobId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const PostCreateJob = async (teamId) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/create`;
  const response = await axios.post(
    url,
    {
      teamId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data.data.id;
};
export const PostCreateJobFailed = async (jobId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/create-failed`;
  await axios.post(
    url,
    { id: jobId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const PostCreateJobFinish = async (
  jobId,
  payInfo,
  workingHours,
  careerStatus,
  category,
  countryInfo,
  periodInfo,
  position,
  keyTasks,
  jobDetail,
  remoteAvailable,
  tags,
  images,
  isModify
) => {
  let body = new FormData();
  const token = GetUserToken();
  // const offset = new Date().getTimezoneOffset() * 60000;
  // const strDate = new Date(periodInfo.strDate._d - offset);

  body.append("id", jobId);
  body.append("payInfo[currency]", payInfo.currency);
  body.append("payInfo[minimum]", payInfo.minimum);
  body.append("payInfo[maximum]", payInfo.maximum);
  body.append("payInfo[isPrivate]", payInfo.isPrivate);
  body.append("position", position);
  body.append(`countryInfo[countryName]`, countryInfo.countryName);
  body.append(`countryInfo[countryCode]`, countryInfo.countryCode);
  body.append(`countryInfo[region]`, countryInfo.region);
  body.append(`keyTasks`, keyTasks);
  body.append(`jobDetail`, jobDetail);
  body.append("remoteAvailable", remoteAvailable);
  body.append(`category`, category._id);
  body.append(`isModify`, isModify);
  if (!periodInfo.regularBasis) {
    body.append("periodInfo[strDate]", periodInfo.strDate._d);
    body.append("periodInfo[endDate]", periodInfo.endDate._d);
  }
  body.append("periodInfo[regularBasis]", periodInfo.regularBasis);

  if (workingHours && workingHours.length) {
    for (let i = 0; i < workingHours.length; i++) {
      body.append(`workingHours[${i}]`, workingHours[i].id);
    }
  }
  if (careerStatus && careerStatus.length) {
    for (let i = 0; i < careerStatus.length; i++) {
      body.append(`careerStatus[${i}]`, careerStatus[i].id);
    }
  }

  if (tags.length !== 0) {
    for (let i = 0; i < tags.length; i++) {
      body.append(`tags[${i}]`, tags[i]);
    }
  }
  if (images.length !== 0) {
    for (let i = 0; i < images.length; i++) {
      body.append(`images`, images[i].fileObj);
    }
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/create-finish`;
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const GetOccupationField = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/occupationalField/list`;

  const response = await axios.get(url);

  return response.data.data;
};
export const PostCreateOccupation = async (ko, en, category) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/occupationalField/create`;
  let body = {
    neme: {
      ko,
      en,
    },
    category,
  };
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const DeleteOccupation = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/occupationField/delete?id=${id}`;
  await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const GetJobDetailInfo = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/info?id=${id}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};
export const GetJobList = async (
  searchStr,
  workingHours,
  careerInfos,
  categories,
  countryInfo,
  order,
  remoteAvailable,
  skip,
  limit
) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/list?searchString=${
    searchStr ? searchStr : ""
  }`;

  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  if (order) url += `&order=${order}`;
  if (remoteAvailable) url += `&remoteAvailable=${remoteAvailable}`;
  if (workingHours && workingHours.length) {
    for (let i = 0; i < workingHours.length; i++) {
      url += `&workingHours[${i}]=${workingHours[i]}`;
    }
  }
  if (careerInfos && careerInfos.length) {
    for (let i = 0; i < careerInfos.length; i++) {
      url += `&careerInfos[${i}]=${careerInfos[i]}`;
    }
  }
  if (categories && categories.length) {
    for (let i = 0; i < categories.length; i++) {
      url += `&categories[${i}]=${categories[i]}`;
    }
  }

  if (countryInfo.countryCode)
    url += `&countryInfo[countryCode]=${countryInfo.countryCode}`;
  if (countryInfo.region) url += `&countryInfo[region]=${countryInfo.region}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const getOtherJobs = async (id, teamId, skip, limit) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/other-jobs?excludeJob=${id}&teamId=${teamId}&skip=${skip}&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};

export const GetJobTeamList = async (id, order, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/team-list?teamId=${id}`;
  if (order) url += `&filter=${order}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const token = GetUserToken();
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const GetApplicants = async (id, filter, order, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/application-list?id=${id}`;
  if (filter) url = url + `&filter=${filter}`;
  if (order) url = url + `&order=${order}`;
  if (skip) url = url + `&skip=${skip}`;
  if (limit) url = url + `&limit=${limit}`;
  const token = GetUserToken();
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data ? response.data.data : [];
};
export const GetResume = async (id, teamId) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/resume?id=${id}&teamId=${teamId}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};
export const PostSetUserUnFavorite = async (id, userId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/application-UnFavorite`;
  let body = {
    id,
    userId,
  };
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const PostSetUserFavorite = async (id, userId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/application-Favorite`;
  let body = {
    id,
    userId,
  };
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const PostJobDelete = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/deleteJob?id=${id}`;
  await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const SetFavoriteJob = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/favorite`;
  await axios.post(
    url,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const ModifyJobPeriod = async (jobId, endDate) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/period-modify`;
  let body = {
    jobId,
    endDate,
  };

  await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const SetUnFavoriteJob = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/unFavorite`;
  await axios.post(
    url,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const PostJobApply = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/apply`;
  await axios.post(
    url,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const PostCancelApply = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/cancel-apply`;
  await axios.post(
    url,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const GetFavoritesList = async (filter, order, isASC, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/favorite-list?filter=${filter}`;
  if (skip) {
    url = url + `&skip=${skip}`;
  }
  if (limit) {
    url = url + `&limit=${limit}`;
  }
  const token = GetUserToken();
  if (order) url = url + `&order=${order}`;
  if (isASC) url = url + `&isASC=${isASC}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data ? response.data.data : [];
};
export const GetSupportList = async (filter, order, isASC, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/applied?filter=${filter}`;
  if (skip) url = url + `&skip=${skip}`;
  if (limit) url = url + `&limit=${limit}`;
  if (order) url = url + `&order=${order}`;
  if (isASC) url = url + `&isASC=${isASC}`;

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data ? response.data.data : [];
};
export const PostMemoSet = async (id, userId, memo) => {
  let body = {
    id,
    userId,
    memo,
  };
  const token = GetUserToken();

  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/memo`;
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
