import React, { Component } from "react";
import "./TwoButtonDialog.scss";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import Button from "../Button/Button";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
class TwoButtonDialog extends Component {
  render() {
    const {
      onClose,
      open,
      dialogHeadText,
      dialogHeadText2,
      dialogBodyText1,
      dialogBodyText2,
      dialogButtonText1,
      dialogButtonText2,
      leftButton,
      rightButton,
      dialogBodyIcon,
      className,
    } = this.props;
    // const what = content[dialogType]

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={""}
          maxWidth={"sm"}
          open={open}
          onClose={onClose}
        >
          <div className={`one-button-dialog-container ${className}`}>
            <h1 className="one-button-dialog-h1">
              {dialogHeadText || i18n.t("global.alert")}
            </h1>
            <h1 className="one-button-dialog-h1">{dialogHeadText2 || ""}</h1>
            {dialogBodyIcon && (
              <div className="one-button-dialog-icon">
                <img src={dialogBodyIcon} alt="icon" />
              </div>
            )}
            <div className="one-button-dialog-text">
              <div>{dialogBodyText1 || null}</div>
              <div>{dialogBodyText2 || null}</div>
            </div>
            <div className="two-button-dialog-button-container">
              <Button
                text={dialogButtonText1 || i18n.t("global.cancel")}
                backColor="white"
                btnClick={leftButton || onClose}
              />
              <Button
                text={dialogButtonText2 || i18n.t("global.confirm")}
                backColor="black"
                btnClick={rightButton || onClose}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(TwoButtonDialog);
