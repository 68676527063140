import React, { Component } from "react";
import "./Introduction.scss";
import Section1 from "./Section1/Section1";

class Intorduction extends Component {
  render() {
    return (
      <>
        <div className="faq-container">
          <Section1 />
        </div>
      </>
    );
  }
}

export default Intorduction;
