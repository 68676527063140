import React, { Component } from "react";
import "./UserInfo.scss";
import InstaIcon from "../../../image/Snsicon/circle-sns-icon-1.svg";
import FacebookIcon from "../../../image/Snsicon/circle-sns-icon-2.svg";
import YoutubeIcon from "../../../image/Snsicon/circle-sns-icon-3.svg";
import PinterIcon from "../../../image/Snsicon/circle-sns-icon-4.svg";
import DribbleIcon from "../../../image/Snsicon/circle-sns-icon-5.svg";
import viewImg from "../../../image/view-icon.svg";
import likeImg from "../../../image/like-icon.svg";
import LocationIcon from "../../../image/location-icon.svg";
import Follower from "../../../image/mypage-follower-icon.svg";
import TumblrIcon from "../../../image/Snsicon/circle-sns-icon-6.svg";
import VimeoIcon from "../../../image/Snsicon/circle-sns-icon-7.svg";
import MediumIcon from "../../../image/Snsicon/circle-sns-icon-8.svg";
import SoundCloudIcon from "../../../image/Snsicon/circle-sns-icon-9.svg";
import FollowDialog from "../FollowDialog/FollowDialog";
import ShareIcon from "../../../image/job/share-black-icon.svg";
import Home from "../../../image/mypage-homepage-icon.svg";
import AddIcon from "../../../image/Collection/add-black-icon.svg";
import profileEditBtn from "../../../image/job/edit-profile-icon.svg";
import i18n from "../../../i18n";
import { Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { nFormatter } from "../../../Component/functions";
import {
  SetFollow,
  GetFollowerList,
  GetFollowingList,
  SetUnFollow,
} from "../../../Api/Follow/Follow";

import { withSnackbar } from "notistack";
import { GetUserToken } from "../../../modules/userAuth";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";

class UserInfo extends Component {
  // constructor(props) {
  //   super(props);
  //   this.refInfo = React.createRef();
  // }
  state = {
    teamInfoOpen: "",
    FollowDialogOpen: false,
    FollowLoading: false,
    FollowOrFollowing: "1",
    isFollow: false,
    isLoading: false,
    FollowerList: [],
    FollowingList: [],
    userInfo: {},
    statusInfoUser: true,
    theposition: false,
  };

  iconFinder = (websiteName) => {
    if (websiteName === "Instagram") {
      return InstaIcon;
    } else if (websiteName === "Facebook") {
      return FacebookIcon;
    } else if (websiteName === "Pinterest") {
      return PinterIcon;
    } else if (websiteName === "Dribble") {
      return DribbleIcon;
    } else if (websiteName === "Youtube") {
      return YoutubeIcon;
    } else if (websiteName === "Tumblr") {
      return TumblrIcon;
    } else if (websiteName === "Vimeo") {
      return VimeoIcon;
    } else if (websiteName === "Medium") {
      return MediumIcon;
    } else if (websiteName === "SoundCloud") {
      return SoundCloudIcon;
    }
    // else {
    //     return OtherIcon
    // }
  };
  handleFollowDialogClose = () => {
    this.setState({ FollowDialogOpen: false });
  };
  componentDidMount = () => {
    this.setState({ userInfo: this.props.userInfo });
    console.log(this.props.userInfo);
    // check position when initialized

    //when scroll callback
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 563) {
      this.setState(
        {
          theposition: true,
        },
        () => console.log(this.state.theposition)
      );
    } else {
      this.setState({ theposition: false });
    }
  };

  handleFollowUser = async (id, isFollowing) => {
    const token = GetUserToken();
    let userInfoCopy = this.state.userInfo;
    userInfoCopy.isFollowing = !userInfoCopy.isFollowing;
    this.setState({ userInfo: userInfoCopy });

    let followId = id ? id : this.state.userInfo._id;

    if (token) {
      if (!isFollowing) {
        try {
          await SetFollow(followId);

          this.setState({ isLoading: false }, () =>
            this.setState({ isFollow: true })
          );
        } catch (e) {
          console.dir(e);
          this.setState({ isFollow: true, isLoading: false });
        }
      } else {
        try {
          await SetUnFollow(followId);

          this.setState(
            { isFollow: false, isLoading: false },
            () => this.handleFollowLists
          );
        } catch (e) {
          console.dir(e);
          this.setState({ isFollow: false, isLoading: false });
        }
      }
    } else {
      this.props.history.push("/login");
    }
  };
  handleFollowLists = async (type, skip, limit) => {
    this.setState({
      FollowOrFollowing: type,
      FollowDialogOpen: true,
    });
    if (type === "1") {
      this.followerListLoad(skip, limit);
    } else if ((type = "2")) {
      this.followingListLoad(skip, limit);
    }
  };
  followerListLoad = async (skip, limit) => {
    this.setState({
      FollowLoading: true,
    });

    const form = {};
    if (this.props.isSelfURL) {
      form.id = this.props.userId;
    } else if (!this.props.isSelfURL) {
      form.id = this.props.userInfo._id;
    }
    form.skip = skip;
    form.limit = limit;
    try {
      const response = await GetFollowerList(form.id, form.skip, form.limit);

      this.setState({ FollowerList: response }, () =>
        this.setState({ FollowLoading: false })
      );

      // this.setState({ FollowerList: response.data.data }, () =>
      //   this.setState({ FollowLoading: false })
      // );
    } catch (e) {
      console.dir(e);
    }
  };
  handleTeamInfoOpen = (e, target) => {
    e.stopPropagation();

    this.setState({ teamInfoOpen: target });
  };
  handleFollowSet = (e, isFollowing, teamId, index) => {
    e.stopPropagation();
    if (isFollowing) {
      this.props.handleTeamUnFollow(teamId, index);
    } else {
      this.props.handleTeamFollow(teamId, index);
    }
  };
  onPopClose = (e) => {
    if (
      (e.target.offsetParent !== null &&
        e.target.offsetParent.className !== null) ||
      !e.target.offsetParent.className.includes("teamInfo_popover")
    ) {
      this.setState({ teamInfoOpen: "" });
    }
  };
  followingListLoad = async (skip, limit) => {
    console.log(skip, limit);
    this.setState({
      FollowLoading: true,
      // FollowerList: [],
    });
    const form = {};

    if (this.props.isSelfURL) {
      form.id = this.props.userId;
    } else if (!this.props.isSelfURL) {
      form.id = this.props.userInfo._id;
    }
    form.skip = skip ? skip : 0;
    form.limit = limit ? limit : 30;

    try {
      const response = await GetFollowingList(form.id, form.skip, form.limit);
      if (
        this.props.userId &&
        response.filter((val) => val._id === this.props.userId)
      ) {
        const FollowingList = response
          .filter((val) => val._id === this.props.userId)
          .concat(response.filter((val) => val._id !== this.props.userId));
        this.setState({ FollowingList }, () => {
          this.setState({ FollowLoading: false });
        });
      } else {
        this.setState({ FollowingList: response }, () =>
          this.setState({ FollowLoading: false })
        );
      }
    } catch (e) {
      console.dir(e);
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const userInfo = this.props.userInfo;
    const isSelfURL = this.props.isSelfURL;
    const matches = this.props.matches;

    return (
      <div
        className={`profile-box ${
          this.state.theposition ? "profile-box-sticky" : null
        }`}
        ref={(ref) => (this.refInfo = ref)}
      >
        {this.state.FollowDialogOpen ? (
          <FollowDialog
            FollowOrFollowing={this.state.FollowOrFollowing}
            open={this.state.FollowDialogOpen}
            onClose={this.handleFollowDialogClose}
            FollowLoading={this.state.FollowLoading}
            FollowerList={this.state.FollowerList}
            FollowingList={this.state.FollowingList}
            followingListLoad={this.followingListLoad}
            followerListLoad={this.followerListLoad}
            handleFollowUser={this.handleFollowUser}
            isFollow={this.isFollow}
            handleFollowLists={this.handleFollowLists}
            userInfo={userInfo}
            history={this.props.history}
          ></FollowDialog>
        ) : null}

        {matches.tablet && (
          <div className="mobile-profilePhoto">
            <div></div>
            <div className="profile-photo">
              <ProfilePhoto src={userInfo.profilePhoto} size={100} />
            </div>

            <div className="mobile-profilePhotoBtns">
              {/* <div className="settingBox">
                <img src={OffSettingIcon} alt="OffSettingIcon" />
              </div> */}

              {/* <img src={MobileMsgIcon} alt="Message" /> */}
              {isSelfURL ? (
                <button
                  className="iconBorderBtn"
                  onClick={() => {
                    this.props.history.push("/setting?profileEdit=user");
                  }}
                >
                  <img src={profileEditBtn} alt="profileEditBtn" />
                </button>
              ) : null}
              {!isSelfURL ? (
                <>
                  <button
                    className="iconBorderBtn"
                    style={{ marginRight: "15px" }}
                  >
                    <img src={ShareIcon} alt="share_button" />
                  </button>
                  <button
                    onClick={() =>
                      this.handleFollowUser(userInfo._id, userInfo.isFollowing)
                    }
                    // className="followBtn"
                    className={
                      userInfo.isFollowing ? "following-md" : "follow-md"
                    }
                  >
                    {userInfo.isFollowing
                      ? i18n.t("mypage.profile.unfollow")
                      : i18n.t("mypage.profile.follow")}
                  </button>
                </>
              ) : null}
            </div>
          </div>
        )}
        {matches.desktop && (
          <div className="profile-photo" style={{ position: "relative" }}>
            <ProfilePhoto src={userInfo?.profilePhoto} size={140} />
            {isSelfURL ? (
              <button
                className="iconBorderBtn"
                onClick={() => {
                  this.props.history.push("/setting?profileEdit=user");
                }}
                style={{
                  position: "absolute",
                  right: "0px",
                  bottom: "5px",
                  borderRadius: "50%",
                  // border: "1px solid #e1e2e3",
                  width: "25px",
                  height: "25px",
                  padding: "6px",
                }}
              >
                <img src={profileEditBtn} alt="profileEditBtn" />
              </button>
            ) : null}
          </div>
        )}

        <div className="profile-otherInfo">
          <span className="profile-otherInfo-userName">
            {userInfo.userName}
          </span>
          <span
            style={{ fontSize: "12px" }}
            className={
              (isSelfURL &&
                userInfo.classification &&
                userInfo.classification[0]) ||
              !isSelfURL
                ? null
                : "emptyActiveField"
            }
            onClick={
              userInfo.classification && userInfo.classification[0]
                ? null
                : () => {
                    this.props.history.push("/setting?profileEdit=user");
                  }
            }
          >
            {userInfo.classification && userInfo.classification[0] ? (
              userInfo.classification
                .map((item) => {
                  return i18n.language === "en" ? item.name.en : item.name.ko;
                })
                .join(" / ")
            ) : isSelfURL ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={AddIcon}
                  alt="addIcon"
                  style={{ width: "10px", marginRight: "5px" }}
                ></img>
                {i18n.t("mypage.profile.job")}
              </div>
            ) : null}
          </span>
          <div className="other-location">
            <img src={LocationIcon} alt={userInfo.countryInfo.countryName} />
            <span>
              {userInfo.countryInfo ? userInfo.countryInfo.countryName : null}
            </span>
          </div>
          {matches.desktop && (
            <div className={`profile-snsBox`}>
              <Avatar.Group
                maxCount={3}
                maxStyle={{
                  color: "white",
                  backgroundColor: "#070707",
                  // border: "0.5px solid #070707",
                  fontSize: "12px",
                }}
                className="sns-Avatars-Grp"
                gap={10}
              >
                {userInfo.linkInfo
                  ? userInfo.linkInfo.map((item) => {
                      return item.link ? (
                        // <Tooltip title={item.websiteName} placement="top">
                        <Avatar
                          onClick={() =>
                            window.open(`https://${item.link}`, "_blank")
                          }
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                          }}
                          src={this.iconFinder(item.websiteName)}
                        />
                      ) : // </Tooltip>
                      null;
                    })
                  : null}
              </Avatar.Group>
            </div>
          )}
        </div>
        {matches.desktop &&
          (!isSelfURL ? (
            <div className="profile-btns">
              <div className="btnsRow">
                <button
                  type="button"
                  onClick={() =>
                    this.handleFollowUser(userInfo._id, userInfo.isFollowing)
                  }
                  className={
                    userInfo.isFollowing ? "following-full" : "follow-full"
                  }
                  // style={{ marginRight: "10px" }}
                >
                  {userInfo.isFollowing
                    ? i18n.t("mypage.profile.unfollow")
                    : i18n.t("mypage.profile.follow")}
                </button>
                <button
                  type="button"
                  className="follow-full"
                  onClick={() =>
                    // alert("준비중 입니다.")
                    this.handleAlert("준비중인 기능입니다", {
                      variant: "info",
                      key: "feature-preparing",
                      autoHideDuration: 5000,
                      preventDuplicate: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                    })
                  }
                >
                  {i18n.t("mypage.profile.message")}
                </button>
              </div>
              {/* <button type="button" onClick={() => alert("준비중 입니다.")}>
                {i18n.t("mypage.profile.sendSupport")}
              </button> */}
            </div>
          ) : null)}

        <div className="userInfo-container">
          {matches.desktop && (
            <div
              className="userInfo-status"
              style={this.state.theposition ? { display: "none" } : null}
            >
              <div className="status-views status">
                <div id="box">
                  <img src={viewImg} alt="views" style={{ width: "16px" }} />
                  <span>{i18n.t("mypage.profile.views")}</span>
                </div>
                <span>{nFormatter(userInfo.views)}</span>
              </div>
              <div className="status-follower status">
                <div id="box">
                  <img
                    src={Follower}
                    alt="follower"
                    style={{ width: "16px" }}
                  />
                  <span>{i18n.t("mypage.profile.follower")}</span>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleFollowLists("1")}
                >
                  {userInfo.follower}
                </span>
              </div>
              <div className="status-views status">
                <div id="box">
                  <img src={Follower} alt="follow" style={{ width: "16px" }} />
                  <span>{i18n.t("mypage.profile.following")}</span>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleFollowLists("2")}
                >
                  {nFormatter(userInfo.following)}
                </span>
              </div>
              <div className="status-views status">
                <div id="box">
                  <img
                    src={likeImg}
                    alt="like"
                    style={{ marginRight: "2px" }}
                  />
                  <span>{i18n.t("mypage.profile.totalLike")}</span>
                </div>
                <span>{nFormatter(userInfo.likes)}</span>
              </div>
            </div>
          )}
          {matches.desktop && (
            <div
              className={`userInfo-site ${
                userInfo.website ? null : "borderNone"
              }`}
              style={this.state.theposition ? { display: "none" } : null}
            >
              {userInfo.website ? (
                <div className="site-box">
                  <img src={Home} alt="site" />
                  <span
                    onClick={() =>
                      window.open(`https://${userInfo.website}`, "_blank")
                    }
                  >
                    {userInfo.website.length >= 30
                      ? userInfo.website.substr(0, 30) + "..."
                      : userInfo.website}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {matches.tablet && (
            <div className="Mobile-followInfo">
              <div
                className="followRow"
                onClick={() => this.handleFollowLists("1")}
              >
                <span>{nFormatter(userInfo.follower)}</span>
                <span> {i18n.t("mypage.profile.follower")}</span>
              </div>
              <div
                className="followRow"
                onClick={() => this.handleFollowLists("2")}
              >
                <span>{nFormatter(userInfo.following)}</span>
                <span> {i18n.t("mypage.profile.following")}</span>
              </div>
            </div>
          )}
          {userInfo.teamList && userInfo.teamList.length && matches.desktop ? (
            <div className="uesrInfo-team_wrapper">
              <span>{i18n.t("header.team")}</span>
              <div className="teamList">
                <Avatar.Group
                  maxCount={3}
                  className="team_avatars-grp"
                  gap={10}
                >
                  {userInfo.teamList.map((team, index) => {
                    return (
                      <Tooltip
                        overlayClassName="teamInfo_tooltip"
                        placement="topRight"
                        color="white"
                        title={
                          <div className={`teamInfo_popover`}>
                            <div className="top_teamInfo-wrapper">
                              <div
                                className="team_detail-info"
                                onClick={() =>
                                  this.props.history.push(
                                    `/team/@${team.linkURL}`
                                  )
                                }
                              >
                                <ProfilePhoto src={team.teamPhoto} size={50} />
                                <div className="team-text nowrap">
                                  <span className="nowrap">{team.name}</span>
                                  <span className="nowrap">
                                    {team.description}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="bottom_teamInfo-wrapper">
                              <div className="team_status-list">
                                <div className="team_status nowrap">
                                  <span>
                                    {i18n.t("teamPage.member.header4")}
                                  </span>
                                  <span>{team.projectCount}</span>
                                </div>
                                <div className="team_status nowrap">
                                  <span>
                                    {i18n.t("teamPage.nav.teamMember")}
                                  </span>
                                  <span>{team.memberCount}</span>
                                </div>
                              </div>
                              {!this.props.isSelfURL ? (
                                <button
                                  onClick={(e) =>
                                    this.handleFollowSet(
                                      e,
                                      team.isFollowing,
                                      team._id,
                                      index
                                    )
                                  }
                                  className={
                                    team.isFollowing
                                      ? "following-sm"
                                      : "follow-sm"
                                  }
                                  style={{ marginLeft: "21px" }}
                                >
                                  {team.isFollowing
                                    ? i18n.t("mypage.profile.unfollow")
                                    : i18n.t("mypage.profile.follow")}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        }
                      >
                        <div className="team_info">
                          {team.teamPhoto ? (
                            <Avatar
                              // onClick={(e) =>
                              //   this.handleTeamInfoOpen(e, team._id)
                              // }
                              // onMouseOut={(e) => this.handleTeamInfoOpen(e, "")}
                              style={{
                                backgroundColor: "white",
                                cursor: "pointer",
                              }}
                              src={team.teamPhoto}
                            />
                          ) : (
                            <Avatar
                              onMouseOver={(e) =>
                                this.handleTeamInfoOpen(e, team._id)
                              }
                              //onMouseOut={(e) => this.handleTeamInfoOpen(e, "")}
                              size={30}
                              icon={<UserOutlined />}
                            />
                          )}
                          {/* <ClickAwayListener
                          onClickAway={() => {
                            this.setState({ teamInfoOpen: "" });
                          }}
                        > */}

                          {/* </ClickAwayListener> */}
                        </div>
                      </Tooltip>
                    );
                  })}
                </Avatar.Group>
              </div>
            </div>
          ) : null}

          {matches.desktop && (
            <div
              className={`userInfo-intro ${
                userInfo.userInfo
                  ? userInfo.userInfo.title && userInfo.userInfo.explanation
                    ? null
                    : "borderNone"
                  : "borderNone"
              }`}
              style={this.state.theposition ? { display: "none" } : null}
            >
              <div className="intro-header h">
                <span>
                  {userInfo.userInfo ? userInfo.userInfo.title : "Intorduction"}
                </span>
              </div>
              <span>
                {userInfo.userInfo ? userInfo.userInfo.explanation : null}
              </span>
            </div>
          )}

          {/* <div className="userInfo-careers">
                      <div className="careers-header h">
                      <span>Photoshop Class I</span>
                      </div>
                      <span>1st place in the Weekly Awards</span>
                      <span>1st place in the Weekly Awards</span>
                      <span>5th place at the World Awards</span>
                      <span>Photoshop Class I</span>
                    </div> */}
          {matches.desktop && (
            <div className="userInfo-footer">
              <span>
                {i18n.t("mypage.profile.joing")}{" "}
                {userInfo.createdAt.substring(0, 10)}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withSnackbar(UserInfo);
