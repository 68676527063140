import React, { Component } from "react";
import "./ApplicantsListDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../i18n";
import LocationIcon from "../../image/location-icon.svg";
import ArrowIcon from "../../image/arrowIcon/black-down-icon.svg";
import LikeIcon from "../../image/like-icon.svg";
import ViewIcon from "../../image/view-icon.svg";

import AcceptDialog from "./AcceptDialog/AcceptDialog";
import XboxIcon from "../../image/no-applicant-icon.svg";
import CloseIcon from "../../image/close-icon.svg";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { handlePopupInfiniteContent } from "../../Component/functions";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";

class ApplicantsListDialog extends Component {
  state = {
    acceptDialogOpen: false,
    selecUser: "",
    messageOpen: "",
    titleMsg: "",
    msg: "",
    function: "",
    isLoading: false,
    items: 0,
    dataNullCheck: false,
  };
  LIMIT = 10;

  handleInfiniteContent = () => {
    const cotainer = document.getElementById("scroll_container");
    const scrollBox = document.getElementById("dialog_popup_scroll");
    handlePopupInfiniteContent(
      scrollBox,
      cotainer,
      this.props.scrollInfo.dataNull,
      this.props.isLoading,
      this.props.addPendingUserList
    );
  };

  handleAcceptDialogOpen = (userId) => {
    this.setState(
      {
        selecUser: userId,
        titleMsg: i18n.t("team.accept.title"),
        msg: i18n.t("team.accept.text"),
        function: "accept",
      },
      () => this.setState({ acceptDialogOpen: true })
    );
  };
  handleDeclineDialogOpen = (userId) => {
    this.setState(
      {
        selecUser: userId,
        titleMsg: i18n.t("team.decline.title"),
        msg: i18n.t("team.decline.text"),
        function: "reject",
      },
      () => this.setState({ acceptDialogOpen: true })
    );
  };
  handleMessageOpen = (userId) => {
    if (this.state.messageOpen === userId) {
      this.setState({ messageOpen: "" });
    } else {
      this.setState({ messageOpen: userId });
    }
  };
  // handleUserAccept = async () => {
  //   try {
  //     await PostUserAccept(this.props.teamId, this.state.selecUser, "member");
  //     this.setState({ acceptDialogOpen: false }, () => this.isPendinUserList());
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };
  // handleUserReject = async () => {
  //   try {
  //     const response = await PostUserReject(
  //       this.props.teamId,
  //       this.state.selecUser
  //     );
  //     this.isPendinUserList();
  //     console.log(response);
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };

  render() {
    const { onClose, open } = this.props;
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
    `;
    const pendingUserList = this.props.pendingUserList;
    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"applicants-dialog"}
          open={open}
          onClose={onClose}
        >
          <AcceptDialog
            open={this.state.acceptDialogOpen}
            onClose={() => this.setState({ acceptDialogOpen: false })}
            headerTitle={this.state.titleMsg}
            bodyText={this.state.msg}
            buttonClick={
              this.state.function === "accept"
                ? () => {
                    this.setState({ acceptDialogOpen: false }, () => {
                      this.props.handleUserAccept(this.state.selecUser);
                    });
                  }
                : () => {
                    this.setState({ acceptDialogOpen: false }, () => {
                      this.props.handleUserReject(this.state.selecUser);
                    });
                  }
            }
          />
          <div id="scroll_container" className="applicants-dialog-container">
            <div className="applicants-title">
              <span>
                {i18n.t("team.applicants.title")}
                {pendingUserList ? ` ${pendingUserList.length}` : 0}
              </span>
              {i18n.lang === "en" ? (
                <span className="storage-period">
                  It will be automatically deleted after
                  <span>14</span>
                  days
                </span>
              ) : // <span className="storage-period">
              //   <span>14</span>일 이후에 자동으로 삭제됩니다.
              // </span>
              null}
              <img src={CloseIcon} alt="dialog-close" onClick={onClose} />
            </div>
            <div className="applicants-userList">
              <div
                id="dialog_popup_scroll"
                onScroll={this.handleInfiniteContent}
                className={`applicants-list-box ${
                  pendingUserList ? "" : "penddingNull"
                }`}
              >
                <div className={`applicants-scrollBox `}>
                  {pendingUserList ? (
                    pendingUserList.map((user, idx) => {
                      return (
                        <div
                          className="applicatns-box"
                          key={`${user.userName}_${idx}`}
                        >
                          <div className="applicants-userInfo">
                            <ProfilePhoto size={50} scr={user.profilePhoto} />
                            <div className="user-infoBox">
                              <div className="infoBox-top">
                                <span>{user.userName}</span>
                                <span>
                                  {user.favorites && user.favorites[0]
                                    ? user.favorites[0]?.name +
                                      "/" +
                                      user.favorites[1]
                                      ? user.favorites[1]?.name
                                      : null
                                    : null}
                                </span>
                              </div>
                              <div className="infoBox-bottom">
                                <div className="infoBox-location">
                                  <img src={LocationIcon} alt="user-location" />
                                  <span>{user.countryInfo.countryName}</span>
                                </div>
                                <div className="infoBox-status">
                                  <img src={LikeIcon} alt="Like Conut" />
                                  <span>{user.likeMembers}</span>
                                </div>
                                <div className="infoBox-status">
                                  <img src={ViewIcon} alt="View Conut" />
                                  <span>{user.views}</span>
                                </div>
                              </div>
                            </div>
                            <div className="applicants-btns">
                              <button
                                onClick={() =>
                                  this.handleAcceptDialogOpen(user._id)
                                }
                              >
                                {i18n.t("team.accept.btn1")}
                              </button>
                              <button
                                onClick={() =>
                                  this.handleDeclineDialogOpen(user._id)
                                }
                              >
                                {i18n.t("team.accept.btn2")}
                              </button>
                            </div>
                          </div>
                          <div className="infoBox-msg">
                            <div
                              className="msg-text"
                              onClick={() => this.handleMessageOpen(user._id)}
                            >
                              {i18n.t("team.applicants.msg")}
                              <img
                                src={ArrowIcon}
                                alt="user-messageOpen"
                                style={{
                                  transform:
                                    this.state.messageOpen === user._id
                                      ? "rotate(180deg)"
                                      : "",
                                }}
                              />
                            </div>
                            {this.state.messageOpen === user._id ? (
                              <div className="msg-box">
                                <span>{user.messege}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  ) : this.props.isLoading ? (
                    <FadeLoader
                      loading={this.props.isLoading}
                      css={override}
                      size={14}
                    ></FadeLoader>
                  ) : (
                    <div className="null-applicants">
                      <img src={XboxIcon} alt="XboxIcon" />
                      <span>{i18n.t("team.applicants.null")}</span>
                    </div>
                  )}

                  {this.props.pendingUserList && (
                    <FadeLoader
                      loading={this.props.isLoading}
                      css={override}
                      size={6}
                    ></FadeLoader>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default ApplicantsListDialog;
