import React, { Component } from "react";
import "../../Styles/common/Button.scss";
class Button extends Component {
  render() {
    const { width, height, text, backColor, btnClick } = this.props;
    return (
      <button
        className={
          !backColor || backColor === "black" ? `blackButton` : "whiteButton"
        }
        style={{
          width: width ? `${width}px` : "100px",
          height: height ? `${height}px` : "40px",
        }}
        onClick={btnClick || null}
      >
        {text}
      </button>
    );
  }
}
export default Button;
