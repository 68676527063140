import React, { Component } from "react";
import i18n from "../../../../i18n";
import "./AllJobs.scss";
import FilterArea from "./FilterArea/FilterArea";

import { DeleteJob, GetAdminJobList } from "../../../../Api/Admin/Job/Job";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteDialog from "../../../../Component/DelConfirmDialog/DelConfirmDialog";
import { connect } from "react-redux";
import { langChange } from "../../../../Redux/action/index";
import SearchResult from "./SearchResult/SearchResult";
class AllJobs extends Component {
  state = {
    selectedFilter: 1,
    jobsList: [],
    isLoading: false,
    remoteFilter: false,
    selectedCareer: [],
    selectedWorkinHours: [],
    orderType: "",
    isASC: false,
    searchStr: "",
    countryInfo: {
      countryCode: "",
      countryName: "",
      region: null,
    },
    filter: 1,
    endCount: 0,
    processCount: 0,
    scheduledCount: 0,
    deleteDialogOpen: false,
    selectedJob: null,
  };
  skip = 0;
  limit = 20;
  componentDidMount = async () => {
    await this.getJobList();
  };
  handleChangeCountry = (e) => {
    this.items = 0;
    this.setState({
      countryInfo: {
        countryCode: e.value,
        countryName: e.label,
        region: null,
      },
    });
  };
  handleFilterChange = (e, type, id) => {
    this.items = 0;
    if (type === "career") {
      let selectedCareer = this.state.selectedCareer;
      if (e.target.checked && !selectedCareer.find((val) => val === id)) {
        selectedCareer.push(id);
        this.setState({ selectedCareer }, () => this.getJobList());
      } else {
        selectedCareer = selectedCareer.filter((val) => val !== id);
        this.setState({ selectedCareer }, () => this.getJobList());
      }
    } else if (type === "employment") {
      let selectedWorkinHours = this.state.selectedWorkinHours;
      if (e.target.checked && !selectedWorkinHours.find((val) => val === id)) {
        selectedWorkinHours.push(id);
        this.setState({ selectedWorkinHours }, () => this.getJobList());
      } else {
        selectedWorkinHours = selectedWorkinHours.filter((val) => val !== id);
        this.setState({ selectedWorkinHours }, () => this.getJobList());
      }
    } else {
      if (e.target.checked) {
        this.setState({ remoteFilter: true }, () => this.getJobList());
      } else {
        this.setState({ remoteFilter: false }, () => this.getJobList());
      }
    }
  };
  handleSelectFilter = (filter) => {
    console.log(filter);
    this.skip = 0;
    this.setState({ selectedFilter: filter }, () => this.getJobList());
  };
  getJobList = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await GetAdminJobList(
        this.state.searchStr,
        this.state.selectedFilter,
        this.state.orderType,
        this.state.isASC,
        this.state.selectedWorkinHours,
        this.state.selectedCareer,
        this.state.countryInfo,
        this.state.remoteFilter,
        0,
        this.limit
      );
      this.skip += response.list.length;
      this.setState({
        jobsList: response.list,
        endCount: response.endCount,
        processCount: response.processCount,
        scheduledCount: response.scheduledCount,
        isLoading: false,
      });
    } catch (e) {
      console.dir(e);
      this.setState({ isLoading: false });
    }
  };
  handleRegionChange = (val) => {
    let countryInfo = this.state.countryInfo;

    countryInfo.region = val;
    if (val !== "") {
      countryInfo.region = val;
      this.setState({ countryInfo });
    } else {
      countryInfo.region = "";
      this.setState({ countryInfo });
    }
  };
  handleSorter = async (orderType, isASC) => {
    this.setState({ orderType, isASC }, () => this.getJobList());
  };

  handleSearchLoaction = () => {
    this.items = 0;
    if (this.state.countryInfo.countryName) {
      this.getJobList();
    }
  };
  setCurrentPage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getProjList()
    );
  };
  handleDeleteDialogOpen = (id) => {
    this.setState({ selectedJob: id, deleteDialogOpen: true });
  };
  handleDeleteRecruitmentItem = async () => {
    try {
      await DeleteJob(this.state.selectedJob);
      this.getJobList();
      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.dir(e);
    }
  };
  handleInitFileters = () => {
    if (
      this.state.searchStr ||
      this.state.selectedFilter !== 1 ||
      this.state.orderType ||
      this.state.selectedWorkinHours.length ||
      this.state.selectedCareer ||
      this.state.countryInfo.countryCode ||
      this.state.remoteFilter
    ) {
      this.setState(
        {
          searchStr: "",
          selectedFilter: 1,
          orderType: "",
          isASC: false,
          selectedWorkinHours: [],
          selectedCareer: [],
          countryInfo: {
            countryCode: null,
            countryName: null,
            region: null,
          },
          remoteFilter: false,
        },
        () => this.getJobList()
      );
    }
  };
  handleSearchStr = (e) => {
    this.setState({ searchStr: e.target.value }, () =>
      console.log(this.state.searchStr)
    );
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          <div className="admin_jobs-container">
            <FilterArea
              handleSearchStr={this.handleSearchStr}
              getJobList={this.getJobList}
              selectedFilter={this.state.selectedFilter}
              handleSelectFilter={this.handleSelectFilter}
              endCount={this.state.endCount}
              processCount={this.state.processCount}
              scheduledCount={this.state.scheduledCount}
              selectedCareer={this.state.selectedCareer}
              selectedWorkinHours={this.state.selectedWorkinHours}
              remoteFilter={this.state.remoteFilter}
              handleFilterChange={this.handleFilterChange}
              handleChangeCountry={this.handleChangeCountry}
              handleInitFileters={this.handleInitFileters}
              countryInfo={this.state.countryInfo}
              handleRegionChange={this.handleRegionChange}
              handleSearchLoaction={this.handleSearchLoaction}
            />
            <SearchResult
              list={this.state.jobsList}
              handleSorter={this.handleSorter}
              handleDeleteDialogOpen={this.handleDeleteDialogOpen}
            />
            {this.state.deleteDialogOpen ? (
              <DeleteDialog
                dialogHeader={i18n.t("teamPage.recruitment.deleteHeader")}
                dialogText={i18n.t("teamPage.recruitment.deleteText")}
                open={this.state.deleteDialogOpen}
                buttonClick={this.handleDeleteRecruitmentItem}
                onClose={() => this.setState({ deleteDialogOpen: false })}
              />
            ) : null}
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllJobs);
