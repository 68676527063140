import React, { Component } from "react";
import searchIcon from "../../image/black-search-icon.svg";
import { connect } from "react-redux";
import arrowImg from "../../image/arrow-gray-icon.svg";
import FilterIcon from "../../Mobile/filtersIcon.svg";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import "./Discover.scss";
import { GetCategories } from "../../Api/Category/Category";
import { GetProjectList } from "../../Api/Project/project";
import { ClickAwayListener } from "@material-ui/core";
import { css } from "@emotion/core";
import { searchContent } from "../../Redux/action/index";
import { Spin, Badge } from "antd";
import { Dialog } from "@material-ui/core";
import loadable from "@loadable/component";
import { LoadingOutlined } from "@ant-design/icons";
import quertString from "query-string";
import Media from "react-media";
import EmptyStates from "../../Component/EmptyStates/EmptyStates";
import { handleWindowInfiniteScroll } from "../../Component/functions";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import FadeLoader from "react-spinners/BeatLoader";
import SvgColor from "react-svg-color";
import { BottomSheet } from "react-spring-bottom-sheet";

const override = css`
  display: flex;
  justify-content: center;
`;

const Project = loadable(() => import("../../Component/Project/Project"));
class Discover extends Component {
  state = {
    contentDialogOpen: false,
    projectDialogOpen: false,
    workFieldList: [],
    strDate: "",
    endDate: "",
    toolList: [
      {
        tool: i18n.t("discover.tool.photo"),
      },
      {
        tool: i18n.t("discover.tool.premiere"),
      },
      {
        tool: i18n.t("discover.tool.illust"),
      },
      {
        tool: i18n.t("discover.tool.fresco"),
      },
      {
        tool: i18n.t("discover.tool.maya"),
      },
      {
        tool: i18n.t("discover.tool.final"),
      },
      {
        tool: i18n.t("discover.tool.effect"),
      },
    ],
    dateList: [
      {
        id: 0,
        text: "전체 기간",
      },
      {
        id: 1,
        text: "최근 24시간",
      },
      {
        id: 2,
        text: "최근 1주일",
      },
      {
        id: 3,
        text: "최근 1개월",
      },
      {
        id: 4,
        text: "최근 3개월",
      },
    ],
    sortList: [
      {
        id: 1,
        sort: i18n.t("discover.sort.recommend"),
      },
      {
        id: 3,
        sort: i18n.t("discover.sort.reprod"),
      },
      // {
      //   id: 2,
      //   sort: i18n.t("discover.sort.discussion"),
      // },
      {
        id: 2,
        sort: i18n.t("discover.sort.latest"),
      },
    ],

    projectList: [],
    colorOpen: false,
    filterOpen: false,
    toolListOpen: false,
    sortListOpen: false,
    clickedFilter: "",
    clickedTool: [],
    clickedSort: { id: 3, sort: i18n.t("discover.sort.reprod") },
    colorPicker: [],
    searchText: "",
    items: 0,
    contentLoad: false,
    selecteDate: "전체 기간",
    firstRender: false,
    selecSort: 0,

    index: 0,
    projectLoading: false,
    isDataNullCheck: false,
    searchHistory: [],
    historyOpen: false,
  };
  limit = 20;
  reRender = false;
  // handleColorOpen = () => {
  //   this.setState({ colorOpen: true });
  // };
  // handleColorClose = () => {
  //   this.setState({ colorOpen: false });
  // };
  search = "";
  handleToolOpen = () => {
    this.setState({ toolListOpen: true });
  };
  handleToolClose = () => {
    this.setState({ toolListOpen: false });
  };
  handleFilterOpen = () => {
    this.setState({ filterOpen: true });
  };
  handleSortListOpen = () => {
    this.setState({ sortListOpen: true });
  };
  handleSortListClose = () => {
    this.setState({ sortListOpen: false });
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleFilterClose = () => {
    // if (!this.state.clickedFilter) {
    //   this.setState({ filterOpen: false });
    // } else {
    //   this.setState({ filterOpen: false, projectList: [] });
    // }
    this.setState({ filterOpen: false });
  };
  handleDateFilterClose = () => {
    this.setState({ contentDialogOpen: false });
  };
  handleColorPicker = (e) => {
    this.setState({ colorPicker: e.color });
  };
  handleSortChecked = (e) => {
    this.setState(
      { clickedSort: e, items: 0, projectList: [], sortListOpen: false },
      () => this.renderProject()
    );
  };
  getCategoryList = async () => {
    try {
      const response = await GetCategories();
      this.setState({ workFieldList: response });
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert();
      console.dir(e);
    }
  };
  addRenderProject = async () => {
    this.setState({ contentLoad: true });

    try {
      const order = this.state.clickedSort ? this.state.clickedSort.id : 3;
      const response = await GetProjectList(
        order,
        this.limit,
        this.state.clickedFilter ? this.state.clickedFilter._id : "",
        this.state.items,
        this.state.searchText,
        this.state.strDate,
        this.state.endDate
      );

      if (!response[0] || response.length < 10) {
        this.setState({ isDataNullCheck: true });
      } else {
        this.setState({ isDataNullCheck: false });
      }

      let projectList = this.state.projectList;
      let add = projectList.concat(response);
      let index = this.state.items + this.state.projectList.length;

      this.setState({ contentLoad: false }, () =>
        this.setState({
          projectList: add,
          items: index,
        })
      );
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert();
      console.dir(e);
    }
    this.setState({ contentLoad: false });
    this.reRender = false;
  };

  handleToolChecked = (e) => {
    this.setState({ clickedTool: e.tool });
  };
  handleFilterChecked = (e) => {
    if (this.state.clickedFilter !== e) {
      this.setState({ clickedFilter: e }, () => {
        this.renderProject();
      });
    } else {
      this.setState({ clickedFilter: "" }, () => {
        this.renderProject();
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", handleWindowInfiniteScroll);
  }
  componentDidMount = () => {
    this.getCategoryList();
    this.renderProject();
    if (this.props.searchTitle !== this.state.searchText) {
      this.setState({ searchText: this.props.searchTitle }, () => {
        this.handleMobileSearch();
      });
    }
    this.props.history.push("/discover");
    window.addEventListener("scroll", () =>
      handleWindowInfiniteScroll(
        this.state.isDataNullCheck,
        this.state.firstRender,
        this.addRenderProject,
        this.state.contentLoad
      )
    );
    this.reRender = false;
  };
  renderProject = () => {
    this.setState(
      {
        projectLoading: true,
        contentLoad: true,
        items: 0,
        projectList: [],
      },
      async () => {
        try {
          const categoryId = quertString.parse(this.props.location.search);

          const order = this.state.clickedSort ? this.state.clickedSort.id : 3;
          const searchStr = this.state.searchText;

          const category = this.state.clickedFilter
            ? this.state.clickedFilter._id
            : JSON.stringify(categoryId) !== "{}"
            ? categoryId.c
            : "";

          const response = await GetProjectList(
            order,
            this.limit,
            category,
            this.state.items,
            searchStr,
            this.state.strDate,
            this.state.endDate
          );

          let projectList = response;
          const clickedFilter = this.state.workFieldList.filter(
            (item) => item._id === categoryId.c
          );
          if (clickedFilter[0] && !this.state.clickedFilter) {
            this.setState({ clickedFilter: clickedFilter[0] });
          }

          if (!projectList || projectList.length < 20) {
            this.setState({ isDataNullCheck: true });
          } else {
            this.setState({ isDataNullCheck: false });
          }

          this.setState(
            {
              projectList,
              items: projectList.length,
            },
            () => {
              this.setState({
                contentLoad: false,
                firstRender: true,
                projectLoading: false,
              });
            }
          );
        } catch (e) {
          console.dir(e);
          this.handleAlert();
          this.setState({
            projectLoading: false,
            contentLoad: false,
            isLoading: false,
          });
        }
      }
    );
  };

  handleSearchList = (e) => {
    const arr = [];
    if (!localStorage.getItem("searchHistory")) {
      arr.push(e);
      localStorage.setItem("searchHistory", JSON.stringify(arr));
    } else {
      const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      if (searchHistory.indexOf(e) === -1) {
        searchHistory.unshift(e);
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
      }
    }
    this.setState({ searchText: e }, () =>
      this.props.searchContent(this.state.searchText)
    );
  };

  handleMobileSearch = () => {
    this.setState({ historyOpen: false }, async () => {
      try {
        const response = await GetProjectList(
          1,
          null,
          null,
          null,
          this.state.searchText
        );

        let projectList = response;
        this.setState({ projectList });
      } catch (e) {
        // alert(i18n.t("global.error.server.msg"));
        this.handleAlert();
        console.dir(e);
      }
    });
  };
  handleHistoryClose = () => {
    this.setState({ historyOpen: false });
  };
  handleHistoryClick = (e) => {
    this.props.searchContent(e);
    let input = document.getElementById("search-text-discover");
    input.value = e;
    this.setState({ searchText: e, historyOpen: false }, async () => {
      this.props.searchContent(this.state.searchText);
      try {
        const response = await GetProjectList(
          1,
          null,
          null,
          null,
          this.state.searchText
        );

        let projectList = response;
        this.setState({ projectList });
      } catch (e) {
        // alert(i18n.t("global.error.server.msg"));
        this.handleAlert();
        console.dir(e);
      }
    });
  };
  handleSearchHitory = () => {
    if (!localStorage.getItem("searchHistory")) {
      return;
    } else {
      const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      this.setState({
        searchHistory,
        historyOpen: true,
      });
    }
  };
  handleSearch = (e) => {
    const searchText = e.target.value.trim();

    if (e.key === "Enter" && e.target.value !== this.state.searchText) {
      this.handleSearchList(searchText);

      this.setState({ searchText }, async () => {
        this.props.searchContent(this.state.searchText);
        try {
          const response = await GetProjectList(
            1,
            null,
            null,
            null,
            this.state.searchText
          );
          let projectList = response;
          this.props.searchContent("");
          this.setState({ projectList });
        } catch (e) {
          // alert(i18n.t("global.error.server.msg"));
          this.handleAlert();
          console.dir(e);
        }
      });
    }
  };
  handleDateRange = (date) => {
    const today = new Date();
    let strDate = today;
    let endDate = new Date();

    if (date.id === 1) {
      endDate = new Date(endDate.setDate(today.getDate() - 1));
    } else if (date.id === 2) {
      endDate = new Date(endDate.setDate(today.getDate() - 7));
    } else if (date.id === 3) {
      endDate = new Date(endDate.setMonth(today.getMonth() - 1));
    } else if (date.id === 4) {
      endDate = new Date(endDate.setMonth(today.getMonth() - 3));
    } else {
      strDate = null;
      endDate = null;
    }

    this.setState({ selecteDate: date.text, items: 0, strDate, endDate }, () =>
      this.renderProject()
    );
  };
  handleProjectClick = (index) => {
    this.setState({ index }, () => this.handleProjectInfoSet());
  };
  handleClearInput = () => {
    let input = document.getElementById("search-text-discover");
    input.value = "";
  };
  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };
  render() {
    const color = "#070707";
    const antIcon = (
      <LoadingOutlined
        variant="reat"
        style={{ fontSize: 50, color: "rgba(0, 0, 0, 0.5)" }}
        spin
      />
    );
    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 599px)",
            tablet: "(min-width: 600px and max-width: 1024px)",
            tabletDesktop: "(min-width: 600px)",
            desktop: "(min-width: 1025px)",
          }}
        >
          {(matches) => (
            <Spin
              indicator={antIcon}
              spinning={false}
              delay={500}
              wrapperClassName={"main-spin"}
            >
              {/* <ContentDialog
                open={this.state.contentDialogOpen}
                title={i18n.t("main.criteria")}
                multiMsg={[
                  {
                    title: i18n.t("main.criteria.title.motionTrend"),
                    msg: i18n.t("main.criteria.msg.motionTrend"),
                  },
                  {
                    title: i18n.t("main.criteria.title.spotlight"),
                    msg: i18n.t("main.criteria.msg.spotlight"),
                  },

                  // {
                  //     title: i18n.t('main.criteria.title.debut'),
                  //     msg: i18n.t('main.criteria.msg.debut'),
                  // },
                ]}
                onClose={this.handleContentDialogClose}
              /> */}
              <div className="main-container">
                {/* <CategorySlider /> */}
                <div
                  className={`discover-container ${
                    matches.tablet ? "tabletContainer" : ""
                  }`}
                >
                  {matches.desktop && (
                    <div className="searchBox">
                      <div className="search-bar">
                        <img id="search" src={searchIcon} alt="search" />
                        <input
                          id="search-text-discover"
                          type="text"
                          placeholder={i18n.t("global.search")}
                          defaultValue={this.props.searchTitle}
                          onKeyPress={this.handleSearch}
                          onClick={this.handleSearchHitory}
                          autoComplete="off"
                        ></input>

                        <img
                          id="close"
                          src={closeIcon}
                          alt="closeBtn"
                          onClick={this.handleClearInput}
                        ></img>
                      </div>
                    </div>
                  )}
                  <div className="search-result">
                    <span>
                      '
                      {this.state.searchText
                        ? this.state.searchText
                        : this.props.searchTitle}
                      '
                    </span>
                    <span>
                      {this.state.projectList.length
                        ? "에 대한 검색 결과 입니다."
                        : "에 대한 검색 결과가 없습니다."}
                    </span>
                  </div>
                  {/* {matches.tablet && (
                    <CategorySlider
                      history={this.props.history}
                      searchCategory={this.handleFilterChecked}
                    />
                  )} */}
                  <div className="filtersWrap">
                    <div
                      className={`filtersBox  nowrap`}
                      onClick={this.handleFilterOpen}
                    >
                      <Badge
                        dot={this.state.clickedFilter !== "" ? true : false}
                      >
                        <span>{i18n.t("분야 및 정렬")}</span>
                      </Badge>
                      <SvgColor
                        svg={FilterIcon}
                        width={10}
                        // colors={["#fff", "#fff"]}
                        // rotate={this.state.filterOpen ? 180 : null}
                      />
                    </div>
                    {matches.tabletDesktop && (
                      <Dialog
                        fullWidth={this.props.fullWidth}
                        className={"project-filter-dialog-container"}
                        maxWidth={false}
                        open={this.state.filterOpen}
                        onClose={this.handleFilterClose}
                      >
                        <div className={"items-section-bottomSheet"}>
                          <div className="items-section-title">
                            {i18n.t("discover.filter.sort")}
                          </div>
                          <div className="items-list">
                            {this.state.sortList.map((item, index) => {
                              return (
                                <div>
                                  <div className="popular-content ">
                                    <input
                                      type="radio"
                                      name="sort-project"
                                      id={`project-sort-${index}`}
                                      checked={
                                        this.state.clickedSort.sort ===
                                        item.sort
                                          ? true
                                          : false
                                      }
                                    ></input>

                                    <label
                                      className="radioBox"
                                      htmlFor={`project-sort-${index}`}
                                      onClick={() =>
                                        this.handleSortChecked(item)
                                      }
                                    >
                                      {item.sort}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="items-section-title">
                            {i18n.t("project.upload.fields")}
                          </div>
                          <div className="items-list">
                            <div>
                              <div
                                className="popular-content"
                                onClick={() => this.handleFilterChecked("")}
                              >
                                <input
                                  type="radio"
                                  name="category"
                                  id={`project-category-All`}
                                  checked={
                                    this.state.clickedFilter === ""
                                      ? true
                                      : false
                                  }
                                ></input>

                                <label
                                  className="radioBox"
                                  htmlFor={`project-category-All`}
                                  onClick={() => this.handleFilterChecked("")}
                                >
                                  {i18n.t("discover.workfield.all")}
                                </label>
                              </div>
                            </div>
                            {this.state.workFieldList.map((item, index) => {
                              return (
                                <div key={item.name.en}>
                                  <div className="popular-content ">
                                    <input
                                      type="radio"
                                      name="category"
                                      id={`project-category-${index}`}
                                      checked={
                                        this.state.clickedFilter &&
                                        this.state.clickedFilter._id ===
                                          item._id
                                          ? true
                                          : false
                                      }
                                    ></input>

                                    <label
                                      className="radioBox"
                                      htmlFor={`project-category-${index}`}
                                      onClick={() =>
                                        this.handleFilterChecked(item)
                                      }
                                    >
                                      {i18n.language === "en"
                                        ? item.name.en
                                        : item.name.ko}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Dialog>
                    )}
                    {matches.mobile && (
                      <BottomSheet // 필터 및 정렬
                        open={this.state.filterOpen}
                        onDismiss={this.handleFilterClose}
                        // blocking={false}
                        // header={<span>필터</span>}
                        // snapPoints={({ maxHeight }) => [
                        //   maxHeight / 2,
                        //   // maxHeight * 0.6,
                        // ]}
                      >
                        <div className={"items-section-bottomSheet "}>
                          <div className="items-section-title">
                            {i18n.t("discover.filter.sort")}
                          </div>
                          <div className="items-list">
                            {this.state.sortList.map((item, index) => {
                              return (
                                <div>
                                  <div className="popular-content ">
                                    <input
                                      type="radio"
                                      name="sort-project"
                                      id={`project-sort-${index}`}
                                      checked={
                                        this.state.clickedSort.sort ===
                                        item.sort
                                          ? true
                                          : false
                                      }
                                    ></input>

                                    <label
                                      className="radioBox"
                                      htmlFor={`project-sort-${index}`}
                                      onClick={() =>
                                        this.handleSortChecked(item)
                                      }
                                    >
                                      {item.sort}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="items-section-title">
                            {i18n.t("project.upload.fields")}
                          </div>
                          <div className="items-list mobileVer">
                            <div>
                              <div
                                className="popular-content"
                                onClick={() => this.handleFilterChecked("")}
                              >
                                <input
                                  type="radio"
                                  name="category"
                                  id={`project-category-All`}
                                  checked={
                                    this.state.clickedFilter === ""
                                      ? true
                                      : false
                                  }
                                ></input>

                                <label
                                  className="radioBox"
                                  htmlFor={`project-category-All`}
                                  onClick={() => this.handleFilterChecked("")}
                                >
                                  {i18n.t("discover.workfield.all")}
                                </label>
                              </div>
                            </div>
                            {this.state.workFieldList.map((item, index) => {
                              return (
                                <div key={item.name.en}>
                                  <div className="popular-content ">
                                    <input
                                      type="radio"
                                      name="category"
                                      id={`project-category-${index}`}
                                      checked={
                                        this.state.clickedFilter &&
                                        this.state.clickedFilter._id ===
                                          item._id
                                          ? true
                                          : false
                                      }
                                    ></input>

                                    <label
                                      className="radioBox"
                                      htmlFor={`project-category-${index}`}
                                      onClick={() =>
                                        this.handleFilterChecked(item)
                                      }
                                    >
                                      {i18n.language === "en"
                                        ? item.name.en
                                        : item.name.ko}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </BottomSheet>
                    )}

                    {this.state.sortListOpen ? (
                      <ClickAwayListener onClickAway={this.handleSortListClose}>
                        <div className="sortList">
                          <div className="sortList-title">
                            {i18n.t("discover.filter.sort")}
                          </div>
                          {this.state.sortList.map((item) => {
                            return (
                              <span
                                key={item.sort}
                                onClick={() => this.handleSortChecked(item)}
                                className={
                                  this.state.clickedSort.sort === item.sort
                                    ? "filterCheck"
                                    : null
                                }
                              >
                                {item.sort}
                              </span>
                            );
                          })}
                        </div>
                      </ClickAwayListener>
                    ) : null}
                    {this.state.clickedSort.id !== 1 ? (
                      <div
                        className={`filtersBox dateFilter`}
                        onClick={(e) => {
                          this.setState({
                            contentDialogOpen: !this.state.contentDialogOpen,
                          });
                        }}
                        style={{ marginLeft: "5px" }}
                      >
                        <Badge
                          dot={
                            this.state.selecteDate !== "전체 기간"
                              ? true
                              : false
                          }
                        >
                          <span className="nowrap">
                            {this.state.selecteDate
                              ? this.state.selecteDate
                              : i18n.t("전체 기간")}
                          </span>
                        </Badge>
                        {/* <SvgColor
                        svg={arrowImg}
                        width={10}
                        className="ArrowIcon"
                        // colors={["#fff", "#fff"]}
                        rotate={this.state.filterOpen ? 180 : null}
                      /> */}
                        <img
                          src={arrowImg}
                          alt="arrowIcon"
                          style={
                            this.state.contentDialogOpen
                              ? { transform: "rotate(180deg)" }
                              : { transform: "" }
                          }
                        ></img>
                        {this.state.contentDialogOpen ? (
                          <ClickAwayListener
                            onClickAway={this.handleDateFilterClose}
                          >
                            <div
                              className={`dateDropList-wrapper ${
                                this.state.contentDialogOpen
                                  ? "date-open"
                                  : "date-close"
                              }`}
                            >
                              <div className="dateList-item">
                                {this.state.dateList.map((item, index) => {
                                  return (
                                    <React.Fragment>
                                      <input
                                        type="radio"
                                        name="item"
                                        id={`dateList-item-${index}`}
                                        // value="user"
                                        checked={
                                          this.state.selecteDate === item.text
                                            ? true
                                            : false
                                        }
                                      ></input>
                                      <label
                                        htmlFor={`dateList-item-${index}`}
                                        onClick={() =>
                                          this.handleDateRange(item)
                                        }
                                      >
                                        {item.text}
                                      </label>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </ClickAwayListener>
                        ) : null}
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`reference-container ${
                      this.state.projectList[0] ? "" : "emptySearchResult"
                    }`}
                  >
                    {this.state.projectList[0] ? (
                      this.state.projectList.map((item, index) => {
                        return (
                          <div className="reference-content" key={item._id}>
                            <Project
                              projectList={this.state.projectList}
                              mobile={matches.desktop ? false : true}
                              thumbnail={item.thumbnail}
                              title={item.title}
                              creators={item.creators}
                              uploaderInfo={item.uploaderInfo}
                              likeMembers={item.likeMembers}
                              views={item.views}
                              history={this.props.history}
                              projectId={item._id}
                              projUserId={item.userId}
                            ></Project>
                          </div>
                        );
                      })
                    ) : this.state.projectLoading ? (
                      <div className="emptyStates-container">
                        <FadeLoader
                          color={color}
                          loading={this.state.projectLoading}
                          css={override}
                          size={10}
                        ></FadeLoader>
                      </div>
                    ) : (
                      <EmptyStates />
                    )}
                  </div>
                </div>
              </div>
            </Spin>
          )}
        </Media>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  searchTitle: state.searchKeyword.searchTitle,
});

const mapDispatchToProps = (dispatch) => ({
  searchContent: (value) => dispatch(searchContent(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(Discover);
