import React, { Component } from "react";
import "./ImportProjectDialog.scss";
import { compose } from "redux";
import { Dialog } from "@material-ui/core";
import i18n from "../../../i18n";
import XboxIcon from "../../../image/no-applicant-icon.svg";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { GetUploadProjectList } from "../../../Api/Team/Team";
import { PostAddProject } from "../../../Api/Project/project";
import { withSnackbar } from "notistack";

class ImportProjectDialog extends Component {
  state = {
    myProjList: [],
    selectedProject: [],
    uploadDialogOpen: false,
    isDataLoading: false,
  };
  componentDidMount = () => {
    this.myProjectLoad();
  };

  handleProjectUpload = async () => {
    if (this.state.selectedProject.find((item) => item.isUploaded)) {
      this.props.enqueueSnackbar(i18n.t("이미 추가된 프로젝트 입니다."), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      this.setState({ isLoading: true });
      try {
        await PostAddProject(
          this.props.teamId,
          this.state.selectedProject.map((item) => item._id)
        );
        this.setState({ isLoading: false }, () => {
          this.props.projectListLoad(this.props.teamId);
          this.props.onClose();
        });
      } catch (e) {
        console.dir(e);
        this.setState({ isLoading: false }, () => {
          this.props.onClose();
          alert("서버 통신 에러가 발생했습니다.");
        });
      }
    }
  };

  handleUploadDialogOpen = () => {
    this.setState({ uploadDialogOpen: true });
  };
  myProjectLoad = async () => {
    this.setState({ isDataLoading: true });

    try {
      const response = await GetUploadProjectList(this.props.userId);

      this.setState({ myProjList: response }, () =>
        this.setState({ isDataLoading: false })
      );
    } catch (e) {
      console.log(e);
    }
  };
  handleSelectedProject = (project) => {
    if (project.isUploaded) {
      this.props.enqueueSnackbar(i18n.t("이미 추가된 프로젝트 입니다."), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      let selectedProject = this.state.selectedProject;
      if (
        !this.state.selectedProject.find((item) => item._id === project._id)
      ) {
        selectedProject.push(project);
        this.setState({ selectedProject });
      } else {
        selectedProject = selectedProject.filter(
          (item) => item._id !== project._id
        );
        this.setState({ selectedProject });
      }
    }
  };

  renderProject = (item) => {
    return (
      <div onClick={() => this.handleSelectedProject(item)}>
        <div
          // className="imgList-screen"
          style={{
            cursor: "pointer",
            backgroundImage: `url(${item.thumbnail})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: "56.25%",
            height: "100%",
            width: "100%",
          }}
          // src={item.thumbnail}
          // alt="thumbnail"
        ></div>
        <div className="hidden-info"></div>
        <span className="hidden-text">{item.title}</span>
      </div>
    );
  };
  render() {
    const { onClose, open } = this.props;

    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
    `;

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"importDialog"}
          open={open}
          onClose={onClose}
        >
          <div className="import_project-dialog-container">
            <div className="import_project-title">
              <span>{i18n.t("team.project.importHeader")}</span>
            </div>
            <div className="reference-container1 uesr_importProject">
              {this.state.myProjList && this.state.myProjList.length >= 1 ? (
                <div className="uesr_importProject-box">
                  {this.state.myProjList.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{ marginBottom: "unset" }}
                        className={`reference-content importProject ${
                          this.state.selectedProject.find(
                            (data) => data._id === item._id
                          )
                            ? "selectedProject"
                            : ""
                        }`}
                      >
                        {this.renderProject(item)}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{ padding: "20px" }}>
                  {this.state.isDataLoading ? (
                    <FadeLoader
                      //   color={color}
                      loading={this.props.FollowLoading}
                      css={override}
                      size={6}
                    ></FadeLoader>
                  ) : (
                    <div className="null-applicants">
                      <img src={XboxIcon} alt="XboxIcon" />
                      <span
                        style={{
                          textAlign: "center",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {i18n.t("team.project.import.null")}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="import_project-dialog-btns">
              <button type="button" onClick={onClose}>
                {i18n.t("global.cancel")}
              </button>
              <button
                type="button"
                disabled={
                  !this.state.selectedProject ||
                  !this.state.selectedProject.length
                    ? true
                    : false
                }
                onClick={() => this.handleProjectUpload()}
              >
                {i18n.t("global.next")}
              </button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default compose(withSnackbar)(ImportProjectDialog);
