import firebase from "firebase";

export let database;
export let auth;

const MAKEID_CHAR = "@make@";
const DATETIME_CHAR = "@time@";
export const SPLIT_CHAR = "@spl@";
const ONE_VS_ONE = "ONE_VS_ONE";
const MULTI = "MULTI";
export let messageRef = null;
export let roomRef = null;
let config = {
  apiKey: "AIzaSyA8DOCwTG-NKMMBZtkYMw7yzcIQNwuFKQ0",
  authDomain: "motiontrend.firebaseapp.com",
  databaseURL:
    "https://motiontrend-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "motiontrend",
  storageBucket: "motiontrend.appspot.com",
  messagingSenderId: "466390029989",
  appId: "1:466390029989:web:a6c5448cf7a0cb35085f4c",
  measurementId: "G-0CJ0N8PFJ6",
};

export const fire = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  database = firebase.database();
  auth = firebase.auth();
};

// 채팅 로직들

export const handleGetRoomList = async () => {
  if (auth.currentUser) {
    roomRef = database.ref("UserRooms/" + auth.currentUser.uid);
    roomRef.off();
    const roomDB = await roomRef.orderByChild("timestamp").once("value");
    const roomList = [];
    roomDB.forEach((data) => {
      const val = data.val();

      roomList.push({
        lastMessage: val.lastMessage,
        isLastMessageImage: val.isLastMessageImage,
        roomImg: val.profileImg,
        roomId: val.roomId,
        roomTitle: val.roomTitle,
        users: JSON.parse(val.users),
        userNameList: val.userNameList.split(SPLIT_CHAR),
        userList: val.userList.split(SPLIT_CHAR),
        isReaded: val.isReaded,
        isJob: val.isJob,
        time: val.timestamp,
      });
    });
    roomList.sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return 1;
      } else {
        return -1;
      }
    });

    return roomList;
  } else {
    return null;
  }
};

export const handleOpenNewRoom = (userInfo, isJob) => {
  const users = [
    {
      _id: userInfo._id,
      userName: userInfo.userName,
      profilePhoto: userInfo.profilePhoto,
      linkURL: userInfo.linkURL,
      isJobOffer: false,
    },
    {
      _id: auth.currentUser.uid,
      userName: auth.currentUser.displayName,
      profilePhoto: auth.currentUser.photoURL,
      isJobOffer: true,
    },
  ];
  const roomTitle = userInfo.userName + "님";
  const userList = [userInfo._id, auth.currentUser.uid]; // 챗방 유저리스트
  const userNameList = [userInfo.userName, auth.currentUser.displayName]; // 챗방 유저 이름
  const roomId =
    MAKEID_CHAR + auth.currentUser.uid + DATETIME_CHAR + yyyyMMddHHmmsss();

  getMessages(roomId);
  return {
    roomTitle,
    userList,
    userNameList,
    roomId,
    users,
    isJob,
  };
};

export const handleOpenRoom = (roomInfo) => {
  getMessages(roomInfo.roomId);
};

export const getMessages = async (roomId) => {
  if (roomId) {
    if (messageRef) {
      // 이전 메세지 ref 이벤트 제거
      messageRef.off();
    }
    messageRef = database.ref("Messages/" + roomId);
  }
};

export const uploadFiles = (files, roomInfo, cbProgress) => {
  var fileName = files[0].name;
  var path =
    yyyyMMddHHmmsss().substr(0, 8) +
    "/" +
    roomInfo.roomId +
    "/" +
    auth.currentUser.uid +
    "/" +
    fileName;
  var uploadTask = firebase.storage().ref().child(path).put(files[0]);

  var cbError = (error) => {
    console.log(error);
    alert("업로드 중 에러가 발생하였습니다.");
  };
  var cbComplete = () => {
    firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then((url) => {
        sendMessage(url, roomInfo, true);
      });
  };
  uploadTask.on("state_changed", cbProgress, cbError, cbComplete);
};

export const sendMessage = (msg, roomInfo, isImage) => {
  var user = auth.currentUser;

  if (msg.length > 0) {
    var multiUpdates = {};
    var messageRefKey = messageRef.push().key; // 메세지 키값 구하기
    var convertMsg = convertMessage(msg);
    //메세지 처음 입력 하는 경우
    var roomUserlistLength = roomInfo.userList.length;
    for (let i = 0; i < roomUserlistLength; i++) {
      multiUpdates[
        "RoomUsers/" + roomInfo.roomId + "/" + roomInfo.userList[i]
      ] = true;
    }
    database.ref().update(multiUpdates); // 권한 때문에 먼저 저장해야함
    // this.loadMessageList(this.roomId); //방에 메세지를 처음 입력하는 경우 권한때문에 다시 메세지를 로드 해주어야함
    multiUpdates = {}; // 변수 초기화
    //메세지 저장
    multiUpdates["Messages/" + roomInfo.roomId + "/" + messageRefKey] = {
      uid: user.uid,
      userName: user.displayName,
      message: convertMsg, // 태그 입력 방지
      profileImg: user.photoURL ? user.photoURL : "",
      isImage: isImage ? true : false,
      timestamp: firebase.database.ServerValue.TIMESTAMP, //서버시간 등록하기
    }; //유저별 룸리스트 저장
    var roomUserListLength = roomInfo.userList.length;
    if (roomInfo.userList && roomUserListLength > 0) {
      for (let i = 0; i < roomUserListLength; i++) {
        multiUpdates[
          "UserRooms/" + roomInfo.userList[i] + "/" + roomInfo.roomId
        ] = {
          roomId: roomInfo.roomId,
          roomTitle: roomInfo.roomTitle,
          userNameList: roomInfo.userNameList.join(SPLIT_CHAR),
          userList: roomInfo.userList.join(SPLIT_CHAR),
          users: JSON.stringify(roomInfo.users),
          roomType: roomUserListLength > 2 ? MULTI : ONE_VS_ONE,
          roomOneVSOneTarget:
            roomUserListLength === 2 && i === 0
              ? roomInfo.userList[1] // 1대 1 대화이고 i 값이 0 이면
              : roomUserListLength === 2 && i === 1
              ? roomInfo.userList[0] // 1대 1 대화 이고 i값이 1이면
              : "", // 나머지
          lastMessage: convertMsg,
          isLastMessageImage: isImage ? true : false,
          profileImg: user.photoURL ? user.photoURL : "",
          isReaded: false,
          isJob: roomInfo.isJob ? roomInfo.isJob : false,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        };
      }
    }
    database.ref().update(multiUpdates);
  }
};

export const cancelJobOffer = (roomInfo) => {
  const multiUpdates = {};
  for (let i = 0; i < roomInfo.userList.length; i++) {
    multiUpdates[
      "UserRooms/" + roomInfo.userList[i] + "/" + roomInfo.roomId + "/isJob"
    ] = false;
  }
  database.ref().update(multiUpdates);
};

export const handleRead = (roomId) => {
  const multiUpdates = {};
  multiUpdates[
    "UserRooms/" + auth.currentUser.uid + "/" + roomId + "/isReaded"
  ] = true;
  database.ref().update(multiUpdates);
};

export const handleQuit = async (roomId) => {
  await database
    .ref("UserRooms/" + auth.currentUser.uid + "/" + roomId)
    .remove();

  const multiUpdates = {};
  multiUpdates["RoomUsers/" + roomId + "/" + auth.currentUser.uid] = false;

  await database.ref().update(multiUpdates);

  const roomData = await database.ref("RoomUsers/" + roomId).once("value");

  let isRoomDeleted = true;
  roomData.forEach((item) => {
    const val = item.val();
    if (val) {
      isRoomDeleted = false;
    }
  });
  if (isRoomDeleted) {
    await database.ref("Messages/" + roomId).remove();
    await database.ref("RoomUsers/" + roomId).remove();
  }
};

const yyyyMMddHHmmsss = () => {
  var vDate = new Date();
  var yyyy = vDate.getFullYear().toString();
  var MM = (vDate.getMonth() + 1).toString();
  var dd = vDate.getDate().toString();
  var HH = vDate.getHours().toString();
  var mm = vDate.getMinutes().toString();
  var ss = vDate.getSeconds().toString();
  var sss = vDate.getMilliseconds().toString();
  return (
    yyyy +
    (MM[1] ? MM : "0" + MM[0]) +
    (dd[1] ? dd : "0" + dd[0]) +
    (HH[1] ? HH : "0" + HH[0]) +
    (mm[1] ? mm : "0" + mm[0]) +
    (ss[1] ? ss : "0" + ss[0]) +
    sss
  );
};
export const timestampToTime = (timestamp) => {
  var date = new Date(timestamp),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hour = date.getHours(),
    minute = date.getMinutes(),
    week = new Array("일", "월", "화", "수", "목", "금", "토");
  var convertDate =
    year + "년 " + month + "월 " + day + "일 (" + week[date.getDay()] + ") ";
  var convertHour = "";
  if (hour < 12) {
    convertHour = "오전 " + pad(hour) + ":" + pad(minute);
  } else if (hour === 12) {
    convertHour = "오후 " + pad(hour) + ":" + pad(minute);
  } else {
    convertHour = "오후 " + pad(hour - 12) + ":" + pad(minute);
  }
  return convertDate + convertHour;
};

const timestampToTimeForRoomList = (timestamp) => {
  var date = new Date(timestamp),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hour = date.getHours(),
    minute = date.getMinutes();
  var nowDate = new Date(),
    nowYear = nowDate.getFullYear(),
    nowMonth = nowDate.getMonth() + 1,
    nowDay = nowDate.getDate(),
    nowHour = nowDate.getHours(),
    nowMinute = nowDate.getMinutes();
  var result;
  if (year === nowYear && month === nowMonth && day === nowDay) {
    result = pad(hour) + ":" + pad(minute);
  } else {
    result = pad(year) + "-" + pad(month) + "-" + pad(day);
  }
  return result;
};

const convertMessage = (html) => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const pad = (n) => {
  return n > 9 ? "" + n : "0" + n;
};
