import axios from "axios";
import { GetUserToken } from "../../../modules/userAuth";
const token = GetUserToken();
export const PostDelteContent = async (id, reason, isDelete) => {
  let body = {
    id,
    reason,
    isDelete,
  };

  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/to-solve`;
  await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const GetReportList = async (
  type,
  solved,
  isTotal,
  strDate,
  endDate
) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/report-list?type=${type}&isTotal=${isTotal}`;
  if (solved) url += `&solved=${solved}`;
  if (strDate) url += `&strDate=${strDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};
