import React, { Component } from "react";
import "./Admin.scss";
import { connect } from "react-redux";
import Header from "./Header/Header";
import Contents from "./Contents/Contents";
import LeftBar from "./LeftBar/LeftBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "../Component/OneButtonDialog/OneButtonDialog";
import pageInfoIcon from "../image/Admin/page-information-black-icon.svg";
import memberInfoIcon from "../image/Admin/member-information-black-icon.svg";
import ReportIcon from "../image/Admin/list-report-icon.svg";
// import faqInfoIcon from "../image/Admin/faq-and-homepage-black-icon.svg";
import i18n from "../i18n";
import { GetMyInfo } from "../Api/User/user";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { GetUserToken } from "../modules/userAuth";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: null,
      isLoading: true,
      alertDialogOpen: false,
      alertTitle: "",
      alertMsg: "",
      authCheck: false,
    };
  }

  category = [
    {
      name: i18n.t("admin.leftbar.maincate.pages"),
      img: pageInfoIcon,
      path: "/allproject",
      subCategories: [
        {
          name: i18n.t("admin.leftbar.subcate.projects"),
          path: "/allproject",
        },

        {
          name: "Curation",
          path: "/curation",
        },
      ],
    },
    {
      name: i18n.t("채용 관리"),
      img: pageInfoIcon,
      path: "/alljobs",
      subCategories: [
        {
          name: "Job",
          path: "/alljobs",
        },
      ],
    },
    // {
    //   name: "Page infomation",
    //   img: memberInfoIcon,
    //   path: "/allusers",
    //   subCategories: [
    //     {
    //       name: "Full project",
    //       path: "/allProject",
    //     },
    //     // {
    //     // 	name: "Individual Users",
    //     // 	path: "/individualusers",
    //     // },
    //     // {
    //     // 	name: "Company Users",
    //     // 	path: "/companyusers",
    //     // },
    //   ],
    // },
    {
      name: i18n.t("admin.leftbar.maincate.users"),
      img: memberInfoIcon,
      path: "/allusers",
      subCategories: [
        {
          name: i18n.t("admin.leftbar.subcate.allUsers"),
          path: "/allusers",
        },
        // {
        // 	name: "Individual Users",
        // 	path: "/individualusers",
        // },
        // {
        // 	name: "Company Users",
        // 	path: "/companyusers",
        // },
      ],
    },

    {
      name: "Report",
      path: "/report",
      img: ReportIcon,
      subCategories: [
        {
          name: "All Report",
          path: "/report",
        },
      ],
    },
    // {
    // 	name: "FAQ",
    // 	img: faqInfoIcon,
    // 	subCategories: [
    // 		{
    // 			name: "Modify Terms and Conditions",
    // 			path: "/admin",
    // 		},
    // 		{
    // 			name: "Modiry Motiontrend Introduction",
    // 			path: "/admin",
    // 		},
    // 	],
    // },
  ];

  componentDidMount = async () => {
    const token = GetUserToken();
    const userInfoResult = await GetMyInfo();

    if (token && userInfoResult.authority === "admin") {
      this.setState({ isLoading: false });
      return;
    } else {
      // alert("접근 권한이 없습니다");
      this.handleAlert();
      this.props.history.push("/");
    }
  };

  handleAlertShow = (title, msg) => {
    this.setState({
      alertTitle: title,
      alertMsg: msg,
      alertDialogOpen: true,
      loaderDialogOpen: false,
    });
  };

  handlAlertClose = () => {
    this.setState({
      alertDialogOpen: false,
    });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };

  render() {
    // Render a loading screen when we don't have a clear state yet
    if (this.state.isLoading) {
      return (
        <>
          <Alert
            onButtonClick={this.handlAlertClose}
            dialogHeadText={this.state.alertTitle}
            dialogBodyText1={this.state.alertMsg}
            open={this.state.alertDialogOpen}
            onClose={this.handlAlertClose}
          />
          <div className="admin-loading">
            <CircularProgress color="secondary" />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="admin-container">
          <Header history={this.props.history} />
          <div className="admin_content">
            <>
              <LeftBar
                categories={this.category}
                isLogin={this.state.isLogin}
                {...this.props}
              ></LeftBar>
              <Contents {...this.props}></Contents>
            </>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  myInfo: state.myInfo.myInfo,
});
export default compose(connect(mapStateToProps), withSnackbar)(Admin);
