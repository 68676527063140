import React, { Component } from "react";
import { connect } from "react-redux";
import "./CreateSuccess.scss";
import Media from "react-media";
import i18n from "../../i18n";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
import { TeamCreate_GAEvent } from "../../modules/gaEvents";

class CreateSuccess extends Component {
  state = {};
  componentDidMount = () => {
    TeamCreate_GAEvent("success");
  };
  render() {
    return (
      <>
        <Media
          queries={{
            tablet: "(max-width:1199px)",
            desktop: "(min-width:1200px)",
          }}
        >
          {(matches) => (
            <div className="create_success-container">
              <div className="create_sucess-box">
                <div className="create_sucess-header">
                  <span>{i18n.t("teamPage.success.header")}</span>
                </div>
                <div className="create_sucess-body">
                  <ProfilePhoto src={this.props.teamPhoto} size={142} />
                  <span>{i18n.t("teamPage.success.msg")}</span>
                </div>
              </div>
            </div>
          )}
        </Media>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
export default connect(mapStateToProps)(CreateSuccess);
