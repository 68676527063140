import React, { Component } from "react";
import "./Collection.scss";

import { compose } from "redux";
import OffSettingIcon from "../../image/ProjectIcon/project-settings-icon.svg";
// import OnSettingIcon from "../../image/ProjectIcon/red-project-settings-icon.svg";
import { ClickAwayListener } from "@material-ui/core";
import i18n from "../../i18n";
import MobileSelector from "../../Component/MobileSelector/MobileSeletor";
import Media from "react-media";
import DeleteDialog from "../../Component/CollectionDialog/CollectionDelDialog/CollectionDelDialog";
import {
  PatchCollection,
  PostDeleteCollection,
} from "../../Api/Collection/Collection";
import { withSnackbar } from "notistack";
import EditCollectionDialog from "../CollectionDialog/AddColletionDialog/AddCollectionDialog";
class Collection extends Component {
  state = {
    settingMenuOpen: false,
    deleteDialogOpen: false,
    editDialogOpen: false,
    selectedInfo: null,
    settingMenu: [
      {
        id: 1,
        text: i18n.t("project.edit"),
      },

      // {
      //   id: 2,
      //   text: "Copy",
      // },

      // {
      //   id: 4,
      //   text: "Unpublish",
      // },

      {
        id: 2,
        text: i18n.t("global.delete"),
      },
      // {
      //   id: 3,
      //   text: i18n.t("global.copy"),
      // },
    ],

    collectionId: "",
  };

  handleSelecAction = (collectionInfo, id) => {
    this.setState(
      {
        selectedInfo: {
          _id: collectionInfo.collectionId,
          projects: collectionInfo.collectionList,
          name: collectionInfo.collectionName,
        },
      },
      () => {
        switch (id) {
          case 1:
            this.handleEditDialogOpen();
            break;
          case 2:
            this.handleDeleteDialogOpen();
            break;
          case 3:
            break;
          default:
            break;
        }
      }
    );
  };
  handleEditDialogOpen = () => {
    this.setState({ editDialogOpen: !this.state.editDialogOpen });
  };
  handleDeleteDialogOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };
  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleMobileSelectorClose = () => {
    this.setState({ deleteDialogOpen: false });
  };
  handleDeleteColletion = async () => {
    try {
      await PostDeleteCollection(this.state.selectedInfo._id);

      this.setState({ deleteDialogOpen: false }, () => {
        this.props.colletionReLoad(this.props.userInfoId);
      });
    } catch (e) {
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      console.dir(e);
    }
  };
  handleCollectionPage = (collectionId) => {
    this.props.history.push(`collections/?id=${collectionId}`);
  };

  handleSettingOpen = () => {
    this.setState({ settingMenuOpen: true });
  };
  handleSettingClose = () => {
    this.setState({ settingMenuOpen: false });
  };
  handleModifyCollection = async (name, isHidden) => {
    try {
      await PatchCollection(this.state.selectedInfo._id, name, isHidden);
      this.setState({
        selectedInfo: null,
        editDialogOpen: false,
      });
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    const {
      collectionId,
      collectionCount,
      collectionName,
      collectionList,
      myCollection,
    } = this.props;

    return (
      <Media
        queries={{
          tablet: "(max-width:1199px)",
          desktop: "(min-width:1200px)",
        }}
      >
        {(matches) => (
          <div className="collection-gridContainer">
            {this.state.editDialogOpen && (
              <EditCollectionDialog
                creatorInfo={this.props.creatorInfo}
                isModify={true}
                projects={this.state.selectedInfo?.projects}
                open={this.state.editDialogOpen}
                onClose={() =>
                  this.setState({ editDialogOpen: false, selectedInfo: null })
                }
                handleCreateCollection={this.handleModifyCollection}
                collectionInfo={this.state.selectedInfo}
              />
            )}
            {myCollection && matches.tablet ? (
              <MobileSelector
                open={this.state.mobileSelectorOpen}
                onClose={this.handleMobileSelectorClose}
                selecList={this.state.mobileSettingMenu}
                handleSelec={this.handleSelecList}
              ></MobileSelector>
            ) : null}
            <DeleteDialog
              dialogHeadText={i18n.t("collection.delete.title")}
              dialogBodyText1={i18n.t("collection.delete.text")}
              dialogBodyText2={i18n.t("collection.delete.subText")}
              dialogButtonText2={i18n.t("projDelete.checkBtn")}
              open={this.state.deleteDialogOpen}
              onClose={this.handleDeleteDialogClose}
              onButtonClick={this.handleDeleteColletion}
            />
            <div className="gridContainer-box">
              <div>
                <div className="girdImg-list">
                  <div
                    className={`${
                      collectionList[0] ? "gridImg-item" : "nullGrid"
                    }`}
                    style={{
                      backgroundImage: `url(${
                        collectionList[0] ? collectionList[0].thumbnail : null
                      })`,
                    }}
                  ></div>
                  <div className="imgColumn">
                    <div
                      style={{
                        backgroundImage: `url(${
                          collectionList[1] ? collectionList[1].thumbnail : null
                        })`,
                      }}
                      className={`${
                        collectionList[1] ? "gridImg-item" : "nullGrid"
                      }`}
                    ></div>
                    <div
                      style={{
                        backgroundImage: `url(${
                          collectionList[2] ? collectionList[2].thumbnail : null
                        })`,
                      }}
                      className={`${
                        collectionList[2] ? "gridImg-item" : "nullGrid"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              {myCollection && matches.desktop ? (
                <div
                  onClick={() => this.handleSettingOpen()}
                  className="Project-setting"
                >
                  <div className="Project-list">
                    <img src={OffSettingIcon} alt="SettingIcon" />
                    {this.state.settingMenuOpen ? (
                      <ClickAwayListener onClickAway={this.handleSettingClose}>
                        <div className="settingList boxshadow">
                          {this.state.settingMenu.map((item) => {
                            return (
                              <span
                                key={`setting_item-${item.id}`}
                                onClick={() =>
                                  this.handleSelecAction(
                                    {
                                      collectionId,
                                      collectionName,
                                      collectionList,
                                    },
                                    item.id
                                  )
                                }
                              >
                                {item.text}
                              </span>
                            );
                          })}
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="gridContent-title">
                <span>{collectionName}</span>
                <span>{collectionCount} items</span>
              </div>
              <div
                className="hidden-info"
                onClick={() => this.handleCollectionPage(collectionId)}
              ></div>
            </div>
          </div>
        )}
      </Media>
    );
  }
}

export default compose(withSnackbar)(Collection);
