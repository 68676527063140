import React, { Component } from "react";
import "./TeamInfo.scss";
import InstaIcon from "../../image/Snsicon/circle-sns-icon-1.svg";
import FacebookIcon from "../../image/Snsicon/circle-sns-icon-2.svg";
import YoutubeIcon from "../../image/Snsicon/circle-sns-icon-3.svg";
import PinterIcon from "../../image/Snsicon/circle-sns-icon-4.svg";
import DribbleIcon from "../../image/Snsicon/circle-sns-icon-5.svg";
import viewImg from "../../image/view-icon.svg";
import likeImg from "../../image/like-icon.svg";
import LocationIcon from "../../image/location-icon.svg";
import Follower from "../../image/mypage-follower-icon.svg";
import TumblrIcon from "../../image/Snsicon/circle-sns-icon-6.svg";
import VimeoIcon from "../../image/Snsicon/circle-sns-icon-7.svg";
import MediumIcon from "../../image/Snsicon/circle-sns-icon-8.svg";
import SoundCloudIcon from "../../image/Snsicon/circle-sns-icon-9.svg";
import FollowDialog from "../../Mypage/OtherPage/FollowDialog/FollowDialog";
import Home from "../../image/mypage-homepage-icon.svg";

import profileEditBtn from "../../image/job/edit-profile-icon.svg";
import i18n from "../../i18n";
import { Avatar } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
//import MobileMsgIcon from "../../Mobile/Mypage/in_my_message_black_icon_button.svg";
import MembersIcon from "../../image/users.png";
import { nFormatter } from "../../Component/functions";
import {
  GetFollowerList,
  GetFollowingList,
  SetFollow,
  SetUnFollow,
} from "../../Api/Follow/Follow";
import { Fragment } from "react";
import { GetUserToken } from "../../modules/userAuth";
class TeamInfo extends Component {
  state = {
    FollowDialogOpen: false,
    FollowLoading: false,
    FollowOrFollowing: "1",
    isFollow: false,
    isLoading: false,
    FollowerList: [],
    FollowingList: [],
    teamInfo: {},
    scrollOffset: 0.055,
    theposition: false,
  };

  iconFinder = (websiteName) => {
    if (websiteName === "Instagram") {
      return InstaIcon;
    } else if (websiteName === "Facebook") {
      return FacebookIcon;
    } else if (websiteName === "Pinterest") {
      return PinterIcon;
    } else if (websiteName === "Dribble") {
      return DribbleIcon;
    } else if (websiteName === "Youtube") {
      return YoutubeIcon;
    } else if (websiteName === "Tumblr") {
      return TumblrIcon;
    } else if (websiteName === "Vimeo") {
      return VimeoIcon;
    } else if (websiteName === "Medium") {
      return MediumIcon;
    } else if (websiteName === "SoundCloud") {
      return SoundCloudIcon;
    }
    // else {
    //     return OtherIcon
    // }
  };
  handleFollowDialogClose = () => {
    this.setState({ FollowDialogOpen: false });
  };
  componentDidMount = () => {
    this.setState({ teamInfo: this.props.teamInfo });
    window.addEventListener("scroll", this.listenToScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 563) {
      this.setState(
        {
          theposition: true,
        },
        () => console.log(this.state.theposition)
      );
    } else {
      this.setState({ theposition: false });
    }
  };

  handleFollowUser = async (id, isFollowing) => {
    const token = GetUserToken();
    let userInfoCopy = this.props.teamInfo;
    userInfoCopy.isFollowing = !userInfoCopy.isFollowing;
    let teamId = id ? id : this.props.teamInfo._id;
    if (token) {
      if (!isFollowing) {
        userInfoCopy.follower = ++userInfoCopy.follower;
        this.setState({ teamInfo: userInfoCopy });
        try {
          await SetFollow(teamId);
          this.setState({ isLoading: false }, () =>
            this.setState({ isFollow: true })
          );
        } catch (e) {
          console.dir(e);
          userInfoCopy.follower--;
          userInfoCopy.isFollowing = !userInfoCopy.isFollowing;
          this.setState({
            isFollow: true,
            isLoading: false,
            teamInfo: userInfoCopy,
          });
        }
      } else {
        userInfoCopy.follower = --userInfoCopy.follower;
        this.setState({ teamInfo: userInfoCopy });
        try {
          await SetUnFollow(teamId);
          this.setState(
            { isFollow: false, isLoading: false },
            () => this.handleFollowLists
          );
        } catch (e) {
          console.dir(e);
          userInfoCopy.follower++;
          userInfoCopy.isFollowing = !userInfoCopy.isFollowing;
          this.setState({
            isFollow: false,
            isLoading: false,
            teamInfo: userInfoCopy,
          });
        }
      }
    } else {
      this.props.history.push("/login");
    }
  };
  handleFollowLists = async (type, skip, limit) => {
    this.setState({
      FollowOrFollowing: type,
      FollowDialogOpen: true,
    });
    if (type === "1") {
      this.followerListLoad(skip, limit);
    } else if (type === "2") {
      this.followingListLoad(skip, limit);
    }
  };
  followerListLoad = async (skip, limit) => {
    this.setState({
      FollowLoading: true,
    });

    const form = {};

    form.id = this.props.teamInfo._id;

    form.skip = skip;
    form.limit = limit;

    try {
      const response = await GetFollowerList(
        form.id ?? this.props.teamInfo._id,
        form.skip,
        form.limit
      );

      this.setState({ FollowerList: response }, () =>
        this.setState({ FollowLoading: false })
      );

      this.setState({ FollowLoading: false });
    } catch (e) {
      this.setState({ FollowLoading: false });
      console.dir(e);
    }
  };
  followingListLoad = async (skip, limit) => {
    this.setState({
      FollowLoading: true,
      // FollowerList: [],
    });
    const form = {};

    form.id = this.props.teamInfo._id;

    form.skip = skip;
    form.limit = limit;

    try {
      const response = await GetFollowingList(
        form.id ?? this.props.teamInfo._id,
        form.skip,
        form.limit
      );
      this.setState({ FollowingList: response }, () =>
        this.setState({ FollowLoading: false })
      );

      // console.log("팔로잉 통신 : ", response, url, this.state.userInfo._id);
    } catch (e) {
      console.dir(e);
    }
  };
  render() {
    const { teamInfo, isSelfURL, matches, isUserAuth } = this.props;

    return (
      <div
        className={`profile-box ${
          this.state.theposition ? "profile-box-sticky" : null
        }`}
      >
        {this.state.FollowDialogOpen ? (
          <FollowDialog
            FollowOrFollowing={this.state.FollowOrFollowing}
            open={this.state.FollowDialogOpen}
            onClose={this.handleFollowDialogClose}
            FollowLoading={this.state.FollowLoading}
            FollowerList={this.state.FollowerList}
            FollowingList={this.state.FollowingList}
            followingListLoad={this.followingListLoad}
            followerListLoad={this.followerListLoad}
            handleFollowUser={this.handleFollowUser}
            isFollow={this.isFollow}
            handleFollowLists={this.handleFollowLists}
            userInfo={teamInfo}
            history={this.props.history}
          ></FollowDialog>
        ) : null}

        {matches.tablet && (
          <div className="mobile-profilePhoto">
            <div></div>
            <div className="profile-photo">
              {teamInfo.teamPhoto ? (
                <img src={teamInfo.teamPhoto} alt="profilePhoto" />
              ) : (
                <Avatar size={100} icon={<UserOutlined />} />
              )}
            </div>

            <div className="mobile-profilePhotoBtns">
              {/* <div className="settingBox">
                <img src={OffSettingIcon} alt="OffSettingIcon" />
              </div> */}

              {/* <img src={MobileMsgIcon} alt="Message" /> */}
              {isUserAuth === "admin" || isUserAuth === "owner" ? (
                <button
                  className="iconBorderBtn"
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/team/setting`,
                      state: { teamId: teamInfo._id },
                    });
                  }}
                >
                  <img src={profileEditBtn} alt="profileEditBtn" />
                </button>
              ) : null}
              {!isSelfURL ? (
                <button
                  onClick={() =>
                    this.handleFollowUser(teamInfo._id, teamInfo.isFollowing)
                  }
                  // className="followBtn"
                  className={
                    teamInfo.isFollowing ? "following-full" : "follow-full"
                  }
                >
                  {teamInfo.isFollowing
                    ? i18n.t("mypage.profile.unfollow")
                    : i18n.t("mypage.profile.follow")}
                </button>
              ) : null}
            </div>
          </div>
        )}
        {matches.desktop && (
          <div
            className="team-profile-photo"
            style={{ marginBottom: "20px", position: "relative" }}
          >
            {teamInfo.teamPhoto ? (
              <img
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  border: "1px solid #efefef",
                }}
                src={teamInfo.teamPhoto}
                alt="profile"
              ></img>
            ) : (
              <Avatar size={100} icon={<TeamOutlined />} />
            )}
            {isUserAuth === "admin" || isUserAuth === "owner" ? (
              <button
                className="iconBorderBtn"
                onClick={() => {
                  this.props.history.push({
                    pathname: `/team/setting`,
                    state: { teamId: teamInfo._id },
                  });
                }}
                style={{
                  position: "absolute",
                  right: "0px",
                  bottom: "5px",
                  borderRadius: "50%",
                  // border: "1px solid #e1e2e3",
                  width: "25px",
                  height: "25px",
                  padding: "6px",
                }}
              >
                <img src={profileEditBtn} alt="profileEditBtn" />
              </button>
            ) : null}
          </div>
        )}

        <div className="profile-otherInfo">
          <span className="profile-otherInfo-userName teamInfo-teamName">
            {teamInfo.name}
            <span>TEAM</span>
          </span>

          <div className="other-location team">
            <img src={LocationIcon} alt={teamInfo.countryInfo?.countryName} />
            <span>
              {teamInfo.countryInfo ? teamInfo.countryInfo?.countryName : null}
            </span>
          </div>

          {matches.desktop && (
            <Fragment>
              <div className="teamInfo-member-row">
                <img src={MembersIcon} alt="MembersIcon" />
                <span>{teamInfo.members ? teamInfo.members.length : 0}</span>
              </div>
              <div className={`profile-snsBox`}>
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "white",
                    backgroundColor: "#070707",
                    // border: "0.5px solid #070707",
                    fontSize: "12px",
                  }}
                  className="sns-Avatars-Grp"
                  gap={10}
                >
                  {teamInfo.linkInfo
                    ? teamInfo.linkInfo.map((item) => {
                        return item.link ? (
                          // <Tooltip title={item.websiteName} placement="top">
                          <Avatar
                            onClick={() =>
                              window.open(`https://${item.link}`, "_blank")
                            }
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            src={this.iconFinder(item.websiteName)}
                          />
                        ) : // </Tooltip>
                        null;
                      })
                    : null}
                </Avatar.Group>
              </div>
            </Fragment>
          )}
        </div>
        {matches.desktop &&
          (!isSelfURL ? (
            <div className="profile-btns">
              <div className="btnsRow">
                <button
                  className={
                    teamInfo.isFollowing ? "following-full" : "follow-full"
                  }
                  type="button"
                  onClick={() =>
                    this.handleFollowUser(teamInfo._id, teamInfo.isFollowing)
                  }
                >
                  {teamInfo.isFollowing
                    ? i18n.t("mypage.profile.unfollow")
                    : i18n.t("mypage.profile.follow")}
                </button>
              </div>
            </div>
          ) : null)}

        <div className="userInfo-container">
          {matches.desktop && (
            <div
              className="userInfo-status"
              style={this.state.theposition ? { display: "none" } : null}
            >
              <div className="status-views status">
                <div id="box">
                  <img src={viewImg} alt="views" />
                  <span>{i18n.t("mypage.profile.views")}</span>
                </div>
                <span>{nFormatter(teamInfo.views)}</span>
              </div>
              <div className="status-follower status">
                <div id="box">
                  <img src={Follower} alt="follower" />
                  <span>{i18n.t("mypage.profile.follower")}</span>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleFollowLists("1")}
                >
                  {teamInfo.follower}
                </span>
              </div>
              {/* <div className="status-views status">
                <div id="box">
                  <img src={Follower} alt="follow" />
                  <span>{i18n.t("mypage.profile.following")}</span>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleFollowLists("2")}
                >
                  {nFormatter(teamInfo.following)}
                </span>
              </div> */}
              <div className="status-views status">
                <div id="box">
                  <img
                    src={likeImg}
                    alt="like"
                    style={{ marginRight: "2px" }}
                  />
                  <span>{i18n.t("mypage.profile.totalLike")}</span>
                </div>
                <span>{nFormatter(teamInfo.likeMembers)}</span>
              </div>
            </div>
          )}
          {matches.tablet && (
            <div className="Mobile-followInfo">
              <div
                className="followRow"
                onClick={() => this.handleFollowLists("1")}
              >
                <span>{nFormatter(teamInfo.follower)}</span>
                <span> {i18n.t("mypage.profile.follower")}</span>
              </div>
            </div>
          )}
          {matches.desktop && (
            <div
              className={`userInfo-site ${
                teamInfo.website ? null : "borderNone"
              }`}
              style={this.state.theposition ? { display: "none" } : null}
            >
              {teamInfo.website ? (
                <div className="site-box">
                  <img src={Home} alt="site" />
                  <span
                    onClick={() =>
                      window.open(`https://${teamInfo.website}`, "_blank")
                    }
                  >
                    {teamInfo.website.length >= 30
                      ? teamInfo.website.substr(0, 30) + "..."
                      : teamInfo.website}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {matches.desktop && (
            <div
              className={`userInfo-intro ${
                teamInfo.teamInfo
                  ? teamInfo.teamInfo.title && teamInfo.teamInfo.explanation
                    ? null
                    : "borderNone"
                  : "borderNone"
              }`}
              style={this.state.theposition ? { display: "none" } : null}
            >
              <div className="intro-header h">
                <span>
                  {teamInfo.teamInfo ? teamInfo.teamInfo.title : "Intorduction"}
                </span>
              </div>
              <span>
                {teamInfo.teamInfo ? teamInfo.teamInfo.explanation : null}
              </span>
            </div>
          )}
          {/* {isUserAuth === "admin" ||
          (isUserAuth === "owner" && matches.desktop) ? (
            <button
              className="editSetting"
              onClick={() => {
                this.props.history.push({
                  pathname: `/team/setting`,
                  state: { teamId: teamInfo._id },
                });
              }}
            >
              {i18n.t("mypage.profile.edit")}
            </button>
          ) : null} */}

          {matches.desktop && (
            <div className="userInfo-footer">
              <span>
                {i18n.t("teamPage.createdAt")}{" "}
                {teamInfo.createdAt
                  ? teamInfo.createdAt.substring(0, 10)
                  : null}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TeamInfo;
