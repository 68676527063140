import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const JwtRefresh = async () => {
  const token = GetUserToken();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/jwt-refresh`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

export const GetUserInfo = async (userId) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/info?id=${userId}`;
  const response = await axios.get(url);

  return response.data.data;
};
export const GetLinkInfo = async (linkURL) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/user-link-info?linkURL=${linkURL}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const GetUserList = async (searchStr) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/list?searchString=${searchStr}`;
  const response = await axios.get(url, config);

  return response.data.data;
};

export const PostUserPhotoSet = async (file) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/set-photo`;
  const body = new FormData();
  body.append("profilePhoto", file);
  await axios.patch(url, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const PostDeleteUser = async () => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/delete`;
  const response = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.message;
};
export const PostAccountSet = async (linkURL) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/setting`;
  const response = await axios.patch(
    url,
    {
      linkURL,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.message;
};
export const PostSetUserInfo = async (
  userName,
  classification,
  countryInfo,
  website,
  linkInfo,
  userInfo
) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/set-info`;

  let body = {
    userName,
    classification,
    countryInfo,
    website,
    linkInfo,
    userInfo,
  };

  const response = await axios.patch(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.message;
};

export const GetMyInfo = async (accessToken) => {
  const token = GetUserToken();

  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/user/myinfo`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data.data;
};
