import React, { Component } from "react";
import "./Pagination.scss";
import LeftDouble from "../../image/PaginationIcon/line-gray-left-double-icon.svg";
import Left from "../../image/PaginationIcon/line-gray-left-icon.svg";
import RightDouble from "../../image/PaginationIcon/line-gray-right-double-icon.svg";
import Right from "../../image/PaginationIcon/line-gray-right-icon.svg";
class Pagination extends Component {
  state = {
    pageGroupSize: 5,
    curPageGroup: 1,
    page: this.props.currentPage || 1,
  };
  maxPage =
    Math.floor(this.props.totalCount / this.props.limit) +
    (this.props.totalCount % this.props.limit > 0 ? 1 : 0);
  renderPage = () => {
    const start = this.state.pageGroupSize * (this.state.curPageGroup - 1);
    const end =
      start + this.state.pageGroupSize > this.maxPage
        ? this.maxPage
        : start + this.state.pageGroupSize;

    let retArray = [];
    for (let loop = start + 1; loop <= end; loop++) {
      retArray.push(loop);
    }

    return retArray;
  };
  handleFirst = () => {
    this.handleActivePage(1);
  };

  handleLast = () => {
    this.handleActivePage(this.maxPage);
  };
  handleActivePage = (page) => {
    if (page > 0 && page <= this.maxPage) {
      this.setState({ page });
      if (this.props.handleActivePage) {
        this.props.handleActivePage(page);
      }
    }
  };
  render() {
    return this.renderPage().length > 1 ? (
      <div className="pagination">
        <div className="firstPage" onClick={this.handleFirst}>
          <img src={LeftDouble} alt="first" />
        </div>
        <div
          className="prevPage"
          onClick={() => this.handleActivePage(this.state.page - 1)}
        >
          <img src={Left} alt="left" />
        </div>
        <div className="number_wrapper">
          {this.renderPage().map((num) => {
            return (
              <div
                className={num === this.state.page ? "activePage" : ""}
                onClick={
                  num === this.state.page
                    ? null
                    : () => this.handleActivePage(num)
                }
              >
                {num}
              </div>
            );
          })}
        </div>
        <div
          className="nextPage"
          onClick={() => this.handleActivePage(this.state.page + 1)}
        >
          <img src={Right} alt="right" />
        </div>
        <div className="lastPage" onClick={this.handleLast}>
          <img src={RightDouble} alt="last" />
        </div>
      </div>
    ) : null;
  }
}
export default Pagination;
