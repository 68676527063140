import React, { Component } from "react";
import { connect } from "react-redux";
import query from "query-string";
import { PostInviteMail } from "../../Api/Team/Team";
import "./TeamInviteMail.scss";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
// import { Spin } from "antd";
import FadeLoader from "react-spinners/BeatLoader";
import warningIcon from "../../image/DelteDialogIcon/warning-icon.svg";
import Button from "../../Component/Button/Button";
class TeamInviteMail extends Component {
  state = {
    isLoading: true,
    teamInfo: null,
    isInvite: true,
    errorCode: null,
  };
  componentDidMount = async () => {
    const qs = query.parse(this.props.history.location.search);
    if (qs.c && qs.u && qs.t) {
      try {
        const res = await PostInviteMail(qs.c, qs.u, qs.t);

        this.setState({ teamInfo: res, isLoading: false, isInvite: true });
      } catch (e) {
        console.dir(e);
        switch (e.response.data.error_code) {
          case "ERR_INVALID_REQ":
            this.setState({ errorCode: 1 });
            break;
          case "ERR_VERIFY_FAILED":
            this.setState({ errorCode: 2 });
            break;
          default:
            this.setState({ errorCode: 3 });
            break;
        }
        this.setState({ isLoading: false, isInvite: false });
      }
    } else {
    }
  };
  renderErrorMsg = () => {
    if (this.state.errorCode === 1) {
      return "이미 가입했거나 관리자가 초대를 취소한 유저입니다.";
    } else if (this.state.errorCode === 2) {
      return "잘못된 가입코드이거나 만료된 가입코드입니다.";
    } else {
      this.props.history.push("/not-found");
    }
  };
  renderBody = () => {
    const teamInfo = this.state.teamInfo;
    if (!this.state.isInvite) {
      return (
        <div className="team-invite_success">
          <ProfilePhoto src={teamInfo?.teamPhoto} size={80} />
          <span className="title">팀 참여 완료</span>
          <span className="sub-title">
            {teamInfo?.name} 팀에 합류가 완료되었습니다 <br />
            로그인 후 모든 기능 이용이 가능합니다.
          </span>
          <Button
            width="150"
            text={"로그인 화면으로 돌아가기"}
            btnClick={() => this.props.history.push("/login")}
          />
        </div>
      );
    } else {
      return (
        <div className="team-invite_failed">
          <img src={warningIcon} alt="invite-error" />
          <span>{this.renderErrorMsg()}</span>
          <Button
            width="150"
            text={"로그인 화면으로 돌아가기"}
            btnClick={() => this.props.history.push("/login")}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="team-invite_container">
        <div className="team-invite_wrapper">
          {!this.state.isLoading ? (
            this.renderBody()
          ) : (
            <FadeLoader
              color={"black"}
              size={24}
              loading={this.state.isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
});
export default connect(mapStateToProps, null)(TeamInviteMail);
