import React, { Component } from "react";
import "./TeamProjectUpload.scss";
import { Dialog } from "@material-ui/core";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { PostAddProject } from "../../Api/Project/project";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withSnackbar } from "notistack";
class TeamProjectUpload extends Component {
  state = {
    isLoading: false,
    selectedUser: [],
  };

  handleProjectUpload = async () => {
    this.setState({ isLoading: true });
    try {
      await PostAddProject(this.props.teamId, this.props.projectId);
      this.setState({ isLoading: false }, () => {
        this.props.projectListLoad(this.props.teamId);
        this.props.onClose();
      });
    } catch (e) {
      console.dir(e);
      this.setState({ isLoading: false }, () => {
        this.props.onClose();
        // alert("서버 통신 에러가 발생했습니다.");
        this.handleAlert();
      });
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const theme1 = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });

    const { onClose, open, thumbnail } = this.props;
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <ThemeProvider theme={theme1}>
          <Dialog
            fullWidth={this.props.fullWidth}
            classes={{
              root: `classes-nesting-uploadSetting`, // class name, e.g. `classes-nesting-root-x`
              // label: `classes-nesting-label-uploadSetting`, // class name, e.g. `classes-nesting-label-x`
            }}
            maxWidth={"false"}
            open={open}
            onClose={onClose}
          >
            <Spin
              indicator={antIcon}
              spinning={this.state.isLoading}
              delay={500}
            >
              <div className="closeBtn" onClick={onClose}>
                <img src={closeIcon} alt="close" />
              </div>
              <div className="one-button-dialog-container projectUpload">
                <div className="projectSetting-container">
                  <div className="projectSetting-title">
                    <span>{i18n.t("header.upload")}</span>
                  </div>

                  <div className="previewBox">
                    <div
                      className="preivewImg-content"
                      style={{
                        backgroundImage: `url(${thumbnail})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    >
                      {/* <img src={this.state.preview} alt="thumbnail" /> */}
                    </div>
                  </div>

                  <div className="projectSetting-btns">
                    <button className="backBtn" type="button" onClick={onClose}>
                      {i18n.t("project.upload.back")}
                    </button>
                    <div className="rtBtns">
                      <button type="button" onClick={this.handleProjectUpload}>
                        {i18n.t("project.upload.uploadBtn")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withSnackbar(TeamProjectUpload);
