import axios from "axios";
import { GetUserToken } from "../../../modules/userAuth";

export const GetAdminJobApplications = async (teamId, skip = 0, limit = 20) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/job-applicantions?id=${teamId}&skip=${skip}&limit=${limit}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(url, config);

  return response.data.data;
};

export const GetAdminJobList = async (
  searchString,
  filter,
  order,
  isASC,
  workingHours,
  careerInfos,
  countryInfo,
  remoteAvailable,
  skip,
  limit
) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/job-list?limit=${limit}&isASC=${isASC}`;
  if (searchString) url += `&searchString=${searchString}`;
  if (filter) url += `&filter=${filter}`;
  if (order) url += `&order=${order}`;
  // if (strDate && endDate) url = url + `&strDate=${strDate}&endDate=${endDate}`;
  if (skip) url += `&skip=${skip}`;
  if (remoteAvailable) url += `&remoteAvailable=${remoteAvailable}`;
  if (workingHours && workingHours.length) {
    for (let i = 0; i < workingHours.length; i++) {
      url += `&workingHours[${i}]=${workingHours[i]}`;
    }
  }
  if (careerInfos && careerInfos.length) {
    for (let i = 0; i < careerInfos.length; i++) {
      url += `&careerInfos[${i}]=${careerInfos[i]}`;
    }
  }
  if (countryInfo.countryCode)
    url += `&countryInfo[countryCode]=${countryInfo.countryCode}`;
  if (countryInfo.region) url += `&countryInfo[region]=${countryInfo.region}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const DeleteJob = async (id) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/job-delete`;
  let body = {
    id,
  };
  await axios.patch(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
