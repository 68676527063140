import React, { Component } from "react";
import "./FindEmail.scss";
import i18n from "../../i18n";
import { PostPWResetEmail, EmailExistsCheck } from "../../Api/Account/account";
import { withSnackbar } from "notistack";
class FindEmail extends Component {
  state = {
    email: "",
    mailSendMsg: "",
    emailCheck: false,
    emailMsg: "",
    nextActive: false,
  };
  componentDidMount = () => {
    const emailBtn = document.getElementById("emailReduBtn");
    const btn = document.getElementById("findBtn");
    emailBtn.disabled = true;
    btn.disabled = true;
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration: object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate: object && typeof object.preventDuplicate === "boolean" ? object.preventDuplicate : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    }
    this.props.enqueueSnackbar(text, valueObject);
  }
  handleChangeEmail = (e) => {
    this.setState({ mailSendMsg: "", nextActive: false });
    const emailRule =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email = e.target.value.trim();
    const emailBtn = document.getElementById("emailReduBtn");
    const btn = document.getElementById("findBtn");
    if (email.length === 0) {
      emailBtn.disabled = true;
      this.setState({
        emailCheck: false,
        email: email,
        emailMsg: i18n.t("userInfo.emailInput"),
      });
    } else if (!emailRule.test(email)) {
      emailBtn.disabled = true;
      this.setState({
        emailCheck: false,
        email: email,
        emailMsg: i18n.t("userInfo.emailCondition"),
      });
    } else {
      emailBtn.disabled = false;
      this.setState({
        emailCheck: true,
        email: email,
        emailMsg: "",
      });
    }
    if (!this.state.emailCheck) {
      btn.disabled = true;
    } else {
      btn.disabled = false;
    }
  };
  handlePaswordReset = async () => {
    try {
      await PostPWResetEmail(this.state.email, i18n.lang);
      this.setState({
        mailSendMsg: i18n.t("findPassword.alert.link"),
      });
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert(i18n.t("global.error.server.msg"))
      console.log(e);
    }
  };
  handleEmailCheck = async () => {
    try {
      const response = await EmailExistsCheck(this.state.email);

      const description = response.toUpperCase();

      if (description === "LOCAL") {
        this.setState({
          nextActive: true,
          mailSendMsg: i18n.t("findPassword.alert.valid"),
        });
      } else if (description === null) {
        this.setState({
          nextActive: false,
          emailMsg: i18n.t("findPassword.alert.inValid"),
        });
      } else {
        this.setState({
          nextActive: false,
          emailMsg: i18n.t(
            i18n.t(`findPassword.alert.social`, { description })
          ),
        });
      }
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert(i18n.t("global.error.server.msg"))
      console.log(e);
    }
  };
  render() {
    return (
      <div className="findContainer">
        <div className="emailFindBox">
          <span>{ i18n.t("findPassword.title") }</span>
          { i18n.t("findPassword.subTitle") }
          <span
            style={
              this.state.mailSendMsg
                ? {
                  display: "block",
                  marginTop: "20px",
                  color: "green",
                }
                : { display: "none" }
            }
            className="alertMsg"
          >
            { this.state.mailSendMsg }
          </span>
          {/* </span> */ }

          <div className="numberInput">
            {/* <span>{i18n.t('login.email')}</span> */ }

            <div className="emailInput row1">
              <input
                type="text"
                placeholder="E-mail"
                onChange={ this.handleChangeEmail }
              />
              <button
                id="emailReduBtn"
                className={ this.state.emailCheck ? "checkBox" : "hidenBox" }
                type="button"
                onClick={ this.state.emailCheck ? this.handleEmailCheck : null }
              >
                { i18n.t("global.check") }
              </button>
            </div>
            <span className="alertMsg">{ this.state.emailMsg }</span>
          </div>

          <button
            className={ this.state.nextActive ? "findNextBtn" : "disableBtn" }
            type="button"
            id="findBtn"
            onClick={ this.state.nextActive ? this.handlePaswordReset : null }
          >
            { i18n.t("findPassword.alert.email") }
          </button>
        </div>
      </div>
    );
  }
}

export default withSnackbar(FindEmail);
