import React, { Component } from "react";
import "./Login.scss";
import KakaoIcon from "../image/join-sns-icon-1.svg";
import NaverIcon from "../image/join-sns-icon-2.svg";
import GoogleIcon from "../image/join-sns-icon-3.svg";
import FacebookIcon from "../image/Snsicon/join-sns-icon-4.svg";
import i18n from "../i18n";
import { openSignInWindow } from "../Component/HandlePopup/Handlepopup";
import { connect } from "react-redux";
import { compose } from "redux";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getMyInfo, changeLoginStatus } from "../Redux/action/index";
import { PostLogin } from "../Api/Account/account";
import { GetMyInfo } from "../Api/User/user";
import { withSnackbar } from "notistack";
import { RemoveUserToken, SetUserToken } from "../modules/userAuth";
import { SignUp_GAEvent } from "../modules/gaEvents";

class Login extends Component {
  state = {
    autoLogin: false,
    login: true,
    loginInfo: {
      isIndividual: true,
    },
    user_id: "",
    user_pw: "",
    userInfo: [],
    isLoading: false,
    isCapsLockOn: false,
  };

  toFind = () => {
    this.props.history.push("/find");
  };
  toSignUp = () => {
    this.props.history.push("/sign-up");
  };
  handleAutoLogin = (e) => {
    if (e.target.checked) {
      this.setState({ autoLogin: true });
    } else {
      this.setState({ autoLogin: false });
    }
  };
  handlechangeLoginStatus = async (value) => {
    await this.props.changeLoginStatus(value);
  };

  handlechangeInfo = (value) => {
    if (value) {
      this.setState({
        loginInfo: { ...this.state.loginInfo, isIndividual: true },
      });
    } else {
      this.setState({
        loginInfo: { ...this.state.loginInfo, isIndividual: false },
      });
    }
  };

  handleGoogleLogin = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/google`,
      "/",
      this.props.history,
      this.handleLoginSuccess
    );
  };
  handleKakaoLogin = () => {
    this.props.enqueueSnackbar(i18n.t("준비중인 기능입니다."), {
      variant: "warning",
      key: "under.building",
      autoHideDuration: 5000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    // openSignInWindow(
    //     `${process.env.REACT_APP_BASE_URL}/api/v1/auth/kakao`,
    //     '/',
    //     this.props.history,
    //     this.handleLoginSuccess
    // )
  };

  handleNaverLogin = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/naver`,
      "/",
      this.props.history,
      this.handleLoginSuccess
    );
  };
  handleFacebookLogin = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/facebook`,
      "/",
      this.props.history,
      this.handleLoginSuccess
    );
  };
  btnActiveCheck = () => {
    const btn = document.getElementById("loginButton");
    if (btn && this.state.user_id && this.state.user_pw) {
      btn.disabled = false;
      btn.style.backgroundColor = "#070707";
      btn.style.color = "#ffffff";
    } else {
      btn.disabled = true;
      btn.style.backgroundColor = "#efefef";
      btn.style.color = "#070707";
    }
  };

  componentDidMount = () => {
    this.handleUserPw();
    this.btnActiveCheck();
  };
  handleUserId = (e) => {
    this.setState({ user_id: e.target.value }, () => this.btnActiveCheck());
  };

  handleDetectCapsLock = (e) => {
    if (e.getModifierState("CapsLock") && !this.state.isCapsLockOn) {
      this.setState({ isCapsLockOn: true });
    } else if (!e.getModifierState("CapsLock") && this.state.isCapsLockOn) {
      this.setState({ isCapsLockOn: false });
    }
  };

  handleUserPw = (e) => {
    if (e) {
      this.setState({ user_pw: e.target.value });
    } else {
    }
    setTimeout(
      function () {
        this.btnActiveCheck();
      }.bind(this),
      100
    );
  };

  handleUserInfoSubmit = async (e) => {
    e.preventDefault();
    const btn = document.getElementById("loginButton");
    this.setState({
      isLoading: true,
    });
    btn.disabled = true;
    btn.style.backgroundColor = "#efefef";

    try {
      const response = await PostLogin(this.state.user_id, this.state.user_pw);
      console.log(response);
      if (response && response.message !== "error") {
        SetUserToken(
          response.data.token,
          this.state.autoLogin,
          response.data.firebaseToken
        );
      }

      const userInfoResult = await GetMyInfo(response.data.token);
      if (response.message === "success") {
        this.handlechangeLoginStatus(true);
        this.props.getMyInfo(userInfoResult);
        this.setState({ login: true, isLoading: false }, () => {
          this.props.history.push("/");
          window.location.reload();
        });
      } else {
        return;
      }
    } catch (e) {
      this.setState({ login: false, isLoading: false });

      console.dir(e);
    }
    btn.style.backgroundColor = "#070707";
    btn.disabled = false;
  };

  handleLoginSuccess = (data) => {
    return new Promise(async (resolve, reject) => {
      this.setState({ isLoading: true });
      try {
        if (data) {
          SetUserToken(data.token, null, data.firebaseToken);
          const userInfoResult = await GetMyInfo(data.token);
          this.props.getMyInfo(userInfoResult);
          this.handlechangeLoginStatus(true);
          this.setState({ isLoading: false }, () => {
            SignUp_GAEvent("login");
            this.props.history.push("/");
          });
        } else {
          this.setState({ isLoading: false }, () => {
            RemoveUserToken();
            this.props.enqueueSnackbar(i18n.t("login.alert"), {
              variant: "warning",
              key: "noData-registered",
              autoHideDuration: 5000,
              preventDuplicate: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          });
        }
        resolve();
      } catch (e) {
        RemoveUserToken();
        // this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        //   variant: "error",
        //   key: "global.error.server.msg",
        //   autoHideDuration: 5000,
        //   preventDuplicate: true,
        //   anchorOrigin: {
        //     vertical: "bottom",
        //     horizontal: "right",
        //   },
        // });
        console.log(e);
        reject();
      }
    });
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          <div className="Login-container">
            <form
              onSubmit={this.handleUserInfoSubmit}
              className="login-box"
              method="post"
            >
              <div className="login-header">
                <span>{i18n.t("global.login")}</span>
                {!this.state.login ? (
                  <span className="loginStatus">{i18n.t("login.check")}</span>
                ) : null}
              </div>

              <section className="indiviBox">
                <div className="login-inputBox">
                  <span>{i18n.t("login.email")}</span>
                  <input
                    type="email"
                    placeholder={i18n.t("login.email")}
                    name="email"
                    defaultValue={this.state.user_id}
                    onChange={this.handleUserId}
                    required
                  />
                  <span>{i18n.t("login.password")}</span>
                  <input
                    type="password"
                    placeholder={i18n.t("login.password")}
                    name="password"
                    defaultValue={this.state.user_pw}
                    onChange={this.handleUserPw}
                    onKeyDown={this.handleDetectCapsLock}
                    required
                  />
                </div>
                <div className="login-autoLogin">
                  <input
                    type="checkBox"
                    id="log"
                    name="saveInfo"
                    checked={this.state.autoLogin}
                    onChange={this.handleAutoLogin}
                  />
                  <label htmlFor="log">
                    <span></span>
                    {i18n.t("login.loggedin")}
                  </label>
                </div>

                <div className="loginBtns">
                  <button type="button" onClick={this.handleKakaoLogin}>
                    <img src={KakaoIcon} alt="Kakao" />
                    {i18n.t("global.kakao")}
                  </button>
                  <button type="button" onClick={this.handleNaverLogin}>
                    <img src={NaverIcon} alt="Naver" />
                    {i18n.t("global.naver")}
                  </button>
                  <button type="button" onClick={this.handleGoogleLogin}>
                    <img src={GoogleIcon} alt="Google" />
                    {i18n.t("global.google")}
                  </button>
                  <button type="button" onClick={this.handleFacebookLogin}>
                    <img src={FacebookIcon} alt="facebook" />
                    {i18n.t("global.facebook")}
                  </button>
                </div>

                <div className="login-btn">
                  <button id="loginButton" type="submit">
                    {i18n.t("global.login")}
                  </button>
                </div>
                <div className="login-signin">
                  <div className="accountFind">
                    {i18n.language === "en" ? (
                      <>
                        <span className="title">Did you forget your</span>
                        <span className="email" onClick={this.toFind}>
                          {" "}
                          password?
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="email" onClick={this.toFind}>
                          비밀번호
                        </span>
                        <span className="title">를 잊으셨나요?</span>
                      </>
                    )}
                  </div>
                  <div className="accountCreate">
                    <span className="member" onClick={this.toSignUp}>
                      {i18n.t("login.signup")}
                    </span>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
});
const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
  changeLoginStatus: (value) => dispatch(changeLoginStatus(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(Login);
