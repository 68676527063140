import i18n from "../i18n";
import { GetUserToken } from "../modules/userAuth";
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const isLoginCheck = () => {
  const token = GetUserToken();
  if (token) {
    return true;
  } else {
    return false;
  }
};
export const renderPayInfo = (payInfo) => {
  if (payInfo.isPrivate) {
    return i18n.t("jobInfo.payment.consultation");
  } else {
    if (i18n.language === "ko" || i18n.language === "ko_KR") {
      if (payInfo.currency === "KRW") {
        return `${numberWithCommas(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${numberWithCommas(payInfo.maximum).toLocaleString("ko-KR")} 만원`;
      } else {
        return `${numberWithCommas(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${numberWithCommas(payInfo.maximum).toLocaleString("ko-KR")} 만원`;
      }
    } else {
      if (payInfo.currency === "USD") {
        return `${nFormatter(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
      } else {
        return `${nFormatter(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
      }
    }
  }
};
export const renderWorkingHours = (workingHoursArr) => {
  for (let i = 0; i < workingHoursArr.length; i++) {
    switch (workingHoursArr[i]) {
      case "fullTime":
        workingHoursArr[i] = i18n.t("jobInfo.workingHours.fullTime");
        break;
      case "partTime":
        workingHoursArr[i] = i18n.t("jobInfo.workingHours.partTime");
        break;
      case "freelancer":
        workingHoursArr[i] = i18n.t("jobInfo.workingHours.freelancer");
        break;
      default:
    }
  }

  return workingHoursArr.join(" / ");
};

export const renderCareer = (careerArr, join) => {
  for (let i = 0; i < careerArr.length; i++) {
    switch (careerArr[i]) {
      case "intern":
        careerArr[i] = i18n.t("jobInfo.career.intern");
        break;
      case "newComer":
        careerArr[i] = i18n.t("jobInfo.career.newComer");
        break;
      case "experienced":
        careerArr[i] = i18n.t("jobInfo.career.experienced");
        break;
      default:
    }
  }

  return careerArr.join(join ? join : " / ");
};

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const renderDate = (dt, cut) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (Number(month) < 9) {
    month = "0" + month;
  }
  if (Number(day) < 9) {
    day = "0" + day;
  }
  if (!cut) {
    return year + "-" + month + "-" + day;
  } else {
    return month + "-" + day;
  }
};
//페이지 무한스크롤 함수
export const handleWindowInfiniteScroll = async (
  isDataNullCheck, //데이터가 더이상 있는지 확인 boolean
  isFirstRender, //처음 렌더시 실행 방지 boolean
  func, //스크롤이 바닥에 있을시 실행될 함수
  isLoading //로딩중에 중복 실행 방지 boolean
) => {
  let scrollHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  );
  let scrollTop = Math.max(
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
  let clientHeight = document.documentElement.clientHeight;

  if (
    scrollTop + clientHeight + 1 >= scrollHeight &&
    isFirstRender &&
    !isDataNullCheck &&
    !isLoading
  ) {
    await func();
  }
};

//다이얼로그 무한스크롤 함수
export const handlePopupInfiniteContent = (
  scrollBox, //스크롤이 실행될 박스
  container,
  dataNullCheck, //데이터 수가 LIMIT값을 넘지 않을경우 실행 방지(Bool)
  isLoading, //데이터 받아오는중 실행방지 (Bool)
  func //실행할 함수
) => {
  let scrollHeight = Math.max(container.scrollHeight, scrollBox.scrollHeight);
  let scrollTop = Math.max(container.scrollTop, scrollBox.scrollTop);
  let clientHeight = scrollBox.clientHeight;

  if (
    scrollTop + clientHeight + 1 >= scrollHeight &&
    !dataNullCheck &&
    !isLoading
  ) {
    func();
  }
};
export const convertURLtoFileObj = async (url, type) => {
  //url 에서 파일 객체 변환
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
    });
    console.log(response);
    const data = await response.blob();
    const urlSpilt = url.split("/");
    const urlLength = urlSpilt.length;
    const fileName = urlSpilt[urlLength - 1];
    const fileType = fileName.split(".").reverse()[0];
    const metadata = { type: `${type}/${fileType}` };
    console.log(new File([data], fileName, metadata));
    return new File([data], fileName, metadata);
  } catch (e) {
    console.dir(e);
  }
};
export const timeBefore = (createdAt) => {
  var now = new Date();
  var writeDay = new Date(createdAt);
  // console.log(writeDay.toISOString());
  var minus;
  if (now.getFullYear() > writeDay.getFullYear()) {
    minus = now.getFullYear() - writeDay.getFullYear();
    if (i18n.language === "ko" || i18n.language === "ko-KR") {
      return minus + "년 전";
    } else {
      return minus + "years ago";
    }
  } else if (now.getMonth() > writeDay.getMonth()) {
    minus = now.getMonth() - writeDay.getMonth();
    if (i18n.language === "ko" || i18n.language === "ko-KR") {
      return minus + "개월 전";
    } else {
      return minus + "month ago";
    }
  } else if (now.getDate() > writeDay.getDate()) {
    minus = now.getDate() - writeDay.getDate();
    if (i18n.language === "ko" || i18n.language === "ko-KR") {
      return minus + "일 전";
    } else {
      return minus + "days ago";
    }
  } else if (now.getDate() === writeDay.getDate()) {
    var nowTime = now.getTime();
    var writeTime = writeDay.getTime();
    if (nowTime > writeTime) {
      let sec = parseInt(nowTime - writeTime) / 1000;
      let day = parseInt(sec / 60 / 60 / 24);
      let hour = parseInt(sec / 60 / 60);
      let min = parseInt(sec / 60);
      sec = sec - day * 60 * 60 * 24;
      sec = sec - hour * 60 * 60;
      sec = parseInt(sec - min * 60);
      if (hour > 0) {
        if (i18n.language === "ko" || i18n.language === "ko-KR") {
          return hour + "시간 전";
        } else {
          return hour + "hours ago";
        }
      } else if (min > 0) {
        if (i18n.language === "ko" || i18n.language === "ko-KR") {
          return min + "분 전";
        } else {
          return min + "minutes ago";
        }
      } else if (sec > 0) {
        if (i18n.language === "ko" || i18n.language === "ko-KR") {
          return sec + "초 전";
        } else {
          return sec + "seconds ago";
        }
      }
    }
  }
};
