import React, { Component } from "react";
import { Fragment } from "react";
import "./Job.scss";
import { compose } from "redux";
import { withSnackbar } from "notistack";
// import rightBtn from "../../image/r-white-circle-arrow.svg";
// import leftBtn from "../../image/l-white-circle-arrow.svg";
import SearchIcon from "../../image/black-search-icon.svg";
import FilterIcon from "../../Mobile/filtersIcon.svg";
import CloseIcon from "../../image/close-icon.svg";
import LocationIcon from "../../image/location-icon.svg";
import JobDialog from "./JobDialog/JobDialog";
import { ClickAwayListener } from "@material-ui/core";
import EmptyStates from "../../Component/EmptyStates/EmptyStates";
import i18n from "../../i18n";
import queryString from "query-string";
import JobContent from "../../Component/Job/Job";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DropdownIcon from "../../image/line-black-down-arrow-icon.svg";
// import CheckBoxDropDown from "../../Component/CheckBoxDropDown/CheckBoxDropDown";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Badge } from "antd";
import SvgColor from "react-svg-color";
import axios from "axios";
import WorkFieldsSlider from "../../Component/WorkFieldsSlider/WorkFieldsSlider";
import {
  GetJobList,
  GetJobDetailInfo,
  getOtherJobs,
  GetResume,
  PostJobApply,
  PostCancelApply,
  SetFavoriteJob,
  JobViewCount,
  SetUnFavoriteJob,
  GetOccupationField,
} from "../../Api/Job/job";
// import OccupationField from "../../Component/OccupationField/OccupationField";
import { SetFollow, SetUnFollow } from "../../Api/Follow/Follow";
// import { GetCategories } from "../../Api/Category/Category";
import FadeLoader from "react-spinners/BeatLoader";
import { handleWindowInfiniteScroll } from "../../Component/functions";
import { css } from "@emotion/core";
import { GetUserToken } from "../../modules/userAuth";
import Media from "react-media";
import { JobDetail_GAEvent, JobMain_GAEvent } from "../../modules/gaEvents";
const override = css`
  display: flex;
  justify-content: center;
`;
class Job extends Component {
  state = {
    searchStr: "",
    teamListDialogOpen: false,
    jobDialogOpen: false,
    locationFieldOpen: false,
    categoriesOpen: false,
    jobList: [],
    jobListTotalCount: 0,
    isLoading: false,
    applyCancel: false,
    timer: "",
    isActionLoad: false,
    mobileFiltersOpen: false,
    conditionFilterList: [
      {
        type: "career",
        title: i18n.t("job.searchFilter.career"),
        list: [
          {
            id: 2,
            text: i18n.t("job.career.new"),
          },
          {
            id: 3,
            text: i18n.t("job.career.career"),
          },
          {
            id: 1,
            text: i18n.t("job.career.intern"),
          },
        ],
      },
      {
        type: "employment",
        title: i18n.t("job.searchFilter.employment"),
        list: [
          {
            id: 1,
            text: i18n.t("job.employment.full"),
          },
          {
            id: 3,
            text: i18n.t("job.employment.part"),
          },
          {
            id: 2,
            text: i18n.t("job.employment.free"),
          },
        ],
      },
      {
        id: 0,
        type: "remote",
        title: i18n.t("job.searchFilter.woking"),
        list: [
          {
            id: 0,
            text: i18n.t("job.woking"),
          },
        ],
      },
    ],
    errMsg: "",
    err: false,
    remoteFilter: false,
    selectedCareer: [],
    selectedWorkinHours: [],
    selectedOrder: 1,
    selectedCategories: [],
    countryInfo: {
      countryCode: "",
      countryName: "",
      region: "",
    },
    isPage: false,
    selectedJob: "",
    nextDataCheck: true,
    prevDataCheck: true,
    firstRender: false,
    isDataNullCheck: false,
    resumeLoaded: false,
    categories: [],
    otherJobsSkip: 0,
    otherJobsLimit: 10,
    supported: false,
    regionData: [{ value: "hello", label: "test" }],
  };
  mainTimer = null;
  viewTimer = null; //viewCount 타이머
  limit = 20;
  items = 0;

  getGeoInfo = async (parseCode) => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState(
          {
            countryInfo: { countryCode: data.country_code },
          },
          () => this.jobListLoad(parseCode)
        );
        if (data.country_code) {
          return data.country_code;
        }
      })

      .catch((error) => {
        console.dir(error);
      });
  };
  handleSearchTag = (tag) => {
    if (tag) {
      JobMain_GAEvent("search");
    }
    this.items = 0;
    this.setState({ jobList: [], searchStr: tag, jobDialogOpen: false }, () => {
      console.log(this.state.searchStr);
      this.jobListLoad();
    });
  };

  componentDidMount = async () => {
    const header = document.getElementById("nav_header-container");
    header.classList.remove("header-pageView");
    const code = window.location.search;
    const parseCode = queryString.parse(code);
    // this.getOccpation();
    this.mainTimer = setInterval(this.setMainTime, 1000);
    await this.getGeoInfo();
    if (parseCode && parseCode.s && parseCode.s.length) {
      this.handleSearchTag(parseCode.s);
    }
    if (code && parseCode.j && parseCode.j.length === 24) {
      header.classList.add("header-pageView");

      this.setState({ isPage: true }, async () => {
        // await this.jobListLoad(parseCode.j);
        await this.getGeoInfo(parseCode.j);
      });
    } else {
      header.classList.remove("header-pageView");
      // await this.jobListLoad();
    }
    await this.getGategories();
    this.setState({ firstRender: true });
    window.addEventListener("scroll", () =>
      handleWindowInfiniteScroll(
        this.state.isDataNullCheck,
        this.state.firstRender,
        this.jobListLoad,
        this.state.isLoading
      )
    );
  };
  getOccpation = async () => {
    try {
      const res = await GetOccupationField();
      console.log(res);
    } catch (e) {
      console.dir(e);
    }
  };
  componentWillUnmount() {
    clearInterval(this.mainTimer);
    window.removeEventListener("scroll", handleWindowInfiniteScroll);
  }
  getGategories = async () => {
    try {
      const categories = await GetOccupationField();

      this.setState({ categories });
    } catch (e) {
      console.dir(e);
    }
  };
  jobListLoad = async (initial) => {
    let jobList = this.state.jobList;
    this.setState({ isLoading: true });

    try {
      const response = await GetJobList(
        this.state.searchStr,
        this.state.selectedWorkinHours,
        this.state.selectedCareer,
        this.state.selectedCategories,
        this.state.countryInfo,
        this.state.selectedOrder,
        this.state.remoteFilter,
        this.items,
        this.limit
      );
      console.log(response);
      if (!response || !response.list[0] || response.list.length < this.limit) {
        this.setState({ isDataNullCheck: true });
      }
      if (response) {
        this.items += response.list.length;

        if (initial) {
          const info = response.list.find((item) => item._id === initial);
          // const header = document.getElementById("nav_header-container");
          // header.style.zIndex = "2000";
          if (info) {
            this.handleJobDetailDialogOpen(info._id, info.teamInfo._id);
          }
        }

        jobList = jobList.concat(response?.list);

        this.setState({
          jobList,
          jobListTotalCount: response.count,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false, isDataNullCheck: true });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  };

  handleJobDetailDialogOpen = async (id, teamId) => {
    try {
      this.setState(
        {
          jobInfo: null,
          otherJobs: -1,
          resume: null,
          otherJobsSkip: 0,
          jobDialogOpen: true,
        },
        () => {
          this.props.history.push(`/job?j=${id}`);
          this.handleGetJobInfo(id);
          this.handleGetOtherJobs(id, teamId, true);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  handleJobDetailResumeDialogOpen = (id, teamId) => {
    this.setState(
      {
        jobInfo: null,
        otherJobs: -1,
        resume: -1,
        otherJobsSkip: 0,
        jobDialogOpen: true,
        resumeLoaded: true,
      },
      () => {
        clearInterval(this.viewTimer);
        this.handleGetJobInfo(id);
        this.handleGetOtherJobs(id, teamId, true);
        this.handleGetPortfolio(id);
      }
    );
  };
  Date = "";

  handleGetJobInfo = async (id) => {
    const jobInfo = await GetJobDetailInfo(id);
    this.Date = new Date().getSeconds();
    this.viewTimer = setInterval(() => this.setTime(id), 1000);
    this.setState({ jobInfo });
  };
  setMainTime = () => {
    let sttDate = new Date().getSeconds();
    const endDate = this.Date + 5;
    if (sttDate === endDate) {
      JobMain_GAEvent("stay");
    }
  };
  setTime = (id) => {
    let sttDate = new Date().getSeconds();
    const endDate = this.Date + 3;
    if (sttDate === endDate) {
      this.viewCount(id);
    }
  };
  viewCount = async (id) => {
    try {
      await JobViewCount(id);

      clearInterval(this.viewTimer);
    } catch (e) {
      clearInterval(this.viewTimer);
    }
  };

  handleOrderChange = (order) => {
    this.items = 0;
    this.setState({ selectedOrder: order, jobList: [] }, async () => {
      await this.jobListLoad();
    });
  };

  handleGetOtherJobs = async (id, teamId, initial) => {
    const otherJobs = await getOtherJobs(
      id,
      teamId,
      this.state.otherJobsSkip,
      this.state.otherJobsLimit
    );

    this.setState(
      {
        otherJobs: otherJobs ? otherJobs : { list: [], count: 0 },
      },
      () => {
        if (initial) {
          this.setState({ otherJobsSkip: this.state.otherJobsSkip + 10 });
        }
      }
    );
  };

  HeaderOnClose = () => {
    this.handleJobDialogClose();
  };

  handleGetPortfolio = async (id, teamId) => {
    const token = GetUserToken();
    if (token) {
      this.setState({ resume: -1 });
      try {
        const resume = await GetResume(id, teamId);
        this.setState({ resume });
      } catch (e) {
        let errMsg = "";
        if (
          e.response.data.description ===
          "해당 공고의 팀 소유자는 지원할 수 없습니다."
        ) {
          errMsg = "해당 공고의 소유자는 지원할 수 없습니다.";
        }
        this.setState({
          resume: null,
          err: true,
          errMsg,
        });
      }
    } else {
      this.props.history.push("/login");
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleFollow = async () => {
    const token = GetUserToken();
    if (token) {
      const jobInfo = this.state.jobInfo;
      jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
      this.setState({ jobInfo });
      if (jobInfo.teamInfo.isFollowing) {
        try {
          await SetFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      } else {
        try {
          await SetUnFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      }
    } else {
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleApply = (id, teamId) => {
    const token = GetUserToken();

    if (token) {
      if (id) {
        this.handleOtherApply(id, teamId);
      } else {
        this.handleJobApply(this.state.jobInfo._id);
      }
    } else {
      this.props.history.push("/login");
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleOtherApply = async (id, teamId) => {
    const job = this.state.otherJobs.list.find((item) => item._id === id);
    if (job.isApplied) {
      if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
        const otherJobs = this.state.otherJobs;
        otherJobs.list = otherJobs.list.map((item) => {
          if (item._id === id) {
            item.isApplied = !item.isApplied;
          }
          return item;
        });
        this.setState({ otherJobs });
        try {
          await PostCancelApply(id);
        } catch (e) {
          console.dir(e);
          otherJobs.list = otherJobs.list.map((item) => {
            if (item._id === id) {
              item.isApplied = !item.isApplied;
            }
            return item;
          });
        }
        this.setState({ otherJobs });
      }
    } else {
      this.handleJobDetailResumeDialogOpen(
        id,
        teamId ? teamId : this.state.jobInfo.teamInfo._id
      );
    }
  };

  handleJobApply = async (id) => {
    const jobInfo = this.state.jobInfo;

    if (!jobInfo.isApplied) {
      jobInfo.isApplied = !jobInfo.isApplied;

      try {
        await PostJobApply(id);
        JobDetail_GAEvent("apply");
        this.setState({ supported: true, resume: false });
      } catch (e) {
        console.dir(e);
        jobInfo.isApplied = !jobInfo.isApplied;

        this.setState({
          jobInfo,
          supported: false,
          resume: false,
          // err: true,
          // errMsg: e.response.data.description,
          // jobDialogOpen: false,
        });
      }
    } else {
      // if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
      //   jobInfo.isApplied = !jobInfo.isApplied;
      // }
      this.setState({
        jobInfo,
        supported: false,
        resume: null,
        applyCancel: true,
      });
    }
  };
  handleCancelApply = async (id) => {
    const jobInfo = this.state.jobInfo;
    try {
      jobInfo.isApplied = !jobInfo.isApplied;
      await PostCancelApply(id);
      this.setState({ jobInfo, applyCancel: false });
    } catch (e) {
      console.dir(e);
      jobInfo.isApplied = !jobInfo.isApplied;
      this.setState({ jobInfo });
    }
  };
  handleBookMark = (id) => {
    const token = GetUserToken();
    if (token) {
      try {
        if (id) {
          this.handleOtherBookMark(id);
        } else {
          this.handleJobBookMark(this.state.jobInfo._id);
        }
      } catch (e) {
        // if (e && e.response && e.response.data) {
        //   alert("로그인 해주세요.");
        // }
      }
    } else {
      this.props.history.push("/login");
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleJobBookMark = async (id) => {
    const jobInfo = this.state.jobInfo;

    const token = GetUserToken();
    if (token) {
      jobInfo.isFavorite = !jobInfo.isFavorite;
      this.setState({ jobInfo });
      try {
        if (!jobInfo.isFavorite) {
          await SetUnFavoriteJob(id ? id : this.state.jobInfo._id);
        } else {
          await SetFavoriteJob(id ? id : this.state.jobInfo._id);
        }
      } catch (e) {
        jobInfo.isFavorite = !jobInfo.isFavorite;
        this.setState({ jobInfo });
      }
    } else {
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleJobListBookmark = async (id, index) => {
    const btn = document.getElementById(`bookmark_btn-${index}`);
    const jobList = this.state.jobList;

    this.setState({ isActionLoad: true });

    if (btn) {
      // btn.disabled = true;
      jobList[index].isFavorite = !jobList[index].isFavorite;
      this.setState({ jobList });
      try {
        if (!jobList[index].isFavorite) {
          await SetUnFavoriteJob(id);
        } else {
          await SetFavoriteJob(id);
          JobDetail_GAEvent("bookmark");
        }
        this.setState({ isActionLoad: false });
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          this.handleAlert(i18n.t("global.alert.login"), {
            variant: "error",
            key: i18n.t("global.alert.login"),
            autoHideDuration: 5000,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
        jobList[index].isFavorite = !jobList[index].isFavorite;

        this.setState({ jobList, isActionLoad: false });
        console.dir(e);
      }
    } else {
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleOtherBookMark = async (id) => {
    const otherJobs = this.state.otherJobs;
    otherJobs.list = otherJobs.list.map((item) => {
      if (item._id === id) {
        item.isFavorite = !item.isFavorite;
      }
      return item;
    });
    this.setState({
      otherJobs,
    });
    try {
      if (!otherJobs.list.find((item) => item._id === id).isFavorite) {
        await SetUnFavoriteJob(id);
      } else {
        await SetFavoriteJob(id);
      }
    } catch (e) {
      if (e && e.response && e.response.data) {
        this.handleAlert(i18n.t("global.alert.login"), {
          variant: "error",
          key: i18n.t("global.alert.login"),
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
      otherJobs.list = otherJobs.list.map((item) => {
        if (item._id === id) {
          item.isFavorite = !item.isFavorite;
        }
        return item;
      });
      this.setState({
        otherJobs,
      });
      console.dir(e);
    }
  };

  handleRemovePortfolio = async () => {
    this.setState({ resume: null });
  };
  handleWorkFieldsFilterClear = () => {
    this.items = 0;
    this.setState({ selectedCategories: "", jobList: [] }, () =>
      this.jobListLoad()
    );
  };
  handleFilterClear = () => {
    if (
      this.state.searchStr ||
      this.state.countryInfo.countryCode ||
      this.state.selectedOrder !== 1 ||
      this.state.selectedCareer[0] ||
      this.state.selectedWorkinHours[0] ||
      this.state.selectedCategories[0]
    ) {
      this.items = 0;
      this.setState(
        {
          searchStr: "",
          countryInfo: {
            countryCode: "",
            // countryName: "",
            region: "",
          },
          selectedOrder: 1,
          selectedCareer: [],
          selectedWorkinHours: [],
          selectedCategories: [],
        },
        () => this.jobListLoad()
      );
    }
  };
  handleJobSearch = (e) => {
    this.items = 0;

    if (e.code === "Enter") {
      this.setState({ jobList: [] }, () => {
        this.jobListLoad();
      });
    }
  };
  handleChangeCountry = (e) => {
    // console.log(e);
    // this.items = 0;
    // this.setState({
    //   countryInfo: {
    //     countryCode: e.value,
    //     countryName: e.label,
    //     region: null,
    //   },
    // });
    this.items = 0;
    this.setState({
      countryInfo: {
        countryCode: e,
        // countryName: e,
        region: null,
      },
    });
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleTeamListDialogOpen = () => {
    const token = GetUserToken();
    if (token) {
      this.setState({ teamListDialogOpen: true });
    } else {
      // alert("로그인 후 이용 가능합니다.");
      this.handleAlert(i18n.t("global.alert.login"));
    }
  };

  handleLocationFieldOpen = (e) => {
    e.stopPropagation();
    this.setState({
      categoriesOpen: false,
      conditionsOpen: false,
      locationFieldOpen: !this.state.locationFieldOpen,
    });
  };
  handleRegionChange = (val) => {
    let countryInfo = this.state.countryInfo;

    countryInfo.region = val;
    if (val !== "") {
      countryInfo.region = val;
      this.setState({ countryInfo });
    } else {
      countryInfo.region = "";
      this.setState({ countryInfo });
    }
  };
  reRender = true;

  handleSearchLoaction = () => {
    console.log("검색");
    this.items = 0;
    // if (this.state.countryInfo.countryCode) {
    this.setState({ locationFieldOpen: false, jobList: [] }, () =>
      this.jobListLoad()
    );
    // }
  };
  isCareer = (career) => {
    if (career === "experienced") {
      if (i18n.language === "ko") {
        return "경력";
      } else {
        return "Experienced";
      }
    } else if (career === "newComer") {
      if (i18n.language === "ko") {
        return "신입";
      } else {
        return "NewComer";
      }
    } else if (career === "intern") {
      if (i18n.language === "ko") {
        return "인턴";
      } else {
        return "intern";
      }
    }
  };
  handleLocationFieldClose = () => {
    this.setState({ locationFieldOpen: false });
  };
  handleSelecCoutry = (e) => {
    this.setState({ selecCoutry: e.country });
  };

  handleJobDialogClose = () => {
    clearInterval(this.viewTimer);
    this.setState({
      jobDialogOpen: false,
      supported: false,
      isPage: false,
      err: false,
    });
    this.props.history.push(`/job`);
    const header = document.getElementById("nav_header-container");
    header.classList.remove("header-pageView");
  };
  toTeamPage = (url) => {
    this.props.history.push(`/team/@${url}`);
  };
  toNextBtn = (id) => {
    const jobListIndex = this.state.jobList.findIndex(
      (item) => item._id === id
    );
    let jobLastIndex = jobListIndex + 2;
    if (jobLastIndex === this.state.jobList.length) {
      this.setState({ nextDataCheck: false });
    }
    this.setState({ jobDialogOpen: false, prevDataCheck: true }, () => {
      this.handleJobDetailDialogOpen(
        this.state.jobList[jobListIndex + 1]._id,
        this.state.jobList[jobListIndex + 1].teamInfo._id
      );
    });
  };
  handleFilterChange = (e, type, id) => {
    console.log(type, id);
    this.items = 0;
    if (type === "career") {
      let selectedCareer = this.state.selectedCareer;
      if (e.target.checked && !selectedCareer.find((val) => val === id)) {
        selectedCareer.push(id);
        this.setState(
          { selectedCareer, jobList: [] },
          async () => await this.jobListLoad()
        );
      } else {
        selectedCareer = selectedCareer.filter((val) => val !== id);
        this.setState(
          { selectedCareer, jobList: [] },
          async () => await this.jobListLoad()
        );
      }
    } else if (type === "employment") {
      let selectedWorkinHours = this.state.selectedWorkinHours;
      if (e.target.checked && !selectedWorkinHours.find((val) => val === id)) {
        selectedWorkinHours.push(id);
        this.setState(
          { selectedWorkinHours, jobList: [] },
          async () => await this.jobListLoad()
        );
      } else {
        selectedWorkinHours = selectedWorkinHours.filter((val) => val !== id);
        this.setState(
          { selectedWorkinHours, jobList: [] },
          async () => await this.jobListLoad()
        );
      }
    } else {
      if (e.target.checked) {
        this.setState(
          { remoteFilter: true, jobList: [] },
          async () => await this.jobListLoad()
        );
      } else {
        this.setState(
          { remoteFilter: false, jobList: [] },
          async () => await this.jobListLoad()
        );
      }
    }
  };
  toPrevBtn = (id) => {
    const jobListIndex = this.state.jobList.findIndex(
      (item) => item._id === id
    );
    let jobStartIndex = jobListIndex - 1;
    if (jobStartIndex === 0) {
      this.setState({ prevDataCheck: false });
    }
    this.setState({ jobDialogOpen: false, nextDataCheck: true }, () => {
      this.handleJobDetailDialogOpen(
        this.state.jobList[jobListIndex - 1]._id,
        this.state.jobList[jobListIndex + 1].teamInfo._id
      );
    });
  };
  handleSelectCategories = (id) => {
    this.items = 0;

    this.setState({ selectedCategories: [id], jobList: [] }, async () => {
      await this.jobListLoad();
      console.log(this.state.selectedCategories, "분야 보기");
      console.log(this.state.jobList);
    });
    // if (
    //   e.target.checked &&
    //   !this.state.selectedCategories.find((val) => val === categories._id)
    // ) {
    //   selectedCategories.push(categories._id);
    //   this.setState({ selectedCategories, jobList: [] }, async () => {
    //     await this.jobListLoad();
    //   });
    // } else {
    //   selectedCategories = selectedCategories.filter(
    //     (val) => val !== categories._id
    //   );
    //   // console.log(selectedCategories);
    //   this.setState({ selectedCategories, jobList: [] }, async () => {
    //     await this.jobListLoad();
    //   });
    // }
  };
  // handleSelectCategories = (e, categories) => {
  //   let selectedCategories = this.state.selectedCategories;

  //   this.items = 0;
  //   if (
  //     e.target.checked &&
  //     !this.state.selectedCategories.find((val) => val === categories._id)
  //   ) {
  //     selectedCategories.push(categories._id);
  //     this.setState({ selectedCategories, jobList: [] }, async () => {
  //       await this.jobListLoad();
  //       console.log(this.state.selectedCategories, "분야 보기");
  //     });
  //   } else {
  //     selectedCategories = selectedCategories.filter(
  //       (val) => val !== categories._id
  //     );
  //     // console.log(selectedCategories);
  //     this.setState({ selectedCategories, jobList: [] }, async () => {
  //       await this.jobListLoad();
  //       console.log(this.state.selectedCategories, "분야 보기");
  //     });
  //   }
  // };
  handleClearSearchStr = () => {
    this.items = 0;
    this.setState({ searchStr: "" });
  };
  renderPeriod = (strDate, endDate) => {
    if (strDate && endDate) {
      return strDate.substring(0, 10) + "~" + endDate.substring(0, 10);
    }
  };

  selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        webkitAppearance: "none",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#efefef"
          : isFocused
          ? "#efefef"
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "#070707" : "black",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#efefef" : "#efefef"),
        },
      };
    },
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      border: "1px solid #efefef;",
      width: "100%",
      height: "35px",

      "&:hover": {
        display: "flex",
        border: "1px solid #efefef;",
        width: "100%",
        height: "35px",
      },
    }),
    input: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      alignItems: "center",
      visibility: "visible",
      color: "hsl(0, 0%, 20%)",
      boxSizing: "border-box",
    }),
    valueContainer: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: "100%",
      height: "35px",
      padding: "0 7px",
    }),

    singleValue: () => ({
      maxWidth: "calc(100% - 47px)",
      color: "#070707",
      marginLeft: "2px",
      marginRight: "2px",
      overflow: "hidden",
      position: "absolute",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      top: "50%",
      // -webkit-transform: "translateY(-50%)",
      // -moz-transform: "translateY(-50%)",
      // -ms-transform: "translateY(-50%)",
      transform: "translateY(-50%)",
      boxSizing: "border-box",
    }),

    // DropdownIndicator: () => ({ width: "5px" }),
  };

  renderJobContent = (item, idx) => {
    return (
      <JobContent
        history={this.props.history}
        loading={this.state.isActionLoad}
        handleJobListBookmark={this.handleJobListBookmark}
        handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
        jobInfo={item}
        index={idx}
      />
    );
  };

  handleLocationReset = () => {
    console.log("alsdkfj;asdlkfj;asdlf");
    this.setState({ countryInfo: "" }, () => this.handleSearchLoaction());
  };
  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 4,
    //   slidesToScroll: 1,
    //   nextArrow: <CustomNextArrow />,
    //   prevArrow: <CustomPrevArrow />,
    // };
    return (
      <>
        <Media
          key="job-main"
          queries={{
            mobile: "(max-width: 1149px)",
            desktop: "(min-width: 1150px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {this.state.jobDialogOpen ? (
                <JobDialog
                  applyCancel={this.state.applyCancel}
                  handleCancelApply={this.handleCancelApply}
                  applyCancelBack={() =>
                    this.setState({
                      resume: null,
                      supported: false,
                      applyCancel: false,
                    })
                  }
                  handleSearchTag={this.handleSearchTag}
                  err={this.state.err}
                  errMsg={this.state.errMsg}
                  isPage={this.state.isPage}
                  supported={this.state.supported}
                  toNextBtn={this.toNextBtn}
                  toPrevBtn={this.toPrevBtn}
                  nextDataCheck={this.state.nextDataCheck}
                  prevDataCheck={this.state.prevDataCheck}
                  open={this.state.jobDialogOpen}
                  onClose={this.state.isPage ? null : this.handleJobDialogClose}
                  HeaderOnClose={this.HeaderOnClose}
                  jobInfo={this.state.jobInfo}
                  otherJobs={this.state.otherJobs}
                  resume={this.state.resume}
                  getResume={this.handleGetPortfolio}
                  handleRemovePortfolio={this.handleRemovePortfolio}
                  history={this.props.history}
                  handleFollow={this.handleFollow}
                  handleApply={this.handleApply}
                  handleBookMark={this.handleBookMark}
                  handleJobDetailResumeDialogOpen={
                    this.handleJobDetailResumeDialogOpen
                  }
                  errBackClick={() => this.setState({ err: false, errMsg: "" })}
                  supportedDone={() =>
                    this.setState({
                      supported: !this.state.supported,
                      resume: null,
                    })
                  }
                  handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
                  skip={this.state.otherJobsSkip}
                  limit={this.state.otherJobsLimit}
                />
              ) : null}

              <div className="main-container">
                <div className="job-container">
                  <div className="jobInfo-container">
                    <WorkFieldsSlider
                      // history={this.props.history}
                      // searchCategory={this.handleSearch}
                      resize={this.state.selecSort}
                      selectedCategories={this.state.selectedCategories}
                      // selectedCount={this.state.selectedCategories.length}
                      handleChange={this.handleSelectCategories}
                      FilterClear={this.handleWorkFieldsFilterClear}
                    />
                    <div className="jobInfo-searchBar">
                      <div className="searchBar-field">
                        <input
                          value={this.state.searchStr}
                          onChange={(e) =>
                            this.setState({ searchStr: e.target.value })
                          }
                          onKeyPress={this.handleJobSearch}
                          type="text"
                          placeholder={i18n.t("global.search")}
                        ></input>
                        <img id="search" src={SearchIcon} alt="search" />
                        <img
                          id="close"
                          src={CloseIcon}
                          alt="close"
                          onClick={this.handleClearSearchStr}
                        />
                      </div>
                      {matches.desktop && (
                        <div className="filters">
                          <ClickAwayListener
                            onClickAway={() =>
                              this.setState({ conditionsOpen: false })
                            }
                          >
                            <div
                              className={`conditons_wrapper ${
                                this.state.conditionsOpen && "job_filterOpen"
                              }`}
                            >
                              <div
                                className="conditions-title"
                                onClick={() =>
                                  this.setState({
                                    conditionsOpen: !this.state.conditionsOpen,
                                  })
                                }
                              >
                                <span>
                                  {i18n.language === "en" ||
                                  i18n.language === "En"
                                    ? i18n.t("global.filter")
                                    : i18n.t("job.searchFilter.field")}
                                  {this.state.selectedCareer.length ||
                                  this.state.selectedWorkinHours.length ||
                                  this.state.remoteFilter ? (
                                    <span>
                                      +
                                      {this.state.selectedCareer.length +
                                        this.state.selectedWorkinHours.length +
                                        (this.state.remoteFilter ? 1 : 0)}
                                    </span>
                                  ) : null}
                                </span>
                                <img
                                  src={DropdownIcon}
                                  style={
                                    this.state.conditionsOpen
                                      ? { transform: "rotate(180deg)" }
                                      : null
                                  }
                                  alt="open"
                                />
                              </div>
                              {matches.desktop && (
                                <div
                                  className={`conditions_items ${
                                    !this.state.conditionsOpen ? "close" : ""
                                  }`}
                                >
                                  {this.state.conditionFilterList.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          className="items-section"
                                          key={`${item.title}_${index}`}
                                        >
                                          <span>{item.title}</span>
                                          <div className="items-list">
                                            {item.list.map((value, ind) => {
                                              return (
                                                <div
                                                  className="checkBox"
                                                  key={`${value.text}_${ind}`}
                                                >
                                                  <input
                                                    onChange={(e) =>
                                                      this.handleFilterChange(
                                                        e,
                                                        item.type,
                                                        value.id
                                                      )
                                                    }
                                                    type="checkBox"
                                                    id={`${value.text}_${ind}`}
                                                    checked={
                                                      item.type === "career" &&
                                                      this.state.selectedCareer.find(
                                                        (val) =>
                                                          val === value.id
                                                      )
                                                        ? true
                                                        : item.type ===
                                                            "employment" &&
                                                          this.state.selectedWorkinHours.find(
                                                            (val) =>
                                                              val === value.id
                                                          )
                                                        ? true
                                                        : item.type ===
                                                            "remote" &&
                                                          this.state
                                                            .remoteFilter
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={`${value.text}_${ind}`}
                                                  >
                                                    <span></span>
                                                    {value.text}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </ClickAwayListener>

                          <ClickAwayListener
                            onClickAway={this.handleLocationFieldClose}
                          >
                            <div
                              className={`locations_wrapper ${
                                this.state.locationFieldOpen && "job_filterOpen"
                              }`}
                              htmlFor="locations"
                            >
                              <>
                                <div
                                  className="location_filter-title"
                                  onClick={this.handleLocationFieldOpen}
                                >
                                  <Badge
                                    dot={
                                      this.state.countryInfo.countryCode
                                        ? true
                                        : false
                                    }
                                  >
                                    {/* <span> */}
                                    {i18n.t("job.searchFilter.location")}
                                    {/* </span> */}
                                  </Badge>
                                  <img
                                    src={DropdownIcon}
                                    style={
                                      this.state.locationFieldOpen
                                        ? { transform: "rotate(180deg)" }
                                        : null
                                    }
                                    alt="filterOpen"
                                  />
                                </div>
                                {this.state.locationFieldOpen ? (
                                  <div className="locationFilter">
                                    <div className="location-coutry row">
                                      <span>
                                        {i18n.t(
                                          "job.searchFilter.location.country"
                                        )}
                                      </span>

                                      <CountryDropdown
                                        value={
                                          this.state.countryInfo.countryCode
                                        }
                                        id="my-country-field-id"
                                        name="my-country-field"
                                        className="fllter-region-input"
                                        onChange={this.handleChangeCountry}
                                        labelType="full"
                                        valueType="short"
                                        defaultOptionLabel={i18n.t(
                                          "job.upload_1.country.blank"
                                        )}
                                      />
                                    </div>
                                    <div className="location-city row">
                                      <span>
                                        {i18n.t(
                                          "job.searchFilter.location.city"
                                        )}
                                      </span>
                                      {/* {this.state.countryInfo.countryCode ? ( */}
                                      <RegionDropdown
                                        style={{
                                          padding: "0 15px",
                                          borderRadius: "unset",
                                        }}
                                        countryValueType="short"
                                        country={
                                          this.state.countryInfo.countryCode
                                        }
                                        value={this.state.countryInfo.region}
                                        className="fllter-region-input"
                                        defaultOptionLabel={i18n.t(
                                          "job.upload_1.region"
                                        )}
                                        blankOptionLabel={i18n.t(
                                          "job.upload_1.region.blank"
                                        )}
                                        onChange={(val) =>
                                          this.handleRegionChange(val)
                                        }
                                        disabled={
                                          this.state.countryInfo.countryCode
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>
                                    <div className="location-btns">
                                      <button
                                        type="button"
                                        onClick={this.handleLocationReset}
                                      >
                                        {i18n.t("global.reset")}
                                      </button>
                                      <button
                                        type="button"
                                        onClick={this.handleSearchLoaction}
                                      >
                                        {i18n.t(
                                          "admin.project.adjust.display.apply"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            </div>
                          </ClickAwayListener>
                        </div>
                      )}

                      {matches.mobile && ( // 모바일 Botton Sheet 형태의 필터 모달 (채용 분야 , 채용 조건)
                        <Fragment>
                          <div className="filtersWrapper">
                            <div className="filtersWrap nowrap">
                              <div
                                className={`filtersBox ${
                                  this.state.selectedCategories.length ||
                                  this.state.selectedCareer.length ||
                                  this.state.selectedWorkinHours.length ||
                                  this.state.remoteFilter
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.setState({
                                    mobileFiltersOpen:
                                      !this.state.mobileFiltersOpen,
                                  })
                                }
                              >
                                <SvgColor
                                  svg={FilterIcon}
                                  width={10}
                                  // colors={["#fff", "#fff"]}
                                />
                                <Badge
                                  dot={
                                    this.state.selectedCategories.length ||
                                    this.state.selectedCareer.length ||
                                    this.state.selectedWorkinHours.length ||
                                    this.state.remoteFilter
                                      ? true
                                      : false
                                  }
                                >
                                  <span>{i18n.t("global.filterAndSort")}</span>
                                </Badge>
                              </div>
                              <div
                                className={`filtersBox ${
                                  // this.state.selectedCategories.length ||
                                  this.state.selectedCareer.length ||
                                  this.state.selectedWorkinHours.length ||
                                  this.state.remoteFilter
                                    ? "active"
                                    : ""
                                }`}
                                onClick={this.handleLocationFieldOpen}
                                style={{ marginLeft: "5px" }}
                              >
                                <SvgColor
                                  svg={LocationIcon}
                                  width={10}
                                  // colors={["#fff", "#fff"]}
                                />
                                <Badge
                                  dot={
                                    this.state.countryInfo.countryCode
                                      ? true
                                      : false
                                  }
                                >
                                  <span>
                                    {i18n.t("job.searchFilter.location")}
                                  </span>
                                </Badge>
                              </div>
                            </div>
                            <div className="jobInfo-headerTitle nowrap">
                              <span onClick={this.handleFilterClear}>
                                {i18n.t("job.content.filterReset")}
                              </span>
                            </div>
                          </div>
                          <BottomSheet // 필터 및 정렬
                            open={this.state.mobileFiltersOpen}
                            onDismiss={() =>
                              this.setState({ mobileFiltersOpen: false })
                            }
                            // blocking={false}
                            // header={<span>필터</span>}
                            snapPoints={({ maxHeight }) => [
                              maxHeight / 2,
                              // maxHeight * 0.6,
                            ]}
                          >
                            <div
                              className={`conditions_items_mobile ${
                                !this.state.conditionsOpen ? "close" : ""
                              }`}
                            >
                              <div
                                className="items-section"
                                // key={`${item.title}_${index}`}
                              >
                                <span>{i18n.t("discover.filter.sort")}</span>
                                <div className="items-list">
                                  <input
                                    type="radio"
                                    id="jobList-sort-1"
                                    name="jobList-sort-1"
                                    // value="asdf"
                                    checked={
                                      this.state.selectedOrder === 1
                                        ? true
                                        : false
                                    }
                                    onChange={() => this.handleOrderChange(1)}
                                  ></input>
                                  <label
                                    htmlFor="jobList-sort-1"
                                    className="radioBox"
                                  >
                                    {i18n.t("job.search.sort_1")}
                                  </label>
                                  <input
                                    type="radio"
                                    id="jobList-sort-2"
                                    name="jobList-sort-2"
                                    // value="asdf"
                                    checked={
                                      this.state.selectedOrder === 2
                                        ? true
                                        : false
                                    }
                                    onChange={() => this.handleOrderChange(2)}
                                  ></input>
                                  <label
                                    htmlFor="jobList-sort-2"
                                    className="radioBox"
                                  >
                                    {i18n.t("job.search.sort_2")}
                                  </label>
                                </div>
                              </div>
                              {/* <div
                                className="items-section"
                                // key={`${item.title}_${index}`}
                              >
                                <span>{i18n.t("job.searchFilter.type")}</span>
                                <div className="items-list">
                                  {this.state.categories.map((item, index) => {
                                    return (
                                      <div
                                        className="checkBox"
                                        key={`${item.name.en}_${index}`}
                                      >
                                        <input
                                          checked={
                                            this.state.selectedCategories &&
                                            this.state.selectedCategories
                                              .length !== 0
                                              ? this.state.selectedCategories.find(
                                                  (data) => data === item._id
                                                )
                                              : false
                                            // : false
                                          }
                                          onChange={(e) =>
                                            this.handleSelectCategories(e, item)
                                          }
                                          type="checkBox"
                                          id={`${item.name.en}_${index}`}
                                        />
                                        <label
                                          htmlFor={`${item.name.en}_${index}`}
                                        >
                                          {item.name.en || item.name.ko
                                            ? i18n.language === "en"
                                              ? item.name?.en
                                              : item.name?.ko
                                            : item.name}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div> */}

                              {this.state.conditionFilterList.map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="items-section"
                                      key={`${item.title}_${index}`}
                                    >
                                      <span>{item.title}</span>
                                      <div className="items-list">
                                        {item.list.map((value, ind) => {
                                          return (
                                            <div
                                              className="checkBox"
                                              key={`${value.text}_${ind}`}
                                            >
                                              <input
                                                onChange={(e) =>
                                                  this.handleFilterChange(
                                                    e,
                                                    item.type,
                                                    value.id
                                                  )
                                                }
                                                type="checkBox"
                                                id={`${value.text}_${ind}`}
                                                checked={
                                                  item.type === "career" &&
                                                  this.state.selectedCareer.find(
                                                    (val) => val === value.id
                                                  )
                                                    ? true
                                                    : item.type ===
                                                        "employment" &&
                                                      this.state.selectedWorkinHours.find(
                                                        (val) =>
                                                          val === value.id
                                                      )
                                                    ? true
                                                    : item.type === "remote" &&
                                                      this.state.remoteFilter
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                htmlFor={`${value.text}_${ind}`}
                                              >
                                                {/* <span></span> */}
                                                {value.text}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </BottomSheet>
                          <BottomSheet // 지역
                            open={this.state.locationFieldOpen}
                            onDismiss={() =>
                              this.setState({ locationFieldOpen: false })
                            }
                            // blocking={false}
                            // header={<span>필터</span>}
                            // snapPoints={({ maxHeight }) => [
                            //   maxHeight / 2,
                            //   // maxHeight * 0.6,
                            // ]}
                          >
                            <div
                              className={`locations_wrapper ${
                                this.state.locationFieldOpen && "job_filterOpen"
                              }`}
                              htmlFor="locations"
                            >
                              <>
                                {this.state.locationFieldOpen ? (
                                  <div className="locationFilter">
                                    <div className="location-coutry row">
                                      <span>
                                        {i18n.t(
                                          "job.searchFilter.location.country"
                                        )}
                                      </span>
                                      <CountryDropdown
                                        value={
                                          this.state.countryInfo.countryCode
                                        }
                                        id="my-country-field-id"
                                        name="my-country-field"
                                        className="fllter-region-input"
                                        onChange={this.handleChangeCountry}
                                        labelType="full"
                                        valueType="short"
                                        defaultOptionLabel={i18n.t(
                                          "job.upload_1.country.blank"
                                        )}
                                      />
                                    </div>
                                    <div className="location-city row">
                                      <span>
                                        {i18n.t(
                                          "job.searchFilter.location.city"
                                        )}
                                      </span>
                                      {/* {this.state.countryInfo.countryCode ? ( */}
                                      <RegionDropdown
                                        style={{
                                          padding: "0 15px",
                                          borderRadius: "unset",
                                        }}
                                        countryValueType="short"
                                        country={
                                          this.state.countryInfo.countryCode
                                        }
                                        value={this.state.countryInfo.region}
                                        className="fllter-region-input"
                                        defaultOptionLabel={i18n.t(
                                          "job.upload_1.region"
                                        )}
                                        blankOptionLabel={i18n.t(
                                          "job.upload_1.region.blank"
                                        )}
                                        onChange={(val) =>
                                          this.handleRegionChange(val)
                                        }
                                        disabled={
                                          this.state.countryInfo.countryCode
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>
                                    <div className="location-btns">
                                      <button
                                        type="button"
                                        onClick={this.handleLocationReset}
                                      >
                                        {i18n.t("global.reset")}
                                      </button>
                                      <button
                                        type="button"
                                        onClick={this.handleSearchLoaction}
                                      >
                                        {i18n.t(
                                          "admin.project.adjust.display.apply"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            </div>
                          </BottomSheet>
                        </Fragment>
                      )}
                    </div>

                    <div className="jobInfo-contentBox">
                      {matches.desktop && (
                        <div className="jobInfo-header">
                          <div className="jobInfo-headerTitle">
                            {/* <span>{this.state.jobListTotalCount}</span>
                            <span> {i18n.t("job.content.title")}</span> */}
                            <span onClick={this.handleFilterClear}>
                              {" "}
                              {i18n.t("job.content.filterReset")}
                            </span>
                          </div>
                          {/* <button type="button" onClick={this.handleTeamListDialogOpen}>
                            {i18n.t("job.postingBtn")}
                          </button> */}

                          <div className="jobInfo-list_sort">
                            <span
                              className={
                                this.state.selectedOrder === 1 ? "checked" : ""
                              }
                              onClick={() => this.handleOrderChange(1)}
                            >
                              {i18n.t("job.search.sort_1")}
                            </span>
                            <span
                              className={
                                this.state.selectedOrder === 2 ? "checked" : ""
                              }
                              onClick={() => this.handleOrderChange(2)}
                            >
                              {i18n.t("job.search.sort_2")}
                            </span>
                          </div>
                        </div>
                      )}

                      {this.state.jobList && this.state.jobList.length ? (
                        <div className="jobInfo-list">
                          {this.state.jobList.map((item, idx) => {
                            return this.renderJobContent(item, idx);
                          })}
                        </div>
                      ) : !this.state.isLoading ? (
                        <div className="empty_searchJob">
                          <EmptyStates />
                        </div>
                      ) : null}
                      {this.state.isLoading && (
                        <div className="empty_searchJob">
                          <FadeLoader
                            css={override}
                            loading={this.state.isLoading}
                            size={10}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}

export default compose(withSnackbar)(Job);
