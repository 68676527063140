import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const PatchNofifyReadAll = async () => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/read-all`;
  await axios.patch(url, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const GetNotifyList = async (skip, limit) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/list?skip=0&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const PatchNotifyAccept = async (notifyId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/accept`;
  await axios.patch(
    url,
    { id: notifyId },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const DeleteNotifyAll = async () => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/delete-all`;
  await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const PatchNotifyReject = async (notifyId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/reject`;
  await axios.patch(
    url,
    { id: notifyId },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
