import React, { Component } from "react";
import "./UserInterest.scss";
import "../../index.css";
import "../../image/check-on-box-icon.svg";
import i18n from "../../i18n";
import { GetCategories } from "../../Api/Category/Category";

class UserInterest extends Component {
  state = {
    interests: [],
    userSelect: [],
  };

  componentDidMount = async () => {
    try {
      const response = await GetCategories();
      if (response) {
        const interests = response;
        this.setState({ interests });
      }
    } catch (e) {
      console.dir(e);
    }
  };
  handleUserInterset = async (value) => {
    const btn = document.getElementById("intersetBtn");
    btn.disabled = true;
    btn.style.backgroundColor = "#efefef";
    await this.props.infoSubmit(value);
    btn.disabled = false;
    btn.style.backgroundColor = "#070707";
  };
  handleUserClick = (e, value) => {
    if (
      e.target.checked &&
      !this.state.userSelect.find((item) => item === value)
    ) {
      let userSelect = this.state.userSelect;
      if (userSelect.length < 2) {
        userSelect.push(value);
        this.setState({ userSelect });
      } else {
        userSelect.push(value);
        userSelect.shift();
        this.setState({ userSelect });
      }
    } else {
      let userSelect = this.state.userSelect;
      userSelect = userSelect.filter((item) => item !== value);
      this.setState({ userSelect });
    }
  };

  render() {
    return (
      <div className="interset-container">
        <div className="interset-space">
          <div className="interset-header">
            <span>{i18n.t("userInterest.headerTitle")}</span>
          </div>
          <div className="interset-content" draggable={false}>
            {this.state.interests.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <input
                    name="interset"
                    type="checkbox"
                    id={item.name.en}
                    value={item._id}
                    onChange={(e) => this.handleUserClick(e, item._id)}
                  />
                  <label
                    className={
                      this.state.userSelect.find((data) => data === item._id)
                        ? "clickedItem"
                        : null
                    }
                    draggable={false}
                    htmlFor={item.name.en}
                  >
                    {item.name.en}
                  </label>
                </React.Fragment>
              );
            })}
          </div>
          <button
            id="intersetBtn"
            onClick={() => this.handleUserInterset(this.state.userSelect)}
          >
            {i18n.t("global.next")}
          </button>
        </div>
      </div>
    );
  }
}

export default UserInterest;
