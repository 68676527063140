import React, { Component } from "react";
import { Table, Menu, Dropdown } from "antd";
import "antd/dist/antd.css";
import "./SearchResult.scss";

// import Moment from "react-moment";
import "moment/locale/ko";
import i18n from "../../../../../i18n";
// import OnListIcon from "../../../../../image/AdminIcon/change-red-list-icon.svg";
// import OffListIcon from "../../../../../image/AdminIcon/change-gray-list-icon.svg";
// import OnTileIcon from "../../../../../image/AdminIcon/change-red-icon.svg";
// import OffTileIcon from "../../../../../image/AdminIcon/change-gray-icon.svg";
// import imgPlaceholder from "../../../../../image/Admin/placeholder.gif";
// import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
// import Pagination from "../../../../../Component/Pagination/Pagination";
import { langChange } from "../../../../../Redux/action/index";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { PostDeleteProject } from "../../../../../Api/Admin/Project/project";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import {
  renderCareer,
  renderPayInfo,
} from "../../../../../Component/functions";
import ApplicantsListDialog from "../applicantsDialog/applicantsDialog";
// import { GetPendingUserList } from "../../../../../Api/Team/Team";
import { GetAdminJobApplications } from "../../../../../Api/Admin/Job/Job";
class SearchResult extends Component {
  state = {
    isDialogOpen: false,
    loading: false,
    arrayType: "list",
    isPendingLoading: false,
    selectedTeamId: null,
  };
  limit = 10;
  pendingScrollState = {
    items: 0,
    limit: 20,
  };
  checkSorterType = (sorter) => {
    if (sorter === "strDate") {
      return 1;
    } else if (sorter === "endDate") {
      return 2;
    } else if (sorter === "views") {
      return 3;
    } else if (sorter === "bookmark") {
      return 4;
    } else if (sorter === "applicant") {
      return 5;
    } else {
      return null;
    }
  };
  renderPercent = (aplicants, unCheck) => {
    if (!aplicants && !unCheck) {
      return 0;
    } else {
      let result = ((aplicants - unCheck) / aplicants) * 100;
      return Math.floor(result);
    }
  };

  addPendingUserList = async () => {
    this.setState({ isPendingLoading: true });
    try {
      const response = await GetAdminJobApplications(
        this.state.selectedTeamId,
        this.pendingScrollState.items,
        this.pendingScrollState.limit
      );
      if (response.length > this.LIMIT) {
        this.setState({ dataNullCheck: true });
      }
      this.pendingScrollState.items =
        this.pendingScrollState.items + response.length;
      this.setState({ pendingUserList: response.applicant }, () => {
        this.setState({ isPendingLoading: false });
      });
    } catch (e) {
      this.setState({ isPendingLoading: false });
    }
  };
  isPendinUserList = async (teamId, e) => {
    this.setState({ isPendingLoading: true, selectedTeamId: teamId });
    try {
      const response = await GetAdminJobApplications(teamId);

      if (response) {
        this.setState({ pendingUserList: response.list }, () => {
          this.setState({ isPendingLoading: false, isDialogOpen: true });
        });
      }
    } catch (e) {
      this.setState({ isPendingLoading: false });
      console.dir(e);
    }
  };
  handleTableSortChange = async (a, b, sorter) => {
    let orderType = this.checkSorterType(sorter.columnKey);

    if (sorter.order === "ascend") {
      this.props.handleSorter(orderType, true);
    } else if (sorter.order === "descend") {
      this.props.handleSorter(orderType, false);
    } else {
      this.props.handleSorter(null, null);
    }
  };
  // handleDeleteJob = async (id) => {
  //   try {
  //     this.props.handleDeleteRecruitmentItem()
  //     await DeleteJob(id);
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };
  columns = [
    {
      title: i18n.t("팀명"),
      dataIndex: "teamInfo",
      ellipsis: true,
      width: 130,
      align: "left",
      render: (teamInfo) => {
        return (
          <div className="postTitle" style={{ cursor: "pointer" }}>
            <span>{teamInfo.name}</span>
            <span>@{teamInfo.linkURL}</span>
          </div>
        );
      },
    },
    {
      title: i18n.t("채용 정보"),
      ellipsis: true,
      width: 250,
      align: "center",
      render: (jobInfo) => {
        return (
          <div className="job_info">
            <span>{jobInfo.position}</span>
            <span>
              {renderPayInfo(jobInfo.payInfo)} |{" "}
              {renderCareer(jobInfo.careerStatus)}
            </span>
          </div>
        );
      },
    },
    {
      title: i18n.t("채용 분야"),
      ellipsis: true,
      width: 140,
      dataIndex: "category",
      align: "center",
      render: (category) => {
        return (
          <div className="job_field">
            <span>
              {category[0]
                ? i18n.language === "en"
                  ? category[0].name.en
                  : category[0].name.ko
                : "분야 선택 안함"}
            </span>
          </div>
        );
      },
    },
    {
      title: i18n.t("채용 시작일"),
      width: 140,
      ellipsis: true,
      dataIndex: "periodInfo",
      sorter: true,
      key: "strDatg",
      align: "center",

      render: (periodInfo) => {
        return (
          <span className="job-text">
            {periodInfo.strDate.substring(0, 10)}
          </span>
        );
      },
    },
    {
      title: i18n.t("채용 마감일"),
      width: 140,
      ellipsis: true,
      key: "endDate",
      dataIndex: "periodInfo",
      sorter: true,
      align: "center",
      render: (periodInfo) => {
        return (
          <span className="job-text">
            {periodInfo.endDate.substring(0, 10)}
          </span>
        );
      },
    },
    {
      title: i18n.t("조회수"),
      ellipsis: true,
      dataIndex: "views",
      sorter: true,
      key: "views",
      width: 100,
      align: "center",
      render: (views) => {
        return <span>{views}</span>;
      },
    },
    {
      title: i18n.t("북마크"),
      ellipsis: true,
      dataIndex: "bookmarkCount",
      width: 100,
      sorter: true,
      align: "center",
      key: "bookmark",
      render: (bookmarkCount) => {
        return <span className="job-text">{bookmarkCount}</span>;
      },
    },
    {
      title: i18n.t("지원자"),
      ellipsis: true,
      dataIndex: "applicant",
      width: 100,
      sorter: true,
      align: "center",
      key: "applicant",
      render: (applicant) => {
        return <span className="job-text">{applicant}</span>;
      },
    },
    {
      title: i18n.t("미확인"),
      ellipsis: true,
      dataIndex: "unCheckedApplicant",
      width: 100,

      align: "center",

      render: (unCheckedApplicant) => {
        return <span className="job-text">{unCheckedApplicant}</span>;
      },
    },
    {
      title: i18n.t("확인율"),
      ellipsis: true,
      width: 100,
      align: "center",

      render: (jobInfo) => {
        return (
          <span className="job-text">
            {this.renderPercent(jobInfo.applicant, jobInfo.unCheckedApplicant)}%
          </span>
        );
      },
    },
    {
      // title: '',
      dataIndex: "",
      align: "right",
      fixed: "right",
      render: (info) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={this.handleDetailPostPopUp}>
              <div>{i18n.t("채용 정보 확인")}</div>
            </Menu.Item>
            <Menu.Item onClick={() => this.isPendinUserList(info._id)}>
              <div>{i18n.t("지원자 보기")}</div>
            </Menu.Item>

            <Menu.Item>
              <div onClick={() => this.props.handleDeleteDialogOpen(info._id)}>
                {i18n.t("삭제")}
              </div>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            {/* <Button>bottomCenter</Button> */}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              // onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Dropdown>
        );
      },
    },
  ];
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    return (
      <div className="job_searchResultArea-container">
        <ApplicantsListDialog
          open={this.state.isDialogOpen}
          onClose={() => this.setState({ isDialogOpen: false })}
          pendingUserList={this.state.pendingUserList}
          scrollInfo={this.pendingScrollState}
          isLoading={this.state.isPendingLoading}
          addPendingUserList={this.addPendingUserList}
        />
        <Table
          className="modal-table"
          rowKey="_id"
          pagination={false}
          columns={this.columns}
          dataSource={this.props.list}
          onChange={this.handleTableSortChange}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (event) => {
                this.setState({
                  selectedPostId: record._id,
                });
              }, // click row
            };
          }}
          // 번역
          locale={{
            filterConfirm: i18n.t("global.ok"),
            filterReset: i18n.t("global.reset"),
            triggerDesc: i18n.t("admin.sort.triggerDesc"),
            triggerAsc: i18n.t("admin.sort.triggerAsc"),
            cancelSort: i18n.t("admin.sort.cancelSort"),
            emptyText: i18n.t("global.noData"),
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(SearchResult);
