import React, { Component } from "react";
import "./OperationRulesDialog.scss";
import { Dialog } from "@material-ui/core";
// import { withStyles } from '@material-ui/core/styles'
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import { connect } from "react-redux";
import { langChange } from "../../Redux/action/index";
// const styles = {
//     maxWidthXs: {
//         maxWidth: '520px',
//     },
// }

class RulesDialog extends Component {
  state = {};
  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"termsDialog"}
          open={open}
          onClose={onClose}
        >
          <div className="closeBtn" onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </div>
          <div className="term-button-dialog-container terms">
            {this.props.lang === "ko" ? (
              <div className="terms_container">
                <div className="terms_header">
                  <span>모션트렌드 커뮤니티 가이드라인</span>
                </div>
                <div className="terms_title">
                  <span className="title-header">
                    {`<모션트렌드 이용제한 안내>`}
                  </span>
                  <span className="title-header">
                    <p>
                      모션트렌드는 전 연령층 대상의 서비스로서 즐거운 작품
                      감상과 상호 의견 교류를 위해 다음과 같은 불량 게시물과
                      ID에 대해 이용제한 조치를 취하고 있습니다.
                    </p>
                  </span>
                  <span className="title-header">
                    1. 불량 게시물/댓글에 대한 정의
                  </span>
                  <p>
                    모션트렌드의 모든 업로드 게시물과 댓글에 대하여
                    방송통신심의위원회의 심의규정을 참고하여 다음과 같은
                    게시물을 불량 게시물로 정의합니다.
                  </p>
                  <span className="title-sub_header">
                    1) 욕설, 비방(특정 인물 또는 단체, 종교 등을 욕하거나
                    비방하는) 게시물/댓글
                  </span>
                  <span className="title-sub_header">
                    2) 폭력, 비행, 사행심 조장하는 게시물/댓글
                  </span>
                  <p>
                    - 자살을 미화 · 권유하거나 자살방법을 적시하는 내용,
                    동반자살을 유도하는 내용<br></br>- 범죄 관련 내용을 미화 ·
                    권유 · 조장하는 내용<br></br>- 성폭력, 마약복용 등 퇴폐적
                    행위를 자극하거나 미화하는 내용<br></br>- 사회 윤리적으로
                    용납되지 않은 행위를 매개하는 경우(사이버 스토킹에 관한 내용
                    등)<br></br>- 살인을 청탁하거나 이를 권유, 유도, 매개하는
                    내용<br></br>- 처형장면, 시체 등 혐오감을 일으키는 내용을
                    주된 내용으로 묘사하는 경우<br></br>- 혈흔이 낭자하거나
                    신체를 훼손시키는 내용을 주된 내용으로 묘사하는 경우
                    <br></br>- 부녀자 및 어린이 학대 등 폭력행위를 미화하는 내용
                    <br></br>- 존속에 대한 상해 폭행 살인 등 전통적인 가족윤리를
                    훼손할 우려가 있는 내용<br></br>- 청소년 유해약물의 제조 및
                    사용을 조장하는 경우<br></br>- 도박 등의 사행심을 조장하거나
                    불법적인 경품/복권을 판매하는 등의 내용<br></br>- 금전적
                    거래를 발생시키거나 이를 알선하는 경우<br></br>- 불법적인
                    피라미드식 영업행위를 권유 조장하는 등의 내용<br></br>-
                    돈버는 사이트 소개, 피라미드식 사기행위 등 허위사실이나
                    불법적 내용을 게시하는 경우 등<br></br>- 스와핑, 동거 등
                    사회 윤리적으로 용납되지 않은 행위를 매개하는 경우<br></br>-
                    청소년에게 불건전한 교제를 조장할 우려가 있거나 이를
                    매개하는 경우
                  </p>
                  <span className="title-sub_header">
                    3) 음란성, 성 폭력성 게시물/댓글
                  </span>
                  <p>
                    지나친 노출로 청소년에게 유해하다고 판단되는 경우 (ex.
                    남녀의 성기, 음모 또는 항문이 구체적으로 묘사되는 경우)
                    <br></br>- 성행위를 직접적으로 묘사한 내용<br></br>- 신체의
                    일부 또는 성기구를 이용한 자위행위를 묘사한 경우<br></br>-
                    어린이 또는 청소년을 성 유희의 대상으로 직접적이고
                    구체적으로 묘사한 경우<br></br>- 성범죄를 구체적 사실적으로
                    묘사하거나 미화한 내용<br></br>- 노골적인 성적대화 등
                    성적유희 대상을 찾거나 매개하는 내용<br></br>- 성행위와
                    관련된 신음소리 등을 자극적으로 묘사한 경우<br></br>- 수간,
                    혼음, 성고문 등 변태성욕을 묘사한 경우<br></br>- 남녀의
                    성기를 저속하게 표현한 경우<br></br>- 불륜관계, 근친상간 등
                    반인륜적 성행위를 자세하게 소개하거나 흥미위주로 묘사한 경우
                    <br></br>- 성매매 등 불법 성행위를 정당하게 묘사하거나 알선,
                    유도, 조장, 방조하는 내용<br></br>- 음란정보 또는 퇴폐업소가
                    있는 장소를 안내 또는 매개하는 내용<br></br>- 음란 사이트,
                    자료를 링크한 경우<br></br>- 기타 일반인의 성적 수치심을
                    현저히 해할 우려가 있는 경우<br></br>
                  </p>
                  <span className="title-sub_header">
                    4) 불법유통, 저작권 위반 관련 게시물/댓글
                  </span>
                  <p>
                    - 저작권자의 동의를 구하지 않은 창작물을 불법게재, 편집,
                    배포, 권유하는 내용 (ex. 방송, 음원, 영화, 소설, 모바일
                    게임, 만화, 영상 등 타인의 지적 활동으로 창출된 결과물을 내
                    게시물에 삽입, 배포, 전송하는 경우)<br></br>- 다른 서비스나
                    사이트에서 허락되지 않은 자료를 퍼오거나 링크하는 경우
                    <br></br>- 정품을 사용하지 않고 무료 다운로드 등 불법 복제를
                    권하는 내용<br></br>- 시리얼 번호 등 불법 복제와 관련된 내용
                    <br></br>- P2P나 불법공유 사이트를 권하는 내용<br></br>-
                    주민등록생성기, 카드생성기, 아이템생성기 등 불법자료를
                    올리는 경우<br></br>- 불법 제품을 이용자에게 판매하거나
                    소개하는 경우<br></br>- 불법적인 해킹 정보를 유통하는 경우
                    <br></br>- 난자매매, 장기매매, 대리모 등의 신체와 관련된
                    금전 거래를 야기할 우려가 있는 경우<br></br>- 악성코드,
                    바이러스 등 이용자의 서비스 환경을 저해하는 프로그램 및 검색
                    어뷰징 등 회사의 업무를 방해하는 프로그램을 수록하거나
                    설치를 유도, 배포하는 경우<br></br>- 타인의 권리에 속하는
                    저작권, 상표권, 의장권 등을 무단으로 침해하는 내용<br></br>※
                    자신의 저작권을 침해한 게시물(침해 주장 게시물)이 있는 경우,
                    ‘신고’를 통해 해당 게시물(일러스트레이션)의 저작권 침해 내용
                    신고와 함께 게시중단을 요청해 주세요.
                  </p>
                  <span className="title-sub_header">
                    5) 상업 광고 게시물/댓글
                  </span>
                  <p>
                    - 특정 회사나 개인의 이익을 목적으로 상업적 내용을 게시한
                    경우<br></br>- 상품을 진열하고 금전적 거래를 발생시키는 경우
                    혹은 이를 알선하는 경우<br></br>- 판매 물품에 대해 허위 또는
                    과장으로 광고하여 모션트렌드 이용자에게 손해를 발생시키는
                    경우<br></br>- 특정 상호명을 포함하여 반복적으로 등록하여
                    직/간접적으로 상호명의 인지가 가능한 경우<br></br>- 특정
                    회사의 웹주소 (URL) 포함될 경우<br></br>
                  </p>

                  <span className="title-sub_header">
                    6) 도배성 게시물/댓글
                  </span>
                  <p>
                    - 작품과 관련되지 않은 공지글 등을 비롯한 게시판 성격에 맞지
                    않거나 동일한 내용(특정 단어/문구)을 반복적 나열하는 도배성
                    게시물/댓글<br></br>- 프로그램 등을 이용하여 기계적인
                    패턴글로 도배하는 경우<br></br>- 행운의 편지글, 스팸글,
                    홍보글 등의 도배자료를 올리는 경우<br></br>
                  </p>
                  <span className="title-sub_header">
                    7) 타인의 개인정보(실명, 주민번호, 연락처, 주소, 블로그
                    주소, 사진, 영상 등)를 본인의 동의 없이 게재하거나 노출을
                    유도한 게시물/댓글
                  </span>

                  <span className="title-sub_header">
                    8) 사이버 명예훼손의 우려가 있는 게시물/댓글
                  </span>
                  <p>
                    - 개인의 사생활, 초상권을 침해한 내용<br></br>- 개인이나
                    단체에 대해 비방, 명예훼손적 사실 및 허위사실 등을 유포한
                    경우 또는 권리를 침해한 경우<br></br>- 욕설 또는 언어폭력
                    등의 저속한 표현으로 특정인의 인격을 모독하거나 불쾌감을
                    불러 일으키는 내용<br></br>
                  </p>

                  <span className="title-sub_header">
                    9) 개인적인 비난, 비판, 토론 형태를 갖는 게시물/댓글
                  </span>
                  <span className="title-sub_header">
                    10) 운영자, 직원 및 관계자를 사칭하는 게시물/댓글
                  </span>
                  <span className="title-sub_header">
                    11) 어뷰징 프로그램 사용 및 서비스를 조작하려는 의도가
                    보이는 게시물/댓글
                  </span>
                  <span className="title-sub_header">
                    12) 불쾌감을 유발하는 혐오성, 반사회적 게시물/댓글
                  </span>
                  <span className="title-sub_header">
                    13) 기타 선량한 풍속 및 사회질서에 객관적으로도 명백히
                    위배되는 게시물/댓글
                  </span>

                  <span className="title-header">
                    2. 불량 게시물/댓글에 대한 신고
                  </span>
                  <p>
                    1) 모션트렌드의 모든 게시물/댓글에는 '신고' 버튼이 있습니다.
                    게시물/댓글이 불량성이라고 판단될 때는 ‘신고’ 버튼을 누르고,
                    신고 이유를 입력해서 회원이 직접 신고할 수 있습니다.
                    <br></br>
                    2) 부당한 사유로 신고를 하거나 동일 게시물을 중복 신고
                    시에는 신고자가 징계를 받을 수 있습니다.<br></br>
                  </p>
                  <span className="title-header">
                    3. 불량 게시물/댓글의 징계
                  </span>
                  <p>
                    1) 동일 게시물/댓글이 반복적으로 신고된 경우 해당 게시물은
                    블라인드 처리되며, 커뮤니티 가이드라인에 맞지 않는 경우
                    사전통지 없이 삭제될 수 있습니다.<br></br>
                    2) 게시물/댓글 징계 횟수가 누적이 되는 ID의 경우 누적 정도에
                    따라서 경고, 일시정지, 영구이용정지 등 단계적으로 이용이
                    제한될 수 있습니다. 단, 음란게시물 등 불법적인 게시물을
                    작성하는 등 그 위반 정도가 중한 경우에는 신고횟수나 징계횟수
                    누적 정도와 관계없이 ID 일시정지 또는 영구이용정지 될 수
                    있습니다.<br></br>
                  </p>
                  <span className="title-header">
                    4. 부당신고의 정의 및 징계
                  </span>
                  <p>
                    1) 불량성이 아닌 타인의 게시물을 적합하지 않은 사유로
                    신고하거나 동일 게시물을 중복 신고하는 경우 부당신고로
                    처리됩니다.<br></br>
                    2) 부당신고자는 서비스 이용 제한 등 징계를 받을 수 있습니다.
                    부당신고의 정도가 심한 경우, 영구적으로 모션트렌드 서비스
                    이용이 제한될 수 있습니다.
                  </p>
                </div>

                <div className="termsBtn">
                  <button onClick={onClose} type="button">
                    {i18n.t("global.ok")}
                  </button>
                </div>
              </div>
            ) : (
              // 영문 버전 적용
              <div className="terms_container">
                <div className="terms_header">
                  <span>MotionTrend Community Guidelines</span>
                </div>
                <div className="terms_title">
                  <span className="title-header">
                    {`<Notice on Restriction on MotionTrend Use>`}
                  </span>
                  <span className="title-header">
                    <p>
                      As a service for users of all ages, MotionTrend takes the
                      following restriction measures on users (IDs) who write
                      bad postings or comments to create a venue for pleasurable
                      illustration appreciation and decent exchange of thoughts.
                    </p>
                  </span>
                  <span className="title-header">
                    1. Definition of bad posting and comment
                  </span>
                  <p>
                    All postings and comments of MotionTrend are applied with
                    the review regulations of Korea Communication Standards
                    Commission and the postings/comments which are not
                    incompliance with the regulations are defined as bad
                    postings/comments.
                  </p>
                  <span className="title-sub_header">
                    1) Postings or comments that use abusive or defamatory
                    language (towards a specific person, group or religion)
                  </span>
                  <span className="title-sub_header">
                    2) Postings or comments that encourage violence, misdeed or
                    speculation, including the following:
                  </span>
                  <p>
                    - Postings or comments that glamorize or recommend suicide,
                    contain explicit descriptions of how to commit suicide and
                    induce joint suicide.<br></br>- Postings or comments that
                    glamorize, suggest or induces crime-related contents.
                    <br></br>- Postings or comments that stimulate other to
                    commit decadent activity such as sexual violence and drug
                    use or those that glamorize such activities.<br></br>-
                    Postings or comments that mediate activities that are not
                    tolerated by social ethics including cyber stocking.
                    <br></br>- Postings or comments that solicit or suggest,
                    induce or mediate others to commit murder.<br></br>-
                    Postings or comments that include contents that disgust
                    others, such as image of execution or a corpse.<br></br>-
                    Postings or comments that include contents covered with
                    blood or of bodily damage as the main contents.<br></br>-
                    Postings or comments that glamorize violence including
                    bullying the fair sex or child maltreatment<br></br>-
                    Postings or comments that contain contents undermining
                    traditional family ethics such as injury, assault and murder
                    of family members.<br></br>- Postings or comments that
                    contain detailed information on how to make drugs that are
                    harmful to minors and encourage the use of such drugs.
                    <br></br>- Postings or comments that encourage speculation
                    such as gambling or sell illegal giveaway or lottery.
                    <br></br>- Postings or comments that trigger or aid monetary
                    transaction.<br></br>- Postings or comments that recommend
                    or encourage illegal multilevel marketing activities.
                    <br></br>- Postings or comments that contain false or
                    illegal information, such as illegal money-making website or
                    illegal multilevel marketing activities.<br></br>- Postings
                    or comments that mediate activities that are not tolerated
                    by social ethics, including swapping or co-habit.<br></br>
                    Postings or comments that might encourage unsound
                    relationship in minors or mediate such relationship.
                  </p>
                  <span className="title-sub_header">
                    3) Obscene postings or comments or those of sexual crime
                    nature, including the following:
                  </span>
                  <p>
                    - Postings or comments that contain excessive display of
                    bare skin that is considered to be harmful to adolescent
                    (depicting male/female sexual organs, pubic hair or the anus
                    specifically)<br></br>- Postings or comments that directly
                    depict intercourse.<br></br>- Postings or comments that
                    portray masturbation using bodily parts or sex toys.
                    <br></br>- Postings or comments that portray children or
                    teenagers as subjects of sexual entertainment in a direct
                    and specific manner.<br></br>- Postings or comments that
                    depict sexual crime in specific and realistic manner.
                    <br></br>- Postings or comments that seek for or mediate
                    target for sexual entertainment through explicit sexual
                    conversation.<br></br>- Postings or comments that depict
                    sounds that are made during sexual activities in a
                    provocative manner.<br></br>- Postings or comments that
                    portray abnormal sexual activities, including sodomy, group
                    sex or sexual torture.<br></br>- Postings or comments that
                    depict male/female genitals in a degrading manner.<br></br>-
                    Postings or comments that provide detailed description of
                    inhumane sexual activities, including adultery or incest, or
                    portray such information as entertainment.<br></br>-
                    Postings or comments that depict prostitution to be
                    legitimate or contents that arrange, induce, encourage or
                    abetting such action.<br></br>- Postings or comments that
                    guide viewers to a location where there is obscene
                    information or decadent entertainment establishments or
                    those mediating to such location.<br></br>- Postings or
                    comments that provide a link to other websites that contain
                    obscene contents or to obscene material<br></br>- Postings
                    or comments that are concerned to cause sexual humiliation
                    of other general public.
                  </p>
                  <span className="title-sub_header">
                    4) Postings or comments relating to illegal distribution or
                    those violating copyrights, including the following:
                  </span>
                  <p>
                    - Contents that include illegal posting, editing,
                    distribution and recommendation of copyrighted material
                    (e.g., inserting, distributing or transferring a products of
                    an individual's intellectual activity, such as broadcasting
                    material, music, movie, books, mobile games, cartoon and
                    video) without approval from the intellectual property
                    rights owner.<br></br>- Postings or comments that contain
                    unpermitted reproductions or links of posts from other
                    service or website.<br></br>- Postings or comments that
                    encourage illegal duplications, such as free downloads
                    without purchasing original copy<br></br>- Contents
                    regarding illegal duplication such as serial number.
                    <br></br>- Contents recommending illegal file sharing or P2P
                    website.<br></br>- Postings or comments that contain an
                    illegal program which automatically generates resident
                    registration number, card number and game items.<br></br>-
                    Postings or comments that sell or introduce illegal products
                    to users.<br></br>- Postings or comments that distribute
                    illegal hacking related information.<br></br>- Postings or
                    comments that may lead to monetary transactions related to
                    the human body, including the sales of ovum, organs or
                    surrogate mothering.<br></br>- Postings or comments that
                    include programs that impede users' service environment,
                    such as malicious codes or viruses, programs that interfere
                    with the company's business, such as search abusing, or
                    those that recommend the installment of or distribute such
                    programs<br></br>- Postings or comments that violate
                    copyrights, trademark rights or design rights owned by an
                    individual without his/her permission.<br></br>※ If there
                    are posts that violate your copyrights (deemed to have
                    violated such copyright) please report relevant posting
                    (illustration) with 'Report' button along with a description
                    thereof and request for a suspension of posting.
                  </p>
                  <span className="title-sub_header">
                    5) Postings or comments of commercial advertisement,
                    including the following:
                  </span>
                  <p>
                    - Postings or comments that contain commercial details to
                    seek for an interest or a specific company and individual.
                    <br></br>- Postings or comments that display goods and
                    generate monetary transactions or aids such transactions.
                    <br></br>- Postings or comments that inflict loss on a
                    MotionTrend user through an exaggerated or false
                    advertisement of the selling products.<br></br>- Postings or
                    comments that mention a specific business name repeatedly so
                    that the name of business can be recognized either directly
                    or indirectly.
                    <br></br>- Postings or comments that contain the web address
                    (URL) of a specific company.
                  </p>

                  <span className="title-sub_header">
                    6) Postings or comments entered repeatedly
                  </span>
                  <p>
                    - When repeatedly entering multiple posts and comments that
                    are irrelevant to the characteristic of board including
                    notice irrelevant to illustration or uploading identical
                    contents repeatedly (specific words or phrase)<br></br>-
                    When repeatedly entering mechanical pattern posts using a
                    special program<br></br>- When repeatedly entering document
                    including letters of fortune, spam-like post or promotional
                    posts.
                  </p>
                  <span className="title-sub_header">
                    7) Posts or comments that contain personal information of an
                    individual, including name, resident registration number,
                    contact number, address, blog address, photo or video,
                    without consent from the individual or those that induce the
                    exposure of such information.
                  </span>

                  <span className="title-sub_header">
                    8) Posts or comments which are concerned to be 'cyber
                    smears,' including the following:
                  </span>
                  <p>
                    - Contents that infringe on privacy or portrait right of an
                    individual.<br></br>- Contents that disclose matters that
                    could defame or calumniate an individual or group or false
                    information or that infringe the rights of individual or
                    group.<br></br>- Contents displeasing other or defame a
                    specific individual with profanity or other foul
                    expressions.
                  </p>

                  <span className="title-sub_header">
                    9) Postings or comments of personal criticism or discussion
                  </span>
                  <span className="title-sub_header">
                    10) Postings or comments where an author misrepresents
                    oneself as the administrator, staff or related person.
                  </span>
                  <span className="title-sub_header">
                    11) Postings or comments where an author has the intention
                    to manipulate service using abusing program.
                  </span>
                  <span className="title-sub_header">
                    12) Postings or comments that displease other users such as
                    disgusting or anti-social contents.
                  </span>
                  <span className="title-sub_header">
                    13) Other postings or comments explicitly violating other
                    sound culture and social order objectively.
                  </span>

                  <span className="title-header">
                    2. Reporting bad postings and comments
                  </span>
                  <p>
                    1) There is a 'Report' button to all postings and comments
                    of MotionTrend. A member can report through clicking
                    'Report' button when a posting or comment is deemed to be
                    foul along with the reason for reporting.<br></br>
                    2) If a report is made with unfair reason or if identical
                    posts are reported in duplicate, the reporter is subject to
                    a disciplinary measure.
                  </p>
                  <span className="title-header">
                    3. Disciplinary punishment to bad posting and comment
                  </span>
                  <p>
                    1) When an identical post or comment is reported repeatedly
                    such post will be disclosed from viewers and subject to be
                    deleted without prior notice if it is not in compliance with
                    community guideline.<br></br>
                    2) IDs accumulated with the disciplinary action for postings
                    or comments are subject to be restricted for the use of the
                    service in phase from warning, temporary suspension to
                    permanent suspension. However, if the severity of the
                    violation such as posting illegal posting, i.e. obscene
                    post, the ID of the posted user could be suspended
                    temporarily or permanently regardless of the accumulation of
                    reports or disciplinary measures.
                  </p>
                  <span className="title-header">
                    4. Definition of unfair report and disciplinary measure that
                    follows
                  </span>
                  <p>
                    1) If a repeated report is made for identical posts or for a
                    normal post which is not a bad post, with unconformable
                    reason, such report will be handled as an unfair reporting.
                    <br></br>
                    2) A reporter who has wrongfully reported could be imposed
                    with restriction for using the service. If the severity of
                    the wrongful report is too high, the reporter is subject to
                    be restricted for MotionTrend service use permanently.
                  </p>
                </div>

                <div className="termsBtn">
                  <button onClick={onClose} type="button">
                    {i18n.t("global.ok")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RulesDialog);
