import React, { Component } from "react";
import "./TermsDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { withStyles } from '@material-ui/core/styles'
import closeIcon from "../../image/close-icon.svg";
import { connect } from "react-redux";
import { langChange } from "../../Redux/action/index";
import i18n from "../../i18n";
// const styles = {
//     maxWidthXs: {
//         maxWidth: '520px',
//     },
// }
class TermsDialog extends Component {
  state = {};
  render() {
    const { onClose, open } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paperWidthSm: {
            minWidth: 250,
            maxWidth: 1500,
            width: "auto",
            height: "auto",
            zIndex: 9999,
          },
          container: {
            height: "100%",
          },
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"termsDialog"}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </div>
            <div className="term-button-dialog-container terms">
              {this.props.lang === "ko" ? (
                <div className="terms_container">
                  <div className="terms_header">
                    <span>{i18n.t("terms.condition.header")}</span>
                  </div>
                  <div className="terms_title">
                    <span className="title-header">제 1 조 (목적)</span>
                    <p>
                      이 약관은 모션트렌드 주식회사("회사")가 제공하는
                      모션트렌드(www.motiontrend.com) 및 모션트렌드 관련 제반
                      서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및
                      책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                    </p>
                    <span className="title-header">제 2 조 (정의)</span>
                    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                    <p>
                      ①"서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의
                      각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수
                      있는 모션트렌드(motiontrend.com) 및 모션트렌드 관련 제반
                      서비스를 의미합니다.
                    </p>
                    <p>
                      ②"회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라
                      "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를
                      이용하는 고객을 말합니다.
                    </p>
                    <p>
                      ③"유저네임(User Name)"라 함은"회원"의 식별과 "서비스"
                      이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자 또는
                      숫자의 조합을 의미합니다.
                    </p>
                    <p>
                      ④"비밀번호"라 함은"회원"이 부여 받은 "유저네임"과 일치되는
                      "회원"임을 확인하고 비밀보호를 위해"회원" 자신이 정한 문자
                      또는 숫자의 조합을 의미합니다.
                    </p>
                    <p>
                      ⑤"게시물"이라 함은"회원"이 "서비스"를 이용함에 있어
                      "서비스상"에 게시한 부호·문자·음성·음향·화상·동영상 등의
                      정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을
                      의미합니다.
                    </p>
                    <p>
                      ⑥"콜라보레이션"이라 함은 회사가 회원을 대상으로 특정한
                      주제의 게시물을 제안받고 심사하여 상금 혹은 특전을
                      제공하는 콘테스트를 의미합니다.
                    </p>
                    <span className="title-header">
                      제 3 조 (약관의 게시와 개정)
                    </span>
                    <p>
                      "회사"는 이 약관의 내용을"회원"이 쉽게 알 수 있도록 서비스
                      초기 화면에 게시합니다.
                    </p>
                    <p>
                      ②"회사"는 "약관의 규제에 관한 법률", "정보통신망 이용촉진
                      및 정보보호 등에 관한 법률(이하 "정보통신망법")" 등
                      관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
                      있습니다.
                    </p>
                    <p>
                      ③"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를
                      명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의
                      적용일자30일 전부터 적용일자 전일까지 공지합니다. 다만,
                      회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간
                      서비스내 전자우편, 로그인시 동의창 등의 전자적 수단을 통해
                      따로 명확히 통지하도록 합니다.
                    </p>
                    <p>
                      ④회사가 전항에 따라 개정약관을 공지 또는 통지하면서
                      회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가
                      표명된 것으로 본다는 뜻을 명확하게 공지 또는
                      통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지
                      아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                    </p>
                    <p>
                      ⑤회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정
                      약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을
                      해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는
                      특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수
                      있습니다.
                    </p>
                    <span className="title-header">
                      제 4 조 (이용계약 체결)
                    </span>
                    <p>
                      ①이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가
                      약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고
                      "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                    </p>
                    <p>
                      ②"회사"는 "가입신청자"의 신청에 대하여"서비스" 이용을
                      승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에
                      해당하는 신청에 대하여는 승낙을 하지 않거나 사후에
                      이용계약을 해지할 수 있습니다.<br></br>
                      1.가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한
                      적이 있는 경우, 단 "회사"의 회원 재가입 승낙을 얻은
                      경우에는 예외로 함.<br></br>
                      2.실명이 아니거나 타인의 명의를 이용한 경우<br></br>
                      3.허위의 정보를 기재하거나, "회사"가 제시하는 내용을
                      기재하지 않은 경우
                      <br></br>
                      4.이용자의 귀책사유로 인하여 승인이 불가능하거나 기타
                      규정한 제반 사항을 위반하며 신청하는 경우
                    </p>
                    <p>
                      ③제1항에 따른 신청에 있어"회사"는 "회원"의 종류에 따라
                      전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
                    </p>
                    <p>
                      ④"회사"는 서비스관련설비의 여유가 없거나, 기술상 또는
                      업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                    </p>
                    <p>
                      ⑤제2항과 제4항에 따라 회원가입신청의 승낙을 하지
                      아니하거나 유보한 경우, "회사"는 원칙적으로 이를
                      가입신청자에게 알리도록 합니다.
                    </p>
                    <p>
                      ⑥이용계약의 성립 시기는"회사"가 가입완료를 신청절차 상에서
                      표시한 시점으로 합니다.
                    </p>
                    <p>
                      ⑦"회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여
                      서비스 메뉴를 세분하여 이용에 차등을 둘 수 있습니다.
                    </p>
                    <p>
                      ⑧"회사"는 "회원"에 대하여 "영화 및 비디오물의 진흥에 관한
                      법률" 및 "청소년보호법"등에 따른 등급 및 연령 준수를 위해
                      이용제한이나 등급별 제한을 할 수 있습니다.
                    </p>
                    <span className="title-header">
                      제 5 조 (회원정보의 변경)
                    </span>
                    <p>
                      ①"회원"은 개인정보관리화면을 통하여 언제든지 본인의
                      개인정보를 열람하고 수정할 수 있습니다.
                    </p>
                    <p>
                      ②"회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우
                      온라인으로 수정을 하거나 전자우편, 기타 방법으로 "회사"에
                      대하여 그 변경사항을 알려야 합니다.
                    </p>
                    <p>
                      ③제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에
                      대하여"회사"는 책임지지 않습니다.
                    </p>
                    <span className="title-header">
                      제 6 조 (개인정보보호 의무)
                    </span>
                    <p>
                      "회사"는 "정보통신망법" 등 관계 법령이 정하는 바에
                      따라"회원"의 개인정보를 보호하기 위해 노력합니다.
                      개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의
                      개인정보취급방침이 적용됩니다. 다만, "회사"의 공식 사이트
                      이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이
                      적용되지 않습니다.
                    </p>
                    <span className="title-header">
                      제 7 조 ("회원"의 "유저네임" 및 "비밀번호"의 관리에 대한
                      의무)
                    </span>
                    <p>
                      ①"회원"의 "유저네임"와 "비밀번호"에 관한 관리책임은
                      "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안
                      됩니다.
                    </p>
                    <p>
                      ②"회사"는 "회원"의 "유저네임" 이 개인정보 유출 우려가
                      있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사" 및
                      "회사"의 운영자로 오인한 우려가 있는 경우, 해당
                      "유저네임"의 이용을 제한할 수 있습니다.
                    </p>
                    <p>
                      ③"회원"은 "유저네임" 및 "비밀번호"가 도용되거나 제3자가
                      사용하고 있음을 인지한 경우에는 이를 즉시 "회사"에
                      통지하고 "회사"의 안내에 따라야 합니다.
                    </p>
                    <p>
                      ④제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지
                      않거나, 통지한 경우에도 "회사"의 안내에 따르지 않아 발생한
                      불이익에 대하여 "회사"는 책임지지 않습니다.
                    </p>
                    <span className="title-header">
                      제 8 조 ("회원"에 대한 통지)
                    </span>
                    <p>
                      ①"회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도
                      규정이 없는 한 서비스 내 전자우편주소로 할 수 있습니다.
                    </p>
                    <p>
                      ②"회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의
                      게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
                    </p>
                    <span className="title-header">
                      제 9 조 ("회사"의 의무)
                    </span>
                    <p>
                      ①"회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는
                      행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를
                      제공하기 위하여 최선을 다하여 노력합니다.
                    </p>
                    <p>
                      ②"회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록
                      개인정보보호를 위해 보안시스템을 갖추어야 하며
                      개인정보취급방침을 공시하고 준수합니다.
                    </p>
                    <p>
                      ③"회사"는 서비스이용과 관련하여 "회원"으로부터 제기된
                      의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야
                      합니다. "회원"이 제기한 의견이나 불만사항에 대해서는
                      게시판을 활용하거나 전자우편 등을 통하여"회원"에게
                      처리과정 및 결과를 전달합니다.
                    </p>
                    <span className="title-header">
                      (제 10 조 ("회원"의 의무)
                    </span>
                    <p>
                      ①"회원"은 다음 행위를 하여서는 안 됩니다. 1.신청 또는 변경
                      시 허위내용의 등록<br></br>
                      2.타인의 정보도용<br></br>
                      3."회사"가 게시한 정보의 변경
                      <br></br>
                      4."회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의
                      송신 또는 게시<br></br>
                      5."회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해
                      <br></br>
                      6."회사" 및 기타 제3자의 명예를 손상시키거나 업무를
                      방해하는 행위
                      <br></br>
                      7.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                      반하는 정보를"서비스"에 공개 또는 게시하는 행위<br></br>
                      8.회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는
                      행위<br></br>
                      9.기타 불법적이거나 부당한 행위
                      <br></br>
                    </p>
                    <p>
                      ②"회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와
                      관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을
                      준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를
                      하여서는 안 됩니다.
                    </p>
                    <span className="title-header">
                      제 11 조 ("서비스"의 제공 등)
                    </span>
                    <p>
                      ①"서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
                    </p>
                    ②"회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                    통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의
                    제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는
                    제8조["회원"에 대한 통지]에 정한 방법으로 "회원"에게
                    통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한
                    사유가 있는 경우 사후에 통지할 수 있습니다.
                    <p></p>
                    <p>
                      ③"회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
                      있으며, 정기점검시간은 서비스제공화면에 공지한 바에
                      따릅니다.
                    </p>
                    <span className="title-header">
                      제 12 조 ("서비스"의 변경)
                    </span>
                    <p>
                      ①"회사"는 상당한 이유가 있는 경우에 운영상, 기술상의
                      필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할
                      수 있습니다.
                    </p>
                    <p>
                      ②"서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는
                      경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은
                      그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.
                    </p>
                    <p>
                      ③"회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의
                      정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에
                      대하여 관련법에 특별한 규정이 없는 한"회원"에게 별도의
                      보상을 하지 않습니다.
                    </p>
                    <span className="title-header">
                      제 13 조 (정보의 제공 및 광고의 게재)
                    </span>
                    <p>
                      ①"회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는
                      다양한 정보를 공지사항이나 전자우편 등의 방법으로
                      "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에
                      따른 거래관련 정보 및 고객문의 등에 대한 답변 등을
                      제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수
                      있습니다.
                    </p>
                    <p>
                      ②제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고
                      하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다.
                      다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에
                      있어서는 제외됩니다.
                    </p>
                    <p>
                      ③"회사"는 "서비스"의 운영과 관련하여 서비스 화면,
                      홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가
                      게재된 전자우편을 수신한"회원"은 수신거절을 "회사"에게 할
                      수 있습니다.
                    </p>
                    <p>
                      ④"이용자(회원, 비회원 포함)"는 회사가 제공하는 서비스와
                      관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의
                      조치를 취하지 않습니다.
                    </p>
                    <span className="title-header">
                      제 14 조 ("게시물"의 저작권)
                    </span>
                    <p>
                      ①"회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당
                      게시물의 저작자에게 귀속됩니다.
                    </p>
                    <p>
                      ②"회원"이 "서비스" 내에 게시하는 "게시물"은 검색결과 내지
                      "서비스" 및 관련 프로모션 등에 노출될 수 있으며, 해당
                      노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어
                      게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을
                      준수하며, "회원"은 언제든지 고객센터 또는"서비스" 내
                      관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외,
                      비공개 등의 조치를 취할 수 있습니다.
                    </p>
                    <p>
                      ③"회사"는 제2항 이외의 방법으로 "회원"의 "게시물"을
                      이용하고자 하는 경우에는 전화, 전자우편 등을 통해 사전에
                      "회원"의 동의를 얻어야 합니다. 다만 "회원"이
                      "콜라보레이션"에 참여하여 심사를 받고 상금 또는 특전을
                      받은 게시물에 대해서는 "회사"가 해당 페이지 내에서 이를
                      게시할 권리를 갖습니다.
                    </p>
                    <span className="title-header">
                      제 15 조 ("게시물"의 관리)
                    </span>
                    <p>
                      ①"회원"의 "게시물"이 "정보통신망법" 및 "저작권법" 등
                      관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이
                      정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을
                      요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야
                      합니다.
                    </p>
                    <p>
                      ②"회사"는 전항에 따른 권리자의 요청이 없는 경우라도
                      권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
                      관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에
                      대해 임시조치 등을 취할 수 있습니다.
                    </p>
                    <p>
                      ③본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이
                      규정한 범위 내에서 "회사"가 정한 "신고 서비스"에 따릅니다.
                    </p>
                    <span className="title-header">제 16 조 (권리의 귀속)</span>
                    <p>
                      ①"서비스"에 대한 저작권 및 지적재산권은 "회사"에
                      귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라
                      제공된 저작물 등은 제외합니다.
                    </p>
                    <p>
                      ②"회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한
                      이용조건에 따라 "계정", "유저네임", "콘텐츠" 등을 이용할
                      수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매,
                      담보제공 등의 처분행위를 할 수 없습니다.
                    </p>
                    <span className="title-header">
                      제 17 조 (계약해제, 해지 등)
                    </span>
                    <p>
                      ①"회원"은 언제든지 서비스초기화면의 고객센터 또는 내 정보
                      관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며,
                      "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야
                      합니다.
                    </p>
                    <p>
                      ②"회원"이 계약을 해지할 경우, 관련법 및 개인정보취급방침에
                      따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지
                      즉시 "회원"의 모든 데이터는 소멸됩니다.
                    </p>
                    <p>
                      ③"회원"이 계약을 해지하는 경우, "회원"이 작성한 게시물
                      일체는 삭제됩니다. 다만, "콜라보레이션"을 통해 상금 또는
                      특전을 받은 게시물은 해당 "콜라보레이션" 에 그대로 존속
                      유지됩니다
                    </p>
                    <span className="title-header">제 18 조 (이용제한 등)</span>
                    <p>
                      ①"회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의
                      정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지
                      등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.
                    </p>
                    <p>
                      ②"회사"는 전항에도 불구하고, "저작권법" 및
                      "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및
                      운영방해, "정보통신망법"을 위반한 불법통신 및 해킹,
                      악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을
                      위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본
                      항에 따른 영구이용정지 시"서비스" 이용을 통해 획득한 모든
                      혜택도 모두 소멸되며, "회사"는 이에 대해 별도로 보상하지
                      않습니다.
                    </p>
                    <p>
                      ③본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는
                      경우에는 "회사"는 제8조["회원"에 대한 통지]에 따라
                      통지합니다.
                    </p>
                    <p>
                      ④"회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한
                      절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
                      정당하다고 "회사"가 인정하는 경우 "회사"는 즉시"서비스"의
                      이용을 재개합니다.
                    </p>
                    <span className="title-header">제 19 조 (책임제한)</span>
                    <p>
                      ①"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여
                      "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한
                      책임이 면제됩니다.
                    </p>
                    <p>
                      ②"회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에
                      대하여는 책임을 지지 않습니다.
                    </p>
                    <p>
                      ③"회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료,
                      사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
                      않습니다.
                    </p>
                    <p>
                      ④"회사"는 "회원"간 또는 "회원"과 제3자 상호간에 "서비스"를
                      매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
                    </p>
                    <p>
                      ⑤"회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에
                      특별한 규정이 없는 한 책임을 지지 않습니다.
                    </p>
                    <span className="title-header">
                      제 20 조 (준거법 및 재판관할)
                    </span>
                    <p>
                      ①"회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로
                      합니다.
                    </p>
                    <p>
                      ②"회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법
                      상의 관할법원에 제소합니다.
                    </p>
                    <span className="title-header">부칙</span>
                    <p>①이 약관은 2021년 2월 4일부터 적용됩니다.</p>
                  </div>
                  <div className="termsBtn">
                    <button onClick={onClose} type="button">
                      {i18n.t("global.ok")}
                    </button>
                  </div>
                </div>
              ) : (
                // 영문 버전
                <div className="terms_container">
                  <div className="terms_header">
                    <span>{i18n.t("terms.condition.header")}</span>
                  </div>
                  <div className="terms_title">
                    <span className="title-header">Article 1. Purpose</span>
                    <p>
                      The purpose of these Terms of Use ("TOU") is to define the
                      rights, roles, responsibilities, and other necessary
                      matters between MotionTrend Corporation ("Company") and
                      "Members" in regards to the use of the Motiontrend
                      (www.Motiontrend.com) and its relating services provided
                      by the Company.
                    </p>
                    <span className="title-header">
                      Definition of terminologies used in the TOU is as follows:
                    </span>
                    <p>
                      ①"Service" refers to Motiontrend and Motiontrend related
                      services that "Member" can use regardless of the device
                      (including all sorts of wired/wireless devices including
                      PC, TV and portable devices) where the Service runs.
                    </p>
                    <p>
                      ②"Member" is the individual who accesses the Service,
                      enters into an agreement with the Company, in accordance
                      with the TOU, and uses the Service which is provided by
                      the Company.
                    </p>
                    <p>
                      ③"Username" is a combination of letters or numbers that is
                      created by Member and approved by the Company, and is used
                      to identify the Member and use the Service.
                    </p>
                    <p>
                      ④"Password" is a combination of letters, letters and/or
                      numbers that is created by the Member, and is used to
                      confirm the identity of the Member through a match with
                      the Username set by the Member.
                    </p>
                    <p>
                      ⑤"Posting" refers to text, photo, video and all sorts of
                      files and links in the form of signs, letters, voice,
                      sound, visual and video that the Member has posted on the
                      Service while using the Service.
                    </p>
                    <p>
                      ⑥"Collaboration" refers to a contest where the Company is
                      suggested with a Posting of a specific theme from its
                      Member and judges the Posting to provide prize or
                      benefits.
                    </p>
                    <span className="title-header">
                      Article 3. Posting and Revising the TOU
                    </span>
                    <p>
                      ①The Company shall post the TOU on the first screen of the
                      service homepage, so that the Member can easily see the
                      TOU.
                    </p>
                    <p>
                      ②The Company may revise the TOU, as long as the revisions
                      do not infringe related laws, including the "Regulation of
                      Standardized Contracts Act" and the "Act on Promotion of
                      Information and Communication Network Utilization and
                      information Protection, etc. (hereinafter "Information
                      Network Law")."
                    </p>
                    <p>
                      ③In the event the Company revises the TOU, the Company
                      shall post a notification, alongside the current TOU,
                      thirty (30) days prior to the application of the revised
                      provisions until the day prior to the application of
                      revisions, using methods stated in Clause 1. However, if
                      the revised provisions are unfavorable to the Member, the
                      Company shall post a notification thirty (30) days prior
                      to the revisions and use other electronic means, including
                      electronic mail and consent boxes that are shown after
                      logging in, to clearly notify the Members of such
                      revisions.
                    </p>
                    <p>
                      ④In the event the Company notifies the Member, in
                      accordance with provisions in the previous Clause, and the
                      notice states that the Member would be deemed to have
                      agreed with the revised TOU unless the Member sends a
                      reply stating otherwise within thirty (30) days, it would
                      be understood that the Member has agreed with the revised
                      TOU if the Member does not clearly express his/her
                      intention to disagree with the revised TOU.
                    </p>
                    <p>
                      ⑤The Company cannot apply the revised provisions if the
                      Member does not agree with the application of the revised
                      TOU, and the Member may terminate the agreement with the
                      Company. However, if the original TOU cannot be applied
                      due to specific reasons, the Company may terminate the
                      agreement for use.
                    </p>
                    <span className="title-header">
                      Article 4. Entering into an Agreement to Use
                    </span>
                    <p>
                      ①An agreement for use is executed after the individual who
                      wishes to become a Member ("Subscription Applicant")
                      agrees to the provisions of the TOU, submits an
                      application for subscription, and the Company approves of
                      the application
                    </p>
                    <p>
                      ②As a general rule, the Company shall grant approval to
                      the Subscription Applicant to use the Service. However,
                      the Company may disallow use or terminate the agreement at
                      a later date in the following cases:<br></br>
                      1.If the Subscription Applicant has previously lost
                      his/her rights as a Member, due to provisions in the TOU.
                      However, those who were granted permission to re-subscribe
                      is an exception.<br></br>
                      2.If the Subscription Applicant does not use his/her
                      real-name or use the name of a different individual.
                      <br></br>
                      3.If the Subscription Applicant provides false information
                      or does not provide information requested by the Company.
                      <br></br>
                      4.If approval cannot be granted due to reasons
                      attributable to the Subscription Application, or if
                      applications are made by infringing on other regulations.
                    </p>
                    <p>
                      ③The Company may request a real-name confirmation or
                      self-verification through a specialty organization when
                      application for subscription is made, as mentioned in
                      Clause 1.
                    </p>
                    <p>
                      ④The Company may reserve approval if facilities related to
                      the Service are insufficient or if technical or managerial
                      issues are present.
                    </p>
                    <p>
                      ⑤As a general rule, the Company must notify the
                      Subscription Applicant of the decision if the subscription
                      application is not approved or is deferred due to reasons
                      specified Clause 2 and 4 of this Article.
                    </p>
                    <p>
                      ⑥The agreement to use the Service shall become effective
                      when the Company shows that the application has been
                      completed on the application process.
                    </p>
                    <p>
                      ⑦The Company may divide the Member into grades according
                      to the company policy and segment the service menus to
                      differentiate the available service depending on the
                      grade.
                    </p>
                    <p>
                      ⑧The Company may pose restrictions on use or limit per
                      user grade for its Member in order to comply with
                      Promotion of the Motion Pictures and Video Products Act
                      and Juvenile Protection Act.
                    </p>
                    <span className="title-header">
                      Article 5. Modifying Member Information
                    </span>
                    <p>
                      ①The Member can view and change his/her personal
                      information at any time with the "My Settings" page.
                    </p>
                    <p>
                      ②The Member shall notify the Company of any changes in
                      information that was submitted in the subscription
                      application by changing the pertaining information online
                      or by notifying the Company via email or other means.
                    </p>
                    <p>
                      ③The Company shall not be responsible for any loss
                      experienced by the Member due to his/her failure to notify
                      the Company of changes in his/her personal information.
                    </p>
                    <span className="title-header">
                      Article 6. Privacy Protection Obligations
                    </span>
                    <p>
                      The Company shall exert efforts to protect the Member’s
                      personal information in accordance with Act on Promotion
                      of Information and Communications Network Utilization and
                      Information Protection and related laws. Related laws and
                      the Company’s Privacy Policy shall be applied to the
                      protection and use of personal information. However, the
                      Company’s Privacy Policy shall not be applied to websites
                      that are linked to the Company’s office website.
                    </p>
                    <span className="title-header">
                      Article 7. Obligation to Manage the Member’s Username and
                      Password
                    </span>
                    <p>
                      ①The Member shall be responsible for managing the Member’s
                      Username and Password, and shall not allow a third party
                      to use this information.
                    </p>
                    <p>
                      ②The Company may restrict the use of a Member’s Username
                      if the Username generates concerns of personal information
                      leakage, is anti-social, goes against customs, or can
                      mislead others to believe that the Member represents the
                      Company or is the Company’s administrator.
                    </p>
                    <p>
                      ③The Member shall immediately notify the Company and
                      following the Company’s guide if the Member becomes aware
                      of that his/her Username and/or Password has been stolen
                      or is being used by a third party.
                    </p>
                    <p>
                      ④The Company shall not be responsible for any damages that
                      result from the Member’s failure to immediately notify the
                      Company or follow the Company’s guide after notifying the
                      Company in the event of Clause 3.
                    </p>
                    <span className="title-header">
                      Article 8. Notification to Members
                    </span>
                    <p>
                      ①The Company shall notify the Member via electronic mail
                      in the Service, unless a separate regulation exists in the
                      TOU to do otherwise.
                    </p>
                    <p>
                      ②When notifying all Members, the Company can post a
                      notification on the Company’s bulletin board for more than
                      seven (7) days to replace the notification specified in
                      Clause 1.
                    </p>
                    <span className="title-header">
                      Article 9. Company’s Obligations
                    </span>
                    <p>
                      ①The Company shall not conduct actions that are prohibited
                      by the TOU or infringe on customs, and will exert its
                      utmost efforts to provide the Service in a continuous and
                      stable manner.
                    </p>
                    <p>
                      ②The Company shall provide a security system to protect
                      personal information to ensure that the Member can use the
                      Service safely, and announce and conform to the Privacy
                      Policy.
                    </p>
                    <p>
                      ③The Company shall handle opinions or complaints filed by
                      the Member in relations with the use of the Service, if
                      such opinions or complaints are deemed justifiable. The
                      resolution process and results of opinions or complaints
                      filed by the Member must be shown through a bulletin board
                      or by email.
                    </p>
                    <span className="title-header">
                      Article 10. Member’s Obligations
                    </span>
                    <p>
                      ①The Member shall not conduct the following activities:
                      <br></br>
                      1.Submit false information when applying for a
                      subscription or changing inserted information 2.Illegally
                      use another individual’s information<br></br>
                      3.Change information posted by the Company<br></br>
                      4.Transmit or post information (including computer
                      program) other than information decided by the Company
                      <br></br>
                      5.Infringe on the Company or third party’s intellectual
                      property rights, including copyrights
                      <br></br>
                      6.Damage the Company or third party’s reputation or
                      interrupt the Company or third party’s business
                      <br></br>
                      7.Use the Service to transfer or share information that
                      contain sexually explicit or violent messages, videos or
                      sounds or other contents that go against good public order
                      and customs<br></br>
                      8.Use the Service for profit-making purposes, without
                      approval from the Company<br></br>
                      9.Other illegal or unjustifiable actions
                    </p>
                    <p>
                      ②The Member shall abide by related laws, provisions in the
                      TOU, the policy, user guide and precautions posted in
                      relations with the Service, and shall not interfere with
                      the Company’s business.
                    </p>
                    <span className="title-header">
                      Article 11. Providing the Service
                    </span>

                    <p>
                      ①The Service is provided 24 hours a day, and 7 days a
                      week, in principle.
                    </p>

                    <p>
                      ②The Company may temporarily suspend the provision of the
                      Service due to reasons such as maintenance, replacement or
                      malfunction of information and communication facilities,
                      including computers, loss of communication or major
                      operational issues. In such an event, the Company shall
                      notify the Member using the method stated in Article 8.
                      However, the Company may notify the Member ex post facto
                      if situations prohibit the Company from posting a
                      notification in advance.
                    </p>
                    <p>
                      ③The Company may conduct regular maintenance if it is
                      necessary for providing the Service, and maintenance shall
                      be conducted at the time posted on the Service Webpage.
                    </p>
                    <span className="title-header">
                      Article 12. Modifying the Service
                    </span>

                    <p>
                      ①The Company may modify parts or the whole of the Service
                      if operational or technical necessities arise due to
                      significant reasons.
                    </p>
                    <p>
                      ②If modifications have been made to the Service’s
                      contents, use method and/or time, the reasons of the
                      modifications, details of contents that are to be modified
                      and the provided date shall be posted in the first screen
                      of the Service webpage before modifications are applied.
                    </p>
                    <p>
                      ③The Company may modify, suspend, or update parts or the
                      whole of the Service, which is provided free-of-charge, if
                      it is necessary for the Company’s policy or operational
                      needs. The Company shall not provide separate compensation
                      to the Member, unless otherwise specified in provisions of
                      related laws.
                    </p>
                    <span className="title-header">
                      Article 13. Providing the Information and Placing
                      Advertisements
                    </span>

                    <p>
                      ①The Company may provide information that is deemed to be
                      useful to the Member while using the Service using methods
                      such as notifications or email. However, the Member may
                      refuse to accept the email, excluding responses for
                      customer inquiries or information that is stated in
                      related laws, at any time
                    </p>
                    <p>
                      ②The Member’s approval shall be acquired in advance when
                      transmitting information in Clause 1 via phone or fax.
                      However, the Member’s transaction-related information and
                      responses for the Member’s customer inquiry are an
                      exception.
                    </p>
                    <p>
                      ③The Company may post advertisements about the Service
                      operations on the Service screen, webpage and/or Member’s
                      email. The Member who has received the
                      advertisement-posted email can notify the Company to
                      refuse the receiving.
                    </p>
                    <p>
                      ④The User (Members and non-Members altogether) shall not
                      modify, update or restrict postings or information the
                      Company provided in relations with the Service.
                    </p>
                    <span className="title-header">
                      Article 14. Copyrights of the Postings
                    </span>

                    <p>
                      ①The copyright of the Posting that the Member has uploaded
                      to the Service shall vest in the creator of the Posting.
                    </p>
                    <p>
                      ②The Posting that the Member posts within Service could be
                      exposed in the search result, Service, or MotionTrend
                      Service and relating promotions and the Posting could be
                      partially modified, replicated and edited within a scope
                      required for the exposure. In such case, the Company
                      complies with the regulations stipulated in the Copyright
                      Act and the Member may takes several measures for the
                      Posting including deleting, excluding from the search
                      result or making it private at any time through the
                      customer center or management feature in the Service.
                    </p>
                    <p>
                      ③When the Company uses the Member’s Posting in a method
                      other than means defined above, the Company shall acquire
                      approval from the Member in advance via phone or email.
                      However, the Company holds the rights to post a Posting
                      with which the Member has joined the Collaboration and
                      received prize or benefits upon review.
                    </p>
                    <span className="title-header">
                      Article 15. Management of Posting
                    </span>

                    <p>
                      ①If the Member’s Posting includes contents violating the
                      related legislations including "Information Network Law"
                      and "Copyright Law," the right holder may request for the
                      suspension of posting as well as the deletion of the
                      Posting in accordance with the procedure stipulated in the
                      related law and the Company shall take the measure
                      according to a related legislation.
                    </p>
                    <p>
                      ②Even if there is no request made by the right holder as
                      above clause, should there be a reason applausable of
                      infringement of rights, or if the case is in violation of
                      related legislation or corporate policy, a temporary
                      measure can be taken for the Posting according to a
                      related legislation.
                    </p>
                    <p>
                      ③The detailed procedure for incidents stipulated in
                      Article 15 shall follow the "Reporting Service" set by the
                      Company within the scope define in "Information Network
                      Law" and "Copyright Law."
                    </p>
                    <span className="title-header">
                      Article 16. Ownership of Rights
                    </span>

                    <p>
                      ①The copyright and intellectual property right of the
                      Service shall belong to the Company. However, the Posting
                      of Member and creations provided through a partnership
                      agreement shall be considered as an exception.
                    </p>
                    <p>
                      ②The Company shall only grant the Member the right to use
                      the Account, Username, and Contents in accordance with
                      conditions defined by the Company, and the Member shall
                      not transfer, sell, provide as collateral, or dispose of
                      such rights in any way.
                    </p>
                    <span className="title-header">
                      Article 17. Cancellation or Termination of Agreement
                    </span>

                    <p>
                      ①The Member may apply to cancel the use agreement at any
                      time through the customer center in the first screen of
                      the Service webpage or through "My Settings" menu, and the
                      Company shall immediately handle the cancellation, as is
                      defined in related laws.
                    </p>
                    <p>
                      ②If the Member terminates the agreement, the Company shall
                      immediately delete all Member data, in accordance with
                      related laws and the Privacy Policy, unless the Company
                      owns the Member’s information.
                    </p>
                    <p>
                      ③When a "member" cancels the contract, the entire posts
                      created by the "member" are deleted. However, posts that
                      have received prize money or any benefits from
                      "Collaboration" shall remain as artworks under
                      "Collaboration".
                    </p>
                    <span className="title-header">
                      Article 18. Restricting Use
                    </span>

                    <p>
                      ①The Company may gradually limit the Member’s rights to
                      use the Service, through warnings, temporary suspensions,
                      and permanent suspension, if the Member has violated
                      obligations defined in the TOU or if the Member and/or
                      Member has interrupted the normal operations of the
                      Service.
                    </p>
                    <p>
                      ②Despite the Clause above, the Company shall immediately
                      impose a permanent suspension if the Member has provided
                      illegal programs or disrupted operation, which is a
                      violation of the Copyright Act and the Computer Program
                      Protection Act, conducted illegitimate communication or
                      hacking, a violation of the Act on Promotion of
                      Information and Communications Network Utilization and
                      Information Protection, etc. and violated related laws by
                      distributing malicious programs or exceeding accessing
                      authorities. If the Member is permanently suspended due to
                      provisions in this Clause, all benefits acquired during
                      the use of the Service shall be taken away, and the
                      Company shall not provide any other compensation.
                    </p>
                    <p>
                      ③If the use of the Service is restricted or subscription
                      is terminated in accordance with this Article, the Company
                      shall provide notifications in accordance with provisions
                      in Article 8.
                    </p>
                    <p>
                      ④The Member may submit a formal objection to the Company’s
                      restriction of use using procedures defined by the
                      Company. If the Company deems that the objection is
                      justifiable, the Company shall immediately restore the
                      Member’s right to use the Service.
                    </p>
                    <span className="title-header">
                      Article 19. Limitation of Liabilities
                    </span>

                    <p>
                      ①The Company shall be exempt from any responsibilities
                      related to the provision of the Service if the Company is
                      unable to provide the Service due to natural disasters or
                      other force majeure.
                    </p>
                    <p>
                      ②The Company shall not be responsible for failures that
                      occur due to reasons that are attributable to the Member
                      during the use of the Service.
                    </p>
                    <p>
                      ③The Company shall not be responsible for the reliability
                      and accuracy of the information, data and fact that the
                      Member has posted in regards to the Service.
                    </p>
                    <p>
                      ④The Company shall be exempt from any responsibilities
                      that result from transactions made between Members or by a
                      Member and a third party using The Service as a medium.
                    </p>
                    <p>
                      ⑤The Company shall not be responsible for the use of the
                      Service, which is provided free-of-charge, unless
                      provisions specified in related laws state otherwise.
                    </p>
                    <span className="title-header">
                      Article 20. Governing Law and Jurisdiction
                    </span>
                    <p>
                      ①Disputes that arise between the Company and the Member
                      will be governed by the laws of the Republic of Korea.
                    </p>
                    <p>
                      ②Lawsuits surrounding disputes that arise between the
                      Company and Members shall be brought to the competent
                      court stated in the Civil Procedure Act.
                    </p>
                    <span className="title-header">[Appendix]</span>
                    <p>①The TOU shall become effective on February 2, 2021.</p>
                  </div>
                  <div className="termsBtn">
                    <button onClick={onClose} type="button">
                      {i18n.t("global.ok")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TermsDialog);
