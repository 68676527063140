import React, { Component } from "react";
import "./CategorySlider.scss";
// import leftArrowImg from "../../image/red-circle-arrow-icon-1.svg";
// import rightArrowImg from "../../image/red-circle-arrow-icon-2.svg";
import i18n from "../../i18n";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.scss";

import Flickity from "react-flickity-component";
import { GetCategories } from "../../Api/Category/Category";

const flickityOptions = {
  prevNextButtons: true,
  pageDots: false,
  draggable: true,
  // wrapAround: true,
  // selectedAttraction: 0.015,
  // friction: 0.25,
  // initialIndex: 0,
  cellAlign: "left",
  wrapAround: false,
  contain: true,
  groupCells: true,
  freeScroll: true,
  freeScrollFriction: 0.04,
  // cellSelector: ".categoriesBox",
};

class CategorySlider extends Component {
  state = {
    referenceDialogOpen: false,
    clickedCategory: "",
    clickedDetail: [],
    categories: [],
    isPause: true,
    // Play with this value to change the speed
    // tickerSpeed: 0.1,
    slideshowEl: document.querySelector(".js-slideshow"),
    isCateClicked: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resize !== this.props.resize) {
      this.flkty.resize(); // Flikty 리사이즈를 통한 강제 리프레시
    }
  }
  componentWillUnmount = () => {
    this.flkty.off("dragStart");
    this.flkty.off("dragEnd");
    this.flkty.off("scroll");
  };
  componentDidMount = () => {
    if (!this.state.categories[0]) {
      this.getCategoryList();
    }

    this.flkty.on("dragStart", () => {
      document.ontouchmove = (e) => {
        e.preventDefault();
      };
      this.flkty.slider.childNodes.forEach(
        (slide) => (slide.style.pointerEvents = "none")
      );
    });
    this.flkty.on("dragEnd", () => {
      document.ontouchmove = () => {
        return true;
      };
      this.flkty.slider.childNodes.forEach(
        (slide) => (slide.style.pointerEvents = "all")
      );
    });

    this.flkty.on("scroll", () => {
      const firstCellOverflowed = Math.abs(this.flkty.x) >= 20; // 첫번째 Cell X 좌표상 가려지는 시점
      const lastCellOverflowed =
        Math.abs(this.flkty.x) <= this.flkty.slidesWidth - 20; // 마지막 Cell X 좌표상 가려지는 시점
      this.setState({
        firstCellOverflowed: firstCellOverflowed,
        lastCellOverflowed: lastCellOverflowed,
      });

      // enable/disable previous/next buttons
      // console.log(this.flkty.prevButton);
      if (firstCellOverflowed) {
        this.flkty.prevButton.element.removeAttribute("disabled"); // <-- remove disabled from the prev
        this.flkty.prevButton.isEnabled = true; // <-- enable Button Function
      } else {
        this.flkty.prevButton.element.setAttribute("disabled", "disabled"); // <-- remove disabled from the prev
        this.flkty.prevButton.isEnabled = false; // <-- disable Button Function
      }
      if (lastCellOverflowed) {
        this.flkty.nextButton.element.removeAttribute("disabled"); // <-- remove disabled from the next
        this.flkty.nextButton.isEnabled = true; // <-- enable Button Function
      } else {
        this.flkty.nextButton.element.setAttribute("disabled", "disabled"); // <-- remove disabled from the next
        this.flkty.nextButton.isEnabled = false; // <-- disable Button Function
      }
    });

    this.flkty.on("cellSelect", () => {});

    // this.update();
  };
  handleClick = (e, id) => {
    // console.log(e, id);
    e.preventDefault();

    if (this.state.clickedCategory === id) {
      // this.pause();
      this.handleClickClear();
    } else {
      // this.pause();
      this.handleClickCategory(id);
    }
  };

  getCategoryList = async () => {
    try {
      const response = await GetCategories();

      this.setState({ categories: response });
    } catch (e) {
      console.log(e);
    }
  };
  handleReferenceDialogClose = () => {
    this.setState({ referenceDialogOpen: false });
  };

  handleClickCategory = (item) => {
    if (this.props.history.location.pathname === "/") {
      this.setState({ clickedCategory: item }, () => {
        this.props.searchCategory(item);
      });
    } else if (this.props.history.location.pathname === "/discover") {
      this.setState({ clickedCategory: item }, () => {
        this.props.searchCategory(item);
      });
    } else {
      this.props.history.push("/");
      this.setState({ clickedCategory: item }, () => {
        this.props.searchCategory(item);
      });
    }
  };
  handleClickDetail = (data) => {
    this.setState({ clickedDetail: data.title });
  };

  handleClickClear = () => {
    this.setState({ clickedCategory: "" }, () => {
      this.props.searchCategory();
    });
  };
  render() {
    return (
      <div
        className="categories-container"
        data-first={this.state.firstCellOverflowed ? "true" : "false"}
        data-last={this.state.lastCellOverflowed ? "true" : "false"}
      >
        <Flickity
          style={{ cursor: "pointer" }}
          flickityRef={(c) => (this.flkty = c)}
          className={"slideshow js-slideshow"} // default ''
          options={flickityOptions} // takes flickity options {}

          // elementType={'div'} // default 'div'
          // disableImagesLoaded={false} // default false
          // reloadOnUpdate // default false
          // static // default false
        >
          <React.Fragment key={`parent_All`}>
            <div
              onClick={(e) => this.handleClick(e, "")}
              className="categoriesBox"
              // onMouseEnter={this.pause}
              // onMouseOver={this.pause}
              // onMouseLeave={this.play}
              // value={item._id}

              style={{ cursor: "pointer" }}
            >
              <input
                type="radio"
                name="category"
                id={`theme-All`}
                // onChange={() => this.handleClickCategory(item._id)}
              ></input>
              <label
                id="theme"
                htmlFor={`theme-All`}
                className={this.state.clickedCategory === "" ? "check" : null}
              >
                {/* {i18n.language === "en" ? item.name.en : item.name.ko} */}
                {i18n.t("discover.workfield.all")}
              </label>
            </div>
          </React.Fragment>
          {this.state.categories.map((item, ind) => {
            return (
              <React.Fragment key={`parent_${item._id}`}>
                <div
                  onClick={(e) => this.handleClick(e, item._id)}
                  className="categoriesBox"
                  // id={
                  //   ind === this.state.categories.length - 1
                  //     ? "categories-last"
                  //     : ""
                  // }
                  // onMouseEnter={this.pause}
                  // onMouseOver={this.pause}
                  // onMouseLeave={this.play}
                  value={item._id}
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="radio"
                    name="category"
                    id={`theme${ind}`}
                    // onChange={() => this.handleClickCategory(item._id)}
                  ></input>
                  <label
                    id="theme"
                    htmlFor={`theme${ind}`}
                    className={
                      this.state.clickedCategory === item._id ? "check" : null
                    }
                  >
                    {/* {i18n.language === "en" ? item.name.en : item.name.ko} */}
                    {item.name.en}
                  </label>
                </div>
              </React.Fragment>
            );
          })}
        </Flickity>
      </div>
    );
  }
}

export default CategorySlider;
