import React, { Component } from "react";
import { connect } from "react-redux";
import "./JobManagement.scss";
import i18n from "../i18n";
import Media from "react-media";
import JobDialog from "../Main/Job/JobDialog/JobDialog";
import DelConfirmDialog from "../Component/DelConfirmDialog/DelConfirmDialog";
import Pagination from "../Component/Pagination/Pagination";
import {
  GetFavoritesList,
  GetSupportList,
  PostCancelApply,
  SetUnFavoriteJob,
  GetJobDetailInfo,
  GetResume,
  getOtherJobs,
  PostJobApply,
  SetFavoriteJob,
  JobViewCount,
} from "../Api/Job/job";
import { SetFollow, SetUnFollow } from "../Api/Follow/Follow";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import JobTable from "./JobTable";
import { GetUserToken } from "../modules/userAuth";

class JobManagement extends Component {
  state = {
    jobDialogOpen: false,
    otherJobs: 0,
    resume: null,
    otherJobsSkip: 0,
    otherJobsLimit: 20,
    err: false,
    errMsg: "",
    isPage: false,
    supproted: false,
    jobInfo: {},
    applyCancel: false,
    delConfirmDialogOpen: false,
    headerText: "",
    bodyText: "",
    render: true,
    supportList: [],
    favorites: [],
    isASC: false,
    selectNav: 1,
    filterOpen: false,
    selectedFilter: "",
    page: 1,
    appliedOrder: 0,
    isOrder: 0,
    bookmarkOrder: 0,
    tabChangeLoading: false,
    filterList: [
      {
        id: 1,
        title: i18n.t("jobBookmark.history.filter1"),
      },
      {
        id: 2,
        title: i18n.t("jobBookmark.history.filter2"),
      },
      {
        id: 3,
        title: i18n.t("jobBookmark.history.filter3"),
      },
    ],
    selectedJob: "",
    bookmarkfilter: [
      {
        id: 1,
        title: i18n.t("jobBookmark.bookmark.filter1"),
      },
      {
        id: 2,
        title: i18n.t("jobBookmark.bookmark.filter2"),
      },
      {
        id: 3,
        title: i18n.t("jobBookmark.bookmark.filter3"),
      },
    ],
  };
  pagination = {
    support: {
      page: 1,
      total: 0,
    },
    bookmark: {
      page: 1,
      total: 0,
    },
  };

  token = GetUserToken();
  viewCount = "";
  limit = 10;
  Date = "";
  componentDidMount = () => {
    if (this.token) {
      this.setState({ tabChangeLoading: true }, async () => {
        this.getFavoritesList();
        this.getSupportList();
      });
    } else {
      this.props.history.push("/not-found");
    }
  };
  setTime = (id) => {
    let sttDate = new Date().getSeconds();
    const endDate = this.Date + 3;
    if (sttDate === endDate) {
      this.viewCount(id);
    }
  };
  viewCount = async (id) => {
    try {
      await JobViewCount(id);
      clearInterval(this.viewTimer);
    } catch (e) {
      clearInterval(this.viewTimer);
    }
  };
  handleBookMark = (id) => {
    const token = GetUserToken();
    if (token) {
      try {
        if (id) {
          this.handleOtherBookMark(id);
        } else {
          this.handleJobBookMark(this.state.jobInfo._id);
        }
      } catch (e) {}
    } else {
      this.props.history.push("/login");
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleOtherBookMark = async (id) => {
    const otherJobs = this.state.otherJobs;
    otherJobs.list = otherJobs.list.map((item) => {
      if (item._id === id) {
        item.isFavorite = !item.isFavorite;
      }
      return item;
    });
    this.setState({
      otherJobs,
    });
    try {
      if (!otherJobs.list.find((item) => item._id === id).isFavorite) {
        await SetUnFavoriteJob(id);
      } else {
        await SetFavoriteJob(id);
      }
    } catch (e) {
      if (e && e.response && e.response.data) {
        this.handleAlert(i18n.t("global.aler.login"));
      }
      otherJobs.list = otherJobs.list.map((item) => {
        if (item._id === id) {
          item.isFavorite = !item.isFavorite;
        }
        return item;
      });
      this.setState({
        otherJobs,
      });
      console.dir(e);
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleJobBookMark = async (id) => {
    const jobInfo = this.state.jobInfo;

    const token = GetUserToken();
    if (token) {
      jobInfo.isFavorite = !jobInfo.isFavorite;
      this.setState({ jobInfo });
      try {
        if (!jobInfo.isFavorite) {
          await SetUnFavoriteJob(id ? id : this.state.jobInfo._id);
        } else {
          await SetFavoriteJob(id ? id : this.state.jobInfo._id);
        }
      } catch (e) {
        jobInfo.isFavorite = !jobInfo.isFavorite;
        this.setState({ jobInfo });
      }
    } else {
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleCancelApply = async (id) => {
    const jobInfo = this.state.jobInfo;
    try {
      jobInfo.isApplied = !jobInfo.isApplied;
      await PostCancelApply(id);
      this.getSupportList();
      this.setState({ jobInfo, applyCancel: false });
    } catch (e) {
      console.dir(e);
      jobInfo.isApplied = !jobInfo.isApplied;
      this.setState({ jobInfo });
    }
  };
  handleGetJobInfo = async (id) => {
    const jobInfo = await GetJobDetailInfo(id);
    this.Date = new Date().getSeconds();
    this.viewTimer = setInterval(() => this.setTime(id), 1000);
    this.setState({ jobInfo });
  };
  setPage = (page) => {
    if (this.state.selectNav === 1) {
      this.pagination.support.page = page;
      this.getSupportList();
    } else {
      this.pagination.bookmark.page = page;

      this.getFavoritesList();
    }
  };

  getSupportList = async () => {
    if (!this.state.isOrder) {
      if (!this.state.jobDialogOpen) {
        this.pagination.support.page = 1;
      }
    }
    this.setState({ isLoading: true });

    try {
      const page = (this.pagination.support.page - 1) * this.limit;

      const response = await GetSupportList(
        this.state.selectedFilter,
        this.state.isOrder,
        this.state.isASC,
        page,
        this.limit
      );

      this.pagination.support.total = response.count ? response.count : 0;
      this.setState({ supportList: response.list ? response.list : [] }, () => {
        this.setState({ isLoading: false, tabChangeLoading: false });
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleJobDetailDialogOpen = (id, teamId) => {
    this.setState(
      {
        jobInfo: null,
        otherJobs: -1,
        resume: null,
        otherJobsSkip: 0,
        jobDialogOpen: true,
      },
      () => {
        this.handleGetJobInfo(id);
        this.handleGetOtherJobs(id, teamId, true);
      }
    );
  };
  handleGetOtherJobs = async (id, teamId, initial) => {
    const otherJobs = await getOtherJobs(
      id,
      teamId,
      this.state.otherJobsSkip,
      this.state.otherJobsLimit
    );
    this.setState(
      {
        otherJobs: otherJobs ? otherJobs : { list: [], count: 0 },
      },
      () => {
        if (initial) {
          this.setState({ otherJobsSkip: this.state.otherJobsSkip + 10 });
        }
      }
    );
  };
  getFavoritesList = async () => {
    if (this.state.isOrder) {
      this.pagination.bookmark.page = 1;
    }
    this.setState({ isLoading: true });
    try {
      const page = (this.pagination.bookmark.page - 1) * this.limit;

      const response = await GetFavoritesList(
        this.state.selectedFilter ? this.state.selectedFilter : 1,
        this.state.isOrder,
        this.state.isASC,
        page,
        this.limit
      );

      this.pagination.bookmark.total = response.total ? response.total : 0;
      this.setState({ favorites: response.list }, () =>
        this.setState({ isLoading: false, tabChangeLoading: false })
      );
    } catch (e) {
      console.dir(e);
    }
  };
  handleDelConfirmDialogOpen = (id) => {
    this.setState(
      {
        headerText:
          this.state.selectNav === 1
            ? i18n.t("jobBookmark.history.delete.header")
            : i18n.t("jobBookmark.bookmark.delete.header"),
        bodyText:
          this.state.selectNav === 1
            ? i18n.t("jobBookmark.history.delete.body")
            : i18n.t("jobBookmark.bookmark.delete.body"),
        selectedJob: id,
      },
      () => this.setState({ delConfirmDialogOpen: true })
    );
  };
  renderDay = (endDate) => {
    var now = new Date();
    var then = new Date(endDate);
    var gap = now.getTime() - then.getTime();
    gap = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1;
    return `D-${gap - 1}`;
  };
  checkSorterType = (sorter) => {
    if (sorter === "position") {
      return 1;
    } else if (sorter === "category") {
      return 2;
    } else if (sorter === "views") {
      return 4;
    } else if (sorter === "periodInfo") {
      return 5;
    }
  };
  handleTableChange = async (a, filters, sorter) => {
    let func =
      this.state.selectNav === 1 ? this.getSupportList : this.getFavoritesList;
    let orderType = this.checkSorterType(sorter.columnKey);

    if (sorter.order === "ascend") {
      this.setState({ isOrder: orderType, isASC: true }, () => {
        func();
      });
    } else if (sorter.order === "descend") {
      this.setState({ isOrder: orderType, isASC: false }, () => {
        func();
      });
    } else {
      //this.props.handleSorter(null, null);
    }
  };
  handleUnFavorite = async () => {
    try {
      await SetUnFavoriteJob(this.state.selectedJob);
      this.setState(
        {
          delConfirmDialogOpen: false,
          selectedJob: "",
          tabChangeLoading: true,
        },
        () => {
          this.getFavoritesList();
        }
      );
    } catch (e) {
      console.dir(e);
    }
  };
  handleListCancelApply = async () => {
    try {
      await PostCancelApply(this.state.selectedJob);
      this.setState(
        {
          delConfirmDialogOpen: false,
          selectedJob: "",
          tabChangeLoading: true,
        },
        () => {
          this.getSupportList();
        }
      );
    } catch (e) {
      console.dir(e);
    }
  };
  renderPeriod = (strDate, endDate) => {
    return strDate.substring(0, 10) + "-" + endDate.substring(0, 10);
  };
  handleSelectNav = (selectNav) => {
    this.setState({ selectNav, tabChangeLoading: true }, () => {
      if (this.state.selectNav === 1) {
        this.pagination.bookmark.page = 1;
        this.setState({ supportList: [] }, async () => {
          this.getSupportList();
        });
      } else {
        this.setState({ favorites: [] }, async () => {
          this.pagination.support.page = 1;
          this.getFavoritesList();
        });
      }
    });
  };

  handleSelectFilter = (id, type) => {
    this.setState({ selectedFilter: id });
    if (type === "history") {
      this.getSupportList();
    } else {
      this.getFavoritesList();
    }
  };
  handleRemovePortfolio = async () => {
    this.setState({ resume: null });
  };

  handleGetPortfolio = async (id, teamId) => {
    const token = GetUserToken();
    if (token) {
      this.setState({ resume: -1 });
      try {
        const resume = await GetResume(id, teamId);
        this.setState({ resume });
      } catch (e) {
        let errMsg = "";
        if (
          e.response.data.description ===
          "해당 공고의 팀 소유자는 지원할 수 없습니다."
        ) {
          errMsg = "해당 공고의 소유자는 지원할 수 없습니다.";
        }
        this.setState({
          resume: null,
          err: true,
          errMsg,
        });
      }
    } else {
      this.props.history.push("/login");
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleJobDialogClose = () => {
    clearInterval(this.viewTimer);
    this.setState({ jobDialogOpen: false, supported: false, isPage: false });
  };
  renderFilter = () => {
    if (this.state.selectNav === 1) {
      return (
        <div className="bookmark-filter-container">
          {this.state.filterList.map((filter) => {
            return (
              <span
                onClick={() => this.handleSelectFilter(filter.id, "history")}
              >
                {filter.title}
              </span>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="bookmark-filter-container">
          {this.state.bookmarkfilter.map((filter) => {
            return (
              <span
                onClick={() => this.handleSelectFilter(filter.id, "bookmark")}
              >
                {filter.title}
              </span>
            );
          })}
        </div>
      );
    }
  };
  handleFollow = async () => {
    const token = GetUserToken();
    if (token) {
      const jobInfo = this.state.jobInfo;
      jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
      this.setState({ jobInfo });
      if (jobInfo.teamInfo.isFollowing) {
        try {
          await SetFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      } else {
        try {
          await SetUnFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      }
    } else {
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleJobApply = async (id) => {
    const jobInfo = this.state.jobInfo;
    if (!jobInfo.isApplied) {
      jobInfo.isApplied = !jobInfo.isApplied;

      try {
        await PostJobApply(id);
        this.setState({ supported: true, resume: false });
      } catch (e) {
        console.dir(e);
        jobInfo.isApplied = !jobInfo.isApplied;

        this.setState({
          jobInfo,
          supported: false,
          resume: false,
        });
      }
    } else {
      this.setState({ jobInfo, resume: null, applyCancel: true });
    }
  };
  handleOtherApply = async (id, teamId) => {
    const job = this.state.favorites.find((item) => item.job._id === id);

    if (job.job.isApplied) {
      if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
        let favorites = this.state.favorites;
        favorites = favorites.map((item) => {
          if (item.job._id === id) {
            item.job.isApplied = !item.job.isApplied;
          }
          return item;
        });
        this.setState({ favorites });
        try {
          await PostCancelApply(id);
        } catch (e) {
          console.dir(e);
          favorites = favorites.map((item) => {
            if (item.job._id === id) {
              item.job.isApplied = !item.job.isApplied;
            }
            return item;
          });
        }
        this.setState({ favorites });
      }
    } else {
      this.handleJobDetailResumeDialogOpen(
        id,
        teamId ? teamId : job.job.teamInfo._id
      );
    }
  };
  handleApply = (id) => {
    const token = GetUserToken();
    if (token) {
      if (id) {
        this.handleOtherApply(id);
      } else {
        this.handleJobApply(this.state.jobInfo._id);
      }
    } else {
      this.props.history.push("/login");
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleJobDetailResumeDialogOpen = (id, teamId) => {
    this.setState(
      {
        jobInfo: null,
        otherJobs: -1,
        resume: -1,
        otherJobsSkip: 0,
        jobDialogOpen: true,
        resumeLoaded: true,
      },
      () => {
        clearInterval(this.viewTimer);
        this.handleGetJobInfo(id);
        this.handleGetOtherJobs(id, teamId, true);
        this.handleGetPortfolio(id, teamId);
      }
    );
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return this.state.render ? (
      <>
        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          {this.state.jobDialogOpen ? (
            <JobDialog
              applyCancel={this.state.applyCancel}
              handleCancelApply={this.handleCancelApply}
              applyCancelBack={() => this.setState({ applyCancel: false })}
              err={this.state.err}
              errMsg={this.state.errMsg}
              isPage={this.state.isPage}
              supported={this.state.supported}
              toNextBtn={this.toNextBtn}
              toPrevBtn={this.toPrevBtn}
              nextDataCheck={false}
              prevDataCheck={false}
              open={this.state.jobDialogOpen}
              onClose={this.handleJobDialogClose}
              jobInfo={this.state.jobInfo}
              otherJobs={this.state.otherJobs}
              resume={this.state.resume}
              getResume={this.handleGetPortfolio}
              handleRemovePortfolio={this.handleRemovePortfolio}
              history={this.props.history}
              handleFollow={this.handleFollow}
              handleApply={this.handleApply}
              handleBookMark={this.handleBookMark}
              handleJobDetailResumeDialogOpen={
                this.handleJobDetailResumeDialogOpen
              }
              errBackClick={() => this.setState({ err: false, errMsg: "" })}
              supportedDone={() =>
                this.setState({
                  supported: !this.state.supported,
                  resume: null,
                })
              }
              handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
              skip={this.state.otherJobsSkip}
              limit={this.state.otherJobsLimit}
            />
          ) : null}
          <Media
            queries={{
              tablet: "(max-width:1199px)",
              desktop: "(min-width:1200px)",
            }}
          >
            {(matches) => (
              <div className="job-management-container">
                <DelConfirmDialog
                  open={this.state.delConfirmDialogOpen}
                  onClose={() => this.setState({ delConfirmDialogOpen: false })}
                  dialogHeader={this.state.headerText}
                  dialogText={this.state.bodyText}
                  buttonClick={
                    this.state.selectNav === 1
                      ? this.handleListCancelApply
                      : this.handleUnFavorite
                  }
                />
                <div className="job-management-wrapper">
                  <div className="job-management-title">
                    <span>{i18n.t("jobBookmark.title")}</span>
                  </div>
                  <div className="job-management-nav">
                    <nav
                      className={
                        this.state.selectNav === 1 ? "selectedNav" : ""
                      }
                      onClick={() => this.handleSelectNav(1)}
                    >
                      {i18n.t("jobBookmark.nav1")}
                    </nav>
                    <nav
                      className={
                        this.state.selectNav === 2 ? "selectedNav" : ""
                      }
                      onClick={() => this.handleSelectNav(2)}
                    >
                      {i18n.t("jobBookmark.nav2")}
                    </nav>
                  </div>

                  <div className="job-list-table-container">
                    <JobTable
                      selectNav={this.state.selectNav}
                      handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
                      handleDelConfirmDialogOpen={
                        this.handleDelConfirmDialogOpen
                      }
                      history={this.props.history}
                      handleApply={this.handleApply}
                      favorites={this.state.favorites}
                      supportList={this.state.supportList}
                    />
                    {!this.state.tabChangeLoading ? (
                      this.state.selectNav === 1 ? (
                        <Pagination
                          currentPage={this.pagination.support.page}
                          totalCount={this.pagination.support.total}
                          limit={this.limit}
                          handleActivePage={this.setPage}
                        />
                      ) : (
                        <Pagination
                          currentPage={this.pagination.bookmark.page}
                          totalCount={this.pagination.bookmark.total}
                          limit={this.limit}
                          handleActivePage={this.setPage}
                        />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </Media>
        </Spin>
      </>
    ) : null;
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
export default connect(mapStateToProps)(JobManagement);
