import Main from "../Main/Main";
import Admin from "../Admin/Admin";
import SignUp from "../SignUp/SignUp";
//import JobUpload from "../Main/Job/JobUpload/JobUpload";
import Login from "../Login/Login";
import Discover from "../Main/Discover/Discover";
import Job from "../Main/Job/Job";
import JobManagement from "../JobManagement/JobManagement";
import Tutorial from "../Main/Tutorial/Tutorial";
import CreateTeam from "../Team/CreateTeam/CreateTeam";
import JoinTeam from "../Team/JoinTeam/JoinTeam";
import Intro from "../Introdution/Introdution";
import Faq from "../Faq/Faq";
import Find from "../FindAccount/FindEmail/FindEmail";
import UploadProject from "../UploadProject/UploadProject";
import FindNewPW from "../FindAccount/FindPassword/FindNewPW";
import FindPWCoplete from "../FindAccount/FindPassword/FindComplete";
import MyPage from "../Mypage/OtherPage/MyPage";
import Setting from "../Setting/Setting";
import TeamSetting from "../TeamSetting/TeamSetting";
import JobUpload from "../Main/Job/JobUpload/JobUpload";
import CollectionPage from "../Mypage/OtherPage/CollectionPage/CollectionPage";
import chatTest from "../chatTest/chatTest";
import TeamList from "../TeamList/TeamList";
import TeamPage from "../TeamPage/TeamPage";
import Team from "../Team/Team";
import NotFound from "../NotFound/NotFound";
import Notification from "../Mobile/NotificationCenter/Notification/Notification";
import Message from "../Mobile/NotificationCenter/Message/Message";
import TeamInvite from "../Team/TeamInviteMail/TeamInviteMail";
const routes = [
  {
    path: "/not-found",
    component: NotFound,
  },
  {
    path: "/jobUpload",
    component: JobUpload,
    exact: true,
  },
  {
    path: "/team-invite",
    component: TeamInvite,
    exact: true,
  },
  {
    path: "/",
    component: Main,
    exact: true,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/chats",
    component: chatTest,
    exact: true,
  },
  {
    path: "/collections",
    component: CollectionPage,
    exact: true,
  },
  {
    path: "/sign-up",
    component: SignUp,
    exact: true,
  },
  {
    path: "/job-management",
    component: JobManagement,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },

  {
    path: "/discover",
    component: Discover,
    exact: true,
  },
  {
    path: "/job",
    component: Job,
    exact: true,
  },
  {
    path: "/tutorial",
    component: Tutorial,
    exact: true,
  },

  {
    path: "/find",
    component: Find,
    exact: true,
  },
  {
    path: "/intro",
    component: Intro,
    exact: true,
  },
  {
    path: "/faq",
    component: Faq,
    exact: true,
  },
  {
    path: "/findNewPW",
    component: FindNewPW,
    exact: true,
  },
  {
    path: "/findPWComplete",
    component: FindPWCoplete,
    exact: true,
  },

  {
    path: "/setting",
    component: Setting,
    exact: true,
  },
  {
    path: "/team/setting",
    component: TeamSetting,
    exact: true,
  },
  {
    path: "/project-upload",
    component: UploadProject,
    exact: true,
  },
  {
    path: "/team",
    component: Team,
    exact: true,
  },
  {
    path: "/team/create",
    component: CreateTeam,
    exact: true,
  },

  {
    path: "/team/join",
    component: JoinTeam,
    exact: true,
  },
  {
    path: "/team/list",
    component: TeamList,
    exact: true,
  },
  {
    path: "/notification",
    component: Notification,
    exact: true,
  },
  {
    path: "/message",
    component: Message,
    exact: true,
  },
  {
    path: "/@:linkURL",
    component: MyPage,
    exact: true,
  },
  {
    path: "/team/@:linkURL",
    component: TeamPage,
    exact: true,
  },
];

function page(role) {
  return routes.filter((r) => {
    return !r.role || r.role === role;
  });
}
export default page;
