import React, { Component } from "react";
import "./LeftBar.scss";
// import { NavLink } from "react-router-dom";
import { message } from "antd";
// import LeftBarItem from "./LeftBarItem";
// import i18n from "../../i18n";
// import profileImg from "../../image/temp/papers.co-az54-kirby-pink-face-cute-illustration-art-40-wallpaper.jpg";
// import settingIcon from "../../image/Admin/admin-setting-black-icon.svg";
import arrowIcon from "../../image/Admin/line-red-arrow-up-icon.svg";

class LeftBar extends Component {
  state = {
    openedCategory: [],
  };

  confirm = () => {
    message.info("Clicked on Yes.");
  };

  onClickLogo = () => {
    this.props.history.push("/");
  };

  handleCategoryOpen = (name) => {
    if (
      this.state.openedCategory.length &&
      this.state.openedCategory.find((item) => item === name)
    ) {
      let categories = this.state.openedCategory;
      categories = categories.filter((item) => item !== name);
      this.setState({ openedCategory: categories });
    } else {
      let categories = this.state.openedCategory;

      categories.push(name);
      this.setState({ openedCategory: categories });
    }
  };

  isCategoryOpened = (data) => {
    if (
      this.state.openedCategory.length &&
      this.state.openedCategory.find((item) => item === data)
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderCateagories = () => {
    const { path } = this.props.match;
    return this.props.categories.map((item, index) => {
      return (
        <div
          className="category_dropdown"
          onClick={() => this.handleCategoryOpen(item.name)}
          key={index}
        >
          <div className="category">
            <div className="category_dropdown-left">
              <img src={item.img} alt="categoryIcon"></img>
              <span>{item.name}</span>
            </div>
            <img
              style={
                this.isCategoryOpened(item.name)
                  ? null
                  : { transform: "rotate(180deg)" }
              }
              src={arrowIcon}
              alt="arrowIcon"
            />
          </div>
          {this.isCategoryOpened(item.name)
            ? item.subCategories.map((data, index2) => {
                return (
                  <div
                    className={"subCategory_container"}
                    onClick={() =>
                      this.props.history.push(`${path}${data.path}`)
                    }
                    key={index2}
                  >
                    <span>{data.name}</span>
                  </div>
                );
              })
            : null}
        </div>
      );
    });
  };

  render() {
    return (
      <div className="leftBar-container">
        {/* <div className="admin_profile-container">
          <div className="admin_profile-left">
            <div className="profile_img-container">
              <img src={profileImg} alt="profileImg" />
            </div>
            <div className="admin_info">
              <span>Motiontrend</span>
              <span>Hello, User</span>
            </div>
          </div>
          <img src={settingIcon} alt="settingIcon" />
        </div> */}
        <div className="category_container">{this.renderCateagories()}</div>
        {/* <div className="leftBar_footer">
          <div className="btn_container">
            <Popconfirm className="footer_btn" placement="topLeft" title='Are you sure you want to logout now?' onConfirm={this.props.handleLogout} okText="Yes" cancelText="No">
              <span >{i18n.t("global.logout")}</span>
            </Popconfirm>
            <div className="footer_btn">
              <span>Manager history</span>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default LeftBar;
