import React, { Component } from "react";
import "./MessageDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../i18n";
import Button from "../../../Component/Button/Button";
import calendarIcon from "../../../image/job/black-date-icon.svg";
import { renderDate } from "../../../Component/functions";

class MessageDialog extends Component {
	state = {
		modify: false,
		messageInput: "",
	};

	sendMessage = () => {
		console.log(this.props.userInfo);
		this.props.history.push(
			`/chats?userId=${this.props.userInfo.userId}&message=${this.state.messageInput}`
		);
	};

	render() {
		const { onClose, open, userInfo } = this.props;

		return (
			<>
				<Dialog
					fullWidth={this.props.fullWidth}
					className={"msg_dialog"}
					open={open}
					onClose={onClose}>
					<div className="msg_dialog-container">
						<div className="msg_dialog-header">
							<span>{userInfo?.userName} 에게 메시지 보내기</span>
						</div>
						<div className="msg_dialog-body">
							<div className="body-top">
								<span>수신자</span>
								<div className="recipient-info">
									<div
										className="photo"
										style={{
											backgroundImage: `url(${userInfo?.profilePhoto})`,
										}}></div>
									<div className="detailInfo">
										<span>{userInfo?.userName}</span>
										{/* <span>{userInfo?.createdAt}</span> */}
										<div className="applicants-date">
											<img
												src={calendarIcon}
												alt="date"
												style={{ width: "10px", height: "10px" }}
											/>
											<span>
												{renderDate(userInfo?.createdAt)}{" "}
												{i18n.t("teamPage.applicants.applied")}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="body-bottom">
								<textarea
									placeHolder="메시지 내용을 입력해 주세요"
									onChange={(e) =>
										this.setState({ messageInput: e.target.value })
									}
									value={this.state.messageInput}></textarea>
								<span>수신자에게 "채용 정보"로 표시됩니다.</span>
							</div>
						</div>
						<Button
							backColor="white"
							text="메시지 전송"
							btnClick={this.sendMessage}
						/>
					</div>
				</Dialog>
			</>
		);
	}
}

export default MessageDialog;
