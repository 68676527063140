import React, { Component } from "react";
import "./TeamSubscription.scss";
import { GetAvailableTeamList } from "../../../Api/Team/Team";
import i18n from "../../../i18n";
import { ClickAwayListener } from "@material-ui/core";
import RemoveIcon from "../../../image/close-icon.svg";

import { withSnackbar } from "notistack";
import { compose } from "redux";
import { CheckOutlined } from "@ant-design/icons";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";

class TeamSubscription extends Component {
  state = {
    teamList: [],
    teamSearchStr: "",
    selectedTeam: "",
    teamListOpen: false,
    msg: "",
  };

  handleMsgChange = (e) => {
    this.setState({ msg: e.target.value }, () =>
      this.props.handleSetInfo(this.state)
    );
  };
  handleSelectedTeam = (team) => {
    if (team.isJoined) {
      this.handleTeanListClose();
      this.props.enqueueSnackbar(i18n.t("이미 참여중인 팀입니다."), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      this.setState({ selectedTeam: team }, () => {
        this.handleTeanListClose();
        this.props.handleSetInfo(this.state);
      });
    }
  };
  handleTeamListLoad = async () => {
    try {
      const teamList = await GetAvailableTeamList(this.state.teamSearchStr);

      this.setState({ teamList });
    } catch (e) {
      console.dir(e);
    }
  };

  renderTeamList = () => {
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ teamListOpen: false })}
      >
        {this.state.teamList[0] ? (
          <div className="userList-container">
            {this.state.teamList.map((item) => {
              return (
                <div
                  className="userList-box"
                  onClick={() => this.handleSelectedTeam(item)}
                >
                  <ProfilePhoto size={40} src={item.teamPhoto} />
                  <div className="userList-userInfo">
                    <span>{item.name}</span>
                  </div>
                  <div className="userList_linkURL">
                    <span>@{item.linkURL}</span>
                  </div>
                  {item.isJoined ? (
                    <span className="joined_text">
                      <CheckOutlined width="10px" height="10px" /> {"참여중"}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="userList-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            {this.state.teamSearchStr ? (
              <div className="no-data">
                <span>{i18n.t("global.search.empty")}</span>
              </div>
            ) : (
              <div className="no-data">
                <span>{i18n.t("global.search.placeholder")}</span>
              </div>
            )}
          </div>
        )}
      </ClickAwayListener>
    );
  };
  handleSelectedRemove = () => {
    this.setState({ selectedTeam: "" }, () =>
      this.props.handleSetInfo(this.state)
    );
  };
  handleTeamListOpen = () => {
    this.setState({ teamListOpen: true });
  };
  handleTeanListClose = () => {
    this.setState({ teamListOpen: false });
  };
  handleTeamSearch = (e) => {
    this.setState({ teamSearchStr: e.target.value }, () => {
      if (e.target.value) {
        this.handleTeamListLoad();
      }
    });
  };

  render() {
    return (
      <div className="container addInfo-container">
        <div className="addInfo-box">
          <div className="membersFind-input addInfo-columnBox">
            <span>{i18n.t("team.join.team.title")}</span>
            {this.state.selectedTeam ? (
              <div className="selectedTeam-field">
                <div className="selectedTeam-box">
                  <span>{this.state.selectedTeam.name}</span>
                  <img
                    alt="removeBtn"
                    src={RemoveIcon}
                    onClick={this.handleSelectedRemove}
                  />
                </div>
              </div>
            ) : (
              <input
                type="text"
                placeholder={i18n.t("team.join.placeholder")}
                onChange={this.handleTeamSearch}
                onClick={this.handleTeamListOpen}
              />
            )}
            <div className="teamSearchResultContainer">
              {this.state.teamListOpen ? this.renderTeamList() : null}
            </div>
          </div>
          <div className="join_team-msg">
            <span>{i18n.t("team.join.team.msg")}</span>
            <textarea
              type="text"
              wrap="hard"
              cols="20"
              onChange={this.handleMsgChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withSnackbar)(TeamSubscription);
