import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PreviewIcon from "../image/UploadToolIcon/modify-icon-12.svg";
import UploadIcon from "../image/UploadToolIcon/modify-upload-icon.svg";
import "./UploadProject.scss";
import "./Quill.scss";
import PreviewDialog from "./PreviewDialog/PreviewDialog";
import MobileDragModla from "./MobileDNDModal/MobileDNDModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EmbedDialog from "./UrlModal/UrlModal";
import MobileAddIcon from "../Mobile/Project-upload/modify_add_black_icon.svg";
import UploadSettingDialog from "./UploadSetting/UploadSetting";
import SoundUploadDialog from "./SoundUpload/SoundUploadDialog";
import AlertDialog from "../Component/OneButtonDialog/OneButtonDialog";
import TextIcon from "../image/ProjectUpload/modify-icon-4.svg";
import VideoIcon from "../image/ProjectUpload/modify-icon-3.svg";
import AudioIcon from "../image/ProjectUpload/modify-icon-2.svg";
import ImageIcon from "../image/ProjectUpload/modify-icon-1.svg";
import TagDelIcon from "../image/ProjectUpload/xbox-icon.svg";
import ScreenEdit from "../image/ProjectUpload/screen-edit-white-icon.svg";
import ScreenDel from "../image/ProjectUpload/x-white-icon.svg";
import MobileToolBar from "./MobileToolBar/MobileToolBar";
import MobileColorPicker from "./MobileColorPicker/MobileColorPicker";
import M_DraftIcon from "../Mobile/Project-upload/top_sequence_gray_icon.svg";
import ReactQuill from "react-quill";
import i18n from "../i18n";
import SvgColor from "react-svg-color";
import ArrowIcon from "../../src/image/feather/chevron-left.svg";
import {
  ProjectCreate,
  CreateData,
  ThumbnailUpload,
  UploadSuccess,
  CreateDataFailed,
} from "../Api/Project/project";
import "react-quill/dist/quill.snow.css";
import LoaderDialog from "../Component/UploadLoaderDialog/UploadLoaderDialog";
import { Prompt } from "react-router-dom";
import { projectEdit } from "../Redux/action/index";
import MobileTextTool from "./MobileTextTool/MobileTextTool";
import Media from "react-media";
import Quill from "quill";
import { withSnackbar } from "notistack";
import query from "query-string";
import { GetUserToken } from "../modules/userAuth";
import { convertURLtoFileObj } from "../Component/functions";
import { GetCategories } from "../Api/Category/Category";
import { ProjectUpload_GAEvent } from "../modules/gaEvents";
const R_Quill = ReactQuill.Quill;
var Font = R_Quill.import("formats/font");
Font.whitelist = [
  "",
  "serif",
  "monospace",
  "montserrat",
  "montserratR",
  "montserratM",
];
R_Quill.register(Font, true);
var Size = R_Quill.import("attributors/style/size");
Size.whitelist = [];
for (let i = 8; i <= 60; i++) {
  Size.whitelist.push(`${i}px`);
}
R_Quill.register(Size, true);
class UploadProject extends Component {
  constructor(props) {
    super(props);
    this.mobileToolBar = React.createRef();
  }
  state = {
    selectedUser: [],
    categories: [],
    selectedCategory: "CC BY-ND",
    selecLicence: "",
    thumbnail: "",
    thumbnailPreview: "",
    colorListOpen: false,
    previewDialogOpen: false,
    navGuard: false, //nav가드 on/off
    loaderDialogOpen: false,
    alertDialogOpen: false,
    soundDialogOpen: false,
    embedDialogOpen: false,
    settingDialogOpen: false,
    editSelecOpen: false,
    dragDialogOpen: false, //모바일 dnd팝업
    audioFile: "", //오디오 파일 객체
    audioData: "", //오디오 파일 미리보기
    title: "", //제목
    content: [], //컨텐츠
    preview: [],
    file: "",
    delta: "", //텍스트 에디터 델타
    embed: "", //임베드 코드
    index: null, //컨텐츠 추가할 위치 인덱스
    tagList: [],
    clickList: "",
    msg: "", //에러메시지
    msgTitle: "", //에러메시지 헤더
    isDraft: false,
    isUpload: true,
    clickedContent: null,
    quill: null, //모바일 quill객체를 담을 변수
    color: "",
    bold: false,
    underline: false,
    align: "",
    mode: "",
  };
  quil = null;
  editorParent = null;
  content = {
    //컨텐츠 객체
    id: 0, //컨텐츠 아이디
    item: {}, //컨텐츠 내용
    type: "", //컨텐츠 타입
  };
  clickItem = [];
  GetCategoryList = async () => {
    try {
      const response = await GetCategories();
      this.setState({ categories: response });
    } catch (e) {
      console.dir(e);
    }
  };
  handleThumbnailChange = (thumbnail, thumbnailPreview) => {
    this.setState({ thumbnail, thumbnailPreview });
  };
  handleUpload = async (thumbnail, category, license, isDraft, userIds) => {
    const content = this.state.content;
    const projectInfo = {
      id:
        JSON.stringify(this.props.project) !== "{}"
          ? this.props.project._id
          : null,
      title: this.state.title,
      tags: this.state.tagList,
      category: category?._id,
      license: license ? license : "All Rights Reserved",
      isDraft: isDraft || this.state.isDraft,
      userIds,
    };
    console.log(projectInfo);
    if (JSON.stringify(this.props.project) === "{}") {
      delete projectInfo.id;
    }

    if (!projectInfo.isDraft) {
      if (!projectInfo.title || projectInfo.title === "") {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning1"), {
          variant: "warning",
          key: "noData-title",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
      if (!projectInfo.category) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning2"), {
          variant: "warning",
          key: "noData-category",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
      if (!projectInfo.license || !projectInfo.license.length) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning3"), {
          variant: "warning",
          key: "noData-license",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
      if (!content || !content.length) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning4"), {
          variant: "warning",
          key: "noData-content",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
      if (this.state.mode !== "Edit" && !thumbnail) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning5"), {
          variant: "warning",
          key: "noData-thumbnail",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        return;
      }
    }

    try {
      this.setState({ loaderDialogOpen: true, isUpload: false });

      const projectId = await ProjectCreate(projectInfo);

      if (projectId) {
        try {
          if (thumbnail) {
            await ThumbnailUpload(projectId, thumbnail);
          }
          await CreateData(projectId, content);
          if (!projectInfo.isDraft) {
            ProjectUpload_GAEvent("upload");
          } else {
            ProjectUpload_GAEvent("draft");
          }
        } catch (e) {
          await CreateDataFailed(projectId);
        }
      }

      await UploadSuccess(projectId);
      this.props.enqueueSnackbar(i18n.t("project.upload.sucess"), {
        variant: "success",
        key: "project-uploaded",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      this.setState(
        { loaderDialogOpen: false, navGuard: false, isUpload: true },
        () => {
          this.props.projectEdit({});
          this.props.history.push("/");
        }
      );
    } catch (e) {
      console.dir(e);
      this.setState({ loaderDialogOpen: false, isUpload: true });
      // alert(i18n.t("global.error.server.msg"));
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handlePreviewDialogClose = () => {
    this.setState({ previewDialogOpen: false });
  };
  onClickEditorAway = (e) => {
    console.log(e);
    // e.preventDefault();
    if (e.target.className === "delete") {
      return;
    }
    if (this.editorParent) {
      if (!this.editorParent.contains(e.target)) {
        const editor = this.reactQuillRef.getEditor();
        this.quil = this.reactQuillRef;
        const content = this.state.content.map((item) => {
          if (item.id === this.state.clickList) {
            item.text = editor.container.firstChild.innerHTML;
            item.item.file = item.text;
            item.item.delta = editor.getContents();

            //  this.setState({ bold: false, underline: false, align: "" });
          }
          return item;
        });

        this.setState({ clickedContent: null, content });
      }
    } else {
      if (!this.state.colorListOpen) {
        this.setState({ clickedContent: null });
      }
    }
    // else if (this.clickedItem) {
    //   if (!this.clickedItem.contains(e.target)) {
    //     this.setState({ clickedContent: null });
    //   }
    // }
  };

  handleProjectTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  modules = {
    toolbar: {
      container: [
        //[{ font: ["", "serif", "monospace", "montserrat", "montserratR"] }],
        [{ font: Font.whitelist }],
        ["bold", "underline", "italic"],
        //[{ list: "ordered" }, { list: "bullet" }],
        [
          { color: [] },
          {
            size: Size.whitelist,
          },
        ],
        [{ align: [] }],
      ],
    },
  };
  mobileMobules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  mobile = new Quill("#editor", this.mobileMobules);
  format = [
    "font",
    "bold",
    "italic",
    "underline",
    "list",
    "size",
    "bullet",
    "color",
    "align",
  ];

  handleContentPush(list, index) {
    const content = this.state.content;
    if (index === null) {
      content.push(list);
    } else {
      content.splice(index + 1, 0, list);
    }
    this.setState({ content }, () => (this.content.id += 1));
    this.handleNavGuardCheck();
  }
  isContentTypeCheck = (index) => {
    //const editor = this.reactQuillRef.getEditor();
    const content = this.state.content;
    if (content[index]) {
      if (content[index + 1] && content[index + 1].type === "text") {
        this.setState({ clickedContent: content[index + 1] }, () => {
          const editor = this.reactQuillRef.getEditor();

          editor.setSelection(editor.getLength());
        });
        return true;
      } else if (content[index].type === "text") {
        this.setState({ clickedContent: content[index] }, () => {
          const editor = this.reactQuillRef.getEditor();

          editor.setSelection(editor.getLength());
        });
        return true;
      } else {
        return false;
      }
    } else {
      if (
        content[content.length - 1] &&
        content[content.length - 1].type === "text"
      ) {
        this.setState({ clickedContent: content[content.length - 1] }, () => {
          const editor = this.reactQuillRef.getEditor();

          editor.setSelection(editor.getLength());
        });
        return true;
      } else {
        return false;
      }
    }
  };
  handleChangeCategory = (item) => {
    this.setState({ selectedCategory: item });
  };
  handleAddContent = (type, list, e) => {
    const content = this.state.content;
    const index = this.state.index;
    list.type = type;
    if (e) {
      e.stopPropagation();
    }
    switch (type) {
      case "image":
        this.handleContentPush(list, index);
        break;
      case "audio":
        this.handleContentPush(list, index);
        break;
      case "video":
        this.handleContentPush(list, index);
        break;
      case "text":
        if (this.isContentTypeCheck(index)) {
          // this.setState({ clickedContent: content[index] });
          return;
        } else {
          if (index === null) {
            content.push(JSON.parse(JSON.stringify(list)));
          } else {
            content.splice(
              index + 1,
              0,
              JSON.parse(JSON.stringify(this.content))
            );
          }
          this.setState({ content }, () => {
            this.content.id += 1;
          });
        }

        this.handleNavGuardCheck();
        break;
      default:
        break;
    }
  };

  handleFileOnChange = (e) => {
    e.preventDefault();
    const reset = document.getElementById("inputType_img");
    const content = JSON.parse(JSON.stringify(this.content));
    let reader = new FileReader();
    let img = new Image();
    let file = e.target.files[0];
    const height = 500;
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        img.src = e.target.result;
        this.setState({ loaderDialogOpen: true }, () => {
          img.onload = () => {
            if (img.width <= height) {
              this.setState(
                {
                  msgTitle: i18n.t("img.uploadAlert.title"),
                  msg: i18n.t("img.uploadAlert.text", { height }),
                  alertDialogOpen: true,
                  loaderDialogOpen: false,
                  embedDialogOpen: false,
                  soundDialogOpen: false,
                },
                () => {
                  reset.value = "";
                }
              );
            } else {
              this.setState(
                {
                  loaderDialogOpen: false,
                  file,
                  preview: reader.result,
                },
                () => {
                  content.item.file = reader.result;
                  content.data = file;
                  reset.value = "";
                  this.handleAddContent("image", content);
                }
              );
            }
          };
        });
      };
    } else {
      console.log("파일없음");
    }
  };
  handleColorListOpen = (e) => {
    //e.preventDefault();

    this.setState({
      colorListOpen: !this.state.colorListOpen,
    });
  };
  handleChange = (html, index) => {
    const content = this.state.content;
    content[index].item.delta = this.state.delta;
    content[index].item.text = html;
    this.setState({ content });
  };
  loadEmbedData = (data) => {
    this.setState(
      {
        embed: data,
        embedDialogOpen: false,
      },
      () => this.handleVideoUpload()
    );
  };
  handleNavGuardCheck = () => {
    if (this.state.content[0]) {
      this.setState({ navGuard: true });
    } else {
      this.setState({ navGuard: false });
    }
  };
  mobileAudioUpload = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const fileSize = file.size;
    const maxSize = 10 * 1024 * 1024; //100MB용량 제한
    const fileName = file.name
      .slice(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (file) {
      if (fileSize > maxSize || (fileName !== "wav" && fileName !== "mp3")) {
        alert("10mb 미만, wav,mp3 파일만 등록가능합니다");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState(
            {
              audioFile: file,
              audioData: reader.result,
            },
            () => {
              this.setState(
                { soundDialogOpen: true, embedDialogOpen: false },
                () => (e.target.files = null)
              );
            }
          );
        };
      }
    }
  };
  loadAudioData = (data, src) => {
    const content = JSON.parse(JSON.stringify(this.content));
    content.data = data;
    content.item.file = src;

    this.setState(
      {
        data,
        file: src,
        soundDialogOpen: false,
      },
      () => {
        this.handleAddContent("audio", content);
      }
    );
  };
  handleClickLicense = (e) => {
    this.setState({
      selecLicence: e,
    });
  };
  handleVideoUpload = () => {
    const content = JSON.parse(JSON.stringify(this.content));
    content.item.src = this.state.embed;
    this.handleAddContent("video", content);
    this.handleEmbedDialogClose();
  };
  handleIndexSet = (index, content, e) => {
    this.setState({ index });
    if (content) {
      this.setState({ index }, () => this.handleAddContent("text", content, e));
    }
  };
  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const currnetTag = this.state.content;
    const drggingItemIndex = result.source.index;
    const afterDragItemIndex = result.destination.index;
    const removeTag = currnetTag.splice(drggingItemIndex, 1);
    currnetTag.splice(afterDragItemIndex, 0, removeTag[0]);
    this.setState({ content: currnetTag });
  };

  handleDeleteTag = (index) => {
    let tagList = this.state.tagList;
    tagList.splice(index, 1);
    this.setState({
      tagList,
    });
  };
  handleSelectCopyright = async (e) => {
    const item = e.value;
    this.setState({ selecLicence: item });
  };
  handleTagList = (e) => {
    const tagSpcialRegex = /[~!@#$%^&*()_+|<>?:{}]/;
    const tagList = this.state.tagList;
    const tag = e.target.value.trim();
    if (tag && this.state.tagList.length <= 9) {
      if (e.key === "Enter") {
        if (tag.length > 20) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.text"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tagSpcialRegex.test(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.SpicialText"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tagList.includes(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.overlap"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else {
          tagList.push(tag);
          this.setState({ tagList });
          e.target.value = "";
        }
      }
    }
  };
  handleAddCollaborator = (user) => {
    let selectedUser = this.state.selectedUser;
    selectedUser.push(user);
    this.setState({ selectedUser });
  };
  handleImageChange = (e) => {
    e.preventDefault();
    const content = this.state.content;
    let reader = new FileReader();
    let img = new Image();
    let file = e.target.files[0];
    const height = 500;
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        img.src = e.target.result;
        this.setState({ loaderDialogOpen: true }, () => {
          img.onload = () => {
            if (img.width <= height) {
              this.setState({
                msgTitle: i18n.t("img.uploadAlert.title"),
                msg: i18n.t("img.uploadAlert.text", { height }),
                alertDialogOpen: true,
                loaderDialogOpen: false,
              });
            } else {
              this.setState(
                {
                  loaderDialogOpen: false,
                  file,
                  preview: reader.result,
                },
                () => {
                  content[this.state.clickedContent.id].item.file =
                    this.state.preview;
                  content[this.state.clickedContent.id].data = file;
                  this.setState({ content, editSelecOpen: false });
                }
              );
            }
          };
        });
      };
    } else {
      console.log("파일없음");
    }
  };
  handleRemoveIcon = (e) => {
    let content = this.state.content;
    content = content.filter((item) => item.id !== e.id);

    this.setState({ content, clickedContent: null }, () => {
      this.handleNavGuardCheck();
    });
  };
  handleClickMenu = (id) => {
    switch (id) {
      case 1:
        this.changeImage.click();
        break;
      case 2:
        this.setState({ editSelecOpen: false }, () =>
          this.handleRemoveIcon(this.state.clickedContent)
        );
        break;
      default:
      // do nothing
    }
  };

  handleClickedContent = (proj, e) => {
    e.stopPropagation();

    this.setState({ clickedContent: proj });
  };

  handleGetContent = () => {
    const ref = this.reactQuillRef;
    if (ref) {
      const quill = ref.getEditor();
      const content = quill.getFormat();

      if (JSON.stringify(content) !== "{}") {
        this.setState({
          color: content.color ? content.color : "",
          bold: content.bold ? content.bold : false,
          underline: content.underline ? content.underline : false,
          align: content.align ? content.align : "",
        });
      } else if (JSON.stringify(content) === "{}") {
        this.setState({ bold: "", color: "", align: "" });
      }
    }
  };

  handleQuillEditorSet = () => {
    const ref = this.reactQuillRef;
    if (ref) {
      const quill = ref.getEditor();
      this.setState({ quill });
    }
  };
  handleAlignSet = (align) => {
    let quill = this.state.quill;
    this.setState({ align });
    if (quill) {
      quill.format("align", align);
    }
  };
  handleEffectSet = (type, effect) => {
    let quill = this.state.quill;
    if (quill) {
      this.setState({ [type]: effect });
      quill.format(type, effect);
    }
  };
  showColorPicker = (value) => {
    let quill = this.state.quill;
    this.handleColorListOpen();
    if (quill) {
      quill.format("color", value);
    }
  };

  handleContentChange = (e, evt) => {
    evt.stopPropagation();

    this.setState({ clickedContent: e }, () => {
      if (e.id !== this.state.clickedContent.id) {
        const editor = this.reactQuillRef.getEditor();
        editor.setSelection(editor.getLength());
      }
    });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleAlertDialogClose = () => {
    this.setState({ alertDialogOpen: false });
  };
  handleSoundDialogClose = () => {
    this.setState({ soundDialogOpen: false });
  };
  handleEmbedDialogClose = () => {
    this.setState({ embedDialogOpen: false });
  };
  handleSettingDialogOpen = (mobile) => {
    if (!mobile) {
      // if (!this.state.title) {
      //   this.props.enqueueSnackbar(i18n.t("project.upload.warning1"), {
      //     variant: "warning",
      //     key: "noData-title",
      //     autoHideDuration: 5000,
      //     preventDuplicate: true,
      //     anchorOrigin: {
      //       vertical: "bottom",
      //       horizontal: "right",
      //     },
      //   });
      // }
      if (!this.state.content[0]) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning4"), {
          variant: "warning",
          key: "project.upload.warning4",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        this.setState({ settingDialogOpen: true });
      }
    } else {
      if (!this.state.content[0]) {
        this.props.enqueueSnackbar(i18n.t("project.upload.warning4"), {
          variant: "warning",
          key: "project.upload.warning4",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        this.setState({ isDraft: false, settingDialogOpen: true });
      }
    }
  };

  handleDraftUpload = () => {
    if (this.state.title === "") {
      this.setState({ title: "제목없음" });
    }
    if (this.state.content[0]) {
      this.setState({ isDraft: true }, () => this.handleUpload());
    } else {
      this.props.enqueueSnackbar(i18n.t("project.upload.warning4"), {
        variant: "warning",
        key: "project.upload.warning4",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleSettingDialogClose = () => {
    this.setState({ settingDialogOpen: false });
  };
  handleLoaderDialogClose = () => {
    this.setState({ loaderDialogOpen: false });
  };
  componentDidUpdate = () => {
    if (!this.props.isLogined) {
      this.setState({ navGuard: false }, () => {
        this.handleAlert(i18n.t("global.alert.login"), {
          variant: "error",
          key: i18n.t("global.alert.login"),
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        this.props.history.push("/login");
      });
    }

    if (this.state.navGuard) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  handleContentSet = (content) => {
    this.setState({ content, dragDialogOpen: false });
  };

  editContent = () => {
    this.setState(
      {
        tagList: this.props.project.tags,
        content: this.props.project.contents,
        title: this.props.project.title,
      },
      () =>
        this.state.content.map(async (item, index) => {
          const content = this.state.content;

          delete content[index]._id;
          content[index].id = index;

          if (item.type === "image" && item.item.data) {
            const fileObj = await convertURLtoFileObj(item.item.data, "image");

            content[index].data = fileObj;
          } else if (item.type === "audio") {
            const fileObj = await convertURLtoFileObj(item.item.data, "audio");
            content[index].data = fileObj;
          }
          this.setState({ content, index });
          this.handleNavGuardCheck();
        })
    );
    this.content.id += this.props.project.contents.length;
  };
  componentWillUnmount = () => {
    this.setState({ navGuard: false });

    window.removeEventListener("click", this.onClickEditorAway);
  };
  componentDidMount = () => {
    ProjectUpload_GAEvent("render");
    const qs = query.parse(this.props.history.location.search);
    this.setState({ mode: qs.mode });
    this.GetCategoryList();
    window.addEventListener("click", this.onClickEditorAway, false);

    const token = GetUserToken();

    if (token) {
      this.setState({ content: [], tagList: [] }, () => {
        if (JSON.stringify(this.props.project) !== "{}") {
          this.editContent();
        }
      });
    }
  };
  handleRemoveUser = (index) => {
    let selectedUser = this.state.selectedUser;
    selectedUser.splice(index, 1);
    this.setState({ selectedUser });
  };
  handlePreviewDialogOpen = () => {
    if (this.state.content[0]) {
      this.setState({ previewDialogOpen: true });
    } else {
      this.props.enqueueSnackbar(
        i18n.t("최소 1개 이상의 콘텐츠를 먼저 추가해 주세요"),
        {
          variant: "warning",
          key: "project.upload.contents",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.handleOnDragEnd}>
        <Droppable droppableId="tags">
          {(provided) => (
            <Media
              queries={{
                tablet: "(max-width:599px)",
                desktop: "(min-width:600px)",
              }}
            >
              {(matches) => (
                <div className="main-container upload">
                  <Prompt
                    when={this.state.navGuard}
                    message="입력된 값은 저장되지않습니다 정말 페이지를 나가시겠습니까??"
                  />
                  {this.state.embedDialogOpen ? (
                    <EmbedDialog
                      open={this.state.embedDialogOpen}
                      onClose={this.handleEmbedDialogClose}
                      load={this.loadEmbedData}
                    />
                  ) : null}
                  {this.state.previewDialogOpen ? (
                    <PreviewDialog
                      title={this.state.title}
                      userInfo={this.props.myInfo}
                      open={this.state.previewDialogOpen}
                      onClose={this.handlePreviewDialogClose}
                      projInfo={this.state.content}
                      tags={this.state.tagList}
                    />
                  ) : null}

                  {matches.tablet && this.state.dragDialogOpen && (
                    <>
                      <MobileDragModla
                        handleContentSet={this.handleContentSet}
                        open={this.state.dragDialogOpen}
                        onClose={() => this.setState({ dragDialogOpen: false })}
                        dragContent={JSON.parse(
                          JSON.stringify(this.state.content)
                        )}
                      />
                    </>
                  )}
                  <MobileColorPicker
                    handleColorSet={this.showColorPicker}
                    open={this.state.colorListOpen}
                    onClose={this.handleColorListOpen}
                  ></MobileColorPicker>

                  <UploadSettingDialog
                    handleAddCollaborator={this.handleAddCollaborator}
                    handleRemoveUser={this.handleRemoveUser}
                    handleThumbnailChange={this.handleThumbnailChange}
                    handleChangeCategory={this.handleChangeCategory}
                    handleSelectCopyright={this.handleSelectCopyright}
                    categories={this.state.categories}
                    thumbnail={this.state.thumbnail}
                    thumbnailPreview={
                      this.state.thumbnailPreview ??
                      this.props.project?.thumbnail
                    }
                    selecLicence={
                      this.state.selecLicence ?? this.props.project?.license
                    }
                    selectedCategory={
                      this.state.selectedCategory ??
                      this.props.project?.category
                    }
                    selectedUser={this.state.selectedUser}
                    open={this.state.settingDialogOpen}
                    onClose={this.handleSettingDialogClose}
                    handleSubmit={this.handleUpload}
                  />

                  {this.state.soundDialogOpen && (
                    <SoundUploadDialog
                      file={this.state.audioFile}
                      data={this.state.audioData}
                      load={this.loadAudioData}
                      open={this.state.soundDialogOpen}
                      onClose={this.handleSoundDialogClose}
                    />
                  )}

                  <AlertDialog
                    onButtonClick={this.handleAlertDialogClose}
                    dialogHeadText={this.state.msgTitle}
                    dialogBodyText1={this.state.msg}
                    open={this.state.alertDialogOpen}
                    onClose={this.handleAlertDialogClose}
                  />
                  <LoaderDialog
                    dialogHeadText={i18n.t("upload.loader.title")}
                    dialogBodyText1={i18n.t("upload.loader.text")}
                    open={this.state.loaderDialogOpen}
                    onClose={
                      this.state.isUpload ? this.handleLoaderDialogClose : null
                    }
                  />
                  {matches.desktop ? (
                    <div className="project-toolBar">
                      <div className="toolBarContent">
                        <div
                          className="toolContent"
                          onClick={this.handlePreviewDialogOpen}
                        >
                          <img src={PreviewIcon} alt="Preview" />
                          <span>{i18n.t("project.upload.preview")}</span>
                        </div>
                        <div className="rightToolbar">
                          <button
                            className="saveButton"
                            onClick={() => this.handleDraftUpload()}
                          >
                            {i18n.t("project.upload.draftBtn")}
                          </button>
                          <button
                            className="uploadButton"
                            onClick={() => this.handleSettingDialogOpen(false)}
                          >
                            <img src={UploadIcon} alt="Upload" />
                            {i18n.t("project.upload.uploadBtn")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Mobile-header">
                      <div
                        className="header-BackBtn"
                        onClick={() => this.props.history.goBack()}
                        style={{ zIndex: "21" }}
                      >
                        {/* <img
                          style={{ width: "20px", height: "20px" }}
                          src={M_ProjHeaderBack}
                          alt="M_ProjHeaderBack"
                        /> */}
                        <SvgColor
                          svg={ArrowIcon}
                          width={24}
                          height={24}
                          colors={["#fff", "#fff"]}
                        />
                      </div>
                      <span
                        style={{
                          position: "fixed",
                          top: "15px",
                          left: "0",
                          right: "0",
                          borderBottomColor: "rgba(0,0,0,.13)",
                          zIndex: "20",
                          // width: "100%",
                          textAlign: "center",
                        }}
                        className="header-title"
                      >
                        {i18n.t("proejct.upload.headerTitle")}
                      </span>
                      <div style={{ zIndex: "21" }} className="header-rtBtn">
                        <img
                          style={{
                            cursor: `${
                              this.state.content.length >= 2
                                ? "pointer"
                                : "unset"
                            }`,
                            width: "20px",
                            height: "20px",
                            opacity: `${
                              this.state.content.length >= 2 ? "1.0" : "0.5"
                            }`,
                          }}
                          onClick={() =>
                            this.state.content.length >= 2
                              ? this.setState({ dragDialogOpen: true })
                              : null
                          }
                          src={M_DraftIcon}
                          alt="Draft"
                        />
                        <span
                          // className="follow-md"
                          disabled={!this.state.content[0] ? true : false}
                          onClick={
                            this.state.content[0]
                              ? () => this.handleSettingDialogOpen(true)
                              : null
                          }
                          style={{
                            cursor: `${
                              this.state.content.length >= 2
                                ? "pointer"
                                : "unset"
                            }`,
                            opacity: `${
                              this.state.content.length >= 2 ? "1.0" : "0.5"
                            }`,
                          }}
                        >
                          NEXT
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="project-editCanvas">
                    {matches.tablet && !this.state.content[0] ? (
                      <div className="Mobile-contentNull">
                        <img
                          style={{ cursor: "pointer" }}
                          src={MobileAddIcon}
                          alt="MobileAddIcon"
                          onClick={() => {
                            this.mobileToolBar.current.handleImgUpload();
                          }}
                        />
                        <span>{i18n.t("project.upload.addContent")}</span>
                      </div>
                    ) : null}
                    <div className="projectUpload-title">
                      <input
                        spellCheck="false"
                        className="titleInput"
                        placeholder={i18n.t("project.upload.inputTitle")}
                        defaultValue={
                          this.props.project.title
                            ? this.props.project.title
                            : null
                        }
                        onChange={this.handleProjectTitleChange}
                      ></input>
                    </div>

                    <div
                      className="project-editContent"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={
                        this.state.tagList[0]
                          ? { minHeight: "calc(100vh - 309.2px)" }
                          : null
                      }
                    >
                      {this.state.content[0] ? (
                        this.state.content.map((item, index) => {
                          return (
                            <Draggable
                              isDropDisabled={matches.tablet ?? true}
                              isDragDisabled={matches.tablet ?? true}
                              key={item.id}
                              draggableId={`${item.id}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={`${
                                    this.state.clickedContent
                                      ? this.state.clickedContent.id === item.id
                                        ? "clickedContent"
                                        : "project-itemBox"
                                      : "project-itemBox"
                                  }`}
                                  onClick={(e) =>
                                    this.handleClickedContent(item, e)
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="contentsWrap">
                                    {matches.desktop && (
                                      <>
                                        <div
                                          className="deleteBox"
                                          onClick={() =>
                                            this.handleRemoveIcon(item)
                                          }
                                        >
                                          <img
                                            src={ScreenDel}
                                            alt="ContentDelete"
                                          />
                                        </div>
                                        <div className="moveBox">
                                          <img
                                            src={ScreenEdit}
                                            alt="ContentMove"
                                          />
                                        </div>
                                      </>
                                    )}

                                    {item.type === "image" ? (
                                      <img
                                        src={item.item.file || item.item.data}
                                        alt="projectImg"
                                      />
                                    ) : item.type === "audio" ? (
                                      <>
                                        <div
                                          onClick={(e) =>
                                            this.handleClickedContent(item, e)
                                          }
                                          className="audio-wrapper"
                                        ></div>
                                        <audio
                                          src={item.item.file || item.item.data}
                                          controls="controls"
                                          controlsList="nodownload"
                                        ></audio>
                                      </>
                                    ) : item.type === "video" ? (
                                      matches.desktop ? (
                                        <div
                                          className="iframeBox"
                                          id={`iframe_${index}`}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item.item.src +
                                              "<div class='iframeWrapper'></div>",
                                          }}
                                        ></div>
                                      ) : (
                                        <div className="mobileFrameBox">
                                          <div
                                            className="iframeBox"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item.item.src +
                                                "<div class='iframeWrapper'></div>",
                                            }}
                                          ></div>
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className="textEditor-container"
                                        onClick={(e) =>
                                          this.handleContentChange(item, e)
                                        }
                                      >
                                        {this.state.clickedContent === item ? (
                                          <div
                                            className="textEditor-wrapper"
                                            ref={(ref) =>
                                              (this.editorParent = ref)
                                            }
                                          >
                                            {matches.tablet && (
                                              <MobileTextTool
                                                handleColorListOpen={
                                                  this.handleColorListOpen
                                                }
                                                bold={this.state.bold}
                                                underLine={this.state.underline}
                                                color={this.state.color}
                                                align={this.state.align}
                                                id="mobileTextEditor-custom"
                                                handleAlignSet={
                                                  this.handleAlignSet
                                                }
                                                handleEffectSet={
                                                  this.handleEffectSet
                                                }
                                              />
                                            )}
                                            <ReactQuill
                                              onChangeSelection={
                                                !matches.desktop
                                                  ? this.handleGetContent
                                                  : null
                                              }
                                              id="editor"
                                              defaultValue={
                                                item.item.delta ||
                                                item.item.text
                                              }
                                              ref={(el) => {
                                                this.reactQuillRef = el;
                                              }}
                                              onChange={(html) =>
                                                this.handleChange(html, index)
                                              }
                                              onFocus={() =>
                                                this.handleQuillEditorSet()
                                              }
                                              placeholder="여기에 텍스트를 입력해주세요"
                                              theme="snow"
                                              modules={
                                                matches.tablet
                                                  ? this.mobileMobules
                                                  : this.modules
                                              }
                                              formats={this.formats}
                                            ></ReactQuill>
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              className="textBox ql-editor"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  (item.item.file ||
                                                    item.item.text) &&
                                                  item.item.file !==
                                                    "<p><br></p>" &&
                                                  item.item.text !==
                                                    "<p><br></p>"
                                                    ? item.item.file ||
                                                      item.item.text
                                                    : "<span>여기에 텍스트를 입력해주세요</span>",
                                              }}
                                            ></div>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {matches.desktop &&
                                  this.state.content.length - 1 !== index ? (
                                    <div className="addContentNavBar">
                                      <div className="addContent">
                                        <div className="addContentNav-btn">
                                          <input
                                            ref={(input) =>
                                              (this.imageInput = input)
                                            }
                                            type="file"
                                            accept="image/jpg,image/jpeg,image/png,image/gif"
                                            id="inputType_img"
                                            onChange={this.handleFileOnChange}
                                          />
                                          <label
                                            htmlFor="inputType_img"
                                            style={{ zIndex: 10 }}
                                            onClick={() =>
                                              this.handleIndexSet(index)
                                            }
                                          >
                                            <img
                                              src={ImageIcon}
                                              alt="Img Add"
                                            />
                                          </label>
                                        </div>
                                        <div
                                          className="addContentNav-btn"
                                          onClick={() =>
                                            this.setState({
                                              soundDialogOpen: true,
                                            })
                                          }
                                        >
                                          <label
                                            htmlFor="inputType_audio"
                                            style={{ zIndex: 10 }}
                                            onClick={() =>
                                              this.handleIndexSet(index)
                                            }
                                          >
                                            <img
                                              src={AudioIcon}
                                              alt="audio Add"
                                            />
                                          </label>
                                        </div>
                                        <div
                                          className="addContentNav-btn"
                                          onClick={() =>
                                            this.setState({
                                              embedDialogOpen: true,
                                            })
                                          }
                                        >
                                          <label
                                            htmlFor="inputType_video"
                                            style={{ zIndex: 10 }}
                                            onClick={() =>
                                              this.handleIndexSet(index)
                                            }
                                          >
                                            <img
                                              src={VideoIcon}
                                              alt="video Add"
                                            />
                                          </label>
                                        </div>
                                        <div className="addContentNav-btn">
                                          <label
                                            htmlFor="inputType_text"
                                            style={{ zIndex: 10 }}
                                            onClick={(e) =>
                                              this.handleIndexSet(
                                                index,
                                                this.content,
                                                e
                                              )
                                            }
                                          >
                                            <img
                                              src={TextIcon}
                                              alt="textEdit Add"
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <div className="nullEdit-container"></div>
                      )}
                      {provided.placeholder}
                      {matches.desktop && (
                        <div className="project-addContentNav">
                          <div className="addContentNav-btn">
                            <input
                              ref={(input) => (this.imageInput = input)}
                              type="file"
                              accept="image/jpg,image/jpeg,image/png,image/gif"
                              id="inputType_img"
                              onChange={this.handleFileOnChange}
                            />
                            <label
                              htmlFor="inputType_img"
                              onClick={() => this.setState({ index: null })}
                            >
                              <img src={ImageIcon} alt="Img Add" />
                            </label>
                          </div>
                          <div
                            className="addContentNav-btn"
                            onClick={() =>
                              this.setState({
                                soundDialogOpen: true,
                                index: null,
                              })
                            }
                          >
                            <label htmlFor="inputType_audio">
                              <img src={AudioIcon} alt="audio Add" />
                            </label>
                          </div>
                          <div
                            className="addContentNav-btn"
                            onClick={() =>
                              this.setState({
                                index: null,
                                embedDialogOpen: true,
                              })
                            }
                          >
                            <label htmlFor="inputType_video">
                              <img src={VideoIcon} alt="video Add" />
                            </label>
                          </div>
                          <div className="addContentNav-btn">
                            <label
                              htmlFor="inputType_text"
                              onClick={(e) =>
                                this.setState({ index: null }, () =>
                                  this.handleAddContent("text", this.content, e)
                                )
                              }
                            >
                              <img src={TextIcon} alt="textEdit Add" />
                            </label>
                          </div>
                        </div>
                      )}

                      {matches.desktop && (
                        <div className="input_tag_wrap">
                          <span className="input_tag_icon">
                            {i18n.t("project.upload.tags")}
                          </span>

                          <div className="input_tag_inputBox">
                            {this.state.tagList
                              ? this.state.tagList.map((item, index) => {
                                  return (
                                    <div className="tagBox">
                                      <span className="tag">{`${item}`}</span>
                                      <img
                                        src={TagDelIcon}
                                        alt="delete"
                                        onClick={() =>
                                          this.handleDeleteTag(index)
                                        }
                                      />
                                    </div>
                                  );
                                })
                              : null}
                            <div className="nowrap">
                              <strong className="tagSymbol">#</strong>
                              <input
                                type="text"
                                style={{ border: "unset" }}
                                placeholder={
                                  this.state.tagList.length === 0
                                    ? i18n.t("project.upload.tagsInput")
                                    : i18n.t("project.upload.tagsEnter")
                                }
                                onKeyPress={this.handleTagList}
                              ></input>
                            </div>
                          </div>
                          <div className="input_tag_subTitle">
                            {/* <span>{i18n.t("project.upload.tagTitle")}</span> */}
                            <span>{i18n.t("project.upload.tagWarning")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {matches.tablet && (
                    <div className="input_tag_wrap">
                      <div className="tag_input_field">
                        <span>#</span>
                        <input
                          type="text"
                          style={{ border: "unset" }}
                          placeholder={
                            this.state.tagList.length === 0
                              ? i18n.t("project.upload.tagsInput")
                              : i18n.t("project.upload.tagsEnter")
                          }
                          onKeyPress={this.handleTagList}
                        ></input>
                      </div>
                      <div
                        className={`input_tag_inputBox ${
                          this.state.tagList[0] ? null : "nullTag"
                        }`}
                      >
                        {this.state.tagList[0]
                          ? this.state.tagList.map((item, index) => {
                              return (
                                <div className="tagBox">
                                  <span className="tag">{`${item}`}</span>
                                  <img
                                    src={TagDelIcon}
                                    alt="delete"
                                    onClick={() => this.handleDeleteTag(index)}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>

                      <div className="input_tag_subTitle">
                        <span>{i18n.t("project.upload.tagWarning")}</span>
                      </div>
                    </div>
                  )}
                  {matches.tablet && (
                    <MobileToolBar
                      handleEmbedDialogOpen={() =>
                        this.setState({
                          embedDialogOpen: true,
                          soundDialogOpen: false,
                        })
                      }
                      ref={this.mobileToolBar}
                      audioUpload={this.mobileAudioUpload}
                      clickItem={this.state.clickedContent}
                      handleRemoveContent={this.handleRemoveIcon}
                      imgUpload={this.handleFileOnChange}
                      handleAddContent={(e) => {
                        this.setState(
                          {
                            embedDialogOpen: false,
                            soundDialogOpen: false,
                          },
                          () => this.handleAddContent("text", this.content, e)
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </Media>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.projectEditStatus.project,
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
});

const mapDispatchToProps = (dispatch) => ({
  projectEdit: (value) => dispatch(projectEdit(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(UploadProject);
