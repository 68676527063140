import React, { Component } from "react";
import "./AcceptDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../i18n";
import AcceptIcon from "../../../image/accept-subscription-icon.svg";
class AcceptDialog extends Component {
  state = {};

  render() {
    const { onClose, open, headerTitle, bodyText, buttonClick } = this.props;
    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"accept-dialog"}
          open={open}
          onClose={onClose}
        >
          <div className="accept-dialog-container">
            <div className="accept-title">
              <span>{headerTitle}</span>
            </div>
            <div className="accept-body">
              <img src={AcceptIcon} alt="User-accept" />
              <span>{bodyText}</span>
            </div>
            <div className="accept-dialog-btns">
              <button onClick={onClose}>{i18n.t("global.cancel")}</button>
              <button onClick={buttonClick}>
                {i18n.t("team.accept.btn1")}
              </button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default AcceptDialog;
