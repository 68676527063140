import React, { Component } from "react";
import "./CriteriaBox.scss";
import i18n from "../../i18n";

class Criteria extends Component {
  render() {
    return (
      <div className="introduction-criteria">
        {i18n.language === "en" ? (
          <>
            <div className="criteria-header">
              <span>CRITERIA</span>
            </div>
            <div className="criteria-content">
              <span>Motiontrend pick</span>
              <span>
                Latest
                <br />
                Recommended
                <br />
                Selection criteria
                <br />
                Spotlight
                <br />
                New Creators Support
              </span>
              <span>
                Establish a proven standard for motion trends. And if you choose
                the topic you want <br />
                through that standard, I will introduce you to various creations
                of high quality.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="criteria-header">
              <span>선정 기준</span>
            </div>
            <div className="criteria-content">
              <span>모션트렌드 추천</span>
              <span>
                최신
                <br />
                추천
                <br />
                선택 기준
                <br />
                스포트라이트
                <br />
                새로운 크리에이터 지원
              </span>
              <span>
                만약 당신이 우리에게 원하는 것이 있거나 원하는 주제를
                물어본다면, <br />
                우리는 당신에게 높은 퀄리티에 다양한 창조물을 소개할 것입니다.
              </span>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Criteria;
