import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const SetAllFollow = async (followers) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/follow-all`;
  await axios.post(
    url,
    {
      followers,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const GetFollowingList = async (id, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/following?id=${id}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const token = GetUserToken();
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const GetFollowerList = async (id, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/follower?id=${id}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const SetFollow = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/follow`;
  await axios.post(
    url,
    { follower: id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const SetAllUnFollow = async (followers) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/un-follow-all`;
  await axios.post(
    url,
    {
      followers,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
export const SetUnFollow = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/follow/un-follow`;
  await axios.post(
    url,
    { follower: id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
