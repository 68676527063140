import React, { Component } from "react";
import "./CollectionDelDialog.scss";

import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class CollectionDelDialog extends Component {
  render() {
    const {
      onClose,
      open,
      noButton,
      onButtonClick,
      dialogHeadText,
      dialogBodyText1,
      dialogBodyText2,
      dialogButtonText1,
      dialogButtonText2,
    } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"collection-delete-dialog"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="alert-button-dialog-container colletionDel">
              <h1 className="alert-button-dialog-h1">
                {dialogHeadText || i18n.t("global.alert")}
              </h1>
              <div className="alert-button-dialog-text">
                <div>{dialogBodyText1 || null}</div>
                <div>{dialogBodyText2 || null}</div>
              </div>
              <div className="delete-btns">
                {noButton ? null : (
                  <div className="alert-btns">
                    <div className="alert-button-dialog-button">
                      <span onClick={onClose}>
                        {dialogButtonText1 || i18n.t("global.cancel")}
                      </span>
                    </div>
                    <div className="alert-button-dialog-button">
                      <span onClick={onButtonClick}>
                        {dialogButtonText2 || i18n.t("global.check")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(CollectionDelDialog);
