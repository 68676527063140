import React, { Component } from "react";
import "./Link.scss";
import i18n from "../../i18n";
import DropIcon from "../../image/UserSetting/change-location-gray-icon.svg";
import InstaIcon from "../../image/UserSetting/sns-link-gray-icon-1.svg";
import FbIcon from "../../image/UserSetting/sns-link-gray-icon-2.svg";
import PintIcon from "../../image/UserSetting/sns-link-gray-icon-3.svg";
import DribIcon from "../../image/UserSetting/sns-link-gray-icon-4.svg";
import YtIcon from "../../image/UserSetting/sns-link-gray-icon-5.svg";
import TumbIcon from "../../image/UserSetting/sns-link-gray-icon-6.svg";
import VimeoIcon from "../../image/UserSetting/sns-link-gray-icon-7.svg";
import MediumIcon from "../../image/UserSetting/sns-link-gray-icon-8.svg";
import SoundCloudIcon from "../../image/UserSetting/sns-link-gray-icon-9.svg";
import RedArrowIcon from "../../image/UserSetting/enter-comments-red-icon.svg";

import AlertDialog from "../../Component/OneButtonDialog/OneButtonDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
class Link extends Component {
  state = {
    link: "",
    alertDialogOpen: false,
    msg: "",
    alertTitle: "",
    linkInfo: [
      {
        id: 1,
        icon: InstaIcon,
        link: "",
        websiteName: "Instagram",
        text: i18n.t("userSetting.instagram"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 2,
        icon: FbIcon,
        link: "",
        websiteName: "Facebook",
        text: i18n.t("userSetting.facebook"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 3,
        icon: PintIcon,
        link: "",
        websiteName: "Pinterest",
        text: i18n.t("userSetting.pinterest"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 4,
        icon: DribIcon,
        link: "",
        websiteName: "Dribble",
        text: i18n.t("userSetting.dribble"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 5,
        icon: YtIcon,
        link: "",
        websiteName: "Youtube",
        text: i18n.t("userSetting.youtube"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 6,
        icon: TumbIcon,
        link: "",
        websiteName: "Tumblr",
        text: i18n.t("userSetting.tumblr"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 7,
        icon: VimeoIcon,
        link: "",
        websiteName: "Vimeo",
        text: i18n.t("userSetting.vimeo"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 8,
        icon: MediumIcon,
        link: "",
        websiteName: "Medium",
        text: i18n.t("userSetting.medium"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
      {
        id: 9,
        icon: SoundCloudIcon,
        link: "",
        websiteName: "SoundCloud",
        text: i18n.t("userSetting.soundcloud"),
        placeholder: i18n.t("userSetting.placeholder.link"),
      },
    ],
    socialLinks: [],
    itemsToShow: 5,
    expanded: false,
    isSocialUpdated: false,
  };

  constructor() {
    super();
    this.showMore = this.showMore.bind(this);
  }

  componentDidMount = async () => {
    const teamInfo = await this.props.teamInfo;
    try {
      if (teamInfo.linkInfo.length >= 1) {
        this.setState(
          {
            socialLinks: teamInfo.linkInfo,
          },
          () => {
            const socialLinks = this.state.socialLinks;

            socialLinks.forEach((item, i) => {
              item.id = i + 1;
            });
            this.setState({
              socialLInks: socialLinks,
              isSocialUpdated: true,
            });
          }
        );
      }
    } catch (e) {
      console.dir(e);
    }
  };

  showMore() {
    this.state.itemsToShow === 5
      ? this.setState({
          itemsToShow: this.state.linkInfo.length,
          expanded: true,
        })
      : this.setState({ itemsToShow: 5, expanded: false });
  }

  handleOnDragEnd = (result) => {
    if (this.state.isSocialUpdated) {
      if (!result.destination) return;
      const currnetTag = this.state.socialLinks;
      const drggingItemIndex = result.source.index;
      const afterDragItemIndex = result.destination.index;
      const removeTag = currnetTag.splice(drggingItemIndex, 1);
      currnetTag.splice(afterDragItemIndex, 0, removeTag[0]);
      this.setState({ socialLinks: currnetTag }, () =>
        this.props.handleLinkInfoSet(this.state.socialLinks)
      );
    } else {
      if (!result.destination) return;
      const currnetTag = this.state.linkInfo;
      const drggingItemIndex = result.source.index;
      const afterDragItemIndex = result.destination.index;
      const removeTag = currnetTag.splice(drggingItemIndex, 1);
      currnetTag.splice(afterDragItemIndex, 0, removeTag[0]);
      this.setState({ linkInfo: currnetTag }, () =>
        this.props.handleLinkInfoSet(this.state.linkInfo)
      );
    }
  };
  handleChange = (e, index) => {
    if (this.state.isSocialUpdated) {
      const content = this.state.socialLinks;
      this.setState(
        { link: e.target.value },
        () => (content[index].link = this.state.link),
        this.props.handleLinkInfoSet(this.state.socialLinks)
      );
      this.setState({ linkInfo: content });
    } else {
      const content = this.state.linkInfo;
      this.setState(
        { link: e.target.value },
        () => (content[index].link = this.state.link),
        this.props.handleLinkInfoSet(this.state.linkInfo)
      );
      this.setState({ linkInfo: content });
    }
  };
  handleAlertDialogClose = () => {
    this.setState({ alertDialogOpen: false });
  };

  iconFinder = (websiteName) => {
    if (websiteName === "Instagram") {
      return InstaIcon;
    } else if (websiteName === "Facebook") {
      return FbIcon;
    } else if (websiteName === "Pinterest") {
      return PintIcon;
    } else if (websiteName === "Dribble") {
      return DribIcon;
    } else if (websiteName === "Youtube") {
      return YtIcon;
    } else if (websiteName === "Tumblr") {
      return TumbIcon;
    } else if (websiteName === "Vimeo") {
      return VimeoIcon;
    } else if (websiteName === "Medium") {
      return MediumIcon;
    } else if (websiteName === "SoundCloud") {
      return SoundCloudIcon;
    }
    // else {
    //     return OtherIcon
    // }
  };
  render() {
    return (
      <div className="basicInfo-container setting-container">
        <AlertDialog
          onButtonClick={this.handleAlertDialogClose}
          dialogHeadText={this.state.alertTitle}
          dialogBodyText1={this.state.msg}
          open={this.state.alertDialogOpen}
          onClose={this.handleAlertDialogClose}
        />
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId="tags">
            {(provided) => (
              <div
                className="basicInfo-box"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="Title settingSub-title">
                  {i18n.t("userSetting.link")}
                </div>
                {this.state.isSocialUpdated
                  ? this.state.socialLinks
                      .slice(0, this.state.itemsToShow)
                      .map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="userInfo-input-container setting-input"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="userInfo-input-title link-title">
                                  <div>
                                    <img
                                      src={this.iconFinder(item.websiteName)}
                                      alt="social Icon"
                                    />
                                    {item.websiteName}
                                  </div>
                                  {/* <img
                              className="removeIcon"
                              onClick={() => this.handleRemoveLink(item)}
                              src={CloseIcon}
                              alt="remove content"
                            /> */}
                                </div>
                                <div className="userInfo-inputBox">
                                  <img src={DropIcon} alt="Drag and Drop" />
                                  <div className="linkInput-field">
                                    <span>https://</span>
                                    <input
                                      style={{ marginLeft: "3px" }}
                                      maxLength="100"
                                      onKeyUp={(e) =>
                                        this.handleChange(e, index)
                                      }
                                      defaultValue={item.link}
                                      className="InputField"
                                      // placeholder={i18n.t(
                                      //   "userSetting.placeholder.link"
                                      // )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                  : this.state.linkInfo
                      .slice(0, this.state.itemsToShow)
                      .map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="userInfo-input-container setting-input"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="userInfo-input-title link-title">
                                  <div>
                                    <img
                                      src={this.iconFinder(item.websiteName)}
                                      alt="social Icon"
                                    />
                                    {item.websiteName}
                                  </div>
                                  {/* <img
                              className="removeIcon"
                              onClick={() => this.handleRemoveLink(item)}
                              src={CloseIcon}
                              alt="remove content"
                            /> */}
                                </div>
                                <div className="userInfo-inputBox">
                                  <img src={DropIcon} alt="Drag and Drop" />
                                  <input
                                    maxLength="100"
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                    className="InputField"
                                    placeholder={item.placeholder}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                {provided.placeholder}
                <button
                  className="contentMoreBtn"
                  type="button"
                  onClick={this.showMore}
                >
                  <span>
                    {this.state.expanded
                      ? i18n.t("userSetting.lessBtn")
                      : i18n.t("userSetting.moreBtn")}
                  </span>
                  <img
                    src={RedArrowIcon}
                    style={
                      this.state.expanded
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : { transform: "unset" }
                    }
                    alt="lessBtn"
                  />
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default Link;
