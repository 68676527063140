import React, { useState, useMemo, useEffect } from "react";
import Select, { components } from "react-select";
import countryList from "react-select-country-list";
import i18n from "../../i18n";
import DropdownIcon from "../../image/line-black-down-arrow-icon.svg";

function CountrySelector(props) {
  const [value, setValue] = useState("");
  //const [countryCode, setCountryCode] = useState(props.countryCode);
  const options = useMemo(() => countryList().getData(), []);
  // console.log(options, "나라 옵션");

  const changeHandler = (e) => {
    setValue(e);
    props.handleChangeCountry(e);
  };

  useEffect(() => {
    // 사용자 IP를 통해 찾은 국가가 있을 경우는 Default 값을 Props 로 부터 받아옴
    let index = options.findIndex(
      (option) => option.value === props.countryCode
    );
    setValue(options[index]);
  }, [props.countryCode]);

  useEffect(() => {
    if (!value === "") {
      props.handleChangeCountry(value);
    }
  }, [value]);

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        webkitAppearance: "none",
        // -moz-appearance:none;
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#efefef"
          : isFocused
          ? "#efefef"
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "#070707" : "black",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#efefef" : "#efefef"),
        },
      };
    },
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      border: "1px solid #efefef;",
      width: "100%",
      height: "40px",

      "&:hover": {
        display: "flex",
        border: "1px solid #efefef;;",
        width: "100%",
        height: "40px",
      },
    }),
    input: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      alignItems: "center",
      visibility: "visible",
      color: "hsl(0, 0%, 20%)",
      boxSizing: "border-box",
    }),
    valueContainer: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: "100%",
      height: "35px",
      padding: "0 10px",
    }),

    singleValue: () => ({
      maxWidth: "calc(100% - 47px)",
      color: "#070707",
      marginLeft: "2px",
      marginRight: "2px",
      overflow: "hidden",
      position: "absolute",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      top: "50%",
      // -webkit-transform: "translateY(-50%)",
      // -moz-transform: "translateY(-50%)",
      // -ms-transform: "translateY(-50%)",
      transform: "translateY(-50%)",
      boxSizing: "border-box",
    }),
  };

  const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={DropdownIcon}
          alt="arrowIcon"
          style={{ width: "10px", height: "10px" }}
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      id={props?.id}
      styles={props.style ? props.style : customStyles}
      options={options}
      value={value}
      placeholder={i18n.t("indivi.signup.countrySelect")}
      // defaultValue={value}
      noOptionsMessage={() => "No options"}
      onChange={changeHandler}
      menuPortalTarget={document.querySelector("body")}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
    />
  );
}

export default CountrySelector;
