import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { UserOutlined, CheckOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import i18n from "../../i18n";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import { useSnackbar } from "notistack";
function UseList({
  userList,
  handleuserListClose,
  hanldeSelecUser,
  selectedUser,
  searchUser,
  members,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const handleSelectUser = (item, activeUse, aciveMember) => {
    if (activeUse || aciveMember) {
      enqueueSnackbar(i18n.t("이미 추가된 계정입니다."), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      handleuserListClose();
    } else if (item.isJoined) {
      enqueueSnackbar(i18n.t("team.user.list.overlap"), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      handleuserListClose();
    } else {
      hanldeSelecUser(item);
    }
  };
  return (
    <ClickAwayListener onClickAway={handleuserListClose}>
      <div className="userList-container">
        {userList[0] ? (
          userList.map((item, idx) => {
            //check id
            const activeUse = selectedUser.find((e) => e._id === item._id);
            const aciveMember =
              Object.keys(members).length > 0
                ? Object.values(members).find((e) => e._id === item._id)
                : false;

            return (
              <div
                className="userList-box"
                onClick={() => handleSelectUser(item, activeUse, aciveMember)}
                key={idx}
              >
                <div style={{ display: "flex" }}>
                  {item.profilePhoto ? (
                    <div className="userList-profilePhoto">
                      <div
                        style={{
                          borderRadius: "50%",
                          backgroundImage: `url(${item.profilePhoto})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "40px",
                          height: "40px",
                        }}
                      ></div>
                    </div>
                  ) : (
                    <Avatar size={40} icon={<UserOutlined />} />
                  )}
                  <div className="userList-userInfo">
                    <span>{item.userName}</span>
                    <span>{item.email}</span>
                  </div>
                </div>
                <span>
                  {activeUse || aciveMember ? (
                    <>
                      <CheckOutlined width="10px" height="10px" /> {"추가됨"}
                    </>
                  ) : item.isJoined ? (
                    <>
                      <CheckOutlined width="10px" height="10px" /> {"참여중"}
                    </>
                  ) : null}
                </span>
              </div>
            );
          })
        ) : (
          <div
            className="userList-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            {searchUser ? (
              <div className="no-data">
                <span>검색 결과가 없습니다. 😢</span>
              </div>
            ) : (
              <div className="no-data">
                <span>검색어를 입력해 주세요 🔍</span>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default compose(withSnackbar)(UseList);
