import React, { Component } from "react";
import "./Filter.scss";
import CheckBtn from "../../../../image/check-off-box-icon.svg";
import CheckedBtn from "../../../../image/check-on-box-icon.svg";

class Filter extends Component {
  state = {
    checkList: [
      {
        id: 1,
        text: "Name",
      },
      {
        id: 2,
        text: "Recently created",
      },
      {
        id: 3,
        text: "Number of collections",
      },
    ],
    checkedBtn: "",
  };
  handleRadioCheck = (id) => {
    this.setState({ checkedBtn: id });
  };
  render() {
    return (
      <div className="collections-filterArea">
        <div className="collections-title">
          <span>Create a list of collections</span>
        </div>
        <div className="collections-radioBtns">
          {this.state.checkList.map((item) => {
            return (
              <div
                className="radioItems"
                onClick={() => this.handleRadioCheck(item.id)}
              >
                <img
                  src={
                    this.state.checkedBtn === item.id ? CheckedBtn : CheckBtn
                  }
                  alt="check button"
                />
                <span>{item.text}</span>
              </div>
            );
          })}
        </div>
        <div className="collections-btns">
          <button type="button" onClick={this.props.onClose}>
            Back
          </button>
          <button type="button">Check</button>
        </div>
      </div>
    );
  }
}
export default Filter;
