import Admin from "../Admin";
import AllUsers from "./Users/AllUsers/AllUsers";
import AllProject from "./Project/AllProject/AllProject";
import AllJobs from "./Job/AllJobs/AllJobs";
import Report from "./Report/Report";
import Curation from "./Curation/Curation";
const routes = [
  {
    path: "/",
    component: Admin,
    exact: true,
  },
  {
    path: "/allusers",
    component: AllUsers,
    exact: true,
  },
  {
    path: "/allJobs",
    component: AllJobs,
    exact: true,
  },
  {
    path: "/allproject",
    component: AllProject,
    exact: true,
  },

  {
    path: "/curation",
    component: Curation,
    exact: true,
  },
  {
    path: "/report",
    component: Report,
    exact: true,
  },
];

function page(role) {
  return routes.filter((r) => {
    return !r.role || r.role === role;
  });
}
export default page;
