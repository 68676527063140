import React, { Component } from "react";
import "./ShareDialog.scss";
import { compose } from "redux";
import TwitwerIcon from "../../../image/ShareIcons/share-sns-icon-1.svg";
import PinterIcon from "../../../image/ShareIcons/share-sns-icon-2.svg";
import KakaoIcon from "../../../image/ShareIcons/share-sns-icon-3.svg";
import FacebookIcon from "../../../image/ShareIcons/share-sns-icon-4.svg";
import NaverIcon from "../../../image/ShareIcons/share-sns-icon-5.svg";
import CopyIcon from "../../../image/ShareIcons/copy-icon.svg";
import { Dialog } from "@material-ui/core";
import { openSignInWindow } from "../../../Component/HandlePopup/Handlepopup";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";
import { GetProjectList } from "../../../Api/Project/project";
import { withSnackbar } from "notistack";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class ShareDialog extends Component {
  state = {
    icon: [
      {
        id: 1,
        icon: TwitwerIcon,
      },
      {
        id: 2,
        icon: KakaoIcon,
      },
      {
        id: 3,
        icon: PinterIcon,
      },
      {
        id: 4,
        icon: FacebookIcon,
      },
      {
        id: 5,
        icon: NaverIcon,
      },
    ],
    projInfo: {},
  };
  componentDidMount = () => {
    this.projInfoSet();
  };

  copytoClipboard = (userUrl) => {
    navigator.clipboard.writeText(userUrl);
    this.props.enqueueSnackbar(i18n.t("클립보드에 복사 되었습니다."), {
      variant: "success",
      key: "cliipboardto",
      autoHideDuration: 5000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  projInfoSet = async () => {
    try {
      const response = await GetProjectList(this.props.userUrl);

      this.setState({ projInfo: response });
    } catch (e) {}
  };
  fbSetMetaTag = (url) => {
    //set title
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `${this.state.projInfo.title}`);
    //set description
    // document
    //   .querySelector('meta[property="og:description"]')
    //   .setAttribute("content", this.state.projInfo);
    //set images
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", this.state.projInfo.thumbnail);
    //set url
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", url);
    openSignInWindow(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "/",
      this.props.history
    );
  };
  handleSharePage(id, url) {
    switch (id) {
      case 1:
        openSignInWindow(
          `https://twitter.com/intent/tweet?text=${this.state.projInfo.title}&url=${url}`,
          "/",
          this.props.history
        );
        break;
      case 2:
        // alert("준비중인 기능입니다.");
        this.handleAlert("준비중인 기능입니다.");
        break;
      case 3:
        openSignInWindow(
          `https://www.pinterest.com/pin/create/button/?url=${url}&media=${this.state.projInfo.thumbnail}&description=${this.state.projInfo.title}`,
          "/",
          this.props.history
        );
        break;
      case 4:
        this.fbSetMetaTag(url);
        break;
      case 5:
        openSignInWindow(
          `https://share.naver.com/web/shareView.nhn?url=${url}&title=${this.state.projInfo.title}`,
          "/",
          this.props.history
        );
        break;
      default:
      // do nothing
    }
  }
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const { onClose, open } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });
    const userUrl = `https://motiontrend.tv/@${this.props.projLink}${
      this.props.userUrl ? `?projectId=` + this.props.userUrl : ""
    }`;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"shareDialog-dialog"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="alert-button-dialog-container shareDialog">
              <h1 className="share-dialog-haeder">Share</h1>

              <div className="sharePages-list">
                {this.state.icon.map((item, index) => {
                  return (
                    <img
                      key={`share_icon_${index}`}
                      src={item.icon}
                      onClick={() => this.handleSharePage(item.id, userUrl)}
                      alt="icon"
                    />
                  );
                })}
              </div>
              <div className="sharePage-url">
                <input
                  type="text"
                  defaultValue={userUrl}
                  style={{
                    maxWidth: "calc(100% - 16px)",
                    width: "100%",
                    overflow: "hidden",
                  }}
                ></input>

                <img
                  src={CopyIcon}
                  alt="copyButton"
                  onClick={() => {
                    this.copytoClipboard(userUrl);
                  }}
                />
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(ShareDialog);
