import React, { Component } from "react";
import i18n from "../../../../i18n";
import "./AllUsers.scss";
import FilterArea from "../FilterArea/FilterArea";
import SearchResultArea from "./SearchResultArea/SearchResultArea";
import UserInfoDialog from "../UserInfoDialog/UserInfoDialog";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Alert from "../../../../Component/OneButtonDialog/OneButtonDialog";
import {
  GetAllUserList,
  GetAdminUserInfo,
} from "../../../../Api/Admin/User/user";
import { GetUserToken } from "../../../../modules/userAuth";
class AllUsers extends Component {
  state = {
    isUserInfoDialogOpen: false,
    searchString: "",
    searchFilter: null,
    orderType: null,
    isASC: null,
    selectedLimit: { value: "20" },
    list: [],
    userInfo: [],
    currentPage: 1,
    totalItems: null,
    isLoading: false,
    strDate: "",
    endDate: "",
    alertDialogOpen: false,
    alertTitle: "",
    alertMsg: "",
  };

  componentDidMount = () => {
    this.getUserList();
  };

  setCurrentPage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      this.getUserList
    );
  };

  handleAlertShow = (title, msg) => {
    this.setState({
      alertTitle: title,
      alertMsg: msg,
      alertDialogOpen: true,
      loaderDialogOpen: false,
    });
  };

  handlAlertClose = () => {
    this.setState({
      alertDialogOpen: false,
    });
  };

  getUserList = async () => {
    this.setState({
      isLoading: true,
    });
    const form = {};
    form.filter = this.state.searchFilter;
    if (this.state.searchString && this.state.searchString.length > 0) {
      form.searchString = this.state.searchString;
    }
    form.limit = this.state.selectedLimit.value;
    form.skip = (this.state.currentPage - 1) * form.limit;

    form.order = this.state.orderType;
    form.isASC = this.state.isASC;

    // form.strDate = this.state.strDate.toISOString()
    // form.endDate = this.state.endDate.toISOString()
    form.sttDate = this.state.strDate;
    form.endDate = this.state.endDate;

    const token = GetUserToken();
    if (!token) {
      this.props.history.push("/admin");
      return;
    }

    //${process.env.REACT_APP_BASE_URL}
    try {
      const response = await GetAllUserList(
        form.searchString ? form.searchString : "",
        form.skip,
        form.limit,
        form.filter,
        form.order,
        form.isASC,
        form.sttDate,
        form.endDate
      );

      this.setState({
        list: response.users,
        totalItems: response.total,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.dir(e);
    }
  };

  getUserInfo = async (id) => {
    try {
      const response = await GetAdminUserInfo(id);

      return response;
    } catch (e) {
      console.dir("유저 디테일 정보 에러 ", e);
    }
  };

  handleSearch = async () => {
    if (!this.state.searchFilter) {
      this.handleAlertShow(
        i18n.t("필수 값 확인"),
        i18n.t("검색 필터를 선택해 주세요.")
      );
      return;
    } else if (
      this.state.searchString.length === 0 &&
      (!this.state.strDate || !this.state.endDate)
    ) {
      this.handleAlertShow(
        i18n.t("필수 값 확인"),
        i18n.t("검색어 또는 날짜를 입력해 주세요.")
      );
      return;
    } else {
      await this.getUserList();
    }
  };

  handleSorter = async (orderType, isASC) => {
    this.setState({ orderType, isASC }, () => this.getUserList());
  };

  handleSearchStringUpdate = async (term) => {
    this.setState({
      searchString: term,
      strDate: "",
      endDate: "",
    });
  };

  handleSearchStringSelectionTypeUpdate = async (e) => {
    const value = e.value;
    this.setState({ searchString: value });
  };

  handleFilterChange = async (value) => {
    if (value) {
      this.setState({
        searchFilter: 5, // authority 만 필터함
        searchString: value,
        strDate: "",
        endDate: "",
      });
    } else {
      this.setState({
        searchFilter: "",
        searchString: "",
        strDate: "",
        endDate: "",
      });
    }
  };

  handleSearchFilterUpdate = async (e) => {
    const filter = e.value;
    this.setState({
      searchFilter: filter,
      searchString: "",
      strDate: "",
      endDate: "",
    });
  };

  handleSearchDates = async (strDate, endDate) => {
    this.setState({
      strDate: strDate,
      endDate: endDate,
      searchString: "",
    });
  };

  handleClickUserInfoDialogOpen = async (id) => {
    const userInfo = await this.getUserInfo(id);

    this.setState({
      userInfo,
      isUserInfoDialogOpen: true,
    });
  };

  handleUserInfoConfirmDialogclose = async () => {
    await this.getUserList();
    this.setState({
      isUserInfoDialogOpen: false,
    });
  };

  handlePageSizeChange = (current, size) => {
    // console.log(current, size)
    this.setState({ selectedLimit: { value: size } });
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <Alert
          onButtonClick={this.handlAlertClose}
          dialogHeadText={this.state.alertTitle}
          dialogBodyText1={this.state.alertMsg}
          open={this.state.alertDialogOpen}
          onClose={this.handlAlertClose}
        />

        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          <h2>{i18n.t("admin.users.all.title")}</h2>
          <FilterArea
            searchFilter={this.state.searchFilter}
            searchStringUpdated={this.handleSearchStringUpdate}
            searchStringSelectionTypeUpdated={
              this.handleSearchStringSelectionTypeUpdate
            }
            searchFilterUpdated={this.handleSearchFilterUpdate}
            handleSearch={this.handleSearch}
            handleSearchDates={this.handleSearchDates}
          ></FilterArea>
          <SearchResultArea
            totalItems={this.state.totalItems}
            currentPage={this.state.currentPage}
            list={this.state.list}
            getUserList={this.getUserList}
            handlePageChange={this.setCurrentPage}
            handleSorter={this.handleSorter}
            setPageSize={this.state.selectedLimit.value}
            searchStringSelectionTypeUpdated={
              this.handleSearchStringSelectionTypeUpdate
            }
            handleFilterChange={this.handleFilterChange}
            handlePageSizeChange={this.handlePageSizeChange}
            onClickUserInfoDialogOpen={this.handleClickUserInfoDialogOpen}
          ></SearchResultArea>
          <UserInfoDialog
            open={this.state.isUserInfoDialogOpen}
            onClose={this.handleUserInfoConfirmDialogclose}
            userInfo={this.state.userInfo}
          ></UserInfoDialog>
        </Spin>
      </>
    );
  }
}

export default AllUsers;
