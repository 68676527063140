import React, { Component } from "react";
import "./DelConfirmDialog.scss";
import WarningIcon from "../../image/DelteDialogIcon/warning-icon.svg";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class DelConfirmDialog extends Component {
  state = {};

  render() {
    const {
      onClose,
      open,
      noButton,
      dialogHeader,
      dialogText,
      dialogButtonText1,
      dialogButtonText2,
      buttonClick,
    } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"comment_delete-dialog"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="comment_delete-dialog-container">
              <div className="comment_delete-dialog-title">
                <span>{dialogHeader}</span>
              </div>
              <div className="comment_delete-dialog-body">
                <img src={WarningIcon} alt="warning icon" />
                <span>{dialogText}</span>
              </div>

              {noButton ? null : (
                <div className="comment_delete-dialog-btns">
                  <button type="button" onClick={onClose}>
                    {dialogButtonText1 || i18n.t("global.cancel")}
                  </button>
                  <button type="button" onClick={buttonClick}>
                    {dialogButtonText2 || i18n.t("global.check")}
                  </button>
                </div>
              )}
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(DelConfirmDialog);
