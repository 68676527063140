import React, { Component } from "react";
import { connect } from "react-redux";
import "./Main.scss";
import { css } from "@emotion/core";
import loadable from "@loadable/component";
import { GetProjectInfo, GetProjectList } from "../Api/Project/project";
import { GetUserInfo } from "../Api/User/user";
import CategorySlider from "../Component/CategorySlider/CategorySlider";
import i18n from "../i18n";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import ContentDialog from "../Component/ContentDialog/ContentDialog";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ProjectDialog from "../Component/ProjectDialog/ProjectDialog";
import Media from "react-media";
import { handleWindowInfiniteScroll } from "../Component/functions";
import EmptyStates from "../Component/EmptyStates/EmptyStates";
import query from "query-string";
import FadeLoader from "react-spinners/BeatLoader";
// import MoreIcon from "../Mobile/Proejct-dialog/more-horizontal.svg";
import arrowImg from "../image/arrow-gray-icon.svg";
import { ClickAwayListener } from "@material-ui/core";
import { Badge } from "antd";
import {
  SetFollow,
  SetUnFollow,
  SetAllFollow,
  SetAllUnFollow,
} from "../Api/Follow/Follow";
import { GetUserToken } from "../modules/userAuth";
// import SvgColor from "react-svg-color";

const Project = loadable(() => import("../Component/Project/Project"));

class Main extends Component {
  state = {
    isPage: false,
    projectDialogOpen: false,
    contentDialogOpen: false,
    category: "",
    projectList: [],
    timer: "",
    projectInfo: {},
    isDataNullCheck: false,
    isLoading: false,
    isFollow: false,
    isFollowLoading: false,
    isCreators: false,
    dateFilterOpen: false,
    sortFilterOpen: false,
    strDate: "",
    endDate: "",
    dateList: [
      {
        id: 0,
        text: "전체 기간",
      },
      {
        id: 1,
        text: "최근 24시간",
      },
      {
        id: 2,
        text: "최근 1주일",
      },
      {
        id: 3,
        text: "최근 1개월",
      },
      {
        id: 4,
        text: "최근 3개월",
      },
    ],
    sortList: [
      {
        id: 1,
        sort: i18n.t("discover.sort.recommend"),
      },
      {
        id: 3,
        sort: i18n.t("discover.sort.reprod"),
      },
      {
        id: 2,
        sort: i18n.t("discover.sort.latest"),
      },
      // {
      //   id: 2,
      //   sort: i18n.t("discover.sort.discussion"),
      // },
    ],
    items: 0,
    firstRender: false,
    selecSort: { id: 1, sort: i18n.t("discover.sort.recommend") },
    linkURL: "",
    flagsList: [],
    dataLoading: false,
    selecteDate: "전체 기간",
  };

  handleProjectDialogOpen = async (projectId) => {
    try {
      const projectInfo = await GetProjectInfo(projectId);
      this.setState({ projectInfo }, () => {
        this.isAllFollowCheck();
        this.setState({ projectDialogOpen: true });
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleDateRange = (date) => {
    const today = new Date();
    let strDate = today;
    let endDate = new Date();
    if (date.id === 1) {
      endDate = new Date(endDate.setDate(today.getDate() - 1));
    } else if (date.id === 2) {
      endDate = new Date(endDate.setDate(today.getDate() - 7));
    } else if (date.id === 3) {
      endDate = new Date(endDate.setMonth(today.getMonth() - 1));
    } else if (date.id === 4) {
      endDate = new Date(endDate.setMonth(today.getMonth() - 3));
    } else {
      strDate = null;
      endDate = null;
    }
    // this.state.projectList?
    this.setState(
      { projectList: [], selecteDate: date.text, items: 0, strDate, endDate },
      () => this.renderProject()
    );
  };
  handleUnFollow = async (id, creator, index) => {
    const projectInfo = this.state.projectInfo;
    try {
      // this.isFollowCheck(id, false);

      this.setState({ isFollowLoading: false });

      if (
        this.state.isCreators &&
        !creator &&
        projectInfo.uploaderInfo._id !== id
      ) {
        this.setState({ isFollow: false });
      } else {
        if (!creator) {
          projectInfo.uploaderInfo.isFollowing = false;
          this.setState({ projectInfo }, () => this.isAllFollowCheck());
        } else {
          projectInfo.creators[index].isFollowing = false;
          this.setState({ projectInfo }, () => this.isAllFollowCheck());
        }
      }
      await SetUnFollow(id);
    } catch (e) {
      console.dir(e);

      if (!this.state.isCreators) {
        this.setState({ isFollow: true });
      } else {
        projectInfo.uploaderInfo.isFollowing = true;
        this.setState({ projectInfo });
      }
      this.setState({ projectInfo });
      this.setState({ isFollowLoading: false });
    }
  };
  handleAllUnFollow = async () => {
    if (this.state.isCreators) {
      const token = GetUserToken();
      this.setState({ isFollowLoading: true });
      const followerList = JSON.parse(
        JSON.stringify(this.state.projectInfo.creators)
      );
      followerList.push(
        JSON.parse(JSON.stringify(this.state.projectInfo.uploaderInfo))
      );
      let followers = [];

      for (let i = 0; i < followerList.length; i++) {
        if (followerList[i].isFollowing) {
          followers.push(followerList[i]._id);
        }
      }
      if (token) {
        try {
          await SetAllUnFollow(followers);

          this.setState({ isFollowLoading: false, isFollow: false }, () =>
            this.allFollowStateChange(false)
          );
        } catch (e) {
          console.dir(e);
          this.setState({ isFollow: true, isFollowLoading: false }, () =>
            this.allFollowStateChange(true)
          );
        }
      } else {
        this.setState({ isFollow: true, isFollowLoading: false });
        return;
      }
    } else {
      this.handleUnFollow(this.state.projectInfo.uploaderInfo._id);
    }
  };
  handleFollow = async (id, creator, index) => {
    const token = GetUserToken();
    const projectInfo = this.state.projectInfo;
    this.setState({ isFollowLoading: true });

    if (token) {
      try {
        this.setState({ isFollowLoading: false });
        if (
          this.state.isCreators &&
          !creator &&
          projectInfo.uploaderInfo._id !== id
        ) {
          this.setState({ isFollow: true });
        } else {
          if (!creator) {
            projectInfo.uploaderInfo.isFollowing = true;

            this.setState({ projectInfo }, () => this.isAllFollowCheck());
          } else {
            projectInfo.creators[index].isFollowing = true;
            this.setState({ projectInfo }, () => this.isAllFollowCheck());
          }
        }
        await SetFollow(id);
      } catch (e) {
        console.dir(e);
        if (!this.state.isCreators) {
          this.setState({ isFollow: false });
        } else {
          if (!creator) {
            projectInfo.uploaderInfo.isFollowing = false;
            this.setState({ projectInfo });
          } else {
            projectInfo.creators[index].isFollowing = false;
            this.setState({ projectInfo });
          }
        }
        this.setState({ isFollowLoading: false });
      }
    } else {
      this.handleAlert(i18n.t("global.alert.login"));
    }
  };
  handleAllFollow = async () => {
    if (this.state.isCreators) {
      const token = GetUserToken();
      this.setState({ isFollowLoading: true });
      const followerList = JSON.parse(
        JSON.stringify(this.state.projectInfo.creators)
      );
      followerList.push(
        JSON.parse(JSON.stringify(this.state.projectInfo.uploaderInfo))
      );
      let followers = [];

      for (let i = 0; i < followerList.length; i++) {
        if (!followerList[i].isFollowing) {
          followers.push(followerList[i]._id);
        }
      }
      if (token) {
        try {
          await SetAllFollow(followers);
          this.setState({ isFollow: true, isFollowLoading: false }, () => {
            this.allFollowStateChange(true);
          });
        } catch (e) {
          this.setState({ isFollowLoading: false, isFollow: false }, () =>
            this.allFollowStateChange(false)
          );
          console.dir(e);
        }
      } else {
        this.setState({ isFollowLoading: false, isFollow: false });
        return;
      }
    } else {
      this.handleFollow(this.state.projectInfo.uploaderInfo._id);
    }
  };
  allFollowStateChange = (isFollow) => {
    const dialog = document.getElementById("project-dialog-container");
    const projectInfo = this.state.projectInfo;
    projectInfo.uploaderInfo.isFollowing = isFollow;
    if (this.state.isCreators) {
      for (let i = 0; i < projectInfo.creators.length; i++) {
        projectInfo.creators[i].isFollowing = isFollow;
      }
    }
    this.setState({ projectInfo }, () => dialog.click());
  };
  isAllFollowCheck = () => {
    let isFollow = false;
    const followerList = JSON.parse(
      JSON.stringify(this.state.projectInfo.creators)
    );
    followerList.push(
      JSON.parse(JSON.stringify(this.state.projectInfo.uploaderInfo))
    );

    for (let i = 0; i < followerList.length; i++) {
      if (!followerList[i].isFollowing) {
        isFollow = false;
        // this.setState({ isFollow: false });
      } else {
        isFollow = true;
      }
    }
    if (
      this.state.projectInfo.creators[0] &&
      this.state.projectInfo.creators.length
    ) {
      this.setState({ isCreators: true });
    } else {
      this.setState({ isCreators: false });
    }
    this.setState({ isFollow });
  };
  addRenderProject = async () => {
    const limit = 10;
    this.setState({ dataLoading: true });
    try {
      const response = await GetProjectList(
        this.state.selecSort.id,
        limit,
        this.state.category,
        this.state.items,
        null,
        this.state.strDate,
        this.state.endDate
      );

      if (!response[0] && response.length <= limit) {
        this.setState({ isDataNullCheck: true });
      } else {
        let projectList = this.state.projectList;

        let add = projectList.concat(response);
        let index = this.state.items + this.state.projectList.length;

        this.setState({
          projectList: add,
          items: index,
          dataLoading: false,
        });
      }
    } catch (e) {
      this.handleAlert(i18n.t("global.error.server.msg"));
      console.log(e);
      this.setState({ dataLoading: false });
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  timerSet = (timer) => {
    this.setState({ timer });
  };
  renderProject = async () => {
    this.setState({ isLoading: true, dataLoading: true });
    try {
      const response = await GetProjectList(
        this.state.selecSort.id,
        null,
        this.state.category,
        this.state.items,
        null,
        this.state.strDate,
        this.state.endDate
      );

      let projectList = response;

      if (projectList && projectList.length < 20) {
        this.setState({ isDataNullCheck: true });
      } else {
        this.setState({ isDataNullCheck: false });
      }
      this.setState({
        projectList,
        items: projectList.length,
        firstRender: true,
        isLoading: false,
        dataLoading: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, dataLoading: false });
      console.dir(e);
      this.handleAlert(i18n.t("global.error.server.msg"));
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", handleWindowInfiniteScroll);
  }

  componentDidMount = async () => {
    await this.renderProject();
    const qs = query.parse(this.props.location.search);
    const header = document.getElementById("nav_header-container");
    if (qs && qs.projectId && qs.projectId.length === 24) {
      header.classList.add("header-pageView");

      this.setState({ isPage: true }, () =>
        this.handleProjectDialogOpen(qs.projectId)
      );
    } else if (header) {
      header.classList.remove("header-pageView");
    }
    window.addEventListener("scroll", () =>
      handleWindowInfiniteScroll(
        this.state.isDataNullCheck,
        this.state.firstRender,
        this.addRenderProject,
        this.state.dataLoading
      )
    );
  };

  handleContentDialogClose = () => {
    this.setState({ contentDialogOpen: false });
  };

  toOther = async (id) => {
    try {
      const userInfo = await GetUserInfo(id);
      this.props.history.push(`/${userInfo.linkURL}`);
    } catch (e) {
      this.handleAlert(i18n.t("global.error.server.msg"));
      console.log(e);
    }
  };
  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false, isCreators: false });
    const header = document.getElementById("nav_header-container");
    header.classList.remove("header-pageView");
  };

  handleSortList = (e) => {
    this.setState({ projectList: [], selecSort: e, items: 0 }, () =>
      this.renderProject()
    );
  };

  handleSearch = (item) => {
    if (item) {
      this.setState({ category: item, items: 0 }, () => this.renderProject());
    } else {
      this.setState({ category: "", items: 0 }, () => this.renderProject());
    }
  };

  color = "#070707";
  setStyle = css`
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
  `;

  HeaderOnClose = () => {
    this.handleProjectDialogClose();
  };

  render() {
    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            tablet: "(min-width: 800px and max-width: 1024px)",
            tabletDesktop: "(min-width: 800px)",
            desktop: "(min-width: 1025px)",
          }}
        >
          {(matches) => (
            <>
              {this.state.projectDialogOpen ? (
                <ProjectDialog
                  handleUnFollow={this.handleUnFollow}
                  handleAllUnFollow={this.handleAllUnFollow}
                  handleFollow={this.handleFollow}
                  handleAllFollow={this.handleAllFollow}
                  isCreators={this.state.isCreators}
                  isFollow={this.state.isFollow}
                  isFollowLoading={this.state.isFollowLoading}
                  open={this.state.projectDialogOpen}
                  userId={this.props.myInfo ? this.props.myInfo._id : null}
                  onClose={
                    !this.state.isPage
                      ? () => this.handleProjectDialogClose
                      : null
                  }
                  HeaderOnClose={this.HeaderOnClose}
                  history={this.props.history}
                  isPage={this.state.isPage}
                  projInfo={this.state.projectInfo}
                  nextDataCheck={false}
                  prevDataCheck={false}
                  timerSet={this.timerSet}
                />
              ) : null}
              <ContentDialog
                open={this.state.contentDialogOpen}
                title={i18n.t("main.criteria")}
                multiMsg={[
                  {
                    title: i18n.t("main.criteria.title.motionTrend"),
                    msg: i18n.t("main.criteria.msg.motionTrend"),
                  },
                  {
                    title: i18n.t("main.criteria.title.spotlight"),
                    msg: i18n.t("main.criteria.msg.spotlight"),
                  },
                  // {
                  //     title: i18n.t('main.criteria.title.debut'),
                  //     msg: i18n.t('main.criteria.msg.debut'),
                  // },
                ]}
                onClose={this.handleContentDialogClose}
              />

              <div className="main-container Main" id="mainScreenScroll">
                <div className="referenceList list">
                  <div className="referenceList_wrapper">
                    {matches.mobile && (
                      <div
                        className="categorySlider-wrapper"
                        style={{
                          padding: "0 30px",
                          // overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        <CategorySlider
                          history={this.props.history}
                          searchCategory={this.handleSearch}
                        />
                        {/* <button
                          onClick={() =>
                            this.setState({
                              openDetailCate: !this.state.openDetailCate,
                            })
                          }
                        >
                          <SvgColor
                            svg={MoreIcon}
                            width={16}
                            colors={["#939393", "#939393", "#939393"]}
                          />
                        </button>
                        {this.state.openDetailCate ? (
                          <div className="detailed_cate">hello world</div>
                        ) : null} */}
                      </div>
                    )}
                    <div className="reference-header header">
                      <div className="filtersWrap">
                        <div
                          className={`filtersBox dateFilter`}
                          onClick={(e) => {
                            this.setState({
                              sortFilterOpen: !this.state.sortFilterOpen,
                            });
                          }}
                          // style={{ marginLeft: "5px" }}
                        >
                          {/* <Badge
                            dot={
                              this.state.selecteDate !== "전체 기간"
                                ? true
                                : false
                            }
                          > */}
                          <span className="nowrap">
                            {this.state.selecSort
                              ? this.state.selecSort.sort
                              : i18n.t("전체 기간")}
                          </span>
                          {/* </Badge> */}
                          {/* <SvgColor
                            svg={arrowImg}
                            width={10}
                            className="ArrowIcon"
                            // colors={["#fff", "#fff"]}
                            rotate={this.state.filterOpen ? 180 : null}
                          /> */}
                          <img
                            src={arrowImg}
                            alt="arrowIcon"
                            style={
                              this.state.sortFilterOpen
                                ? { transform: "rotate(180deg)" }
                                : { transform: "" }
                            }
                          ></img>
                          {this.state.sortFilterOpen ? (
                            <ClickAwayListener
                              onClickAway={() =>
                                this.setState({
                                  sortFilterOpen: !this.state.sortFilterOpen,
                                })
                              }
                            >
                              <div
                                className={`dateDropList-wrapper ${
                                  this.state.sortFilterOpen
                                    ? "date-open"
                                    : "date-close"
                                }`}
                              >
                                <div className="dateList-item">
                                  {this.state.sortList.map((item, index) => {
                                    return (
                                      <React.Fragment>
                                        <input
                                          type="radio"
                                          name="item"
                                          id={`dateList-item-${index}`}
                                          // value="user"
                                          checked={
                                            this.state.selecSort.id === item.id
                                              ? true
                                              : false
                                          }
                                        ></input>
                                        <label
                                          htmlFor={`dateList-item-${index}`}
                                          onClick={() =>
                                            this.handleSortList(item)
                                          }
                                        >
                                          {item.sort}
                                        </label>
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </ClickAwayListener>
                          ) : null}
                        </div>
                        {this.state.selecSort.id !== 1 ? (
                          <div
                            className={`filtersBox dateFilter`}
                            onClick={(e) => {
                              this.setState({
                                dateFilterOpen: !this.state.dateFilterOpen,
                              });
                            }}
                            style={{ marginLeft: "5px" }}
                          >
                            <Badge
                              dot={
                                this.state.selecteDate !== "전체 기간"
                                  ? true
                                  : false
                              }
                            >
                              <span className="nowrap">
                                {this.state.selecteDate
                                  ? this.state.selecteDate
                                  : i18n.t("전체 기간")}
                              </span>
                            </Badge>
                            <img
                              src={arrowImg}
                              alt="arrowIcon"
                              style={
                                this.state.dateFilterOpen
                                  ? { transform: "rotate(180deg)" }
                                  : { transform: "" }
                              }
                            ></img>
                            {this.state.dateFilterOpen ? (
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.setState({
                                    dateFilterOpen: !this.state.dateFilterOpen,
                                  })
                                }
                              >
                                <div
                                  className={`dateDropList-wrapper ${
                                    this.state.dateFilterOpen
                                      ? "date-open"
                                      : "date-close"
                                  }`}
                                >
                                  <div className="dateList-item">
                                    {this.state.dateList.map((item, index) => {
                                      return (
                                        <React.Fragment>
                                          <input
                                            type="radio"
                                            name="item"
                                            id={`dateList-item-${index}`}
                                            // value="user"
                                            checked={
                                              this.state.selecteDate ===
                                              item.text
                                                ? true
                                                : false
                                            }
                                          ></input>
                                          <label
                                            htmlFor={`dateList-item-${index}`}
                                            onClick={() =>
                                              this.handleDateRange(item)
                                            }
                                          >
                                            {item.text}
                                          </label>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                              </ClickAwayListener>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      {matches.tabletDesktop && (
                        <div
                          className="categorySlider-wrapper"
                          style={{
                            padding: "0 30px",
                            // overflow: "hidden",
                            width: "100%",
                          }}
                        >
                          <CategorySlider
                            history={this.props.history}
                            searchCategory={this.handleSearch}
                            resize={this.state.selecSort}
                          />
                          {/* <button
                          onClick={() =>
                            this.setState({
                              openDetailCate: !this.state.openDetailCate,
                            })
                          }
                        >
                          <SvgColor
                            svg={MoreIcon}
                            width={16}
                            colors={["#939393", "#939393", "#939393"]}
                          />
                        </button>
                        {this.state.openDetailCate ? (
                          <div className="detailed_cate">hello world</div>
                        ) : null} */}
                        </div>
                      )}

                      <div
                        className="standardWrap"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            contentDialogOpen: true,
                          });
                        }}
                      >
                        <HelpOutlineOutlinedIcon
                          className="icon"
                          style={{
                            fontSize: 16,
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                        <div
                          className="standardTitle"
                          style={{ marginLeft: "5px", whiteSpace: "nowrap" }}
                        >
                          {i18n.t("main.criteria")}
                        </div>
                      </div>
                    </div>

                    {this.state.isLoading ? null : this.state.projectList[0] ? (
                      <div className="reference-container">
                        {this.state.projectList.map((item, index) => {
                          return (
                            <div
                              className="reference-content"
                              key={`main_project_${index}`}
                            >
                              <Project
                                isPage={this.state.isPage}
                                mobile={matches.desktop ? false : true}
                                location={this.props.loacation}
                                thumbnail={item.thumbnail}
                                title={item.title}
                                uploaderInfo={item.uploaderInfo}
                                creators={item.creators}
                                likeMembers={item.likeMembers}
                                views={item.views}
                                projectId={item._id}
                                history={this.props.history}
                                projUserId={item.userId}
                                projectList={this.state.projectList}
                              ></Project>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="search_data_empty">
                        <EmptyStates />
                      </div>
                    )}
                    {this.state.dataLoading ? (
                      <FadeLoader
                        color={this.color}
                        loading={this.state.dataLoading}
                        css={this.setStyle}
                        size={10}
                      ></FadeLoader>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </Media>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  lang: state.language.lang,
});

export default compose(connect(mapStateToProps, null), withSnackbar)(Main);
