import React, { Component } from "react";
import "./AddCollectionDialog.scss";
import { Dialog } from "@material-ui/core";
import { Switch } from "antd";
import i18n from "../../../i18n";
import { PostCreateCollection } from "../../../Api/Collection/Collection";
import Collection from "../../Collection/Collection";
class AddCollectionDialog extends Component {
  state = {
    collectionName: "",
    isHidden: false,
  };

  handleCollectionNameChange = (e) => {
    this.setState({ collectionName: e.target.value });
  };

  handleSecret = (e) => {
    this.setState({ isHidden: e });
  };
  handleCreateCollection = async () => {
    try {
      await PostCreateCollection(
        this.state.collectionName,
        this.state.isHidden,
        this.props.projId
      );
      this.props.onClose();
    } catch (e) {
      console.dir(e);
    }
  };
  componentDidMount = () => {
    if (this.props.isModify) {
      this.setState({ collectionName: this.props.collectionInfo.name });
    }
  };
  render() {
    const { onClose, open, thumbnail, creatorInfo, isModify, projects } =
      this.props;

    return (
      <Dialog
        fullWidth={this.props.fullWidth}
        className={"addcollection-dialog"}
        maxWidth={false}
        open={open}
        onClose={onClose}
      >
        <div className="addCollection-container">
          <div className="collection-box">
            <div className="collection-title">
              <span>{i18n.t("addCollection.dialog.title")}</span>
            </div>
            <div className="collection-fileList">
              {/* <span>{i18n.t("collection.dialog.listTitle")}</span> */}
              {isModify ? (
                <Collection collectionList={projects} />
              ) : (
                <div
                  className="colletion-thumbnail"
                  style={{ backgroundImage: `url(${thumbnail ?? null})` }}
                ></div>
              )}
            </div>
            <div className="collection-creatorInfo">
              <div
                className="creatorInfo-photo"
                style={{ backgroundImage: `url(${creatorInfo.profilePhoto})` }}
              ></div>
              <span>{creatorInfo.userName}</span>
            </div>
            <div className="collection-save">
              <div className="columnBox">
                <span>{i18n.t("addCollection.dialog.setSecret")}</span>
                <Switch onChange={this.handleSecret} />
              </div>
              <div className="columnBox">
                {/* <span>{i18n.t("addCollection.dialog.nameInput")}</span> */}
                <input
                  className="collection-nameInput"
                  type="text"
                  value={this.state.collectionName}
                  placeholder={i18n.t("addCollection.dialog.nameInput")}
                  onChange={this.handleCollectionNameChange}
                />
              </div>
            </div>
            <div className="collection-btns">
              <button onClick={onClose}>{i18n.t("global.back")}</button>
              <button
                onClick={() =>
                  this.props.handleCreateCollection(
                    this.state.collectionName,
                    this.state.isHidden
                  )
                }
              >
                {i18n.t("global.create")}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddCollectionDialog;
