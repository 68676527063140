import React, { Component } from "react";
import Select, { components } from "react-select";
import "./TagsAndCateModify.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../../../i18n";
import TagDelIcon from "../../../../../image/ProjectUpload/xbox-icon.svg";
import closeIcon from "../../../../../image/close-icon.svg";
import downArrowIcon from "../../../../../image/Admin/line-red-arrow-up-icon.svg";

import { confirmAlert } from "react-confirm-alert";
import { PatchProject } from "../../../../../Api/Admin/Project/project";
import { withSnackbar } from "notistack";
class TagsAndCateModify extends Component {
  state = { tagList: [] };

  constructor(props) {
    super(props);
    // console.log(props, 'props')

    this.state = {
      tagList: this.props.projInfo ? this.props.projInfo.tags : [],
      selectedCate: this.props.projInfo.category._id,
    };
  }
  handleTagsCateUpdate = async () => {
    this.setState({ loading: true });

    confirmAlert({
      title: "카테고리 및 태그 변경",
      message: `지정된 태그와 카테고리로 변경하시겠습니까 ?`,

      afterClose: () => {
        this.setState({ loading: false });
      },

      buttons: [
        {
          label: "수정",
          onClick: async () => {
            this.props.onClose();
            try {
              const response = await PatchProject(
                this.props.projInfo._id,
                this.state.selectedCate,
                this.state.tagList
              );

              //   console.log("태그 및 카테고리 변경 : ", response);
              if (response.message === "success") {
                this.setState(
                  { loading: false },
                  () => this.props.getProjList() // 유저 정보 다시 가져옴
                );
                // alert(`정보가 수정 되었습니다.`);
              }
            } catch (e) {
              this.props.enqueueSnackbar("message blah blah", {
                variant: "success",
                key: "uppoer.accept",
                autoHideDuration: 5000,
                preventDuplicate: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              console.dir(e);
            }
          },
        },
        {
          label: "취소",
          onClick: () => {
            this.setState({ loading: false });
            return;
          },
        },
      ],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.projInfo.tags !== this.props.projInfo.tags) {
      this.setState({ tagList: this.props.projInfo.tags });
    }
  }

  handleDeleteTag = (index) => {
    let tagList = this.state.tagList;
    tagList.splice(index, 1);
    this.setState({
      tagList,
    });
  };

  handleCateSelection = (e) => {
    this.setState({ selectedCate: e.value });
  };

  handleTagList = (e) => {
    const tagSpcialRegex = /[~!@#$%^&*()_+|<>?:{}]/;
    const tagList = this.state.tagList;
    const tag = e.target.value.trim();
    if (tag && this.state.tagList.length <= 9) {
      if (e.key === "Enter") {
        if (tag.length > 20) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.text"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tagSpcialRegex.test(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.SpicialText"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tagList.includes(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.overlap"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else {
          tagList.push(tag);
          this.setState({ tagList });
          e.target.value = "";
        }
      }
    }
  };

  findCateIndex = () => {
    const id = this.props.projInfo.category._id;

    const categoryArr = this.props.categoriesKor;
    // const index = categoryArr.findIndex((item) => item.value === id)
    const index = categoryArr
      .map(function (item) {
        return item.value;
      })
      .indexOf(id);

    return index;
  };

  render() {
    const { onClose, open } = this.props;
    const themeColor = "#ff2e43";

    const customStyles2 = {
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? "white" : "black",
        backgroundColor: state.isFocused ? themeColor : "none",
      }),
      container: () => ({
        maxHeight: "37px",
        flex: "2 1",
        height: "100%",
        minWidth: "100px",
        position: "relative",
        boxSizing: "border-box",
      }),
      // control: () => ({
      //     // none of react-select's styles are passed to <Control />
      //     display: 'flex',
      //     alignItems: 'center',
      //     height: '100%',
      //     width: '100%',
      // }),
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img
            alt="downArrowIcon"
            src={downArrowIcon}
            className={!this.props.open ? "closed" : "opened"}
          />
        </components.DropdownIndicator>
      );
    };
    return (
      <div className="tagsAndCate-container">
        <Dialog
          // fullWidth={this.props.fullWidth}
          className={"modifyTagsAndCate"}
          maxWidth={false}
          open={open}
          onClose={onClose}
        >
          <div className="closeBtn" onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </div>
          <h2>Category</h2>

          <Select
            menuColor={themeColor}
            className="CateDropDown"
            styles={customStyles2}
            placeholder={i18n.t("분야")}
            defaultValue={
              i18n.language === "ko"
                ? this.props.categoriesKor[this.findCateIndex()]
                : this.props.categoriesEng[this.findCateIndex()]
            }
            options={
              i18n.language === "ko"
                ? this.props.categoriesKor
                : this.props.categoriesEng
            }
            // onClick={this.handleCateSelection}
            onChange={this.handleCateSelection}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
          />

          <h2 style={{ marginTop: "30px" }}>Tags</h2>
          <div className="input_tag_wrap_admin">
            <div className="input_tag_inputBox">
              {this.state.tagList
                ? this.state.tagList.map((item, index) => {
                    return (
                      <div className="tagBox" key={`tags_${item}_${index}`}>
                        {/* <span className="tag">{`${item}`}</span> */}
                        <span className="tag">{item}</span>
                        <img
                          src={TagDelIcon}
                          alt="delete"
                          onClick={() => this.handleDeleteTag(index)}
                        />
                      </div>
                    );
                  })
                : null}
              <input
                type="text"
                placeholder={
                  this.state.tagList.length === 0
                    ? i18n.t("project.upload.tagsInput")
                    : i18n.t("project.upload.tagsEnter")
                }
                onKeyPress={this.handleTagList}
              ></input>
            </div>
          </div>
          <div className="btn-wrapper">
            <button onClick={onClose}>취소</button>{" "}
            <button onClick={this.handleTagsCateUpdate}>수정</button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withSnackbar(TagsAndCateModify);
