import { GA } from "../service/ga";

export const SignUp_GAEvent = (type: String) => {
  //회원가입 관련 이벤트
  switch (type) {
    case "stay":
      GA.trackSignUpEvent({
        action: "Stay Sign Up",
        label: "Sign Up Page Stay",
      });
      break;
    case "render":
      GA.trackSignUpEvent({
        action: "Rendered Sign Up",
        label: "Sign Up Page Arrival",
      });
      break;
    case "complete":
      GA.trackSignUpEvent({
        action: "Sign Up Complete",
        label: "Sign Up Complete",
      });
      break;
    case "login":
      GA.trackSignUpEvent({
        action: "Login Success",
        label: "Login Success",
      });
      break;
    default:
      break;
  }
};

export const ProjectUpload_GAEvent = (type: String) => {
  switch (type) {
    case "render":
      GA.trackProjectUploadSectionEvent({
        action: "Rendered Project Upload Page",
        label: "Project Upload Page Arrival",
      });
      break;
    case "upload":
      GA.trackProjectUploadSectionEvent({
        action: "Project Upload",
        label: "Project Upload Success",
      });
      break;
    case "draft":
      GA.trackProjectUploadSectionEvent({
        action: "Project Draft Save",
        label: "Project Draft Save Success",
      });
  }
};

export const ExistingTeam_GAEvent = (type: String) => {
  switch (type) {
    case "render":
      GA.trackExistingTeamEvent({
        action: "Rendered Team Participation Section",
        label: "Rendered Team Participation Section",
      });
      break;
    case "complete":
      GA.trackExistingTeamEvent({
        action: "Team Participation Application Completed",
        label: "Team Participation Application Completed",
      });
      break;
  }
};

export const TeamCreate_GAEvent = (type: String) => {
  switch (type) {
    case "render":
      GA.trackCreateTeamEvent({
        action: "Rendered Team Create Section",
        label: "Rendered Team Create Section",
      });
      break;
    case "invite":
      GA.trackCreateTeamEvent({
        action: "Rendered Team Invite Member Section",
        label: "Rendered Team Invite Member Section",
      });
      break;
    case "success":
      GA.trackCreateTeamEvent({
        action: "Team Create Success",
        label: "Team Create Success",
      });
      break;
  }
};

export const TeamList_GAEvent = (type: String) => {
  switch (type) {
    case "render":
      GA.trackTeamListEvent({
        action: "Rendered Team List Section",
        label: "Rendered Team List Section",
      });
      break;
  }
};

export const JobDetail_GAEvent = (type: String) => {
  switch (type) {
    case "stay":
      GA.trackJobDetailEvent({
        action: "Job Detail Viewed",
        label: "Job Detail Viewed",
      });
      break;
    case "bookmark":
      GA.trackJobDetailEvent({
        action: "Job Detail Bookmark",
        label: "Job Detail Bookmark",
      });
      break;
    case "share":
      GA.trackJobDetailEvent({
        action: "Job Detail Share Open",
        label: "Job Detail Share Open",
      });
      break;
    case "apply":
      GA.trackJobDetailEvent({
        action: "Job Detail Apply",
        label: "Job Detail Apply",
      });
      break;
  }
};

export const JobMain_GAEvent = (type: String) => {
  switch (type) {
    case "stay":
      GA.trackJobMainEvent({
        action: "Job Page Viewed",
        label: "Job Page Viewed",
      });
      break;
    case "search":
      GA.trackJobMainEvent({
        action: "Job Content Searching",
        label: "Job Content Searching",
      });
      break;
  }
};

export const ProjectDetail_GAEvent = (type: String) => {
  //프로젝트 상세 이벤트
  switch (type) {
    case "stay":
      GA.trackProjectSectionEvent({
        action: "Project Viewed",
        label: "Project Viewed",
      });
      break;
    case "like":
      GA.trackProjectSectionEvent({
        action: "Like Button Clicked",
        label: "Like Button Clicked",
      });
      break;
    case "unlike":
      GA.trackProjectSectionEvent({
        action: "Un Like Button Clicked",
        label: "Un Like Button Clicked",
      });
      break;
    case "saveColl":
      GA.trackProjectSectionEvent({
        action: "Collection Save",
        label: "Collection Save",
      });
      break;
    case "clickColl":
      GA.trackProjectSectionEvent({
        action: "Collection Button Clicked",
        label: "Collection Button Clicked",
      });
      break;
    case "comment":
      GA.trackProjectSectionEvent({
        action: "Write Comment",
        label: "Write Comment",
      });
      break;
    default:
      break;
  }
};
