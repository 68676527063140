import React, { Component } from "react";
import "./CompleteSignUp.scss";
import CKIcon from "../../image/check-icon.svg";
import i18n from "../../i18n";
class CompleteSignUp extends Component {
  state = {};
  componentDidMount = () => {
    console.log("asdasdfq");
  };
  toLogin = () => {
    this.props.history.push("/login");
  };
  toMain = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="complete-container">
        <div className="complete-box">
          <div className="complete-header">
            <span>{i18n.t("signup.complete")}</span>
          </div>
          <div className="complete-icon">
            <img src={CKIcon} alt="check" />
          </div>
          <div className="complete-text">
            <span>{i18n.t("signup.complete.msg")}</span>
          </div>
          <div className="complete-btns">
            <button onClick={this.toMain}>{i18n.t("global.home")}</button>
            <button onClick={this.toLogin}>{i18n.t("global.login")}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CompleteSignUp;
