import React, { Component } from "react";
import "./FilterArea.scss";
import DropdownIcon from "../../../../../image/Admin/line-red-arrow-up-icon.svg";
import i18n from "../../../../../i18n";
import { ClickAwayListener } from "@material-ui/core";
// import CheckBoxDropDown from "../../../../../Component/CheckBoxDropDown/CheckBoxDropDown";
import CoutrySelect from "../../../../../SignUp/UserInfo/CountrySelect";
import { RegionDropdown } from "react-country-region-selector";
import SearchIcon from "../../../../../image/black-search-icon.svg";
import refeshIcon from "../../../../../image/codicon_refresh.svg";
class FilterArea extends Component {
  state = {
    locationFieldOpen: false,
    conditionsOpen: false,
    conditionFilterList: [
      {
        type: "career",
        title: i18n.t("job.searchFilter.career"),
        list: [
          {
            id: 2,
            text: i18n.t("job.career.new"),
          },
          {
            id: 3,
            text: i18n.t("job.career.career"),
          },
          {
            id: 1,
            text: i18n.t("job.career.intern"),
          },
        ],
      },
      {
        type: "employment",
        title: i18n.t("job.searchFilter.employment"),
        list: [
          {
            id: 1,
            text: i18n.t("job.employment.full"),
          },
          {
            id: 3,
            text: i18n.t("job.employment.part"),
          },
          {
            id: 2,
            text: i18n.t("job.employment.free"),
          },
        ],
      },
      {
        id: 0,
        type: "remote",
        title: i18n.t("job.searchFilter.woking"),
        list: [
          {
            id: 0,
            text: i18n.t("job.woking"),
          },
        ],
      },
    ],

    countryInfo: {
      countryName: "",
      countryCode: "",
      region: "",
    },
  };

  handleSearchLoaction = () => {
    this.setState({ locationFieldOpen: false }, () => {
      this.props.handleSearchLoaction();
    });
  };
  handleLocationFieldOpen = () => {
    this.setState({ locationFieldOpen: true });
  };
  render() {
    const { endCount, processCount, scheduledCount } = this.props;
    return (
      <>
        <div className="filterArea-container">
          <div className="filterArea-lt">
            <span
              onClick={() => this.props.handleSelectFilter(1)}
              className={this.props.selectedFilter === 1 ? "activeNav" : ""}
            >
              진행중({processCount})
            </span>
            <span
              onClick={() => this.props.handleSelectFilter(2)}
              className={this.props.selectedFilter === 2 ? "activeNav" : ""}
            >
              진행 예정({scheduledCount})
            </span>
            <span
              onClick={() => this.props.handleSelectFilter(3)}
              className={this.props.selectedFilter === 3 ? "activeNav" : ""}
            >
              마감({endCount})
            </span>
          </div>
          <div className="filterArea-rt">
            <div className="search-field">
              <input type="search" onChange={this.props.handleSearchStr} />
              <button type="button" onClick={this.props.getJobList}>
                <img src={SearchIcon} alt="search" />
              </button>
            </div>
            <div className="filters">
              {/* <CheckBoxDropDown /> */}
              <ClickAwayListener
                onClickAway={() => this.setState({ conditionsOpen: false })}
              >
                <div
                  className={`conditons_wrapper ${
                    this.state.conditionsOpen && "job_filterOpen"
                  }`}
                >
                  <div
                    className="conditions-title"
                    onClick={() =>
                      this.setState({
                        conditionsOpen: !this.state.conditionsOpen,
                      })
                    }
                  >
                    <span>
                      {i18n.language === "en" || i18n.language === "En"
                        ? i18n.t("global.filter")
                        : i18n.t("job.searchFilter.field")}
                      {this.props.selectedCareer.length ||
                      this.props.selectedWorkinHours.length ||
                      this.props.remoteFilter ? (
                        <span>
                          +
                          {this.props.selectedCareer.length +
                            this.props.selectedWorkinHours.length +
                            (this.props.remoteFilter ? 1 : 0)}
                        </span>
                      ) : null}
                    </span>
                    <img
                      src={DropdownIcon}
                      style={
                        this.state.conditionsOpen
                          ? { transform: "rotate(180deg)" }
                          : null
                      }
                      alt="open"
                    />
                  </div>
                  <div
                    className={`conditions_items ${
                      !this.state.conditionsOpen ? "close" : ""
                    }`}
                  >
                    {this.state.conditionFilterList.map((item, index) => {
                      return (
                        <div
                          className="items-section"
                          key={`${item.title}_${index}`}
                        >
                          <span>{item.title}</span>
                          <div className="items-list">
                            {item.list.map((value, ind) => {
                              return (
                                <div
                                  className="checkBox"
                                  key={`${value.text}_${ind}`}
                                >
                                  <input
                                    onChange={(e) =>
                                      this.props.handleFilterChange(
                                        e,
                                        item.type,
                                        value.id
                                      )
                                    }
                                    type="checkBox"
                                    id={`${value.text}_${ind}`}
                                  />
                                  <label htmlFor={`${value.text}_${ind}`}>
                                    <span></span>
                                    {value.text}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ClickAwayListener>
              <ClickAwayListener
                onClickAway={() => this.setState({ locationFieldOpen: false })}
              >
                <div
                  className={`locations_wrapper ${
                    this.state.locationFieldOpen && "job_filterOpen"
                  }`}
                  htmlFor="locations"
                >
                  <>
                    <div
                      className="location_filter-title"
                      onClick={this.handleLocationFieldOpen}
                    >
                      {i18n.t("job.searchFilter.location")}
                      <img
                        src={DropdownIcon}
                        style={
                          this.state.locationFieldOpen
                            ? { transform: "rotate(180deg)" }
                            : null
                        }
                        alt="filterOpen"
                      />
                    </div>
                    {this.state.locationFieldOpen ? (
                      <div className="locationFilter">
                        {/* <div className="location-header">
                              <span>{i18n.t("job.searchFilter.location")}</span>
                            </div> */}
                        <div className="location-coutry row">
                          <span>
                            {i18n.t("job.searchFilter.location.country")}
                          </span>
                          <CoutrySelect
                            handleChangeCountry={this.props.handleChangeCountry}
                            countryCode={this.props.countryInfo.countryCode}
                            style={this.selectStyle}
                          />
                        </div>
                        <div className="location-city row">
                          <span>
                            {i18n.t("job.searchFilter.location.city")}
                          </span>
                          {/* {this.state.countryInfo.countryCode ? ( */}
                          <RegionDropdown
                            style={{
                              padding: "0 15px",
                              borderRadius: "unset",
                            }}
                            countryValueType="short"
                            country={this.props.countryInfo.countryCode}
                            value={this.props.countryInfo.region}
                            className="fllter-region-input"
                            defaultOptionLabel={i18n.t("job.upload_1.region")}
                            blankOptionLabel={i18n.t(
                              "job.upload_1.region.blank"
                            )}
                            onChange={(val) =>
                              this.props.handleRegionChange(val)
                            }
                            disabled={
                              this.props.countryInfo.countryCode ? false : true
                            }
                          />
                        </div>
                        <div className="location-btns">
                          <button
                            type="button"
                            onClick={this.props.handleSearchLoaction}
                          >
                            {i18n.t("admin.project.adjust.display.apply")}
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ locationFieldOpen: false })
                            }
                          >
                            {i18n.t("global.clear")}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </>
                </div>
              </ClickAwayListener>
              <img
                onClick={this.props.handleInitfileter}
                src={refeshIcon}
                alt="alt src"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FilterArea;
