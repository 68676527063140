import React, { Component } from "react";
import "./UploadSetting.scss";
import { ClickAwayListener, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import AddThumbnail from "../../image/ProjectUpload/add-black-icon.svg";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import DelIcon from "../../image/ProjectUpload/xbox-icon.svg";
import Select from "react-select";

import { GetUserList } from "../../Api/User/user";
import CropDialog from "../../Component/CropDialog/CropDialog";
import { getCroppedImg } from "../../modules/imageCrop";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class UploadSetting extends Component {
  state = {
    crop: {
      x: 0,
      y: 0,
    },
    cropSrc: "",
    fileName: "",
    fileType: "",
    croppedAreaPixels: "",
    categories: [],
    selectedCategory: "",
    selecLicence: "",
    licenseListOpen: false,
    preview: null,
    file: "",
    isDraft: false,
    isLoading: false,
    userList: [],
    searchUser: "",
    userListOpen: false,
    selectedUser: [],
  };
  ratio = 16 / 9;

  licenseList = [
    { value: "CC BY", label: i18n.t("project.upload.cclicense1") },
    { value: "CC BY-SA", label: i18n.t("project.upload.cclicense2") },
    { value: "CC BY-ND", label: i18n.t("project.upload.cclicense3") },
    {
      value: "CC BY-NC",
      label: i18n.t("project.upload.cclicense4"),
    },
    {
      value: "CC BY-NC-SA",
      label: i18n.t("project.upload.cclicense5"),
    },
    {
      value: "CC BY-NC-ND",
      label: i18n.t("project.upload.cclicense6"),
    },
    {
      value: "All Rights Reserved",
      label: i18n.t("project.upload.cclicense7"),
    },
  ];

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "black" : "white",
      // padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      border: "1px solid #efefef",
      width: "100%",
      height: "40px",
    }),
    input: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      alignItems: "center",
      visibility: "visible",
      color: "hsl(0, 0%, 20%)",
      boxSizing: "border-box",
    }),
    valueContainer: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: "100%",
      height: "40px",
      padding: "0 15px",
      fontSize: "12px",
    }),

    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
      fontSize: "12px",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        maxWidth: "calc(100% - 60px)",
      };
    },
  };

  handleSelectCopyright = async (e) => {
    const item = e.value;
    this.setState({ selecLicence: item });
  };

  handleUserListOpen = () => {
    this.setState({ userListOpen: true });
  };

  handleChangeCategory = (e, item) => {
    this.setState({ selectedCategory: item });
  };

  // GetCategoryList = async () => {
  //   try {
  //     const response = await GetCategories();
  //     this.setState({ categories: response });
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };

  // handleLicenseOpen = () => {
  //   const arrow = document.getElementById("licenseIcon");
  //   this.setState(
  //     { licenseListOpen: true },
  //     () => (arrow.style.transform = "rotate(-180deg)")
  //   );
  // };
  // handleLicenseClose = () => {
  //   const arrow = document.getElementById("licenseIcon");
  //   this.setState(
  //     { licenseListOpen: false },
  //     () => (arrow.style.transform = "rotate(0deg)")
  //   );
  // };
  handleFileOnChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          cropSrc: reader.result,
          cropDialogOpen: true,
          fileName: file.name,
          fileType: file.type,
        });
      };
    }
  };

  handleSubmit = () => {
    let selectedUser = [];
    for (let i = 0; i < this.props.selectedUser.length; i++) {
      console.log(selectedUser);
      selectedUser.push(this.props.selectedUser[i]._id);
    }
    console.log(selectedUser);
    this.props.handleSubmit(
      this.props.thumbnail,
      this.props.selectedCategory,
      this.props.selecLicence,
      this.state.isDraft,
      selectedUser
    );
  };

  handleThumbUpload = (e) => {
    e.preventDefault();
    this.thumUploadInput.click();
  };
  handleDraftSubmit = () => {
    this.setState({ isDraft: true }, () => {
      this.handleSubmit();
    });
  };
  handleUserSearch = (e) => {
    this.setState({ searchUser: e.target.value }, () => {
      this.handleUserListLoad();
    });
  };
  handleUserListLoad = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await GetUserList(this.state.searchUser);
      this.setState({ isLoading: false, userList: response });
    } catch (e) {
      console.dir(e);
    }
  };
  onCropChange = (pos) => {
    this.setState({ crop: pos });
  };

  onImageLoaded = (image) => {
    this.imgRef = image;
  };
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  showCroppedImage = async (img) => {
    try {
      const croppedImage = await getCroppedImg(
        img,
        this.state.croppedAreaPixels,
        0,
        this.state.fileName,
        this.state.fileType
      );

      const file = new File([croppedImage], this.state.fileName, {
        type: this.state.fileType,
      });
      const url = URL.createObjectURL(croppedImage);
      this.props.handleThumbnailChange(file, url);
      this.setState({
        fileName: "",
        fileType: "",
        cropDialogOpen: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  renderUserListLoad = () => {
    if (
      this.state.userList[0] &&
      !this.state.isLoading &&
      this.state.userListOpen
    ) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ userListOpen: false })}
        >
          <div className="search_user-container">
            <div className="search_user-columns">
              {this.state.userList.map((item) => {
                return (
                  <div
                    className="search_user-row"
                    onClick={() => {
                      this.props.handleAddCollaborator(item);
                      this.setState({ userListOpen: true });
                    }}
                  >
                    <ProfilePhoto src={item.profilePhoto} size={36} />
                    <div className="search_user-userInfo">
                      <span>{item.userName}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  };
  render() {
    const theme1 = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // your override styles here. for example, the following overrides dialog's "root" class
          paper: {
            margin: "0 30px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.8)",
          },
        },
      },
    });
    console.log(this.props);
    const optionIndex = this.licenseList.findIndex(
      (option) => option.value === this.props.selecLicence
      // (option) => option.value === this.state.selecLicence
    );

    const { onClose, open } = this.props;

    return (
      <>
        <ThemeProvider theme={theme1}>
          <Dialog
            fullWidth={this.props.fullWidth}
            classes={{
              root: `classes-nesting-uploadSetting`, // class name, e.g. `classes-nesting-root-x`
              // label: `classes-nesting-label-uploadSetting`, // class name, e.g. `classes-nesting-label-x`
            }}
            maxWidth={"false"}
            open={open}
            onClose={onClose}
          >
            <CropDialog
              ratio={this.ratio}
              showCroppedImage={this.showCroppedImage}
              onImageLoaded={this.onImageLoaded}
              onCropChange={this.onCropChange}
              crop={this.state.crop}
              src={this.state.cropSrc}
              open={this.state.cropDialogOpen}
              onCropComplete={this.onCropComplete}
              onClose={() => this.setState({ cropDialogOpen: false })}
            />
            <div className="closeBtn" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </div>
            <div className="one-button-dialog-container projectUpload">
              <div className="projectSetting-container">
                <div className="projectSetting-title">
                  <span>{i18n.t("header.upload")}</span>
                </div>

                <div className="previewBox">
                  {this.props.thumbnailPreview ? (
                    <div
                      className="preivewImg-content"
                      style={{
                        backgroundImage: `url(${this.props.thumbnailPreview})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    >
                      {/* <img src={this.state.preview} alt="thumbnail" /> */}
                    </div>
                  ) : (
                    <div
                      onClick={this.handleThumbUpload}
                      className="previewImg-box"
                    >
                      <div>
                        <img src={AddThumbnail} alt="AddThumbnail" />
                        <span
                          style={{
                            marginTop: "10px",
                          }}
                          className="thumbnail-title"
                        >
                          {i18n.t("project.upload.Thumbnail")}
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    ref={(input) => (this.thumUploadInput = input)}
                    type="file"
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    id="previewFileInput"
                    onChange={this.handleFileOnChange}
                    hidden
                  />
                  <label
                    hidden={this.props.thumbnailPreview ? false : true}
                    htmlFor="previewFileInput"
                  >
                    {i18n.t("project.upload.changeThumb")}
                  </label>
                </div>
                <div className="project-collaborator-addField">
                  <span>{i18n.t("project.upload.collaborator")}</span>
                  <div className="input-field">
                    {this.props.selectedUser[0]
                      ? this.props.selectedUser.map((item, index) => {
                          return (
                            <div className="selectedUser-items">
                              <div className="item-box">
                                <span>{item.userName}</span>
                                <img
                                  src={DelIcon}
                                  onClick={() => {
                                    this.props.handleRemoveUser(index);
                                    this.setState({ userListOpen: false });
                                  }}
                                  alt="delete Icon"
                                />
                              </div>
                            </div>
                          );
                        })
                      : null}
                    <input
                      type="text"
                      placeholder={
                        this.props.selectedUser[0]
                          ? null
                          : "Add co-owners by name or username"
                      }
                      onChange={this.handleUserSearch}
                      onClick={this.handleUserListOpen}
                    />
                    {this.renderUserListLoad()}
                  </div>
                </div>

                <div className="license-container">
                  <span className="license-title">
                    {i18n.t("project.upload.ccLicense")}
                  </span>
                  <Select
                    styles={this.customStyles}
                    options={this.licenseList}
                    defaultValue={
                      this.props.selecLicence
                        ? this.licenseList[optionIndex]
                        : this.licenseList[6]
                    }
                    onChange={this.handleSelectCopyright}
                  />
                </div>
                <div className="categories-container">
                  <span className="categories-title">
                    {i18n.t("project.upload.fields")}
                  </span>
                  <div className="categories-list">
                    {this.props.categories.map((item, index) => {
                      return (
                        <div key={`categories_${index}`}>
                          <input
                            checked={
                              this.props.selectedCategory
                                ? this.props.selectedCategory._id === item._id
                                : false
                            }
                            type="radio"
                            name="category"
                            id={`categories_${index}_item`}
                            onClick={() =>
                              this.props.handleChangeCategory(item)
                            }
                          />
                          <label htmlFor={`categories_${index}_item`}>
                            <span></span>
                            {i18n.language === "en"
                              ? item.name.en
                              : item.name.ko}
                          </label>
                        </div>
                      );
                    })}
                    {/* <span className="viewMoreBtn">
                                            <img
                                                src={ViewMore}
                                                alt="addCategories"
                                            />
                                            {i18n.t('project.upload.viewMore')}
                                        </span> */}
                  </div>
                </div>
                <div className="projectSetting-btns">
                  <button className="backBtn" type="button" onClick={onClose}>
                    {i18n.t("project.upload.back")}
                  </button>
                  <div className="rtBtns">
                    <button type="button" onClick={this.handleDraftSubmit}>
                      {i18n.t("project.upload.draftBtn")}
                    </button>
                    <button type="button" onClick={this.handleSubmit}>
                      {i18n.t("project.upload.uploadBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(UploadSetting);
