import React, { Component } from "react";
import "./MemberChangeAuthDialog.scss";

import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class MemberChangeAuthDialog extends Component {
  state = {
    seletedtAuth: "",
    authList: [
      {
        authName: i18n.t("team.member.auth.owner"),
        auth: "owner",
      },
      {
        authName: i18n.t("team.member.auth.manager"),
        auth: "admin",
      },
      {
        authName: i18n.t("team.member.auth.member"),
        auth: "member",
      },
    ],
  };
  handleChangeAuth = (auth) => {
    this.setState({ seletedtAuth: auth });
  };
  render() {
    const { onClose, open, userName } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"change_auth-dialog-container"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="change_auth-dialog">
              <span className="change_auth-dialog-title">
                {/* 계정 유형 변경 */}
              </span>
              <div className="change_auth-dialog-body">
                <span>{i18n.t("tema.member.auth.change", { userName })}</span>
                <span>{i18n.t("team.member.auth.more")}</span>
                <div className="auth_checkList">
                  {this.props.auth === "admin"
                    ? this.state.authList.map((auth, index) => {
                        return (
                          <div
                            className="auth-checkBox"
                            key={`auth_${auth}-index`}
                          >
                            <input
                              defaultChecked={this.props.auth === auth.auth}
                              type="radio"
                              name="authority"
                              id={`authority_${index}}`}
                              onChange={() => this.handleChangeAuth(auth.auth)}
                            />
                            <label htmlFor={`authority_${index}}`}>
                              <span></span>
                              {auth.authName}
                            </label>
                          </div>
                        );
                      })
                    : this.state.authList.slice(1).map((auth, index) => {
                        return (
                          <div className="auth-checkBox">
                            <input
                              defaultChecked={this.props.auth === auth.auth}
                              type="radio"
                              name="authority"
                              id={`authority_${index}}`}
                              onChange={() => this.handleChangeAuth(auth.auth)}
                            />
                            <label htmlFor={`authority_${index}}`}>
                              <span></span>
                              {auth.authName}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>

              <div className="change_auth-dialog-btns">
                <button type="button" onClick={onClose}>
                  {i18n.t("global.cancel")}
                </button>
                <button
                  type="button"
                  onClick={
                    this.state.seletedtAuth
                      ? () => {
                          onClose();
                          this.props.handleChageAuthMember(
                            this.state.seletedtAuth,
                            this.props.auth,
                            this.props.memberId
                          );
                        }
                      : null
                  }
                >
                  {i18n.t("global.save")}
                </button>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(MemberChangeAuthDialog);
