import React, { Component } from "react";
import { connect } from "react-redux";
import "./TeamPage.scss";
import MobileSelector from "../Component/MobileSelector/MobileSeletor";
import i18n from "../i18n";
import ProjectAddIcon from "../image/mypage-add-gray-button.svg";
import AlertDialog from "../Component/OneButtonDialog/OneButtonDialog";
import loadable from "@loadable/component";
import ProjectDialog from "../Component/ProjectDialog/ProjectDialog";
import quertString from "query-string";
import Media from "react-media";
import ImportProjectDialog from "./TeamProjectUpload/ImportProjectDialog/ImportProjectDialog";
import ShareDialog from "../Mypage/OtherPage/ShareDialog/ShareDialog";
import PlusIcon from "../image/plus-icon.svg";
import SvgColor from "react-svg-color";
import ArrowIcon from "../image/arrow-gray-icon.svg";
import RecruitmentTable from "./RecruitmentList/RecruitmentList";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import shareBtn from "../image/job/share-black-icon.svg";
import TeamInfo from "./TeamInfo/TeamInfo";
import UploadDialog from "./TeamProjectUpload/TeamProjectUpload";
import InviteDialog from "./InviteMemberDialog/InviteMemberDialog";
import MemberTable from "./MembersList/MembersList";
import { Spin } from "antd";
import BlackArrowIcon from "../image/line-black-down-arrow-icon.svg";
import MobileViewInfo from "../Mobile/Mypage/information_view_black_icon.svg";
import MobileLikeInfo from "../Mobile/Mypage/information_like_black_icon.svg";
import InstaIcon from "../image/Snsicon/circle-sns-icon-1.svg";
import FacebookIcon from "../image/Snsicon/circle-sns-icon-2.svg";
import YoutubeIcon from "../image/Snsicon/circle-sns-icon-3.svg";
import PinterIcon from "../image/Snsicon/circle-sns-icon-4.svg";
import DribbleIcon from "../image/Snsicon/circle-sns-icon-5.svg";
import TumblrIcon from "../image/Snsicon/circle-sns-icon-6.svg";
import VimeoIcon from "../image/Snsicon/circle-sns-icon-7.svg";
import MediumIcon from "../image/Snsicon/circle-sns-icon-8.svg";
import SoundCloudIcon from "../image/Snsicon/circle-sns-icon-9.svg";
import Follower from "../image/mypage-follower-icon.svg";
import Home from "../image/mypage-homepage-icon.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { ClickAwayListener } from "@material-ui/core";
import ApplicantsListDialog from "./ApplicantsListDialog/ApplicantsListDialog";
import Toggle from "react-toggle";
import { Avatar } from "antd";
import {
  GetRecruitmentList,
  GetTeamLinkInfo,
  GetTeamMemberList,
  PostMemberInvite,
  GetPendingUserList,
  PostUserAccept,
  PostUserReject,
  GetMemberModeJobList,
  CloseSetJob,
  RemoveAppicants,
  PostMemberAuthChange,
  PostRemoveMember,
  PostMemberInviteCancel,
  PostTeamOwnerChange,
} from "../Api/Team/Team";
import {
  PostCancelApply,
  SetUnFavoriteJob,
  SetFavoriteJob,
  GetApplicants,
  PostSetUserUnFavorite,
  PostSetUserFavorite,
  ModifyJobPeriod,
  PostMemoSet,
} from "../Api/Job/job";
import { GetProjectInfo, GetTeamProjectList } from "../Api/Project/project";
import { withSnackbar } from "notistack";
import {
  TeampageBannerDelete,
  TeampageBannerSet,
} from "../Api/BannerEdit/BannerEdit";
import { compose } from "redux";
import { SetFollow, SetUnFollow } from "../Api/Follow/Follow";
import { GetUserToken } from "../modules/userAuth";
import { BottomSheet } from "react-spring-bottom-sheet";

const Project = loadable(() => import("../Component/Project/Project"));
class TeamPage extends Component {
  state = {
    applicnatsView: false,
    isUserAuth: "",
    inviteDialogOpen: false,
    applicantsDialogOpen: false,
    uploadDialogOpen: false,
    selectedProject: "",
    importDialogOpen: false,
    mobileSelectorOpen: false,
    shareDialogOpen: false,
    projectDialogOpen: false,
    pendingUserList: [],
    videoList: [],
    memberList: [],
    memberModeMemberList: [],
    file: "",
    preview: "",
    selecMenu: "",
    selectedJobOrder: {
      id: 1,
      text: i18n.t("job.search.sort_2"),
    },
    bannerLoading: false,
    jobFilterOpen: false,
    isPendingLoading: false,
    isLoading: false,
    selectedMobileList: 0,
    applicantsList: [],
    jobId: "",
    jobOrder: [
      {
        id: 2,
        text: i18n.t("job.search.sort_1"),
      },
      {
        id: 1,
        text: i18n.t("job.search.sort_2"),
      },
    ],
    isPage: false,
    recruitmentList: [],
    memberModeRecruitmentList: [],
    isJobMemberMode: false,
    recruitmentLoading: false,
    alertDialogOpen: false,
    msgTitle: "",
    msg: "",
    memberNavOpen: false,
    teamInfo: {},
    projInfo: {},
    render: false,
    aplicantFilter: 0,
    isMemberMode: false,
    memberModeFilter: 1,
    timer: null,
    selecList: {
      0: [
        {
          id: 1,
          text: i18n.t("project.edit"),
        },
        {
          id: 2,
          text: i18n.t("global.delete"),
        },
      ],
      1: [
        {
          id: 1,
          text: i18n.t("teamPage.members.nav2"),
        },
        {
          id: 2,
          text: i18n.t("teamPage.members.nav1"),
        },
      ],
      2: [
        {
          id: 1,
          text: i18n.t("teamPage.members.nav2"),
        },
      ],
    },

    jobMobileSelectOpen: false,

    dialogRender: false,
  };
  membersNav = [
    {
      id: 1,
      text: i18n.t("teamPage.members.nav1"),
    },
    {
      id: 2,
      text: i18n.t("teamPage.members.nav2"),
    },
  ];
  projectNav = [
    {
      id: 1,
      text: i18n.t("mypage.nav.project"),
    },
    {
      id: 2,
      text: i18n.t("teamPage.nav.teamMember"),
    },

    {
      id: 3,
      text: i18n.t("teamPage.nav.recruitment"),
    },
  ];
  mobileProjectNav = [
    {
      id: 1,
      text: i18n.t("mypage.nav.project"),
    },

    {
      id: 2,
      text: i18n.t("teamPage.nav.teamMember"),
    },

    {
      id: 3,
      text: i18n.t("teamPage.nav.recruitment"),
    },
    {
      id: 4,
      text: i18n.t("mypage.nav.information"),
    },
  ];
  limit = 20;
  scrollState = {
    jobList: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
    userList: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
    userProject: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
  };
  pendingScrollState = {
    items: 0,
    dataNull: false,
    limit: 10,
  };
  pageState = {
    job: {
      page: 1,
      total: 0,
    },
    jobMemberMode: {
      page: 1,
      total: 0,
    },
    applicats: {
      page: 1,
      total: 0,
    },
    member: {
      page: 1,
      total: 0,
    },
  };
  headerCount = {
    memberMode: {
      endCount: 0,
      processCount: 0,
      scheduledCount: 0,
    },
    applicants: {
      memoCount: 0,
      favoriteCount: 0,
      count: 0,
    },
  };
  fileUpload = false;
  handleMoveJobList = () => {
    this.state.this.setState({ applicnatsView: false, selectedJobOrder: "" });
  };
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleInfiniteContent);
  };
  handleUploadDialogOpen = (project) => {
    this.setState({ importDialogOpen: false }, () =>
      this.setState({ uploadDialogOpen: true, selectedProject: project })
    );
  };
  handleImportDialogOpen = () => {
    this.setState({ importDialogOpen: true });
  };
  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const header = document.getElementById("nav_header-container");
    const query = quertString.parse(this.props.location.search);
    if (query && query.projectId && query.projectId.length === 24) {
      try {
        const response = await GetProjectInfo(query.projectId);
        header.classList.add("header-pageView");
        this.setState({ projInfo: response, isPage: true }, () =>
          this.setState({ dialogRender: true }, () =>
            this.setState({ projectDialogOpen: true })
          )
        );
      } catch (e) {
        // alert(i18n.t("global.error.server.msg"));
        this.handleAlert("global.error.server.msg", {
          variant: "error",
          key: i18n.t("global.error.server.msg"),
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.dir(e);
      }
    } else
      this.setState(
        { selecMenu: query.tab ? Number(query.tab) : 1 },
        async () => {
          try {
            const response = await GetTeamLinkInfo(
              this.props.location.pathname.replace("/team/@", "")
            );

            this.setState({ teamInfo: response }, async () => {
              console.log(this.state.teamInfo);
              await this.isPendinUserList();
              this.projectLoad(this.state.teamInfo._id);

              if (this.state.selecMenu === 1) {
                // this.projectLoad(this.state.teamInfo._id);
              } else if (this.state.selecMenu === 2) {
                this.teamMemberListLoad();
              } else if (this.state.selecMenu === 3) {
                if (
                  response.myAuthority !== "admin" ||
                  response.myAuthority !== "owner"
                ) {
                  this.recruitmentListLoad(this.state.teamInfo._id);
                } else {
                  this.getRecruitmentMemberModeList(this.state.teamInfo._id);
                }
              }
              this.isTeamAuthCheck();
            });
          } catch (e) {
            this.props.history.push("/not-found");
            console.dir(e);
          }
        }
      );
  };
  timerSet = (timer) => {
    this.setState({ timer });
  };
  handleaApplicantsOpen = async () => {
    this.setState({ applicantsDialogOpen: true, isPendingLoading: true });
    await this.isPendinUserList();
  };
  handleInfiniteContent = () => {
    let scrollHeight = Math.max(
      document.documentElement.scrollHeight,
      document.body.scrollHeight
    );
    let scrollTop = Math.max(
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    let clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      this.addRenderProject();
    }
  };
  handleShareDialogOpen = () => {
    this.setState({ shareDialogOpen: true });
  };
  handleShareDialogClose = () => {
    this.setState({ shareDialogOpen: false });
  };

  handleFollow = async (member) => {
    this.setState({
      memberList: this.state.memberList.map((item) => {
        if (item._id === member._id) {
          item.isFollowing = !item.isFollowing;
        }
        return item;
      }),
    });
    await SetFollow(member._id);
  };

  handleUnFollow = async (member) => {
    this.setState({
      memberList: this.state.memberList.map((item) => {
        if (item._id === member._id) {
          item.isFollowing = !item.isFollowing;
        }
        return item;
      }),
    });
    await SetUnFollow(member._id);
  };

  addRenderProject = () => {
    switch (this.state.selecMenu) {
      case 1:
        this.addUserProject(this.state.teamInfo._id);
        break;
      case 2:
        break;
      default:
    }
  };
  handleInvite = async (membersList) => {
    let members = membersList;
    for (let i = 0; i < membersList.length; i++) {
      members[i].userId = members[i]._id;
      delete members[i].email;
      delete members[i].job;
      delete members[i].profilePhoto;
      delete members[i].userName;
      delete members[i]._id;
    }

    try {
      await PostMemberInvite(this.state.teamInfo._id, members);

      this.setState({ inviteDialogOpen: false }, () =>
        this.teamMemberListLoad(this.state.teamInfo._id)
      );
    } catch (e) {
      console.dir(e);
    }
  };
  addUserProject = async (id) => {
    if (
      !this.scrollState.userProject.dataNull &&
      this.scrollState.userProject.firstRender
    ) {
      try {
        const response = await GetTeamProjectList(
          id,
          this.scrollState.userProject.items,
          this.limit
        );
        if (!response[0] || response.length < this.limit) {
          this.scrollState.userProject.dataNull = true;
        } else {
          let videoList = this.state.videoList;
          let add = videoList.concat(response);
          this.scrollState.userProject.items = add.length;
          this.setState({ videoList: add });
        }
      } catch (e) {
        console.dir(e);
      }
    }
  };

  projectLoad = async (id) => {
    try {
      const response = await GetTeamProjectList(id);
      this.setState(
        {
          videoList: response,
          render: true,
          isLoading: false,
        },
        () => {
          this.scrollState.userProject.firstRender = true;
          this.scrollState.userProject.items = this.state.videoList.length;
          if (this.state.videoList.length < 20) {
            this.scrollState.userProject.dataNull = true;
          }
        }
      );
    } catch (e) {
      console.dir(e);
      this.setState({ isLoading: false });
    }
  };

  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };

  handleMobileViewProject = (e) => {
    this.setState({ selecMenu: e.id }, () => {
      if (this.state.selecMenu === 1) {
        // this.setState({videoList:[]},()=>this.projectLoad(this.state.userInfo._id))
        return;
      } else if (this.state.selecMenu === 2) {
        this.teamMemberListLoad(this.state.teamInfo._id);
      } else if (this.state.selecMenu === 3) {
        this.recruitmentListLoad(this.state.teamInfo._id);
      } else if (this.state.selecMenu === 4) {
        return;
      }
    });
  };

  handleBannerDelete = async () => {
    this.setState({ bannerLoading: true });
    try {
      const teamInfo = this.state.teamInfo;
      await TeampageBannerDelete(this.state.teamInfo._id);
      delete teamInfo.bannerPhoto;

      this.setState({ bannerLoading: false, preview: "", teamInfo });
    } catch (e) {
      this.setState({ bannerLoading: false });
      console.dir(e);
    }
  };

  handleBannerSubmit = async (preview) => {
    try {
      await TeampageBannerSet(this.state.teamInfo._id, this.state.file);

      this.setState({ bannerLoading: false }, () => {
        this.setState({ preview });
      });
    } catch (e) {
      this.setState({ bannerLoading: false });
      console.dir(e);
    }
  };

  handleAdminMode = () => {
    if (this.state.selecMenu === 2) {
      this.setState(
        {
          isMemberMode: !this.state.isMemberMode,
          isLoading: true,
        },
        () => this.teamMemberListLoad(this.state.teamInfo._id)
      );
    } else if (this.state.selecMenu === 3) {
      this.setState(
        {
          selectedJobOrder: "",
          applicnatsView: false,
          isLoading: true,
          isJobMemberMode: !this.state.isJobMemberMode,
        },
        () => {
          if (this.state.isJobMemberMode) {
            this.getRecruitmentMemberModeList(this.state.teamInfo._id);
          } else {
            this.recruitmentListLoad(this.state.teamInfo._id);
          }
        }
      );
    }
  };

  handleBannerUpload = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let img = new Image();
    let file = e.target.files[0];
    const height = 1200;
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        img.src = e.target.result;
        this.setState({ bannerLoading: true }, () => {
          img.onload = () => {
            if (img.width <= height) {
              this.setState({
                msgTitle: i18n.t("img.uploadAlert.title"),
                msg: i18n.t("img.uploadAlert.text", { height }),
                alertDialogOpen: true,
                bannerLoading: false,
                mobileSelectorOpen: false,
              });
            } else {
              this.setState(
                {
                  file: file,
                  mobileSelectorOpen: false,
                },
                () => this.handleBannerSubmit(reader.result)
              );
            }
          };
        });
      };
    }
  };
  handleAlertDialogClose = () => {
    this.setState({ alertDialogOpen: false });
  };

  toOther = () => {
    this.props.history.push("/other");
  };
  toProjectupload = () => {
    this.props.history.push("/project-upload");
  };
  handleFollowOnClick = () => {
    const token = GetUserToken();
    if (token) {
      return;
    } else {
      // alert("로그인이 필요한 서비스 입니다.");
      this.handleAlert();
      this.props.history.push("/login");
    }
  };
  handleViewProject = (menu) => {
    this.setState(
      {
        selecMenu: menu,
        applicnatsView: false,
      },
      () => {
        this.props.history.push(`?tab=${this.state.selecMenu}`);
        if (this.state.selecMenu === 1) {
          // this.setState({videoList:[]},()=>this.projectLoad(this.state.userInfo._id))
          return;
        } else if (this.state.selecMenu === 2) {
          this.teamMemberListLoad(this.state.teamInfo._id);
        } else if (this.state.selecMenu === 3) {
          this.recruitmentListLoad(this.state.teamInfo._id);
        } else if (this.state.selecMenu === 4) {
          return;
        }
      }
    );
  };
  isTeamAuthCheck = () => {
    if (!this.state.teamInfo.myAuthority) {
      this.setState({ isUserAuth: "guest" }, () => {
        this.recruitmentListLoad(this.state.teamInfo._id);
      });
    } else if (this.state.teamInfo.myAuthority === "member") {
      this.setState({ isUserAuth: "member" }, () => {
        this.recruitmentListLoad(this.state.teamInfo._id);
      });
    } else if (this.state.teamInfo.myAuthority === "owner") {
      this.setState({ isUserAuth: "owner", isJobMemberMode: true }, () => {
        this.getRecruitmentMemberModeList(this.state.teamInfo._id);
      });
    } else if (this.state.teamInfo.myAuthority === "admin") {
      this.setState({ isUserAuth: "admin", isJobMemberMode: true }, () => {
        this.getRecruitmentMemberModeList(this.state.teamInfo._id);
      });
    }
  };
  teamMemberListLoad = async (
    id = this.state.teamInfo._id,
    type,
    order,
    isASC
  ) => {
    try {
      const page = (this.pageState.member.page - 1) * this.limit;
      const response = await GetTeamMemberList(
        id,
        this.state.isMemberMode,
        type,
        order,
        isASC,
        page,
        this.limit
      );
      this.pageState.member.total = response.count ? response.count : 0;
      this.setState({ memberList: response, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  getRecruitmentMemberModeList = async (id) => {
    this.setState({ isLoading: true });
    try {
      const page = (this.pageState.jobMemberMode.page - 1) * this.limit;
      const response = await GetMemberModeJobList(
        id,
        this.state.memberModeFilter,
        this.state.selectedJobOrder?.id,
        page,
        this.limit
      );

      this.headerCount.memberMode.endCount = response.endCount;
      this.headerCount.memberMode.processCount = response.processCount;
      this.headerCount.memberMode.scheduledCount = response.scheduledCount;
      this.setState({
        isLoading: false,
        memberModeRecruitmentList: response.list,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.dir(e);
    }
  };
  recruitmentListLoad = async (id) => {
    try {
      const page = (this.pageState.job.page - 1) * this.limit;
      const response = await GetRecruitmentList(
        id,
        this.state.selectedJobOrder ? this.state.selectedJobOrder.id : "",
        page,
        this.limit
      );

      this.pageState.job.page += response;
      // this.jobTotal = response.count;
      this.setState({
        recruitmentList: response !== null ? response : [],
        recruitmentLoading: true,
        isLoading: false,
      });
    } catch (e) {
      console.dir(e);
    }
  };

  handleMobileSelectorOpen = (selected, job, file) => {
    if (file) {
      this.fileUpload = true;
    }
    if (job) {
      this.setState({ jobMobileSelectOpen: true });
    } else if (selected === 1) {
      this.setState({ memberNavOpen: true });
    } else {
      this.setState({ selectedMobileList: selected }, () => {
        this.setState({ mobileSelectorOpen: true });
      });
    }
  };
  handleMobileSelectorClose = () => {
    this.fileUpload = false;
    this.setState({ mobileSelectorOpen: false });
  };
  handleInviteDialogOpen = () => {
    this.setState({ inviteDialogOpen: true });
  };
  handleSelecTeamMenu = (menu) => {
    switch (menu) {
      case 1:
        this.handleaApplicantsOpen();
        break;
      case 2:
        this.handleInviteDialogOpen();
        break;
      default:
    }
  };
  // handleSelectJobMent = (menu) => {
  //   switch (menu) {
  //     case 1:
  //       this.toJobUpload(this.state.teamInfo._id);
  //       break;
  //     case 2:
  //       break;
  //     default:
  //   }
  // };
  handleSelecList = (e) => {
    //this.handleMobileSelectorClose();
    if (this.state.selectedMobileList === 0) {
      switch (e) {
        case 1:
          // this.bannerUpload.click();
          // this.handleMobileSelectorClose();
          break;
        case 2:
          this.handleBannerDelete();
          this.handleMobileSelectorClose();
          break;
        default:
      }
    } else {
      switch (e) {
        case 1:
          this.handleInviteDialogOpen();
          this.handleMobileSelectorClose();
          break;
        case 2:
          this.handleaApplicantsOpen();
          this.handleMobileSelectorClose();
          break;
        default:
      }
    }
  };
  toJobEdit = (jobId, id) => {
    this.props.history.push({
      pathname: `/jobUpload`,
      search: "mode=Edit",
      state: { jobId, teamId: id },
    });
  };
  toJobUpload = (id) => {
    this.props.history.push({
      pathname: `/jobUpload`,
      search: "mode=Add",
      state: { teamId: id },
    });
  };
  Project = () => {
    if (this.state.videoList[0]) {
      return (
        <div className="userContent-list reference-container">
          {this.state.videoList.map((item) => {
            return (
              <div className="reference-content" key={item.title}>
                <Project
                  projectList={this.state.videoList}
                  location={this.props.loacation}
                  projectReLoad={this.projectLoad}
                  projLink={this.state.teamInfo.linkURL}
                  thumbnail={item.thumbnail}
                  creators={item.creators}
                  title={item.title}
                  uploaderInfo={item.uploaderInfo}
                  likeMembers={item.likeMembers}
                  views={item.views}
                  myProj={
                    this.state.isUserAuth !== "member" &&
                    this.state.isUserAuth !== "guest"
                  }
                  userInfoId={this.state.teamInfo._id}
                  history={this.props.history}
                  projectId={item._id}
                  projUserId={item.userId}
                  toOther={() => this.toOther}
                ></Project>
              </div>
            );
          })}
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <span style={{ marginTop: "60px" }}>
              {i18n.t("global.loading")}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <img
              src={ProjectAddIcon}
              alt="ProjectUplaod"
              onClick={this.handleImportDialogOpen}
            ></img>
            <span>{i18n.t("mypage.project.empty")}</span>
          </div>
        </div>
      );
    }
  };

  handleSelectJobOrder = (value) => {
    if (this.state.selectedJobOrder !== value) {
      this.setState({ selectedJobOrder: value, isLoading: true }, () => {
        this.setState({ jobFilterOpen: false });
        if (!this.state.isJobMemberMode) {
          this.recruitmentListLoad(this.state.teamInfo._id);
        } else {
          this.getRecruitmentMemberModeList(this.state.teamInfo._id);
        }
      });
    }
  };
  handleMemberFilterChange = (filter) => {
    this.setState(
      {
        memberModeFilter: filter,
        isLoading: true,
        memberModeRecruitmentList: [],
      },
      () => {
        this.getRecruitmentMemberModeList(this.state.teamInfo._id);
      }
    );
  };
  handleApplicantsFilter = (filter) => {
    this.pageState.applicats.page = 1;
    this.setState({ aplicantFilter: filter }, () => {
      this.handleApplicantsView(this.state.jobId);
    });
  };
  renderJobHeader = () => {
    if (!this.state.isJobMemberMode && !this.state.applicnatsView) {
      return (
        <div className="job-search-container">
          <div className="job_header-title">
            {i18n.t("teamPage.job.mainHeader")}
            <span>
              (
              {this.state.recruitmentList?.list
                ? this.state.recruitmentList.list.length
                : 0}
              )
            </span>
          </div>
          <div
            className="job-filter-wrapper"
            onClick={() =>
              this.setState({ jobFilterOpen: !this.state.jobFilterOpen })
            }
          >
            <span className="nowrap">
              {this.state.selectedJobOrder
                ? this.state.selectedJobOrder.text
                : i18n.t("job.search.sort_2")}
            </span>

            <img
              src={ArrowIcon}
              style={
                this.state.jobFilterOpen
                  ? { transform: `rotate(180deg)` }
                  : null
              }
              alt="arrowIcon"
            />
            {this.state.jobFilterOpen ? (
              <ClickAwayListener
                onClickAway={() => this.setState({ jobFilterOpen: false })}
              >
                <div className="job-filter-box">
                  {this.state.jobOrder.map((item) => {
                    return (
                      <span
                        onClick={() => this.handleSelectJobOrder(item)}
                        className={
                          this.state.selectedJobOrder?.id === item.id
                            ? "selectedFilter"
                            : ""
                        }
                      >
                        {item.text}
                      </span>
                    );
                  })}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
      );
    } else if (this.state.isJobMemberMode && !this.state.applicnatsView) {
      return (
        <div className="job-search-container">
          <div className="teble_headerNav">
            <div
              onClick={() => this.handleMemberFilterChange(1)}
              className={`nav_menu-item ${
                this.state.memberModeFilter === 1 ? "activeNav" : ""
              }`}
            >
              <span>
                {i18n.t("teamPage.job.progress")} (
                {this.headerCount.memberMode.processCount})
              </span>
            </div>
            <div
              onClick={() => this.handleMemberFilterChange(3)}
              className={`nav_menu-item ${
                this.state.memberModeFilter === 3 ? "activeNav" : ""
              }`}
            >
              <span>
                진행 예정 ({this.headerCount.memberMode.scheduledCount})
              </span>
            </div>
            <div
              onClick={() => this.handleMemberFilterChange(2)}
              className={`nav_menu-item ${
                this.state.memberModeFilter === 2 ? "activeNav" : ""
              }`}
            >
              <span>마감 ({this.headerCount.memberMode.endCount})</span>
            </div>
            {/* <div className="item-gradient end" /> */}
          </div>
          <div
            className="job-filter-wrapper"
            onClick={() =>
              this.setState({ jobFilterOpen: !this.state.jobFilterOpen })
            }
          >
            <span className="nowrap">
              {this.state.selectedJobOrder
                ? this.state.selectedJobOrder.text
                : i18n.t("job.search.sort_2")}
            </span>
            <img
              src={ArrowIcon}
              style={
                this.state.jobFilterOpen
                  ? { transform: `rotate(180deg)` }
                  : null
              }
              alt="arrowIcon"
            />
            {this.state.jobFilterOpen ? (
              <ClickAwayListener
                onClickAway={() => this.setState({ jobFilterOpen: false })}
              >
                <div className="job-filter-box">
                  {this.state.jobOrder.map((item) => {
                    return (
                      <span
                        onClick={() => this.handleSelectJobOrder(item)}
                        className={
                          this.state.selectedJobOrder.id === item.id
                            ? "selectedFilter"
                            : ""
                        }
                      >
                        {item.text}
                      </span>
                    );
                  })}
                  <span
                    onClick={() =>
                      this.handleSelectJobOrder({ text: "신규지원자", id: 3 })
                    }
                    className={
                      this.state.selectedJobOrder.id === 3
                        ? "selectedFilter"
                        : ""
                    }
                  >
                    신규지원자
                  </span>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="job-search-container">
          <div className="teble_headerNav">
            <div
              onClick={() => this.handleApplicantsFilter(0)}
              className={`nav_menu-item ${
                !this.state.aplicantFilter ? "activeNav" : ""
              } `}
            >
              <span>지원자 ({this.headerCount.applicants.count})</span>
            </div>
            <div
              onClick={() => this.handleApplicantsFilter(1)}
              className={`nav_menu-item ${
                this.state.aplicantFilter === 1 ? "activeNav" : ""
              }`}
            >
              <span>
                관심 목록 ({this.headerCount.applicants.favoriteCount})
              </span>
            </div>
            <div
              onClick={() => this.handleApplicantsFilter(2)}
              className={`nav_menu-item ${
                this.state.aplicantFilter === 2 ? "activeNav" : ""
              }`}
            >
              <span>메모 ({this.headerCount.applicants.memoCount})</span>
            </div>
            {/* <div className="item-gradient end" /> */}
          </div>
          <div
            className="job-filter-wrapper"
            onClick={() => this.setState({ jobFilterOpen: true })}
          >
            <span className="nowrap">
              {this.state.selectedJobOrder
                ? this.state.selectedJobOrder.text
                : i18n.t("jobBookmark.history.filter3")}
            </span>
            <img
              src={ArrowIcon}
              style={
                this.state.jobFilterOpen
                  ? { transform: `rotate(180deg)` }
                  : null
              }
              alt="arrowIcon"
            />
            {this.state.jobFilterOpen ? (
              <ClickAwayListener
                onClickAway={() => this.setState({ jobFilterOpen: false })}
              >
                <div className="job-filter-box">
                  {this.state.jobOrder.map((item) => {
                    return (
                      <span
                        onClick={() => this.handleSelectJobOrder(item)}
                        className={
                          this.state.selectedJobOrder.id === item.id
                            ? "selectedFilter"
                            : ""
                        }
                      >
                        {item.text}
                      </span>
                    );
                  })}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
      );
    }
  };
  renderSearch = (menu) => {
    if (menu === 1) {
      return;
    } else if (menu === 2) {
      return;
    } else if (menu === 3) {
      return this.renderJobHeader();
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleOtherApply = async (id) => {
    const job = this.state.recruitmentList.list.find((item) => item._id === id);
    if (job.isApplied) {
      if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
        const recruitmentList = this.state.recruitmentList;
        recruitmentList.list = recruitmentList.list.map((item) => {
          if (item._id === id) {
            item.isApplied = !item.isApplied;
          }
          return item;
        });
        this.setState({ recruitmentList });
        try {
          await PostCancelApply(id);
        } catch (e) {
          console.dir(e);
          recruitmentList.list = recruitmentList.list.map((item) => {
            if (item._id === id) {
              item.isApplied = !item.isApplied;
            }
            return item;
          });
        }
        this.setState({ recruitmentList });
      }
    } else {
      this.handleJobDetailResumeDialogOpen(id, this.state.jobInfo.teamInfo._id);
    }
  };
  handleUserFavorite = async (userId, id, index, favorite) => {
    try {
      const applicantsList = this.state.applicantsList;
      applicantsList[index].applications.isFavorite = !favorite;
      if (favorite) {
        this.headerCount.applicants.favoriteCount -= 1;
        this.setState({ applicantsList });
        await PostSetUserUnFavorite(id, userId);
      } else {
        this.headerCount.applicants.favoriteCount += 1;
        this.setState({ applicantsList });
        await PostSetUserFavorite(id, userId);
      }
    } catch (e) {
      const applicantsList = this.state.applicantsList;
      applicantsList[index].applications.isFavorite = favorite;
      this.setState({ applicantsList });
      console.dir(e);
    }
  };
  handleApplicantsView = async (id) => {
    this.setState({ isLoading: true });
    try {
      const response = await GetApplicants(
        id,
        this.state.aplicantFilter,
        this.state.selectedJobOrder
      );
      this.headerCount.applicants.count = response.count;
      this.headerCount.applicants.memoCount = response.memoCount;
      this.headerCount.applicants.favoriteCount = response.favoriteCount;
      this.pageState.applicats.page += response.list.length;
      this.pageState.applicats.total = response.count;
      this.setState(
        {
          applicantsList: response ? response.list : [],
        },
        () => {
          this.setState({
            jobId: id,
            applicnatsView: true,
            isJobMemberMode: true,
            isLoading: false,
          });
        }
      );
    } catch (e) {
      this.setState({ applicnatsView: false, isLoading: false });
      console.dir(e);
    }
  };
  handleSetPeriod = async (jobId, endDate, index) => {
    //let memberModeRecruitmentList = this.state.memberModeRecruitmentList;

    try {
      this.setState({ isLoading: true }, () =>
        this.getRecruitmentMemberModeList(this.state.teamInfo._id)
      );
      this.handleAlert(i18n.t("calendar.success"));
      await ModifyJobPeriod(jobId, endDate);
    } catch (e) {
      console.dir(e);
    }
  };
  handleRemoveAppicants = async (id, userId) => {
    this.setState({ isLoading: true });
    let applicantsList = this.state.applicantsList;

    try {
      applicantsList = applicantsList.filter(
        (val) => val.applications._id !== userId
      );
      this.setState({ isLoading: false, applicantsList });
      await RemoveAppicants(id, userId);
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleApply = (id) => {
    const token = GetUserToken();
    if (token) {
      this.handleOtherApply(id);
    } else {
      this.props.history.push("/login");
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleMemoRegistration = async (jobId, userId, memo, modify, index) => {
    await PostMemoSet(jobId, userId, memo);
    const applicantsList = this.state.applicantsList;

    applicantsList[index].applications.memo.text = memo;
    if (memo && !modify) {
      // this.setState({applicantsList})
      this.headerCount.applicants.memoCount += 1;
    } else if (!memo) {
      this.headerCount.applicants.memoCount -= 1;
    }
    this.setState({ applicantsList });
  };
  handleBookMark = (id) => {
    const token = GetUserToken();
    if (token) {
      this.handleOtherBookMark(id);
    } else {
      this.props.history.push("/login");
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleOtherBookMark = async (id) => {
    const recruitmentList = this.state.recruitmentList;
    recruitmentList.list = recruitmentList.list.map((item) => {
      if (item._id === id) {
        item.isFavorite = !item.isFavorite;
      }
      return item;
    });
    this.setState({
      recruitmentList,
    });
    try {
      if (!recruitmentList.list.find((item) => item._id === id).isFavorite) {
        await SetUnFavoriteJob(id);
      } else {
        await SetFavoriteJob(id);
      }
    } catch (e) {
      if (e && e.response && e.response.data) {
        this.handleAlert(i18n.t("global.alert.login"), {
          variant: "error",
          key: i18n.t("global.alert.login"),
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
      recruitmentList.list = recruitmentList.list.map((item) => {
        if (item._id === id) {
          item.isFavorite = !item.isFavorite;
        }
        return item;
      });
      this.setState({
        recruitmentList,
      });
      console.dir(e);
    }
  };

  desktopRender = (selecMenu) => {
    if (selecMenu === 1 || selecMenu === 4) {
      if (selecMenu === 4) {
        this.setState({ selecMenu: 1 });
      }
      return this.Project();
    } else if (selecMenu === 2) {
      return (
        <MemberTable
          myInfo={this.props.myInfo}
          history={this.props.history}
          isLoading={this.state.isLoading}
          isMemberMode={this.state.isMemberMode}
          teamName={this.state.teamInfo.name}
          userAuth={this.state.teamInfo.myAuthority}
          teamInfo={this.state.teamInfo}
          memberInfo={this.state.memberList}
          teamMemberListLoad={this.teamMemberListLoad}
          handleFollow={this.handleFollow}
          handleUnFollow={this.handleUnFollow}
          teamId={this.state.teamInfo._id}
          handleRemoveMember={this.handleRemoveMember}
          handleChangeOwner={this.handleChangeOwner}
          handleChageAuthMember={this.handleChageAuthMember}
        />
      );
    } else if (selecMenu === 3) {
      return (
        <RecruitmentTable
          handleMemoRegistration={this.handleMemoRegistration}
          memberListReload={this.getRecruitmentMemberModeList}
          handleSetPeriod={this.handleSetPeriod}
          handleRemoveAppicants={this.handleRemoveAppicants}
          handleBookMark={this.handleBookMark}
          toJobEdit={this.toJobEdit}
          handleJobClose={this.handleJobClose}
          m_list={this.state.memberModeRecruitmentList}
          handleUserFavorite={this.handleUserFavorite}
          applicantsList={this.state.applicantsList}
          handleApplicantsView={this.handleApplicantsView}
          applicnatsView={this.state.applicnatsView}
          handleApply={this.handleApply}
          history={this.props.history}
          list={
            this.state.recruitmentList ? this.state.recruitmentList.list : []
          }
          headerCount={this.headerCount}
          pageState={this.pageState}
          setPage={this.jobSetPage}
          memberMode={this.state.isJobMemberMode}
          teamId={this.state.teamInfo._id}
          reLoadList={this.recruitmentListLoad}
          isLoading={this.state.recruitmentLoading}
          skip={this.jobSkip}
          limit={this.jobLimit}
          isUserAuth={this.state.isUserAuth}
        />
      );
    }
  };

  iconFinder = (websiteName) => {
    if (websiteName === "Instagram") {
      return InstaIcon;
    } else if (websiteName === "Facebook") {
      return FacebookIcon;
    } else if (websiteName === "Pinterest") {
      return PinterIcon;
    } else if (websiteName === "Dribble") {
      return DribbleIcon;
    } else if (websiteName === "Youtube") {
      return YoutubeIcon;
    } else if (websiteName === "Tumblr") {
      return TumblrIcon;
    } else if (websiteName === "Vimeo") {
      return VimeoIcon;
    } else if (websiteName === "Medium") {
      return MediumIcon;
    } else if (websiteName === "SoundCloud") {
      return SoundCloudIcon;
    }
    // else {
    //     return OtherIcon
    // }
  };
  handleChangeOwner = async (memberInfo) => {
    console.log(this.props, memberInfo);
    try {
      await PostTeamOwnerChange(this.state.teamInfo._id, memberInfo._id);
      const memberList = this.state.memberList.map((item) => {
        if (item._id === memberInfo._id) {
          item.authority = "owner";
        }
        if (item._id === this.props.myInfo._id) {
          item.authority = "member";
        }
        return item;
      });
      this.setState({ memberList });
      this.handleAlert(i18n.t("소유권 이전이 완료되었습니다."), {
        variant: "success",
        key: "success.team.authChange.user",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleChageAuthMember = async (auth, selfAuth, memberId) => {
    if (selfAuth === "owner" && auth !== selfAuth) {
      this.setState({ changeAuthDialogOpen: false });
      this.handleAlert(
        i18n.t("팀 소유자 계정은 다른 계정 유형으로 변경이 불가합니다."),
        {
          variant: "warning",
          key: "error.uneditableData",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      return;
    } else {
      try {
        const memberList = this.state.memberList.map((item) => {
          if (item._id === memberId) {
            item.authority = auth;
          }
          return item;
        });
        this.handleAlert();
        this.setState({ memberList });
        await PostMemberAuthChange(this.state.teamInfo._id, memberId, auth);
        // this.props.teamMemberListLoad();
        this.setState({ changeAuthDialogOpen: false }, () =>
          this.handleAlert(i18n.t("계정 유형이 변경 되었습니다."), {
            variant: "success",
            key: "success.team.authChange.user",
            autoHideDuration: 5000,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          })
        );
      } catch (e) {
        console.dir(e);
      }
    }
  };
  mobileRender = (selecMenu) => {
    if (selecMenu === 1) {
      return this.Project();
    } else if (selecMenu === 2) {
      return (
        <MemberTable
          myInfo={this.props.myInfo}
          history={this.props.history}
          isLoading={this.state.isLoading}
          isMemberMode={this.state.isMemberMode}
          teamName={this.state.teamInfo.name}
          userAuth={this.state.teamInfo.myAuthority}
          teamInfo={this.state.teamInfo}
          memberInfo={this.state.memberList}
          teamMemberListLoad={this.teamMemberListLoad}
          handleFollow={this.handleFollow}
          handleUnFollow={this.handleUnFollow}
          teamId={this.state.teamInfo._id}
          handleChangeOwner={this.handleChangeOwner}
          handleChageAuthMember={this.handleChageAuthMember}
        />
      );
    } else if (selecMenu === 3) {
      return (
        <RecruitmentTable
          handleMemoRegistration={this.handleMemoRegistration}
          memberListReload={this.getRecruitmentMemberModeList}
          handleSetPeriod={this.handleSetPeriod}
          handleRemoveAppicants={this.handleRemoveAppicants}
          handleBookMark={this.handleBookMark}
          toJobEdit={this.toJobEdit}
          handleJobClose={this.handleJobClose}
          m_list={this.state.memberModeRecruitmentList}
          handleUserFavorite={this.handleUserFavorite}
          applicantsList={this.state.applicantsList}
          handleApplicantsView={this.handleApplicantsView}
          applicnatsView={this.state.applicnatsView}
          handleApply={this.handleApply}
          history={this.props.history}
          list={
            this.state.recruitmentList ? this.state.recruitmentList.list : []
          }
          headerCount={this.headerCount}
          pageState={this.pageState}
          setPage={this.jobSetPage}
          memberMode={this.state.isJobMemberMode}
          teamId={this.state.teamInfo._id}
          reLoadList={this.recruitmentListLoad}
          isLoading={this.state.recruitmentLoading}
          skip={this.jobSkip}
          limit={this.jobLimit}
          isUserAuth={this.state.isUserAuth}
        />
      );
    } else if (selecMenu === 4) {
      return this.mobileInfo();
    }
  };

  mobileInfo = () => {
    return (
      <div className="mobile-userInfo-container">
        <div className="info-title">
          {i18n.t("userSetting.userInfo.expaln")}
        </div>
        <div className="mobile-userInfo-intro">
          <div className="intro-header">
            <span>
              {this.state.teamInfo.teamInfo
                ? this.state.teamInfo.teamInfo.title
                : null}
            </span>
          </div>
          <span>
            {this.state.teamInfo.teamInfo &&
            this.state.teamInfo.teamInfo.explanation
              ? this.state.teamInfo.teamInfo.explanation
              : !this.state.teamInfo.teamInfo.explanation &&
                !this.state.teamInfo.teamInfo.title
              ? i18n.t("mypage.noDescription")
              : null}
          </span>
        </div>
        <div className="info-title">{i18n.t("global.additionalInfo")}</div>
        {this.state.teamInfo.linkInfo.length ? (
          <div className={`profile-snsBox`}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "white",
                backgroundColor: "#070707",
                // border: "0.5px solid #070707",
                fontSize: "12px",
              }}
              className="sns-Avatars-Grp"
              gap={10}
            >
              {this.state.teamInfo.linkInfo
                ? this.state.teamInfo.linkInfo.map((item) => {
                    return item.link ? (
                      // <Tooltip title={item.websiteName} placement="top">
                      <Avatar
                        onClick={() =>
                          window.open(`https://${item.link}`, "_blank")
                        }
                        style={{
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        src={this.iconFinder(item.websiteName)}
                      />
                    ) : // </Tooltip>
                    null;
                  })
                : null}
            </Avatar.Group>
          </div>
        ) : null}

        <div className="mobile-userInfo-list">
          <div className="mobile-userInfo-box">
            <img src={Follower} alt="viewCount" />
            <span className="mobile-userInfo-text">
              {i18n.t("team.create.addMember.auth.member")}
            </span>
            <span className="mobile-userInfo-count">
              {this.state.teamInfo.members.length}
            </span>
          </div>
          <div className="mobile-userInfo-box">
            <img src={MobileViewInfo} alt="viewCount" />
            <span className="mobile-userInfo-text">
              {i18n.t("mypage.profile.views")}
            </span>
            <span className="mobile-userInfo-count">
              {this.state.teamInfo.views}
            </span>
          </div>

          <div className="mobile-userInfo-box">
            <img src={MobileLikeInfo} alt="viewCount" />
            <span className="mobile-userInfo-text">
              {i18n.t("mypage.profile.totalLike")}
            </span>
            <span className="mobile-userInfo-count">
              {this.state.teamInfo.likeMembers}
            </span>
          </div>
          {this.state.teamInfo.website ? (
            <div className="mobile-userInfo-box">
              <img src={Home} alt="viewCount" />
              <span
                className="mobile-userInfo-count"
                onClick={() =>
                  window.open(
                    `https://${this.state.teamInfo.website}`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {this.state.teamInfo.website.length >= 30
                  ? this.state.teamInfo.website.substr(0, 30) + "..."
                  : this.state.teamInfo.website}
              </span>
            </div>
          ) : null}
        </div>

        <div className="mobile-userInfo-createdAt">
          <span>
            {i18n.t("mypage.profile.joing")}
            {""}
          </span>
          <span>
            {this.state.teamInfo.createdAt
              ? this.state.teamInfo.createdAt.substring(0, 10)
              : null}
          </span>
        </div>
      </div>
    );
  };

  addPendingUserList = async () => {
    this.setState({ isPendingLoading: true });
    try {
      const response = await GetPendingUserList(
        this.state.teamInfo._id,
        this.pendingScrollState.items,
        this.pendingScrollState.limit
      );
      if (response.length > this.LIMIT) {
        this.setState({ dataNullCheck: true });
      }
      this.pendingScrollState.items =
        this.pendingScrollState.items + response.length;
      this.setState({ pendingUserList: response }, () => {
        this.setState({ isPendingLoading: false });
      });
    } catch (e) {
      this.setState({ isPendingLoading: false });
    }
  };

  isPendinUserList = async () => {
    this.setState({ isPendingLoading: true });
    try {
      const response = await GetPendingUserList(this.state.teamInfo._id);
      this.setState({ pendingUserList: response }, () => {
        this.setState({ isPendingLoading: false });
      });
    } catch (e) {
      this.setState({ isPendingLoading: false });
      console.dir(e);
    }
  };
  handleUserReject = async (selectedUser) => {
    try {
      await PostUserReject(this.state.teamInfo._id, selectedUser);

      const pendingUserList = this.state.pendingUserList.filter(
        (val) => val._id !== selectedUser
      );
      this.setState({ pendingUserList });
    } catch (e) {
      console.dir(e);
    }
  };
  handleRemoveMember = async (memberInfo) => {
    if (memberInfo.isJoined) {
      try {
        await PostRemoveMember(this.state.teamInfo._id, memberInfo._id);
        const memberList = this.state.memberList.filter(
          (item) => item._id !== memberInfo._id
        );

        this.setState({ removeDialogOpen: false, memberList });
      } catch (e) {
        console.dir(e);
        this.props.enqueueSnackbar(i18n.t("삭제 실패 통신 오류"), {
          variant: "error",
          key: "error.team.delete.user",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        await PostMemberInviteCancel(this.state.teamInfo._id, memberInfo._id);
        const memberList = this.state.memberList.filter(
          (item) => item._id !== memberInfo._id
        );
        this.setState({ removeDialogOpen: false, memberList });
        this.props.enqueueSnackbar(
          i18n.t("팀에서 1명의 유저가 제외 되었습니다."),
          {
            variant: "success",
            key: "success.team.delete.user",
            autoHideDuration: 5000,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      } catch (e) {
        console.dir(e);
        this.props.enqueueSnackbar(i18n.t("삭제 실패 통신 오류"), {
          variant: "error",
          key: "error.team.delete.user",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };
  handleUserAccept = async (selectedUser) => {
    try {
      this.setState({ acceptDialogOpen: false }, () => {
        const pendingUserList = this.state.pendingUserList.filter(
          (val) => val._id !== selectedUser
        );
        this.setState(
          { pendingUserList },
          async () => await this.teamMemberListLoad(this.state.teamInfo._id)
        );
      });
      await PostUserAccept(this.state.teamInfo._id, selectedUser, "member");
    } catch (e) {
      console.dir(e);
    }
  };
  handleJobClose = async (id) => {
    try {
      await CloseSetJob(id);
      this.getRecruitmentMemberModeList(this.state.teamInfo._id);
      this.handleAlert(i18n.t("teamPage.job.closeMsg"));
    } catch (e) {
      console.dir(e);
    }
  };

  jobSetPage = (page) => {
    if (!this.state.isJobMemberMode && !this.state.applicnatsView) {
      this.pageState.job.page = page;
    } else if (this.state.isJobMemberMode && !this.state.applicnatsView) {
      this.pageState.jobMemberMode.page = page;
    } else {
      this.pageState.applicats.page = page;
    }
  };

  render() {
    const color = "white";
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    `;
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
        <Media
          queries={{
            tablet: "(max-width:1199px)",
            desktop: "(min-width:1200px)",
          }}
        >
          {(matches) => (
            <>
              {this.state.applicantsDialogOpen ? (
                <ApplicantsListDialog
                  handleUserAccept={this.handleUserAccept}
                  handleUserReject={this.handleUserReject}
                  pendingUserList={this.state.pendingUserList}
                  scrollInfo={this.pendingScrollState}
                  isLoading={this.state.isPendingLoading}
                  addPendingUserList={this.addPendingUserList}
                  teamId={this.state.teamInfo._id}
                  open={this.state.applicantsDialogOpen}
                  onClose={() => this.setState({ applicantsDialogOpen: false })}
                />
              ) : null}
              {this.state.inviteDialogOpen ? (
                <InviteDialog
                  handleInvite={this.handleInvite}
                  teamId={this.state.teamInfo._id}
                  open={this.state.inviteDialogOpen}
                  onClose={() => this.setState({ inviteDialogOpen: false })}
                  userAuth={this.state.teamInfo.myAuthority}
                />
              ) : null}
              <ShareDialog
                open={this.state.shareDialogOpen}
                onClose={this.handleShareDialogClose}
                projLink={this.state.teamInfo.linkURL}
              ></ShareDialog>
              <MobileSelector
                fileOnChage={this.handleBannerUpload}
                file={this.fileUpload}
                open={this.state.mobileSelectorOpen}
                onClose={this.handleMobileSelectorClose}
                selecList={this.state.selecList[this.state.selectedMobileList]}
                handleSelec={this.handleSelecList}
              ></MobileSelector>
              {this.state.importDialogOpen ? (
                <ImportProjectDialog
                  handleUploadDialogOpen={this.handleUploadDialogOpen}
                  teamId={this.state.teamInfo._id}
                  open={this.state.importDialogOpen}
                  onClose={() => this.setState({ importDialogOpen: false })}
                  userId={
                    this.state.teamInfo._id ? this.state.teamInfo._id : null
                  }
                  location={this.props.location}
                  history={this.props.history}
                  projectListLoad={this.projectLoad}
                />
              ) : null}

              <UploadDialog
                teamId={this.state.teamInfo._id}
                open={this.state.uploadDialogOpen}
                onClose={() => this.setState({ uploadDialogOpen: false })}
                thumbnail={this.state.selectedProject.thumbnail}
                projectId={this.state.selectedProject._id}
                projectListLoad={this.projectLoad}
              />
              <AlertDialog
                onButtonClick={this.handleAlertDialogClose}
                dialogHeadText={this.state.msgTitle}
                dialogBodyText1={this.state.msg}
                open={this.state.alertDialogOpen}
                onClose={this.handleAlertDialogClose}
              />
              {this.state.dialogRender ? (
                <ProjectDialog
                  isPage={this.state.isPage}
                  timerSet={this.timerSet}
                  userId={this.props.userId}
                  open={this.state.projectDialogOpen}
                  onClose={this.handleProjectDialogClose}
                  history={this.props.history}
                  projInfo={this.state.projInfo}
                ></ProjectDialog>
              ) : null}

              <div className="other-container">
                <div className="otherPage-container">
                  <div className="cotainer">
                    {/* <Spin
                      style={{ height: "50%" }}
                      indicator={antIcon}
                      spinning={this.state.bannerLoading}
                    > */}
                    <div
                      className={`mainBanner-screen ${
                        matches.desktop ? "desktopBanner" : null
                      }`}
                    >
                      {this.state.teamInfo.bannerPhoto || this.state.preview ? (
                        <div
                          className={
                            this.state.isUserAuth !== "member" &&
                            this.state.isUserAuth !== "guest"
                              ? "mypageBanner"
                              : null
                          }
                          style={{
                            backgroundImage: `url(${
                              this.state.preview
                                ? this.state.preview
                                : this.state.teamInfo.bannerPhoto
                            })`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <FadeLoader
                            color={color}
                            loading={this.state.bannerLoading}
                            css={override}
                            size={10}
                          ></FadeLoader>
                          {this.state.isUserAuth !== "member" &&
                          this.state.isUserAuth !== "guest" &&
                          !this.state.bannerLoading &&
                          matches.desktop ? (
                            <>
                              <div className="edit-btn-wrapper">
                                <button onClick={() => this.handleSelecList(2)}>
                                  Delete
                                </button>
                              </div>

                              <input
                                ref={(input) => (this.bannerUpload = input)}
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />

                              <label htmlFor="mySpaceBanner">
                                <SvgColor
                                  svg={ProjectAddIcon}
                                  width={70}
                                  colors={["#fff", "#fff"]}
                                />

                                <span>{i18n.t("mypage.banner.title")}</span>
                              </label>
                            </>
                          ) : null}
                          {this.state.isUserAuth !== "member" &&
                          this.state.isUserAuth !== "guest" &&
                          matches.tablet ? (
                            <>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                style={{ color: "white" }}
                                onClick={() =>
                                  this.handleMobileSelectorOpen(0, null, "file")
                                }
                                className="moreButton"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <input
                                ref={(input) => (this.bannerUpload = input)}
                                hidden
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />
                            </>
                          ) : null}
                        </div>
                      ) : (
                        //<canvas id="imgLoadCanvas"></canvas> */
                        <div>
                          {this.state.isUserAuth !== "member" &&
                          this.state.isUserAuth !== "guest" &&
                          !this.state.bannerLoading ? (
                            <>
                              <input
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />
                              <label htmlFor="mySpaceBanner">
                                <img
                                  src={ProjectAddIcon}
                                  style={{ width: "70px", height: "70px" }}
                                  alt="bannerAddIcon"
                                />
                                <span> {i18n.t("mypage.banner.addTitle")}</span>
                              </label>
                            </>
                          ) : (
                            <FadeLoader
                              color={color}
                              loading={this.state.bannerLoading}
                              css={override}
                              size={10}
                            ></FadeLoader>
                          )}
                        </div>
                      )}
                    </div>
                    {/* </Spin> */}
                    <div className="page-container">
                      <TeamInfo
                        userId={this.props.userId}
                        teamInfo={this.state.teamInfo}
                        isSelfURL={
                          this.state.isUserAuth !== "member" &&
                          this.state.isUserAuth !== "guest"
                        }
                        isUserAuth={this.state.isUserAuth}
                        history={this.props.history}
                        matches={matches}
                      />
                      <div className="project-box">
                        <div className="project-tabMenu">
                          <div className="tabMenu-list">
                            {matches.desktop &&
                              this.projectNav.map((item) => {
                                if (
                                  this.state.isUserAuth !== "member" &&
                                  this.state.isUserAuth !== "guest" &&
                                  item.id === 4
                                ) {
                                } else {
                                  return (
                                    <div
                                      className={
                                        this.state.selecMenu === item.id
                                          ? "selecMenu"
                                          : null
                                      }
                                      onClick={() =>
                                        this.handleViewProject(item.id)
                                      }
                                    >
                                      {item.id === 2 &&
                                      this.state.isUserAuth !== "member" &&
                                      this.state.isUserAuth !== "guest" &&
                                      this.state.pendingUserList &&
                                      this.state.pendingUserList[0] ? (
                                        <span className="unrede"></span>
                                      ) : null}
                                      {item.text}
                                    </div>
                                  );
                                }
                              })}
                            {matches.tablet &&
                              this.mobileProjectNav.map((item) => {
                                // if (
                                //   this.state.isUserAuth !== "member" &&
                                //   this.state.isUserAuth !== "guest" &&
                                //   item.id === 5
                                // ) {
                                // } else {
                                return (
                                  <div
                                    className={
                                      this.state.selecMenu === item.id
                                        ? "selecMenu"
                                        : null
                                    }
                                    onClick={() =>
                                      this.handleMobileViewProject(item)
                                    }
                                  >
                                    {item.text}
                                  </div>
                                );
                                // }
                              })}
                          </div>
                          <div className="tabMenu-rtBtns">
                            {this.state.selecMenu === 1 ? (
                              <>
                                {/* <img src={AddIcon} /> */}
                                {this.state.selecMenu !== 3 ? (
                                  <>
                                    {matches.desktop && (
                                      <img
                                        src={PlusIcon}
                                        onClick={this.handleImportDialogOpen}
                                        alt="importIcon"
                                      />
                                    )}
                                    {matches.tablet && (
                                      <img
                                        src={PlusIcon}
                                        onClick={this.handleImportDialogOpen}
                                        alt="importIcon"
                                      />
                                    )}
                                  </>
                                ) : null}

                                {matches.desktop && (
                                  <button className="iconBorderBtn">
                                    <img
                                      src={shareBtn}
                                      alt="shareBtn"
                                      onClick={this.handleShareDialogOpen}
                                    />
                                  </button>
                                )}
                              </>
                            ) : this.state.selecMenu === 2 ? (
                              <>
                                {matches.desktop &&
                                this.state.isUserAuth !== "guest" ? (
                                  <div className="tabMenu-members">
                                    {this.membersNav.map((item) => {
                                      if (
                                        this.state.isUserAuth === "member" &&
                                        item.id === 1
                                      ) {
                                        return;
                                      } else {
                                        return (
                                          <span
                                            onClick={() =>
                                              this.handleSelecTeamMenu(item.id)
                                            }
                                          >
                                            {item.text}
                                            {item.id === 1 &&
                                            this.state.pendingUserList &&
                                            this.state.pendingUserList[0] ? (
                                              <div className="unrede"></div>
                                            ) : null}
                                          </span>
                                        );
                                      }
                                    })}
                                    {this.state.isUserAuth !== "member" ? (
                                      <div className="switchButton">
                                        <span>
                                          {i18n.t("teamPage.adminMode.btn")}
                                        </span>

                                        <Toggle
                                          className="custom-toggle2"
                                          // defaultChecked={
                                          //   this.state.isMemberMode
                                          // }
                                          checked={this.state.isMemberMode}
                                          aria-label="No label tag"
                                          onChange={this.handleAdminMode}
                                          disabled={false}
                                          icons={false}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                                {matches.tablet &&
                                this.state.isUserAuth !== "member" ? (
                                  <>
                                    {this.state.isUserAuth !== "guest" ? (
                                      <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        // size="20"
                                        // style={{ fontSize: "2.2rem" }}
                                        className="dottedMoreBtn"
                                        onClick={() =>
                                          this.handleMobileSelectorOpen(1)
                                        }
                                        // className="moreButton"
                                      >
                                        <MoreVertIcon
                                          style={{ fontSize: "2.2rem" }}
                                        />
                                      </IconButton>
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            ) : this.state.applicnatsView ? (
                              <div
                                className="move_jobList"
                                onClick={() =>
                                  this.setState({
                                    applicnatsView: false,
                                  })
                                }
                              >
                                <img src={BlackArrowIcon} alt="goToJopList" />
                                <span>채용리스트로 이동</span>
                              </div>
                            ) : (
                              <div className="tabMenu-members">
                                {matches.tablet &&
                                this.state.selecMenu !== 4 ? (
                                  <IconButton
                                    // aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    size="20"
                                    // style={{ color: "white" }}
                                    // className="dottedMoreBtn"
                                    onClick={() =>
                                      this.handleMobileSelectorOpen(0, true)
                                    }
                                    // className="moreButton"
                                  >
                                    <MoreVertIcon
                                      style={{ fontSize: "2.2rem" }}
                                    />
                                  </IconButton>
                                ) : (
                                  <>
                                    {this.state.isUserAuth !== "guest" ? (
                                      this.state.isUserAuth === "member" ||
                                      this.state.selecMenu !== 3 ? null : (
                                        <span
                                          onClick={() =>
                                            this.toJobUpload(
                                              this.state.teamInfo._id
                                            )
                                          }
                                        >
                                          {i18n.t("teamPage.job.nav1")}
                                        </span>
                                      )
                                    ) : null}
                                    {this.state.isUserAuth !== "guest" &&
                                    this.state.isUserAuth !== "member" &&
                                    this.state.selecMenu === 3 ? (
                                      <div className="switchButton">
                                        <span onClick={this.handleAdminMode}>
                                          {i18n.t("teamPage.job.nav2")}
                                        </span>
                                        <Toggle
                                          className="custom-toggle2"
                                          // defaultChecked={
                                          //   this.state.isJobMemberMode
                                          // }
                                          checked={this.state.isJobMemberMode}
                                          aria-label="No label tag"
                                          onChange={this.handleAdminMode}
                                          disabled={false}
                                          icons={false}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="project-list-container">
                          {this.renderSearch(this.state.selecMenu)}
                          {/* {matches.desktop && */}
                          {matches.desktop &&
                            this.desktopRender(this.state.selecMenu)}
                          {matches.tablet &&
                            this.mobileRender(this.state.selecMenu)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BottomSheet
                className="bottom_sheet-job"
                open={this.state.jobMobileSelectOpen}
                onDismiss={() => this.setState({ jobMobileSelectOpen: false })}
              >
                <div className="bottom-colums">
                  {this.state.isUserAuth !== "guest" ? (
                    this.state.isUserAuth === "member" ? null : (
                      <span
                        onClick={() =>
                          this.toJobUpload(this.state.teamInfo._id)
                        }
                      >
                        {i18n.t("teamPage.job.nav1")}
                      </span>
                    )
                  ) : null}
                  {this.state.isUserAuth !== "guest" &&
                  this.state.isUserAuth !== "member" &&
                  this.state.selecMenu === 3 ? (
                    <div className="switchButton">
                      <span onClick={this.handleAdminMode}>
                        {i18n.t("teamPage.job.nav2")}
                      </span>
                      <Toggle
                        className="custom-toggle2"
                        // defaultChecked={
                        //   this.state.isJobMemberMode
                        // }
                        checked={this.state.isJobMemberMode}
                        aria-label="No label tag"
                        onChange={this.handleAdminMode}
                        disabled={false}
                        icons={false}
                      />
                    </div>
                  ) : null}
                </div>
              </BottomSheet>
              <BottomSheet
                className="bottom_sheet-job"
                open={this.state.memberNavOpen}
                onDismiss={() => this.setState({ memberNavOpen: false })}
              >
                <div className="bottom-colums">
                  {this.membersNav.map((item) => {
                    if (this.state.isUserAuth === "member" && item.id === 1) {
                      return;
                    } else {
                      return (
                        <span
                          onClick={() => {
                            this.setState({ memberNavOpen: false });
                            this.handleSelecTeamMenu(item.id);
                          }}
                        >
                          {item.text}
                          {item.id === 1 &&
                          this.state.pendingUserList &&
                          this.state.pendingUserList[0] ? (
                            <div className="unrede"></div>
                          ) : null}
                        </span>
                      );
                    }
                  })}

                  {this.state.isUserAuth !== "guest" &&
                  this.state.isUserAuth !== "member" ? (
                    <div className="switchButton">
                      <span>{i18n.t("teamPage.adminMode.btn")}</span>

                      <Toggle
                        className="custom-toggle2"
                        // defaultChecked={
                        //   this.state.isMemberMode
                        // }
                        checked={this.state.isMemberMode}
                        aria-label="No label tag"
                        onChange={() => {
                          this.handleAdminMode();
                          this.setState({ memberNavOpen: false });
                        }}
                        disabled={false}
                        icons={false}
                      />
                    </div>
                  ) : null}
                </div>
              </BottomSheet>
            </>
          )}
        </Media>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
export default compose(withSnackbar, connect(mapStateToProps))(TeamPage);
