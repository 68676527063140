import React, { Component } from "react";
import i18n from "../../../../i18n";
import ViewImg from "../../../../image/view-icon.svg";
import LikeImg from "../../../../image/like-icon.svg";
// import settingIcon from "../../../../image/ProjectIcon/project-settings-icon.svg";
// import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Avatar, Menu, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { withSnackbar } from "notistack";
class Project extends Component {
  // "준비중인 기능입니다."
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration: object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate: object && typeof object.preventDuplicate === "boolean" ? object.preventDuplicate : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    }
    this.props.enqueueSnackbar(text, valueObject);
  }
  render() {
    const {
      thumbnail,
      title,
      creatorPhoto,
      creatorName,
      likeMembers,
      views,
      createdAt,
      postId,
    } = this.props;

    const menu = (
      <Menu>
        <Menu.Item
          value={ postId }
          onClick={ this.props.handleGridViewProjPopUp() }
        >
          <div>{ i18n.t("게시글 보기") }</div>
        </Menu.Item>
        <Menu.Item onClick={ () => this.handleAlert("준비중인 기능입니다.") }>
          <div>{ i18n.t("레퍼런스 조정으로 복사") }</div>
        </Menu.Item>
        <Menu.Item onClick={ () => this.handleAlert("준비중인 기능입니다.") }>
          <div>{ i18n.t("깃발 분류") }</div>
        </Menu.Item>
        <Menu.Item onClick={ () => this.handleAlert("준비중인 기능입니다.") }>
          <div>{ i18n.t("게시물 제한") }</div>
        </Menu.Item>
        <Menu.Item onClick={ () => this.handleAlert("준비중인 기능입니다.") }>
          <div>{ i18n.t("게시물 삭제") }</div>
        </Menu.Item>
        <Menu.Item
          value={ postId }
          onClick={ this.props.handleTagsAndCatePopUpFromGrid() }
        >
          <div>{ i18n.t("카테고리 및 태그 수정") }</div>
        </Menu.Item>
        <Menu.Item onClick={ () => this.handleAlert("준비중인 기능입니다.") }>
          <div>{ i18n.t("작성자에게 메시지 보내기") }</div>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <div
          className="imgList-screen"
          style={ {
            cursor: "pointer",
            backgroundImage: `url(${thumbnail})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
          } }
        >
          <div
            className="hidden-info"
            // onClick={this.handleContextMenu(postId)}
            style={ {
              position: "absolute",
              top: "10px",
              display: "flex",
              right: "5px",
            } }
          >
            {/* <img src={labelImg} alt="label" /> */ }
            <Dropdown overlay={ menu } placement="bottomRight" arrow>
              {/* <Button>bottomCenter</Button> */ }
              {/* <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                // onClick={handleClick}
              > */}
              <MoreVertIcon />
              {/* </IconButton> */ }
            </Dropdown>
          </div>
        </div>
        <div
          className="imgList-info"
          style={
            {
              // whiteSpace: 'noWrap',
            }
          }
        >
          {/* {title.length >= 20 ? title.substr(0, 20) + '...' : title} */ }
          <div
            style={ {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginRight: "5px",
            } }
          >
            { title }
          </div>

          <span
            style={ {
              // textOverflow: 'ellipsis',
              whiteSpace: "nowrap",
              // overflow: 'hidden',
            } }
          >
            { createdAt.substring(0, 10) }
          </span>
        </div>
        <div className="imgList-info">
          <div className="imgList-profile">
            { !creatorPhoto ? (
              <Avatar size={ 20 } icon={ <UserOutlined /> } />
            ) : (
              <div
                style={ {
                  backgroundImage: `url(${creatorPhoto})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                } }
              />
            ) }

            <span>{ !creatorName ? null : creatorName }</span>
          </div>
          <div className="imgList-views">
            <img src={ LikeImg } alt="label" />
            <span>{ likeMembers }</span>
            <img src={ ViewImg } alt="label" />
            <span>{ views }</span>
          </div>
        </div>
      </>
    );
  }
}

export default withSnackbar(Project);
