import React, { Component } from "react";
import "./ProfilePhoto.scss";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

class ProfilePhoto extends Component {
  render() {
    const { src, size, cursor, click } = this.props;
    return src ? (
      <div
        className="profile-photo"
        onClick={click}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${src})`,
          cursor: cursor ? cursor : null,
          width: `${size}px`,
          height: `${size}px`,
          outline: "unset",
          borderRadius: "50%",
          border: "1px solid #efefef",
        }}
        alt="profilePhoto"
      ></div>
    ) : (
      <Avatar onClick={click} size={size} icon={<UserOutlined />} />
    );
  }
}
export default ProfilePhoto;
