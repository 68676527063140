import React, { Component } from "react";
import "./MobileSelector.scss";
import { withStyles } from "@material-ui/core/styles";
import { BottomSheet } from "react-spring-bottom-sheet";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class MobileSelector extends Component {
  state = {
    selecList: "",
  };
  handleClickList = (item) => {
    const teamInfo = this.props.teamInfo;
    if (teamInfo) {
      this.setState({ selecList: item.id }, () => {
        if (!this.props.Button) {
          this.props.handleSelec(this.props.teamInfo, item.id);
        }
      });
    } else {
      this.setState({ selecList: item.id }, () => {
        if (!this.props.Button) {
          if (this.props.file && item.id === 1) {
            this.imgFile.click();
          }
          this.props.handleSelec(item.id);
        }
      });
    }
  };

  render() {
    const { onClose, open, Button, selecList } = this.props;

    return (
      <BottomSheet open={open} onDismiss={onClose}>
        <input
          hidden
          type="file"
          ref={(ref) => (this.imgFile = ref)}
          onChange={this.props.fileOnChage}
        />
        {selecList
          ? selecList.map((item) => {
              return item.show === false ? null : (
                <div
                  key={`mobile-selector-checkedList_${item.id}`}
                  className={`mobile-selector-items ${
                    this.state.selecList === item.id
                      ? "mobile-selector-checkedList"
                      : ""
                  }`}
                >
                  {item.icon ? <img src={item.icon} alt="SelecIcon" /> : null}
                  <span onClick={() => this.handleClickList(item)}>
                    {item.text}
                  </span>
                </div>
              );
            })
          : null}
        {Button ? (
          <div className="mobile-selector-btns">
            <span onClick={onClose}>Cancel</span>
            <span onClick={() => this.props.handleSelec(this.state.selecList)}>
              Apply
            </span>
          </div>
        ) : null}
      </BottomSheet>
    );
  }
}

export default withStyles(styles)(MobileSelector);
