import React, { Component } from 'react'
import './FindComplete.scss'
import CKIcon from '../../image/check-icon.svg'
import i18n from '../../i18n'
class FindComplete extends Component {
    state = {}
    toLogin = () => {
        this.props.history.push('/login')
    }
    render() {
        return (
            <div className="pwComplete-container">
                <div className="complete-box">
                    <div className="complete-header">
                        <span>{i18n.t('global.change.sucess')}</span>
                    </div>
                    <div className="complete-icon">
                        <img src={CKIcon} alt="checkIcon" />
                    </div>
                    <div className="complete-text">
                        <span>{i18n.t('findPassword.completeMsg')}</span>
                    </div>
                    <button id="nextBtn" type="button" onClick={this.toLogin}>
                        {i18n.t('userInfo.toLogin')}
                    </button>
                </div>
            </div>
        )
    }
}

export default FindComplete
