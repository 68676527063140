import React, { Component } from "react";
import "./PersonalDialog.scss";
import { Dialog } from "@material-ui/core";
// import { withStyles } from '@material-ui/core/styles'
import closeIcon from "../../image/close-icon.svg";
import i18n from "../../i18n";
import { connect } from "react-redux";
import { langChange } from "../../Redux/action/index";
// const styles = {
//     maxWidthXs: {
//         maxWidth: '520px',
//     },
// }

class TermsDialog extends Component {
  state = {};
  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"termsDialog"}
          open={open}
          onClose={onClose}
        >
          <div className="closeBtn" onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </div>
          <div className="term-button-dialog-container terms">
            {this.props.lang === "ko" ? (
              <div className="terms_container">
                <div className="terms_header">
                  <span>{i18n.t("terms.condition.privacy")}</span>
                </div>
                <div className="terms_title">
                  <span className="title-header">
                    <p>
                      모션트렌드 주식회사("이하 회사")는 정보통신망 이용촉진 및
                      정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
                      전기통신사업법, 등 정보통신서비스제공자가 준수하여야 할
                      관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
                      의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을
                      다하고 있습니다. 본 개인정보처리방침은 회사가 제공하는
                      모션트렌드 및 모션트렌드 관련 제반 서비스 이용에 적용되며
                      다음과 같은 내용을 담고 있습니다.
                    </p>
                  </span>
                  <span className="title-header">
                    1. 수집하는 개인정보의 항목 및 수집방법
                  </span>
                  <p>
                    가. 수집하는 개인정보의 항목 첫째, 회사는 편리한 회원 가입
                    및 서비스 이용을 위하여 다양한 소셜 로그인을 지원하며, 이
                    과정에서 아래와 같이 회원의 개인정보를 처리합니다. 단,
                    회원가입을 위해 지원하는 소셜 로그인의 종류와 방식은
                    국가별로 다를 수 있습니다.
                    <br></br>- 구글 아이디로 회원 가입: 이메일 주소, 공개 프로필
                    정보(이름, 프로필 사진, 연령대, 성별)<br></br>- 네이버
                    아이디로 회원 가입: 네이버 아이디, 별명, 프로필 사진, 이메일
                    주소, 나이, 성별<br></br>- 페이스북 아이디로 회원 가입:
                    이메일 주소, 공개 프로필 정보(이름, 프로필 사진, 연령대,
                    성별)
                    <br></br>- 카카오 아이디로 회원 가입: 이메일 주소, 공개
                    프로필 정보(이름, 프로필 사진, 연령대, 성별)<br></br>-
                    이메일 로그인: 이메일 주소, 이름, 사용자 이름(URL)<br></br>
                    <br></br>
                    둘째, 회원의 선택에 의해 프로필 정보(프로필 이미지, 국가,
                    성별, 나이, 대표 사이트, 공개용 이메일주소, 활동분야, SNS,
                    히스토리)가 수집될 수 있습니다. 또한 서비스 이용 과정에서 IP
                    Address, 쿠키, 방문일시, 서비스 이용기록, 불량 이용기록,
                    기기정보, 결제정보가 수집될 수 있으며, 고객 문의 접수 및
                    처리 과정에서 이메일 주소나 전화번호가 수집될 수도 있습니다.
                  </p>
                  <p>
                    나. 개인정보 수집방법<br></br>
                    회원 가입 및 회원정보 수정, 이벤트 응모, 생성정보 수집 툴을
                    통한 수집<br></br>
                    웹페이지, 팩스, 전화 등을 통한 고객센터 문의 접수
                  </p>
                  <span className="title-header">
                    2. 개인정보의 수집 및 이용목적
                  </span>
                  <p>
                    가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른
                    요금정산 웹사이트 및 모바일 서비스 이용을 위한 컨텐츠 제공,
                    특정 맞춤 서비스 제공, 모션트렌드 및 모션트렌드 관련 제반
                    서비스에서 제공하는 특정 맞춤 서비스 이용, 물품배송 또는
                    청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심
                  </p>
                  <p>
                    나. 회원관리 회원제 서비스 제공, 개인식별, 모션트렌드
                    이용약관 위반 회원에 대한 이용제한 조치, 서비스의 원활한
                    운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재,
                    가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한
                    기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴
                    의사의 확인
                  </p>
                  <p>
                    다. 신규 서비스 개발 및 마케팅·광고에의 활용 신규 서비스
                    개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공
                    및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회
                    제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에
                    대한 통계
                  </p>
                  <span className="title-header">
                    3. 개인정보의 공유 및 제공
                  </span>
                  <p>
                    회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및
                    이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전
                    동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
                    이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
                    경우에는 예외로 합니다.
                  </p>
                  <p>
                    가. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                    절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </p>
                  <span className="title-header">
                    4. 개인정보의 보유 및 이용기간
                  </span>
                  <p>
                    이용자는 개인정보주체로서 다음과 같은 이용자의 개인정보는
                    원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
                    파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한
                    기간 동안 보존합니다.
                  </p>
                  <p>
                    가. 회사 내부 방침에 의한 정보보유 사유 - 부정이용기록
                    <br></br>
                    보존 이유 : 부정 이용 방지<br></br>
                    보존 기간 : 1년<br></br>
                  </p>
                  <p>
                    나. 관련법령에 의한 정보보유 사유 상법, 전자상거래 등에서의
                    소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할
                    필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안
                    회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그
                    보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                  </p>
                  <p>
                    - 계약 또는 청약철회 등에 관한 기록 보존 이유 : 전자상거래
                    등에서의 소비자보호에 관한 법률 보존 기간 : 5년
                  </p>
                  <p>
                    - 대금결제 및 재화 등의 공급에 관한 기록 보존 이유 :
                    전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 : 5년
                  </p>
                  <p>
                    - 소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유 :
                    전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 : 3년
                  </p>
                  <p>
                    - 웹사이트 방문기록 보존 이유 : 통신비밀보호법 보존 기간 :
                    3개월
                  </p>
                  <span className="title-header">
                    5. 개인정보 파기절차 및 방법
                  </span>
                  <p>
                    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
                    달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및
                    방법은 다음과 같습니다.
                  </p>
                  <p>
                    가. 파기절차<br></br> -이용자가 회원가입 등을 위해 입력한
                    정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
                    별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호
                    사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후
                    파기됩니다.<br></br> -동 개인정보는 법률에 의한 경우가
                    아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                    나. 파기방법 -종이에 출력된 개인정보는 분쇄기로 분쇄하거나
                    소각을 통하여 파기합니다.<br></br>
                    -전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                    기술적 방법을 사용하여 삭제합니다.
                  </p>
                  <span className="title-header">
                    6. 이용자 권리와 그 행사방법
                  </span>
                  <p>
                    -이용자는 언제든지 등록되어 있는 자신의 개인정보를
                    조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에
                    동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를
                    요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는
                    전부 이용이 어려울 수 있습니다.<br></br>
                    -이용자의 개인정보 조회, 수정을 위해서는 '내 정보 관리'(또는
                    '회원정보수정' 등)를, 가입해지(동의철회)를 위해서는
                    "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람,
                    정정 또는 탈퇴가 가능합니다.<br></br>
                    -혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로
                    연락하시면 지체 없이 조치하겠습니다.<br></br>
                    -이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는
                    정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                    않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한
                    경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이
                    이루어지도록 하겠습니다.<br></br>
                    -회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "5.
                    개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그
                    외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                  </p>
                  <span className="title-header">
                    7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                  </span>
                  <p>
                    가. 쿠키란?<br></br>
                    -회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서
                    이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를
                    사용합니다.<br></br>
                    <br></br>
                    -쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지
                    않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나
                    삭제할 수 있습니다.<br></br>
                    나. 회사의 쿠키 사용 목적<br></br>
                    이용자들이 방문한 모션트렌드 서비스에 대한 방문 및 이용형태,
                    인기 검색어, 이용자 규모 등을 파악하여 이용자에게 광고를
                    포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
                  </p>
                  <p>
                    다. 쿠키의 설치/운영 및 거부<br></br>
                    -이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
                    이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
                    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                    모든 쿠키의 저장을 거부할 수도 있습니다.<br></br>
                    -다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한
                    모션트렌드 일부 서비스는 이용에 어려움이 있을 수 있습니다.
                    <br></br>
                    -쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의
                    경우)은 다음과 같습니다.<br></br>
                    ①[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br></br>
                    ②[개인정보 탭]을 클릭합니다.<br></br>
                    ③[개인정보처리 수준]을 설정하시면 됩니다.<br></br>
                  </p>
                  <span className="title-header">
                    8. 개인정보의 기술적/관리적 보호 대책
                  </span>
                  <p>
                    회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실,
                    도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                    다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
                  </p>
                  <p>가. 비밀번호 암호화</p>
                  <p>
                    모션트렌드 회원 유저네임(Username)의 비밀번호는 암호화되어
                    저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인
                    및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
                  </p>
                  <p>
                    나. 해킹 등에 대비한 대책 회사는 해킹이나 컴퓨터 바이러스
                    등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기
                    위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
                    자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여
                    이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록
                    방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서
                    개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
                    침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
                    있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
                    기술적 장치를 갖추려 노력하고 있습니다.
                  </p>
                  <p>
                    다. 처리 직원의 최소화 및 교육 회사의 개인정보관련 처리
                    직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를
                    부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시
                    교육을 통하여 모션트렌드 개인정보처리방침의 준수를 항상
                    강조하고 있습니다.
                  </p>
                  <p>
                    라. 개인정보보호전담기구의 운영 그리고 사내
                    개인정보보호전담기구 등을 통하여 모션트렌드
                    개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여
                    문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록
                    노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의
                    문제로 유저네임, 비밀번호 등 개인정보가 유출되어 발생한
                    문제에 대해 회사는 일체의 책임을 지지 않습니다.
                  </p>
                  <span className="title-header">
                    9. 개인정보관리책임자 및 담당자의 연락처
                  </span>
                  <p>
                    귀하께서는 회사의 서비스를 이용하시며 발생하는 모든
                    개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로
                    신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해
                    신속하게 충분한 답변을 드릴 것입니다.
                  </p>
                  <p>
                    개인정보 보호책임자<br></br>이 름 : 홍길동<br></br>전 화 :
                    1234-1234
                    <br></br>메 일 : 문의하기
                  </p>
                  <p>
                    개인정보 보호담당자<br></br>이 름 : 홍길동<br></br>전 화 :
                    1234-1234
                    <br></br>메 일 : 문의하기
                  </p>
                  <p>
                    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
                    아래 기관에 문의하시기 바랍니다.<br></br>-
                    개인정보침해신고센터 (www.118.or.kr / 국번없이 118)<br></br>
                    - 대검찰청 사이버범죄수사단 (www.spo.go.kr / 국번없이 1301)
                    <br></br>- 경찰청 사이버테러대응센터 (www.ctrc.go.kr /
                    국번없이 182)
                  </p>
                  <span className="title-header">10. 기타</span>
                  <p>
                    모션트렌드에 링크되어 있는 웹사이트들이 개인정보를 수집하는
                    행위에 대해서는 본 "모션트렌드 개인정보처리방침"이 적용되지
                    않음을 알려 드립니다.
                  </p>
                  <span className="title-header">11. 고지(통지) 의무</span>
                  <p>
                    현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는
                    개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지 또는
                    '이메일'을 통해 통지할 것입니다. 다만, 개인정보의 수집 및
                    활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을
                    경우에는 최소 30일 전에 고지 또는 통지합니다.<br></br>
                    <br></br>- 공고일자: 2021년 2월 2일
                    <br></br>- 시행일자: 2021월 3일 2일
                    <br></br>
                  </p>
                </div>

                <div className="termsBtn">
                  <button onClick={onClose} type="button">
                    {i18n.t("global.ok")}
                  </button>
                </div>
              </div>
            ) : (
              // 영문 버전 적용
              <div className="terms_container">
                <div className="terms_header">
                  <span>{i18n.t("terms.condition.privacy")}</span>
                </div>
                <div className="terms_title">
                  <span className="title-header">
                    <p>
                      MotionTrend Corporation (hereinafter the "Company") abides
                      by privacy protection regulations in all laws an
                      information communications service provider must abide by,
                      including the Act on Promotion of Information and
                      Communications Network Utilization and Information
                      Protection, Privacy Protection Act, Protection of
                      Communications Secrets Act and the Telecommunications
                      Business Act, and provides a Privacy Policy that is based
                      on related laws and regulations to protect user’s rights
                      and interests. This MotionTrend Privacy Policy is applied
                      to the use of Motiontrend and its relating services
                      provided by the Company, and contains the following
                      information.
                    </p>
                  </span>
                  <span className="title-header">
                    1. Collected personal information and method
                  </span>
                  <p>
                    <br></br>
                    A. Collected personal information
                    <br></br>
                    First, The company supports various SNS logins for
                    convenient member registration and service provision, and
                    during this process processes the following personal
                    information. However, the types and methods of SNS login may
                    vary by country.<br></br>
                    <br></br>- Register with Google: Email address, public
                    profile information (name, profile picture, age, gender)
                    <br></br>- Register with Naver ID: Naver ID, nickname,
                    profile picture, email address, age, gender
                    <br></br>- Register with Facebook: Email address, public
                    profile information (name, profile picture, age, gender)
                    <br></br>- Register with Kakao ID: Kakao ID, nickname,
                    profile picture, email address, age, gender
                    <br></br>- Email login: Email address, name, user name (URL)
                    <br></br>
                    <br></br>
                    Second, by user’s choice, profile information (profile
                    image, country, gender, age, website, public email address,
                    area of activity, social media service, history) can be
                    collected. Also, while using the services, IP Address,
                    cookie, date of visit, service usage information, bad usage
                    record, device information, and payment information can be
                    collected. Email address and phone number may be collected
                    while receiving and processing customer inquiry.
                  </p>
                  <p>
                    B. How personal information is collected
                    <br></br>
                    Collect through subscription, member information
                    modification, event participation and through tools for
                    collecting generated information
                    <br></br>
                    Through accepting inquiries in Customer Center via web page,
                    fax and phone
                  </p>
                  <span className="title-header">
                    2. Personal Information Collection and Purpose of Use
                  </span>
                  <p>
                    A. Executing agreements made to provide services, and when
                    settling accounts for the provided services When providing
                    contents for the use of web sites and mobile service,
                    customized serviced provided by Motiontrend and
                    Motiontrend-based services, delivering goods or invoices,
                    self-authentication and during purchase and fee payment and
                    collection
                  </p>
                  <p>
                    B. Managing members When providing subscription services,
                    personal identification, imposing restrictions to members
                    who violate the Motiontrend terms of use, restricting
                    actions that interrupt operation of the service and illegal
                    use of the service, confirming the intention to subscribe;
                    restricting subscriptions and number of subscriptions,
                    restoring records for dispute arbitration, handling customer
                    services including complaints, delivering notifications and
                    confirming the intention to unsubscribe.
                  </p>

                  <p>
                    C. Developing new services and use in marketing, advertising
                    activities When developing new services and providing
                    customized service, providing services based on statistical
                    characteristics and placing advertisements, confirming the
                    validity of the service, providing event information and
                    opportunities to participate, providing advertising
                    information, identifying access frequency, collecting
                    statistics on member’s service usage.
                  </p>
                  <span className="title-header">
                    3. Disclosure and Provision of Personal Information
                  </span>
                  <p>
                    The company must use the user’s personal information within
                    the range specified in "2. Personal Information Collection
                    and Purpose of Use", not exceed the said range without prior
                    consent from the user, and as a principal rule, not disclose
                    users’ information. However, the following is an exception.
                  </p>
                  <p>
                    A. When requested by an investigative agency, through
                    procedures and methods defined by law, for investigative
                    purposes, in accordance with legal regulations.
                  </p>
                  <span className="title-header">
                    4. Personal Information Retention and Use Term
                  </span>
                  <p>
                    User’s personal information is discarded immediately after
                    the purpose of its collection and use has been achieved.
                    However, the following information is stored for below
                    purpose over the stated duration.
                  </p>
                  <p>
                    A. If the information is required to be stored according to
                    internal regulations set forth by the Company<br></br>-
                    Delinquent use record<br></br>
                    Storage reason: Prevent delinquent uses
                    <br></br>
                    Storage period: One (1) year
                  </p>
                  <p>
                    B. If the information is required to be stored according to
                    regulations of related laws<br></br>
                    The users’ personal information can be stored for a certain
                    period of time, as is stipulated by related laws, in the
                    event the information is required to be stored according to
                    regulations of related laws, such as commercial law and the
                    Act on the Consumer Protection in the Electronic Commerce
                    Transactions. In such cases, the information will only be
                    used for the stored purpose, over the duration stated below.
                  </p>
                  <p>
                    - Records of subscription or withdrawal from subscription
                    <br></br>
                    Storage reason: Act on the Consumer Protection in the
                    Electronic Commerce Transactions, etc.<br></br>
                    Storage period: Five (5) years
                  </p>
                  <p>
                    - Records of supplying, such as goods and payment
                    settlements<br></br>
                    Storage reason: Act on the Consumer Protection in the
                    Electronic Commerce Transactions, etc.<br></br>
                    Storage period: Five (5) years
                  </p>
                  <p>
                    - Records of customer complaints or dispute settlements
                    <br></br>
                    Storage reason: Act on the Consumer Protection in the
                    Electronic Commerce Transactions, etc.<br></br>
                    Storage period: Three (3) years
                  </p>
                  <p>
                    - History of website visits<br></br>
                    Storage reason: Protection of Communications Secrets Act
                    <br></br>
                    Storage period: Three (3) months
                  </p>
                  <span className="title-header">
                    5. Procedure and Methods of Personal Information Destruction
                  </span>
                  <p>
                    The Company deletes personal information immediately after
                    the purpose of its use has been achieved. The destruction
                    procedure and methods are as follows.
                  </p>
                  <p>
                    A. Destruction procedure<br></br>
                    -Information that a user inserts when subscribing to a
                    service is relocated to a separate database (a separate file
                    for paper documents) after the purpose of its use has been
                    achieved. The information is stored in that location for a
                    legally permitted period of time for specific purposes
                    (refer to "Retention and Use Term") before being deleted.
                    <br></br>
                    -The personal information stated above is not used for other
                    purposes, unless required by law.<br></br>
                    B. Destruction method<br></br>
                    -Personal information printed on paper is destroyed with a
                    paper shredder or incinerator.<br></br>
                    -Personal information stored in electronic files is deleted
                    using technical methods that prevent its restoration.
                  </p>
                  <span className="title-header">
                    6. Rights of Users and Exercising Those Rights
                  </span>
                  <p>
                    -The user can view and modify his/her registered personal
                    information at any time. In the event the user does not
                    agree with the Company’s treatment of personal information,
                    the user may refuse to approve to the Company’s handling of
                    the personal information or request for a subscription
                    cancellation (withdrawal). However, the user may have
                    difficulties using portions of, or the whole service in such
                    an event.<br></br>
                    -Click [My Settings] to view or modify personal information
                    of the user; to withdraw his/her membership (withdraw
                    consent), the user must click [Delete Account] and directly
                    view, modify information and cancel his/her membership.
                    <br></br>
                    -Or contact the chief privacy officer via written document,
                    phone or email, and immediate measures will be enforced.
                    <br></br>
                    -If a user requests for correction of his/her personal
                    information, the user cannot use or access his/her
                    information until the correction is completed. If a user’s
                    incorrect personal information has already been provided to
                    a third party, the corrections made will be notified
                    immediately to the third party, so that necessary
                    corrections are made.
                    <br></br>
                    -The company processes personal information that has been
                    deleted at the user’s request or at the request of a legal
                    representative according to the terms and conditions
                    described in "5. Personal Information Retention and Use
                    Term", and imposes necessary measures to prevent the
                    information from being viewed or used for other purposes.
                  </p>
                  <span className="title-header">
                    7. Installation/Management and Rejection of Automated
                    Collection of Personal Information
                  </span>
                  <p>
                    A. What is a "cookie"?<br></br>
                    -For the purpose of providing a personalized and customized
                    service, the company uses a "cookie" to store and retrieve
                    user’s information as needed.
                    <br></br>
                    -A cookie is a very small text file that is sent from the
                    server that is used to operate the website to the user’s
                    browser, and is saved in the hard disk of the user’s
                    computer. When the user visits the website at a later date,
                    the website server reads the contents of the cookie that is
                    saved in the user’s hard disk to maintain the user’s
                    environment setting and provide customized service to the
                    user.<br></br>
                    -A cookie does not automatically or actively collect
                    information that can be used to identify an individual. A
                    user can always refuse to save these cookies or delete the
                    cookies.<br></br>
                    B. Company’s use of cookies<br></br>
                    The company uses cookies to analyze the user’s visiting or
                    use pattern of the Motiontrend service, popular search
                    keywords and user volume to provide an optimized and
                    customized service that includes advertisement to users.
                  </p>
                  <p>
                    C. Installing/managing/rejecting cookies
                    <br></br>
                    -The user has the right to decide whether or not to install
                    cookies. The user can use the option setting in the web
                    browser to either allow all cookies, check every time a
                    cookie is saved, or refuse to save all cookies.<br></br>
                    -However, if the user decides not to save the cookies,
                    he/she may have difficulties using some Motiontrend services
                    that require the user to login.
                    <br></br>
                    -How to set whether to allow cookies installation (For
                    Internet Explorer users).<br></br>
                    ①Go to the [Tools] menu and select [Internet Option] ②Click
                    the [Privacy] tab. ③Set the privacy level.
                  </p>
                  <span className="title-header">
                    8. Technical/Administrative Protective Measure for Personal
                    Information
                  </span>
                  <p>
                    The company has come up with the following
                    technical/administrative measures to ensure that is safely
                    secured, and not lost, stolen, leaked, altered or damaged
                    when processed.
                  </p>
                  <p>A. Encrypting passwords</p>
                  <p>
                    Motiontrend member’s usernames are encrypted when saved and
                    managed so that it can only be verified by the user.
                    Personal information can only be viewed and changed by the
                    user who knows the password.
                  </p>
                  <p>
                    B. Countermeasures against hacking attempts<br></br>
                    The company is making its utmost efforts to prevent member’s
                    personal information from being leaked or damaged due to
                    hacking attempts or computer viruses.
                    <br></br>
                    The company is backing up resources regularly to prepare for
                    any possible damages made in the personal information, and
                    is uses the latest vaccine programs to prevent user’s
                    personal information or resources from being leaked or
                    damaged. The company is also using methods, such as
                    encrypted communication, to safely transmit personal
                    information over the network.
                    <br></br>
                    The company also uses a firewall system to control all
                    unauthorized access from the outside, while making efforts
                    to prepare all possible technical measures to secure
                    security in the systems.
                  </p>
                  <p>
                    C. Minimizing employees who access the information and
                    provide training
                    <br></br>
                    The right to handle personal information is limited to the
                    employee in charge. A separate password is granted for this
                    purpose and renewed at a regular basis. The employee in
                    charge undergoes regular training to emphasize the
                    importance of abiding by Motiontrend Privacy Policy.
                  </p>
                  <p>
                    D. Operating an exclusive organization for privacy
                    protection<br></br>
                    The company operates an exclusive organization for privacy
                    protection to confirm that the Motiontrend Privacy Policy is
                    being executed properly and to verify whether the employee
                    in charge is abiding by the policy. Efforts are also made to
                    immediately correct any issues upon detection of such
                    problems.
                    <br></br>
                    However, the company will not be responsible for any
                    problems that arise from personal information, such as IDs
                    or password, which is leaked due to the user’s negligence or
                    problems in the internet.
                  </p>
                  <span className="title-header">
                    9. Contacting the Chief Privacy Officer and Personal
                    Information Manager
                  </span>
                  <p>
                    You may file all privacy-related complaints, which arise
                    during the use of the service, to the chief privacy officer
                    or related department. The company will provide you with an
                    immediate and sufficient response to your inquiry.
                  </p>
                  <p>
                    Chief Privacy Officer<br></br>
                    Name : Gildong Hong<br></br>
                    Email : for inquiries
                  </p>
                  <p>
                    Personal Information Manager<br></br>
                    Name : Gildong Hong<br></br>
                    Email : for inquiries
                  </p>
                  <p>
                    Contact the organizations shown below to file reports or
                    seek consultation for other privacy infringements;<br></br>-
                    Privacy Infringement Report Center (www.118.or.kr )<br></br>
                    - Cyber Crime Investigation Unit, Supreme Prosecutor’s
                    Office (www.spo.go.kr)<br></br>- Cyber Terror Response
                    Center, National Police Agency ( www.ctrc.go.kr)
                  </p>
                  <span className="title-header">10. Application</span>
                  <p>
                    The "Motiontrend Privacy Policy" does not apply to any
                    collection of personal information that is conducted in
                    websites that are linked to the Motiontrend service.
                  </p>
                  <span className="title-header">
                    11. Notice (Notification) obligations
                  </span>
                  <p>
                    Any addition, change, or deletion to the current Privacy
                    Policy will be notified 7 days in advance as a
                    ‘Notification’ on the website or through ‘email’. However,
                    important changes to user’s rights such as changes to
                    collection or use of personal information or provision of
                    information to third parties shall be notified 30 days in
                    advance.<br></br>
                    <br></br>- Announcement Date: February 2nd, 2021<br></br>-
                    Enforcement Date: march 2th, 2021<br></br>
                    <br></br>
                    This Privacy Policy is originally written in Korean and this
                    is a translated version thereof. Should there be a
                    discordance between the Korean and English version, the
                    Korean Privacy Policy shall be primarily applied.
                  </p>
                </div>

                <div className="termsBtn">
                  <button onClick={onClose} type="button">
                    {i18n.t("global.ok")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TermsDialog);
