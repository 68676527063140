import React, { Component } from "react";
import DropdownIcon from "../../image/line-black-down-arrow-icon.svg";
import "./CheckBoxDropDown.scss";
import { ClickAwayListener } from "@material-ui/core";
import i18n from "../../i18n";
class CheckBoxDropDown extends Component {
  state = {
    open: false,
  };
  render() {
    const {
      text,
      list,
      checked,
      selectedCount,
      open,
      className,
      onOpen,
      onClose,
    } = this.props;

    return (
      <ClickAwayListener onClickAway={onClose}>
        <div className={`dropdown_wrapper ${className}`} onClick={onOpen}>
          <div className="drobdonw_title">
            <span>{text}</span>
            {selectedCount ? <span>+{selectedCount}</span> : null}
          </div>
          <img
            alt="open"
            src={DropdownIcon}
            style={open ? { transform: "rotate(180deg)" } : null}
          />

          <div className={`dropdown_box ${!open ? "close" : ""}`}>
            <div className="dropdown_scroll_list">
              {list[0] && list.length
                ? list.map((item, index) => {
                    return (
                      <div
                        key={`dropdonw_menu_${index}`}
                        // onClick={() => {
                        //   this.chekcBox.click();
                        // }}
                      >
                        <input
                          checked={
                            open
                              ? checked && checked.length !== 0
                                ? checked.find((data) => data?._id === item._id)
                                : false
                              : false
                          }
                          onChange={(e) => this.props.handleChange(e, item)}
                          type="checkBox"
                          id={`dropdonw_menu_${index}_item`}
                        />
                        <label htmlFor={`dropdonw_menu_${index}_item`}>
                          <span></span>
                          {item.name.en || item.name.ko
                            ? i18n.language === "en"
                              ? item.name?.en
                              : item.name?.ko
                            : item.name}
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}
export default CheckBoxDropDown;
