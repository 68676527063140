import React, { Component } from "react";
import "./Availability.scss";
import i18n from "../../i18n";
import Toggle from "react-toggle";
import "react-toggle/style.css";
class Availability extends Component {
  state = { allowJoin: "", isLoading: false };

  componentDidMount = async () => {
    const allowJoin = await this.props.teamInfo.allowJoin;
    this.setState({ isLoading: true }, () =>
      this.setState(
        {
          allowJoin: allowJoin,
        },
        () => this.setState({ isLoading: false })
      )
    );
  };

  handleChange = () => {
    this.setState(
      {
        allowJoin: !this.state.allowJoin,
      },
      () => this.props.handleAvailabilityInfoSet(this.state.allowJoin)
    );
  };

  render() {
    return (
      <div className="AvailabilityContainer">
        <div className="Availability-title-box-wrap">
          <div className="Availability-title-box">
            {i18n.t("teamSetting.allowJoin.Title")}
          </div>
          {!this.state.isLoading ? (
            <Toggle
              className="custom-toggle"
              defaultChecked={this.state.allowJoin}
              aria-label="No label tag"
              onChange={this.handleChange}
              disabled={false}
            />
          ) : null}
        </div>
        <div className="AvailabilityMain">
          <div className="AvailabilityMain-desc">
            {i18n.t("teamSetting.allowJoin.desc")}
          </div>
        </div>
      </div>
    );
  }
}

export default Availability;
