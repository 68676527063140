import React, { Component } from "react";
import MoreIcon from "../../Mobile/Proejct-dialog/more-horizontal.svg";

import ArrowIcon from "../../../src/image/feather/chevron-left.svg";
import "./MobileHeader.scss";
class MobileHeader extends Component {
  state = {};

  render() {
    return (
      <div className="m-haeder-container">
        <div className="header-wrapper">
          <div className="close" onClick={this.props.onClose}>
            <img src={ArrowIcon} alt="dialogClose" />
          </div>
          {this.props.isBottomSheet ? (
            <div className="more" onClick={this.props.selectOpen}>
              <img src={MoreIcon} alt="MeneOpen" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default MobileHeader;
