import React, { Component } from "react";
import "./IntroBox_2.scss";
import ScreenImg_1 from "../../image/Introdution/introduction-img-3/introduction-img-3.png";
import ScreenImg_2 from "../../image/Introdution/introduction-img-4/introduction-img-4.png";
import BtBannerImg from "../../image/Introdution/introduction-img-5/introduction-img-5.png";
import i18n from "../../i18n";
import Media from "react-media";
class introBox_2 extends Component {
  render() {
    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            tablet: "(min-width: 800px)",
          }}
        >
          {(matches) =>
            i18n.language === "en" ? (
              <div className="page_introBox">
                <div className="page_introTitle">
                  <span>INTRODUCTION</span>
                  <span>
                    Motiontrend strives to
                    <br />
                    discover new creators.
                  </span>
                  <span>
                    Everyone who makes a creation can join Motion Trend. In
                    addition, you can collaborate
                    <br />
                    with various creators. Do you want to share my skills and my
                    creations? You can get
                    <br />
                    what you want through motion trends. Feel free to show your
                    thoughts anytime,
                    <br /> anywhere with us.
                  </span>
                </div>
                <div className="page_itroContent">
                  <div className="itroContent-ltImage">
                    <span>MOTIONTREND</span>
                    <img src={ScreenImg_1} alt="screenImg1" />
                  </div>
                  <div className="itroContent-rtImage">
                    <img src={ScreenImg_2} alt="screenImg2" />
                    <span>CREATOR</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="page_introBox">
                <div className="page_introTitle">
                  <span>크리에이터 발굴</span>
                  <span>모션트렌드는 숨겨진 '인재'를 찾습니다</span>
                  <span>
                    모든 사람들은 모션트렌드에 참여할 수 있습니다.
                    <br />
                    또 한 다양한 크리에이터와 협업할 수 있으며, 모든 사람들의
                    기술과 창작물을 공유할 수 있습니다.
                    <br />
                    언제 어디서나 저희 모션트렌드와 함께 여러분의 생각을
                    자유롭게 선보여주세요.
                  </span>
                </div>
                <div className="page_itroContent">
                  <div className="itroContent-ltImage">
                    <span>모션트렌드</span>
                    <img src={ScreenImg_1} alt="screenImg1" />
                  </div>
                  <div className="itroContent-rtImage">
                    <img src={ScreenImg_2} alt="screenImg2" />
                    <span>크리에이터</span>
                  </div>
                </div>
              </div>
            )
          }
        </Media>
        <div className="page_btBanner">
          <div
            style={{
              backgroundImage: `url(${BtBannerImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </>
    );
  }
}

export default introBox_2;
