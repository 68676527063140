import React, { Component } from "react";
import "./MemberRemoveDialog.scss";

import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class MemberRemoveDialog extends Component {
  render() {
    const { onClose, open } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"removeDialog-container"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="remove-dialog">
              <span className="remove-dialog-title">
                {this.props.userName}을(를) 팀에서 제외 하시겠습니까?
              </span>
              <div className="remove-dialog-text">
                <span>
                  팀에서 제외되면 해당 멤버는 더이상 팀에 게시물을 등록할 수
                  없고 기타 부여된 모든 권한이 상실됩니다.
                </span>
              </div>
              <div className="remove-dialog-btns">
                <button type="button" onClick={onClose}>
                  {i18n.t("global.cancel")}
                </button>
                <button type="button" onClick={this.props.handleRemoveMember}>
                  {i18n.t("team.member.remove.btn")}
                </button>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(MemberRemoveDialog);
