import React, { Component } from "react";
import "./SaveSucessDialog.scss";
import SaveIcon from "../../../image/Collection/join-complete-icon.svg";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class SaveSucessDialog extends Component {
  render() {
    const { onClose, open, dialogHeadText, dialogBodyText } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"save-dialog"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="alert-button-dialog-container save-dialog-container">
              <span className="save-dialog-title">
                {dialogHeadText || i18n.t("global.alert")}
              </span>
              <div className="save-button-dialog-text">
                <img src={SaveIcon} alt="Save Success" />
                <span>{dialogBodyText}</span>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(SaveSucessDialog);
