import React, { Component } from "react";
import { Redirect, Route, withRouter, Switch } from "react-router-dom";
import Pages from "./Pages";
import { connect } from "react-redux";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import axios from "axios";
import Media from "react-media";
import Cookies from "universal-cookie";
import {
  changeLoginStatus,
  changeSocket,
  getMyInfo,
} from "../Redux/action/index";
import socketio from "socket.io-client";

import { createBrowserHistory } from "history";
import { GetMyInfo } from "../Api/User/user";
import * as firebase from "../firebase";
import "../utils.scss";
import { GA } from "../service/ga";

const history = createBrowserHistory();

history.listen((location) => {
  GA.trackPageView({ path: location.pathname });
});

class RouterWrapper extends Component {
  state = {
    loaded: false,
  };
  componentDidMount = () => {
    console.log(process.env.NODE_ENV);
    window.addEventListener("beforeunload", this.listenPage);
    this.loginCheck();
    this.handleLoaded();
    window.addEventListener("popstate", (e) => this.popStateChange(e));
    GA.trackPageView({ path: window.location.pathname });
  };

  popStateChange = (e) => {
    const container = document.getElementsByClassName("MuiBackdrop-root");

    if (container.length) {
      container[container.length - 1 === -1 ? 0 : container.length - 1].click();
    } else {
      window.onpopstate = null;
      return;
    }
  };

  handleLoaded = async () => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    if (token && !this.props.myInfo) {
      try {
        const response = await GetMyInfo();
        console.log(response);
        this.props.getMyInfo(response);
      } catch (e) {
        console.log(e);
      }
    }
  };
  listenPage = () => {
    const cookies = new Cookies();
    if (this.props.myInfo) {
      const myInfoJSON = JSON.stringify(this.props.myInfo);
      cookies.set("myInfo", myInfoJSON);
    } else {
      this.handleLoaded();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.listenPage);
    window.removeEventListener("popstate", this.popStateChange);
    this.listenPage();
  };

  loginCheck = async () => {
    this.setState({ loaded: false });
    const cookies = new Cookies();
    const token = cookies.get("token");
    const firebaseToken = cookies.get("firebaseToken");

    if (token) {
      try {
        await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/auth/jwt-verfy`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (firebaseToken) {
          try {
            await firebase.auth.signInWithCustomToken(firebaseToken);
          } catch (e) {
            console.dir(e);
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/v1/auth/firebase-refresh`,
              null,
              { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.data && response.data.data.firebaseToken) {
              cookies.remove("firebaseToken");
              cookies.set("firebaseToken", response.data.data.firebaseToken);
              await firebase.auth.signInWithCustomToken(
                response.data.data.firebaseToken
              );
            }
          }
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/v1/auth/firebase-refresh`,
            null,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (response.data.data && response.data.data.firebaseToken) {
            cookies.remove("firebaseToken");
            cookies.set("firebaseToken", response.data.data.firebaseToken);
            await firebase.auth.signInWithCustomToken(
              response.data.data.firebaseToken
            );
          }
        }

        this.props.getMyInfo(cookies.get("myInfo"));
        this.props.changeLoginStatus(true);
        try {
          const socket = socketio(process.env.REACT_APP_BASE_URL);
          this.props.changeSocket(socket);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.dir(e);
        this.handleLogout();
      }
      this.setState({ loaded: true });
    } else {
      this.handleLogout();
      this.setState({ loaded: true });
    }
  };

  renderFooter = (url) => {
    // TODO: Footer 페이지 걸러야함
    if (this.state.loaded) {
      if (url && url.includes("/admin")) {
        return <Footer isAdmin={true} history={this.props.history} />;
      } else {
        return <Footer isAdmin={false} history={this.props.history} />;
      }
    }
  };

  renderHeader = (url) => {
    if (this.state.loaded && url !== "/message") {
      if (url && url.includes("/project-upload")) {
        return (
          <Media
            queries={{
              tablet: "(max-width:599px)",
              desktop: "(min-width:600px)",
            }}
          >
            {(matches) =>
              matches.desktop && (
                <Header
                  history={this.props.history}
                  location={this.props.location.pathname}
                  isLogined={this.props.isLogined}
                />
              )
            }
          </Media>
        );
      } else {
        return (
          <Header
            history={this.props.history}
            location={this.props.location.pathname}
            isLogined={this.props.isLogined}
          />
        );
      }
    }
  };

  handleLogout = () => {
    const cookies = new Cookies();
    this.props.getMyInfo(null);
    this.props.changeLoginStatus(false);
    cookies.remove("token");
    cookies.remove("firebaseToken");
    cookies.remove("myInfo");
  };

  render() {
    return (
      <>
        {this.renderHeader(this.props.location.pathname)}
        {this.state.loaded ? (
          <Switch>
            {Pages().map((item) => {
              return (
                <Route
                  key={item.path}
                  exact={item.exact}
                  path={item.path}
                  sensitive={true}
                  component={item.component}
                ></Route>
              );
            })}
            <Redirect to="/not-found" />
          </Switch>
        ) : null}
        {this.renderFooter(this.props.location.pathname)}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
  isOut: state.isOut,
  socket: state.socketStatus.socket,
});
const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
  changeLoginStatus: (value) => dispatch(changeLoginStatus(value)),
  changeSocket: (value) => dispatch(changeSocket(value)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouterWrapper)
);
