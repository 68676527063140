import axios from "axios";
import { GetUserToken } from "../../modules/userAuth";

export const TeampageBannerSet = async (teamId, file) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/set-banner`;
  const body = new FormData();
  const token = GetUserToken();
  body.append("id", teamId);
  body.append("teamBanner", file);
  await axios.patch(url, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const TeampageBannerDelete = async (teamId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/clear-banner`;
  await axios.patch(
    url,
    {
      id: teamId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const UserpageBannerSet = async (file) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/set-banner`;
  const body = new FormData();
  body.append("bannerPhoto", file);
  await axios.patch(url, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const UserpageBannerDelete = async () => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/clear-banner`;
  await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
