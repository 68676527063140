import React, { Component } from "react";
import "./BasicInfo.scss";
import i18n from "../../i18n";
//import RedArrow from "../../image/ProjectUpload/line-red-arrow-down-icon.svg";
import AddIcon from "../../image/plus-icon.svg";
import CountrySelect from "../../SignUp/UserInfo/CountrySelect";
import { RegionDropdown } from "react-country-region-selector";
//import { ClickAwayListener } from "@material-ui/core";

import axios from "axios";
import { PatchTeamPhoto } from "../../Api/Team/Team";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
class BasicInfo extends Component {
  state = {
    teamName: "",
    nameValid: false,
    userMsg: "",
    job: "",
    jobValid: false,
    jobMsg: "",
    profile: "",
    file: "",
    selecCountry: "",
    region: null,
    webValid: false,
    website: "",
    webMsg: "",
    coutryListOpen: false,
    countryName: "",
    countryCode: "",
  };

  componentDidMount = async () => {
    try {
      const teamInfo = await this.props.teamInfo;
      if (teamInfo) {
        this.setState(
          {
            teamName: teamInfo.teamName,
            // job: teamInfo.job,
            profile: teamInfo.profilePhoto,
            website: teamInfo.website,
            countryName: teamInfo.locationInfo.countryName,
            countryCode: teamInfo.locationInfo.countryCode,
            region: teamInfo.locationInfo.region,
          },
          () => {
            if (!this.state.countryCode) {
              this.getGeoInfo();
            }
            this.props.handleBasicInfoSet(this.state);
          }
        );
      }
    } catch (e) {
      console.dir(e);
    }
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState(
          {
            countryName: data.country_name,
            countryCode: data.country_code,
          },
          () => this.props.handleBasicInfoSet(this.state)
        );
      })
      .catch((error) => {
        console.dir(error);
      });
  };

  handleChangeCountry = (e) => {
    this.setState(
      {
        countryCode: e.value,
        countryName: e.label,
        region: null,
      },
      () => {
        this.props.handleBasicInfoSet(this.state);
      }
    );
  };

  handleProfilePhotoUpload = async () => {
    try {
      await PatchTeamPhoto(this.props.teamInfo.teamId, this.state.file);
      this.props.enqueueSnackbar(i18n.t("userSetting.profileUpload"), {
        variant: "success",
        key: "userSetting.profileUpload",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.dir(e);
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  handleCoutryOpen = () => {
    this.setState({ coutryListOpen: true });
  };
  handleCoutryClose = () => {
    this.setState({ coutryListOpen: false });
  };

  handleUserNameChange = (e) => {
    const teamName = e.target.value.trim();
    const spcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자

    // const nickSpcialRegex = /_-/;
    if (teamName.length === 0) {
      this.setState(
        {
          nameValid: false,
          teamName: teamName,
          userNameMsg: i18n.t("userInfo.nickName"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else if (spcialRegex.test(teamName)) {
      this.setState(
        {
          nameValid: false,
          teamName: teamName,
          userNameMsg: i18n.t("global.alert.specialCharacter"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else if (teamName.length < 1 || teamName.length > 20) {
      this.setState(
        {
          nameValid: false,
          teamName: teamName,
          userNameMsg: i18n.t("userInfo.nickNameCondition"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    } else {
      this.setState(
        {
          nameValid: true,
          teamName: teamName,
          userNameMsg: i18n.t("all.signup.Available"),
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    }
  };

  // handleJobChange = (e) => {
  //   const job = e.target.value.trim();
  //   const spcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자

  //   if (spcialRegex.test(job)) {
  //     // 특수문자 검사
  //     this.setState({
  //       jobValid: false,
  //       job: job,
  //       jobMsg: i18n.t("global.alert.specialCharacter"),
  //     });
  //   } else if (job.length > 40) {
  //     const maxLength = 40;
  //     this.setState({
  //       jobValid: false,
  //       job: job,
  //       jobMsg: i18n.t("global.alert.maxlength", { maxLength }),
  //     });
  //   } else {
  //     this.setState(
  //       {
  //         jobValid: true,
  //         job: job,
  //         jobMsg: i18n.t("all.signup.Available"),
  //       },
  //       () => this.props.handleBasicInfoSet(this.state)
  //     );
  //   }
  // };

  handleRegionChange = (val) => {
    if (val !== "") {
      this.setState({ region: val }, () => {
        this.props.handleBasicInfoSet(this.state);
      });
    } else {
      this.setState({ region: null }, () => {
        this.props.handleBasicInfoSet(this.state);
      });
    }
  };

  handleWebsiteChage = (e) => {
    const url = e.target.value.trim();
    const urlRegex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim;
    if (!urlRegex.test(url)) {
      // Url 유효성 검사
      this.setState({
        webValid: false,
        website: url,
        webMsg: i18n.t("웹사이트 주소를 다시 확인해 주세요."),
      });
    } else {
      this.setState(
        {
          webValid: true,
          website: url,
          webMsg: "",
        },
        () => this.props.handleBasicInfoSet(this.state)
      );
    }
    // this.setState({ website: e.target.value }, () =>
    //   this.props.handleBasicInfoSet(this.state)
    // );
  };

  handleChangeProfile = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState(
        {
          file,
          profile: reader.result,
        },
        () => {
          this.props.handleBasicInfoSet(this.state);
          this.handleProfilePhotoUpload();
        }
      );
    };
  };

  render() {
    return (
      <div className="basicInfo-container setting-container">
        <div className="basicInfo-box">
          <div className="Title settingSub-title">
            {i18n.t("setting.profile.title")}
          </div>
          <div className="userProfileBox">
            <div className="profileBox ellipse">
              <ProfilePhoto size={140} src={this.state.profile} />
            </div>
            <input
              type="file"
              accept="image/*"
              id="profile-img"
              onChange={this.handleChangeProfile}
            />
            <label htmlFor="profile-img">
              <img src={AddIcon} alt="profileAdd" />
            </label>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("teamSetting.team.title")}
            </div>
            <div className="userInfo-inputBox">
              <input
                onKeyUp={this.handleUserNameChange}
                className="InputField"
                defaultValue={this.state.teamName}
                placeholder={i18n.t("teamSetting.team.title")}
              />
              <div
                style={{
                  color: this.state.nameValid ? "green" : "#ff2e43",
                }}
                className="notValidMsg"
              >
                {this.state.userNameMsg}
              </div>
            </div>
          </div>

          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("job.upload_1.location")}
            </div>
            <div className="userInfo-locationBox">
              <CountrySelect
                handleChangeCountry={this.handleChangeCountry}
                countryCode={this.state.countryCode}
              />
              <RegionDropdown
                style={{
                  padding: "0 15px",
                  borderRadius: "unset",
                }}
                countryValueType="short"
                country={this.state.countryCode}
                value={this.state.region}
                className="region-input grayBorder"
                defaultOptionLabel={i18n.t("job.upload_1.region")}
                onChange={(val) => this.handleRegionChange(val)}
              />
            </div>
          </div>
          <div className="userInfo-input-container setting-input">
            <div className="userInfo-input-title setting-title">
              {i18n.t("userSetting.website")}
            </div>
            <div className="userInfo-inputBox">
              <input
                type="url"
                className="InputField"
                maxLength="50"
                defaultValue={this.state.website}
                onKeyUp={this.handleWebsiteChage}
                placeholder={i18n.t("userSetting.website")}
                required
              />
              <div
                style={{
                  color: this.state.webValid ? "green" : "#ff2e43",
                }}
                className="notValidMsg"
              >
                {this.state.webMsg}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withSnackbar)(BasicInfo);
