import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const GetCategories = async () => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/classification/list`;
    const response = await axios.get(url);
    return response.data.data;
  } catch (e) {
    return e.response;
  }
};

//카테고리 생성 api
// name 객체에 영어,한글 적어서 전송
export const PostCreateCategory = async (name) => {
  const token = GetUserToken();
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/classification/create`;
    await axios.post(
      url,
      { name },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (e) {}
};

export const DeleteCategory = async (id) => {
  try {
    const token = GetUserToken();
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/classification/delete?id=${id}`;
    await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {}
};
