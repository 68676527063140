import React, { Component } from "react";
import "./Setting.scss";
import Account from "./Account/Account";
import Delete from "./Delete/Delete";
import BasicInfo from "./BasicInfo/BasicInfo";
import Link from "./Link/Link";
import MyIntro from "./MyIntro/MyIntro";
import i18n from "../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import quertString from "query-string";
import { GetMyInfo, PostAccountSet, PostSetUserInfo } from "../Api/User/user";
import { withSnackbar } from "notistack";
import { GetUserToken } from "../modules/userAuth";
import { getMyInfo } from "../Redux/action/index";
import { compose } from "redux";
import { connect } from "react-redux";
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = { setMenu: "account" };
  }
  state = {
    setMenu: "",
    profilePhoto: "",
    userName: "",
    favorites: "",
    locationInfo: {
      countryName: "",
      countryCode: "",
      region: null,
    },
    website: "",
    linkInfo: [],
    userInfo: {
      title: "",
      explanation: "",
    },
    userLink: "",
    btnActive: false,
    linkURL: "",
  };

  componentDidMount = async () => {
    const token = GetUserToken();
    const setMenu = quertString.parse(this.props.location.search);
    this.setState({ isLoading: true });
    if (token) {
      try {
        // const response = await JwtRefresh();
        // const { token } = response;
        // RemoveUserToken();
        // SetUserToken(token);

        const userInfoResult = await GetMyInfo(token);
        console.log(userInfoResult);
        if (userInfoResult) {
          this.setState(
            {
              setMenu: setMenu.profileEdit ? setMenu.profileEdit : "account",
              profilePhoto: userInfoResult.profilePhoto,
              userName: userInfoResult.userName,
              favorites: userInfoResult.classification,
              website: userInfoResult.website,
              linkInfo: userInfoResult.linkInfo,
              locationInfo: userInfoResult.countryInfo,
              userInfo: userInfoResult.userInfo,
              linkURL: userInfoResult.linkURL,
            },
            () => {
              this.setState({ isLoading: false });
            }
          );
        }
      } catch (e) {
        console.dir(e);
      }
    } else {
      this.handleAlert();
      this.props.history.push("/login");
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };

  handleNecessartValue = () => {
    if (
      this.state.userName &&
      this.state.locationInfo.countryCode &&
      this.state.locationInfo.countryName
    ) {
      this.setState({ btnActive: true });
    } else {
      this.setState({ btnActive: false });
    }
  };

  handleSaveUserInfo = async () => {
    const linkInfo = this.state.linkInfo;
    this.setState({ isLoading: true });

    if (linkInfo) {
      for (let i = 0; i < linkInfo.length; i++) {
        delete linkInfo[i].id;
        delete linkInfo[i].icon;
        delete linkInfo[i].text;
        delete linkInfo[i].placeholder;
      }
      linkInfo.sort(function (a, b) {
        return (a.link === "") - (b.link === "");
      });
    }

    // 비어 있는 소셜 링크 값 순서 마지막으로 변경

    this.setState({ linkInfo });

    try {
      const response = await PostSetUserInfo(
        this.state.userName,
        this.state.favorites,
        this.state.locationInfo,
        this.state.website,
        this.state.linkInfo,
        this.state.userInfo
      );

      if (response === "success") {
        this.setState({ isLoading: false }, () => {
          this.handleAlert("설정이 완료되었습니다.");
          this.props.history.goBack();
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleAccountSet = async () => {
    this.setState({ isLoading: true });
    console.log(this.props);
    try {
      const response = await PostAccountSet(this.state.userLink);
      if (response === "success") {
        this.setState({ isLoading: false }, () => {
          const myInfo = this.props.myInfo;
          myInfo.linkURL = this.state.userLink;
          this.props.getMyInfo(myInfo);
          this.handleAlert("링크 변경이 완료됐습니다");
          this.props.history.push(`/@${this.state.userLink}`);
        });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleMyIntroSet = (data) => {
    this.setState({
      userInfo: {
        title: data.title,
        explanation: data.explanation,
      },
    });
  };

  handleBasicInfoSet = (data) => {
    this.setState(
      {
        // profilePhoto: data.file,
        profilePhoto: data.profile,
        userName: data.userName,
        favorites: data.selectedFavorites,
        locationInfo: {
          countryName: data.countryName,
          countryCode: data.countryCode,
          region: data.region,
        },
        website: data.website,
      },
      () => {
        this.handleNecessartValue();
      }
    );
  };

  handleLinkInfoSet = (data) => {
    const linkInfo = data;

    this.setState({
      linkInfo,
    });
  };
  handleUserLinkSet = (data) => {
    this.setState({
      userLink: data.linkUrl,
      btnActive: data.urlAvailable,
    });
  };

  handleSelTab = (e) => {
    this.setState({ setMenu: e.target.value });
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin
        indicator={antIcon}
        spinning={this.state.isLoading}
        delay={500}
        wrapperClassName={"main-spin"}
      >
        <div className="SettingContainer">
          <div className="setting-leftNav"></div>
          <div className="SettingMain">
            <div className="selTab">
              <input
                type="radio"
                id="account-setting"
                value="account"
                name="selMenu"
                checked={this.state.setMenu === "account" ? "checked" : null}
                onChange={this.handleSelTab}
              />
              <label style={{ cursor: "pointer" }} htmlFor="account-setting">
                {i18n.t("userSetting.accountsetting.title")}
              </label>
              <input
                type="radio"
                id="profile-setting"
                name="selMenu"
                value="user"
                checked={this.state.setMenu === "user" ? "checked" : null}
                onChange={this.handleSelTab}
              />
              <label style={{ cursor: "pointer" }} htmlFor="profile-setting">
                {i18n.t("userSetting.profilesetting.title")}
              </label>
            </div>
            {this.state.setMenu === "account" ? (
              <>
                <div className="PageBox">
                  <Account
                    userInfo={this.state}
                    handleUserLinkSet={this.handleUserLinkSet}
                  />
                </div>
                <div className="PageBox">
                  <Delete history={this.props.history} />
                </div>
              </>
            ) : (
              <>
                <div className="PageBox">
                  <BasicInfo
                    userInfo={this.state}
                    handleBasicInfoSet={this.handleBasicInfoSet}
                  />
                </div>
                <div className="PageBox">
                  <Link
                    userInfo={this.state}
                    handleLinkInfoSet={this.handleLinkInfoSet}
                  />
                </div>
                <div className="PageBox">
                  <MyIntro
                    userInfo={this.state}
                    handleMyIntroSet={this.handleMyIntroSet}
                  />
                </div>
              </>
            )}
            {this.state.setMenu === "account" ? (
              <button
                disabled={!this.state.btnActive}
                className={this.state.btnActive ? "submitBtn" : "disabledBtn"}
                onClick={this.state.btnActive ? this.handleAccountSet : null}
              >
                {i18n.t("userSetting.saveBtn")}
              </button>
            ) : (
              <button
                disabled={!this.state.btnActive}
                className={this.state.btnActive ? "submitBtn" : "disabledBtn"}
                onClick={this.state.btnActive ? this.handleSaveUserInfo : null}
              >
                {i18n.t("userSetting.saveBtn")}
              </button>
            )}
          </div>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(Setting);
