import React, { Component } from "react";
import "./Report.scss";
import ReportList from "./ReportList/ReportList";
import { GetReportList } from "../../../Api/Admin/Report/report";
import arrowIcon from "../../../image/line-red-arrow-icon.svg";
import { ClickAwayListener } from "@material-ui/core";
import i18n from "../../../i18n";
import { DatePicker } from "antd";
import CheckIcon from "../../../image/red-check-icon.svg";
import moment from "moment";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
class Report extends Component {
  state = {
    isLoading: false,
    reportList: [],
    completeList: [],
    selectedMenu: 1,
    selectedFilter: { value: "all", text: "All" },
    filterOpen: false,
    dateFilterOpen: false,
    isTotal: true,
    endDate: "",
    strDate: "",
    test1: "",
    test2: "",
    strDateOpen: false,
    endDateOpen: false,
    dateFilters: [
      {
        id: 1,
        text: "Total",
      },
      {
        id: 2,
        text: "Last 1 Month",
      },
      {
        id: 3,
        text: "Last 3 Month",
      },
    ],
    filters: [
      {
        value: "comment",
        text: "Comment",
      },
      {
        value: "project",
        text: "Project",
      },
      {
        value: "all",
        text: "All",
      },
    ],
    selectedDate: { id: 1, text: "Total" },
    reportSelected: "",
    reportTable: [
      {
        id: 1,
        text: "Unresolved",
      },
      {
        id: 2,
        text: "Completed",
      },
    ],
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.getReportList();
    await this.getCompleteReportList();
  };
  handleFilterChange = (val) => {
    this.setState({ selectedFilter: val, filterOpen: false }, async () => {
      this.getReportList();
      this.getCompleteReportList();
    });
  };
  getReportList = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await GetReportList(
        this.state.selectedFilter.value,
        1,
        this.state.isTotal,
        this.state.strDate ? this.state.strDate._d.toISOString() : null,
        this.state.endDate ? this.state.endDate._d.toISOString() : null
      );

      this.setState({ reportList: response, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  getCompleteReportList = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await GetReportList(
        this.state.selectedFilter.value,
        2,
        this.state.isTotal,
        this.state.strDate ? this.state.strDate._d.toISOString() : null,
        this.state.endDate ? this.state.endDate._d.toISOString() : null
      );

      this.setState({ completeList: response, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleSelectedMenu = (menu) => {
    this.setState({ selectedMenu: menu }, async () => {
      if (this.state.selectedMenu === 1) {
        await this.getReportList();
      } else {
        await this.getCompleteReportList();
      }
    });
  };
  renderTypeSelect = () => {
    return (
      <div className="filter_box">
        <div className="filter_title">
          <span>Multiple Selectable</span>
        </div>
        <div className="filter_items">
          {this.state.filters.map((item, index) => {
            return (
              <div key={`filter_item_${index}`}>
                <input
                  checked={this.state.selectedFilter.value === item.value}
                  onChange={() => this.handleFilterChange(item)}
                  type="radio"
                  name="category"
                  id={`filter_${index}_item`}
                />
                <label htmlFor={`filter_${index}_item`}>
                  <span></span>
                  {item.text}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  // 종료일 Validation 함수
  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  // 시작일 변경 팝업 상태 변경
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endDateOpen: true });
    }
  };

  // 종료일  변경 팝업 상태 변경
  handleEndOpenChange = (open) => {
    this.setState({ endDateOpen: open });
  };

  handleSelectedDate = (val) => {
    this.setState({ selectedDate: val, dateFilterOpen: false }, () => {
      if (this.state.selectedDate.id === 1) {
        this.setState({ isTotal: true, endDate: "", strDate: "" }, async () => {
          await this.getCompleteReportList();
          await this.getReportList();
        });
      } else if (this.state.selectedDate.id === 2) {
        const today = new Date();
        const strDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );

        this.setState(
          {
            isTotal: false,
            strDate: moment(strDate, "YYYY-MM-DD"),
            endDate: moment(today, "YYYY-MM-DD"),
          },
          async () => {
            await this.getCompleteReportList();
            await this.getReportList();
          }
        );
      } else if (this.state.selectedDate.id === 3) {
        const today = new Date();
        const strDate = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );

        this.setState(
          {
            isTotal: false,
            strDate: moment(strDate, "YYYY-MM-DD"),
            endDate: moment(today, "YYYY-MM-DD"),
          },
          async () => {
            await this.getCompleteReportList();
            await this.getReportList();
          }
        );
      }
    });
  };
  handleStrDateChange = (strDate) => {
    this.setState({ strDate, isTotal: false });
  };
  handleEndDateChange = (endDate) => {
    this.setState(
      { endDate, isTotal: false, dateFilterOpen: false },
      async () => {
        await this.getCompleteReportList();
        await this.getReportList();
      }
    );
  };
  renderDateSelect = () => {
    return (
      <div className="datePicker_box">
        <div className="filter_items">
          {this.state.dateFilters.map((item, index) => {
            return (
              <div
                onClick={() => this.handleSelectedDate(item)}
                key={`filter_item${index}`}
              >
                <label htmlFor={`filter_${index}_item`}>{item.text}</label>
                {this.state.selectedDate.id === item.id ? (
                  <img src={CheckIcon} alt="datePick" />
                ) : null}
              </div>
            );
          })}
        </div>
        <div className="datePicker_wrapper">
          <span>Direct input</span>
          <div className="datePicker_items">
            <DatePicker
              disabledDate={this.disabledStartDate}
              // showTime
              format="YYYY-MM-DD"
              value={this.state.strDate}
              placeholder="시작일"
              onChange={this.handleStrDateChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <DatePicker
              disabledDate={this.disabledEndDate}
              // showTime
              format="YYYY-MM-DD"
              value={this.state.endDate}
              placeholder="종료일"
              onChange={this.handleEndDateChange}
              open={this.state.endDateOpen}
              onOpenChange={this.handleEndOpenChange}
            />
          </div>
        </div>
      </div>
    );
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
        <div className="report-container ">
          <div className="report_list-container">
            <div className="hidden-container">
              <div className="hidden-title">
                <span>{i18n.t("admin.project.adjust.hidden.title")}</span>
              </div>
            </div>
            <div className="report_list-tableHeader">
              <div className="report_list-table">
                <div className="list_table_lt">
                  {this.state.reportTable.map((item) => {
                    return (
                      <div
                        className="table-item"
                        key={`resport_list_${item.id}`}
                      >
                        <span
                          className={`${
                            this.state.selectedMenu === item.id
                              ? "selectedMenu"
                              : ""
                          }`}
                          onClick={() => this.handleSelectedMenu(item.id)}
                        >
                          {item.text}
                          <span>
                            {item.id === 1
                              ? this.state.reportList.length
                              : this.state.completeList.length}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="list_table_rt">
                  <ClickAwayListener
                    onClickAway={() => this.setState({ filterOpen: false })}
                  >
                    <div className="filter_wrapper">
                      <div
                        className="filter_select"
                        onClick={() =>
                          this.setState({
                            filterOpen: !this.state.filterOpen,
                          })
                        }
                      >
                        {this.state.selectedFilter.text}
                        <img
                          src={arrowIcon}
                          style={
                            this.state.filterOpen
                              ? { transform: "rotate(180deg)" }
                              : null
                          }
                          alt="filter-open"
                        />
                      </div>
                      {this.state.filterOpen ? this.renderTypeSelect() : null}
                    </div>
                  </ClickAwayListener>
                  <ClickAwayListener
                    onClickAway={() => this.setState({ dateFilterOpen: false })}
                  >
                    <div className="filter_wrapper">
                      <div
                        className="filter_select"
                        onClick={() =>
                          this.setState({
                            dateFilterOpen: !this.state.dateFilterOpen,
                          })
                        }
                      >
                        {this.state.selectedDate.text}
                        <img
                          src={arrowIcon}
                          style={
                            this.state.dateFilterOpen
                              ? { transform: "rotate(180deg)" }
                              : null
                          }
                          alt="filter-open"
                        />
                      </div>
                      {this.state.dateFilterOpen
                        ? this.renderDateSelect()
                        : null}
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
            <div className="hidden-list" style={{ flexDirection: "column" }}>
              <ReportList
                handleFilterChange={this.handleFilterChange}
                unresolvedCount={this.state.reportList.length}
                resolvedCount={this.state.completeList.length}
                list={
                  this.state.selectedMenu === 1
                    ? this.state.reportList
                    : this.state.completeList
                }
                checkedFilter={this.state.selectedFilter}
                handleSelectedMenu={this.handleSelectedMenu}
              />
            </div>
          </div>
        </div>
      </Spin>
    );
  }

  // searchUpdated(term) {
  //   this.setState({ searchTerm: term }, ()=> console.log(this.state.searchTerm));
  // }
}

export default Report;
