import React, { Component } from "react";
import "./ReportDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../../i18n";
import ArrowIcon from "../../../../image/arrowIcon/black-down-icon.svg";
import ProfilePhoto from "../../../../Component/ProfilePhoto/ProfilePhoto";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
class ReportDialog extends Component {
  state = {
    viewContent: false,
  };
  handleContentView = () => {
    if (this.props.reportInfo.data.type === "comment") {
      if (this.state.viewContent === false) {
        this.setState({ viewContent: true });
      } else {
        this.setState({ viewContent: false });
      }
    } else if (this.props.reportInfo.data.type === "project") {
      console.log("ss");
    }
  };
  render() {
    const { onClose, open, reportInfo } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={""}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="Report-detail-dialog-container">
              <div className="Report-detail-dialog-title">
                <span>View details of report</span>
              </div>
              <div className="Report-detail-dialog-body">
                <div className="reporter-userInfo-box">
                  <span>Direct input</span>
                  <div className="reporter-userInfo">
                    <ProfilePhoto
                      src={reportInfo.reporterInfo.profilePhoto}
                      size={16}
                    />
                    <span className="userName">
                      {reportInfo.reporterInfo.userName}
                    </span>
                  </div>
                </div>
                <div className="report-content">
                  <span>Direct input</span>
                  <span>{reportInfo.data.text}</span>
                </div>
                <div className="view-reported-content">
                  <div
                    className="view-reported-box"
                    onClick={this.handleContentView}
                  >
                    <span>
                      {reportInfo.data.type === "comment"
                        ? i18n.t("admin.report.dialog.viwe.comment")
                        : i18n.t("admin.report.dialog.viwe.project")}
                    </span>
                    <img src={ArrowIcon} alt="more reported content" />
                  </div>
                  {this.state.viewContent ? (
                    reportInfo.commentInfo ? (
                      <div className="repoted-content-box">
                        <div className="reported-defendant-info">
                          <ProfilePhoto
                            src={reportInfo.defendantInfo.profilePhoto}
                            size={16}
                          />
                          <span>{reportInfo.defendantInfo.userName}</span>
                          <span>
                            {reportInfo.commentInfo.createdAt.slice(0, 10)}
                          </span>
                        </div>
                        <div className="reported-content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: reportInfo.commentInfo.comment.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></span>
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <div>{i18n.t("admin.report.deletdAlert")}</div>
                  )}
                </div>
              </div>
              <div className="report-btns">
                <button
                  className="report-button-dialog-button"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  className="report-button-dialog-button"
                  onClick={() =>
                    this.props.handleReportDeleteDialogOpen(
                      reportInfo.reportedId
                    )
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}
export default withStyles(styles)(ReportDialog);
