import React, { Component } from "react";
import "./PursuitBox.scss";
import PursuitImg from "../../image/Introdution/introduction-img-1/introduction-img-1.png";
import PursuitIntroImg from "../../image/Introdution/introduction-img-2/introduction-img-2.png";
import PursuitEllipse from "../../image/Introdution/pursuit-img.svg";
import PursuitEllipseKo from "../../image/Introdution/pursuit-img-2.svg";
import M_Pursuit from "../../image/Introdution/pursuit_img_mo.svg";
import i18n from "../../i18n";
import Media from "react-media";

class PursuitBox extends Component {
  render() {
    return (
      <Media
        queries={{
          mobile: "(max-width: 799px)",
          tablet: "(min-width: 800px)",
        }}
      >
        {(matches) => (
          <div className="introduction-pursuit">
            {i18n.language === "en" ? (
              <>
                <div className="pursuit-header">
                  <span>PURSUIT</span>
                  <span>
                    Create an opportunity
                    <br /> to develop.
                  </span>
                </div>
                {matches.mobile && (
                  <div className="pursuit-header-content">
                    <span>
                      Create opportunities for my technology to develop in line
                      with the rapidly changing trend.
                      <br /> It is necessary to check how each small movement
                      will be reflected in the eyes
                      <br /> of others. But there is something important. You
                      can change according to other
                      <br />
                      people's eyes, but you can also lead the trend by taking
                      the lead in change. It's up to
                      <br /> you to decide how to proceed.
                    </span>
                  </div>
                )}

                <div className="pursuit-image">
                  <div
                    style={{
                      backgroundImage: `url(${PursuitImg})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
                <div className="pursuit-header-content">
                  {matches.tablet && (
                    <span>
                      Create opportunities for my technology to develop in line
                      with the rapidly changing
                      <br /> trend. It is necessary to check how each small
                      movement will be reflected in the eyes
                      <br /> of others. But there is something important. You
                      can change according to other
                      <br />
                      people's eyes, but you can also lead the trend by taking
                      the lead in change. It's up to
                      <br /> you to decide how to proceed.
                    </span>
                  )}

                  <div className="pursuit-introBox">
                    <div className="introBox-position">
                      <img src={PursuitIntroImg} alt="PursuitIntroImage" />
                      <span>Create an opportunity to develop.</span>
                    </div>
                  </div>
                </div>
                <div className="pursuit-footer-content">
                  <div className="footer-content-header">
                    {/* <span>About Curation</span> */}
                    <span>About Curation</span>
                    <span>
                      We hope that creators will be able to create better works,
                      meet many fans, and generate revenue so that they can
                      continue their creative activities.
                    </span>
                  </div>
                  {matches.mobile ? (
                    <img src={M_Pursuit} alt="PursuitEllipse" />
                  ) : (
                    <img src={PursuitEllipse} alt="PursuitEllipse" />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="pursuit-header">
                  <span></span>
                  <span>작품 큐레이션</span>
                </div>
                <div className="pursuit-image">
                  <div
                    style={{
                      backgroundImage: `url(${PursuitImg})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
                <div className="pursuit-header-content">
                  <span>
                    저희 큐레이터들은 꾸준히 활동하는 가능성 있는 크리에이터들의
                    작품이 더 많은 사람들에게 노출될 수 있도록 지원하고
                    있습니다.<br></br>
                    이를 통해 크리에이터들이 더 좋은 작품을 만들고, 많은 팬들을
                    만나며, 수익을 창출하여 창작 활동을 지속할 수 있기를
                    바랍니다.
                  </span>
                  <div className="pursuit-introBox">
                    <div className="introBox-position">
                      <img src={PursuitIntroImg} alt="PursuitIntroImage" />
                      {/* <span>발전할 수 있는 기회를 만들어라.</span> */}
                    </div>
                  </div>
                </div>
                <div className="pursuit-footer-content">
                  <div className="footer-content-header">
                    <span></span>
                    <span>창작자 간의 교류</span>
                    <span>
                      창작물을 모션 트렌드와 공유하고 다른 크리에이터들과 소통을
                      시작해보세요.
                      <br /> 영감을 얻고 더욱 더 발전할 수 있을 뿐만 아니라, 더
                      많은 사람들과 일할 수 있는 기회를 갖게 될 수 있습니다.
                      <br /> 적극적인 커뮤니케이션을 통해 기회를 얻으세요.
                    </span>
                  </div>
                  {matches.mobile ? (
                    <img src={M_Pursuit} alt="PursuitEllipse" />
                  ) : (
                    <img src={PursuitEllipseKo} alt="PursuitEllipse" />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Media>
    );
  }
}

export default PursuitBox;
