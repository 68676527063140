import React, { Component } from "react";
import "./ShareDialog.scss";
import { compose } from "redux";
import TwitwerIcon from "../../image/ShareIcons/share-sns-icon-1.svg";
import PinterIcon from "../../image/ShareIcons/share-sns-icon-2.svg";
import KakaoIcon from "../../image/ShareIcons/share-sns-icon-3.svg";
import FacebookIcon from "../../image/ShareIcons/share-sns-icon-4.svg";
import NaverIcon from "../../image/ShareIcons/share-sns-icon-5.svg";
import CopyIcon from "../../image/ShareIcons/copy-icon.svg";
import { Dialog } from "@material-ui/core";
import { openSignInWindow } from "../HandlePopup/Handlepopup";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import { GetProjectList } from "../../Api/Project/project";
import { withSnackbar } from "notistack";
import { BottomSheet } from "react-spring-bottom-sheet";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class ShareDialog extends Component {
  state = {
    icon: [
      {
        id: 1,
        icon: TwitwerIcon,
      },
      {
        id: 2,
        icon: KakaoIcon,
        ShareDialog,
      },
      {
        id: 3,
        icon: PinterIcon,
      },
      {
        id: 4,
        icon: FacebookIcon,
      },
      {
        id: 5,
        icon: NaverIcon,
      },
    ],
    projInfo: {},
    fullWidth: false,
  };
  userUrl = null;
  componentDidMount = () => {
    // this.projInfoSet();
    // if (!this.props.fullWidth) {
    //   if (window.innerWidth < 700) {
    //     this.setState({ fullWidth: true });
    //   }
    //   window.addEventListener("resize", (e) => {
    //     this.handleResize(e.target.innerWidth);
    //   });
    // }

    this.userUrl = `${window.location.origin}${window.location.pathname}?${this.props.projLink}`;
  };

  copytoClipboard = (userUrl) => {
    navigator.clipboard.writeText(userUrl);
    this.props.enqueueSnackbar(i18n.t("클립보드에 복사 되었습니다."), {
      variant: "success",
      key: "cliipboardto",
      autoHideDuration: 5000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  projInfoSet = async () => {
    try {
      const response = await GetProjectList(this.props.userUrl);

      this.setState({ projInfo: response });
    } catch (e) {}
  };
  renderDialog = () => {
    const { onClose, open } = this.props;

    if (!this.props.fullWidth) {
      return (
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"shareDialog-dialog"}
          maxWidth={false}
          open={open}
          onClose={onClose}
        >
          <div className="closeBtn" onClick={onClose}>
            <div className="closeBar1" />
            <div className="closeBar2" />
          </div>
          <div className="alert-button-dialog-container shareDialog">
            {/* <h1 className="share-dialog-haeder">Share</h1> */}

            <div className="sharePages-list">
              {this.state.icon.map((item, index) => {
                return (
                  <img
                    key={`share_icon_${index}`}
                    src={item.icon}
                    onClick={() => this.handleSharePage(item.id, this.userUrl)}
                    alt="icon"
                  />
                );
              })}
            </div>
            <div className="sharePage-url">
              <input
                type="text"
                defaultValue={this.userUrl}
                style={{
                  maxWidth: "calc(100% - 16px)",
                  width: "100%",
                  overflow: "hidden",
                }}
              ></input>

              <img
                src={CopyIcon}
                alt="copyButton"
                onClick={() => {
                  this.copytoClipboard(this.userUrl);
                }}
              />
            </div>
          </div>
        </Dialog>
      );
    } else {
      return (
        <BottomSheet open={open} onDismiss={onClose}>
          <div className="alert-button-dialog-container shareDialog">
            <div className="sharePages-list">
              {this.state.icon.map((item, index) => {
                return (
                  <img
                    key={`share_icon_${index}`}
                    src={item.icon}
                    onClick={() => this.handleSharePage(item.id, this.userUrl)}
                    alt="icon"
                  />
                );
              })}
            </div>
            <div className="sharePage-url">
              <input
                type="text"
                defaultValue={this.userUrl}
                style={{
                  maxWidth: "calc(100% - 16px)",
                  width: "100%",
                  overflow: "hidden",
                }}
              ></input>

              <img
                src={CopyIcon}
                alt="copyButton"
                onClick={() => {
                  this.copytoClipboard(this.userUrl);
                }}
              />
            </div>
          </div>
        </BottomSheet>
      );
    }
  };
  fbSetMetaTag = (url) => {
    //set title
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `${this.state.projInfo.title}`);
    //set description
    // document
    //   .querySelector('meta[property="og:description"]')
    //   .setAttribute("content", this.state.projInfo);
    //set images
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", this.state.projInfo.thumbnail);
    //set url
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", url);
    openSignInWindow(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "/",
      this.props.history
    );
  };
  handleSharePage(id, url) {
    switch (id) {
      case 1:
        openSignInWindow(
          `https://twitter.com/intent/tweet?text=${this.state.projInfo.title}&url=${url}`,
          "/",
          this.props.history
        );
        break;
      case 2:
        alert("준비중인 기능입니다.");
        break;
      case 3:
        openSignInWindow(
          `https://www.pinterest.com/pin/create/button/?url=${url}&media=${this.state.projInfo.thumbnail}&description=${this.state.projInfo.title}`,
          "/",
          this.props.history
        );
        break;
      case 4:
        this.fbSetMetaTag(url);
        break;
      case 5:
        openSignInWindow(
          `https://share.naver.com/web/shareView.nhn?url=${url}&title=${this.state.projInfo.title}`,
          "/",
          this.props.history
        );
        break;
      default:
      // do nothing
    }
  }
  render() {
    return this.renderDialog();
  }
}

export default compose(withStyles(styles), withSnackbar)(ShareDialog);
