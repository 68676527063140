import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyPage.scss";
import MobileSelector from "../../Component/MobileSelector/MobileSeletor";
import i18n from "../../i18n";
import ProjectAddIcon from "../../image/mypage-add-gray-button.svg";
import loadable from "@loadable/component";
import Collection from "../../Component/Collection/Collection";
import AlertDialog from "../../Component/OneButtonDialog/OneButtonDialog";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import ProjectDialog from "../../Component/ProjectDialog/ProjectDialog";
import quertString from "query-string";
import Media from "react-media";
import { GetMyInfo, GetLinkInfo } from "../../Api/User/user";
import ShareDialog from "./ShareDialog/ShareDialog";
import SvgColor from "react-svg-color";
import MobileViewInfo from "../../Mobile/Mypage/information_view_black_icon.svg";
import MobileLikeInfo from "../../Mobile/Mypage/information_like_black_icon.svg";
// import MobileFollowInfo from "../../Mobile/Mypage/information_follower_black_icon.svg";
// import MobileMoodBoardInfo from "../../Mobile/Mypage/i_information_moodboard_black_icon.svg";
import InstaIcon from "../../image/Snsicon/circle-sns-icon-1.svg";
import FacebookIcon from "../../image/Snsicon/circle-sns-icon-2.svg";
import YoutubeIcon from "../../image/Snsicon/circle-sns-icon-3.svg";
import PinterIcon from "../../image/Snsicon/circle-sns-icon-4.svg";
import DribbleIcon from "../../image/Snsicon/circle-sns-icon-5.svg";
import TumblrIcon from "../../image/Snsicon/circle-sns-icon-6.svg";
import VimeoIcon from "../../image/Snsicon/circle-sns-icon-7.svg";
import MediumIcon from "../../image/Snsicon/circle-sns-icon-8.svg";
import SoundCloudIcon from "../../image/Snsicon/circle-sns-icon-9.svg";
import Home from "../../image/mypage-homepage-icon.svg";
import shareBtn from "../../image/job/share-black-icon.svg";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UserInfo from "./UserInfo/UserInfo";
import { GetCollectionList } from "../../Api/Collection/Collection";
import { Avatar } from "antd";
import {
  UserpageBannerSet,
  UserpageBannerDelete,
} from "../../Api/BannerEdit/BannerEdit";
import {
  GetProjectInfo,
  GetMyProjectList,
  GetLikeProjList,
  GetDraftList,
} from "../../Api/Project/project";
import { handleWindowInfiniteScroll } from "../../Component/functions";
import { SetFollow } from "../../Api/Follow/Follow";
import { TrafficOutlined } from "@material-ui/icons";
import { GetUserToken } from "../../modules/userAuth";
const Project = loadable(() => import("../../Component/Project/Project"));
class MyPage extends Component {
  state = {
    isSelfUrl: false,
    mobileSelectorOpen: false,
    shareDialogOpen: false,
    projectDialogOpen: false,
    videoList: [],
    likeList: [],
    draftList: [],
    collectionList: [],
    file: "",
    preview: "",
    selecMenu: "",
    bannerLoading: false,
    isLoading: false,
    alertDialogOpen: false,
    msgTitle: "",
    msg: "",
    userInfo: {},
    projInfo: {},
    render: false,
    mobileProjectNav: [
      {
        id: 1,
        text: i18n.t("mypage.nav.project"),
      },
      {
        id: 2,
        text: i18n.t("mypage.nav.collection"),
      },

      {
        id: 3,
        text: i18n.t("mypage.nav.like"),
      },
      // {
      //   id: 5,
      //   text: i18n.t("mypage.nav.insight"),
      // },
      {
        id: 4,
        text: i18n.t("mypage.nav.Draft"),
      },
      {
        id: 5,
        text: i18n.t("mypage.nav.information"),
      },
    ],
    selecList: [
      {
        id: 1,
        text: i18n.t("project.edit"),
      },
      {
        id: 2,
        text: i18n.t("global.delete"),
      },
    ],
    projectNav: [
      {
        id: 1,
        text: i18n.t("mypage.nav.project"),
      },
      {
        id: 2,
        text: i18n.t("mypage.nav.collection"),
      },
      // {
      //   id: 3,
      //   text: i18n.t("mypage.nav.tutorial"),
      // },
      {
        id: 3,
        text: i18n.t("mypage.nav.like"),
      },
      // {
      //   id: 5,
      //   text: i18n.t("mypage.nav.insight"),
      // },

      {
        id: 4,
        text: i18n.t("mypage.nav.Draft"),
      },
    ],
    dialogRender: false,
    timer: null,
  };
  limit = 10;
  scrollState = {
    like: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
    collection: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
    draft: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
    userProject: {
      items: 0,
      firstRender: false,
      dataNull: false,
    },
  };
  collectionLimit = 20;
  selfUrlCheck = async () => {
    const token = GetUserToken();
    if (token) {
      try {
        const userInfoResult = await GetMyInfo();

        if (this.props.match.params.linkURL === userInfoResult.linkURL) {
          this.setState({ isSelfUrl: true });
        }
      } catch (e) {
        console.dir(e);
      }
    } else {
      return;
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleInfiniteContent);
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.location.pathname.replace("/@", "") !==
      this.props.location.pathname.replace("/@", "")
    ) {
      window.location.reload();
    }
  };
  componentDidMount = async () => {
    await this.selfUrlCheck();
    const header = document.getElementById("nav_header-container");
    const query = quertString.parse(this.props.location.search);

    if (query.tab) {
      // this.setState({ selecMenu: query.tab }, async () => {
      // });
      await this.getLinkInfo();
      this.handleViewProject(Number(query.tab));
    } else {
      this.setState({ selecMenu: 1 }, async () => {
        await this.getLinkInfo();
      });
    }
    if (query && query.projectId && query.projectId.length === 24) {
      try {
        header.classList.add("header-pageView");
        const projInfo = await GetProjectInfo(query.projectId);
        this.setState({ projInfo, isPage: true }, () =>
          this.setState({ dialogRender: true }, () =>
            this.setState({ projectDialogOpen: true })
          )
        );
      } catch (e) {
        // alert(i18n.t("global.error.server.msg"));
        this.handleAlert(i18n.t("global.error.server.msg"), {
          variant: "error",
          key: i18n.t("global.alert.login"),
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.dir(e);
      }
    }

    window.addEventListener("scroll", this.handleInfiniteContent);
  };
  handleTeamUnFollow = async (teamId, index) => {
    let userInfo = this.state.userInfo;
    try {
      userInfo.teamList[index].isFollowing = false;
      await SetFollow(teamId);
      this.setState({ userInfo });
    } catch (e) {
      userInfo.teamList[index].isFollowing = TrafficOutlined;
    }
  };
  handleTeamFollow = async (teamId, index) => {
    const token = GetUserToken();
    let userInfo = this.state.userInfo;
    if (token) {
      try {
        userInfo.teamList[index].isFollowing = true;
        await SetFollow(teamId);
        this.setState({ userInfo });
      } catch (e) {
        userInfo.teamList[index].isFollowing = false;
      }
    } else {
      this.props.enqueueSnackbar(i18n.t("로그인후 이용 가능합니다."), {
        variant: "success",
        key: "success-logout",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  getLinkInfo = async () => {
    try {
      const userInfo = await GetLinkInfo(
        this.props.location.pathname.replace("/@", "")
      );

      this.setState({ userInfo, render: true }, () =>
        this.projectLoad(this.state.userInfo._id)
      );
    } catch (e) {
      this.props.history.push("/not-found");
      console.dir(e);
    }
  };

  iconFinder = (websiteName) => {
    if (websiteName === "Instagram") {
      return InstaIcon;
    } else if (websiteName === "Facebook") {
      return FacebookIcon;
    } else if (websiteName === "Pinterest") {
      return PinterIcon;
    } else if (websiteName === "Dribble") {
      return DribbleIcon;
    } else if (websiteName === "Youtube") {
      return YoutubeIcon;
    } else if (websiteName === "Tumblr") {
      return TumblrIcon;
    } else if (websiteName === "Vimeo") {
      return VimeoIcon;
    } else if (websiteName === "Medium") {
      return MediumIcon;
    } else if (websiteName === "SoundCloud") {
      return SoundCloudIcon;
    }
    // else {
    //     return OtherIcon
    // }
  };

  handleInfiniteContent = () => {
    const selecMenu = this.state.selecMenu;
    let scrollState =
      selecMenu === 1
        ? this.scrollState.userProject
        : selecMenu === 2
        ? this.scrollState.collection
        : selecMenu === 3
        ? this.scrollState.like
        : this.scrollState.draft;

    handleWindowInfiniteScroll(
      scrollState.dataNull,
      scrollState.firstRender,
      this.addRenderProject
    );
  };
  handleShareDialogOpen = () => {
    this.setState({ shareDialogOpen: true });
  };
  handleShareDialogClose = () => {
    this.setState({ shareDialogOpen: false });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  isSelfUrl = () => {
    const token = GetUserToken();
    return (
      this.props.location.pathname.replace("/@", "") !== "" &&
      token &&
      this.props.location.pathname.replace("/@", "") ===
        localStorage.getItem("linkUrl")
    );
  };

  addRenderProject = () => {
    switch (this.state.selecMenu) {
      case 1:
        this.addUserProject(this.state.userInfo._id);
        break;
      case 2:
        break;
      case 3:
        this.addLikeProject(this.state.userInfo._id);
        break;
      case 4:
        this.addDraftProejct(this.state.userInfo._id);
        break;
      default:
    }
  };
  addUserProject = async (id) => {
    if (
      !this.scrollState.userProject.dataNull &&
      this.scrollState.userProject.firstRender
    ) {
      try {
        const myProjList = await GetMyProjectList(
          id,
          this.scrollState.userProject.items,
          this.limit
        );

        if (!myProjList[0] || myProjList.length < this.limit) {
          this.scrollState.userProject.dataNull = true;
        } else {
          let videoList = this.state.videoList;
          let add = videoList.concat(myProjList);
          this.scrollState.userProject.items = add.length;
          this.setState({ videoList: add });
        }
      } catch (e) {
        console.dir(e);
      }
    }
  };
  addLikeProject = async (id) => {
    if (!this.scrollState.like.dataNull && this.scrollState.like.firstRender) {
      try {
        const response = await GetLikeProjList(
          id,
          this.scrollState.like.items,
          this.limit
        );

        if (!response[0] || response <= this.limit) {
          this.scrollState.like.dataNull = true;
        } else {
          let likeList = this.state.likeList;
          let add = likeList.concat(response);
          this.scrollState.like.items = add.length;
          this.setState({ likeList: add });
        }
      } catch (e) {
        console.dir(e);
      }
    }
  };
  addDraftProejct = async (id) => {
    if (
      !this.scrollState.draft.dataNull &&
      this.scrollState.draft.firstRender
    ) {
      try {
        const response = await GetDraftList(
          id,
          this.scrollState.draft.items,
          this.limit
        );

        if (!response[0] || response <= this.limit) {
          this.scrollState.draft.dataNull = true;
        } else {
          let draftList = this.state.draftList;
          let add = draftList.concat(response);
          this.scrollState.draft.items = add.length;
          this.setState({ draftList: add });
        }
      } catch (e) {
        console.dir(e);
      }
    }
  };
  projectLoad = async (id) => {
    this.setState({ isLoading: true });
    try {
      const response = await GetMyProjectList(id, 0);

      this.setState(
        {
          videoList: response,
          render: true,
          isLoading: false,
        },
        () => {
          this.scrollState.userProject.firstRender = true;
          this.scrollState.userProject.items = this.state.videoList.length;
          if (this.state.videoList.length < 20) {
            this.scrollState.userProject.dataNull = true;
          }
        }
      );
    } catch (e) {
      console.dir(e);
      this.setState({ isLoading: false });
    }
  };
  likeProjectLoad = async (id) => {
    this.setState({ isLoading: true });
    try {
      const likeProjList = await GetLikeProjList(id);
      this.setState(
        {
          likeList: likeProjList,
          render: true,
          isLoading: false,
        },
        () => {
          this.scrollState.like.firstRender = true;
          this.scrollState.like.items = this.state.likeList.length;
          if (this.state.likeList.length < 20) {
            this.scrollState.like.dataNull = true;
          }
        }
      );
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  draftProjectLoad = async (id) => {
    this.setState({ isLoading: true });
    try {
      const draftList = await GetDraftList(id);
      this.setState(
        {
          draftList,
          render: true,
          isLoading: false,
        },
        () => {
          this.scrollState.draft.firstRender = true;
          this.scrollState.draft.items = this.state.draftList.length;
          if (this.state.draftList.length < 20) {
            this.scrollState.draft.dataNull = true;
          }
        }
      );
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };
  timerSet = (timer) => {
    this.setState({ timer });
  };
  handleViewProject = (menu) => {
    if (menu === this.state.selecMenu) {
      return;
    } else {
      this.setState({ selecMenu: menu }, () => {
        this.props.history.push(`?tab=${this.state.selecMenu}`);
        if (this.state.selecMenu === 1) {
          return;
        } else if (this.state.selecMenu === 2) {
          this.collectionListLoad(this.state.userInfo._id);
        } else if (this.state.selecMenu === 3) {
          this.likeProjectLoad(this.state.userInfo._id);
        } else if (this.state.selecMenu === 4) {
          this.draftProjectLoad(this.state.userInfo._id);
        }
      });
    }
  };

  handleMobileViewProject = (e) => {
    if (this.state.selecMenu === e.id) {
      return;
    } else {
      this.setState({ selecMenu: e.id }, () => {
        if (this.state.selecMenu === 1) {
          return;
        } else if (this.state.selecMenu === 2) {
          this.collectionListLoad(this.state.userInfo._id);
        } else if (this.state.selecMenu === 3) {
          this.likeProjectLoad(this.state.userInfo._id);
        } else if (this.state.selecMenu === 4) {
          this.draftProjectLoad(this.state.userInfo._id);
        } else if (this.state.selecMenu === 5) {
          return;
        }
      });
    }
  };

  desktopRender = (selecMenu, matches) => {
    if (selecMenu === 1) {
      return this.Project(matches.desktop);
    } else if (selecMenu === 2) {
      return this.Colletion();
    } else if (selecMenu === 3) {
      return this.Like();
    } else if (selecMenu === 4) {
      return this.Draft();
    }
  };
  mobileRender = (selecMenu, matches) => {
    if (selecMenu === 1) {
      return this.Project(matches.desktop);
    } else if (selecMenu === 2) {
      return this.Colletion();
    } else if (selecMenu === 3) {
      return this.Like(matches.desktop);
    } else if (selecMenu === 4) {
      return this.Draft(matches.desktop);
    } else if (selecMenu === 5) {
      return this.mobileInfo();
    }
  };

  handleBannerDelete = async () => {
    this.setState({ bannerLoading: true, preview: "" });
    try {
      const userInfo = this.state.userInfo;

      await UserpageBannerDelete();

      delete userInfo.bannerPhoto;
      this.setState({ bannerLoading: false, preview: "", userInfo });
    } catch (e) {
      this.setState({ bannerLoading: false });
      console.dir(e);
    }
  };

  handleBannerSubmit = async (preview) => {
    try {
      await UserpageBannerSet(this.state.file);
      this.setState({ bannerLoading: false }, () => {
        this.setState({ preview });
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleBannerUpload = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let img = new Image();
    let file = e.target.files[0];
    const height = 1200;
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        img.src = e.target.result;
        this.setState({ bannerLoading: true }, () => {
          img.onload = () => {
            if (img.width <= height) {
              this.setState({
                msgTitle: i18n.t("img.uploadAlert.title"),
                msg: i18n.t("img.uploadAlert.text", { height }),
                alertDialogOpen: true,
                bannerLoading: false,
                mobileSelectorOpen: false,
              });
            } else {
              this.setState(
                {
                  file: file,
                  mobileSelectorOpen: false,
                },
                () => this.handleBannerSubmit(reader.result)
              );
            }
          };
        });
      };
    }
  };
  handleAlertDialogClose = () => {
    this.setState({ alertDialogOpen: false });
  };

  toOther = () => {
    this.props.history.push("/other");
  };
  toProjectupload = () => {
    if (this.state.isSelfUrl) {
      this.props.history.push("/project-upload");
    }
  };
  handleFollowOnClick = () => {
    const token = GetUserToken();
    if (token) {
      return;
    } else {
      // alert("로그인이 필요한 서비스 입니다.");
      this.handleAlert(i18n.t("global.alert.login"));
      this.props.history.push("/login");
    }
  };

  collectionListLoad = async (id) => {
    if (this.state.isSelfUrl) {
      try {
        const response = await GetCollectionList(null, this.limit);
        this.setState({ collectionList: response });
      } catch (e) {
        console.dir(e);
      }
    } else {
      try {
        const collectionList = await GetCollectionList(
          id,
          this.limit,
          this.scrollState.collection.items
        );
        this.setState({ collectionList });
      } catch (e) {
        console.dir(e);
      }
    }
  };

  handleMobileSelectorClose = () => {
    const file = document.getElementById("mySpaceBanner");

    this.setState({ mobileSelectorOpen: false }, () => {
      file.click();
      this.bannerUpload.click();
    });
  };

  handleSelecList = (e) => {
    switch (e) {
      case 1:
        this.bannerUpload.click();
        // this.handleMobileSelectorClose();

        break;
      case 2:
        this.handleBannerDelete();
        this.handleMobileSelectorClose();
        break;
      default:
      // do nothing
    }
  };
  Colletion = () => {
    if (this.state.collectionList) {
      return (
        <div className="userContent-list reference-container">
          {this.state.collectionList.map((item, idx) => {
            return (
              <div className="reference-content" key={`collections_key${idx}`}>
                <Collection
                  userInfoId={this.state.userInfo._id}
                  creatorInfo={this.state.userInfo}
                  history={this.props.history}
                  collectionId={item._id}
                  colletionReLoad={this.collectionListLoad}
                  collectionCount={item.projects.length}
                  collectionList={item.projects}
                  collectionName={item.name}
                  myCollection={this.state.isSelfUrl}
                ></Collection>
              </div>
            );
          })}
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <span style={{ marginTop: "60px" }}>
              {i18n.t("global.loading")}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <img src={ProjectAddIcon} alt="ProjectUplaod"></img>
            <span>{i18n.t("mypage.colletcion.empty")}</span>
          </div>
        </div>
      );
    }
  };
  Project = (desktop) => {
    if (this.state.videoList[0]) {
      return (
        <div className="userContent-list reference-container">
          {this.state.videoList.map((item, index) => {
            return (
              <div className="reference-content" key={`projects_key${index}`}>
                <Project
                  mobile={desktop ? false : true}
                  projectList={this.state.videoList}
                  location={this.props.loacation}
                  projectReLoad={this.projectLoad}
                  projLink={this.state.userInfo.linkURL}
                  thumbnail={item.thumbnail}
                  title={item.title}
                  creators={item.creators}
                  uploaderInfo={item.uploaderInfo}
                  likeMembers={item.likeMembers}
                  views={item.views}
                  myProj={this.state.isSelfUrl}
                  userInfoId={this.state.userInfo._id}
                  history={this.props.history}
                  projectId={item._id}
                  projUserId={item.userId}
                ></Project>
              </div>
            );
          })}
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <span style={{ marginTop: "60px" }}>
              {i18n.t("global.loading")}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nullList">
          <div
            className="projectNullBox"
            style={!this.state.isSelfUrl ? { cursor: "unset" } : null}
            onClick={this.toProjectupload}
          >
            <img src={ProjectAddIcon} alt="ProjectUplaod"></img>
            <span>{i18n.t("mypage.project.empty")}</span>
          </div>
        </div>
      );
    }
  };

  Like = () => {
    if (this.state.likeList[0]) {
      return (
        <div className="userContent-list reference-container">
          {this.state.likeList.map((item, index) => {
            return (
              <div
                className="reference-content"
                key={`like_projects_key${index}`}
              >
                <Project
                  projectList={this.state.likeList}
                  location={this.props.loacation}
                  thumbnail={item.thumbnail}
                  title={item.title}
                  uploaderInfo={item.uploaderInfo}
                  creators={item.creators}
                  likeMembers={item.likeMembers.length}
                  views={item.views}
                  projectId={item._id}
                  history={this.props.history}
                  projUserId={item.userId}
                ></Project>
              </div>
            );
          })}
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <span style={{ marginTop: "60px" }}>
              {i18n.t("global.loading")}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <img src={ProjectAddIcon} alt="ProjectUplaod"></img>
            <span>{i18n.t("mypage.liked.empty")}</span>
          </div>
        </div>
      );
    }
  };
  Draft = () => {
    if (this.state.draftList[0]) {
      return (
        <div className="userContent-list reference-container">
          {this.state.draftList.map((item, index) => {
            return (
              <div
                className="reference-content"
                key={`draft_projects_key${index}`}
              >
                <Project
                  projectList={this.state.draftList}
                  location={this.props.loacation}
                  projectReLoad={this.draftProjectLoad}
                  thumbnail={item.thumbnail ? item.thumbnail : null}
                  title={item.title ? item.title : null}
                  creators={item.creators}
                  likeMembers={item.likeMembers.length}
                  views={item.views}
                  history={this.props.history}
                  uploaderInfo={item.uploaderInfo}
                  myProj={this.state.isSelfUrl ? true : false}
                  userInfoId={this.state.userInfo._id}
                  projectId={item._id}
                  toOther={() => this.toOther}
                ></Project>
              </div>
            );
          })}
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="nullList">
          <div className="projectNullBox">
            <span style={{ marginTop: "60px" }}>
              {i18n.t("global.loading")}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nullList">
          <div
            className="projectNullBox"
            style={!this.state.isSelfUrl ? { cursor: "unset" } : null}
            onClick={this.toProjectupload}
          >
            <img src={ProjectAddIcon} alt="ProjectUplaod"></img>
            <span>{i18n.t("mypage.project.empty")}</span>
          </div>
        </div>
      );
    }
  };
  mobileInfo = () => {
    return (
      <div className="mobile-userInfo-container">
        <div className="info-title">
          {i18n.t("userSetting.userInfo.expaln")}
        </div>
        <div className="mobile-userInfo-intro">
          <div className="intro-header">
            <span>
              {this.state.userInfo.userInfo
                ? this.state.userInfo.userInfo.title
                : null}
            </span>
          </div>
          <span>
            {this.state.userInfo.userInfo &&
            this.state.userInfo.userInfo.explanation
              ? this.state.userInfo.userInfo.explanation
              : !this.state.userInfo.userInfo.explanation &&
                !this.state.userInfo.userInfo.title
              ? i18n.t("mypage.noDescription")
              : null}
          </span>
        </div>
        <div className="info-title">{i18n.t("global.additionalInfo")}</div>
        {this.state.userInfo.linkInfo.length ? (
          <div className={`profile-snsBox`}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "white",
                backgroundColor: "#070707",
                // border: "0.5px solid #070707",
                fontSize: "12px",
              }}
              className="sns-Avatars-Grp"
              gap={10}
            >
              {this.state.userInfo.linkInfo
                ? this.state.userInfo.linkInfo.map((item) => {
                    return item.link ? (
                      // <Tooltip title={item.websiteName} placement="top">
                      <Avatar
                        onClick={() =>
                          window.open(`https://${item.link}`, "_blank")
                        }
                        style={{
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        src={this.iconFinder(item.websiteName)}
                      />
                    ) : // </Tooltip>
                    null;
                  })
                : null}
            </Avatar.Group>
          </div>
        ) : null}

        <div className="mobile-userInfo-list">
          <div className="mobile-userInfo-box">
            <img src={MobileViewInfo} alt="viewCount" />
            <span className="mobile-userInfo-text">
              {i18n.t("mypage.profile.views")}
            </span>
            <span className="mobile-userInfo-count">
              {this.state.userInfo.views}
            </span>
          </div>

          <div className="mobile-userInfo-box">
            <img src={MobileLikeInfo} alt="viewCount" />
            <span className="mobile-userInfo-text">
              {i18n.t("mypage.profile.totalLike")}
            </span>
            <span className="mobile-userInfo-count">
              {this.state.userInfo.likes}
            </span>
          </div>
          {this.state.userInfo.website ? (
            <div className="mobile-userInfo-box">
              <img src={Home} alt="viewCount" />
              <span
                className="mobile-userInfo-count"
                onClick={() =>
                  window.open(
                    `https://${this.state.userInfo.website}`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {this.state.userInfo.website.length >= 30
                  ? this.state.userInfo.website.substr(0, 30) + "..."
                  : this.state.userInfo.website}
              </span>
            </div>
          ) : null}
        </div>

        <div className="mobile-userInfo-createdAt">
          <span>
            {i18n.t("mypage.profile.joing")}
            {""}
          </span>
          <span>
            {this.state.userInfo.createdAt
              ? this.state.userInfo.createdAt.substring(0, 10)
              : null}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const color = "white";
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    `;

    return this.state.render ? (
      <>
        <Media
          queries={{
            tablet: "(max-width:1199px)",
            desktop: "(min-width:1200px)",
          }}
        >
          {(matches) => (
            <>
              {this.state.shareDialogOpen ? (
                <ShareDialog
                  open={this.state.shareDialogOpen}
                  onClose={this.handleShareDialogClose}
                  projLink={this.state.userInfo.linkURL}
                ></ShareDialog>
              ) : null}
              <MobileSelector
                fileOnChage={this.handleBannerUpload}
                file={true}
                open={this.state.mobileSelectorOpen}
                onClose={this.handleMobileSelectorClose}
                selecList={this.state.selecList}
                handleSelec={this.handleSelecList}
              ></MobileSelector>
              <AlertDialog
                onButtonClick={this.handleAlertDialogClose}
                dialogHeadText={this.state.msgTitle}
                dialogBodyText1={this.state.msg}
                open={this.state.alertDialogOpen}
                onClose={this.handleAlertDialogClose}
              />
              {this.state.dialogRender ? (
                <ProjectDialog
                  isPage={this.state.isPage}
                  userId={this.props.myInfo._id}
                  open={this.state.projectDialogOpen}
                  onClose={this.handleProjectDialogClose}
                  history={this.props.history}
                  projInfo={this.state.projInfo}
                  timerSet={this.timerSet}
                ></ProjectDialog>
              ) : null}

              <div className="other-container">
                <div className="otherPage-container">
                  <div className="cotainer">
                    {/* <Spin
                      style={{ height: "50%" }}
                      indicator={antIcon}
                      spinning={this.state.bannerLoading}
                    > */}
                    <div
                      className={`mainBanner-screen ${
                        matches.desktop ? "desktopBanner" : null
                      }`}
                    >
                      {this.state.userInfo.bannerPhoto || this.state.preview ? (
                        <div
                          className={
                            this.state.isSelfUrl ? "mypageBanner" : null
                          }
                          style={{
                            backgroundImage: `url(${
                              this.state.preview
                                ? this.state.preview
                                : this.state.userInfo.bannerPhoto
                            })`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <FadeLoader
                            color={color}
                            loading={this.state.bannerLoading}
                            css={override}
                            size={10}
                          ></FadeLoader>
                          {this.state.isSelfUrl &&
                          !this.state.bannerLoading &&
                          matches.desktop ? (
                            <>
                              <div className="edit-btn-wrapper">
                                <button onClick={() => this.handleSelecList(2)}>
                                  Delete
                                </button>
                              </div>

                              <input
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />

                              <label htmlFor="mySpaceBanner">
                                <SvgColor
                                  svg={ProjectAddIcon}
                                  width={70}
                                  colors={["#fff", "#fff"]}
                                />

                                <span>{i18n.t("mypage.banner.title")}</span>
                              </label>
                            </>
                          ) : null}
                          {this.state.isSelfUrl && matches.tablet ? (
                            <>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                style={{ color: "white" }}
                                onClick={() =>
                                  this.setState({
                                    mobileSelectorOpen: true,
                                  })
                                }
                                className="moreButton"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <input
                                ref={(input) => (this.bannerUpload = input)}
                                className="test"
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />
                            </>
                          ) : null}
                        </div>
                      ) : (
                        //<canvas id="imgLoadCanvas"></canvas> */
                        <div>
                          {this.state.isSelfUrl && !this.state.bannerLoading ? (
                            <>
                              <input
                                type="file"
                                onChange={this.handleBannerUpload}
                                accept="image/jpg,image/jpeg,image/png,image/gif"
                                id="mySpaceBanner"
                              />
                              <label htmlFor="mySpaceBanner">
                                <img
                                  src={ProjectAddIcon}
                                  style={{ width: "70px", height: "70px" }}
                                  alt="bannerAddIcon"
                                />
                                <span> {i18n.t("mypage.banner.addTitle")}</span>
                              </label>
                            </>
                          ) : (
                            <FadeLoader
                              color={color}
                              loading={this.state.bannerLoading}
                              css={override}
                              size={10}
                            ></FadeLoader>
                          )}
                        </div>
                      )}
                    </div>
                    {/* </Spin> */}
                    <div className="page-container">
                      <UserInfo
                        userId={
                          this.props.myInfo ? this.props.myInfo._id : null
                        }
                        handleTeamFollow={this.handleTeamFollow}
                        handleTeamUnFollow={this.handleTeamUnFollow}
                        userInfo={this.state.userInfo}
                        isSelfURL={this.state.isSelfUrl}
                        history={this.props.history}
                        matches={matches}
                      />
                      <div className="project-box">
                        <div className="project-tabMenu">
                          <div className="tabMenu-list">
                            {matches.desktop &&
                              this.state.projectNav.map((item, index) => {
                                if (!this.state.isSelfUrl && item.id === 3) {
                                } else {
                                  return (
                                    <div
                                      key={`navigator_key${index}`}
                                      className={
                                        this.state.selecMenu === item.id
                                          ? "selecMenu"
                                          : null
                                      }
                                      onClick={() =>
                                        this.handleViewProject(item.id)
                                      }
                                    >
                                      {item.text}
                                    </div>
                                  );
                                }
                              })}

                            {matches.tablet &&
                              this.state.mobileProjectNav.map((item, index) => {
                                if (!this.state.isSelfUrl && item.id === 4) {
                                } else {
                                  return (
                                    <div
                                      key={`navigator_key${index}`}
                                      className={
                                        this.state.selecMenu === item.id
                                          ? "selecMenu"
                                          : null
                                      }
                                      onClick={() =>
                                        this.handleMobileViewProject(item)
                                      }
                                    >
                                      {item.text}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                          {matches.desktop && (
                            <button className="iconBorderBtn">
                              <img
                                src={shareBtn}
                                alt="shareBtn"
                                onClick={this.handleShareDialogOpen}
                              />
                            </button>
                          )}
                        </div>
                        <div className="project-list-container">
                          {matches.desktop &&
                            this.desktopRender(this.state.selecMenu, matches)}
                          {matches.tablet &&
                            this.mobileRender(this.state.selecMenu, matches)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Media>
      </>
    ) : null;
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});
export default compose(connect(mapStateToProps), withSnackbar)(MyPage);
