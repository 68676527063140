import React, { Component } from "react";
import xboxIcon from "../image/no-applicant-icon.svg";
import { renderDate } from "../Component/functions";
import JobContent from "../Component/Job/Job";
import i18n from "../i18n";

class JobTable extends Component {
  state = {};
  renderDeadEnd = (date) => {
    if (date) {
      return `${i18n.t("jobInfo.otherjobs.deadend")} ${renderDate(date, true)}`;
    } else {
      return "";
    }
  };
  renderPeriod = (date) => {
    if (date) {
      return date.substring(0, 10).replaceAll("-", ".");
    } else {
      return "";
    }
  };
  renderTable = () => {
    if (this.props.selectNav === 1) {
      return (
        <>
          {this.props.supportList.length !== 0 ? (
            this.props.supportList.map((item, key) => {
              return (
                <JobContent
                  handleJobDetailDialogOpen={
                    this.props.handleJobDetailDialogOpen
                  }
                  handleDelConfirmDialogOpen={
                    this.props.handleDelConfirmDialogOpen
                  }
                  handleApply={this.props.handleApply}
                  jobInfo={item}
                  index={key}
                  row={true}
                  history={this.props.history}
                  management={true}
                />
              );
            })
          ) : (
            <div className="null-content-container">
              <div className="null-content-wrapper">
                <img src={xboxIcon} alt="xboxIcon" />
                <span> {i18n.t("지원 내역이 없습니다.")}</span>
              </div>
            </div>
          )}
        </>
      );
    } else if (this.props.selectNav === 2) {
      return (
        <>
          {this.props.favorites.length !== 0 ? (
            this.props.favorites.map((item, key) => {
              return (
                <JobContent
                  handleJobDetailDialogOpen={
                    this.props.handleJobDetailDialogOpen
                  }
                  handleDelConfirmDialogOpen={
                    this.props.handleDelConfirmDialogOpen
                  }
                  handleApply={this.props.handleApply}
                  jobInfo={item.job}
                  index={key}
                  row={true}
                  history={this.props.history}
                  management={true}
                  bookmark={true}
                />
              );
            })
          ) : (
            <div className="null-content-container">
              <div className="null-content-wrapper">
                <img src={xboxIcon} alt="xboxIcon" />
                <span> {i18n.t("저장된 채용 북마크가 없습니다.")}</span>
              </div>
            </div>
          )}
        </>
      );
    }
  };
  render() {
    return this.renderTable();
  }
}

export default JobTable;
