import React, { Component } from "react";
import "./TeamSetting.scss";
import Account from "./Account/Account";
import Delete from "./Delete/Delete";
import BasicInfo from "./BasicInfo/BasicInfo";
import Link from "./Link/Link";
import MyIntro from "./MyIntro/MyIntro";
import Availability from "./Availability/Availability";
import i18n from "../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { GetTeamSettingInfo, PatchTeamInfo } from "../Api/Team/Team";

import { withSnackbar } from "notistack";
import { GetUserToken } from "../modules/userAuth";
class TeamSetting extends Component {
  constructor(props) {
    super(props);
    this.state = { setMenu: "account" };
  }
  state = {
    teamId: "",
    profilePhoto: "",
    teamName: "",
    job: "",
    locationInfo: {
      countryName: "",
      countryCode: "",
      region: null,
    },
    website: "",
    linkInfo: [],
    teamInfo: {
      title: "",
      explanation: "",
    },
    userLink: "",
    btnActive: false,
    linkURL: "",
    originLinkURL: "",
    allowJoin: "",
  };

  componentDidMount = async () => {
    const param = this.props.history.location.state;

    const token = GetUserToken();
    this.setState({ isLoading: true });
    if (token) {
      try {
        if (token) {
          const response = await GetTeamSettingInfo(param.teamId);
          if (response) {
            this.setState(
              {
                teamId: param.teamId,
                profilePhoto: response.teamPhoto,
                teamName: response.name,
                job: response.job,
                website: response.website,
                linkInfo: response.linkInfo,
                locationInfo: response.countryInfo,
                teamInfo: response.teamInfo,
                linkURL: response.linkURL,
                originLinkURL: response.linkURL,
                allowJoin: response.allowJoin,
              },
              () => {
                this.setState({ isLoading: false });
              }
            );
          }
        }
      } catch (e) {
        console.dir(e);
        if (e.response.status === 400) {
          // alert("접근 권한이 없습니다.");
          this.handleAlert("global.error.server.msg", {
            variant: "error",
            key: i18n.t("global.error.server.msg"),
            autoHideDuration: 5000,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          this.props.history.goBack();
        }
      }
    } else {
      // alert("로그인 후 이용가능합니다");
      this.handleAlert(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      this.props.history.push("/login");
    }
  };

  handleNecessartValue = () => {
    if (
      this.state.teamName &&
      this.state.linkURL &&
      this.state.locationInfo.countryCode &&
      this.state.locationInfo.countryName
    ) {
      this.setState({ btnActive: true });
    } else {
      this.setState({ btnActive: false });
    }
  };

  handleSaveUserInfo = async () => {
    const linkInfo = this.state.linkInfo;
    this.setState({ isLoading: true });
    if (linkInfo) {
      for (let i = 0; i < linkInfo.length; i++) {
        delete linkInfo[i].id;
        delete linkInfo[i].icon;
        delete linkInfo[i].text;
        delete linkInfo[i].placeholder;
      }
      linkInfo.sort(function (a, b) {
        return (a.link === "") - (b.link === "");
      });
    }

    // 비어 있는 소셜 링크 값 순서 마지막으로 변경

    this.setState({ linkInfo });

    try {
      const response = await PatchTeamInfo(
        this.state.teamId,
        this.state.teamName,
        this.state.linkURL,
        this.state.locationInfo,
        this.state.linkInfo,
        this.state.website,
        this.state.allowJoin,
        this.state.teamInfo
      );

      if (response.message === "success") {
        this.setState({ isLoading: false }, () => {
          // alert("저장 되었습니다");
          this.handleAlert("저장되었습니다");
          // this.props.history.goBack();
          this.props.history.push(`/team/@${this.state.linkURL}`);
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };

  handleMyIntroSet = (data) => {
    this.setState({
      teamInfo: {
        title: data.title,
        explanation: data.explanation,
      },
    });
  };

  handleBasicInfoSet = (data) => {
    this.setState(
      {
        // profilePhoto: data.file,
        profilePhoto: data.profile,
        teamName: data.teamName,
        // job: data.job,
        locationInfo: {
          countryName: data.countryName,
          countryCode: data.countryCode,
          region: data.region,
        },
        website: data.website,
      },
      () => {
        this.handleNecessartValue();
      }
    );
  };

  handleLinkInfoSet = (data) => {
    const linkInfo = data;

    this.setState({
      linkInfo,
    });
  };

  handleUserLinkSet = (data) => {
    this.setState({
      linkURL: data.linkUrl,
      btnActive: data.urlAvailable,
    });
  };

  handleAvailabilityInfoSet = (data) => {
    this.setState({ allowJoin: data });
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );

    return (
      <Spin
        indicator={antIcon}
        spinning={this.state.isLoading}
        delay={500}
        wrapperClassName={"main-spin"}
      >
        <div className="SettingContainer">
          <div className="setting-leftNav"></div>
          <div className="SettingMain">
            {this.state.isLoading === true ? null : (
              <>
                <div className="PageBox">
                  <BasicInfo
                    teamInfo={this.state}
                    handleBasicInfoSet={this.handleBasicInfoSet}
                  />
                </div>
                <div className="PageBox">
                  <MyIntro
                    teamInfo={this.state}
                    handleMyIntroSet={this.handleMyIntroSet}
                  />
                </div>
                <div className="PageBox">
                  <Account
                    teamInfo={this.state}
                    handleUserLinkSet={this.handleUserLinkSet}
                  />
                </div>
                <Availability
                  teamInfo={this.state}
                  handleAvailabilityInfoSet={this.handleAvailabilityInfoSet}
                ></Availability>
                <div className="PageBox">
                  <Link
                    teamInfo={this.state}
                    handleLinkInfoSet={this.handleLinkInfoSet}
                  />
                </div>
                <div className="PageBox">
                  <Delete teamInfo={this.state} history={this.props.history} />
                </div>
              </>
            )}
            <button
              disabled={!this.state.btnActive}
              className={this.state.btnActive ? "submitBtn" : "disabledBtn"}
              onClick={this.state.btnActive ? this.handleSaveUserInfo : null}
            >
              {i18n.t("teamSetting.saveBtn")}
            </button>
          </div>
        </div>
      </Spin>
    );
  }
}

export default withSnackbar(TeamSetting);
