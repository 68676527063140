import React, { Component } from "react";
import "./Footer.scss";
import "../../index.css";
import i18n from "../../i18n";
import instaIcon from "../../image/snsicon-01.svg";
import fbIcon from "../../image/snsicon-02.svg";
import youtubeIcon from "../../image/snsicon-03.svg";
import footerLogo from "../../image/black-logo-icon.svg";
import { langChange } from "../../Redux/action/index";
import { connect } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import TermsDialog from "../../SignUp/TermsDialog/TermsDialog";
import PersonalDialog from "../../SignUp/PersonalDialog/PersonalDialog";
import RulesDialog from "../../SignUp/OperationRulesDialog/OperationRulesDialog";
import ArrowIcon from "../../image/language-down-arrow-icon.svg";
class Footer extends Component {
  state = {
    langMenuOpen: false,
    termsDialogOpen: false,
    personalDialogOpen: false,
    rulesDialogOpen: false,
  };
  lang = [
    {
      title: "English",
      id: "en",
    },
    {
      title: "한국어",
      id: "ko",
    },
  ];
  handleLangOpen = () => {
    this.setState({ langMenuOpen: true });
  };
  handleLangClose = () => {
    this.setState({ langMenuOpen: false });
  };
  componentDidMount = () => {
    console.log(this.props.history);
    console.log(this.props);
  };
  onSelect = (lang) => {
    i18n.changeLanguage(lang);
    this.props.langChange(lang);
    this.handleLangClose();
    window.location.reload();
  };
  toIntroPage = () => {
    this.props.history.push("/intro");
  };
  toFaqPage = () => {
    this.props.history.push("/faq");
  };
  handleTermsDialogClose = () => {
    this.setState({ termsDialogOpen: false });
  };
  handlePersonalDialogClose = () => {
    this.setState({ personalDialogOpen: false });
  };
  handleRulesDialogClose = () => {
    this.setState({ rulesDialogOpen: false });
  };
  render() {
    return (
      <>
        <TermsDialog
          open={this.state.termsDialogOpen}
          onClose={this.handleTermsDialogClose}
        />
        <PersonalDialog
          open={this.state.personalDialogOpen}
          onClose={this.handlePersonalDialogClose}
        />
        <RulesDialog
          open={this.state.rulesDialogOpen}
          onClose={this.handleRulesDialogClose}
        />
        <div
          className="footer-container"
          style={
            this.props.isAdmin
              ? { height: "unset", borderTop: "1px solid #c8c8c8" }
              : { height: "74px" }
          }
        >
          {this.props.isAdmin ? null : (
            <div className="footer-topBox">
              <div className="footer-header">
                <span>{i18n.t("footer.contact")}</span>
                <span>help@motiontrend.tv</span>
              </div>

              <div className="footer-rt">
                <span>@ Motiontrend All right reserved</span>
                <div className="footer-icons">
                  <img
                    style={{ cursor: "pointer" }}
                    src={instaIcon}
                    alt="instagram"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/motiontrend.tv/?igshid=xoyeal0g1mcr",
                        "_blank"
                      )
                    }
                  />

                  <img src={fbIcon} alt="facebook" />
                  <img src={youtubeIcon} alt="youtube" />
                </div>
              </div>
            </div>
          )}
          <div
            className="footer-bottomBox"
            style={this.props.isAdmin ? { height: "30px" } : { height: "50%" }}
          >
            <div className="footer-service">
              <span onClick={this.toIntroPage}>
                {i18n.t("footer.introdution")}
              </span>
              <span
                onClick={() => {
                  this.setState({ termsDialogOpen: true });
                }}
              >
                {i18n.t("footer.terms")}
              </span>
              <span
                onClick={() => {
                  this.setState({ personalDialogOpen: true });
                }}
              >
                {i18n.t("footer.privacy")}
              </span>
              <span
                onClick={() => {
                  this.setState({ rulesDialogOpen: true });
                }}
              >
                {i18n.t("footer.OpertationRules")}
              </span>
              <input type="checkbox" name="menu"></input>
              <span onClick={this.toFaqPage}>{i18n.t("FAQ")}</span>
              <label id="lang" htmlFor="lang" onClick={this.handleLangOpen}>
                {i18n.t("header.lang")}
                <img
                  src={ArrowIcon}
                  style={{
                    transform: this.state.langMenuOpen
                      ? "rotate(180deg)"
                      : null,
                  }}
                  alt="ArrowIcon"
                />
                <span></span>
                {this.state.langMenuOpen ? (
                  <ClickAwayListener onClickAway={this.handleLangClose}>
                    <div draggable={false} className="langMenu">
                      {this.lang.map((item, index) => {
                        return (
                          <React.Fragment key={`langs${index}`}>
                            <input
                              type="radio"
                              name="selLang"
                              id={`langs_${item.id}`}
                              value={item.id}
                              checked={
                                item.id === this.props.lang ? "checked" : null
                              }
                            ></input>
                            <label
                              htmlFor={`langs_${item.id}`}
                              onClick={() => this.onSelect(item.id)}
                            >
                              {item.title}
                            </label>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </ClickAwayListener>
                ) : null}
              </label>
            </div>

            <NavLink to={`/`}>
              <img src={footerLogo} alt="logo" />
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
