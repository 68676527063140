//액션 타입 정의
export const LANG = "LANG";
export const GET_MY_INFO = "GET_MY_INFO";
export const SET_IS_OUT = "SET_IS_OUT";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const TYPE_STATUS = "TYPE_STATUS";
export const SEARCH_LIST = "SEARCH_LISH";
export const PROJECT_EDIT = "PROJECT_EDIT";
export const SOCKET_STATUS = "SOCKET_STATUS";
export const TEAM_INFO = "TEAM_INFO";
//액션 생성 함수 정의
export const getMyInfo = (value) => {
	return {
		type: GET_MY_INFO,
		value: value,
	};
};

export const projectEdit = (value) => {
	return {
		type: PROJECT_EDIT,
		value: value,
	};
};
export const searchContent = (value) => {
	return {
		type: SEARCH_LIST,
		value: value,
	};
};
export const setIsOut = (value) => {
	return {
		type: SET_IS_OUT,
		value: value,
	};
};

export const langChange = (value) => {
	return {
		type: LANG,
		value: value,
	};
};

export const changeLoginStatus = (value) => {
	return {
		type: LOGIN_STATUS,
		value: value,
	};
};

export const changeType = (value) => {
	return {
		type: TYPE_STATUS,
		value: value,
	};
};

export const changeSocket = (value) => {
	return {
		type: SOCKET_STATUS,
		value: value,
	};
};
