import React, { Component } from "react";
import "./JoinTeam.scss";
import TeamSubscription from "./TeamSubscription/TeamSubscription";
import JoinComplete from "./JoinComplete/JoinComplete";
import i18n from "../../i18n";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { withSnackbar } from "notistack";
import { GetTeamInfo, PostTeamApply } from "../../Api/Team/Team";
import { ExistingTeam_GAEvent } from "../../modules/gaEvents";
class JoinTeam extends Component {
  state = {
    isLoading: false,
    menuList: [
      {
        id: 1,
        menu: i18n.t("team.join.step_1"),
      },

      // {
      //   id: 2,
      //   menu: i18n.t("team.join.step_2"),
      // },
    ],
    msg: "",
    teamInfo: {},
    teamLinkURL: "",
    menu: 1,
    members: [],
  };
  componentDidMount = () => {
    ExistingTeam_GAEvent("render");
  };
  handleNextStep = () => {
    if (this.state.menu === 2) {
      ExistingTeam_GAEvent("complete");
      return;
    } else {
      this.setState({ menu: this.state.menu + 1 });
    }
  };
  handleTeamSelecCheck = () => {
    if (this.state.msg && this.state.teamInfo) {
      return true;
    } else return false;
  };
  loadTeamInfo = async () => {
    try {
      const response = await GetTeamInfo(this.state.teamInfo._id);
      this.setState({ teamLinkURL: response.linkURL, isLoading: false }, () => {
        this.handleNextStep();
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleTeamApply = async () => {
    this.setState({ isLoading: true });
    try {
      await PostTeamApply(this.state.teamInfo._id, this.state.msg);
      this.loadTeamInfo();
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleToTeamPage = () => {
    this.props.history.push(`/team/list?tab=2`);
  };
  handleBackStep = () => {
    if (this.state.menu === 1) {
      this.props.history.push("/team/list");
    } else {
      this.setState({ menu: this.state.menu - 1 });
    }
  };
  handleSetInfo = (data) => {
    this.setState({ teamInfo: data.selectedTeam, msg: data.msg });
  };
  renderMenu = () => {
    if (this.state.menu === 1) {
      return <TeamSubscription handleSetInfo={this.handleSetInfo} />;
    } else if (this.state.menu === 2) {
      return <JoinComplete teamInfo={this.state.teamInfo}></JoinComplete>;
    }
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
        <div className="container createTeam joinTeam">
          <div className="createTeamBox">
            <div className="createTeam-menuStep">
              {this.state.menuList.map((item) => {
                return (
                  <span
                    className={`menuStep-item 
                  ${this.state.menu === item.id ? "selectedMenu" : ""}`}
                    style={{ width: "100%" }}
                  >
                    {item.menu}
                  </span>
                );
              })}
            </div>
            {this.renderMenu()}
            <div
              className={`createTeamBtns ${
                this.state.menu === 2 ? "join-cancelBtn" : ""
              }`}
            >
              {this.state.menu === 1 ? (
                <>
                  <button onClick={this.handleBackStep}>
                    {i18n.t("global.back")}
                  </button>
                  <button
                    className={`${
                      this.handleTeamSelecCheck() ? "" : "disabled-button"
                    }`}
                    onClick={
                      this.handleTeamSelecCheck() ? this.handleTeamApply : null
                    }
                  >
                    {i18n.t("global.apply")}
                  </button>
                </>
              ) : this.state.menu === 2 ? (
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={this.handleToTeamPage}
                >
                  {i18n.t("teamPage.success.button2")}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default withSnackbar(JoinTeam);
