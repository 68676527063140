import React, { Component } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Slider from "react-slick";
import "./JobDialog.scss";
import WhiteCloseIcon from "../../../image/close-white-icon.svg";
import rightBtn from "../../../image/r-white-circle-arrow.svg";
import leftBtn from "../../../image/l-white-circle-arrow.svg";
import shareBtn from "../../../image/job/share-black-icon.svg";
import locationIcon from "../../../image/job/location-gray-icon.svg";
import BookmarkIcon from "../../../image/job/gray-bookmark-icon.svg";
import UnBookmarkIcon from "../../../image/job/black-bookmark-icon.svg";
import calendarIcon from "../../../image/job/black-date-icon.svg";
import viewMoreBtn from "../../../image/job/enter-comments-icon.svg";
import profileEditBtn from "../../../image/job/edit-profile-icon.svg";
import supportCompleteIcon from "../../../image/job/support-complete-icon.svg";
import Media from "react-media";
import Header from "../../../Component/MobileHeader/MobileHeader";
import {
  nFormatter,
  numberWithCommas,
  renderWorkingHours,
  renderCareer,
} from "../../../Component/functions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import XboxIcon from "../../../image/no-result-icon.svg";
import i18n from "../../../i18n";
import ApplyDialog from "../applyDialog/applyDialog";
import ShareDialog from "../../../Component/ShareDialog/ShareDialog";
import FadeLoader from "react-spinners/BeatLoader";
import BlackButton from "../../../Component/Button/Button";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
import { JobDetail_GAEvent } from "../../../modules/gaEvents";

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  draggable: true,
  freeScroll: true,
  wrapAround: false,
  contain: true,
  cellAlign: "left",
};

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      alt="nextArrow"
      style={{
        ...style,
        display: "block",
        width: "44px",
        height: "44px",
        right: "20px",
      }}
      onClick={onClick}
      src={rightBtn}
    />
  );
}
function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      alt="prevArrow"
      style={{
        ...style,
        display: "block",
        width: "44px",
        height: "44px",
        left: "20px",
      }}
      onClick={onClick}
      src={leftBtn}
    />
  );
}
class JobDetail extends Component {
  state = {
    jobInfo: {},
    isLoading: true,
    successDialogOpen: false,
    isUserInfoLoading: false,
    userInfo: {},
    isBookmark: false,
    isFollow: false,
    userProjectArray: [],
    teamJobArray: [],
    fullScreen: false,
    supported: false,
    navTop: 0,
    shareDialogOpen: false,
    startGradation: false,
    endGradation: true,
    timer: "",
  };

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  timer = null;
  seconds = 0;
  setTime = () => {
    let sttDate = new Date().getSeconds();
    const endDate = this.Date + 3;
    if (sttDate === endDate) {
      JobDetail_GAEvent("stay");
    }
  };
  componentDidMount = () => {
    this.timer = setInterval(this.setTime, 1000);

    if (window.innerWidth < 1025) {
      this.setState({ fullScreen: true });
    }
    window.addEventListener("resize", (e) => {
      this.handleResize(e.target.innerWidth);
    });
  };

  handleResize = (width) => {
    if (width < 1025) {
      this.setState({ fullScreen: true });
    } else {
      this.setState({ fullScreen: false });
    }
  };
  componentWillUnmount = () => {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.handleResize);
  };
  renderPeriod = (date) => {
    if (date) {
      return date.substring(0, 10).replaceAll("-", ".");
    } else {
      return "";
    }
  };

  renderDeadEnd = (date) => {
    if (date) {
      const dateInfo = new Date(date);
      return `${i18n.t("jobInfo.otherjobs.deadend")} ${
        this.monthNames[dateInfo.getMonth()]
      } ${dateInfo.getDate()}`;
    } else {
      return "";
    }
  };

  onCloseShareDialog = () => {
    this.setState({ shareDialogOpen: false });
  };

  handleShare = () => {
    JobDetail_GAEvent("share");
    this.setState({ shareDialogOpen: true });
  };

  renderPayInfo = (payInfo) => {
    if (i18n.language === "ko" || i18n.language === "ko_KR") {
      if (payInfo.currency === "KRW") {
        return `${numberWithCommas(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${numberWithCommas(payInfo.maximum).toLocaleString("ko-KR")} 만원`;
      } else {
        return `${numberWithCommas(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${numberWithCommas(payInfo.maximum).toLocaleString("ko-KR")} 만원`;
      }
    } else {
      if (payInfo.currency === "USD") {
        return `${nFormatter(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
      } else {
        return `${nFormatter(payInfo.minimum).toLocaleString(
          "ko-KR"
        )} - ${nFormatter(payInfo.maximum).toLocaleString("ko-KR")} $`;
      }
    }
  };

  renderSpin = (size = 24, color) => {
    return (
      <FadeLoader
        color={color ? color : "black"}
        loading={this.state.isLoading}
        size={size}
      />
      //   <Spin
      //     indicator={
      //       <LoadingOutlined style={{ fontSize: size, color: "#ff2e43" }} spin />
      //     }
      //     spinning={this.state.isLoading}
      //     delay={500}
      //   ></Spin>
    );
  };

  renderCategories = (categories) => {
    let categoryArr = [];
    if (i18n.language === "en") {
      categoryArr = categories.map((item) => item.name.en);
    } else {
      categoryArr = categories.map((item) => item.name.ko);
    }

    return categoryArr.join(" / ");
  };
  handleSearchTag = (e, tag) => {
    // console.log(this.props, tag);
    if (this.props.history.location.pathname === "/job") {
      this.props.handleSearchTag(tag);
    } else {
      this.props.history.push(`/job?s=${tag}`);
    }
  };
  onTagScroll = (e) => {
    if (e.target.scrollLeft > 0) {
      this.setState({ startGradation: true });
    } else {
      this.setState({ startGradation: false });
    }

    if (e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth) {
      this.setState({ endGradation: true });
    } else {
      this.setState({ endGradation: false });
    }
  };

  renderSupported = () => {
    return (
      <>
        <span className="supported_title">
          {i18n.t("jobInfo.applied.title")}
        </span>
        <div className="supported_text">
          <img src={supportCompleteIcon} alt="supportCompleteIcon" />
          <span>{i18n.t("jobInfo.applied.text")}</span>
        </div>
        <div className="supported_btn-container">
          <div className="supported_btn" onClick={this.props.supportedDone}>
            <span>{i18n.t("global.back")}</span>
          </div>
        </div>
      </>
    );
  };
  renderMsg = () => {
    return (
      <>
        <div className="supported_title">{i18n.t("jobInfo.impossible")}</div>

        <div className="supported_text">
          <img src={XboxIcon} alt="errIcon" />
          <span> {this.props.errMsg}</span>
        </div>
        <div className="supported_btn-container">
          <BlackButton
            text={i18n.t("project.upload.back")}
            width={"100%"}
            btnClick={this.props.errBackClick}
          />
        </div>
      </>
    );
  };
  renderCancelConfirm = () => {
    return (
      <>
        <div className="supported_title">{i18n.t("jobInfo.cancel.btn")}</div>

        <div className="supported_text">
          <img src={XboxIcon} alt="errIcon" />
          <span>정말 지원을 취소하시겠습니까?</span>
        </div>
        <div className="cancel_btn-container">
          <div
            className="supported_btn"
            onClick={() => this.props.handleCancelApply(this.props.jobInfo._id)}
          >
            <span>{i18n.t("jobInfo.cancel.btn")}</span>
          </div>
          <div className="supported_btn" onClick={this.props.applyCancelBack}>
            <span>{i18n.t("global.back")}</span>
          </div>
        </div>
      </>
    );
  };
  renderResume = () => {
    const resume = this.props.resume;
    return (
      <>
        <div className="resume_header">
          <span>{i18n.t("job.resume.title")}</span>
          <div
            className="profile_edit-btn"
            onClick={() => this.props.history.push(`/setting?profileEdit=user`)}
          >
            <img src={profileEditBtn} alt="profileEditBtn" />
          </div>
        </div>
        <div className="resume_info-container">
          <div className="resume_info name">
            <span>{i18n.t("global.name")}</span>
            <span>{resume.userName}</span>
          </div>
          <div className="resume_info email">
            <span>{i18n.t("global.email")}</span>
            <span>{resume.email}</span>
          </div>
        </div>
        {!resume.projects || resume.projects.length === 0 ? (
          <div className="resume_empty">
            <span>{i18n.t("jobInfo.project.empty.text")}</span>
            <div
              className="upload_btn"
              onClick={() => this.props.history.push("/project-upload")}
            >
              <span>{i18n.t("global.upload")}</span>
            </div>
          </div>
        ) : (
          <div className="resume_projects-list">
            {resume.projects
              .filter((item, index) => index < 3)
              .map((item) => {
                return <img src={item.thumbnail} alt="project" />;
              })}
          </div>
        )}
        <div className="support_btns">
          <div
            className="support_btn"
            onClick={this.props.handleRemovePortfolio}
          >
            <span>{i18n.t("global.back")}</span>
          </div>
          <div
            className="support_btn support"
            onClick={
              !resume.projects || resume.projects.length === 0
                ? null
                : () => {
                    //this.setState({ supported: true });
                    this.props.handleApply();
                  }
            }
            style={
              !resume.projects || resume.projects.length === 0
                ? {
                    backgroundColor: "#efefef",
                    border: "1px solid #efefef",
                    cursor: "default",
                  }
                : null
            }
          >
            <span>{i18n.t("jobInfo.btn")}</span>
          </div>
        </div>
      </>
    );
  };

  renderJobInfo = () => {
    const jobInfo = this.props.jobInfo;
    return jobInfo ? (
      <>
        <div className="job_big-btns">
          <div
            className="job_big-btn"
            onClick={() => {
              JobDetail_GAEvent("bookmark");
              this.props.handleBookMark();
            }}
          >
            <img
              src={jobInfo.isFavorite ? UnBookmarkIcon : BookmarkIcon}
              alt="bookmarkBtn"
            />
            <span className="job_btn-title">
              {jobInfo.isFavorite
                ? i18n.t("jobInfo.unmarkBtn")
                : i18n.t("jobInfo.bookmarkBtn")}
            </span>
          </div>
          <div
            className="job_big-btn"
            onClick={
              jobInfo.isApplied
                ? () => this.props.handleApply()
                : () => this.props.getResume(jobInfo._id, jobInfo.teamInfo._id)
            }
          >
            {this.props.resume === -1 ? (
              this.renderSpin(6, "white")
            ) : (
              <span className="job_btn-title">
                {jobInfo.isApplied
                  ? i18n.t("jobInfo.cancel.btn")
                  : i18n.t("jobInfo.btn")}
              </span>
            )}
          </div>
        </div>
        <div className="job_detail-info">
          <span className="job_detail-position">{jobInfo.position}</span>
          <div className="info">
            <span>{i18n.t("jobInfo.recruitment.title")}</span>
            <span>
              {i18n.language === "en"
                ? jobInfo.category?.name.en
                : jobInfo.category?.name.ko}
            </span>
          </div>
          {jobInfo.remoteAvailable ? (
            <div className="info">
              <span>{i18n.t("jobInfo.remote.title")}</span>
              <span>{i18n.t("jobInfo.otherjobs.remote")}</span>
            </div>
          ) : null}
          <div className="info">
            <span>{i18n.t("jobInfo.career.title")}</span>
            <span>{renderCareer(jobInfo.careerStatus)}</span>
          </div>
          <div className="info">
            <span>{i18n.t("jobInfo.employmentType.title")}</span>
            <span>{renderWorkingHours(jobInfo.workingHours)}</span>
          </div>
          <div className="info">
            <span>{i18n.t("jobInfo.AnnualIncome.title")}</span>
            <span>
              {jobInfo.payInfo.isPrivate
                ? i18n.t("jobInfo.paymnet.private")
                : `${
                    i18n.language === "en"
                      ? nFormatter(
                          i18n.language === "en"
                            ? Number(jobInfo.payInfo.minimum) * 10000
                            : jobInfo.payInfo.minimum
                        ).toLocaleString("ko-KR") +
                        " ~ " +
                        nFormatter(
                          i18n.language === "en"
                            ? Number(jobInfo.payInfo.maximum) * 10000
                            : jobInfo.payInfo.maximum
                        ).toLocaleString("ko-KR")
                      : numberWithCommas(
                          jobInfo.payInfo.minimum
                        ).toLocaleString("ko-KR") +
                        " ~ " +
                        numberWithCommas(
                          jobInfo.payInfo.maximum
                        ).toLocaleString("ko-KR")
                  } ${
                    jobInfo.payInfo.currency === "KRW"
                      ? i18n.language === "ko" || i18n.language === "ko-KR"
                        ? "만원"
                        : "₩"
                      : "$"
                  }`}
            </span>
          </div>
          <div className="info">
            <span>{i18n.t("jobInfo.deadline.title")}</span>
            <span>
              {jobInfo.periodInfo.regularBasis
                ? i18n.t("jobInfo.period.regularbasis")
                : this.renderPeriod(jobInfo.periodInfo.endDate)}
            </span>
          </div>
          <div className="info">
            <span>{i18n.t("jobInfo.modifiedDate.title")}</span>
            <span>{this.renderPeriod(jobInfo.updatedAt)}</span>
          </div>
        </div>
      </>
    ) : (
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "24px", color: "#ff2e43" }}
            spin
          />
        }
        spinning={this.state.isLoading}
        delay={500}
      ></Spin>
    );
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const { HeaderOnClose, onClose, open, jobInfo, isPage, otherJobs } =
      this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
    };
    if (jobInfo && jobInfo.tags && jobInfo.tags.length) {
      flickityOptions.initialIndex = jobInfo.tags.length;
    }
    return (
      <>
        {jobInfo && (
          <ShareDialog
            fullWidth={this.state.fullScreen}
            open={this.state.shareDialogOpen}
            onClose={this.onCloseShareDialog}
            projLink={`j=${jobInfo?._id}`}
          ></ShareDialog>
        )}

        <Media
          key={`jobDialgo-key`}
          queries={{
            mobile: "(max-width: 799px)",
            mobileTablet: "(max-width: 1024px)",
            tablet: "(min-width: 800px) and (max-width: 1024px)",
            desktop: "(min-width: 1025px)",
          }}
        >
          {(matches) => (
            <>
              <Dialog
                disableEnforceFocus
                id="dialog_container"
                className={`job-detail-dialog ${
                  isPage ? "page_job" : "dialog_job"
                }`}
                open={open}
                onClose={onClose}
                fullScreen={this.state.fullScreen}
              >
                {matches.desktop && (
                  <div className="closeBtn" onClick={onClose}>
                    <img src={WhiteCloseIcon} alt="Close" />
                  </div>
                )}

                <div
                  className="job_detail-container job_dialog-page"
                  // onScroll={this.handleScroll}
                >
                  {matches.mobileTablet && (
                    <Header
                      onClose={!isPage ? onClose : HeaderOnClose}
                      isBottomSheet={false}
                      selectOpen={() =>
                        this.setState({
                          mobileSelecOpen: !this.state.mobileSelecOpen,
                        })
                      }
                      location={this.props.history.location.pathname}
                      //dialog={true}
                    ></Header>
                  )}
                  <div className="job_detail-main">
                    <div
                      className="job_detail-left"
                      style={
                        !jobInfo
                          ? {
                              width: "100%",
                              height: "100vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }
                          : null
                      }
                    >
                      {jobInfo ? (
                        <>
                          <div className="left-img">
                            <Slider {...settings}>
                              {jobInfo.images.map((src, key) => {
                                return (
                                  <div
                                    className="carousel_item"
                                    key={`${key}_img`}
                                    //style={{ backgroundImage: `url(${src})` }}
                                  >
                                    <img src={src} alt="job_img" />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                          <div className="left-header">
                            <div className="header-info">
                              <span className="job_title">
                                {jobInfo.position}
                              </span>
                              {/* <CopyToClipboard
                                onCopy={this.handleShare}
                                text={
                                  window.location.origin +
                                  "/job?id=" +
                                  jobInfo._id
                                }
                              >
                              </CopyToClipboard> */}
                              <div
                                className="job_btn"
                                onClick={this.handleShare}
                              >
                                <img src={shareBtn} alt="shareBtn" />
                              </div>
                            </div>
                            <div className="header-company-info">
                              <div
                                className="company-info"
                                onClick={() =>
                                  this.props.history.push(
                                    `/team/@${jobInfo.teamInfo.linkURL}`
                                  )
                                }
                              >
                                <div className="company-photo">
                                  <ProfilePhoto
                                    src={jobInfo.teamInfo.teamPhoto}
                                    size={18}
                                  />
                                </div>
                                <span>{jobInfo.teamInfo.name}</span>
                              </div>
                              <div className="company-location">
                                <img src={locationIcon} alt="locationIcon" />
                                <span>
                                  {jobInfo.countryInfo.countryName}
                                  {jobInfo.countryInfo.region !== "null"
                                    ? " · " + jobInfo.countryInfo.region
                                    : null}
                                </span>
                              </div>
                            </div>
                            <div className="header_tags">
                              <div
                                className="header_tags_wrraper"
                                onScroll={this.onTagScroll}
                              >
                                {jobInfo.tags && jobInfo.tags.length
                                  ? jobInfo.tags.map((tag, key) => {
                                      return (
                                        <div
                                          onClick={(e) =>
                                            this.handleSearchTag(e, tag)
                                          }
                                          className="tag"
                                          key={`${key}_tag`}
                                        >
                                          <span>{tag}</span>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                              <div
                                className="item-gradient start"
                                style={
                                  this.state.startGradation
                                    ? null
                                    : { backgroundImage: "none" }
                                }
                              ></div>
                              <div
                                className="item-gradient end"
                                style={
                                  this.state.endGradation
                                    ? null
                                    : { backgroundImage: "none" }
                                }
                              ></div>
                            </div>
                          </div>
                          <div className="job_info-container">
                            <div className="job_info-keytasks detail">
                              <span>{i18n.t("job.detail.keyTasks")}</span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: jobInfo.keyTasks.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            </div>
                            <div className="job_info-detail detail">
                              <span>{i18n.t("job.upload.details")}</span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: jobInfo.jobDetail.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            </div>
                            <div
                              className={
                                !otherJobs ||
                                !otherJobs.list ||
                                !otherJobs.list.length ||
                                !otherJobs.count
                                  ? "team-info datablank"
                                  : "team-info"
                              }
                              id="scrollEnd"
                            >
                              {jobInfo ? (
                                <>
                                  <div
                                    className="team-info-left"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/team/@${jobInfo.teamInfo.linkURL}`
                                      )
                                    }
                                  >
                                    <div className="team-photo">
                                      <ProfilePhoto
                                        src={jobInfo.teamInfo.teamPhoto}
                                        size={50}
                                      />
                                    </div>
                                    <div className="team-info-name">
                                      <span className="teamName nowrap">
                                        {jobInfo.teamInfo.name}
                                      </span>
                                      <div className="company-location">
                                        <img
                                          src={locationIcon}
                                          alt="locationIcon"
                                        />
                                        <span className="nowrap">
                                          {jobInfo.countryInfo.countryName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    className={
                                      jobInfo.teamInfo.isFollowing
                                        ? "following-md "
                                        : "follow-md"
                                    }
                                    onClick={this.props.handleFollow}
                                  >
                                    {jobInfo.teamInfo.isFollowing
                                      ? i18n.t("mypage.profile.unfollow")
                                      : i18n.t("project.follow")}
                                  </button>
                                </>
                              ) : (
                                this.renderSpin(12)
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        this.renderSpin(12)
                      )}
                    </div>
                    {jobInfo ? (
                      <div className="job_detail-right">
                        <div
                          className="job_detail-nav"
                          id="navigation"
                          //style={{ top: this.state.navTop }}
                        >
                          {this.props.supported
                            ? this.renderSupported()
                            : this.props.applyCancel
                            ? this.renderCancelConfirm()
                            : this.props.resume && this.props.resume !== -1
                            ? this.renderResume()
                            : this.props.err
                            ? this.renderMsg()
                            : this.renderJobInfo()}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="other_jobs-container"
                    style={
                      !otherJobs ||
                      !otherJobs.list ||
                      !otherJobs.list.length ||
                      !otherJobs.count
                        ? { display: "none" }
                        : null
                    }
                  >
                    {jobInfo ? (
                      <span className="other_jobs-title">
                        {i18n.language === "en"
                          ? i18n.t("jobInfo.otherjobs.title") +
                            jobInfo.teamInfo.name
                          : jobInfo.teamInfo.name +
                            i18n.t("jobInfo.otherjobs.title")}
                      </span>
                    ) : null}

                    {otherJobs &&
                    otherJobs !== -1 &&
                    otherJobs.list &&
                    otherJobs.list.length ? (
                      <>
                        <div
                          className={
                            otherJobs.list.length === 1
                              ? "other_jobs-list single"
                              : "other_jobs-list"
                          }
                        >
                          {otherJobs.list.map((item, key) => {
                            return (
                              <div
                                className="other_jobs"
                                key={`${key}_jobs`}
                                onClick={() =>
                                  this.props.handleJobDetailDialogOpen(
                                    item._id,
                                    item.teamInfo._id
                                  )
                                }
                              >
                                <div className="other_jobs-left nowrap">
                                  <span className="other_jobs-name">
                                    {item.teamInfo.name}
                                  </span>
                                  <span className="other_job-title">
                                    {item.position}
                                  </span>
                                  <div className="other_jobs-info">
                                    {item.payInfo.isPrivate ? null : (
                                      <span>
                                        {this.renderPayInfo(item.payInfo)}
                                      </span>
                                    )}

                                    <span>
                                      {i18n.language === "en"
                                        ? renderCareer(
                                            item.careerStatus,
                                            " and "
                                          )
                                        : renderCareer(item.careerStatus, ", ")}
                                    </span>
                                  </div>
                                  <div className="other_jobs-tags">
                                    {item.tags ? item.tags.join(" · ") : ""}
                                  </div>
                                  <div className="other_jobs-date">
                                    <img
                                      src={calendarIcon}
                                      alt="calendarIcon"
                                    ></img>
                                    <span>
                                      {item.periodInfo.regularBasis
                                        ? i18n.t("jobInfo.period.regularbasis")
                                        : this.renderDeadEnd(
                                            item.periodInfo.endDate
                                          )}
                                    </span>
                                    <span>
                                      {item.remoteAvailable
                                        ? i18n.t("jobInfo.otherjobs.remote")
                                        : i18n.t(
                                            "jobInfo.otherjobs.Residential"
                                          )}
                                    </span>
                                    <span>
                                      {i18n.t("jobInfo.otherjobs.updatedAt")}{" "}
                                      {this.renderPeriod(item.updatedAt)}
                                    </span>
                                  </div>
                                </div>
                                <div className="job_big-btns">
                                  <div
                                    className="job_big-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      JobDetail_GAEvent("bookmark");
                                      this.props.handleBookMark(
                                        item._id,
                                        item.teamInfo._id
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        item.isFavorite
                                          ? UnBookmarkIcon
                                          : BookmarkIcon
                                      }
                                      alt="bookmarkBtn"
                                    />
                                    {item.isFavorite ? (
                                      <span>{i18n.t("jobInfo.unmarkBtn")}</span>
                                    ) : (
                                      <span>
                                        {i18n.t("jobInfo.bookmarkBtn")}
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="job_big-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.props.handleApply(
                                        item._id,
                                        item.teamInfo._id
                                      );
                                    }}
                                  >
                                    {item.isApplied ? (
                                      <span>
                                        {i18n.t("jobInfo.cancel.btn")}
                                      </span>
                                    ) : (
                                      <span>{i18n.t("jobInfo.btn")}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {this.props.otherJobs.count <=
                        this.props.skip ? null : (
                          <div className="other_jobs-more">
                            <span>{i18n.t("jobInfo.otherjobs.more")}</span>
                            <img src={viewMoreBtn} alt="viewMoreBtn" />
                          </div>
                        )}
                      </>
                    ) : this.props.otherJobs === -1 ? (
                      this.renderSpin(10)
                    ) : (
                      <span>Empty</span>
                    )}
                  </div>
                  {jobInfo && this.state.fullScreen ? (
                    <div className="tablet_bottom">
                      <div className="tablet_bottom_btns">
                        <div>
                          <div
                            className="job_big-btn"
                            onClick={() => {
                              JobDetail_GAEvent("bookmark");
                              this.props.handleBookMark();
                            }}
                          >
                            <img
                              src={
                                this.props.isFavorite
                                  ? UnBookmarkIcon
                                  : BookmarkIcon
                              }
                              alt="bookmarkBtn"
                            />
                            <span>
                              {jobInfo.isFavorite
                                ? i18n.t("jobInfo.unmarkBtn")
                                : i18n.t("jobInfo.bookmarkBtn")}
                            </span>
                          </div>
                          <div
                            className="job_big-btn"
                            onClick={
                              jobInfo.isApplied
                                ? () => {
                                    this.props.handleApply();
                                    this.setState({ applyDialogOpen: true });
                                  }
                                : () => {
                                    this.props.getResume(
                                      jobInfo._id,
                                      jobInfo.teamInfo._id
                                    );
                                    this.setState({ applyDialogOpen: true });
                                  }
                            }
                          >
                            {this.props.resume === -1 ? (
                              this.renderSpin()
                            ) : (
                              <span>
                                {jobInfo.isApplied
                                  ? i18n.t("jobInfo.cancel.btn")
                                  : i18n.t("jobInfo.btn")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <ApplyDialog
                  fullScreen={this.state.fullScreen}
                  applyCancelBack={() => {
                    this.setState({ applyDialogOpen: false }, () =>
                      this.props.applyCancelBack()
                    );
                  }}
                  errBackClick={this.props.errBackClick}
                  handleCancelApply={() => {
                    this.props.handleCancelApply(jobInfo._id);
                    this.setState({ applyDialogOpen: false });
                  }}
                  history={this.props.history}
                  applyCancel={this.props.applyCancel}
                  errMsg={this.props.errMsg}
                  open={
                    this.props.resumeLoaded &&
                    this.state.fullScreen &&
                    this.props.resume
                      ? true
                      : this.state.applyDialogOpen
                      ? true
                      : false
                  }
                  resume={this.props.resume}
                  onClose={() => {
                    this.setState({ applyDialogOpen: false });
                  }}
                  handleRemovePortfolio={this.props.handleRemovePortfolio}
                  handleApply={() => {
                    this.props.handleApply();
                  }}
                  err={this.props.err}
                  supported={this.props.supported}
                  supportDone={() => {
                    this.setState(
                      { applyDialogOpen: false },
                      () => this.props.supportedDone
                    );
                  }}
                />
              </Dialog>
            </>
          )}
        </Media>
      </>
    );
  }
}
// calendarIcon
export default compose(withStyles(styles), withSnackbar)(JobDetail);
