import React, { Component } from "react";
import i18n from "../../../../i18n";
import "./AllProject.scss";
import FilterArea from "../FilterArea/FilterArea";
import SearchResultArea from "./SearchResult/SearchResult";
import TagsAndCateModify from "../AllProject/TagsAndCateModify/TagsAndCateModify";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Alert from "../../../../Component/OneButtonDialog/OneButtonDialog";
import ProjectDialog from "../../../../Component/ProjectDialog/ProjectDialog";
import { connect } from "react-redux";
import { langChange } from "../../../../Redux/action/index";
import { GetCategories } from "../../../../Api/Category/Category";
import { GetAllProjectList } from "../../../../Api/Admin/Project/project";
import { GetProjectInfo } from "../../../../Api/Project/project";
import { GetUserToken } from "../../../../modules/userAuth";
class AllProject extends Component {
  state = {
    isprojInfoDialogOpen: false,
    TagsDialogOpen: false,
    tagsDialogRender: false,
    searchString: "",
    searchFilter: "",
    orderType: null,
    isASC: null,
    list: [],
    projInfo: [],
    currentPage: 1,
    totalItems: null,
    isLoading: true,
    strDate: "",
    endDate: "",
    alertDialogOpen: false,
    alertTitle: "",
    alertMsg: "",
    render: false,
    categoriesEng: [],
    categoriesKor: [],
  };
  limit = 10;
  componentDidMount = async () => {
    this.getProjList();
    this.getCategoryList();
  };

  setCurrentPage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getProjList()
    );
  };

  getCategoryList = async () => {
    try {
      const response = await GetCategories();
      this.setState({
        categoriesEng: response.map((item) => ({
          value: item._id,
          label: item.name.en,
        })),
        categoriesKor: response.map((item) => ({
          value: item._id,
          label: item.name.ko,
        })),
      });
    } catch (e) {
      console.dir(e);
    }
  };

  handleAlertShow = (title, msg) => {
    this.setState({
      alertTitle: title,
      alertMsg: msg,
      alertDialogOpen: true,
      loaderDialogOpen: false,
    });
  };

  handlAlertClose = () => {
    this.setState({
      alertDialogOpen: false,
    });
  };

  getProjList = async () => {
    this.setState({
      isLoading: true,
    });
    const form = {};
    form.filter = this.state.searchFilter;
    form.searchString = this.state.searchString;
    form.limit = this.limit;
    form.skip = (this.state.currentPage - 1) * form.limit;
    console.log(form.skip);
    form.order = this.state.orderType;
    form.isAsc = this.state.isASC;
    form.sttDate = this.state.strDate;
    form.endDate = this.state.endDate;

    const token = GetUserToken();
    if (!token) {
      this.props.history.push("/admin");
      return;
    }
    //${process.env.REACT_APP_BASE_URL}
    try {
      const response = await GetAllProjectList(
        form.filter,
        form.searchString,
        form.limit,
        form.skip,
        form.order,
        form.isAsc,
        form.sttDate,
        form.endDate
      );

      this.setState({
        list: response.list,
        totalItems: response.total,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.dir(e);
    }
  };

  getProjInfo = async (id, type) => {
    try {
      const response = await GetProjectInfo(id);
      this.setState({ projInfo: response }, () => {
        if (type === "project") {
          this.setState({ dialogRender: true }, () =>
            this.setState({ projectDialogOpen: true })
          );
        } else if (type === "tags") {
          this.setState({ tagsDialogRender: true }, () =>
            this.setState({ TagsDialogOpen: true })
          );
        }
      });
    } catch (e) {
      console.dir("프로젝트 디테일 정보 에러 ", e);
    }
  };

  handleSearch = async () => {
    if (!this.state.searchFilter) {
      this.handleAlertShow(
        i18n.t("필수 값 확인"),
        i18n.t("검색 필터를 선택해 주세요.")
      );
      return;
    } else if (
      this.state.searchString.length === 0 &&
      (!this.state.strDate || !this.state.endDate)
    ) {
      this.handleAlertShow(
        i18n.t("필수 값 확인"),
        i18n.t("검색어 또는 날짜를 입력해 주세요.")
      );
      return;
    } else {
      await this.getProjList();
    }
  };

  handleSorter = async (orderType, isASC) => {
    this.setState({ orderType: orderType, isASC: isASC }, () =>
      this.getProjList()
    );
  };

  handleSearchStringUpdate = async (term) => {
    this.setState({
      searchString: term,
      strDate: "",
      endDate: "",
    });
  };

  handleSearchStringSelectionTypeUpdate = async (e) => {
    const value = e.value;
    this.setState({ searchString: value });
  };

  handleSearchFilterUpdate = async (e) => {
    const filter = e.value;

    this.setState({
      searchFilter: filter,
      searchString: "",
      strDate: "",
      endDate: "",
    });
  };

  handleSearchDates = async (strDate, endDate) => {
    this.setState({
      strDate: strDate,
      endDate: endDate,
      searchString: "",
    });
  };

  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };

  handleDeleteContents = async () => {
    await this.getProjList();
  };

  handlePageSizeChange = (current, size) => {
    this.setState({ selectedLimit: { value: size } });
  };

  openTagsAndCateDialog = (id) => {
    this.setState({ TagsDialogOpen: true });
  };

  closeTagsAndCateDialog = () => {
    this.setState({ TagsDialogOpen: false });
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <Alert
          onButtonClick={this.handlAlertClose}
          dialogHeadText={this.state.alertTitle}
          dialogBodyText1={this.state.alertMsg}
          open={this.state.alertDialogOpen}
          onClose={this.handlAlertClose}
        />

        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          <h2>{i18n.t("admin.projects.all.title")}</h2>
          <FilterArea
            searchFilter={this.state.searchFilter}
            searchStringUpdated={this.handleSearchStringUpdate}
            searchStringSelectionTypeUpdated={
              this.handleSearchStringSelectionTypeUpdate
            }
            searchFilterUpdated={this.handleSearchFilterUpdate}
            handleSearch={this.handleSearch}
            handleSearchDates={this.handleSearchDates}
            categoriesEng={this.state.categoriesEng}
            categoriesKor={this.state.categoriesKor}
            lang={this.props.lang}
          ></FilterArea>
          <SearchResultArea
            totalItems={this.state.totalItems}
            currentPage={this.state.currentPage}
            handlePageChange={this.setCurrentPage}
            handleSorter={this.handleSorter}
            list={this.state.list}
            limit={this.limit}
            getProjList={this.getProjList}
            getProjInfo={this.getProjInfo}
            categoriesEng={this.state.categoriesEng}
            categoriesKor={this.state.categoriesKor}
            openTagsAndCateDialog={this.openTagsAndCateDialog}
          ></SearchResultArea>

          {this.state.dialogRender ? (
            <ProjectDialog
              userId={this.props.userId}
              open={this.state.projectDialogOpen}
              onClose={this.handleProjectDialogClose}
              history={this.props.history}
              projInfo={this.state.projInfo}
              admin={true}
            ></ProjectDialog>
          ) : null}

          {this.state.tagsDialogRender ? (
            <TagsAndCateModify
              open={this.state.TagsDialogOpen}
              onClose={this.closeTagsAndCateDialog}
              projInfo={this.state.projInfo}
              categoriesEng={this.state.categoriesEng}
              categoriesKor={this.state.categoriesKor}
              getProjList={this.getProjList}
            ></TagsAndCateModify>
          ) : null}
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  langChange: (value) => dispatch(langChange(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllProject);
