import axios from "axios";
import { GetUserToken } from "../../modules/userAuth";

export const DeleteProject = async (projId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/delete?id=${projId}`;
  await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const ViewCountCheck = async (projId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/count`;
  await axios.patch(
    url,
    {
      id: projId,
    },
    token
      ? {
          headers: { Authorization: `Bearer ${token}` },
        }
      : null
  );
};
export const GetDraftList = async (userId, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/user-draft-project?userid=${userId}`;
  const response = await axios.get(url);
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  return response.data.data.list;
};
export const GetLikeProjList = async (userId, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/liked?id=${userId}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data.list;
};

export const PostAddProject = async (teamId, projId) => {
  let body = {
    id: teamId,
    projects: projId,
  };
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/add-projects`;
  await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const GetTeamProjectList = async (teamId, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/projects?id=${teamId}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};

export const GetMyProjectList = async (userId, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/user-project?userid=${userId}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data.list;
};

export const GetProjectInfo = async (projId) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/info?id=${projId}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};

export const GetProjectList = async (
  order,
  limit,
  categories,
  skip,
  searchStr,
  endDate,
  strDate
) => {
  const token = GetUserToken();
  console.log(searchStr);
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/list?order=${order}`;
  if (searchStr) {
    url = url + `&searchString=${searchStr}`;
  }
  if (categories) {
    url = url + `&categories[0]=${categories}`;
  }

  if (skip) {
    url = url + `&skip=${skip}`;
  }
  if (limit) {
    url = url + `&limit=${limit}`;
  }
  if (strDate && endDate) {
    url =
      url +
      `&strDate=${strDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data.list;
};

export const ProjectCreate = async (projectInfo) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/create`;
  const body = projectInfo;

  const response = await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const CreateDataFailed = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/upload-failed`;
  await axios.post(
    url,
    { id },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const CreateData = async (id, contents) => {
  const token = GetUserToken();

  for (let i = 0; i < contents.length; i++) {
    let url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/data-create`;
    const contentInfo = contents[i];
    delete contentInfo.item.data;
    delete contentInfo.item.file;
    let body = {
      id,
      type: contentInfo.type,
      item: contentInfo.item,
    };

    const dataInfo = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/data-create-upload`;
    body = new FormData();
    body.append("id", id);
    body.append("dataId", dataInfo.data.data._id);
    if (contentInfo.data) {
      body.append("data", contentInfo.data);
    }

    await axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
export const ThumbnailUpload = async (id, thumbnail) => {
  const token = GetUserToken();
  if (thumbnail) {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/thumbnail`;
    const body = new FormData();
    body.append("id", id);
    body.append("thumbnail", thumbnail);
    await axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
export const UploadSuccess = async (id, isModify) => {
  const token = GetUserToken();

  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/upload-success`;
  await axios.post(
    url,
    {
      id,
      isModify,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
