import React, { Component } from "react";
import "./CompleteDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CompleteIcon from "../../image/complete-icon.svg";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
class CompleteDialog extends Component {
  state = {};

  render() {
    const { onClose, open } = this.props;
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={""}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="complete-dialog-container">
              <div className="complete-dialog-title">
                <span>Complete</span>
              </div>
              <div className="complete-dialog-body">
                <img src={CompleteIcon} alt="CompleteIcon" />
                <span>Deleted successfully.</span>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}
export default withStyles(styles)(CompleteDialog);
