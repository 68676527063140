import React, { Component } from "react";
import "./Message.scss";
import { connect } from "react-redux";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
import ArrowIcon from "../../../image/enter-comments-icon.svg";
import AddIcon from "../../../image/ActionIcon/top_white_add_icon.svg";
import NoMsgIcon from "../../../image/no_message_black_icon.svg";

import { GetUserToken } from "../../../modules/userAuth";
import * as firebaseChat from "../../../firebase";
import i18n from "../../../i18n";
import Cookies from "universal-cookie";
import axios from "axios";
import { ClickAwayListener } from "@material-ui/core";
import queryString from "query-string";
import OffMsgIcon from "../../../image/top-message-icon.svg";
import OnMsgIcon from "../../../image/loginIcon/top_white_message_icon.svg";
import { timeBefore } from "../../../Component/functions";
import MobileSelector from "../../../Component/MobileSelector/MobileSeletor";
import imageSendIcon from "../../../image/Message/message-function-icon-1.svg";
import { withSnackbar } from "notistack";
import graySubmitIcon from "../../../image/Message/message_send_gray_icon.svg";
import whiteSubmitIcon from "../../../image/Message/message_send_black_icon.svg";
import checkIcon from "../../../image/black-check-icon.svg";
import groupIcon from "../../../image/Group.svg";
import LongPressable from "react-longpressable";
import UserDeleteIcon from "../../../image/Message/to-delete-icon.svg";
import ImgsViewer from "react-images-viewer";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { GetUserInfo } from "../../../Api/User/user";
class Message extends Component {
  state = {
    mode: 1,
    chats: [],
    userList: [],
    messages: [],
    isSearching: false,
    messageInput: "",
    selectedRoom: null,
    selectedUser: null,
    selectedChatRoom: null,
    unreadCount: 0,
    roomInfo: null,
    isJobChecked: false,
    selectorOpen: false,
    selectedImg: null,
    imgOpen: false,
    images: [],
    currImg: 0,
    isLoading: false,
    isFirstLoading: true,
  };
  selecteMenu = [
    {
      id: 1,
      text: i18n.t("chats.select.menu_1"),
    },
    {
      id: 2,
      text: i18n.t("global.close"),
    },
  ];
  scrollRef = null;
  socket = null;
  handleDeleteUser = () => {
    this.setState({ selectedUser: null });
  };
  newMsgAlert = () => {
    const input = document.getElementById("new-message-search");
    input.style.border = "1px solid red";
    this.handleAlert(i18n.t("chats.alert.newMsg"), {
      variant: "error",
      autoHideDuration: 2000,
    });
    setTimeout(() => {
      input.style.border = "1px solid #efefef";
      input.style.borderBottom = "none";
      input.style.borderTop = "none";
    }, 2000);
  };
  componentDidMount = () => {
    this.initializeChatEvent();
    // this.handleGetChatList();
    this.props.socket.on("read", (data) => {
      this.handleGetChatList();
    });
    window.addEventListener("popstate", (e) => this.handleClickBack(e));
  };
  initializeChatEvent = async () => {
    const cookies = new Cookies();
    const firebaseToken = cookies.get("firebaseToken");
    if (firebaseToken) {
      const chats = await this.handleGetChatList(0);
      const code = window.location.search;
      const parseCode = queryString.parse(code);
      firebaseChat.database
        .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
        .on("child_added", () => this.handleGetChatList(1));
      firebaseChat.database
        .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
        .on("child_changed", () => this.handleGetChatList(2));
      chats.map((item) => {
        if (parseCode && parseCode.id) {
          if (item.roomId === parseCode.id) {
            this.handleOpenChatRoom(item);
            this.props.history.replace("/chats");
          }
        }
        firebaseChat.database
          .ref(
            "UserRooms/" + firebaseChat.auth.currentUser.uid + "/" + item.roomId
          )
          .on("child_added", () => this.handleGetChatList(3));
        firebaseChat.database
          .ref(
            "UserRooms/" + firebaseChat.auth.currentUser.uid + "/" + item.roomId
          )
          .on("child_changed", () => this.handleGetChatList(4));
      });
    }
    this.setState({ isFirstLoading: false });
  };
  handleSelectedMenu = (id) => {
    if (id === 1) {
      this.handleQuit();
    } else if (id === 2) {
      this.handleSelectorClose();
    }
  };
  handleSelectedRoom = (item) => {
    this.setState({
      selectedRoom: item,
      selectorOpen: true,
    });
  };
  getMessageImages = () => {
    let images = [];
    this.state.messages
      .filter((item) => item.isImage)
      .map((item) => {
        images.push({ src: item.message });
      });

    this.setState({ images });
  };
  handleSelectorClose = () => {
    this.setState({
      selectedRoom: null,
      selectorOpen: false,
    });
  };
  handleQuit = () => {
    const chats = this.state.chats.filter(
      (item) => this.state.selectedRoom.roomId === item.roomId
    );
    for (let i = 0; i < chats.length; i++) {
      firebaseChat.handleQuit(chats[i].roomId);
    }
    this.handleSelectorClose();
    this.handleGetChatList();
  };
  isScrollPositionEnd = () => {
    if (this.scrollRef) {
      let scrollLocation =
        this.scrollRef.scrollTop + this.scrollRef.clientHeight;
      let fullHeight = this.scrollRef.scrollHeight;

      if (scrollLocation >= fullHeight) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  checkChatExists = (userIdList) => {
    userIdList = userIdList.sort();
    const chats = this.state.chats.find((item) => {
      item = item.users.map((data) => data._id).sort();
      if (JSON.stringify(item) === JSON.stringify(userIdList)) {
        return true;
      } else {
        return false;
      }
    });

    if (chats) {
      return chats.roomId;
    } else {
      return false;
    }
  };
  renderMessageTime = (date) => {
    const time = new Date(date);
    return `${
      time.getHours() < 10 ? "0" + time.getHours() : time.getHours()
    } : ${
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()
    } ${time.getHours() > 11 ? "PM" : "AM"}`;
  };
  sendMessage = (file) => {
    const messageInput = document.getElementById("message-input_bar");
    console.log(file);
    if (file) {
      firebaseChat.uploadFiles(file, this.state.selectedRoom, this.cbProgress);
      this.handleGetChatList();
    } else if (this.state.messageInput && this.state.messageInput.length) {
      firebaseChat.sendMessage(
        this.state.messageInput,
        this.state.selectedRoom,
        false
      );

      this.scrollRef.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
      if (messageInput) {
        messageInput.value = "";
      }
      this.setState({ messageInput: "" });
      this.handleGetChatList();
    }
  };
  newFileSend = (e) => {
    console.log(e.target.files);
    if (!this.state.selectedUser) {
      this.newMsgAlert();
    } else {
      this.handleOpenNewChatRoom(e.target.files);
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleOpenNewChatRoom = async (file) => {
    console.log(file);
    this.setState({ isLoading: true });
    const userInfo = this.state.selectedUser;
    const checkChatExist = this.checkChatExists([
      userInfo._id,
      firebaseChat.auth.currentUser.uid,
    ]);
    this.setState({ messages: [] }, () => {
      if (!checkChatExist) {
        const selectedRoom = firebaseChat.handleOpenNewRoom(
          userInfo,
          this.state.isJobChecked
        );
        firebaseChat.messageRef.on("child_added", (snapShot) => {
          this.handleGetMessage(snapShot, selectedRoom.roomId);
        });

        this.setState(
          {
            selectedRoom,
            isJobChecked: false,
            selectedUser: null,
            mode: 3,
            isLoading: false,
          },
          () => this.sendMessage(file)
        );
      } else {
        const selectedRoom = this.state.chats.find(
          (item) => item.roomId === checkChatExist
        );

        firebaseChat.getMessages(selectedRoom.roomId);

        this.setState(
          {
            selectedRoom: null,
            isJobChecked: false,
          },
          () => {
            this.getChatList();
            this.sendMessage(file);
            this.handleOpenChatRoom(selectedRoom, file);
          }
        );
      }
    });
  };
  scrollPositionCheck = () => {
    if (this.scrollRef) {
      let scrollLocation =
        this.scrollRef.scrollTop + this.scrollRef.clientHeight;
      let fullHeight = this.scrollRef.scrollHeight;

      if (scrollLocation >= fullHeight) {
        this.setState({ unreadCount: 0 });
      }
    } else {
      this.setState({ unreadCount: 0 });
    }
  };
  handleGetMessage = (snapShot, roomId) => {
    var val = snapShot.val();

    const messages = this.state.messages;
    messages.push({
      key: snapShot.key,
      uid: val.uid,
      profileImg: val.profileImg,
      isImage: val.isImage,
      userName: val.userName,
      timestamp: val.timestamp,
      message: val.message,
      isDateFirst: this.timeSeparator(new Date(val.timestamp)),
    });
    firebaseChat.handleRead(roomId);
    this.handleGetChatList();

    if (this.isScrollPositionEnd()) {
      this.setState({ messages }, () => {
        this.getMessageImages();

        if (this.scrollRef) {
          this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
        }
      });
    } else {
      this.setState(
        {
          messages,
        },
        () => {
          this.getMessageImages();
        }
      );
    }
  };
  handleClickBack = (e) => {
    if (this.state.mode === 1) {
      this.props.history.push("/");
      window.removeEventListener("popstate", this.handleClickBack);
    } else {
      this.setState({ mode: 1 });
    }
  };
  handleNext = () => {
    this.setState({ currImg: this.state.currImg + 1 });
  };
  handlePrev = () => {
    this.setState({ currImg: this.state.currImg - 1 });
  };
  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.handleClickBack);
  };
  handleGoToUserPage = async (id) => {
    try {
      const userInfo = await GetUserInfo(id);

      this.props.history.push(`/@${userInfo.linkURL}`);
    } catch (e) {
      console.log(e);
    }
  };
  handleGetChatList = async () => {
    const chats = await firebaseChat.handleGetRoomList();

    if (chats && chats.length) {
      let selectedRoom = null;
      if (this.state.selectedRoom) {
        selectedRoom = chats.find(
          (item) => item.roomId === this.state.selectedRoom.roomId
        );
      }

      this.setState({ chats, selectedRoom });
    } else {
      this.setState({ chats: [] });
    }

    return chats;
  };
  time = null;
  timeSeparator = (date) => {
    if (!this.time) {
      this.time = date.toISOString().substring(0, 10);

      return true;
    }
    if (this.time !== date.toISOString().substring(0, 10)) {
      let time = date.toISOString().substring(0, 10);
      this.time = time;

      return true;
    } else {
      return false;
    }
    // return date.toISOString().substring(0, 10);
  };
  onChangeFile = (e) => {
    console.log(e.target.files);
    firebaseChat.uploadFiles(
      e.target.files,
      this.state.selectedRoom,
      this.cbProgress
    );
  };
  cbProgress = (snapshot) => {
    return (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  };

  handleOpenChatRoom = async (selectedRoom) => {
    this.time = null;
    firebaseChat.handleOpenRoom(selectedRoom);
    firebaseChat.handleRead(selectedRoom.roomId);
    // this.setState({ messages: [] }, () => {
    // });
    firebaseChat.messageRef.on("child_added", (snapShot) =>
      this.handleGetMessage(snapShot, selectedRoom.roomId)
    );

    // const messageRef = await firebaseChat.messageRef
    //   .limitToLast(50)
    //   .once("value");

    // const messages = [];

    // messageRef.forEach((data) => {
    //   messages.push(data.val());
    // });

    this.setState(
      {
        selectedRoom,

        mode: 3,
        isLoading: false,
        selectedUser: null,
        isSearching: false,
      },
      () => {
        this.scrollRef.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    );
  };
  handleImgOpen = (src) => {
    const images = this.state.images;

    let index = images.findIndex((item) => item.src === src);

    this.setState({ selectedImg: src, currImg: index, imgOpen: true });
  };
  handleAddChatRoom = () => {
    this.setState({ mode: 2 }, () =>
      window.history.pushState(this.state.mode, "", null)
    );
  };
  handleSendMessage = (e) => {
    // const messageInput = document.getElementById("message-input_bar");
    // if (e.keyCode === 13 && !e.shiftKey) {
    //   e.preventDefault();
    //   this.sendMessage();
    //
    // } else {
    // }
    this.setState({ messageInput: e.target.value });
  };
  resize = (obj) => {
    obj.style.height = "1px";
    obj.style.height = 2 + obj.scrollHeight + "px";
  };
  renderMessageDate = (date) => {
    const time = new Date(date);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDay();
    return `${year}년 ${month < 10 ? "0" + month : month}월 ${
      day < 10 ? "0" + day : day
    }일`;
  };
  getChatList = () => {
    const cookies = new Cookies();
    const firebaseToken = cookies.get("firebaseToken");
    try {
      if (firebaseToken) {
        firebaseChat.auth
          .signInWithCustomToken(firebaseToken)
          .then(async (credential) => {
            const chats = await firebaseChat.handleGetRoomList();
            firebaseChat.database
              .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
              .on("child_added", this.handleGetChatList);
            firebaseChat.database
              .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
              .on("child_changed", this.handleGetChatList);
            chats.map((item) => {
              firebaseChat.database
                .ref(
                  "UserRooms/" +
                    firebaseChat.auth.currentUser.uid +
                    "/" +
                    item.roomId
                )
                .on("child_added", this.handleGetChatList);
              firebaseChat.database
                .ref(
                  "UserRooms/" +
                    firebaseChat.auth.currentUser.uid +
                    "/" +
                    item.roomId
                )
                .on("child_changed", this.handleGetChatList);
            });
          })
          .catch(async (e) => {
            console.dir(e);
            const token = cookies.get("token");
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/v1/auth/firebase-refresh`,
              null,
              { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.data && response.data.data.firebaseToken) {
              cookies.remove("firebaseToken");
              cookies.set("firebaseToken", response.data.data.firebaseToken);
              this.getChatList();
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  renderMessageTime = (date) => {
    const time = new Date(date);
    // console.log(time.toISOString());
    return `${
      time.getHours() < 10 ? "0" + time.getHours() : time.getHours()
    } : ${
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()
    } ${time.getHours() > 11 ? "PM" : "AM"}`;
  };
  isChatsJobOffer = (chats) => {
    for (let i = 0; i < chats.users.length; i++) {
      if (
        chats.users[i]._id === firebaseChat.auth.currentUser.uid &&
        chats.users[i].isJobOffer
      ) {
        // console.log(true);
        return true;
      }
    }
    return false;
  };
  // handleOnFocusInputBox = () => {};
  searchUsers = async (e) => {
    if (e.target.value && e.target.value.length) {
      const token = GetUserToken();
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/chat-list?searchString=${e.target.value}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      this.setState({ userList: response.data.data });
    }
  };
  handleNewMsgChange = (e) => {
    this.setState({ messageInput: e.target.value });
  };
  renderSearch = () => {
    if (this.state.isSearching) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ isSearching: false })}
        >
          <div className="search_container boxshadow">
            <div className="seach_box">
              {this.state.userList[0] && this.state.userList.length ? (
                this.state.userList.map((item, index) => {
                  return (
                    <div
                      className="search-column"
                      onClick={() => this.setState({ selectedUser: item })}
                      key={index}
                    >
                      <ProfilePhoto size={50} src={item.profilePhoto} />
                      <div className="seach-column-userInfo">
                        <span className="seach-column-userName">
                          {item.userName}
                        </span>

                        <span className="seach-column-job">
                          @{item.linkURL}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="search-empty">
                  <span>검색 결과가 없습니다. 😢</span>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  };
  renderSectionBody = () => {
    if (this.state.mode === 1) {
      if (
        !this.state.chats[0] &&
        !this.state.chats.length &&
        this.state.chats.length === 0 &&
        !this.state.isFirstLoading
      ) {
        return (
          <div className="emptyMsg-wrapper">
            <img src={NoMsgIcon} alt="empty_message" />
            <span>{i18n.t("chats.list.empty")}</span>
          </div>
        );
      } else {
        return (
          <div className="chats_list-wrapper">
            {this.state.chats.map((item, key) => {
              const user = item.users.find(
                (item) => item._id !== firebaseChat.auth.currentUser.uid
              );

              return (
                <LongPressable
                  onLongPress={() => this.handleSelectedRoom(item)}
                  onShortPress={() =>
                    this.setState({ messages: [] }, () =>
                      this.handleOpenChatRoom(item)
                    )
                  }
                  longPressTime={500}
                  key={`message-${item.roomId}`}
                >
                  <div
                    className="chats_room"
                    // onTouchEnd={() => this.handleOpenChatRoom(item)}
                  >
                    {!item.isReaded ? <div className="isUnread"></div> : null}
                    <ProfilePhoto src={user.profilePhoto} size={50} />
                    <div className="room-userInfo">
                      <span>{user ? user.userName : null}</span>
                      <span>
                        {item.lastMessage
                          ? item.isLastMessageImage
                            ? "Image file"
                            : item.lastMessage
                          : ""}
                      </span>
                    </div>
                    {/* {!item.isReaded ? <div className="isUnread"></div> : null} */}
                    <div className="room-info">
                      <div className="time">{timeBefore(item.time)}</div>
                      {item.isJob &&
                        (this.isChatsJobOffer(item) ? (
                          <div className="chats_list-job job-offer">
                            <img src={checkIcon} alt="" />
                            <span>{i18n.t("chats.job.collers")}</span>
                          </div>
                        ) : (
                          <div className="chats_list-job">
                            <img src={groupIcon} alt="" />
                            <span>{i18n.t("chats.job.recipients")}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </LongPressable>
              );
            })}
          </div>
        );
      }
    } else if (this.state.mode === 2) {
      return (
        <div className="create_chat-wrapper">
          <div className="search-user_bar" id="new-message-search">
            <span>{i18n.t("chat.receiver")}</span>
            {!this.state.selectedUser ? (
              <>
                <input
                  type="text"
                  onFocus={() => this.setState({ isSearching: true })}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isSearching: true });
                  }}
                  onChange={this.searchUsers}
                />
                {this.renderSearch()}
              </>
            ) : (
              <div className="selected_user-box">
                <span>{this.state.selectedUser.userName}</span>
                <img
                  src={UserDeleteIcon}
                  onClick={this.handleDeleteUser}
                  alt="removeIcon"
                />
              </div>
            )}
          </div>

          <div className="messageInput">
            <div className="message-functions">
              <div className="function-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.newFileSend}
                />
                <img src={imageSendIcon} alt="imageSendIcon" />
              </div>
              {/* <div className="function-btn">
                <img src={videoSendIcon} alt="videoSendIcon" />
              </div>
              <div className="function-btn">
                <img src={imojiSendIcon} alt="imojiSendIcon" />
              </div> */}
            </div>
            <div className="messageInput-input">
              <textarea
                onKeyDown={this.handleNewMsgChange}
                // placeholder="Write a comment now....."
              />
              <div
                className="message-submit"
                onClick={
                  this.state.messageInput && this.state.selectedUser
                    ? () => this.handleOpenNewChatRoom()
                    : null
                }
              >
                {/* <img src={imojiSendIcon} alt="imoji" /> */}
                <button
                  className={
                    this.state.messageInput && this.state.selectedUser
                      ? "activeSubmit"
                      : "disabledSubmit"
                  }
                >
                  <img
                    src={
                      this.state.messageInput && this.state.selectedUser
                        ? whiteSubmitIcon
                        : graySubmitIcon
                    }
                    alt="submitIcon"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="message-list-container">
          <div className="message-list">
            {this.state.messages.map((item, index) => {
              return (
                <>
                  {item.isDateFirst && (
                    <div className="date-separator">
                      <div>{this.renderMessageDate(item.timestamp)}</div>
                    </div>
                  )}
                  <div
                    className={
                      item.uid === firebaseChat.auth.currentUser.uid
                        ? "message-column myMessage"
                        : "message-column"
                    }
                    key={index}
                  >
                    <div
                      className="message-column-photo"
                      onClick={() => this.handleGoToUserPage(item.uid)}
                    >
                      <ProfilePhoto src={item.profileImg} size={55} />
                    </div>
                    <div className="messege-column-message">
                      <div
                        className="messege-column-info"
                        onClick={() => this.handleGoToUserPage(item.uid)}
                      >
                        <span>{item.userName}</span>
                      </div>
                      <div className="message-text">
                        {item.isImage ? (
                          <>
                            <img
                              onClick={() => this.handleImgOpen(item.message)}
                              className="messege-column-image"
                              src={item.message}
                              alt="messageImage"
                            />
                          </>
                        ) : (
                          <span
                            className="messege-column-text"
                            dangerouslySetInnerHTML={{
                              __html: item.message.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></span>
                        )}
                        <span className="timeBefore">
                          {this.renderMessageTime(item.timestamp)}
                        </span>
                        {/* {item.readCount > 0 ? ( */}
                        {/* <span className="messege-readcount">
												{item.readCount}
											</span> */}
                        {/* ) : null} */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="messageInput">
            {this.state.unreadCount > 0 ? (
              <div
                className="unread-notice"
                onClick={() => {
                  this.setState({ unreadCount: 0 }, () => {
                    if (this.scrollRef) {
                      this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
                    }
                  });
                }}
              >
                <span>
                  {this.state.unreadCount}개의 읽지않은 메세지가 있습니다.
                </span>
              </div>
            ) : null}
            <div className="message-functions">
              {/* <img src={imageSendIcon} alt="imageSendIcon" />
              <img src={videoSendIcon} alt="videoSendIcon" />
              <img src={imojiSendIcon} alt="imojiSendIcon" /> */}
              <div className="function-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onChangeFile}
                />
                <img src={imageSendIcon} alt="fileUpload" />
              </div>
            </div>
            <div className="messageInput-input">
              <textarea
                wrap="hard"
                cols="20"
                onChange={this.handleSendMessage}
                onFocus={(e) => {
                  setTimeout(() => {
                    if (this.scrollRef) {
                      this.scrollRef.scrollIntoView({
                        behavior: "auto",
                        block: "end",
                      }); // console.log(this.scrollRef.);
                    }
                  }, 200);
                }}
                id="message-input_bar"
                // placeholder="Write a comment now....."
              />
              <div
                className="message-submit"
                onClick={() => this.sendMessage()}
              >
                {/* <img src={imojiSendIcon} alt="imoji" /> */}
                <button
                  className={
                    this.state.messageInput ? "activeSubmit" : "disabledSubmit"
                  }
                >
                  <img
                    src={
                      this.state.messageInput ? whiteSubmitIcon : graySubmitIcon
                    }
                    alt="submitIcon"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
  );
  render() {
    return (
      <Spin
        indicator={this.antIcon}
        spinning={this.state.isLoading}
        delay={500}
      >
        <div className="message-root_container">
          {this.state.images[0] && this.state.images.length && (
            <ImgsViewer
              // srcSet={this.state.selectedImg}
              isOpen={this.state.imgOpen}
              currImg={this.state.currImg}
              onClickNext={this.handleNext}
              onClickPrev={this.handlePrev}
              imgs={this.state.images}
              backdropCloseable={true}
              onClose={() =>
                this.setState({
                  currImg: 0,
                  selectedImg: null,
                  imgOpen: false,
                })
              }
              imgCountSeparator="/"
            />
          )}
          <MobileSelector
            open={this.state.selectorOpen}
            onClose={this.handleSelectorClose}
            selecList={this.selecteMenu}
            handleSelec={this.handleSelectedMenu}
          />
          <header className="message-header_wrapper">
            <ul className={this.state.mode !== 1 && "sendMsg"}>
              <li>
                <img
                  onClick={this.handleClickBack}
                  style={{ transform: "rotate(180deg)" }}
                  src={ArrowIcon}
                  alt="back"
                />
                <span>{i18n.t("chat.title")}</span>
              </li>
              <li>
                {this.state.mode === 1 ? (
                  <img
                    onClick={this.handleAddChatRoom}
                    style={{ width: "20px", height: "20px" }}
                    src={AddIcon}
                    alt="message"
                  />
                ) : (
                  <img
                    src={
                      this.state.messageInput && this.state.selectedUser
                        ? OnMsgIcon
                        : OffMsgIcon
                    }
                    alt="messageSend"
                    onClick={
                      this.state.messageInput && this.state.selectedUser
                        ? () => this.handleOpenNewChatRoom()
                        : null
                    }
                  />
                )}
              </li>
            </ul>
          </header>
          <section className="message-inbox_wrapper">
            <div
              className="message-wrapper"
              ref={(ref) => {
                this.scrollRef = ref;
              }}
              onScroll={this.scrollPositionCheck}
            >
              {this.renderSectionBody()}
            </div>
          </section>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  socket: state.socketStatus.socket,
});

export default withSnackbar(connect(mapStateToProps, null)(Message));
