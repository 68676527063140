import React, { Component } from "react";
import "./DeleteDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import i18n from "../../../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import WarningIcon from "../../../image/DelteDialogIcon/warning-icon.svg";
import { DeleteTeam } from "../../../Api/Team/Team";
import { withSnackbar } from "notistack";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class DeleteDialog extends Component {
  state = {
    isLoading: false,
    isValid: false,
  };

  handleTeamDelete = async () => {
    try {
      const response = await DeleteTeam(this.props.id);

      if (response) {
        if (this.props.teamListLoad) {
          this.props.teamListLoad();
        } else {
          this.props.history.push("/");
        }
        this.props.onClose();
        this.props.enqueueSnackbar(i18n.t("팀이 삭제 되었습니다."), {
          variant: "success",
          key: "success-teamDeleted",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (e) {
      console.dir(e);
    }
  };

  teamDeleteValidate = (e) => {
    const inputValue = e.target.value;
    if (inputValue.toUpperCase() === this.props.teamName.toUpperCase()) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  render() {
    const { onClose, open } = this.props;
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    const teamName = this.props.teamName;

    return (
      <>
        <ThemeProvider theme={theme}>
          <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
            <Dialog
              fullWidth={this.props.fullWidth}
              className={""}
              maxWidth={false}
              open={open}
              onClose={onClose}
            >
              <div className="closeBtn" onClick={onClose}>
                <div className="closeBar1" />
                <div className="closeBar2" />
              </div>
              <div className="alert-button-dialog-container DeleteDialog1">
                {/* <div className="delete-dialog-haeder">
                  <span>{i18n.t("teamSetting.account.delete.header")}</span>
                </div> */}
                <div className="delete-dialog-body">
                  <img src={WarningIcon} alt="warning icon" />
                  <div className="delete-title">
                    <span>
                      {i18n.t("teamSetting.account.delete.title", { teamName })}
                    </span>
                  </div>

                  {i18n.language !== "ko" ? (
                    <div className="delete-explan">
                      <span>
                        All team members will lose access
                        <br />
                      </span>{" "}
                      Deleted teams cannot be recovered.
                    </div>
                  ) : (
                    <div className="delete-explan">
                      <span>모든 팀원의 접속 권한이 제한됩니다.</span>
                      <br />
                      삭제된 팀은 복구가 되지 않습니다
                    </div>
                  )}
                  <input
                    className="InputField-Delete"
                    onChange={this.teamDeleteValidate}
                  ></input>
                  <div className="delete-confirm-desc">
                    {i18n.t("teamSetting.account.delete.validate.msg")}
                  </div>
                </div>
                <div className="deleteBtns">
                  <button
                    className="dialogCloseBtn"
                    type="button"
                    onClick={onClose}
                  >
                    {i18n.t("global.no")}
                  </button>
                  <button
                    disabled={!this.state.isValid}
                    // className="deleteBtn"
                    className={this.state.isValid ? "deleteBtn" : "disabledBtn"}
                    type="button"
                    onClick={this.handleTeamDelete}
                  >
                    {i18n.t("global.yes")}
                  </button>
                </div>
              </div>
            </Dialog>
          </Spin>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(DeleteDialog);
