import React, { Component } from "react";
import "./ReportDialog.scss";

import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import RulesDialog from "../../SignUp/OperationRulesDialog/OperationRulesDialog";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class ReportDialog extends Component {
  state = {
    reportText: "",
    rulesDialogOpen: false,
  };
  handleReportInput = (e) => {
    let reportText = e.target.value.trim();
    this.setState({ reportText });
  };
  handleRulesDialogClose = () => {
    this.setState({ rulesDialogOpen: false });
  };

  render() {
    const {
      onClose,
      open,
      noButton,
      handleReportComment,
      dialogButtonText1,
      dialogButtonText2,
    } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <RulesDialog
          open={this.state.rulesDialogOpen}
          onClose={this.handleRulesDialogClose}
        />

        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"report"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="alert-button-dialog-container report_dialog-container">
              <div className="report-dialog-title">
                <span>{i18n.t("project.selector.report")}</span>
              </div>

              <div className="report-dialog-textarea">
                <textarea
                  placeholder={i18n.t("reportMsgPlaceholder")}
                  type="text"
                  onChange={this.handleReportInput}
                />
              </div>
              <div className="report-dialog-textLenght">
                {}
                <span>{this.state.reportText.length} / 800</span>
              </div>
              <div className="report-dialog-text">
                {i18n.language === "ko" ? (
                  <span>
                    상세한 신고 기준을 확인하기 위해서는{" "}
                    <span
                      onClick={() => {
                        this.setState({ rulesDialogOpen: true });
                      }}
                    >
                      커뮤니티 가이드라인
                    </span>
                    을 참고해주세요
                  </span>
                ) : (
                  <span>
                    Please review our{" "}
                    <span
                      onClick={() => {
                        this.setState({ rulesDialogOpen: true });
                      }}
                    >
                      Community Guidelines
                    </span>{" "}
                    for additional information about what is and isn't permitted
                    on MotionTrend.
                  </span>
                )}

                {/* <div>{dialogBodyText2 || null}</div> */}
              </div>
              <div>
                {noButton ? null : (
                  <div className="report-dialog-btns">
                    <button type="button" onClick={onClose}>
                      {dialogButtonText1 || i18n.t("global.cancel")}
                    </button>

                    <button
                      type="button"
                      onClick={() => handleReportComment(this.state.reportText)}
                    >
                      {dialogButtonText2 || i18n.t("project.selector.report")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(styles)(ReportDialog);
