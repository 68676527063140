import axios from "axios";

export const GetFavorite = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/favorite/list`
  );

  return response.data.data;
};

export const PostPWResetEmail = async (email, lang) => {
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/pw_reset-check`,
    {
      email,
      lang,
    }
  );
};
export const PostLinkExistsCheck = async (linkURL) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/exists-link?linkURL=${linkURL}`;
  const response = await axios.get(url);
  return response.data.data;
};
export const PostLogin = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`,
    {
      email,
      password,
    }
  );

  return response.data;
};
export const PostPWResetCodeCheck = async (code) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/pw_reset-done`,
    {
      code,
    }
  );
  return response.data;
};
export const PatchNewPassword = async (email, newPassword) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/pw_reset-complete`,
    {
      email,
      newPassword,
    }
  );
  return response.data;
};
export const EmailExistsCheck = async (email) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/exists-email`,
    {
      email,
    }
  );
  return response.data.data;
};
