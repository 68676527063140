import React, { Component } from "react";
import "./UploadLoaderDialog.scss";
import { css } from "@emotion/core";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import FadeLoader from "react-spinners/BeatLoader";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
const override = css`
  display: flex;
  justify-content: center;
`;
class UploadLoaderDialog extends Component {
  state = {
    loading: true,
  };
  render() {
    const {
      onClose,
      open,
      // noButton,
      // onButtonClick,
      dialogHeadText,
      dialogBodyText1,
      dialogBodyText2,
      // dialogButtonText,
    } = this.props;
    const color = "#070707";

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={""}
          maxWidth={"sm"}
          open={open}
          onClose={onClose}
          classes={{
            root: `classes-nesting-uploadLoader`, // class name, e.g. `classes-nesting-root-x`
          }}
        >
          <div style={{ padding: "30px" }}>
            <div className="closeBtn" onClick={onClose}>
              <div className="closeBar1" />
              <div className="closeBar2" />
            </div>
            <div className="one-button-dialog-container">
              <h1 className="one-button-dialog-h1">
                {dialogHeadText || i18n.t("global.alert")}
              </h1>
              <FadeLoader
                color={color}
                loading={this.state.loading}
                css={override}
                size={10}
              ></FadeLoader>
              <div
                className="one-button-dialog-text waitMsg"
                style={{
                  color: "#070707",
                  marginBottom: "unset",
                }}
              >
                <div>{dialogBodyText1 || null}</div>
                <div>{dialogBodyText2 || null}</div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(UploadLoaderDialog);
