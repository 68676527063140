import React, { Component } from "react";
import "./Team.scss";
import CreateTeamIcon from "../image/Team/create-team-icon.svg";
import JoinTeam from "../image/Team/join-team-icon.svg";
import hoverCreateIcon from "../image/Team/red-create-team-icon.svg";
import hoverJoinIcon from "../image/Team/red-join-team-icon.svg";
import i18n from "../i18n";
class Team extends Component {
  state = {
    create: false,
    join: false,
  };
  handleCreateMouseOver = () => {
    this.setState({ create: true });
  };
  handleCreateMouseOut = () => {
    this.setState({ create: false });
  };
  handleJoinMouseOver = () => {
    this.setState({ join: true });
  };
  handleJoinMouseOut = () => {
    this.setState({ join: false });
  };
  handleSelec = (menu) => {
    if (menu === "join") {
      this.props.history.push("/team/join");
    } else if (menu === "create") {
      this.props.history.push("/team/create");
    }
  };
  render() {
    return (
      <div className="container teamContainer">
        <div className="addTeam-selction">
          <div
            className="createTeamBox columnBox"
            onMouseOver={this.handleCreateMouseOver}
            onMouseOut={this.handleCreateMouseOut}
            onClick={() => this.handleSelec("create")}
          >
            <span className="createTeamBox-title">
              {i18n.t("team.create.main.title")}
            </span>
            <img
              src={!this.state.create ? CreateTeamIcon : hoverCreateIcon}
              alt=" Create a team"
            />
            <span className="createTeamBox-text">
              {i18n.t("team.create.main.text")}
            </span>
          </div>
          <div
            className="joinTeamBox columnBox"
            onMouseOver={this.handleJoinMouseOver}
            onMouseOut={this.handleJoinMouseOut}
            onClick={() => this.handleSelec("join")}
          >
            <span className="createTeamBox-title">
              {i18n.t("team.join.main.title")}
            </span>
            <img
              src={!this.state.join ? JoinTeam : hoverJoinIcon}
              alt=" Create a team"
            />
            <span className="createTeamBox-text">
              {i18n.t("team.join.main.text")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
