import React, { Component } from "react";
import "./Delete.scss";
import i18n from "../../i18n";
import DeleteDialog from "./DeleteDialog/DeleteDialog";

class Delete extends Component {
  state = {
    deleteDialogOpen: false,
    teamList: [],
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  render() {
    return (
      <div className="DeleteContainer">
        <DeleteDialog
          history={this.props.history}
          open={this.state.deleteDialogOpen}
          onClose={this.handleDeleteDialogClose}
        />
        <div className="DeleteMain">
          <div className="Title">{i18n.t("setting.account.delete.title")}</div>
          <div className="SubText">
            {i18n.t("setting.account.delete.description")}
          </div>
          <div
            className="Check"
            onClick={() => {
              this.setState({ deleteDialogOpen: true });
            }}
          >
            {i18n.t("setting.account.delete.confirm.btn")}
          </div>
        </div>
      </div>
    );
  }
}

export default Delete;
