import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const PostReport = async (id, text) => {
  const token = GetUserToken();
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/report/create`;
    let body = {
      id,
      text,
    };
    await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    return e.response;
  }
};
