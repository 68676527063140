import React, { Component } from "react";
import "./Curation.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CurationList from "./CurationList/CurationList";
import { GetCategories } from "../../../Api/Category/Category";
import {
  GetCurationtList,
  GetUnCurationtList,
} from "../../../Api/Admin/Curation/curaiton";
import { confirmAlert } from "react-confirm-alert";
import ProjectDialog from "../../../Component/ProjectDialog/ProjectDialog";
import { GetProjectInfo } from "../../../Api/Project/project";
import { PostDeleteProject } from "../../../Api/Admin/Project/project";
class Curation extends Component {
  state = {
    isLoading: false,
    totalItems: null,
    curationList: [],
    selectedMenu: 1,
    strDate: "",
    endDate: "",
    categoriesEng: [],
    categoriesKor: [],
    currentPage: 1,
    selectedLimit: { value: 10 },
    categories: [],
    order: "",
    isASC: "",
    projectDialogOpen: false,
    dialogRender: false,
    projInfo: {},
  };
  handleDeleteContents = async (id) => {
    this.setState({ isLoading: true });

    confirmAlert({
      title: "게시물 삭제 확인",
      message: `선택된 게시물을 정말로 삭제하시겠습니까 ? 삭제후에는 복원이 불가합니다.`,

      afterClose: () => {
        this.setState({ isLoading: false });
      },

      buttons: [
        {
          label: "삭제",
          onClick: async () => {
            try {
              const response = await PostDeleteProject(id);

              if (response.message === "success") {
                this.setState(
                  { isLoading: false },
                  async () => await this.getCurationtList() // 컨텐츠 정보 다시 가져옴
                );
                this.handleAlert(`게시물이 삭제 되었습니다.`);
              }
            } catch (e) {
              console.dir(e);
            }
          },
        },
        {
          label: "취소",
          onClick: () => {
            this.setState({ isLoading: false });
            return;
          },
        },
      ],
    });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  componentDidMount = () => {
    this.getCurationtList();
    this.getCategoryList();
  };
  getProjInfo = async (id) => {
    this.setState({ isLoading: true });
    try {
      const response = await GetProjectInfo(id);
      this.setState({ projInfo: response, isLoading: false }, () => {
        this.setState({ dialogRender: true, projectDialogOpen: true });
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };
  getCategoryList = async () => {
    try {
      const response = await GetCategories();
      this.setState({
        categoriesEng: response.map((item) => ({
          value: item._id,
          label: item.name.en,
        })),
        categoriesKor: response.map((item) => ({
          value: item._id,
          label: item.name.ko,
        })),
      });
    } catch (e) {
      console.dir(e);
    }
  };

  getCurationtList = async () => {
    this.setState({ isLoading: true });
    try {
      const categories = this.state.categories;
      const limit = parseInt(this.state.selectedLimit.value);
      const skip = (this.state.currentPage - 1) * limit;
      const order = this.state.order;
      const isASC = this.state.isASC;
      let strDate = null;
      let endDate = null;

      // 날짜 필터 적용
      if (this.state.strDate && this.state.endDate) {
        strDate = this.state.strDate ? this.state.strDate.toISOString() : "";
        endDate = this.state.endDate ? this.state.endDate.toISOString() : "";
      }
      if (this.state.selectedMenu > 1) {
        const response = await GetCurationtList(
          limit,
          skip,
          categories,
          strDate,
          endDate,
          order,
          isASC
        );

        this.setState({
          curationList: response.list,
          totalItems: response.count,
          isLoading: false,
        });
      } else {
        const response = await GetUnCurationtList(
          limit,
          skip,
          categories,
          strDate,
          endDate,
          order,
          isASC
        );

        this.setState({
          curationList: response.list,
          totalItems: response.count,
          isLoading: false,
        });
      }
    } catch (e) {
      console.dir(e);
      this.setState({
        isLoading: false,
        curationList: [],
        totalItems: "",
      });
    }
  };
  handleSelectedMenu = (menu) => {
    this.initData();
    this.setState({ selectedMenu: menu }, () => this.getCurationtList());
  };

  // 필터 및 정렬 세팅 함수
  handleCategoryChange = (categories) => {
    // set filter change

    if (categories) {
      this.setState({ categories });
    }

    // Reset filters
    else {
      this.setState({ categories: [] });
    }
  };

  setCurrentPage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      this.getCurationtList
    );
  };

  handleSorter = (sortType, isASC) => {
    this.setState({ order: sortType, isASC: isASC }, this.getCurationtList);
  };

  handleDatesChange = (strDate, endDate) => {
    this.initData();
    const DateStart = strDate ? strDate : "";
    const DateEnd = endDate ? endDate : "";

    this.setState(
      {
        strDate: DateStart,
        endDate: DateEnd,
      },
      this.getCurationtList
    );
  };

  initData = () => {
    this.setState({ currentPage: 1 });
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
        <div className="report-container">
          {this.state.dialogRender ? (
            <ProjectDialog
              userId={this.props.userId}
              open={this.state.projectDialogOpen}
              onClose={() => this.setState({ projectDialogOpen: false })}
              history={this.props.history}
              projInfo={this.state.projInfo}
              admin={true}
            ></ProjectDialog>
          ) : null}
          <CurationList
            handleDeleteContents={this.handleDeleteContents}
            getProjInfo={this.getProjInfo}
            totalItems={this.state.totalItems}
            currentPage={this.state.currentPage}
            handlePageChange={this.setCurrentPage}
            handleSorter={this.handleSorter}
            handleDatesChange={this.handleDatesChange}
            setPageSize={this.state.selectedLimit.value}
            handlePageSizeChange={this.handlePageSizeChange}
            list={this.state.curationList}
            handleSelectedMenu={this.handleSelectedMenu}
            categoriesEng={this.state.categoriesEng}
            categoriesKor={this.state.categoriesKor}
            handleCategoryChange={this.handleCategoryChange}
          />
        </div>
      </Spin>
    );
  }

  // searchUpdated(term) {
  //   this.setState({ searchTerm: term }, ()=> console.log(this.state.searchTerm));
  // }
}

export default Curation;
