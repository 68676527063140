import React, { Component } from "react";
import "./TeamList.scss";
import i18n from "../i18n";
import { connect } from "react-redux";
import { Space, Tabs, Badge, Popover } from "antd";

import DeleteConfirmDialog from "../Component/DelConfirmDialog/DelConfirmDialog";
import DeleteDialog from "../TeamSetting/Delete/DeleteDialog/DeleteDialog";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { Fragment } from "react";
import WarningIcon from "../image/DelteDialogIcon/warning-icon.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import queryString from "query-string";
import Media from "react-media";
import IconButton from "@material-ui/core/IconButton";
import MobileSelector from "../Component/MobileSelector/MobileSeletor";
import {
  DeleteTeam,
  GetMyTeamList,
  PostLeavingTeam,
  PostTeamAccept,
  PostTeamReject,
  TeamCancelApply,
  TeamInviteCheck,
} from "../Api/Team/Team";
import { BottomSheet } from "react-spring-bottom-sheet";
import ProfilePhoto from "../Component/ProfilePhoto/ProfilePhoto";
import { TeamList_GAEvent } from "../modules/gaEvents";

const { TabPane } = Tabs;
class TeamList extends Component {
  state = {
    teamList: [],
    isLoading: true,
    isDataLoading: true,
    teamDeleteDialogOpen: false,
    title: "",
    msg: "",
    func: "",
    isValid: false,
    teamLeavingDialogOpen: false,
    mobileSelectorOpen: false,
    selectedTeam: "",
    selectedTab: "1",
    isAddTeam: false,
    addTeamOptions: [
      {
        id: 1,
        show: true,
        text: i18n.t("팀 생성"),
      },
      {
        id: 2,
        show: true,
        text: i18n.t("기존 팀 참여"),
      },
    ],
  };
  componentDidMount = () => {
    TeamList_GAEvent("render");
    const query = window.location.search;
    const parseQuery = queryString.parse(query);
    if (parseQuery.tab) {
      this.setState({ selectedTab: parseQuery.tab }, () => this.teamListLoad());
    } else {
      this.props.history.push(`/team/list?tab=1`);
      this.teamListLoad();
    }

    this.checkInviteExist();
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevState.isAddTeam !== this.state.isAddTeam) {
  //     console.log("Updated Team :", this.state.isAddTeam);
  //   }
  // };

  handleMobileSelectorOpen = (teamInfo) => {
    if (this.state.isAddTeam === true) {
      this.setState({ mobileSelectorOpen: true });
    } else {
      this.setState({ selectedTeam: teamInfo, isAddTeam: false }, () =>
        this.setState({ mobileSelectorOpen: true })
      );
    }
  };

  handleMobileSelectorClose = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "unset";
    this.setState({ mobileSelectorOpen: false, isAddTeam: false });
  };

  teamListLoad = async () => {
    this.setState({ isDataLoading: true });
    try {
      const response = await GetMyTeamList(this.state.selectedTab);

      if (response) {
        this.setState({
          teamList: response,
          isLoading: false,
          isDataLoading: false,
        });
        this.checkInviteExist();
      }
    } catch (e) {
      console.dir(e);
    }
  };

  checkInviteExist = async () => {
    try {
      const response = await TeamInviteCheck();
      this.setState({
        isInviteExist: response,
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleLeavingDialogOpen = (teamInfo) => {
    const selectedTeb = this.state.selectedTab === "1" ? true : false;
    this.setState(
      { selectedTeam: teamInfo, teamLeavingDialogOpen: true },
      () => {
        this.setState({
          title: selectedTeb
            ? i18n.t(`${this.state.selectedTeam.name} 팀에서 나가시겠습니까 ?`)
            : i18n.t(
                `${this.state.selectedTeam.name} 가입신청을 취소하시겠습니까 ?`
              ),
          msg: selectedTeb
            ? i18n.t("한번 나가면 팀에 다시 접속할 수 없습니다.")
            : i18n.t("정말 가입신청을 취소하시겠습니까?."),
          func: selectedTeb ? this.handleLeavingTeam : this.handleCancelApply,
        });
      }
    );
  };
  handleDeleteConfirm = (teamId) => {
    this.setState({
      teamDeleteDialogOpen: true,
      selectedTeam: teamId,
    });
  };
  renderMemberAuth = (auth) => {
    if (i18n.language === "en") {
      if (auth === "member") {
        return "Team Member";
      } else if (auth === "admin") {
        return "Admin";
      } else if (auth === "owner") {
        return "Owner";
      }
    } else if (i18n.language === "ko") {
      if (auth === "member") {
        return "멤버";
      } else if (auth === "admin") {
        return "관리자";
      } else if (auth === "owner") {
        return "소유자";
      }
    }
  };

  handleCancelApply = async () => {
    this.setState({ isDataLoading: true, teamLeavingDialogOpen: false });
    try {
      await TeamCancelApply(this.state.selectedTeam._id);
      this.teamListLoad();
    } catch (e) {
      console.dir(e);
    }
  };

  handleInviteAccept = async (teamInfo) => {
    this.setState({ isDataLoading: true });
    try {
      await PostTeamAccept(teamInfo._id);
      this.teamListLoad();
    } catch (e) {
      console.dir(e);
    }
  };

  handleInviteReject = async (teamInfo) => {
    this.setState({ isDataLoading: true });

    try {
      await PostTeamReject(teamInfo._id);

      this.teamListLoad();
    } catch (e) {
      console.dir(e);
    }
  };

  handleTabChange = async (key) => {
    this.setState({ selectedTab: key, isDataLoading: true }, () =>
      this.teamListLoad()
    );
    this.props.history.push(`/team/list?tab=${key}`);
  };

  handleLeavingTeam = async () => {
    try {
      await PostLeavingTeam(this.state.selectedTeam._id);
      this.setState({ teamLeavingDialogOpen: false });
      this.teamListLoad();
    } catch (e) {
      console.dir(e);
    }
  };

  toTeamPage = async (linkURL) => {
    this.props.history.push(`/team/@${linkURL}`);
  };

  handleTabsTeamOptions = (teamInfo) => {
    const tab1 = [
      {
        id: 1,
        show: teamInfo.authority === "owner",
        text: i18n.t("teamList.setBtn"),
      },
      {
        id: 2,
        show: true,
        text: i18n.t("teamList.leaveBtn"),
      },
    ];

    const tab2 = [
      {
        id: 1,
        show: true,
        text: i18n.t("참여 취소"),
      },
    ];

    const tab3 = [
      {
        id: 1,
        show: true,
        text: i18n.t("승인"),
      },
      {
        id: 2,
        show: true,
        text: i18n.t("거절"),
      },
    ];

    if (this.state.selectedTab === "1") {
      return tab1;
    } else if (this.state.selectedTab === "2") {
      return tab2;
    } else if (this.state.selectedTab === "3") {
      return tab3;
    }
  };

  handleTabsOptionsClickHandler = (teamInfo, optionId) => {
    if (this.state.selectedTab === "1") {
      switch (optionId) {
        case 1: // 설정으로 이동
          // this.handleMobileSelectorClose();
          this.props.history.push({
            pathname: `/team/setting`,
            state: { teamId: teamInfo._id },
          });
          break;
        case 2: // 나가기
          this.handleMobileSelectorClose();
          if (teamInfo.authority !== "owner") {
            this.handleLeavingDialogOpen(teamInfo);
          } else {
            this.handleDeleteConfirm(teamInfo);
          }
          break;
        default:
      }
    } else if (this.state.selectedTab === "2") {
      switch (optionId) {
        case 1: // 침여 신청 취소
          this.handleMobileSelectorClose();
          this.handleLeavingDialogOpen(teamInfo);
          break;
        default:
      }
    } else if (this.state.selectedTab === "3") {
      switch (optionId) {
        case 1: // 팀 참여 수락
          this.handleMobileSelectorClose();
          this.handleInviteAccept(teamInfo);
          break;
        case 2: // 팀 참여 거절
          this.handleMobileSelectorClose();
          this.handleInviteReject(teamInfo);
          break;
        default:
      }
    }
  };

  handleAddTeamBtn = () => {
    this.setState({ isAddTeam: true }, () => this.handleMobileSelectorOpen());
  };

  handleAddTeamsOptionsClickHandler = (optionId) => {
    switch (optionId) {
      case 1: // 팀 생성 으로 이동
        this.setState({ isAddTeam: false }, () =>
          this.props.history.push("/team/create")
        );
        // this.handleMobileSelectorClose();

        break;
      case 2: // 기존 팀 참여
        // this.handleMobileSelectorClose();
        this.setState({ isAddTeam: false }, () =>
          this.props.history.push("/team/join")
        );
        break;
      default:
    }
  };
  teamDeleteValidate = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.toUpperCase() === this.state.selectedTeam.name.toUpperCase()
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  handleTeamDelete = async () => {
    try {
      const response = await DeleteTeam(this.state.selectedTeam._id);

      if (response) {
        this.teamListLoad();

        this.setState({ teamDeleteDialogOpen: false });
        this.props.enqueueSnackbar(i18n.t("팀이 삭제 되었습니다."), {
          variant: "success",
          key: "success-teamDeleted",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (e) {
      console.dir(e);
    }
  };
  render() {
    // const teamCount = this.state.teamList.length;
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
    `;
    const teamName = this.state.selectedTeam.name;
    return !this.state.isLoading ? (
      <div className="user-team-list-container">
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            mobileTablet: "(max-width: 1024px)",
            tablet: "(min-width: 800px) and (max-width: 1024px)",
            desktop: "(min-width: 1025px)",
            tabletDesktop: "(min-width: 800px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.mobileTablet ? (
                <>
                  <BottomSheet //팀 삭제
                    onDismiss={() =>
                      this.setState({
                        teamDeleteDialogOpen: false,
                        selectedTeam: "",
                      })
                    }
                    open={this.state.teamDeleteDialogOpen}
                  >
                    <div className="alert-button-dialog-container DeleteDialog1">
                      {/* <div className="delete-dialog-haeder">
                  <span>{i18n.t("teamSetting.account.delete.header")}</span>
                </div> */}
                      <div className="delete-dialog-body">
                        <img src={WarningIcon} alt="warning icon" />
                        <div className="delete-title">
                          <span>
                            {i18n.t("teamSetting.account.delete.title", {
                              teamName,
                            })}
                          </span>
                        </div>

                        {i18n.language !== "ko" ? (
                          <div className="delete-explan">
                            <span>
                              All team members will lose access
                              <br />
                            </span>{" "}
                            Deleted teams cannot be recovered.
                          </div>
                        ) : (
                          <div className="delete-explan">
                            <span>모든 팀원의 접속 권한이 제한됩니다.</span>
                            <br />
                            삭제된 팀은 복구가 되지 않습니다
                          </div>
                        )}
                        <input
                          className="InputField-Delete"
                          onChange={this.teamDeleteValidate}
                        ></input>
                        <div className="delete-confirm-desc">
                          {i18n.t("teamSetting.account.delete.validate.msg")}
                        </div>
                      </div>
                      <div className="deleteBtns">
                        <button
                          className="dialogCloseBtn"
                          type="button"
                          onClick={() =>
                            this.setState({ teamDeleteDialogOpen: false })
                          }
                        >
                          {i18n.t("global.no")}
                        </button>
                        <button
                          disabled={!this.state.isValid}
                          // className="deleteBtn"
                          className={
                            this.state.isValid ? "deleteBtn" : "disabledBtn"
                          }
                          type="button"
                          onClick={this.handleTeamDelete}
                        >
                          {i18n.t("global.yes")}
                        </button>
                      </div>
                    </div>
                  </BottomSheet>
                  <BottomSheet
                    open={this.state.teamLeavingDialogOpen}
                    onDismiss={() =>
                      this.setState({
                        teamLeavingDialogOpen: false,
                        selectedTeam: "",
                      })
                    }
                  >
                    <div className="comment_delete-dialog">
                      <div className="comment_delete-dialog-container">
                        <div className="comment_delete-dialog-title">
                          <span>{this.state.title}</span>
                        </div>
                        <div className="comment_delete-dialog-body">
                          <img src={WarningIcon} alt="warning icon" />
                          <span>{this.state.msg}</span>
                        </div>

                        <div className="comment_delete-dialog-btns">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ teamLeavingDialogOpen: false })
                            }
                          >
                            {i18n.t("global.cancel")}
                          </button>

                          <button type="button" onClick={this.state.func}>
                            {i18n.t("global.check")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </BottomSheet>
                </>
              ) : (
                <>
                  <DeleteConfirmDialog
                    dialogHeader={this.state.title}
                    dialogText={this.state.msg}
                    open={this.state.teamLeavingDialogOpen}
                    onClose={() =>
                      this.setState({ teamLeavingDialogOpen: false })
                    }
                    buttonClick={this.state.func}
                  />

                  <DeleteDialog
                    history={this.props.history}
                    open={this.state.teamDeleteDialogOpen}
                    onClose={() =>
                      this.setState({ teamDeleteDialogOpen: false })
                    }
                    teamName={this.state.selectedTeam.name}
                    id={this.state.selectedTeam._id}
                    teamListLoad={this.teamListLoad}
                  />
                </>
              )}

              <div className="user-team-list-box">
                <div className="user-team-list-title-box">
                  <div className="user-team-list-title">
                    {/* <span>{i18n.t("teamList.title", { teamCount })}</span> */}
                  </div>
                  {matches.tabletDesktop && (
                    <div className="team-list-box-btn">
                      <Popover
                        placement="bottomRight"
                        className="team-list-popover"
                        // title={"팀 추가"}
                        content={
                          <Space direction="vertical">
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                this.props.history.push("/team/create")
                              }
                            >
                              <span>팀 생성</span>
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.props.history.push("/team/join")
                              }
                            >
                              <span>기존 팀 참여</span>
                            </div>
                          </Space>
                        }
                        trigger="hover"
                      >
                        <button
                          type="button"
                          // onClick={() => this.props.history.push("/team")}
                        >
                          {i18n.t("teamList.newTeamBtn")}
                        </button>
                      </Popover>
                    </div>
                  )}
                  {matches.mobile && (
                    <div className="team-list-box-btn">
                      <button type="button" onClick={this.handleAddTeamBtn}>
                        {i18n.t("teamList.newTeamBtn")}
                      </button>
                    </div>
                  )}
                </div>
                <Tabs
                  defaultActiveKey={this.state.selectedTab}
                  onChange={this.handleTabChange}
                  className="user-team-tabs"
                >
                  <TabPane tab={<Badge>{i18n.t("참여 중")}</Badge>} key="1" />
                  <TabPane
                    tab={<Badge dot={false}>{i18n.t("신청 대기")}</Badge>}
                    key="2"
                  />
                  <TabPane
                    tab={
                      <Badge dot={this.state.isInviteExist}>
                        {i18n.t("초대")}
                      </Badge>
                    }
                    key="3"
                  />
                </Tabs>

                {matches.mobile ? (
                  <MobileSelector
                    open={this.state.mobileSelectorOpen}
                    onClose={this.handleMobileSelectorClose}
                    selecList={
                      this.state.isAddTeam
                        ? this.state.addTeamOptions
                        : this.handleTabsTeamOptions(this.state.selectedTeam)
                    }
                    teamInfo={this.state.selectedTeam}
                    handleSelec={
                      this.state.isAddTeam
                        ? this.handleAddTeamsOptionsClickHandler
                        : this.handleTabsOptionsClickHandler
                    }
                  ></MobileSelector>
                ) : null}
                <div className="team-body-table">
                  {!this.state.isDataLoading &&
                  this.state.teamList.length !== 0 ? (
                    this.state.teamList.map((teamInfo) => {
                      return (
                        <div className={"team-body-columns"}>
                          <div className="body-column">
                            <div
                              className="body-teamInfo nowrap"
                              onClick={() => this.toTeamPage(teamInfo.linkURL)}
                            >
                              <ProfilePhoto
                                size={40}
                                src={teamInfo.teamPhoto}
                              />
                              <span className="teamName">{teamInfo.name}</span>
                              <span className="authority">
                                {this.renderMemberAuth(teamInfo.authority)}
                                {/* {teamInfo.authority} */}
                              </span>
                            </div>
                          </div>
                          {/* <div className="body-column">
                        <span className="column-members">
                          {teamInfo.members}
                        </span>
                      </div> */}

                          <div className="body-column">
                            {matches.tabletDesktop && (
                              <div className="body-teamBtns">
                                {this.handleTabsTeamOptions(teamInfo).map(
                                  (item, index) => {
                                    return (
                                      <>
                                        {item.show ? (
                                          <button // 팀 설정 버튼
                                            type="button"
                                            onClick={() =>
                                              this.handleTabsOptionsClickHandler(
                                                teamInfo,
                                                item.id
                                              )
                                            }
                                          >
                                            {item.text}
                                          </button>
                                        ) : null}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            )}
                            {matches.mobile && (
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={() =>
                                  this.handleMobileSelectorOpen(teamInfo)
                                }
                              >
                                <MoreVertIcon style={{ fontSize: "1.9rem" }} />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={"team-body-columns"}>
                      {this.state.isDataLoading ? (
                        <FadeLoader
                          //   color={color}
                          loading={this.props.FollowLoading}
                          css={override}
                          size={6}
                        ></FadeLoader>
                      ) : (
                        <>
                          {this.state.selectedTab === "1" ? (
                            <div className="body-column">
                              참여중인 팀이 없습니다. 🔍
                              <br />
                              팀을 만들거나 기존 팀에 참여하여 팀 프로젝트를
                              진행해 보세요.
                            </div>
                          ) : this.state.selectedTab === "2" ? (
                            <div className="body-column">
                              참여 신청한 팀이 없습니다.
                              <br />
                              기존팀에 참여 신청을 해보세요
                            </div>
                          ) : this.state.selectedTab === "3" ? (
                            <div className="body-column">
                              받은 초대가 없습니다.
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Media>
      </div>
    ) : null;
  }
}
const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  myInfo: state.myInfo.myInfo,
});
export default connect(mapStateToProps)(TeamList);
