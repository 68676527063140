import React, { Component } from "react";
import "./JobInfo.scss";
import { compose } from "redux";
import CountrySelect from "../../../../SignUp/UserInfo/CountrySelect";
import { RegionDropdown } from "react-country-region-selector";
// import RedArrow from "../../../../image/line-red-arrow-icon.svg";
import moment from "moment";
import AlertDialog from "../../../../Component/OneButtonDialog/OneButtonDialog";
import { ClickAwayListener } from "@material-ui/core";
import i18n from "../../../../i18n";
import TagDelIcon from "../../../../image/ProjectUpload/xbox-icon.svg";
import { DatePicker } from "antd";
import OccupationField from "../../../../Component/OccupationField/OccupationField";
import { GetCategories } from "../../../../Api/Category/Category";
import CropDialog from "../../../../Component/CropDialog/CropDialog";
import red_xboxIcon from "../../../../image/JobUpload/red-xbox-icon.svg";
import { withSnackbar } from "notistack";
import { getCroppedImg } from "../../../../modules/imageCrop";
class JobInfo extends Component {
  state = {
    position: "",
    tags: [],
    msg: "",
    cropDialogOpen: false,
    cropSrc: "",
    croppedAreaPixels: "",
    msgTitle: "",
    alertDialogOpen: false,
    payMsg: "",
    payType: "KRW",
    careerList: [
      {
        id: 1,
        text: i18n.t("job.upload.workType_1"),
      },
      {
        id: 2,
        text: i18n.t("job.upload.workType_2"),
      },
      {
        id: 3,
        text: i18n.t("job.upload.workType_3"),
      },
    ],
    crop: {
      x: 0,
      y: 0,
    },
    workTimeList: [
      {
        id: 1,
        text: i18n.t("job.upload.workTime_1"),
      },
      {
        id: 2,
        text: i18n.t("job.upload.workTime_2"),
      },
      {
        id: 3,
        text: i18n.t("job.upload.workTime_3"),
      },
    ],
    fileName: "",
    fileType: "",
    selectedFile: "",
    selectedWorkTime: [],
    mainTask: "",
    detailTask: `${i18n.t("job.upload.work.preset_1")}\n-\n-\n-\n\n${i18n.t(
      "job.upload.work.preset_2"
    )}\n-\n-\n-\n\n${i18n.t("job.upload.work.preset_3")}\n-\n-\n-\n\n`,
    selectedCareer: [],
    payVisible: false,
    minSalary: "",
    maxSalary: "",
    countryName: "",
    countryCode: "",
    region: "",
    categoriesOpen: false,
    categories: [],
    selectedCategory: "",
    previewVisible: "",
    previewImage: "",
    fileList: [],
    previewTitle: "",
    endDateOpen: false,
    strDateOpen: false,
    croppedImageUrl: "",
    period: {
      regularBasis: false,
      strDate: moment(new Date(), "YYYY-MM-DD"),
      endDate: "",
    },
    remoteWork: false,
    calendarOpen: false,
  };
  today = moment(new Date(), "YYYY-MM-DD");
  strDateAuto = false;
  endDateAuto = false;
  ratio = 4 / 3;
  requierTimeOut = 3000;
  returnVal = true; //필수값이 전부 들어있는지 확인
  getCategoryList = async () => {
    try {
      const response = await GetCategories();
      this.setState({ categories: response });
    } catch (e) {
      console.dir(e);
    }
  };
  isCareerCheck = (career) => {
    if (career === "intern") {
      return {
        id: 1,
        text: i18n.t("job.upload.workType_1"),
      };
    } else if (career === "newComer") {
      return {
        id: 2,
        text: i18n.t("job.upload.workType_2"),
      };
    } else if (career === "experienced") {
      return {
        id: 3,
        text: i18n.t("job.upload.workType_3"),
      };
    }
  };
  isWorkingCheck = (work) => {
    if (work === "fullTime") {
      return {
        id: 1,
        text: i18n.t("job.upload.workTime_1"),
      };
    } else if (work === "partTime") {
      return {
        id: 2,
        text: i18n.t("job.upload.workTime_2"),
      };
    } else if (work === "freelancer") {
      return {
        id: 3,
        text: i18n.t("job.upload.workTime_3"),
      };
    }
  };
  handleRegionChange = (val) => {
    if (val !== "") {
      this.setState({ region: val });
    } else {
      this.setState({ region: null });
    }
  };
  handlePositionChange = (e) => {
    const maxLength = 100;
    if (e.target.value.length <= maxLength) {
      this.setState({ position: e.target.value });
    }
  };
  onImageLoaded = (image) => {
    this.imgRef = image;
  };
  handleAlertDialogClose = () => {
    this.setState({ alertDialogOpen: false });
  };
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };
  showCroppedImage = async (img) => {
    let fileList = this.state.fileList;
    let data = {
      fileObj: "",
      preview: "",
    };
    try {
      const croppedImage = await getCroppedImg(
        img,
        this.state.croppedAreaPixels,
        0,
        this.state.fileName,
        this.state.fileType
      );

      const file = new File([croppedImage], this.state.fileName, {
        type: this.state.fileType,
      });
      const url = URL.createObjectURL(croppedImage);

      data.fileObj = file;
      data.preview = url;
      fileList.push(data);
      this.setState({
        fileList,
        fileName: "",
        fileType: "",
        cropDialogOpen: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  onCropChange = (pos) => {
    this.setState({ crop: pos });
  };

  handleRemoveFile = (index) => {
    let fileList = this.state.fileList;
    fileList.splice(index, 1);
    this.setState({ fileList });
  };
  handleFileOnChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let img = new Image();
    let file = e.target.files[0];
    const inputTag = document.getElementById("job_img-upload");
    const height = 800;
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          if (img.width >= height) {
            this.setState(
              {
                cropSrc: reader.result,
                cropDialogOpen: true,
                fileName: file.name,
                fileType: file.type,
              },
              () => (inputTag.value = "")
            );
          } else {
            inputTag.value = "";
            this.props.enqueueSnackbar(
              i18n.t("img.uploadAlert.text", { height }),
              {
                variant: "warning",
                key: "upload_format",
                autoHideDuration: 5000,
                preventDuplicate: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }
            );
          }
        };
      };
    }
  };
  handleMainWorkChange = (e) => {
    // const maxLenght = 600;
    let mainTask = e.target.value;
    // if (mainTask.length <= maxLenght) {
    this.setState({ mainTask });
    // }
  };
  handleDetailWorkChange = (e) => {
    // const maxLenght = 5000;
    let detailTask = e.target.value;

    // if (detailTask.length <= maxLenght) {
    this.setState({ detailTask });
    // }
  };
  handlePayVisibleChange = (e) => {
    this.setState({ payVisible: e.target.checked });
  };
  handleRemoteWorkChange = (e) => {
    this.setState({ remoteWork: e.target.checked });
  };
  handleDeleteTag = (index) => {
    let tags = this.state.tags;
    tags.splice(index, 1);
    this.setState({
      tags,
    });
  };
  handleMinSalaryChange = (e) => {
    let minSalary = e.target.value;

    // var regexp = /\B(?=(\d{3})+(?!\d))/g;
    if (isFinite(minSalary)) {
      //pay = pay.toString().replace(regexp, ",");
      this.setState({ minSalary });
    }
  };
  handleMaxSalaryChange = (e) => {
    let maxSalary = e.target.value;
    // var regexp = /\B(?=(\d{3})+(?!\d))/g;

    if (isFinite(maxSalary)) {
      //pay = pay.toString().replace(regexp, ",");
      this.setState({ maxSalary });
    }
  };
  componentDidMount = async () => {
    // this.requiredDataCheck();
    //this.today.setDate(this.today.getDate() - 1);
    let endDate = new Date(this.state.period.strDate);
    if (!this.state.period.endDate) {
      endDate.setMonth(endDate.getMonth() + 1);
      endDate = moment(endDate, "YYYY-mm-dd");
      this.setState({ period: { ...this.state.period, endDate } });
    }
    await this.getCategoryList();
    if (this.props.mode === "Edit") {
      this.infoSet();
    }
  };
  infoSet = () => {
    const info = this.props.info();
    this.setState({
      period: {
        ...this.state.period,
        strDate: !info.periodInfo.regularBasis
          ? moment(new Date(info.periodInfo.strDate), "YYYY-MM-DD")
          : "",
        endDate: !info.periodInfo.regularBasis
          ? moment(new Date(info.periodInfo.endDate), "YYYY-MM-DD")
          : "",
        regularBasis: info.periodInfo.regularBasis,
      },
      payType: info.payInfo.currency,
      minSalary: info.payInfo.minimum,
      maxSalary: info.payInfo.maximum,
      payVisible: info.payInfo.isPrivate,
      countryCode: info.countryInfo.countryCode,
      countryName: info.countryInfo.countryName,
      region: info.countryInfo.region,
      workingDay: info.workingDate,
      workingTime: info.workingHours,
      mainTask: info.mainTask,
      detailTask: info.detailTask,
      selectedCareer: info.selectedCareer.map((item) => {
        return this.isCareerCheck(item);
      }),
      fileList: info.fileList,
      remoteWork: info.remoteWork,
      position: info.position,
      tags: info.tags,
      selectedWorkTime: info.selectedWorkTime.map((item) => {
        return this.isWorkingCheck(item);
      }),
      selectedCategory: info.selectedCategory,
    });
  };
  requiredDataCheck = () => {
    if (!this.state.position) {
      const input = document.getElementById("jobUpload_position");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.selectedCategory) {
      const input = document.getElementById("jobUpload_category");
      input.style.border = "1px solid red";

      setTimeout(() => {
        input.style.border = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.mainTask) {
      const input = document.getElementById("jobUpload_mainTask");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.detailTask) {
      const input = document.getElementById("jobUpload_detailTask");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.selectedCareer.length) {
      const input = document.getElementById("jobUpload_career");
      input.style.color = "red";
      setTimeout(() => {
        input.style.color = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.selectedWorkTime.length) {
      const input = document.getElementById("jobUpload_workTimeList");
      input.style.color = "red";
      setTimeout(() => {
        input.style.color = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.minSalary || !this.state.maxSalary) {
      const input = document.getElementById("jobUpload_minSalary");
      const maxInput = document.getElementById("jobUpload_maxSalary");
      input.style.borderColor = "red";
      maxInput.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
        maxInput.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (
      !this.state.period.regularBasis &&
      (this.state.period.strDate || this.state.period.endDate)
    ) {
      const input = document.getElementById("jobUpload_period");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.countryCode) {
      const input = document.getElementById("jobUpload_country");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (this.state.countryCode && !this.state.region) {
      const input = document.getElementById("jobUpload_region");
      input.style.borderColor = "red";
      setTimeout(() => {
        input.style.borderColor = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    if (!this.state.fileList[0] && !this.state.fileList.length) {
      const input = document.getElementById("jobUpload_image");
      input.style.color = "red";
      setTimeout(() => {
        input.style.color = "";
      }, this.requierTimeOut);
      input.focus();
      this.returnVal = true;
    } else {
      this.returnVal = false;
    }
    console.log(this.returnVal);
    return this.returnVal;
  };

  handleStrDateChange = (strDate) => {
    this.setState({ period: { ...this.state.period, strDate } });
  };
  handleEndDateChange = (endDate) => {
    this.setState({ period: { ...this.state.period, endDate } });
  };
  handleInfoSet = () => {
    if (this.requiredDataCheck()) {
      return;
    } else {
      this.props.handleInfoSet(this.state);
    }
  };
  handleChangeCountry = (e) => {
    this.setState({
      countryCode: e.value,
      countryName: e.label,
      region: null,
    });
  };
  handleOpenCategories = () => {
    this.setState({ categoriesOpen: true });
  };
  handleSelectCategory = (e, category) => {
    console.log(e, category._id);
    e.preventDefault();
    if (this.state.selectedCategory._id === category._id) {
      this.setState({ selectedCategory: "" }, () => {
        this.setState({ categoriesOpen: false });
      });
      console.log("sa");
    } else {
      this.setState({ selectedCategory: category }, () => {
        console.log(this.state.selectedCategory);
        this.setState({ categoriesOpen: false });
      });
      console.log("sa");
    }
  };
  handleRegularChange = (e) => {
    this.setState({
      period: { ...this.state.period, regularBasis: e.target.checked },
    });
  };
  renderField = () => {
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ categoriesOpen: false })}
      >
        <div className="workList filterList">
          {this.state.categories.map((item) => {
            return (
              <>
                <span
                  className={
                    this.state.selectedCategory
                      ? this.state.selectedCategory._id === item._id
                        ? "checkTime"
                        : null
                      : null
                  }
                  onClick={() => this.handleSelectCategory(item)}
                >
                  {i18n.language === "en" ? item.name.en : item.name.ko}
                </span>
              </>
            );
          })}
        </div>
      </ClickAwayListener>
    );
  };

  renderOccupationList = () => {
    return (
      <ClickAwayListener onClickAway={this.handleMenuClose}>
        <div className="timeList filterList">
          {this.state.careerList.map((item) => {
            return (
              <>
                <span
                  onClick={() => this.handleSelecTime(item)}
                  className={
                    this.state.selTime
                      ? this.state.selTime.time === item.time
                        ? "checkTime"
                        : null
                      : null
                  }
                >
                  {item.text}
                </span>
              </>
            );
          })}
        </div>
      </ClickAwayListener>
    );
  };
  handleStrOpenChange = (open) => {
    this.setState({ strDateOpen: open });
  };
  handleEndOpenChange = (open) => {
    this.setState({ endDateOpen: open });
  };
  // 시작일 Validation 함수
  disabledStartDate = (startValue) => {
    const { endDate } = this.state.period;

    if (!startValue) {
      return false;
    } else if (!endDate) {
      return startValue.valueOf() < this.today.valueOf();
    } else {
      return (
        // startValue.valueOf() > endDate?.valueOf() ||
        // ?startValue.valueOf() < this.today.valueOf()
        startValue.isBefore(this.today) && !startValue.isSame(this.today, "day")
      );
    }
  };
  isMinimumCheck = (type) => {
    const max = Number(this.state.maxSalary);
    const min = Number(this.state.minSalary);

    if (type === "min") {
      if ((max < min && max) || min <= 0) {
        this.setState({ minSalary: "" });
      }
    } else {
      if (max < min || max <= 0) {
        this.setState({ maxSalary: "" });
      }
    }
  };
  // 종료일 Validation 함수
  disabledEndDate = (endValue) => {
    const { strDate } = this.state.period;
    // if (!endValue || !strDate) {

    return (
      endValue.valueOf() < this.today.valueOf() ||
      endValue.valueOf() < strDate?.valueOf()
    );
  };

  handleSelectCareer = (e, career) => {
    let selectedCareer = this.state.selectedCareer;

    if (
      e.target.checked &&
      !this.state.selectedCareer.find((item) => item.id === career.id)
    ) {
      selectedCareer.push(career);
      this.setState({ selectedCareer });
    } else {
      selectedCareer = selectedCareer.filter((item) => item.id !== career.id);
      this.setState({ selectedCareer });
    }
  };
  handleTagList = (e) => {
    const tagSpcialRegex = /[~!@#$%^&*()_+|<>?:{}]/;
    const tags = this.state.tags;
    const tag = e.target.value.trim();
    if (tag && this.state.tags.length <= 9) {
      if (e.key === "Enter") {
        if (tag.length > 20) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.text"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tagSpcialRegex.test(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.SpicialText"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else if (tags.includes(tag)) {
          this.setState({
            msgTitle: i18n.t("tag.inputAlert.title"),
            msg: i18n.t("tag.inputAlert.overlap"),
            alertDialogOpen: true,
          });
          e.target.value = "";
        } else {
          tags.push(tag);
          this.setState({ tags });
          e.target.value = "";
        }
      }
    }
  };
  handleSelectWorkTime = (e, time) => {
    let selectedWorkTime = this.state.selectedWorkTime;

    if (
      e.target.checked &&
      !this.state.selectedWorkTime.find((item) => item.id === time.id)
    ) {
      selectedWorkTime.push(time);
      this.setState({ selectedWorkTime });
    } else {
      selectedWorkTime = selectedWorkTime.filter((item) => item.id !== time.id);
      this.setState({ selectedWorkTime });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });
  handleCalendarOpen = () => {
    this.setState({ calendarOpen: true });
  };

  render() {
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          webkitAppearance: "none",
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "#efefef"
            : isFocused
            ? "#efefef"
            : null,
          color: isDisabled ? "#ccc" : isSelected ? "#070707" : "black",
          cursor: isDisabled ? "not-allowed" : "default",
          fontWeight: isSelected ? "bold" : "unset",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "#efefef" : "#efefef"),
          },
        };
      },
      control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        border: "1px solid #efefef",
        width: "100%",
        height: "40px",
      }),
      input: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        alignItems: "center",
        visibility: "visible",
        color: "hsl(0, 0%, 20%)",
        boxSizing: "border-box",
      }),
      valueContainer: (provided, state) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        display: "flex",
        width: "100%",
        height: "40px",
        padding: "0 15px",
        fontSize: "1.2rem",
      }),

      menu: (provided, state) => ({
        ...provided,
        color: state.selectProps.menuColor,
        fontSize: "12px",
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return {
          ...provided,
          opacity,
          transition,
          maxWidth: "calc(100% - 60px)",
        };
      },
    };
    return (
      <>
        <div className="jobInfo-mainContainer center">
          <AlertDialog
            onButtonClick={this.handleAlertDialogClose}
            dialogHeadText={this.state.msgTitle}
            dialogBodyText1={this.state.msg}
            open={this.state.alertDialogOpen}
            onClose={this.handleAlertDialogClose}
          />
          {this.state.cropDialogOpen && this.state.cropSrc ? (
            <CropDialog
              ratio={this.ratio}
              showCroppedImage={this.showCroppedImage}
              onImageLoaded={this.onImageLoaded}
              onCropChange={this.onCropChange}
              crop={this.state.crop}
              src={this.state.cropSrc}
              open={this.state.cropDialogOpen}
              onCropComplete={this.onCropComplete}
              onClose={() => this.setState({ cropDialogOpen: false })}
            />
          ) : null}
          <div className="jobUploadInfo-box section">
            <div className="jobUpload-headertitle">
              <span>{i18n.t("teamPage.job.nav1")}</span>
            </div>
            <div className="jobUpload-header">
              <span>{i18n.t("job.upload.headerTitle")}</span>
            </div>
            {/* <div className="jobUpload-introMsg">
              <span>{i18n.t("job.upload.subTitle")}</span>
            </div> */}

            <div className="jobUpload-inputContainer">
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload.jobPosting")}{" "}
                </span>
                <div className="jobInfoField jobInput">
                  <div className="jobInfoField-inputField">
                    <input
                      value={this.state.position}
                      onChange={this.handlePositionChange}
                      type="text"
                      id="jobUpload_position"
                      placeholder={i18n.t("job.upload_3.jobPostingEx")}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload.recruitmentField")}{" "}
                </span>
                <div className="jobInfoType jobInput" id="jobUpload_category">
                  <OccupationField
                    open={this.state.categoriesOpen}
                    onOpen={this.handleOpenCategories}
                    onClose={() => this.setState({ categoriesOpen: false })}
                    title={
                      this.state.selectedCategory
                        ? i18n.language === "en"
                          ? this.state.selectedCategory.name.en
                          : this.state.selectedCategory.name.ko
                        : i18n.t("job.upload.recruitmentSelect")
                    }
                    handleChange={this.handleSelectCategory}
                    checked={[this.state.selectedCategory._id]}
                  />
                  {/* <button
                    id="jobUpload_category"
                    type="button"
                    onClick={this.handleOpenCategories}
                    className={
                      !this.state.selectedCategory
                        ? "placeHolder"
                        : this.state.categoriesOpen
                        ? "categoriesOpen"
                        : null
                    }
                  >
                    {this.state.selectedCategory
                      ? i18n.language === "en"
                        ? this.state.selectedCategory.name.en
                        : this.state.selectedCategory.name.ko
                      : i18n.t("job.upload.recruitmentSelect")}

                    <img
                      src={RedArrow}
                      style={
                        this.state.categoriesOpen
                          ? { transform: "rotate(180deg)" }
                          : null
                      }
                      alt="more"
                    />
                    {this.state
                      .categoriesOpen ? this.renderField() : null}
                  </button> */}
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload.mainTask")}
                </span>
                <div className="jobInfoType jobInput">
                  <textarea
                    id="jobUpload_mainTask"
                    className="section1"
                    value={this.state.mainTask}
                    onChange={this.handleMainWorkChange}
                    style={{ lineHeight: "1.5" }}
                    placeholder="업무 내용을 구체적으로 3줄 이상 입력해주세요"
                  ></textarea>
                  <span
                    style={{
                      marginTop: "5px",
                      color: "#6e7980",
                    }}
                  >
                    ({this.state.mainTask.length}/∞)
                  </span>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload.details")}{" "}
                </span>
                <div className="jobInput_exText">
                  <span>
                    {i18n.t("안내된 추천 필수 항목을 포함하여 입력해 주세요.")}
                  </span>
                </div>
                <div className="jobInfoType jobInput">
                  <textarea
                    id="jobUpload_detailTask"
                    className="section2"
                    style={{ minHeight: "316px", lineHeight: "1.5" }}
                    value={this.state.detailTask}
                    onChange={this.handleDetailWorkChange}
                    // resize={"none"}
                  ></textarea>
                  <span
                    style={{
                      marginTop: "5px",
                      color: "#6e7980",
                    }}
                  >
                    ({this.state.detailTask.length}/∞)
                  </span>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput" id="jobUpload_career">
                  {i18n.t("job.upload.career")}
                </span>
                <div className="jobInfoPay jobInput">
                  <div className="jobCheckList">
                    {this.state.careerList.map((item, index) => {
                      return (
                        <div
                          className="job-checkBox"
                          key={`${item.text}_${index}`}
                        >
                          <input
                            type="checkBox"
                            checked={
                              this.state.selectedCareer[0]
                                ? this.state.selectedCareer.find(
                                    (value) => item?.id === value?.id
                                  )
                                : false
                            }
                            id={`${item.text}_${index}`}
                            onChange={(e) => this.handleSelectCareer(e, item)}
                          />
                          <label
                            className="nowrap"
                            htmlFor={`${item.text}_${index}`}
                          >
                            <span></span>
                            {item.text}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="jobInputList">
                <span
                  className="jobTitle requiredInput"
                  id="jobUpload_workTimeList"
                >
                  {i18n.t("고용 형태")}
                </span>
                <div className="jobInfoPay jobInput">
                  <div className="jobCheckList">
                    {this.state.workTimeList.map((item, index) => {
                      return (
                        <div
                          className="job-checkBox"
                          key={`${item.text}_${index}`}
                        >
                          <input
                            type="checkBox"
                            checked={
                              this.state.selectedWorkTime[0]
                                ? this.state.selectedWorkTime.find(
                                    (value) => item?.id === value?.id
                                  )
                                : null
                            }
                            id={`${item.text}_${index}`}
                            onChange={(e) => this.handleSelectWorkTime(e, item)}
                          />
                          <label
                            className="nowrap"
                            htmlFor={`${item.text}_${index}`}
                          >
                            <span></span>
                            {item.text}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload.inputPay")}
                </span>
                <div className="jobInput jobInfoPay jobInput">
                  <div className="jobInput_exText">
                    <span>{i18n.t("job.upload.pay.ex_1")}</span>
                    <span>{i18n.t("job.upload.pay.ex_2")}</span>
                    <span>{i18n.t("job.upload.pay.ex_3")}</span>
                  </div>
                  <div className="payInput_field">
                    <div className="fieldWrap">
                      <select
                        onChange={(e) =>
                          this.setState({ payType: e.target.value })
                        }
                      >
                        <option value="KRW">KRW</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                    <div className="fieldWrap">
                      <div className="ui icon input">
                        <input
                          id="jobUpload_minSalary"
                          type="text"
                          value={this.state.minSalary}
                          //onBlur={() => this.isMinimumCheck("min")}
                          onChange={this.handleMinSalaryChange}
                          placeholder={i18n.t("최소 연봉")}
                        ></input>
                        <span className="currency unit">
                          {this.state.payType === "KRW" ? "만원" : "$"}
                        </span>
                      </div>
                    </div>

                    <span>-</span>
                    <div className="fieldWrap">
                      <div className="ui icon input">
                        <input
                          type="text"
                          id="jobUpload_maxSalary"
                          value={this.state.maxSalary}
                          // onBlur={() => this.isMinimumCheck("max")}
                          onChange={this.handleMaxSalaryChange}
                          placeholder={i18n.t("최대 연봉")}
                        ></input>
                        <span className="currency unit">
                          {this.state.payType === "KRW" ? "만원" : "$"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="job-checkBox fieldWrap">
                    <input
                      type="checkBox"
                      checked={this.state.payVisible}
                      id="pay-info-checkBox"
                      onChange={this.handlePayVisibleChange}
                    />
                    <label htmlFor="pay-info-checkBox">
                      <span></span>
                      {i18n.t("비공개")}
                    </label>
                    <span>{this.state.payMsg}</span>
                  </div>
                </div>
              </div>
              <div className="jobInputList period_input">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload_2.workingDates")}
                </span>
                <div className="jobInput-dateRange">
                  <DatePicker
                    id="jobUpload_period"
                    // locale={i18n.language === "ko" ? koKR : null}
                    disabledDate={this.disabledStartDate}
                    format="YYYY-MM-DD"
                    value={this.state.period.strDate}
                    placeholder="시작일"
                    open={this.state.strDateOpen}
                    onChange={this.handleStrDateChange}
                    onOpenChange={this.handleStrOpenChange}
                    disabled={this.state.period.regularBasis}
                    showToday={false}
                  />
                  <DatePicker
                    // locale={i18n.language === "ko" ? koKR : null}
                    // onFocus={() => {
                    //   if (!this.endDateAuto) {
                    //     let i;
                    //     const el = document.getElementsByClassName(
                    //       "ant-select-search__field"
                    //     );
                    //     for (i = 0; i < el.length; i++) {
                    //       el[i].setAttribute(
                    //         "autocomplete",
                    //         "registration-select"
                    //       );
                    //     }
                    //     this.endDateAuto = true;
                    //   }
                    // }}
                    disabledDate={this.disabledEndDate}
                    format="YYYY-MM-DD"
                    value={this.state.period.endDate}
                    placeholder="종료일"
                    onChange={this.handleEndDateChange}
                    open={this.state.endDateOpen}
                    disabled={this.state.period.regularBasis}
                    onOpenChange={this.handleEndOpenChange}
                    showToday={false}
                  />
                </div>
                <div className="job-checkBox fieldWrap">
                  <input
                    type="checkBox"
                    checked={this.state.period.regularBasis}
                    id="date-checkBox"
                    onChange={this.handleRegularChange}
                  />
                  <label htmlFor="date-checkBox">
                    <span></span>
                    {i18n.t("상시채용")}
                  </label>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput">
                  {i18n.t("job.upload_2.location")}
                </span>
                <div className="input-location jobInput">
                  <div className="location-country">
                    <CountrySelect
                      id="jobUpload_country"
                      handleChangeCountry={this.handleChangeCountry}
                      countryCode={this.state.countryCode}
                      style={customStyles}
                    />
                    {this.state.countryCode ? (
                      <RegionDropdown
                        id="jobUpload_region"
                        style={{
                          padding: "0 15px",
                          borderRadius: "unset",
                          fontSize: "1.2rem",
                        }}
                        countryValueType="short"
                        country={this.state.countryCode}
                        value={this.state.region}
                        className="region-input"
                        defaultOptionLabel={i18n.t("job.upload_1.region")}
                        onChange={(val) => this.handleRegionChange(val)}
                      />
                    ) : // <input
                    //   className="noData"
                    //   placeholder="국가를 먼저 선택해 주세요"
                    //   readOnly
                    // ></input>
                    null}
                  </div>
                </div>
              </div>
              <div className="jobInputList">
                <span className="jobTitle requiredInput" id="jobUpload_image">
                  {i18n.t("job.upload.image")}
                </span>
                <div className="jobInput_exText">
                  <span>{i18n.t("job.upload.image.ex_1")}</span>
                  <span>{i18n.t("job.upload.image.ex_2")}</span>
                  <span>{i18n.t("job.upload.image.ex_3")}</span>
                  <span>{i18n.t("job.upload.image.ex_4")}</span>
                  <span>{i18n.t("job.upload.image.ex_5")}</span>
                </div>

                <div className="imgFile_container">
                  <div
                    className="imgFile_header"
                    onClick={() => this.imgInput.click()}
                  >
                    {/* <span>Press the left button to upload the image</span> */}
                    <button className="following-sm">
                      {i18n.t("global.upload")}
                    </button>
                    <input
                      id="job_img-upload"
                      onChange={this.handleFileOnChange}
                      type="file"
                      ref={(ref) => (this.imgInput = ref)}
                      hidden
                    />
                  </div>
                  <div className="imgFile_list-wrapper">
                    {this.state.fileList && this.state.fileList.length
                      ? this.state.fileList.map((file, index) => {
                          return (
                            <div
                              className={`flie_box ${
                                this.state.selectedFile === index
                                  ? "selectFile"
                                  : ""
                              }`}
                              onClick={() =>
                                this.setState({ selectedFile: index })
                              }
                            >
                              <img
                                src={red_xboxIcon}
                                alt="img_remove"
                                onClick={() => this.handleRemoveFile(index)}
                              />
                              {/* <img src={file.preview} alt="file_preview" />; */}
                              <div
                                style={{
                                  backgroundImage: `url(${file.preview})`,
                                }}
                              ></div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              <div className="job-additionalInput-wrapper">
                <h2>{i18n.t("job.upload.additional.title")}</h2>
                <div className="jobInputList">
                  <span className="jobTitle">
                    {i18n.t("job.upload.remoteWork")}
                  </span>
                  <div className="job-checkBox fieldWrap">
                    <input
                      type="checkBox"
                      checked={this.state.remoteWork}
                      id="remoteWork-checkBox"
                      onChange={this.handleRemoteWorkChange}
                    />
                    <label htmlFor="remoteWork-checkBox">
                      <span></span>
                      {i18n.t("job.upload.possible")}
                    </label>
                  </div>
                </div>
                <div className="jobInputList job_tagInput_field">
                  <span className="jobTitle">{i18n.t("태그")}</span>
                  <div className="jobInput_exText">
                    <span>{i18n.t("job.upload.tag.ex_1")}</span>
                    <span>{i18n.t("job.upload.tag.ex_2")}</span>
                  </div>
                  <div className="jobInput_tagField">
                    {this.state.tags && this.state.tags.length
                      ? this.state.tags.map((item, index) => {
                          return (
                            <div className="tagBox">
                              <span className="tag">{`${item}`}</span>
                              <img
                                src={TagDelIcon}
                                alt="delete"
                                onClick={() => this.handleDeleteTag(index)}
                              />
                            </div>
                          );
                        })
                      : null}
                    <input
                      type="text"
                      placeholder={
                        !this.state.tags[0]
                          ? i18n.t("job.upload.tag.placeholder")
                          : ""
                      }
                      onKeyPress={this.handleTagList}
                    />
                  </div>
                </div>
              </div>
              <div className="pageMoves-btns">
                <button id="back" type="button" onClick={this.props.toBack}>
                  {i18n.t("global.back")}
                </button>
                <button
                  //className={!this.requiredDataCheck() ? "disabledBtn" : ""}
                  id="next"
                  type="button"
                  onClick={this.handleInfoSet}
                >
                  {this.props.mode !== "Edit"
                    ? i18n.t("global.registration")
                    : i18n.t("project.comment.modify")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default compose(withSnackbar)(JobInfo);
