import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const RemoveAppicants = async (id, userId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let body = {
    id,
    userId,
  };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/application-delete`;
  await axios.patch(url, body, config);
};

export const PostTeamOwnerChange = async (id, userId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let body = {
    id,
    userId,
  };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/change-owner`;
  await axios.post(url, body, config);
};

export const GetMyTeamList = async (filter) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/my-team?filter=${filter}`;
  const response = await axios.get(url, config);
  return response.data.data;
};

export const GetTeamPostingList = async (skip, limit) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/team-job-list`;

  const response = await axios.get(url, config);

  return response.data.data;
};
export const GetUploadProjectList = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/get-projects-upload?id=${teamId}`;
  const response = await axios.get(url, config);

  return response.data.data;
};
export const TeamInviteCheck = async () => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/invite-exist`;
  const response = await axios.get(url, config);
  return response.data.data;
};
export const GetTeamLinkInfo = async (linkURL) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/link-info?linkURL=${linkURL}`;
  const response = await axios.get(url, config);
  return response.data.data;
};

export const DeleteTeam = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/delete?id=${teamId}`;
  const response = await axios.delete(url, config);

  return response;
};
export const TeamNameExistCheck = async (teamName) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/name-exist?teamName=${teamName}`;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await axios.get(url, config);
  return response.data.data;
};
export const TeamLinkExistCheck = async (linkURL) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/link-exist?linkURL=${linkURL}`;
  const response = await axios.get(url, config);

  return response.data;
};
export const CreateTeamId = async () => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/create`;
  const response = await axios.post(url, null, config);
  return response.data.data.teamId;
};

export const GetTeamSettingInfo = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/setting?id=${teamId}`;
  const response = await axios.get(url, config);
  return response.data.data;
};

export const PatchTeamInfo = async (
  teamId,
  teamName,
  linkURL,
  coutryInfo,
  linkInfo,
  website,
  allowJoin,
  teamInfo
) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/set-info`;
  let body = {
    id: teamId,
    teamName: teamName,
    linkURL: linkURL,
    countryInfo: coutryInfo,
    linkInfo: linkInfo,
    website: website,
    allowJoin: allowJoin,
    teamInfo: teamInfo,
  };
  const response = await axios.patch(url, body, config);
  return response.data;
};
export const GetInviteMemberList = async (searchStr, teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/find-users?teamId=${teamId}&searchString=${searchStr}`;
  const response = await axios.get(url, config);

  return response.data.data;
};
export const CloseSetJob = async (jobId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/close`;
  await axios.post(url, { id: jobId }, config);
};

export const GetMemberModeJobList = async (id, filter, order, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/team-list-member?teamId=${id}`;
  if (filter) url += `&filter=${filter}`;
  if (order) url += `&order=${order}`;
  if (skip) url += `&skip=${skip}`;
  if (limit) url += `&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const GetRecruitmentList = async (id, order, skip, limit) => {
  const token = GetUserToken();
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/job/team-list?teamId=${id}`;
  if (order) url = url + `&order=${order}`;
  if (skip) url = url + `&skip=${skip}`;
  if (limit) url = url + `&limit=${limit}`;

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data.data;
};
export const GetTeamMemberList = async (
  teamId,
  isMemberMode,
  type,
  order,
  isASC,
  skip = 0,
  limit = 10
) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/user-list?id=${teamId}&isMemberMode=${isMemberMode}&skip=${skip}&limit=${limit}`;
  if (type) url = url + `&type=${type}`;
  if (order) url = url + `&order=${order}`;
  if (isASC) url = url + `&isASC=${isASC}`;
  const response = await axios.get(url, config);

  return response.data.data;
};

export const PostMemberAuthChange = async (teamId, userId, authority) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/change-authority`;
  let body = {
    id: teamId,
    userId,
    authority,
  };

  await axios.post(url, body, config);
};
export const PatchTeamPhoto = async (teamId, file) => {
  const token = GetUserToken();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/set-photo`;
  const body = new FormData();
  body.append("id", teamId);
  body.append("teamPhoto", file);
  await axios.patch(url, body, config);
};
export const PostMemberInviteCancel = async (teamId, userId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  let body = {
    id: teamId,
    userId,
  };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/cancel-invite`;
  await axios.post(url, body, config);
};
export const PostRemoveMember = async (teamId, userId) => {
  let body = {
    id: teamId,
    userId,
  };
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/remove`;
  await axios.post(url, body, config);
};
export const PostInviteMail = async (code, userId, teamId) => {
  let body = {
    teamId,
    userId,
    code,
  };

  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/accept-mail`;
  const res = await axios.post(url, body, config);
  return res.data.data;
};
export const PostMemberInvite = async (id, userIds) => {
  const token = GetUserToken();
  let body = {
    id,
    userIds,
  };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/invite`;
  await axios.post(url, body, config);
};

export const CreateTeamInfoSet = async (
  teamId,
  teamName,
  teamPage,
  snsURL,
  teamPhoto,
  countryName,
  countryCode,
  region,
  members
) => {
  const token = GetUserToken();
  let body = new FormData();
  body.append("id", teamId);
  body.append("name", teamName);
  body.append("linkURL", teamPage);
  if (snsURL) {
    body.append("SNSURL", snsURL);
  }
  if (teamPhoto) {
    body.append("teamPhoto", teamPhoto);
  }
  if (countryName && countryCode) {
    body.append(`countryInfo[countryName]`, countryName);
    body.append(`countryInfo[countryCode]`, countryCode);
    body.append(`countryInfo[region]`, region);
  }
  if (members.length !== 0) {
    for (let i = 0; i < members.length; i++) {
      body.append(`members[${i}][userId]`, members[i]._id);
      body.append(`members[${i}][authority]`, members[i].authority);
    }
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/create-finish`;
  await axios.post(url, body, config);
};

export const GetTeamInfo = async (teamId) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/info?id=${teamId}`;
  const response = await axios.get(url);
  return response.data.data;
};

export const GetAvailableTeamList = async (searchStr) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/api/v1/team/available-list?searchString=${searchStr ? searchStr : ""}`;
  const response = await axios.get(url, token ? config : null);

  return response.data.data;
};

export const GetTeamList = async (searchStr) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/api/v1/team/list?searchString=${searchStr ? searchStr : ""}`;
  const response = await axios.get(url, token ? config : null);

  return response.data.data;
};
export const GetPendingUserList = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/applications?id=${teamId}`;
  const response = await axios.get(url, config);
  return response.data.data;
};
export const RemoveTeamProject = async (id, project) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/remove-projects`;
  const body = {
    id,
    project,
  };
  await axios.patch(url, body, config);
};
export const PostUserReject = async (teamId, userId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/reject-apply`;
  let body = {
    id: teamId,
    userId,
  };
  await axios.post(url, body, config);
};
export const PostUserAccept = async (teamId, userId, auth) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/accept-apply`;
  let body = {
    id: teamId,
    userId,
    authority: auth,
  };
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  await axios.post(url, body, config);
};
export const PostLeavingTeam = async (id) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/withdrawal`;
  await axios.patch(
    url,
    {
      id,
    },
    config
  );
};

export const PostTeamApply = async (teamId, msg) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/apply`;
  let body = {
    id: teamId,
    message: msg,
  };
  await axios.post(url, body, config);
};
export const PostTeamAccept = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/accept`;
  await axios.post(url, { id: teamId }, config);
};
export const PostTeamReject = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/reject`;
  await axios.post(url, { id: teamId }, config);
};
export const TeamCancelApply = async (teamId) => {
  const token = GetUserToken();
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/team/cancel-apply`;
  await axios.post(
    url,
    {
      id: teamId,
    },
    config
  );
};
