import axios from "axios";

import { GetUserToken } from "../../modules/userAuth";

export const PatchComment = async (id, comment) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/comment/modify`;
  let body = {
    id,
    comment,
  };
  await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const GetCommentList = async (projectId, skip, limit) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/comment/list-project?projectId=${projectId}`;
  if (skip) {
    url = url + `&skip=${skip}`;
  }
  if (limit) {
    url = url + `&limit=${limit}`;
  }
  const response = await axios.get(url);

  return response.data.data;
};
export const CreateComment = async (projId, text) => {
  let body = {
    projectId: projId,
    text: text,
  };

  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/comment/create`;
  const response = await axios.post(url, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.message;
};
export const DeleteCommnet = async (id) => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/comment/delete?id=${id}`;
  await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
