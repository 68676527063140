let windowObjectReference = null;
let previousUrl = null;

// 화면 중앙으로 팝업을 열어주는 기능.
// 나중에 듀얼모니터인 경우도 대응.
function openCenteredWindow(url, width, height) {
  const pos = {
    x: window.screen.width / 2 - width / 2,
    y: window.screen.height / 2 - height / 2,
  };

  if (window.screenLeft) {
    pos.x += window.screenLeft - 150;
  }
  const features = `toolbar=no menubar=no width=${width} height=${height} left=${pos.x} top=${pos.y}`;

  return window.open(url, "_blank", features);
}

// redirect는 editor or root 현재는 이 2가지 경우밖에 없음.
export const openSignInWindow = (
  url,
  redirect,
  history,
  callback,
  handleSignupStatus
) => {
  window.removeEventListener("message", receiveMessage);

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = openCenteredWindow(url, 600, 700);
  } else if (previousUrl !== url) {
    // 기존의 팝업이 열려있지만, url이 다른 경우에는 새로 열고 focus
    windowObjectReference = openCenteredWindow(url, 600, 700);
    windowObjectReference.focus();
  } else {
    // 기존의 팝업이 열려있고, url이 동일한 경우는 새롭게 열지 말고 focus
    windowObjectReference.focus();
  }

  window.addEventListener(
    "message",
    (event) =>
      receiveMessage(event, redirect, history, callback, handleSignupStatus),
    true
  );
  previousUrl = url;
};

export const receiveMessage = async (
  event,
  redirect,
  history,
  callback,
  handleSignupStatus
) => {
  const { data } = event;

  // if we trust the sender and the source is our popup
  if (callback && data && data.status) {
    if (data.status === "success") {
      await callback(data);
      //소셜로그인, 회원가입 기능 완료 시 주석 해제할것
      if (redirect) {
        history.push(redirect);
      }
    } else {
      await callback();
    }
  }
};
