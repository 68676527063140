import React, { Component } from "react";
import "./MembersList.scss";
import i18n from "../../i18n";
import { ClickAwayListener } from "@material-ui/core";
import LocationIcon from "../../image/location-icon.svg";
import ViewsIcon from "../../image/view-icon.svg";
import LikeIcon from "../../image/like-icon.svg";
import ChangeAuthDialog from "./MemberAuth/MemberChangeAuthDialog";
import RemomveDialog from "./MemberRemoveDialog/MemberRemoveDialog";
import { nFormatter } from "../../Component/functions";
import { Table, Tag, Space, Dropdown, Menu } from "antd";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import imgPlaceholder from "../../image/Admin/placeholder.gif";
import emptyIcon from "../../image/no-applicant-icon.svg";
import DeleteDialog from "../../TeamSetting/Delete/DeleteDialog/DeleteDialog";
import Moment from "react-moment";
import { withSnackbar } from "notistack";
import { GetUserInfo } from "../../Api/User/user";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { GetUserToken } from "../../modules/userAuth";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
import TwoButtonDialog from "../../Component/TwoButtonDialog/TwoButtonDialog";
import warningIcon from "../../image/DelteDialogIcon/warning-icon.svg";
class MembersList extends Component {
  state = {
    selectedMember: "",
    changeAuthDialogOpen: false,
    removeDialogOpen: false,
    deleteDialogOpen: false,
    menuOpen: false,
    ownerChangeDialogOpen: false,
  };

  renderDate = (dateString, isJoined) => {
    if (isJoined) {
      const date = new Date(dateString);
      return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
    } else {
      return;
    }
  };
  renderMemberAuthority = (auth) => {
    const authority = auth;

    if (authority === "member") {
      return i18n.t("teamPage.memberList.auth.member");
    } else if (authority === "admin") {
      return i18n.t("teamPage.memberList.auth.admin");
    } else if (authority === "owner") {
      return i18n.t("teamPage.memberList.auth.owner");
    }
  };
  handleTableChange = async (a, filters, sorter) => {
    const order = this.checkSorterType(sorter.columnKey);

    const isASC = sorter.order === "ascend" ? true : false;
    this.props.teamMemberListLoad(undefined, null, order, isASC);
  };

  checkSorterType = (sorter) => {
    if (sorter === "userName") {
      return 1;
    } else if (sorter === "authority") {
      return 2;
    } else if (sorter === "isJoined") {
      return 3;
    } else if (sorter === "projects") {
      return 4;
    } else if (sorter === "joinedDate") {
      return 5;
    } else {
      return null;
    }
  };

  handleMenuOpen = (userInfo) => {
    this.setState({ selectedMember: userInfo, menuOpen: true });
  };

  handleChageAuthDialogOpen = () => {
    if (this.props.userAuth !== "owner") {
      this.props.enqueueSnackbar(i18n.t("global.alert.auth"), {
        variant: "warning",
        key: "no-auth",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    this.setState({ changeAuthDialogOpen: true });
  };

  handleRemoveDialogOpen = (auth) => {
    if (this.props.userAuth === "member") {
      this.props.enqueueSnackbar(i18n.t("global.alert.auth"), {
        variant: "warning",
        key: "no-auth",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    if (auth !== "admin") {
      this.setState({ removeDialogOpen: true });
    } else {
      if (this.props.userAuth === "admin") {
        this.setState({ deleteDialogOpen: true });
      } else {
        this.props.enqueueSnackbar(i18n.t("teamPage.memberList.remove.alert"), {
          variant: "warning",
          key: "error.uneditableData-owner",
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };

  renderMenu = () => {
    return (
      <ClickAwayListener onClickAway={() => this.setState({ menuOpen: false })}>
        <div className="more-menu-list">
          <span onClick={this.handleChageAuthDialogOpen}>
            {i18n.t("team.admin.menu1")}
          </span>
          <span onClick={this.handleRemoveDialogOpen}>
            {i18n.t("team.admin.menu2")}
          </span>
        </div>
      </ClickAwayListener>
    );
  };
  renderAccountStatus = (isJoined) => {
    if (isJoined) {
      return i18n.t("teamPage.memberList.joinStatus.active");
    } else {
      return i18n.t("teamPage.memberList.joinStatus.pending");
    }
  };
  toUserPage = async (userId) => {
    try {
      const response = await GetUserInfo(userId);
      this.props.history.push(`/@${response.linkURL}`);
    } catch (e) {
      console.dir(e);
    }
  };

  handleFollow = (memberInfo, index, e) => {
    const token = GetUserToken();
    e.stopPropagation();
    if (token) {
      if (!memberInfo.isFollowing) {
        this.props.handleFollow(memberInfo);
      } else {
        this.props.handleUnFollow(memberInfo);
      }
    } else {
      // alert("로그인이 필요한 서비스입니다.");
      this.handleAlert();
    }
  };
  renderAuthColor = (auth) => {
    if (auth === "owner") {
      return "#0075ff";
    } else if (auth === "admin") {
      return "#ffc700";
    } else {
      return "#989898";
    }
  };
  renderMemberList = (memberInfo) => {
    console.log(memberInfo);
    const Tab1Columns = [
      {
        title: i18n.t("teamPage.member.header1"),
        dataIndex: ["teamInfo", "thumbnail", "title"],
        key: "userName",
        ellipsis: true,
        sorter: true,
        // width: "100%",
        render: (text, data) => (
          <Space>
            <div
              className="body-memberInfo"
              onClick={() => this.toUserPage(data._id)}
              style={{ display: "flex" }}
            >
              <ProfilePhoto src={data.profilePhoto} size={70} />
              <div className="memberInfo-uesrInfo">
                <div className="userInfo-name">
                  <span>{data.userName}</span>
                </div>
                <div className="userInfo-location">
                  <img src={LocationIcon} alt="locationIcon" />
                  <span>
                    {data.countryInfo ? data.countryInfo.countryName : null}
                  </span>
                </div>
              </div>
            </div>
          </Space>
        ),
      },
      {
        title: i18n.t("teamPage.member.header2"),
        key: "authority",
        dataIndex: "authority",
        ellipsis: true,

        render: (text, data) => {
          let color = !data.authority ? "default" : "red";
          if (data.authority) {
            return (
              <div className="body-memberAuth">
                <div
                  style={{
                    backgroundColor: `${this.renderAuthColor(data.authority)}`,
                  }}
                ></div>
                <span>{this.renderMemberAuthority(data.authority)}</span>
              </div>
            );
          } else {
            return (
              <Tag color={color} key={"authority"}>
                {i18n.t("global.noData")}
              </Tag>
            );
          }
        },

        // filters:
        //   i18n.language === "ko"
        //     ? this.props.categoriesKor.map((item) => ({
        //         value: item.value,
        //         text: item.label,
        //       }))
        //     : this.props.categoriesEng.map((item) => ({
        //         value: item.value,
        //         text: item.label,
        //       })),
      },
      {
        title: i18n.t("teamPage.member.header3"),
        key: "isJoined",
        dataIndex: "isJoined",
        ellipsis: true,
        sorter: true,

        render: (text, data) => {
          let color = data.isJoined ? "darkGray" : "gray";
          if (typeof data.isJoined === "boolean") {
            return (
              <Tag color={color} key={"isJoined"}>
                {this.renderAccountStatus(
                  data.isJoined ? data.isJoined : false
                )}
              </Tag>
            );
          } else {
            return (
              <Tag color={"default"} key={"isJoined"}>
                {i18n.t("global.noData")}
              </Tag>
            );
          }
        },
      },

      {
        title: i18n.t("teamPage.member.header4"),
        ellipsis: true,
        key: "projects",
        dataIndex: "projects",
        sorter: true,
        width: 80,
        render: (text, data) => {
          if (data.projectCount) {
            return (
              <div className="body-memberProjects">
                <span>{nFormatter(data.projectCount)}</span>
              </div>
            );
          } else {
            return <span>0</span>;
          }
        },
      },

      {
        title: i18n.t("teamPage.member.header5"),
        key: "joinedDate",
        dataIndex: "joinedDate",
        ellipsis: true,
        sorter: true,
        render: (text, data) => {
          if (data.isJoined) {
            if (data.joinedAt) {
              return (
                <Moment locale={i18n.language} fromNow>
                  {data.joinedAt}
                </Moment>
              );
            } else {
              return (
                <Tag color={"default"} key={"joinedDate"}>
                  {i18n.t("global.noData")}
                </Tag>
              );
            }
          } else {
            return (
              <Tag color={"default"} key={"joinedDate"}>
                {i18n.t("teamPage.member.Inviting")}
              </Tag>
            );
          }
        },
      },

      // 기능 컬럼
      {
        title: "",
        key: "action",
        dataIndex: "_id",
        fixed: "right",
        align: "right",
        render: (text, data) => {
          const menu = (
            <Menu className="menu-Wrapper">
              <Menu.Item
                onClick={() => this.handleChageAuthDialogOpen(data.authority)}
              >
                <div className="team-members-menu-item">
                  {i18n.t("team.admin.menu1")}
                </div>
              </Menu.Item>
              <Menu.Item
                onClick={() => this.handleRemoveDialogOpen(data.authority)}
              >
                <div className="team-members-menu-item">
                  {i18n.t("team.admin.menu2")}
                </div>
              </Menu.Item>
              {this.props.teamInfo.myAuthority === "owner" && (
                <Menu.Item
                  onClick={() => this.setState({ ownerChangeDialogOpen: true })}
                >
                  <div className="team-members-menu-item">
                    {i18n.t("team.admin.menu3")}
                  </div>
                </Menu.Item>
              )}
            </Menu>
          );
          if (
            data.authority === "owner" ||
            this.props.myInfo._id === data._id
          ) {
            return;
          } else {
            return (
              <Space>
                {/* <Button
                type="primary"
                size="middle"
                style={{ backgroundColor: "black", borderColor: "unset" }}
                onClick={() => this.toJobUpload(data.teamInfo._id, data._id)}
                id={data._id}
              >
                수정
              </Button> */}
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                  {/* <Button>bottomCenter</Button> */}
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => this.handleMenuOpen(data)}
                  >
                    <MoreVertIcon style={{ fontSize: "1.9rem" }} />
                  </IconButton>
                </Dropdown>
              </Space>
            );
          }
        },
      },
    ];
    return (
      <div className="team_members-list-container">
        <TwoButtonDialog
          dialogHeadText={i18n.t("teamPage.member.owner.header")}
          dialogBodyText1={i18n.t("teamPage.member.owner.body1")}
          dialogBodyText2={i18n.t("teamPage.member.owner.body2")}
          dialogBodyIcon={warningIcon}
          className={"owner-change-container"}
          open={this.state.ownerChangeDialogOpen}
          rightButton={() => {
            this.setState({ ownerChangeDialogOpen: false });
            this.props.handleChangeOwner(this.state.selectedMember);
          }}
          onClose={() => this.setState({ ownerChangeDialogOpen: false })}
        />
        <ChangeAuthDialog
          open={this.state.changeAuthDialogOpen}
          onClose={() => this.setState({ changeAuthDialogOpen: false })}
          auth={this.state.selectedMember.authority}
          teamName={this.props.teamName}
          memberId={this.state.selectedMember._id}
          userName={this.state.selectedMember.userName}
          handleChageAuthMember={this.props.handleChageAuthMember}
        />
        <RemomveDialog
          userName={this.state.selectedMember.userName}
          open={this.state.removeDialogOpen}
          handleRemoveMember={this.handleRemoveMember}
          onClose={() => this.setState({ removeDialogOpen: false })}
        />
        <DeleteDialog
          history={this.props.history}
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          teamName={this.props.teamName}
          id={this.props.teamId}
        />
        <Table
          className="memberListTableContainer"
          columns={Tab1Columns}
          rowKey="_id"
          pagination={{
            total: this.props.totalItems,
            current: this.props.currentPage,
            onChange: this.props.handlePageChange,
            pageSize: this.props.setPageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
            onShowSizeChange: this.props.handlePageSizeChange,
            simple: true,
            hideOnSinglePage: true,
          }}
          tableLayout="auto"
          dataSource={memberInfo}
          // 번역
          locale={{
            filterConfirm: i18n.t("global.ok"),
            filterReset: i18n.t("global.reset"),
            triggerDesc: i18n.t("admin.sort.triggerDesc"),
            triggerAsc: i18n.t("admin.sort.triggerAsc"),
            cancelSort: i18n.t("admin.sort.cancelSort"),
            emptyText: (
              <div className="jobList-null">
                <img src={emptyIcon} alt="emptyIcon" />
                <span>{i18n.t("teamPage.recruitment.empty")}</span>
              </div>
            ),
          }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };
  handleRemoveMember = () => {
    this.setState({ removeDialogOpen: false }, () => {
      this.props.handleRemoveMember(this.state.selectedMember);
    });
  };
  renderMemberModeList = (memberInfo) => {
    return (
      <div className="team_members-list-container memberMode-list">
        <div className="team_members-list-columns">
          {memberInfo.map((member, index) => {
            return (
              <div
                className="team_members-list-column"
                key={`members_table_${index}`}
              >
                <div
                  className="team-memberInfo"
                  onClick={() => this.toUserPage(member._id)}
                >
                  <ProfilePhoto src={member.profilePhoto} size={70} />
                  <div className="team-memberInfo-detail">
                    <div className="detail-top">
                      <span style={{ cursor: "pointer" }}>
                        {member.userName}
                      </span>
                      <div className="detail-location">
                        <img src={LocationIcon} alt="user-location" />
                        <span>{member.countryInfo?.countryName}</span>
                      </div>
                    </div>
                    <div className="detail-bt">
                      {this.props.myInfo &&
                      this.props.myInfo._id !== member._id ? (
                        <>
                          <button
                            type="button"
                            onClick={(e) => this.handleFollow(member, index, e)}
                            // style={{ marginRight: "5px" }}
                            className={
                              member.isFollowing ? "following-sm" : "follow-sm"
                            }
                          >
                            {member.isFollowing
                              ? i18n.t("mypage.profile.unfollow")
                              : i18n.t("mypage.profile.follow")}
                          </button>
                          <div className="statDivider"></div>
                        </>
                      ) : null}
                      <div className="member-status">
                        <img
                          src={LikeIcon}
                          alt="LikeCount"
                          style={{ width: "11px" }}
                        />
                        <span>{nFormatter(member.likeMembers)}</span>
                      </div>
                      <div className="member-status">
                        <img
                          src={ViewsIcon}
                          alt="ViewsCount"
                          style={{ width: "12px" }}
                        />
                        <span>{nFormatter(member.views)}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="member-projectInfo">
                  {member.projectInfo ? (
                    <>
                      <li className="ProfileRow-projectCover-1EN">
                        <div>
                          {member.projectInfo[0] ? (
                            <img
                              src={member.projectInfo[0]?.thumbnail}
                              alt="previewImage"
                              onError={(e) => {
                                e.target.src = imgPlaceholder;
                              }}
                            ></img>
                          ) : (
                            <div
                              style={{ backgroundColor: "#f2f2f2" }}
                              className="ProfileRow-emptyCover-3PF"
                            ></div>
                          )}
                        </div>
                      </li>
                      <li className="ProfileRow-projectCover-1EN">
                        <div>
                          {member.projectInfo[1] ? (
                            <img
                              src={member.projectInfo[1]?.thumbnail}
                              alt="previewImage"
                              onError={(e) => {
                                e.target.src = imgPlaceholder;
                              }}
                            ></img>
                          ) : (
                            <div
                              style={{ backgroundColor: "#f2f2f2" }}
                              className="ProfileRow-emptyCover-3PF"
                            ></div>
                          )}
                        </div>
                      </li>
                      <li className="ProfileRow-projectCover-1EN">
                        <div>
                          {member.projectInfo[2] ? (
                            <img
                              src={member.projectInfo[2]?.thumbnail}
                              alt="previewImage"
                              onError={(e) => {
                                e.target.src = imgPlaceholder;
                              }}
                            ></img>
                          ) : (
                            <div
                              style={{ backgroundColor: "#f2f2f2" }}
                              className="ProfileRow-emptyCover-3PF"
                            ></div>
                          )}
                        </div>
                      </li>
                      <li className="ProfileRow-projectCover-1EN">
                        <div>
                          {member.projectInfo[3] ? (
                            <img
                              src={member.projectInfo[3]?.thumbnail}
                              alt="previewImage"
                              onError={(e) => {
                                e.target.src = imgPlaceholder;
                              }}
                            ></img>
                          ) : (
                            <div
                              style={{ backgroundColor: "#f2f2f2" }}
                              className="ProfileRow-emptyCover-3PF"
                            ></div>
                          )}
                        </div>
                      </li>
                      <li className="ProfileRow-projectCover-1EN">
                        <div>
                          {member.projectInfo[4] ? (
                            <img
                              src={member.projectInfo[4]?.thumbnail}
                              alt="previewImage"
                              onError={(e) => {
                                e.target.src = imgPlaceholder;
                              }}
                            ></img>
                          ) : (
                            <div
                              style={{ backgroundColor: "#f2f2f2" }}
                              className="ProfileRow-emptyCover-3PF"
                            ></div>
                          )}
                        </div>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  render() {
    const color = "black";
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    `;
    const memberInfo = this.props.memberInfo;

    return !this.props.isLoading ? (
      this.props.isMemberMode ? (
        this.renderMemberList(memberInfo)
      ) : (
        this.renderMemberModeList(memberInfo)
      )
    ) : (
      <FadeLoader
        color={color}
        loading={this.props.isLoading}
        css={override}
        size={10}
      ></FadeLoader>
    );
  }
}

export default withSnackbar(MembersList);
