import React, { Component } from "react";
import "./SignUp.scss";

import UserInfo from "./UserInfo/UserInfo";
import UserInterest from "./UserInterest/UserInterest";

import CompleteSignUp from "./CompleteSignUp/CompleteSignUp";
import axios from "axios";
import { withSnackbar } from "notistack";
import { SignUp_GAEvent } from "../modules/gaEvents";
class SignIn extends Component {
  state = {
    step: 1,
    email: "",
    password: "",
    userNickName: "",
    userBirthDay: "",
    userInterest: [],
    terms_agreement: false,
    countryInfo: {
      countryCode: "",
      countryName: "",
    },
    personal_agreement: false,
    email_notification_agreement: false,
    socialId: "",
    type: "",
    signupType: false,
  };
  timer = null;
  Seconds = new Date().getSeconds();
  componentDidMount = () => {
    SignUp_GAEvent("render");
    this.timer = setInterval(this.isStaySignUp, 1000);
  };
  componentWillUnmount = () => {
    clearInterval(this.timer);
  };
  isStaySignUp = () => {
    let sttSeconds = new Date().getSeconds();
    const endSeconds = this.Seconds + 3;
    if (sttSeconds === endSeconds) {
      SignUp_GAEvent("stay");
      clearInterval(this.timer);
    }
  };

  userInfoSet = (value) => {
    this.setState(
      {
        email: value.email,
        password: value.password,
        userNickName: value.userNickName,
        countryInfo: {
          countryCode: value.countryCode,
          countryName: value.countryName,
        },
        userBirthDay: value.userBirthDay,
        terms_agreement: value.terms_agreement,
        personal_agreement: value.personal_agreement,
        email_notification_agreement:
          value.email_notification_agreement || false,
        socialId: value.socialId,
        type: value.type,
      },
      () => this.toNextStep()
    );
  };

  infoSubmit = async (value) => {
    this.setState({ userInterest: value }, async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/auth/signup`,
          {
            email: this.state.email,
            userName: this.state.userNickName,
            password: this.state.password,
            countryInfo: {
              countryName: this.state.countryInfo.countryName,
              countryCode: this.state.countryInfo.countryCode,
            },
            terms_agreement: this.state.terms_agreement,
            personal_agreement: this.state.personal_agreement,
            email_notification_agreement:
              this.state.email_notification_agreement,
            birthDay: this.state.userBirthDay,
            classification: this.state.userInterest,
            socialId: this.state.socialId,
            type: this.state.type,
          }
        );

        this.toNextStep();
      } catch (e) {
        console.dir(e);
      }
    });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  toNextStep = () => {
    if (this.state.step === 3) {
      // alert("마지막 스텝입니다");
      this.handleAlert();
    } else {
      this.setState({ step: this.state.step + 1 }, () => {
        if (this.state.step === 3) SignUp_GAEvent("complete");
      });
    }
  };

  renderSignUp = () => {
    if (this.state.step === 1) {
      return (
        <UserInfo
          toNextStep={this.toNextStep}
          history={this.props.history}
          userInfoSet={this.userInfoSet}
        />
      );
    }

    if (this.state.step === 2) {
      return (
        <UserInterest
          toNextStep={this.toNextStep}
          infoSubmit={this.infoSubmit}
        />
      );
    }

    if (this.state.step === 3) {
      return <CompleteSignUp history={this.props.history} />;
    }
  };

  render() {
    return this.renderSignUp();
  }
}

export default withSnackbar(SignIn);
