import React, { Component } from "react";
import "./CreateTeam.scss";
import AddInfo from "./AddInfo/AddInfo";
import AddMember from "./AddMember/AddMember";
import SuccessPage from "../CreateSuccess/CreateSuccess";
import i18n from "../../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CreateTeamId, CreateTeamInfoSet } from "../../Api/Team/Team";
import { withSnackbar } from "notistack";
import { TeamCreate_GAEvent } from "../../modules/gaEvents";
class CreateTeam extends Component {
  state = {
    isLoading: false,
    menuList: [
      {
        id: 1,
        menu: i18n.t("team.create.step_1"),
      },

      {
        id: 2,
        menu: i18n.t("team.create.step_2"),
      },
    ],
    preview: "",
    menu: 1,
    teamPhoto: "",
    teamName: "",
    teamPage: "",
    snsUrl: "",
    countryInfo: {
      countryName: "",
      countryCode: "",
      region: "",
    },
    members: [],

    urlOverlab: true,
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleNextStep = () => {
    if (this.state.menu === 3) {
      // alert("마지막스텝");
      this.handleAlert();
    } else if (this.state.menu === 2) {
      this.handleCreateTeam();
    } else {
      this.setState({ menu: this.state.menu + 1 });
    }
  };
  dataNecessaryCheck = () => {
    if (
      this.state.teamName &&
      this.state.teamPage &&
      this.state.urlOverlab &&
      this.state.countryInfo.countryCode &&
      this.state.countryInfo.countryName &&
      this.state.countryInfo.region
    ) {
      return true;
    } else return false;
  };
  handleSetInfo = (data) => {
    this.setState(
      {
        teamName: data.teamName,
        teamPage: data.teamPageUrl,
        snsUrl: data.snsUrl,
        countryInfo: {
          countryName: data.countryName,
          countryCode: data.countryCode,
          region: data.region,
        },
        teamPhoto: data.file,
        preview: data.profilePhoto,

        urlOverlab: data.urlOverlab,
      },
      () => this.dataNecessaryCheck()
    );
  };
  componentDidMount = () => {
    TeamCreate_GAEvent("render");
    this.dataNecessaryCheck();
  };
  setMembers = (members) => {
    this.setState({ members });
  };
  handleMemberRemove = (members) => {
    // console.log("멤버 삭제", id);
    // let members = [...this.state.members];
    // console.log(members);
    // members = members.filter((item) => item._id !== id);
    this.setState({ members });
  };
  handleCreateTeam = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await CreateTeamId();
      this.handleTeamInfoSend(response);
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };
  handleTeamInfoSend = async (teamId) => {
    try {
      await CreateTeamInfoSet(
        teamId,
        this.state.teamName,
        this.state.teamPage,
        this.state.snsUrl,
        this.state.teamPhoto,
        this.state.countryInfo.countryName,
        this.state.countryInfo.countryCode,
        this.state.countryInfo.region,
        this.state.members
      );

      this.setState({ isLoading: false, menu: this.state.menu + 1 });
    } catch (e) {
      this.setState({ isLoading: false });
      console.dir(e);
    }
  };

  handleBackStep = () => {
    if (this.state.menu === 1) {
      this.props.history.push("/team/list");
    } else {
      this.setState({ menu: this.state.menu - 1 });
    }
  };
  infoSet = () => {
    return this.state;
  };

  toTeamMain = () => {
    this.props.history.push(`/team/@${this.state.teamPage}`);
  };
  renderMenu = () => {
    if (this.state.menu === 1) {
      return (
        <AddInfo handleSetInfo={this.handleSetInfo} infoSet={this.infoSet} />
      );
    } else if (this.state.menu === 2) {
      return (
        <AddMember
          setMembers={this.setMembers}
          handleMemberRemove={this.handleMemberRemove}
        />
      );
    } else if (this.state.menu === 3) {
      return <SuccessPage teamPhoto={this.state.preview} />;
    }
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
        <div className="container createTeam">
          <div className="createTeamBox">
            {this.state.menu !== 3 ? (
              <div className="createTeam-menuStep">
                {this.state.menuList.map((item) => {
                  return (
                    <span
                      className={`menuStep-item 
                 ${this.state.menu === item.id ? "selectedMenu" : ""}`}
                    >
                      {item.menu}
                    </span>
                  );
                })}
              </div>
            ) : null}

            {this.renderMenu()}
            <div className="createTeamBtns">
              {this.state.menu === 1 ? (
                <>
                  <button onClick={this.handleBackStep}>
                    {i18n.t("global.back")}
                  </button>
                  <button
                    className={`${
                      this.dataNecessaryCheck() ? "" : "disabled-button"
                    }`}
                    onClick={
                      this.dataNecessaryCheck() ? this.handleNextStep : null
                    }
                  >
                    {i18n.t("global.next")}
                  </button>
                </>
              ) : this.state.menu === 2 ? (
                <>
                  <button onClick={this.handleBackStep}>
                    {i18n.t("global.back")}
                  </button>
                  <button
                    type="button"
                    className="inviteBtn"
                    onClick={this.handleNextStep}
                  >
                    {this.state.members &&
                    this.state.members.length !== 0 &&
                    this.state.members[0]
                      ? i18n.t("team.create.btn1")
                      : i18n.t("건너뛰기")}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="teamPageBtn"
                    type="button"
                    onClick={this.toTeamMain}
                  >
                    {i18n.t("teamPage.success.button")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default withSnackbar(CreateTeam);
