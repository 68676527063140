import React, { Component } from "react";
import "./UserInfo.scss";

import TermsDialog from "../TermsDialog/TermsDialog";
import CountrySelect from "./CountrySelect";
import KakaoIcon from "../../image/join-sns-icon-1.svg";
import NaverIcon from "../../image/join-sns-icon-2.svg";
import GoogleIcon from "../../image/join-sns-icon-3.svg";
import FacebookIcon from "../../image/Snsicon/join-sns-icon-4.svg";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import i18n from "../../i18n";
import PersonalDialog from "../PersonalDialog/PersonalDialog";
import ContentDialog from "../../Component/ContentDialog/ContentDialog";
import { openSignInWindow } from "../../Component/HandlePopup/Handlepopup";
import moment from "moment";
import { connect } from "react-redux";
import {
  changeLoginStatus,
  changeType,
  getMyInfo,
  setIsOut,
} from "../../Redux/action/index";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { GetMyInfo } from "../../Api/User/user";
import { EmailExistsCheck } from "../../Api/Account/account";
import { withSnackbar } from "notistack";
import { compose } from "redux";
class UserInfo extends Component {
  state = {
    pwMsg: "",
    eamilMsg: "",
    nickMsg: "",
    birthMsg: "",
    emailCheck: null,
    nickCheck: null,
    birthDateValid: false,
    nextBtnActive: false,
    emailRedu: null,
    termsDialogOpen: false,
    personalDialogOpen: false,
    contentDialogOpen: false,
    isCapsLockOn: false,
    socialLogin: false,
    terms_agreement: false,
    personal_agreement: false,
    email_notification_agreement: false,
    type: "",
    email: "",
    pwValid: false,
    pwSucess: false,
    password: "",
    confirmPassword: "",
    userNickName: "",
    userBirthDay: "",
    socialId: "",
    // type: "",
    countryName: "",
    countryCode: "",
    minAge: 14,
    selecCoutry: [],
    isLoading: false,
    fbSignUp: false,
  };

  constructor(props) {
    super(props);

    // 현재 날짜 가져오기
    let maxDate = moment()
      .subtract(this.state.minAge, "years")
      .format("YYYY-MM-DD");
    let minDate = moment().subtract(110, "years").format("YYYY-MM-DD");

    this.state = {
      maxDate: maxDate,
      minDate: minDate,
    };
  }

  componentDidMount() {
    console.log("sss");
    this.setState({ isLoading: false });
    this.getGeoInfo();
  }

  // 위치 정보 가져오기
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        this.setState({
          countryName: data.country_name,
          countryCode: data.country_code,

          //국가 코드 KR (한국)이면 최소 가입 가능 나이 14세로 설정 그외 국가 13세
          minAge: data.country_code === "KR" ? 14 : 13,
        });

        // 최대 생일설정 가능일
        let maxDate = moment()
          .subtract(this.state.minAge, "years")
          .format("YYYY-MM-DD");
        this.setState({
          maxDate: maxDate,
        });
      })
      .catch((error) => {
        console.dir(error);
      });
  };

  //userInfoFuntion
  handleTermsAgree = (e) => {
    if (e.target.checked) {
      this.setState({ terms_agreement: true }, () =>
        this.handleUserInfoCheck()
      );
    } else {
      this.setState({ terms_agreement: false }, () =>
        this.handleUserInfoCheck()
      );
    }
  };
  handlePersonalAgree = (e) => {
    if (e.target.checked) {
      this.setState({ personal_agreement: true }, () =>
        this.handleUserInfoCheck()
      );
    } else {
      this.setState({ personal_agreement: false }, () =>
        this.handleUserInfoCheck()
      );
    }
  };
  handleEmailNotifiAgree = (e) => {
    if (e.target.checked) {
      this.setState({ email_notification_agreement: true });
    } else {
      this.setState({ email_notification_agreement: false });
    }
  };

  handleDetectCapsLock = (e) => {
    if (e.getModifierState("CapsLock") && !this.state.isCapsLockOn) {
      this.setState({ isCapsLockOn: true });
    } else if (!e.getModifierState("CapsLock") && this.state.isCapsLockOn) {
      this.setState({ isCapsLockOn: false });
    }
  };
  handlechangeLoginStatus = async (value) => {
    await this.props.changeLoginStatus(value);
    this.props.history.push("/");
  };

  handleUserInfoSet = async (value) => {
    await this.props.userInfoSet(value);
  };

  handleUserInfoCheck = () => {
    if (
      !this.state.emailRedu &&
      this.state.emailCheck &&
      this.state.nickCheck &&
      this.state.pwValid &&
      this.state.pwSucess &&
      this.state.birthDateValid &&
      this.state.personal_agreement &&
      this.state.terms_agreement
    ) {
      this.setState({ nextBtnActive: true });
    } else {
      this.setState({ nextBtnActive: false });
    }
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleTermsDialogClose = () => {
    this.setState({ termsDialogOpen: false });
  };
  handlePersonalDialogClose = () => {
    this.setState({ personalDialogOpen: false });
  };
  handleContentDialogClose = () => {
    this.setState({ contentDialogOpen: false });
  };
  handleSocialEmailCheck = async (email) => {
    try {
      if (email[0].value === undefined) {
        const response = await EmailExistsCheck(email);
        if (!response) {
          this.setState({ socialLogin: true });
        } else if (response === "local") {
          this.setState({ socialLogin: false });
          // alert(
          //   `이미 ${email} 로 가입된 모션트렌드 계정이 있습니다. 이메일 로그인을 시도해 주세요`
          // );
          this.handleAlert(
            `이미 ${email} 로 가입된 모션트렌드 계정이 있습니다. 이메일 로그인을 시도해 주세요`
          );
        } else if (response !== "local") {
          this.setState({ socialLogin: false });
          // alert(
          //   `${email}계정이 이미 있습니다.${response}간편 로그인을 이용해 주세요!`
          // );
          this.handleAlert(
            `${email}계정이 이미 있습니다.${response}간편 로그인을 이용해 주세요!`
          );
        }
      } else if (email[0].value) {
        const response = await EmailExistsCheck(email[0].value);
        if (!response) {
          this.setState({ socialLogin: true });
        } else if (response === "local") {
          this.setState({ socialLogin: false });

          this.handleAlert(
            `이미 ${email[0].value} 로 가입된 모션트렌드 계정이 있습니다. 이메일 로그인을 시도해 주세요`
          );
        } else if (response !== "local") {
          this.setState({ socialLogin: false });

          this.handleAlert(
            `${email[0].value}계정이 이미 있습니다.${response}간편 로그인을 이용해 주세요!`
          );
        }
      }
    } catch (e) {
      console.dir(e.response);
      if (email) {
        this.setState({ socialLogin: false, isLoading: false });
      }
    }
  };
  handleSignUpSuccess = async (data) => {
    if (typeof data.info === "string") {
      data.info = JSON.parse(data.info);
    }
    return new Promise(async (resolve, reject) => {
      this.setState({ isLoading: true });
      try {
        resolve();
        if (!data.token) {
          await this.handleSocialEmailCheck(data.info.email);
          if (this.state.socialLogin && data.info.email !== undefined) {
            this.setState({
              email:
                data.info.type === "facebook"
                  ? data.info.email[0].value
                  : data.info.email,
              userName: data.info.userName,
              socialId: data.info.socialId,
              type: data.info.type,
              pwValid: true,
              pwSucess: true,
              emailCheck: true,
              isLoading: false,
            });
          } else if (
            data.info.type === "facebook" &&
            data.info.email === undefined
          ) {
            this.setState({
              socialLogin: true,
              fbSignUp: true,
              userName: data.info.userName,
              socialId: data.info.socialId,
              type: data.info.type,
              pwValid: true,
              pwSucess: true,
              emailCheck: true,
              isLoading: false,
            });
          } else {
            this.setState({
              userName: "",
              socialId: "",
              type: "",
              pwValid: false,
              pwSucess: false,
              emailCheck: false,
              isLoading: false,
            });
            return;
          }
          return;
        } else {
          const userInfoResult = await GetMyInfo();
          this.setState({ isLoading: false }, () => {
            this.handlechangeLoginStatus(true);
            this.props.getMyInfo(userInfoResult);
            this.props.history.push("/");
          });
        }
      } catch (e) {
        reject();
        this.setState({ isLoading: false });
        console.dir(e);
      }
    });
  };
  handlePasswordInput = (e) => {
    const password = e.target.value.trim();
    const passwordLowerRegex = /[a-z]/;
    const passwordUpperRegex = /[A-Z]/;
    const passwordNumberRegex = /[0-9]/;
    const passwordSpcialRegex = /[~!@#$%^&*()_+|<>?:{}]/;
    if (this.state.isCapsLockOn) {
      this.setState({
        password,
        pwValid: false,
        pwSucess: false,
        pwMsg: i18n.t("userInfo.passwordCapsLock"),
      });
    } else if (password.length === 0) {
      this.setState({
        password,
        pwValid: false,
        pwSucess: false,
        pwMsg: i18n.t("userInfo.passwordInput"),
      });
    } else if (password.length < 8 || password.length > 32) {
      this.setState({
        password,
        pwValid: false,
        pwSucess: false,
        pwMsg: i18n.t("userInfo.passwordLength"),
      });
    } else if (
      !passwordLowerRegex.test(password) ||
      !passwordUpperRegex.test(password) ||
      !passwordNumberRegex.test(password) ||
      !passwordSpcialRegex.test(password)
    ) {
      this.setState({
        password,
        pwValid: false,
        pwSucess: false,
        pwMsg: i18n.t("userInfo.passwordCondition"),
      });
    } else {
      this.setState(
        {
          password,
          pwValid: true,
          pwSucess: false,
          pwMsg: "",
        },
        () => {
          this.handleChangePasswordWithConfirm();
          // this.handleUserInfoCheck();
        }
      );
    }
  };

  handleChangePasswordWithConfirm = () => {
    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;

    if (
      this.state.confirmPassword === undefined ||
      this.state.confirmPassword === ""
    ) {
      return;
    } else if (!this.state.pwValid || password !== confirmPassword) {
      this.setState(
        {
          pwSucess: false,
          pwMsg: i18n.t("userInfo.confirmPassword"),
        },
        () => this.handleUserInfoCheck()
      );
    } else {
      this.setState(
        {
          pwSucess: true,
          pwMsg: i18n.t("all.signup.Available"),
        },

        () => this.handleUserInfoCheck()
      );
    }
  };

  handleChangeConfirmPW = (e) => {
    const password = this.state.password;
    const confirmPassword = e.target.value.trim();

    if (password !== confirmPassword && !this.state.pwValid) {
      this.setState(
        {
          confirmPassword,
          pwSucess: false,
          pwMsg: i18n.t("userInfo.confirmPassword"),
        },
        () => {
          this.handleUserInfoCheck();
        }
      );
    } else {
      this.setState(
        {
          confirmPassword,
          pwSucess: true,
          pwMsg: i18n.t("all.signup.Available"),
        },
        () => {
          this.handleUserInfoCheck();
        }
      );
    }
  };

  handleChangeUserNickName = (e) => {
    const nickName = e.target.value.trim();
    const nickSpcialRegex = /[~!@#$%^&*()+|<>?:{}]/; // 특수문자

    // const nickSpcialRegex = /_-/;
    if (nickName.length === 0) {
      this.setState(
        {
          nickCheck: false,
          userNickName: nickName,
          nickMsg: i18n.t("userInfo.nickName"),
        },
        () => this.handleUserInfoCheck()
      );
    } else if (nickSpcialRegex.test(nickName)) {
      this.setState(
        {
          nickCheck: false,
          userNickName: nickName,
          nickMsg: i18n.t("userInfo.nickName.invalid"),
        },
        () => this.handleUserInfoCheck()
      );
    } else if (nickName.length < 1 || nickName.length > 20) {
      this.setState(
        {
          nickCheck: false,
          userNickName: nickName,
          nickMsg: i18n.t("userInfo.nickNameCondition"),
        },
        () => this.handleUserInfoCheck()
      );
    } else {
      this.setState(
        {
          nickCheck: true,
          userNickName: nickName,
          nickMsg: i18n.t("all.signup.Available"),
        },
        () => this.handleUserInfoCheck()
      );
    }
    this.setState({ userNickName: e.target.value });
  };
  handleChangeEmail = (e) => {
    const emailRule =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailRule2 =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const email = e.target.value.trim();
    if (email.length === 0) {
      this.setState(
        {
          emailCheck: false,
          email: email,
          eamilMsg: i18n.t("userInfo.emailInput"),
        },
        () => this.handleUserInfoCheck()
      );
    } else if (!emailRule.test(email) || !emailRule2.test(email)) {
      this.setState(
        {
          emailCheck: false,
          email: email,
          eamilMsg: i18n.t("userInfo.emailCondition"),
        },
        () => this.handleUserInfoCheck()
      );
    } else {
      this.setState(
        {
          emailCheck: true,
          email: email,
          eamilMsg: "",
        },
        () => this.handleUserInfoCheck()
      );
    }
  };

  // 나이 계산 함수
  calculate_age = (date) => {
    var today = new Date();
    var birthDate = new Date(date);

    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    return age_now;
  };
  handleBirthDayMsg = (e) => {
    this.setState({ userBirthDay: e.target.value }, () => {
      if (this.state.userBirthDay.length === 0) {
        this.setState(
          {
            birthMsg: `생년월일을 바꿔주세요`,
            birthDateValid: false,
          },
          () => this.handleUserInfoCheck()
        );
      }
    });
  };

  handleChangeBirth = (e) => {
    this.setState({ userBirthDay: e.target.value }, () => {
      let minAge = this.state.minAge;
      if (
        this.calculate_age(
          moment(this.state.userBirthDay, "YYYY-MM-DD").format("YYYY-MM-DD")
        ) < this.state.minAge
      ) {
        this.setState(
          {
            birthMsg: i18n.t("all.signup.minage.alert", { minAge }),
            birthDateValid: false,
          },
          () => this.handleUserInfoCheck()
        );
      } else if (
        this.calculate_age(
          moment(this.state.userBirthDay, "YYYY-MM-DD").format("YYYY-MM-DD")
        ) >= 110
      ) {
        this.setState(
          {
            birthMsg: i18n.t("all.signup.maxage.alert", { minAge }),
            birthDateValid: false,
          },
          () => this.handleUserInfoCheck()
        );
      } else {
        this.setState(
          {
            birthMsg: "",
            birthDateValid: true,
          },
          () => this.handleUserInfoCheck()
        );
      }
    });
  };

  handleChangeCountry = (e) => {
    this.setState({
      countryCode: e.value,
      countryName: e.label,
    });
  };

  handleEmailCheck = async () => {
    if (this.state.emailCheck) {
      try {
        const response = await EmailExistsCheck(this.state.email);

        if (response === "local") {
          this.setState({
            emailRedu: true,
            eamilMsg: i18n.t("userInfo.emailCheck"),
          });
        } else if (response !== "local" && response) {
          this.setState(
            {
              emailRedu: true,
              eamilMsg: i18n.t("userInfo.emailCheck"),
            },
            () => {
              // alert(
              //   `${this.state.email}계정이 이미 있습니다.${response}간편 로그인을 이용해 주세요!`
              // );
              this.handleAlert(
                `${this.state.email}계정이 이미 있습니다.${response}간편 로그인을 이용해 주세요!`
              );
            }
          );

          return;
        } else {
          this.setState({
            emailRedu: false,
            eamilMsg: i18n.t("all.signup.Available"),
          });
        }
      } catch (e) {
        console.dir(e.response);
      }
    } else {
      this.setState({
        emailRedu: true,
        eamilMsg: "이메일을 먼저 확인해 주세요",
      });
    }
  };

  handleSignupStatus = (type) => {
    if (type === "facebook") {
      this.setState({
        isExistFacebook: true,
      });
    } else if (type === "google") {
      this.setState({
        isExistGoogle: true,
      });
    } else if (type === "kakao") {
      this.setState({
        isExistKakao: true,
      });
    } else if (type === "naver") {
      this.setState({
        isExistNaver: true,
      });
    }
  };
  handleNaverSignup = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/naver-signup`,
      null,
      this.props.history,
      this.handleSignUpSuccess,
      this.handleSignupStatus
    );
  };
  handleGoogleSignup = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/google-signup`,
      null,
      this.props.history,
      this.handleSignUpSuccess,
      this.handleSignupStatus
    );
  };

  handleKakaoSignup = () => {
    this.handleAlert("준비중 입니다.");
  };

  handleFacebookSignup = () => {
    openSignInWindow(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/facebook-signup`,
      null,
      this.props.history,
      this.handleSignUpSuccess,
      this.handleSignupStatus
    );
  };

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    return (
      <>
        <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
          <TermsDialog
            open={this.state.termsDialogOpen}
            onClose={this.handleTermsDialogClose}
          />
          <PersonalDialog
            open={this.state.personalDialogOpen}
            onClose={this.handlePersonalDialogClose}
          ></PersonalDialog>
          <ContentDialog
            open={this.state.contentDialogOpen}
            title={i18n.t("all.signup.notice.birthday.title")}
            msg={i18n.t("all.signup.notice.birthday.msg")}
            onClose={this.handleContentDialogClose}
          />
          <div className="join-container">
            <div className="userInfo-container">
              <div className="userInfo-header">
                {!this.state.socialLogin ? (
                  <span>{i18n.t("all.signup.mainTitle")}</span>
                ) : (
                  <span>{i18n.t("추가 정보 입력")}</span>
                )}
              </div>

              <div className="userInfo-box">
                {!this.state.socialLogin ? (
                  <div className="loginBtns">
                    <button type="button" onClick={this.handleKakaoSignup}>
                      <img src={KakaoIcon} alt="Kakao" />
                      {i18n.t("global.kakao")}
                    </button>
                    <button type="button" onClick={this.handleNaverSignup}>
                      <img src={NaverIcon} alt="Naver" />
                      {i18n.t("global.naver")}
                    </button>
                    <button type="button" onClick={this.handleGoogleSignup}>
                      <img src={GoogleIcon} alt="Google" />
                      {i18n.t("global.google")}
                    </button>
                    <button type="button" onClick={this.handleFacebookSignup}>
                      <img src={FacebookIcon} alt="Facebook" />
                      {i18n.t("global.facebook")}
                    </button>
                  </div>
                ) : null}
                {!this.state.socialLogin ? (
                  <div className="userInfo-email column">
                    <span>{i18n.t("all.signup.email")}</span>
                    <div className="emailBox rowSignUp">
                      <input
                        type="email"
                        value={this.state.email}
                        placeholder={i18n.t("all.signup.email")}
                        name="userEmail"
                        autoComplete="off"
                        onChange={this.handleChangeEmail}
                      />

                      <button
                        id="emailBtn"
                        className={
                          this.state.emailCheck ? "checkBox" : "unCheckBox"
                        }
                        type="button"
                        onClick={this.handleEmailCheck}
                      >
                        {i18n.t("global.check")}
                      </button>
                    </div>
                    <span
                      style={{
                        color:
                          this.state.emailCheck && !this.state.emailRedu
                            ? "green"
                            : "#ff2e43",
                      }}
                      id="notInputText"
                    >
                      {this.state.eamilMsg}
                    </span>
                  </div>
                ) : this.state.socialLogin && this.state.fbSignUp ? (
                  <div className="userInfo-email column">
                    <span>{i18n.t("all.signup.email")}</span>
                    <div className="emailBox rowSignUp">
                      <input
                        type="email"
                        value={this.state.email}
                        placeholder={i18n.t("all.signup.email")}
                        name="userEmail"
                        autoComplete="off"
                        onChange={this.handleChangeEmail}
                      />

                      <button
                        id="emailBtn"
                        className={
                          this.state.emailCheck ? "checkBox" : "unCheckBox"
                        }
                        type="button"
                        onClick={this.handleEmailCheck}
                      >
                        {i18n.t("global.check")}
                      </button>
                    </div>
                    <span
                      style={{
                        color:
                          this.state.emailCheck && !this.state.emailRedu
                            ? "green"
                            : "#ff2e43",
                      }}
                      id="notInputText"
                    >
                      {this.state.eamilMsg}
                    </span>
                  </div>
                ) : null}
                {!this.state.socialLogin ? (
                  <div className="userInfo-password column">
                    <span>{i18n.t("all.signup.password")}</span>

                    <>
                      <input
                        type="password"
                        placeholder={i18n.t("all.signup.password")}
                        name="userPW"
                        autoComplete="off"
                        onChange={this.handlePasswordInput}
                        onKeyDown={this.handleDetectCapsLock}
                      />
                      <input
                        type="password"
                        placeholder={i18n.t("all.signup.confirmPW")}
                        name="userConfirmPW"
                        autoComplete="off"
                        onChange={this.handleChangeConfirmPW}
                      />
                    </>

                    <span
                      style={{
                        color: this.state.pwSucess ? "green" : "#ff2e43",
                      }}
                      id="notInputText"
                    >
                      {this.state.pwMsg}
                    </span>
                  </div>
                ) : null}
                <div className="userInfo-name column">
                  <span>{i18n.t("indivi.signup.name")}</span>
                  <div className="nameBox rowSignUp">
                    <input
                      type="text"
                      id="nickNameCheckBox"
                      placeholder={i18n.t("indivi.signup.name")}
                      name="userName"
                      value={this.state.userNickName}
                      onChange={this.handleChangeUserNickName}
                    />
                  </div>
                  <span
                    style={{
                      color: this.state.nickCheck ? "green" : "#ff2e43",
                    }}
                    id="notInputText"
                  >
                    {this.state.nickMsg}
                  </span>
                </div>
                <div className="birthDayBox column">
                  <div className="birthDayBox_Wrap">
                    <span>{i18n.t("indivi.signup.birthDate")}</span>
                    <div className="birthDayBox_RightBox">
                      <HelpOutlineOutlinedIcon
                        className="icon"
                        fontSize="small"
                        onClick={() => {
                          this.setState({
                            contentDialogOpen: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    // value={this.getCurrentDate}
                    max="9999-12-31"
                    onMouseEnter={(e) => {
                      e.target.type = "date";
                      e.target.value = e.target.value || this.state.maxDate;
                    }}
                    onMouseOver={this.handleChangeBirth}
                    // onBlur={
                    //   (e)=> {
                    //     e.target.type = "text";
                    //   }
                    // }
                    placeholder="YYYY-MM-DD"
                    onChange={this.handleChangeBirth}
                  ></input>
                  <span
                    style={{
                      color: this.state.birthDateValid ? "green" : "#ff2e43",
                    }}
                    id="notInputText"
                  >
                    {this.state.birthMsg}
                  </span>
                </div>
                <div className="countrySelect column">
                  <div className="countrySelect signUpRow">
                    <span>{i18n.t("indivi.signup.countrySelect")}</span>
                  </div>
                  <CountrySelect
                    countryCode={this.state.countryCode}
                    handleChangeCountry={this.handleChangeCountry}
                  />
                </div>

                <div className="popupBtn">
                  <span></span>
                  <div className="popupBtn-text">
                    <div className="popupBtn-check">
                      <input
                        type="checkbox"
                        id="agreementCheck"
                        value="Agreement"
                        onChange={this.handleTermsAgree}
                      />
                      <label htmlFor="agreementCheck">
                        <span></span>
                        <p>{i18n.t("all.signup.necessarily")}</p>{" "}
                        {i18n.t("all.signup.conditions")}
                      </label>
                    </div>
                    <span
                      className="termsView"
                      onClick={() => {
                        this.setState({
                          termsDialogOpen: true,
                        });
                      }}
                    >
                      {i18n.t("all.signup.view")}
                    </span>
                  </div>
                  <div className="popupBtn-text">
                    <div className="popupBtn-check">
                      <input
                        type="checkbox"
                        id="termCheck"
                        value="Agreement"
                        onChange={this.handlePersonalAgree}
                      />
                      <label htmlFor="termCheck">
                        <span></span>
                        <p>{i18n.t("all.signup.necessarily")}</p>{" "}
                        {i18n.t("all.signup.personal")}
                      </label>
                    </div>
                    <span
                      className="termsView"
                      onClick={() => {
                        this.setState({
                          personalDialogOpen: true,
                        });
                      }}
                    >
                      {i18n.t("all.signup.view")}
                    </span>
                  </div>
                  <div className="popupBtn-text">
                    <div className="popupBtn-check">
                      <input
                        type="checkbox"
                        id="choiceCheck"
                        value="Agreement"
                        onChange={this.handleEmailNotifiAgree}
                      />
                      <label htmlFor="choiceCheck">
                        <span></span>
                        {i18n.t("all.signup.choice")}{" "}
                        {i18n.t("all.signup.userInfo")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <button
                id="submitBtn"
                type="button"
                className={!this.state.nextBtnActive ? "active" : null}
                onClick={
                  this.state.nextBtnActive
                    ? () => this.handleUserInfoSet(this.state)
                    : null
                }
              >
                <span>{i18n.t("global.next")}</span>
              </button>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isOut: state.isOut,
  lang: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
  setIsOut: (value) => dispatch(setIsOut(value)),
  changeLoginStatus: (value) => dispatch(changeLoginStatus(value)),
  changeType: (value) => dispatch(changeType(value)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(UserInfo);
