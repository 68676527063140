import ReactGA from "react-ga";

interface TrackPageViewParams {
  path: string;
}

interface TrackEventParams {
  category: string;
  action: string;
  value?: number;
  label?: string;
}

interface TrackLSignUpSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Rendered Sign Up"
    | "Stay Sign Up"
    | "Sign Up Complete"
    | "Login Success";
}

interface TrackProjectSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Project Viewed"
    | "Like Button Clicked"
    | "Un Like Button Clicked"
    | "Collection Button Clicked"
    | "Collection Save"
    | "Share Mobal Opened"
    | "Write Comment";
}

interface TrackUploadSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Rendered Project Upload Page"
    | "Project Upload"
    | "Project Draft Save";
}
interface TrackJobMainSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action: "Job Page Viewed" | "Job Content Searching";
}

interface TrackJobDetailSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Job Detail Viewed"
    | "Job Detail Bookmark"
    | "Job Detail Share Open"
    | "Job Detail Apply";
}

interface TrackExistingTeamSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Rendered Team Participation Section"
    | "Team Participation Application Completed";
}

interface TrackCreateTeamSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action:
    | "Rendered Team Create Section"
    | "Rendered Team Invite Member Section"
    | "Team Create Success";
}

interface TrackTeamListSectionEventParams
  extends Omit<TrackEventParams, "category"> {
  action: "Rendered Team List Section";
}

class GAService {
  private env: "development" | "production" | "test";

  constructor() {
    if (!process.env.REACT_APP_GA_TRACKING_ID) {
      throw new Error("GA_TRACKING_ID must be provided.");
    }
    this.env = process.env.NODE_ENV;
    if (this.isProduction) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    }
  }

  private get isProduction() {
    return this.env === "production";
  }

  public trackPageView({ path }: TrackPageViewParams) {
    const decodedPath = decodeURIComponent(path);
    if (!this.isProduction) {
      console.log(`Page Viewed: ${decodedPath}`);
      return;
    }

    ReactGA.set({ page: path });
    ReactGA.pageview(decodedPath);
  }

  private trackEvent(params: TrackEventParams) {
    if (!this.isProduction) {
      return;
    }

    ReactGA.event(params);
  }

  public trackExistingTeamEvent(params: TrackExistingTeamSectionEventParams) {
    this.trackEvent({ category: "Team Page", ...params });
  }

  public trackCreateTeamEvent(params: TrackCreateTeamSectionEventParams) {
    this.trackEvent({ category: "Team Page", ...params });
  }

  public trackTeamListEvent(params: TrackTeamListSectionEventParams) {
    this.trackEvent({ category: "Team Page", ...params });
  }
  
  public trackJobDetailEvent(params: TrackJobDetailSectionEventParams) {
    this.trackEvent({ category: "Job Detail", ...params });
  }

  public trackJobMainEvent(params: TrackJobMainSectionEventParams) {
    this.trackEvent({ category: "Job Main", ...params });
  }

  public trackSignUpEvent(params: TrackLSignUpSectionEventParams) {
    this.trackEvent({ category: "SignUp", ...params });
  }

  public trackProjectSectionEvent(params: TrackProjectSectionEventParams) {
    this.trackEvent({ category: "Project Detail", ...params });
  }

  public trackProjectUploadSectionEvent(params: TrackUploadSectionEventParams) {
    this.trackEvent({ category: "Project Upload", ...params });
  }
}

export const GA = new GAService();
