import React, { Component } from "react";
import "./CalendarDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../i18n";
import Button from "../../../Component/Button/Button";
// import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";

class CalenderDialog extends Component {
  state = {};
  today = new Date();

  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"calendar-dialog"}
          open={open}
          onClose={onClose}
        >
          <div className="calendar-container">
            <div className="calendar-header">
              <span>{i18n.t("calendar.title")}</span>
            </div>
            <div className="calendar-form">
              <Calendar
                locale={i18n.language}
                onChange={this.props.handleDateChange}
                // minDate={
                //   this.props.endDate ? new Date(this.props.endDate) : new Date()
                // }
                minDate={new Date()}
                value={new Date(this.props.endDate)}
                // disabledDate={this.disabledStartDate}
                // // value={this.state.period.strDate}
                // useWeekdaysShort={true}
                // shouldCloseOnSelect={false}
                // showToday={true}
              />
            </div>
            <div className="calendar-btns">
              <Button
                backColor="white"
                btnClick={onClose}
                text={i18n.t("global.cancel")}
              />
              <Button
                btnClick={this.props.handleSetPeriod}
                text={i18n.t("calender.btn")}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default CalenderDialog;
