import axios from "axios";

import { GetUserToken } from "../../../modules/userAuth";

export const GetFlagList = async () => {
  const token = GetUserToken();
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/flag/list`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};
export const PostWithDrawalFlag = async (projectId, flagIds, message) => {
  let body = {
    projectId,
    flagIds,
    message,
  };
  const token = GetUserToken();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/admin/pick/withDrawal`,
    body,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};
export const PostCurate = async (projectId, flagIds) => {
  let body = {
    projectId,
    flagIds,
  };
  const token = GetUserToken();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/admin/pick/curate`,
    body,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};
export const GetCurationtList = async (
  limit,
  skip,
  categories,
  strDate,
  endDate,
  order,
  isASC
) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/pick/curated?limit=${limit}&skip=${skip}`;
  const token = GetUserToken();
  for (let i = 0; i < categories.length; i++) {
    url += `&categorys[${i}]=${categories[i]}`;
  }
  if (strDate) {
    url += `&strDate=${strDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }
  if (order) {
    url += `&order=${order}`;
  }
  if (isASC) {
    url += `&isASC=${isASC}`;
  }
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data.list;
};
export const GetUnCurationtList = async (
  limit,
  skip,
  categories,
  strDate,
  endDate,
  order,
  isASC
) => {
  let url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/pick/un-curated?limit=${limit}&skip=${skip}`;
  const token = GetUserToken();
  for (let i = 0; i < categories.length; i++) {
    url += `&categorys[${i}]=${categories[i]}`;
  }
  if (strDate) {
    url += `&strDate=${strDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }
  if (order) {
    url += `&order=${order}`;
  }
  if (isASC) {
    url += `&isASC=${isASC}`;
  }
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data.list;
};
