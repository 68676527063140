import React, { Component } from "react";
import M_ImgIcon from "../../Mobile/Project-upload/toolbar/modify_icon_1.svg";
import M_TextIcon from "../../Mobile/Project-upload/toolbar/modify_icon_4.svg";
import M_AudioIcon from "../../Mobile/Project-upload/toolbar/modify_audio_icon_2.svg";
import M_VideoIcon from "../../Mobile/Project-upload/toolbar/modify_icon_3.svg";
import M_OffDelIcon from "../../Mobile/Project-upload/toolbar/off_modify_icon_5.svg";
import M_OnDelIcon from "../../Mobile/Project-upload/toolbar/modify_icon_5.svg";
import M_OnImgIcon from "../../Mobile/Project-upload/toolbar/OnIcons/red_modify_icon_1.svg";
import M_OnTextIcon from "../../Mobile/Project-upload/toolbar/OnIcons/red_modify_icon_2.svg";
import M_OnAudioIcon from "../../Mobile/Project-upload/toolbar/OnIcons/red_modify_icon_5.svg";
import M_OnVideoIcon from "../../Mobile/Project-upload/toolbar/OnIcons/red_modify_icon_3.svg";

import "./MobileToolBar.scss";

class MobileToolBar extends Component {
  state = {
    clicked: "",
  };
  handleImgUpload = () => {
    this.imageUpload.click();
  };

  handleAudioUpload = () => {
    this.audioUpload.click();
  };

  render() {
    const clickedItem = this.props.clickItem;

    return (
      <div
        className="mobile_addContent_tool"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mobile_addContent_tool_Wrapper">
          <div className="toolBox" onClick={this.handleImgUpload}>
            <img
              src={
                clickedItem
                  ? clickedItem.type === "image"
                    ? M_OnImgIcon
                    : M_ImgIcon
                  : M_ImgIcon
              }
              alt="ImageUpload"
            />
            <input
              type="file"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              id="inputType_img"
              ref={(input) => (this.imageUpload = input)}
              onChange={this.props.imgUpload}
            ></input>
          </div>
          <div className="toolBox" onClick={this.handleAudioUpload}>
            <img
              src={
                clickedItem
                  ? clickedItem.type === "audio"
                    ? M_OnAudioIcon
                    : M_AudioIcon
                  : M_AudioIcon
              }
              alt="Audio Upload"
            />
            <input
              type="file"
              accept="audio/mp3, audio/wav"
              ref={(input) => (this.audioUpload = input)}
              onChange={this.props.audioUpload}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
            ></input>
          </div>

          <div className="toolBox">
            <img
              src={
                clickedItem
                  ? clickedItem.type === "video"
                    ? M_OnVideoIcon
                    : M_VideoIcon
                  : M_VideoIcon
              }
              alt="EmbedVideo"
              onClick={this.props.handleEmbedDialogOpen}
            />
          </div>
          <div className="toolBox" onClick={this.props.handleAddContent}>
            <img
              src={
                clickedItem
                  ? clickedItem.type === "text"
                    ? M_OnTextIcon
                    : M_TextIcon
                  : M_TextIcon
              }
              alt="TextEditor"
            />
          </div>
          <div
            className="toolBox"
            onClick={
              clickedItem
                ? (e) => {
                    e.preventDefault();
                    this.props.handleRemoveContent(clickedItem);
                  }
                : null
            }
          >
            <img
              className="delete"
              src={clickedItem !== null ? M_OnDelIcon : M_OffDelIcon}
              alt="content Delete"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileToolBar;
