import React, { Component } from "react";
import "./RecruitmentList.scss";
import i18n from "../../i18n";
import calendarIcon from "../../image/job/black-date-icon.svg";
import FavoriteIcon from "../../image/ApplicationsIcon/gray-favorite-icon.svg";
import UnFavoriteIcon from "../../image/ApplicationsIcon/black-favorite-icon.svg";
import DeleteDialog from "../../Component/DelConfirmDialog/DelConfirmDialog";
import MessageDilaog from "./MessageDialog/MessageDialog";
import imgPlaceholder from "../../image/Admin/placeholder.gif";
import { renderDate } from "../../Component/functions";
import DelConfirmDialog from "../../Component/DelConfirmDialog/DelConfirmDialog";
import Pagination from "../../Component/Pagination/Pagination";
import JobDialog from "../../Main/Job/JobDialog/JobDialog";
import EmptyStates from "../../Component/EmptyStates/EmptyStates";
import {
  PostJobDelete,
  GetResume,
  GetJobDetailInfo,
  getOtherJobs,
  SetFavoriteJob,
  SetUnFavoriteJob,
  PostJobApply,
  PostCancelApply,
  GetMemoInfo,
  JobViewCount,
} from "../../Api/Job/job";
import JobContent from "../../Component/Job/Job";
import CalenderDialog from "./CalendarDialog/CalendarDialog";
import MemoDialog from "./MemoDialog/MemoDialog";
import ConfirmDialog from "../../Component/OneButtonDialog/OneButtonDialog";

import { SetFollow, SetUnFollow } from "../../Api/Follow/Follow";

import { GetUserToken } from "../../modules/userAuth";
import ProfilePhoto from "../../Component/ProfilePhoto/ProfilePhoto";
// import moment from "moment";
class RecruitmentList extends Component {
  state = {
    delDialogOpen: false,
    calenderOpen: false,
    confirmDialogOpen: false,
    jobDialogOpen: false,
    selectedJob: "",
    endDate: "",
    changeAuthDialogOpen: false,
    removeDialogOpen: false,
    menuOpen: false,
    selectedIndex: "",
    teamId: "",
    jobInfo: {},
    msgDialogOpen: false,
    msgDialogInfo: {
      createdAt: "",
      userName: "",
      profilePhoto: "",
      userId: "",
    },
    otherJobsLimit: 20,
    otherJobs: [],
    otherJobsSkip: 0,
    resume: "",
    err: false,
    errMsg: "",
    supported: false,
    memo: "",
    memoUserName: "",
    memoInfo: {},
    memoDialogOpen: false,
    userId: "",
    jobId: "",
    modify: false,
    memoLoding: false,
  };
  LIMIT = 20;
  selectedUser = "";
  Date = "";
  viewTimer;
  handleDeleteDialogOpen = (id, teamId) => {
    this.setState({ removeDialogOpen: true, selectedJob: id, teamId });
  };
  setTime = (id) => {
    let sttDate = new Date().getSeconds();
    const endDate = this.Date + 3;
    if (sttDate === endDate) {
      this.viewCount(id);
    }
  };
  viewCount = async (id) => {
    try {
      await JobViewCount(id);
      clearInterval(this.viewTimer);
    } catch (e) {
      clearInterval(this.viewTimer);
    }
  };
  handleMsgDialogOpen = (userName, profilePhoto, createdAt, userId) => {
    this.setState({ msgDialogOpen: true }, () => {
      this.setState({
        msgDialogInfo: {
          userName,
          profilePhoto,
          createdAt,
          userId,
        },
      });
    });
  };
  handleMemoDialogOpen = async (jobId, userId, userName, index) => {
    try {
      this.setState({ memoDialogOpen: true });
      const response = await GetMemoInfo(
        jobId ? jobId : this.state.jobId,
        userId ? userId : this.state.userId
      );
      this.selectedUser = index;
      this.setState({
        jobId,
        userId,
        memoInfo: response !== "{}" ? response : null,
        memoUserName: userName,
        memoLoding: false,
      });
    } catch (e) {
      console.dir(e);
    }
  };
  handleDeleteRecruitmentItem = async () => {
    try {
      await PostJobDelete(this.state.selectedJob);
      this.props.memberListReload(this.state.teamId);
      this.setState({ removeDialogOpen: false });
    } catch (e) {
      console.dir(e);
    }
  };
  handleMemoDialogClose = () => {
    this.setState({
      memoDialogOpen: false,
      memoInfo: {},
      memoUserName: "",
      modify: false,
    });
  };
  toJobUpload = (id, jobId) => {
    this.props.history.push({
      pathname: `/jobUpload`,
      search: "mode=Edit",
      state: { jobId, teamId: id },
    });
  };
  handleMemoRegistration = async () => {
    this.setState({ memoLoding: true });
    try {
      await this.props.handleMemoRegistration(
        this.state.jobId,
        this.state.userId,
        this.state.memo,
        this.state.modify,
        this.selectedUser
      );
      this.handleMemoDialogOpen(
        this.state.jobId,
        this.state.userId,
        this.state.memoUserName,
        this.selectedUser
      );
      // this.selectedUser=""
      this.setState({ modify: false, memoLoding: false });
    } catch (e) {
      console.dir(e);
      this.setState({ memoLoading: false });
    }
  };
  handleMemoChange = (e) => {
    this.setState({ memo: e.target.value });
  };
  renderPeriod = (strDate, endDate) => {
    return strDate.substring(0, 10) + "~" + endDate.substring(0, 10);
  };

  handleGetPortfolio = async (id, teamId) => {
    this.setState({ resume: -1 });
    try {
      const resume = await GetResume(id, teamId);
      this.setState({ resume });
    } catch (e) {
      let errMsg = "";
      if (
        e.response.data.description ===
        "해당 공고의 팀 소유자는 지원할 수 없습니다."
      ) {
        errMsg = "해당 공고의 소유자는 지원할 수 없습니다.";
      }
      this.setState({
        resume: null,
        err: true,
        errMsg,
      });
    }
  };
  handleGetOtherJobs = async (id, teamId, initial) => {
    const otherJobs = await getOtherJobs(
      id,
      teamId,
      this.state.otherJobsSkip,
      this.state.otherJobsLimit
    );
    this.setState(
      {
        otherJobs: otherJobs ? otherJobs : { list: [], count: 0 },
      },
      () => {
        if (initial) {
          this.setState({ otherJobsSkip: this.state.otherJobsSkip + 10 });
        }
      }
    );
  };
  handleGetJobInfo = async (id) => {
    const jobInfo = await GetJobDetailInfo(id);
    this.Date = new Date().getSeconds();
    this.viewTimer = setInterval(() => this.setTime(id), 1000);
    this.setState({ jobInfo });
  };
  handleJobDetailDialogOpen = (id, teamId) => {
    this.setState(
      {
        jobInfo: null,
        otherJobs: -1,
        resume: null,
        otherJobsSkip: 0,
        jobDialogOpen: true,
      },
      () => {
        this.handleGetJobInfo(id);
        this.handleGetOtherJobs(id, teamId, true);
      }
    );
  };
  handleOtherBookMark = async (id) => {
    const otherJobs = this.state.otherJobs;
    otherJobs.list = otherJobs.list.map((item) => {
      if (item._id === id) {
        item.isFavorite = !item.isFavorite;
      }
      return item;
    });
    this.setState({
      otherJobs,
    });
    try {
      if (!otherJobs.list.find((item) => item._id === id).isFavorite) {
        await SetUnFavoriteJob(id);
      } else {
        await SetFavoriteJob(id);
      }
    } catch (e) {
      otherJobs.list = otherJobs.list.map((item) => {
        if (item._id === id) {
          item.isFavorite = !item.isFavorite;
        }
        return item;
      });
      this.setState({
        otherJobs,
      });
    }
  };
  handleJobDetailResumeDialogOpen = (id, teamId) => {
    this.setState(
      {
        jobInfo: null,
        otherJobs: -1,
        resume: -1,
        otherJobsSkip: 0,
        jobDialogOpen: true,
        resumeLoaded: true,
      },
      () => {
        clearInterval(this.viewTimer);
        this.handleGetJobInfo(id);
        this.handleGetOtherJobs(id, teamId, true);
        this.handleGetPortfolio(id);
      }
    );
  };
  handleFollow = async () => {
    const token = GetUserToken();
    if (token) {
      const jobInfo = this.state.jobInfo;
      jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
      this.setState({ jobInfo });
      if (jobInfo.teamInfo.isFollowing) {
        try {
          await SetFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      } else {
        try {
          await SetUnFollow(this.state.jobInfo.teamInfo._id);
        } catch (e) {
          jobInfo.teamInfo.isFollowing = !jobInfo.teamInfo.isFollowing;
          this.setState({ jobInfo });
        }
      }
    } else {
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleOtherApply = async (id) => {
    const job = this.state.otherJobs.list.find((item) => item._id === id);
    if (job.isApplied) {
      if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
        const otherJobs = this.state.otherJobs;
        otherJobs.list = otherJobs.list.map((item) => {
          if (item._id === id) {
            item.isApplied = !item.isApplied;
          }
          return item;
        });
        this.setState({ otherJobs });
        try {
          await PostCancelApply(id);
        } catch (e) {
          console.dir(e);
          otherJobs.list = otherJobs.list.map((item) => {
            if (item._id === id) {
              item.isApplied = !item.isApplied;
            }
            return item;
          });
        }
        this.setState({ otherJobs });
      }
    } else {
      this.handleJobDetailResumeDialogOpen(id, this.state.jobInfo.teamInfo._id);
    }
  };
  handleJobApply = async (id) => {
    const jobInfo = this.state.jobInfo;
    if (!jobInfo.isApplied) {
      jobInfo.isApplied = !jobInfo.isApplied;

      try {
        await PostJobApply(id);
        this.setState({ supported: true, resume: false });
      } catch (e) {
        console.dir(e);
        jobInfo.isApplied = !jobInfo.isApplied;

        this.setState({
          jobInfo,
          supported: false,
          resume: false,
          // err: true,
          // errMsg: e.response.data.description,
          // jobDialogOpen: false,
        });
      }
    } else {
      if (window.confirm(i18n.t("jobInfo.apply.cancel.text"))) {
        jobInfo.isApplied = !jobInfo.isApplied;
        this.setState({ jobInfo, resume: null });
        try {
          await PostCancelApply(id);
        } catch (e) {
          console.dir(e);
          jobInfo.isApplied = !jobInfo.isApplied;
          this.setState({ jobInfo });
        }
      }
    }
  };
  handleApply = (id) => {
    const token = GetUserToken();
    if (token) {
      if (id) {
        this.handleOtherApply(id);
      } else {
        this.handleJobApply(this.state.jobInfo._id);
      }
    } else {
      this.props.enqueueSnackbar(i18n.t("global.alert.login"), {
        variant: "error",
        key: i18n.t("global.alert.login"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  handleBookMark = (id) => {
    if (id) {
      this.handleOtherBookMark(id);
    } else {
      this.handleJobBookMark(this.state.jobInfo._id);
    }
  };
  handleDateChange = (endDate) => {
    this.setState({ endDate });
  };
  // handleSetPeriod = async () => {
  //   try {
  //     console.log("asd");
  //     await ModifyJobPeriod(this.state.selectedJob, this.state.endDate);
  //     this.setState({})
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };
  checkSorterType = (sorter) => {
    if (sorter === "position") {
      return 1;
    } else if (sorter === "category") {
      return 2;
    } else if (sorter === "workingHours") {
      return 3;
    } else if (sorter === "applicant") {
      return 4;
    } else if (sorter === "views") {
      return 5;
    } else if (sorter === "periodInfo") {
      return 6;
    }
  };

  handleJobBookMark = async (id) => {
    const jobInfo = this.state.jobInfo;
    jobInfo.isFavorite = !jobInfo.isFavorite;
    this.setState({ jobInfo });
    try {
      if (!jobInfo.isFavorite) {
        await SetUnFavoriteJob(id ? id : this.state.jobInfo._id);
      } else {
        await SetFavoriteJob(id ? id : this.state.jobInfo._id);
      }
    } catch (e) {
      jobInfo.isFavorite = !jobInfo.isFavorite;
      this.setState({ jobInfo });
      console.dir(e);
    }
  };
  handleRemoveDialogOpen = (jobId, userId) => {
    this.setState({ jobId, userId, delDialogOpen: true });
  };

  renderDeadEnd = (date) => {
    if (date) {
      return `${i18n.t("jobInfo.otherjobs.deadend")} ${renderDate(date, true)}`;
    } else {
      return "";
    }
  };
  renderRecruitmentList = () => {
    if (!this.props.memberMode && !this.props.applicnatsView) {
      return this.props.list && this.props.list.length ? (
        this.props.list.map((item, key) => {
          return (
            <JobContent
              index={key}
              history={this.props.history}
              handleBookMark={this.props.handleBookMark}
              handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
              jobInfo={item}
              row={true}
            />
          );
        })
      ) : (
        <div className="empty-wrapper">
          <EmptyStates />
        </div>
      );
    } else if (this.props.memberMode && !this.props.applicnatsView) {
      return this.props.m_list.length ? (
        this.props.m_list.map((item, index) => {
          return (
            <JobContent
              memberMode={this.props.memberMode}
              index={index}
              history={this.props.history}
              handleBookMark={this.props.handleBookMark}
              toJobEdit={this.props.toJobEdit}
              handleJobDeadLine={this.handleJobDeadLine}
              handleCalendarOpen={this.handleCalendarOpen}
              handleDeleteDialogOpen={this.handleDeleteDialogOpen}
              handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
              handleApplicantsView={this.props.handleApplicantsView}
              jobInfo={item}
              row={true}
            />
          );
        })
      ) : (
        <div className="empty-wrapper">
          <EmptyStates />
        </div>
      );
    } else {
      console.log(this.props.applicantsList);
      return (
        <div className="applicants_container">
          {this.props.applicantsList ? (
            this.props.applicantsList.map((item, index) => {
              return (
                <div className="applicants-wrapper" key={`${item._id}_key`}>
                  <div className="applicants-lt">
                    <div
                      className="favorite-btn"
                      onClick={() => {
                        this.props.handleUserFavorite(
                          item.applications._id,
                          item._id,
                          index,
                          item.applications.isFavorite
                        );
                      }}
                    >
                      <img
                        src={
                          !item.applications.isFavorite
                            ? FavoriteIcon
                            : UnFavoriteIcon
                        }
                        alt="favorite"
                      />
                    </div>
                    <div className="applicants-info">
                      <div className="user-profile">
                        <ProfilePhoto
                          src={item.applications.profilePhoto}
                          size={84}
                        />
                        <div className="user-detailInfo nowrap">
                          <span className="userName nowrap">
                            {item.applications.userName}
                          </span>
                          <div className="applicants-date nowrap">
                            <img
                              src={calendarIcon}
                              alt="date"
                              style={{ width: "10px", height: "10px" }}
                            />
                            <span className="nowrap">
                              {renderDate(item.applications.createdAt)}{" "}
                              {i18n.t("teamPage.applicants.applied")}
                            </span>
                          </div>
                          <span className="user-locaiton nowrap">
                            {item.applications.countryInfo.countryName}
                          </span>
                          {/* <span className="user-favorite">
                            Motion Graphic/3D
                          </span> */}
                          <div className="applicants-btns  nowrap">
                            <div className="lt-btns">
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleMemoDialogOpen(
                                    item._id,
                                    item.applications._id,
                                    item.applications.userName,
                                    index
                                  )
                                }
                              >
                                <div
                                  className="badge"
                                  style={
                                    !item.applications.memo.text
                                      ? { display: "none" }
                                      : null
                                  }
                                ></div>
                                {i18n.t("teamPage.memo")}
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleMsgDialogOpen(
                                    item.applications.userName,
                                    item.applications.profilePhoto,
                                    item.applications.createdAt,
                                    item.applications._id
                                  )
                                }
                              >
                                {i18n.t("teamPage.message")}
                              </button>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                this.handleRemoveDialogOpen(
                                  item._id,
                                  item.applications._id
                                )
                              }
                            >
                              {i18n.t("global.delete")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="member-projectInfo">
                    {item.applications.portfolio ? (
                      <>
                        <li className="ProfileRow-projectCover-1EN">
                          <div>
                            {item.applications.portfolio[0] ? (
                              <img
                                src={item.applications.portfolio[0]?.thumbnail}
                                alt="previewImage"
                                onError={(e) => {
                                  e.target.src = imgPlaceholder;
                                }}
                              ></img>
                            ) : (
                              <div
                                style={{ backgroundColor: "#f2f2f2" }}
                                className="ProfileRow-emptyCover-3PF"
                              ></div>
                            )}
                          </div>
                        </li>
                        <li className="ProfileRow-projectCover-1EN">
                          <div>
                            {item.applications.portfolio[1] ? (
                              <img
                                src={item.applications.portfolio[1]?.thumbnail}
                                alt="previewImage"
                                onError={(e) => {
                                  e.target.src = imgPlaceholder;
                                }}
                              ></img>
                            ) : (
                              <div
                                style={{ backgroundColor: "#f2f2f2" }}
                                className="ProfileRow-emptyCover-3PF"
                              ></div>
                            )}
                          </div>
                        </li>
                        <li className="ProfileRow-projectCover-1EN">
                          <div>
                            {item.applications.portfolio[2] ? (
                              <img
                                src={item.applications.portfolio[2]?.thumbnail}
                                alt="previewImage"
                                onError={(e) => {
                                  e.target.src = imgPlaceholder;
                                }}
                              ></img>
                            ) : (
                              <div
                                style={{ backgroundColor: "#f2f2f2" }}
                                className="ProfileRow-emptyCover-3PF"
                              ></div>
                            )}
                          </div>
                        </li>
                        <li className="ProfileRow-projectCover-1EN">
                          <div>
                            {item.applications.portfolio[3] ? (
                              <img
                                src={item.applications.portfolio[3]?.thumbnail}
                                alt="previewImage"
                                onError={(e) => {
                                  e.target.src = imgPlaceholder;
                                }}
                              ></img>
                            ) : (
                              <div
                                style={{ backgroundColor: "#f2f2f2" }}
                                className="ProfileRow-emptyCover-3PF"
                              ></div>
                            )}
                          </div>
                        </li>
                        <li className="ProfileRow-projectCover-1EN">
                          <div>
                            {item.applications.portfolio[4] ? (
                              <img
                                src={item.applications.portfolio[4]?.thumbnail}
                                alt="previewImage"
                                onError={(e) => {
                                  e.target.src = imgPlaceholder;
                                }}
                              ></img>
                            ) : (
                              <div
                                style={{ backgroundColor: "#f2f2f2" }}
                                className="ProfileRow-emptyCover-3PF"
                              ></div>
                            )}
                          </div>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              );
            })
          ) : (
            <div className="empty-wrapper">
              <EmptyStates />
            </div>
          )}
        </div>
      );
    }
  };

  renderPagination = () => {
    if (!this.props.memberMode && !this.props.applicnatsView) {
      return (
        <Pagination
          currentPage={this.props.pageState.job.page}
          totalCount={this.props.pageState.job.total}
          limit={this.LIMIT}
          handleActivePage={this.props.setPage}
        />
      );
    } else if (this.props.memberMode && !this.props.applicnatsView) {
      <Pagination
        currentPage={this.props.pageState.jobMemberMode.page}
        totalCount={this.props.pageState.jobMemberMode.total}
        handleActivePage={this.props.setPage}
        limit={this.LIMIT}
      />;
    } else {
      <Pagination
        limit={this.LIMIT}
        currentPage={this.props.pageState.applicats.page}
        totalCount={this.props.pageState.applicats.total}
        handleActivePage={this.props.setPage}
      />;
    }
  };
  handleJobDeadLine = (id) => {
    this.setState({ selectedJob: id, confirmDialogOpen: true });
  };
  handleCalendarOpen = (id, endDate, index) => {
    this.setState({
      selectedJob: id,
      endDate,
      calenderOpen: true,
      selectedIndex: index,
    });
  };

  handleMsgDialogClose = () => {
    this.setState({ msgDialogInfo: {}, msgDialogOpen: false });
  };
  handleJobDialogClose = () => {
    const header = document.getElementById("nav_header-container");
    header.style.zIndex = "100";
    clearInterval(this.viewTimer);
    this.setState({
      jobDialogOpen: false,
      supported: false,
      isPage: false,
      err: false,
    });
  };
  render() {
    return (
      <>
        <div className="recruitment-list-container">
          <MessageDilaog
            open={this.state.msgDialogOpen}
            onClose={this.handleMsgDialogClose}
            userInfo={this.state.msgDialogInfo}
            history={this.props.history}
          />
          <CalenderDialog
            handleSetPeriod={() =>
              this.setState({ calenderOpen: false }, () => {
                this.props.handleSetPeriod(
                  this.state.selectedJob,
                  this.state.endDate,
                  this.state.selectedIndex
                );
              })
            }
            handleDateChange={this.handleDateChange}
            endDate={this.state.endDate}
            open={this.state.calenderOpen}
            onClose={() => this.setState({ calenderOpen: false })}
          />
          {this.state.delDialogOpen ? (
            <DelConfirmDialog
              buttonClick={() =>
                this.setState({ delDialogOpen: false }, () => {
                  this.props.handleRemoveAppicants(
                    this.state.jobId,
                    this.state.userId
                  );
                })
              }
              open={this.state.delDialogOpen}
              onClose={() => this.setState({ delDialogOpen: false })}
              dialogHeader="지원자 삭제"
              dialogText="지원자를 삭제하시겠습니까? 한번 삭제하면 복구가 불가능합니다"
            />
          ) : null}

          <ConfirmDialog
            dialogHeadText="채용 조기 마감"
            dialogBodyText1="채용 정보를 조기 마감하면 북마크한"
            dialogBodyText2="구직자에게 조기 마감 알림을 보냅니다."
            dialogButtonText="조기마감"
            onButtonClick={() => {
              this.props.handleJobClose(this.state.selectedJob);
              this.setState({ confirmDialogOpen: false });
            }}
            open={this.state.confirmDialogOpen}
            onClose={() => this.setState({ confirmDialogOpen: false })}
          />
          {this.state.memoDialogOpen && (
            <MemoDialog
              handleMemoRegistration={this.handleMemoRegistration}
              memoLoading={this.state.memoLoding}
              modifyChange={() => this.setState({ modify: true })}
              modify={this.state.modify}
              handleMemoChange={this.handleMemoChange}
              open={this.state.memoDialogOpen}
              onClose={this.handleMemoDialogClose}
              memoInfo={this.state.memoInfo}
              userName={this.state.memoUserName}
            />
          )}
          {this.state.jobDialogOpen ? (
            <JobDialog
              nextDateCheck={false}
              prevDataCheck={false}
              open={this.state.jobDialogOpen}
              onClose={this.handleJobDialogClose}
              jobInfo={this.state.jobInfo}
              resume={this.state.resume}
              otherJobs={this.state.otherJobs}
              handleFollow={this.handleFollow}
              handleApply={this.handleApply}
              getResume={this.handleGetPortfolio}
              history={this.props.history}
              handleBookMark={this.handleBookMark}
              handleJobDetailResumeDialogOpen={
                this.handleJobDetailResumeDialogOpen
              }
              supported={this.state.supported}
              err={this.state.err}
              errMsg={this.state.errMsg}
              supportedDone={() =>
                this.setState({
                  supported: !this.state.supported,
                  resume: null,
                })
              }
              errBackClick={() => this.setState({ err: false, errMsg: "" })}
              handleJobDetailDialogOpen={this.handleJobDetailDialogOpen}
              skip={this.state.otherJobsSkip}
              limit={this.state.otherJobsLimit}
            />
          ) : null}
          {this.state.removeDialogOpen ? (
            <DeleteDialog
              dialogHeader={i18n.t("teamPage.recruitment.deleteHeader")}
              dialogText={i18n.t("teamPage.recruitment.deleteText")}
              open={this.state.removeDialogOpen}
              buttonClick={this.handleDeleteRecruitmentItem}
              onClose={() => this.setState({ removeDialogOpen: false })}
            />
          ) : null}

          {this.props.isLoading
            ? this.renderRecruitmentList(this.props.list)
            : null}
        </div>
        {this.props.isLoading ? this.renderPagination() : null}
      </>
    );
  }
}

export default RecruitmentList;
