import React, { Component } from "react";
import "./CollectionPage.scss";
import quertString from "query-string";
import loadable from "@loadable/component";
import { connect } from "react-redux";
// import SortIcon from "../../../image/Collection/collection-filter-icon.svg";
import Filter from "./Filter/Filter";
import { ClickAwayListener } from "@material-ui/core";
import DeleteDialog from "../../../Component/CollectionDialog/CollectionDelDialog/CollectionDelDialog";
import DeleteIcon from "../../../image/Collection/moodboard-delete-icon.svg";
import i18n from "../../../i18n";
import AddIcon from "../../../Mobile/Project-upload/modify_add_black_icon.svg";
import shareBtn from "../../../image/job/share-black-icon.svg";
import ShareDialog from "../../../Component/ShareDialog/ShareDialog";
import AddCollectionDialog from "../../../Component/CollectionDialog/AddColletionDialog/AddCollectionDialog";
import {
  GetCollectionInfo,
  DeleteCollectionItem,
  PatchCollection,
  PatchColletionViewCount,
} from "../../../Api/Collection/Collection";
import { GetUserInfo } from "../../../Api/User/user";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { Fragment } from "react";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";

const Project = loadable(() => import("../../../Component/Project/Project"));

class CollectionPage extends Component {
  state = {
    collectionDialogOpen: false,
    collections: {},
    userInfo: {},
    isLoading: false,
    filterOpen: false,
    myCollection: false,
    deleteDialogOpen: false,
    shareDialogOpen: false,
    projectId: "",
    collectionId: null,
  };
  viewCountTimer = null;
  countSeconds = new Date().getSeconds();
  componentDidMount = () => {
    if (this.props.location.search) {
      const collectionId = quertString.parse(this.props.location.search);
      this.collectionItemLoad(collectionId.id);
    } else {
      this.props.history.push("/not-found");
    }
    this.timer = setInterval(this.setTimer, 1000);
  };
  componentWillUnmount = () => {
    this.handleClearTimer();
    console.log("s");
  };
  setTimer = () => {
    let startDate = new Date().getSeconds();
    const endDate = this.countSeconds + 3;
    if (startDate === endDate) {
      this.viewCount();
    }
  };
  handleClearTimer = () => {
    clearInterval(this.viewCountTimer);
  };
  viewCount = async () => {
    try {
      await PatchColletionViewCount(this.state.collectionId);
      this.handleClearTimer();
    } catch (e) {
      this.handleClearTimer();
      console.log(e);
    }
  };
  handleShareDialogOpen = () => {
    this.setState({ shareDialogOpen: true });
  };

  handleShareDialogClose = () => {
    this.setState({ shareDialogOpen: false });
  };
  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };
  handleFilterClose = () => {
    this.setState({
      filterOpen: false,
    });
  };
  userInfoLoad = async (id) => {
    try {
      const response = await GetUserInfo(id);
      console.log(response);
      this.setState({ userInfo: response });
    } catch (e) {
      console.dir(e);
    }
  };
  toMain = () => {
    this.props.history.push("/");
  };
  handleDeleteDialogOpen = (projectId) => {
    this.setState({ deleteDialogOpen: true, projectId });
  };
  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleDeleteProject = async () => {
    try {
      await DeleteCollectionItem(
        this.state.collections._id,
        this.state.projectId
      );

      const collectionId = quertString.parse(this.props.location.search);
      this.setState({ deleteDialogOpen: false }, () =>
        this.collectionItemLoad(collectionId.id)
      );
    } catch (e) {
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: i18n.t("global.error.server.msg"),
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.dir(e);
    }
  };
  collectionItemLoad = async (id) => {
    try {
      const response = await GetCollectionInfo(id);
      console.log(response);
      this.setState({ collections: response, collectionId: id }, () => {
        this.userInfoLoad(this.state.collections.userId);
        this.setState({ isLoading: true });
      });
    } catch (e) {
      console.dir(e);
    }
  };
  nullProejctRender = () => {
    if (!this.state.collections.projects[0]) {
      return (
        <div className="null-proejcts-box" onClick={this.toMain}>
          <img src={AddIcon} alt="Add Collection" />
          <span>No collections have been saved</span>
          <span>Try adding a collection</span>
        </div>
      );
    } else {
      return;
    }
  };

  toOther = async (linkURL) => {
    try {
      // const userInfo = await GetUserInfo(id);
      this.props.history.push(`/@${linkURL}`);
      // window.location.reload();
    } catch (e) {
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.log(e);
    }
  };
  handleModifyCollection = async (name, isHidden) => {
    try {
      await PatchCollection(this.state.collectionId, name, isHidden);
      this.setState({
        collections: { ...this.state.collections, name, isHidden },
        collectionDialogOpen: false,
      });
    } catch (e) {
      console.dir(e);
    }
  };
  render() {
    const userInfo = this.state.userInfo;
    const collections = this.state.collections;

    return this.state.isLoading ? (
      <Fragment>
        {this.state.shareDialogOpen ? (
          <ShareDialog
            open={this.state.shareDialogOpen}
            onClose={this.handleShareDialogClose}
            projLink={"/collections/?id=" + this.state.collections._id}
          ></ShareDialog>
        ) : null}
        <div className="collectionPage-container">
          <DeleteDialog
            dialogHeadText={i18n.t("collection.project.delete.title")}
            dialogBodyText1={i18n.t("collection.project.delete.text")}
            dialogBodyText2={i18n.t("collection.project.delete.subText")}
            dialogButtonText2={i18n.t("projDelete.checkBtn")}
            open={this.state.deleteDialogOpen}
            onClose={this.handleDeleteDialogClose}
            onButtonClick={this.handleDeleteProject}
          />
          <AddCollectionDialog
            isModify={true}
            projects={this.state.collections.projects}
            open={this.state.collectionDialogOpen}
            onClose={() => this.setState({ collectionDialogOpen: false })}
            handleCreateCollection={this.handleModifyCollection}
            creatorInfo={userInfo}
            collectionInfo={this.state.collections}
          />
          <div className="collectionPage-profileBox">
            {/* <div
            className="profileBox-profilePhoto"
            style={{ backgroundImage: `url(${userInfo.profilePhoto})` }}
          ></div> */}
            <div className="column-photo">
              <ProfilePhoto src={userInfo.profilePhoto} size={98} />
            </div>
            <span className="visibility">
              {collections.isHidden
                ? i18n.t("addCollection.dialog.setSecret")
                : i18n.t("addCollection.dialog.public")}
            </span>
            <div className="collection-title">{collections.name}</div>

            <div className="provider-box">
              <span className="collection-provider">
                {`${i18n.t("collection.owner")} : `}
              </span>
              <span
                className="userName"
                onClick={() => this.toOther(userInfo.linkURL)}
              >
                {userInfo.userName}
              </span>
            </div>
            <div className="columm-status">
              <span>
                저장된 항목 {collections.projects.length}개 · 조회수{" "}
                {collections.views}
              </span>
            </div>
            <div className="profileBox-btns">
              {this.props.myInfo &&
              this.props.myInfo._id === this.state.userInfo._id ? (
                <button
                  type="button"
                  onClick={() => this.setState({ collectionDialogOpen: true })}
                >
                  {i18n.t("header.settings")}
                </button>
              ) : null}
            </div>
          </div>
          <div className="collections-topTitle">
            <div className="collections-count">
              <span>{collections.projects.length}</span>
              <span>{i18n.t("Items")}</span>
            </div>
            <div className="collections-buttons">
              {/* <img
              src={SortIcon}
              alt="collection-sort"
              onClick={this.handleFilterOpen}
            /> */}
              <img
                src={shareBtn}
                alt="shareIcon"
                onClick={this.handleShareDialogOpen}
              />
              {this.state.filterOpen ? (
                <ClickAwayListener onClickAway={this.handleFilterClose}>
                  <Filter onClose={this.handleFilterClose} />
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
          <div
            className={`collections-list reference-container 
        ${!this.state.collections.projects[0] ? "null-projects" : ""}`}
          >
            {collections.projects.map((item) => {
              return (
                <div className="reference-content">
                  {this.props.myInfo &&
                  this.props.myInfo._id === this.state.userInfo._id ? (
                    <div className="collection-delete-box">
                      <img
                        alt="collection-remove"
                        onClick={() => this.handleDeleteDialogOpen(item._id)}
                        src={DeleteIcon}
                        className="colletions-delete"
                      />
                    </div>
                  ) : null}
                  <Project
                    projectList={this.state.collections.projects}
                    myCollection={
                      this.props.myInfo &&
                      this.props.myInfo._id === this.state.userInfo._id
                    }
                    location={this.props.location}
                    thumbnail={item.thumbnail}
                    title={item.title}
                    creators={item.creators}
                    uploaderInfo={item.creatorInfo}
                    likeMembers={item.likeMembers}
                    views={item.views}
                    history={this.props.history}
                    projectId={item._id}
                    projUserId={item.creatorInfo._id}
                  ></Project>
                </div>
              );
            })}
            {this.nullProejctRender()}
          </div>
        </div>
      </Fragment>
    ) : null;
  }
}
const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
});

export default compose(connect(mapStateToProps), withSnackbar)(CollectionPage);
