import React, { Component } from "react";
// import "./ApplicantsListDialog.scss";
import { Dialog } from "@material-ui/core";
import i18n from "../../../../../i18n";
import LocationIcon from "../../../../../image/location-icon.svg";
// import ArrowIcon from "../../../../../image/arrowIcon/black-down-icon.svg";
// import LikeIcon from "../../../../../image/like-icon.svg";
// import ViewIcon from "../../../../../image/view-icon.svg";
import "./applicantsDialog.scss";
import XboxIcon from "../../../../../image/no-applicant-icon.svg";
import CloseIcon from "../../../../../image/close-icon.svg";
import FadeLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import {
  handlePopupInfiniteContent,
  renderDate,
} from "../../../../../Component/functions";
import ProfilePhoto from "../../../../../Component/ProfilePhoto/ProfilePhoto";

class ApplicantsListDialog extends Component {
  state = {
    acceptDialogOpen: false,
    selecUser: "",
    messageOpen: "",
    titleMsg: "",
    msg: "",
    function: "",
    isLoading: false,
    items: 0,
    dataNullCheck: false,
  };
  LIMIT = 10;

  handleInfiniteContent = () => {
    const cotainer = document.getElementById("scroll_container");
    const scrollBox = document.getElementById("dialog_popup_scroll");
    handlePopupInfiniteContent(
      scrollBox,
      cotainer,
      this.props.scrollInfo.dataNull,
      this.props.isLoading,
      this.props.addPendingUserList
    );
  };

  handleAcceptDialogOpen = (userId) => {
    this.setState(
      {
        selecUser: userId,
        titleMsg: i18n.t("team.accept.title"),
        msg: i18n.t("team.accept.text"),
        function: "accept",
      },
      () => this.setState({ acceptDialogOpen: true })
    );
  };
  handleDeclineDialogOpen = (userId) => {
    this.setState(
      {
        selecUser: userId,
        titleMsg: i18n.t("team.decline.title"),
        msg: i18n.t("team.decline.text"),
        function: "reject",
      },
      () => this.setState({ acceptDialogOpen: true })
    );
  };
  handleMessageOpen = (userId) => {
    if (this.state.messageOpen === userId) {
      this.setState({ messageOpen: "" });
    } else {
      this.setState({ messageOpen: userId });
    }
  };
  // handleUserAccept = async () => {
  //   try {
  //     await PostUserAccept(this.props.teamId, this.state.selecUser, "member");
  //     this.setState({ acceptDialogOpen: false }, () => this.isPendinUserList());
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };
  // handleUserReject = async () => {
  //   try {
  //     const response = await PostUserReject(
  //       this.props.teamId,
  //       this.state.selecUser
  //     );
  //     this.isPendinUserList();
  //     console.log(response);
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };

  render() {
    const { onClose, open } = this.props;
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
    `;
    const pendingUserList = this.props.pendingUserList;
    return (
      <>
        <Dialog
          fullWidth={this.props.fullWidth}
          className={"applicants-dialog"}
          open={open}
          onClose={onClose}
        >
          <div
            id="scroll_container"
            className="applicants-dialog-container admin-applicants-dialog"
          >
            <div className="applicants-title">
              <span>{i18n.t("지원자")}</span>

              <img src={CloseIcon} alt="dialog-close" onClick={onClose} />
            </div>
            <div className="applicants-userList">
              <div
                id="dialog_popup_scroll"
                onScroll={this.handleInfiniteContent}
                className={`applicants-list-box ${
                  pendingUserList ? "" : "penddingNull"
                }`}
              >
                <div className={`applicants-scrollBox `}>
                  {pendingUserList ? (
                    pendingUserList.map((user, idx) => {
                      return (
                        <div
                          className="applicatns-box admin_applicants"
                          key={`${user.applications.userName}_${idx}`}
                        >
                          <div className="applicants-userInfo">
                            <ProfilePhoto
                              size={50}
                              scr={user.applications.profilePhoto}
                            />
                            <div className="user-infoBox">
                              <div className="infoBox-top">
                                <span>{user.applications.userName}</span>
                              </div>
                              <div className="infoBox-status">
                                {/* <img src={LikeIcon} alt="Like Conut" /> */}
                                <span>
                                  {renderDate(user.applications.createdAt)}
                                  지원함
                                </span>
                              </div>
                              <div className="infoBox-bottom">
                                <div className="infoBox-location">
                                  <img src={LocationIcon} alt="user-location" />
                                  <span>
                                    {user.applications.countryInfo.countryName}
                                  </span>
                                </div>
                                {/* <div className="infoBox-status">
                                  <img src={ViewIcon} alt="View Conut" />
                                  <span>{user.views}</span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <ul className="applicanst-portfolio member-projectInfo">
                            <li className="ProfileRow-projectCover-1EN">
                              <div>
                                {user.applications.portfolio[0].thumbnail ? (
                                  <img
                                    alt="previewImage"
                                    src={
                                      user.applications.portfolio[0].thumbnail
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{ backgroundColor: "#f2f2f2" }}
                                    className="ProfileRow-emptyCover-3PF"
                                  ></div>
                                )}
                              </div>
                            </li>
                            <li className="ProfileRow-projectCover-1EN">
                              <div>
                                {user.applications.portfolio[1].thumbnail ? (
                                  <img
                                    alt="previewImage"
                                    src={
                                      user.applications.portfolio[1].thumbnail
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{ backgroundColor: "#f2f2f2" }}
                                    className="ProfileRow-emptyCover-3PF"
                                  ></div>
                                )}
                              </div>
                            </li>
                            <li className="ProfileRow-projectCover-1EN">
                              <div>
                                {user.applications.portfolio[2].thumbnail ? (
                                  <img
                                    alt="previewImage"
                                    src={
                                      user.applications.portfolio[2].thumbnail
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{ backgroundColor: "#f2f2f2" }}
                                    className="ProfileRow-emptyCover-3PF"
                                  ></div>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <div className="null-applicants">
                      <img src={XboxIcon} alt="XboxIcon" />
                      <span>{i18n.t("team.applicants.null")}</span>
                    </div>
                  )}

                  <FadeLoader
                    loading={this.props.isLoading}
                    css={override}
                    size={6}
                  ></FadeLoader>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default ApplicantsListDialog;
