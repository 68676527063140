import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEng from "./locales/en/translation.json";
import translationKo from "./locales/ko/translation.json";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: translationEng, // 영어 json 파일
      },
      ko: {
        translations: translationKo, // 한국어 json 파일
      },
    },
    fallbackLng: "en", //지역 또는 스크립트를 포함하는 기본 지역마다 현지 언어를 찾지 못 할 경우 순수 언어 파일에서 번역을 수행
    debug: false,

    //
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
