import React, { Component } from "react";
import "./IntroBox_1.scss";

import BannerImg from "../../image/Introdution/main-banner/main-banner.png";
import i18n from "../../i18n";

class introBox_1 extends Component {
  render() {
    return (
      <div className="introBox">
        <div className="introduction-banner">
          <div
            style={{
              backgroundImage: `url(${BannerImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
        <div className="introduction-intro">
          {i18n.language !== "ko" ? (
            <div className="intro-introText">
              <span className="intro-introHeader">INTRODUTION</span>
              <span className="intro-introContent">
                Platform for motion creators
              </span>
              <span className="intro-introfooter-content">
                Motion Trend is an open community where you can discover and
                share <br></br> cool works from motion creators around the
                world.
              </span>
            </div>
          ) : (
            <div className="intro-introText">
              <span className="intro-introHeader">모션트렌드 소개</span>
              <span className="intro-introContent">
                모션 크리에이터들을 위한 플랫폼
              </span>
              <span className="intro-introfooter-content">
                모션트렌드는 전 세계 모션 크리에이터들의 멋진 작품을 <br></br>
                발견하고, 공유할 수 있는 오픈된 커뮤니티 입니다.
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default introBox_1;
