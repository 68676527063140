import React, { Component } from "react";
import "./DeleteDialog.scss";
import { Dialog } from "@material-ui/core";
import DeleteIcon from "../../../image/DelteDialogIcon/delete-account-icon.svg";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PostDeleteUser } from "../../../Api/User/user";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { GetMyTeamList } from "../../../Api/Team/Team";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class DeleteDialog extends Component {
  state = {
    fullScreen: false,
    isLoading: false,
  };
  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  componentDidMount = () => {
    if (window.innerWidth < 700) {
      this.setState({ fullScreen: true });
    }
    window.addEventListener("resize", (e) => {
      this.handleResize(e.target.innerWidth);
    });
  };
  handleResize = (width) => {
    if (width < 700) {
      this.setState({ fullWidth: true });
    } else {
      this.setState({ fullWidth: false });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };
  renderDialogForm = () => {
    const { onClose, open } = this.props;
    if (!this.state.fullWidth) {
      return (
        <Dialog
          // fullWidth={ this.state.fullWidth }
          className={""}
          maxWidth={false}
          open={open}
          onClose={onClose}
        >
          <div className="closeBtn" onClick={onClose}>
            <div className="closeBar1" />
            <div className="closeBar2" />
          </div>
          <div className="alert-button-dialog-container DeleteDialog">
            <div className="delete-dialog-haeder">
              <span>{i18n.t("userSetting.account.delete.header")}</span>
            </div>
            <div className="delete-dialog-body">
              <div className="delete-title">
                <span>{i18n.t("userSetting.account.delete.title")}</span>
              </div>
              <img src={DeleteIcon} alt="account delete" />
              {i18n.language === "en" ? (
                <div className="delete-explan">
                  Delete the account.
                  <br />
                  <span>
                    The post ings comments and records
                    <br /> that you wrote will be deleted
                    <br />
                  </span>{" "}
                  and Deleted immediately and cannot be recovered.
                </div>
              ) : (
                <div className="delete-explan">
                  계정을 삭제합니다.
                  <br />
                  <span>작성한 게시물의 댓글과 기록은 삭제됩니다.</span>
                  <br />
                  즉시 삭제되며 계정은 복구 할 수 없습니다.
                </div>
              )}
            </div>
            <div className="deleteBtns">
              <button
                className="dialogCloseBtn"
                type="button"
                onClick={onClose}
              >
                {i18n.t("global.no")}
              </button>
              <button
                className="deleteBtn"
                type="button"
                onClick={this.handleUserDelete}
              >
                {i18n.t("global.yes")}
              </button>
            </div>
          </div>
        </Dialog>
      );
    } else {
      return (
        <BottomSheet open={open} onDismiss={onClose}>
          {/* <div className="closeBtn" onClick={ onClose }>
          <div className="closeBar1" />
          <div className="closeBar2" />
        </div> */}
          <div
            style={{ maxWidth: "unset" }}
            className="alert-button-dialog-container DeleteDialog"
          >
            <div className="delete-dialog-body">
              <div className="delete-title">
                <span>{i18n.t("userSetting.account.delete.title")}</span>
              </div>
              <img src={DeleteIcon} alt="account delete" />
              {i18n.language === "en" ? (
                <div className="delete-explan">
                  Delete the account.
                  <br />
                  <span>
                    The post ings comments and records
                    <br /> that you wrote will be deleted
                    <br />
                  </span>{" "}
                  and Deleted immediately and cannot be recovered.
                </div>
              ) : (
                <div className="delete-explan">
                  계정을 삭제합니다.
                  <br />
                  <span>작성한 게시물의 댓글과 기록은 삭제됩니다.</span>
                  <br />
                  즉시 삭제되며 계정은 복구 할 수 없습니다.
                </div>
              )}
            </div>
            <div className="deleteBtns_bottom">
              <button
                className="dialogCloseBtn"
                type="button"
                onClick={onClose}
              >
                {i18n.t("global.no")}
              </button>
              <button
                className="deleteBtn"
                type="button"
                onClick={this.handleUserDelete}
              >
                {i18n.t("global.yes")}
              </button>
            </div>
          </div>
        </BottomSheet>
      );
    }
  };
  isOwnerCheck = async () => {
    const teamList = await GetMyTeamList(1);

    const isOwner = teamList.findIndex((item) => item.authority === "admin");
    if (isOwner === -1) {
      return false;
    } else {
      return true;
    }
  };
  handleUserDelete = async () => {
    this.setState({ isLoading: true });
    try {
      if (this.isOwnerCheck()) {
        this.setState({ isLoading: false });
        this.handleAlert(i18n.t("userSetting.account.delete.alert"));
      } else {
        const response = await PostDeleteUser();
        if (response === "success") {
          this.setState({ isLoading: false }, () => {
            this.handleAlert(i18n.t("userSetting.account.delete.success"));
            this.props.history.push("/");
            window.location.reload();
          });
        }
      }
    } catch (e) {
      this.setState({ isLoading: false });
      // alert(i18n.t("global.error.server.msg"));
      this.handleAlert();
      console.dir(e);
    }
  };
  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
    );
    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Spin indicator={antIcon} spinning={this.state.isLoading} delay={500}>
            {this.renderDialogForm()}
          </Spin>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(DeleteDialog);
