import React, { Component, Fragment } from "react";
import "./Header.scss";
import logoImg from "../../image/logo.svg";
import mlogoImg from "../../Mobile/m_logo_icon.svg";
import notifyOnIcon from "../../image/top-on-notification-icon.svg";
import blackClose from "../../image/close-icon.svg";
import WhiteCloseIcon from "../../image/close-white-icon.svg";
import searchIcon from "../../image/search-white-icon.svg";
import { LoadingOutlined } from "@ant-design/icons";
import blackSearch from "../../image/black-search-icon.svg";
import i18n from "../../i18n";
import MsgIcon from "../../image/HeaderIcon/top-message-icon.svg";
import whtieMsgIcon from "../../image/loginIcon/top_white_message_icon.svg";
import noMsgIcon from "../../image/no_message_black_icon.svg";
import clockIcon from "../../image/clock.svg";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Tabs, Badge } from "antd";
import Media from "react-media";
import editIcon from "../../image/edit.svg";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import ProjectDialog from "../../Component/ProjectDialog/ProjectDialog";
import "moment/locale/ko";
import Moment from "react-moment";
import { withSnackbar } from "notistack";
import { Spin } from "antd";
import circleIcon from "../../image/complete-icon.svg";
import Cookies from "universal-cookie";
import SvgColor from "react-svg-color";
import groupIcon from "../../image/Group.svg";
import checkIcon from "../../image/black-check-icon.svg";
import {
  langChange,
  getMyInfo,
  changeLoginStatus,
  searchContent,
  projectEdit,
} from "../../Redux/action/index";
import { compose } from "redux";
import { ClickAwayListener } from "@material-ui/core";
import axios from "axios";
import { GetUserInfo } from "../../Api/User/user";
import { GetProjectInfo } from "../../Api/Project/project";
import { PostTeamAccept } from "../../Api/Team/Team";
import { GetUserToken, RemoveUserToken } from "../../modules/userAuth";
import * as firebaseChat from "../../firebase";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import { timeBefore } from "../functions";
import {
  DeleteNotifyAll,
  GetNotifyList,
  PatchNofifyReadAll,
  PatchNotifyAccept,
  PatchNotifyReject,
} from "../../Api/Notify/Notify";
import { renderNotifyMsg } from "../../modules/notify";
const { TabPane } = Tabs;

class Header extends Component {
  state = {
    searchOpen: false,
    projectDialogOpen: false,
    searchText: "",
    userProfileOpen: false,
    user_email: "",
    user_name: "",
    burgerMenuOpen: false,
    searchHistory: [],
    historyOpen: false,
    userLink: "",
    uploadBtn: true,
    mobile: false,
    mobileSearchBar: false,
    notifyList: [],
    isUnread: false,
    isNotifyOpen: false,
    isNotifyLoadDisable: false,
    projInfoRender: false,
    projectInfo: "",
    timer: "",
    pageHidden: false,
    chats: [],
    isMessageUnread: false,
    selectedRenderNotifyList: "all",
    selectNotifyList: [],
  };

  notifySkip = 20;
  timerSet = (timer) => {
    this.setState({ timer });
  };
  antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ff2e43" }} spin />
  );
  TabChangeCallback = async (key) => {
    if (key === "1") {
      this.handleSelectedNotifyRender("all");
    } else if (key === "2") {
      this.handleSelectedNotifyRender("request");
    }
  };
  componentWillUnmount = () => {
    document.removeEventListener("visibilitychange", this.documentHideenCheck);
  };
  componentDidMount = () => {
    document.addEventListener("visibilitychange", () =>
      this.documentHideenCheck(document.hidden)
    );
    if (this.props.location === "/project-upload") {
      this.setState({ uploadBtn: false });
    } else {
      this.setState({ uploadBtn: true });
    }

    const token = GetUserToken();

    if (token && this.props.socket) {
      this.props.socket.emit("authenticate", { token });
      this.props.socket.on("notify", (data) => {
        this.getNotify();
      });

      this.props.socket.on("read", (data) => {
        this.handleGetChatList();
      });
      this.getNotify();
      this.getChatList();
    }
  };

  getChatList = () => {
    const cookies = new Cookies();
    const firebaseToken = cookies.get("firebaseToken");
    try {
      if (firebaseToken) {
        firebaseChat.auth
          .signInWithCustomToken(firebaseToken)
          .then(async (credential) => {
            const chats = await firebaseChat.handleGetRoomList();
            firebaseChat.database
              .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
              .on("child_added", this.handleGetChatList);
            firebaseChat.database
              .ref("UserRooms/" + firebaseChat.auth.currentUser.uid)
              .on("child_changed", this.handleGetChatList);
            chats.map((item) => {
              firebaseChat.database
                .ref(
                  "UserRooms/" +
                    firebaseChat.auth.currentUser.uid +
                    "/" +
                    item.roomId
                )
                .on("child_added", this.handleGetChatList);
              firebaseChat.database
                .ref(
                  "UserRooms/" +
                    firebaseChat.auth.currentUser.uid +
                    "/" +
                    item.roomId
                )
                .on("child_changed", this.handleGetChatList);
            });
          })
          .catch(async (e) => {
            console.dir(e);
            const token = cookies.get("token");
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/v1/auth/firebase-refresh`,
              null,
              { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.data && response.data.data.firebaseToken) {
              cookies.remove("firebaseToken");
              cookies.set("firebaseToken", response.data.data.firebaseToken);
              this.getChatList();
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleGetChatList = async () => {
    const chats = await firebaseChat.handleGetRoomList();

    if (chats) {
      if (chats.find((item) => !item.isReaded)) {
        this.setState({ chats, isMessageUnread: "unread" });
      } else {
        this.setState({ chats, isMessageUnread: "" });
      }
    } else {
      this.setState({ chats: [] });
    }

    this.setState({ chats });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.myInfo !== this.props.myInfo) {
    }
  };
  documentHideenCheck = (pageHidden) => {
    this.setState({ pageHidden });
  };

  notification;
  notifySend = () => {
    if (Notification.permission === "granted") {
      let i = this.state.notifyList.length;

      let interval = window.setInterval(() => {
        i--;
        let nofifyCount = 0;
        if (i === nofifyCount) {
          window.clearInterval(interval);
        }

        if (!this.state.notifyList[i].isRead) {
          this.notification = new Notification(`MotionTrend`, {
            body: `${this.state.notifyList[i].message.ko}`,
            tag: `${this.state.notifyList[i].notifyType}_${this.state.notifyList[i]._id}`,
          });
        }
      }, 2000);
    }
  };

  getNotify = async () => {
    try {
      const response = await GetNotifyList(0, this.notifySkip);
      let notifyList = response.list;
      let isUnread = null;
      if (notifyList) {
        if (notifyList && notifyList.find((item) => item.isRead === false)) {
          isUnread = "unread";
        }
        notifyList = notifyList.map((item) => {
          return renderNotifyMsg(item);
        });
        const confirmNotifyList = notifyList.filter((item) => item.isConfirm);

        if (notifyList && notifyList.length >= response.count) {
          this.setState({
            selectNotifyList: confirmNotifyList,
            notifyList,
            isUnread,
            isNotifyLoadDisable: true,
          });
        } else {
          this.setState({
            notifyList,
            isUnread,
          });
        }
        this.handleNotifyRead();
      } else if (!response.count) {
        this.setState({ notifyList: [] });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleNotifyRead = async () => {
    if (this.state.isNotifyOpen) {
      try {
        await PatchNofifyReadAll();
      } catch (e) {
        console.log(e);
      }
    }
  };

  handleAlert = (text = "message blah blah", object) => {
    const valueObject = {
      variant: object && object.variant ? object.variant : "success",
      key: object && object.key ? object.key : "uppoer.accept",
      autoHideDuration:
        object && object.autoHideDuration ? object.autoHideDuration : 5000,
      preventDuplicate:
        object && typeof object.preventDuplicate === "boolean"
          ? object.preventDuplicate
          : true,
      anchorOrigin: {
        vertical: object && object.vertical ? object.vertical : "right",
        horizontal: object && object.horizontal ? object.horizontal : "bottom",
      },
    };
    this.props.enqueueSnackbar(text, valueObject);
  };
  handleUserPage = async (id) => {
    if (id) {
      try {
        const response = await GetUserInfo(id);
        this.setState({ userLink: response.linkURL }, () => {
          this.props.history.push(`/@${this.state.userLink}`);
        });
      } catch (e) {
        this.handleAlert(i18n.t("global.error.server.msg"));
        console.log(e);
      }
    }
  };
  loadProjectInfo = async (projectId) => {
    if (!this.state.settingMenuOpen) {
      try {
        const response = await GetProjectInfo(projectId);
        this.setState({ projectInfo: response }, () =>
          this.setState({ projInfoRender: true }, () =>
            this.setState({ projectDialogOpen: true })
          )
        );
      } catch (e) {
        this.handleAlert(i18n.t("global.error.server.msg"));
        console.log(e);
      }
    }
  };
  // handleSearchBarClose = (e) => {
  //   e.preventDefault();
  //   this.setState({ search });
  // };
  handleClickedNotify = (item) => {
    if (!item.isConfirm) {
      if (item.notifyType === "FOLLOW_NEW") {
        //this.handleUserPage(item.mainTargetId, item.mainUserLink);
        this.props.history.push(`/@${item.mainUserLink}`);
      } else if (
        item.notifyType === "PROJECT_NEW" ||
        item.notifyType === "PROJECT_LIKE" ||
        item.notifyType === "PROJECT_COMMENT"
      ) {
        this.loadProjectInfo(item.subTargetId);
      } else if (item.notifyType === "TEAM_JOINED") {
        this.props.history.push(`/@${item.mainUserLink}`);
      } else if (
        item.notifyType === "JOB_NEW" ||
        item.notifyType === "JOB_MODIFIED" ||
        item.notifyType === "JOB_MODIFIED" ||
        item.notifyType === "JOB_LEFT_ONEDAY"
      ) {
      }
    }
  };
  handleTeamAccept = async (teamId) => {
    try {
      await PostTeamAccept(teamId);
    } catch (e) {
      console.dir(e);
    }
  };
  handleClickedChat = (item, index) => {
    const chats = this.state.chats;

    if (!item.isReaded) {
      chats[index].isReaded = true;
      if (chats.find((item) => !item.isReaded)) {
        this.setState({ isMessageUnread: "unread" });
      } else {
        this.setState({ isMessageUnread: "" });
      }
      this.setState({ chats }, () => {
        this.props.history.push(`/chats?id=${item.roomId}`);
      });
    } else {
      this.props.history.push(`/chats?id=${item.roomId}`);
    }
  };
  isChatsJobOffer = (chats) => {
    for (let i = 0; i < chats.users.length; i++) {
      if (
        chats.users[i]._id === firebaseChat.auth.currentUser.uid &&
        chats.users[i].isJobOffer
      ) {
        // console.log(true);
        return true;
      }
    }
    return false;
  };
  renderMessege = () => {
    if (this.state.isMessageOpen) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ isMessageOpen: false })}
        >
          <div className="Message-container">
            <div className="notify-header">
              <span>{i18n.t("team.applicants.msg")}</span>
              <NavLink
                onClick={() => {
                  this.handleUserProfileClose();
                }}
                to={`/chats`}
              >
                <img src={editIcon} alt="message-send" />
              </NavLink>
            </div>
            <div className="notify-columns">
              {this.state.chats[0] && this.state.chats.length ? (
                this.state.chats.map((item, index) => {
                  const user = item.users.find(
                    (item) => item._id !== firebaseChat.auth.currentUser.uid
                  );
                  return (
                    <div
                      className="notify-column"
                      onClick={() => this.handleClickedChat(item, index)}
                    >
                      <div className="column-left">
                        <div className="column-img">
                          <ProfilePhoto size={36} src={user?.profilePhoto} />
                          <div
                            className={`unReadDot ${
                              item.isReaded ? null : "unread"
                            }`}
                          ></div>
                        </div>
                        <div className="notify-text">
                          <div className="message-userInfo">
                            <span style={{ fontWeight: "bold" }}>
                              '{`${user.userName}`}'
                            </span>
                            <span>{`${i18n.t("header.message.text")}`}</span>
                          </div>
                          <span className="message-content">
                            {item.lastMessage
                              ? item.isLastMessageImage
                                ? "Image file"
                                : item.lastMessage
                              : ""}
                          </span>
                        </div>
                        <div className="notify-date">
                          <span>{timeBefore(item.time)}</span>
                          {item.isJob &&
                            (this.isChatsJobOffer(item) ? (
                              <div className="message-job job-offer">
                                <img src={checkIcon} alt="" />
                                <span>{i18n.t("chats.job.collers")}</span>
                              </div>
                            ) : (
                              <div className="message-job">
                                <img src={groupIcon} alt="" />
                                <span>{i18n.t("chats.job.recipients")}</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="message-empty">
                  <img src={noMsgIcon} alt="" />
                  <span>{i18n.t("header.message.empty")}</span>
                  <span>{i18n.t("header.message.empty_2")}</span>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  };

  handleSelectedNotifyRender = (type) => {
    const notifyList = this.state.notifyList.filter((item) => item.isConfirm);
    this.setState({
      selectedRenderNotifyList: type,
      selectNotifyList: notifyList,
    });
  };
  handleSearchBarControl = (e) => {
    this.setState({ searchOpen: !this.state.searchOpen });
    e.preventDefault();
  };
  handleCollaboratorDecline = async (projectId, notifyId) => {
    try {
      const token = GetUserToken();
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/refuse`;
      await axios.patch(
        url,
        {
          id: projectId,
          notifyId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (e) {
      console.dir(e);
    }
  };
  handleCollaboratorAccept = async (projectId, notifyId) => {
    try {
      const token = GetUserToken();
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/project/accept`;
      await axios.patch(
        url,
        {
          id: projectId,
          notifyId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (e) {
      console.dir(e);
    }
  };

  handleNotifyAllDelete = async () => {
    try {
      this.setState({ notifyList: [] });
      await DeleteNotifyAll();
    } catch (e) {
      console.dir(e);
    }
  };
  handleButtonDeclineClick = async (notifyId, item, index) => {
    const notifyList = this.state.notifyList;
    try {
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 ${notifyList[index].subTargetName}팀 가입 요청을 거절하였습니다.`;
        notifyList[
          index
        ].message.en = `${notifyList[index].mainTargetName} team join request has been declined`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName} 팀 초대를 거절하였습니다`;
        notifyList[
          index
        ].message.en = `I've declined your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `I declined ${notifyList[index].mainTargetName} as a collaborator.`;
      }
      notifyList[index].isConfirm = false;
      this.setState({ notifyList });
      await PatchNotifyReject(notifyId);
    } catch (e) {
      console.dir(e);
    }
  };
  handleButtonAccept = async (notifyId, item, index) => {
    const notifyList = this.state.notifyList;
    try {
      item.isConfirm = false;
      await PatchNotifyAccept(notifyId);
      notifyList[index].isConfirm = false;
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 ${notifyList[index].subTargetName}팀 가입 요청을 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `${notifyList[index].mainTargetName} team join request has been accepted`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName} 팀 초대를 수락하였습니다`;
        notifyList[
          index
        ].message.en = `I've accepted your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `I accepted Lee Hyun-kyu's ${notifyList[index].mainTargetName} as a collaborator.`;
      }
      this.setState({ notifyList });
    } catch (e) {
      item.isConfirm = false;
      notifyList[index].isConfirm = false;
      if (notifyList[index].notifyType === "TEAM_APPLIED") {
        notifyList[
          index
        ].message.ko = `이미 다른 관리자가 수락하였거나 취소된 요청입니다.`;
        notifyList[
          index
        ].message.en = `Requests already accepted or canceled by another administrator.`;
      } else if (notifyList[index].notifyType === "TEAM_INVITE") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}팀 초대를 이미 수락하였습니다`;
        notifyList[
          index
        ].message.en = `I've accepted your invitation to the ${notifyList[index].mainTargetName} team.`;
      } else if (notifyList[index].notifyType === "PROJECT_COLLAB") {
        notifyList[
          index
        ].message.ko = `${notifyList[index].mainTargetName}님의 공동작업자 초대를 이미 수락하였습니다.`;
        notifyList[
          index
        ].message.en = `You have already accepted the invitation of collaborator ${notifyList[index].mainTargetName}`;
      }
    }
    const selectNotifyList = notifyList.filter((item) => item.isConfirm);
    this.setState({ notifyList, selectNotifyList });
  };
  renderNotifyList = () => {
    if (this.state.selectedRenderNotifyList === "all") {
      return this.state.notifyList[0] && this.state.notifyList.length ? (
        this.state.notifyList.map((item, index) => {
          return (
            <div
              className="notify-column"
              onClick={() => this.handleClickedNotify(item)}
              key={`notify-${item._id}`}
            >
              <Spin indicator={this.antIcon} spinning={item.isConfirm === -1}>
                <div className="column-box">
                  <div className="column-left">
                    <div className="column-img">
                      <ProfilePhoto size={36} src={item.mainTargetPhoto} />
                      <div
                        className={`unReadDot ${item.isRead ? null : "unread"}`}
                      ></div>
                    </div>

                    <div className="notify-text">
                      <>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === "en"
                                  ? item.message.en
                                  : item.message.ko,
                            }}
                          ></span>
                          <Moment
                            // style={{ color: "gray" }}
                            locale={this.props.lang}
                            fromNow
                          >
                            {item.createdAt}
                          </Moment>
                          {item.isConfirm ? (
                            <div className="notify-btns">
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleButtonDeclineClick(item._id, index)
                                }
                              >
                                {i18n.t("header.notify.btn.decline")}
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleButtonAccept(item._id, item, index)
                                }
                              >
                                {i18n.t("header.notify.btn.accept")}
                              </button>
                            </div>
                          ) : null}
                        </span>
                      </>
                    </div>
                    {item.subTargetPhoto && item.type === "project" && (
                      <div
                        className="notify-thumnail"
                        style={{
                          backgroundImage: `url(${item.subTargetPhoto})`,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </Spin>
            </div>
          );
        })
      ) : (
        <div className="notify-empty">
          <img src={circleIcon} alt="notify-empty" />
          <span>{i18n.t("header.notify.empty")}</span>
        </div>
      );
    } else {
      return this.state.selectNotifyList[0] &&
        this.state.selectNotifyList.length ? (
        this.state.selectNotifyList.map((item, index) => {
          return (
            <div
              className="notify-column"
              onClick={() => this.handleClickedNotify(item)}
            >
              <div className="column-box">
                <div className="column-left">
                  <div className="column-img">
                    <ProfilePhoto size={36} src={item?.mainTargetPhoto} />
                    <div
                      className={`unReadDot ${item.isRead ? null : "unread"}`}
                    ></div>
                  </div>
                  <div className="notify-text">
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            i18n.language === "en"
                              ? item.message.en
                              : item.message.ko,
                        }}
                      ></span>
                      {(item.isConfirm && item.type === "project") || "team" ? (
                        <div className="notify-btns">
                          <button
                            type="button"
                            onClick={() =>
                              this.handleButtonDeclineClick(item._id, index)
                            }
                          >
                            {i18n.t("header.notify.btn.decline")}
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              this.handleButtonAccept(item._id, item, index)
                            }
                          >
                            {i18n.t("header.notify.btn.accept")}
                          </button>
                        </div>
                      ) : null}
                    </span>
                  </div>
                </div>
                <span className="notify-date">
                  {this.renderDate(item.createdAt)}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="notify-empty">
          <img src={circleIcon} alt="notify-empty" />
          <span>{i18n.t("header.notify.empty")}</span>
        </div>
      );
    }
  };
  renderNotify = () => {
    if (this.state.isNotifyOpen) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ isNotifyOpen: false })}
        >
          <div className="notify-container">
            <div className="notify-navBar">
              <Tabs
                defaultActiveKey={this.state.selectedTab}
                onChange={this.TabChangeCallback}
                className="user-noti-tabs"
              >
                <TabPane
                  tab={<Badge dot={false}>{i18n.t("header.notify.all")}</Badge>}
                  key="1"
                />
                <TabPane
                  tab={
                    <Badge
                      dot={
                        this.state.selectNotifyList.length >= 1 ? true : false
                      }
                    >
                      {i18n.t("header.notify.request")}
                    </Badge>
                  }
                  key="2"
                />
              </Tabs>

              <button
                className="follow-sm"
                disabled={this.state.notifyList.length ? false : true}
                onClick={this.handleNotifyAllDelete}
              >
                {i18n.t("지우기")}
              </button>
            </div>
            <div className="notify-columns">{this.renderNotifyList()}</div>
            {!this.state.isNotifyLoadDisable ? (
              <div
                className={
                  this.state.isNotifyLoadDisable
                    ? "notify-more disable"
                    : "notify-more"
                }
                onClick={
                  this.state.isNotifyLoadDisable ? null : this.notifyLoadMore
                }
              >
                <span>{i18n.t("header.notify.more")}</span>
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      );
    }
  };

  notifyLoadMore = () => {
    this.notifySkip += 20;
    this.getNotify();
  };

  renderDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}.${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }.${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
  };

  handleMessageOpen = (e) => {
    e.preventDefault();
    this.setState(
      {
        isMessageOpen: !this.state.isMessageOpen,
        isNotifyOpen: false,
      }
      // () => this.props.history.push("/chats")
    );
  };

  handleNotifyOpen = (e) => {
    e.preventDefault();
    if (!this.state.isNotifyOpen) {
      const token = GetUserToken();
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notify/read-all`;
      axios.patch(url, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    if (this.state.isNotifyOpen) {
      this.setState({
        notifyList: this.state.notifyList.map((item) => {
          item.isRead = true;
          return item;
        }),
        isNotifyOpen: !this.state.isNotifyOpen,
        isMessageOpen: false,
        userProfileOpen: false,
        isUnread: false,
      });
    } else {
      this.setState({
        isNotifyOpen: !this.state.isNotifyOpen,
        isMessageOpen: false,
        userProfileOpen: false,
        isUnread: false,
      });
    }
  };

  lang = [
    {
      title: "English",
      id: "en",
    },
    {
      title: "한국어",
      id: "ko",
    },
  ];
  handleBurgerMenuOpen = () => {
    this.setState({
      burgerMenuOpen: true,
    });
  };

  handleBurgerMenuClose = () => {
    this.setState({
      burgerMenuOpen: false,
    });
  };

  handleSearchList = (e, mobile) => {
    const searchText = e.target.value.trim();
    const arr = [];
    if (e.key === "Enter" && e.target.value) {
      if (!localStorage.getItem("searchHistory")) {
        arr.push(searchText);
        localStorage.setItem("searchHistory", JSON.stringify(arr));
      } else {
        const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
        if (searchHistory.indexOf(searchText) === -1) {
          searchHistory.unshift(searchText);
          localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
        }
      }
      this.setState(
        { searchText, historyOpen: false, searchOpen: false },
        () => {
          if (mobile) {
            this.mobileDiscoverSearch();
          } else {
            this.toDiscoverSearch();
          }
        }
      );
    } else if (e.key === "Enter") {
      this.props.history.push("/discover");
    }
  };
  handleRemoveSearchHistory = (index) => {
    const searchHistory = this.state.searchHistory;
    searchHistory.splice(index, 1);
    this.setState({ searchHistory }, () => {
      localStorage.setItem(
        "searchHistory",
        JSON.stringify(this.state.searchHistory)
      );
    });
  };
  mobileDiscoverSearch = () => {
    let input = document.getElementById("header-searchBar");
    this.props.searchContent(this.state.searchText);
    input.value = this.state.searchText;
    this.props.history.push(`/discover?search=${this.state.searchText}`);
  };
  toDiscoverSearch = () => {
    let input = document.getElementById("header-searchBar");
    if (this.props.location !== "/discover") {
      this.props.searchContent(this.state.searchText);
      input.value = this.state.searchText;
      this.toDiscover();
    } else {
      this.props.searchContent(this.state.searchText);
      input.value = this.state.searchText;
    }
  };
  handleSearchClear = () => {
    const search = document.getElementById("header-searchBar");
    search.value = "";
  };
  isLogOut = () => {
    this.props.history.push("/");

    this.props.getMyInfo(null);
    this.props.changeLoginStatus(false);
    RemoveUserToken();
    this.props.enqueueSnackbar(i18n.t("로그아웃 되었습니다."), {
      variant: "success",
      key: "success-logout",
      autoHideDuration: 5000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });

    // window.location.reload();
  };

  toSignUp = () => {
    this.props.history.push("/sign-up");
  };
  toProjectUpload = () => {
    this.props.projectEdit({});
    this.props.history.push("/project-upload");
  };
  toLogin = () => {
    this.props.history.push("/login");
  };
  handleUserProfileOpen = () => {
    this.setState({ userProfileOpen: true });
  };
  handleUserProfileClose = () => {
    this.setState({ userProfileOpen: false });
  };
  toMain = () => {
    const dialog = document.getElementById("dialog_container");

    if (dialog) {
      this.props.history.push("/");
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };
  toDiscover = () => {
    const dialog = document.getElementById("dialog_container");

    if (dialog) {
      this.props.history.push("/discover");
      window.location.reload();
    } else {
      this.props.history.push("/discover");
    }
  };
  toTutorial = () => {
    const dialog = document.getElementById("dialog_container");

    if (dialog) {
      this.props.history.push("/tutorial");
      window.location.reload();
    } else {
      this.props.history.push("/tutorial");
    }
  };

  toJob = () => {
    const dialog = document.getElementById("dialog_container");

    if (dialog) {
      this.props.history.push("/job");
      window.location.reload();
    } else {
      this.props.history.push("/job");
    }
  };
  toMypage = async () => {
    if (!this.props.myInfo) {
      this.toLogin();
    } else {
      if (this.props.myInfo.linkURL && this.props.myInfo.linkURL.length) {
        this.setState({ userProfileOpen: false }, () => {
          this.props.history.push(`/@${this.props.myInfo.linkURL}`);
        });
      }
      try {
        const response = await GetUserInfo(this.props.myInfo._id);
        this.setState(
          { userLink: response.linkURL, userProfileOpen: false },
          () => {
            this.props.history.push(`/@${this.props.myInfo.linkURL}`);
          }
        );
      } catch (e) {
        // alert(i18n.t("global.error.server.msg"));
        this.handleAlert(i18n.t("global.error.server.msg"));
        console.log(e);
      }
    }
  };
  handleHistoryClose = () => {
    this.setState({ historyOpen: false });
  };
  handleHistoryClick = (e) => {
    this.setState(
      { searchText: e, historyOpen: false, searchOpen: false },
      () => this.toDiscoverSearch()
    );
  };

  handleSearchHitory = () => {
    if (!localStorage.getItem("searchHistory")) {
      return;
    } else {
      const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      this.setState({
        searchHistory,
        historyOpen: true,
      });
    }
  };

  handleProjectDialogClose = () => {
    this.setState({ projectDialogOpen: false });
  };

  render() {
    let url = window.location.href;
    return (
      <>
        <Media
          queries={{
            tablet: "(max-width : 1024px)",
            desktop: "(min-width: 1025px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.tablet && (
                <BurgerMenu
                  userData={this.state}
                  isOpen={true}
                  isLogOut={this.isLogOut}
                  uploadProject={this.toProjectUpload}
                  toMypage={this.toMypage}
                >
                  {" "}
                </BurgerMenu>
              )}
              {this.state.projInfoRender ? (
                <ProjectDialog
                  timerSet={this.timerSet}
                  userId={this.props.myInfo._id}
                  open={this.state.projectDialogOpen}
                  onClose={this.handleProjectDialogClose}
                  projInfo={this.state.projectInfo}
                  history={this.props.history}
                ></ProjectDialog>
              ) : null}
              <div className="container">
                <nav className="navContainer" id="nav_header-container">
                  <div className="nav_wrapper" onClick={this.handleClick}>
                    <div className="menuBoxLt">
                      {matches.desktop && (
                        <div className="navLogo">
                          <img src={logoImg} alt="logo" onClick={this.toMain} />
                          {/* main logo */}
                        </div>
                      )}
                      {matches.tablet && (
                        <Fragment>
                          {this.props.history.location.pathname !==
                          "/discover" ? (
                            <div className="navLogo">
                              <img
                                src={mlogoImg}
                                style={{
                                  marginLeft: "30px",
                                  maxWidth: "34px",
                                }}
                                alt="logo"
                                onClick={this.toMain}
                              />
                            </div>
                          ) : null}
                        </Fragment>
                      )}

                      {matches.desktop && (
                        <Fragment>
                          {url && url.includes("/admin") ? null : (
                            <div className="menu">
                              <label
                                htmlFor="reference"
                                className={
                                  this.props.location === "/"
                                    ? "activeMenu"
                                    : null
                                }
                                onClick={this.toMain}
                              >
                                {i18n.t("header.discover")}
                              </label>
                              <label
                                htmlFor="Portfolio"
                                // onClick={this.toDiscover}
                                // className={
                                //   this.props.location === "/Portfolio"
                                //     ? "activeMenu"
                                //     : null
                                // }
                              >
                                {/* {i18n.t("header.discover")} */}
                                Portfolio
                              </label>
                              <label
                                htmlFor="job"
                                className={
                                  this.props.location === "/job"
                                    ? "activeMenu"
                                    : null
                                }
                                onClick={this.toJob}
                              >
                                {i18n.t("header.job")}
                              </label>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>

                    {matches.desktop && (
                      <Fragment>
                        <div className="menuBoxRt">
                          {url && url.includes("/admin") ? null : this.props
                              .isLogined ? (
                            <button
                              className={"activeBtn"}
                              style={
                                !this.state.uploadBtn
                                  ? { display: "none" }
                                  : null
                              }
                              disabled={this.state.uploadBtn ? false : true}
                              type="button"
                              onClick={
                                this.state.uploadBtn
                                  ? this.toProjectUpload
                                  : null
                              }
                            >
                              {i18n.t("header.upload")}
                            </button>
                          ) : null}

                          {this.props.isLogined ? (
                            <div className="loginedList row">
                              {this.props.location !== "/discover" ? (
                                <div
                                  className="search_bar-open"
                                  onClick={this.handleSearchBarControl}
                                >
                                  {this.state.searchOpen ? (
                                    <img
                                      src={WhiteCloseIcon}
                                      alt="search-close"
                                      className="searchIcon"
                                    />
                                  ) : (
                                    <img
                                      className="searchIcon"
                                      src={searchIcon}
                                      alt="search"
                                    />
                                  )}
                                </div>
                              ) : null}
                              <div className="notify_btns">
                                <div
                                  className="msg_btn"
                                  onClick={this.handleMessageOpen}
                                >
                                  <div className="msg_icon">
                                    <img
                                      className="msgIcon"
                                      src={MsgIcon}
                                      alt="message"
                                      style={{
                                        visibility: "hidden",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={`unReadDot ${this.state.isMessageUnread}`}
                                  ></div>
                                </div>
                                {this.renderMessege()}
                                <div
                                  className="notify_btn"
                                  onClick={this.handleNotifyOpen}
                                >
                                  <div className="notify-icon">
                                    <img
                                      src={notifyOnIcon}
                                      alt="iconsize"
                                      style={{ visibility: "hidden" }}
                                    ></img>
                                  </div>
                                  <div
                                    className={`unReadDot ${this.state.isUnread}`}
                                  ></div>
                                </div>
                                {this.renderNotify()}
                              </div>
                              <div className="profileIcon">
                                {this.props.myInfo && (
                                  <ProfilePhoto
                                    size={36}
                                    click={this.handleUserProfileOpen}
                                    cursor="pointer"
                                    src={this.props.myInfo?.profilePhoto}
                                  />
                                )}

                                {this.state.userProfileOpen ? (
                                  <ClickAwayListener
                                    onClickAway={this.handleUserProfileClose}
                                  >
                                    <div className="userProfile-container boxshadow">
                                      <div
                                        onClick={this.toMypage}
                                        className="userProfile-info"
                                      >
                                        <ProfilePhoto
                                          size={36}
                                          src={this.props.myInfo.profilePhoto}
                                        />
                                        <div className="userInfoList">
                                          <div className="user-nickName">
                                            <span>
                                              {this.props.myInfo.userName}
                                            </span>
                                          </div>
                                          <div className="user-email">
                                            <span>
                                              {this.props.myInfo.email}
                                            </span>
                                          </div>
                                          <div className="user-myPage">
                                            <span>
                                              {i18n.t("global.navto.profile")}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="actionList">
                                        <NavLink
                                          onClick={this.handleUserProfileClose}
                                          to={`/setting`}
                                        >
                                          <span>
                                            {i18n.t("header.settings")}
                                          </span>
                                        </NavLink>

                                        <NavLink
                                          onClick={this.handleUserProfileClose}
                                          to={`/team/list`}
                                        >
                                          <span>{i18n.t("header.team")}</span>
                                        </NavLink>
                                        <NavLink
                                          onClick={this.handleUserProfileClose}
                                          to={`/job-management`}
                                        >
                                          <span>
                                            {i18n.t("header.job.management")}
                                          </span>
                                        </NavLink>

                                        {this.props.myInfo.authority ===
                                        "admin" ? (
                                          <NavLink
                                            onClick={
                                              this.handleUserProfileClose
                                            }
                                            to={`/admin`}
                                          >
                                            <span>
                                              {i18n.t("global.admin")}
                                            </span>
                                          </NavLink>
                                        ) : null}

                                        {/* <span>Help</span> */}
                                      </div>
                                      <div
                                        onClick={this.handleUserProfileClose}
                                        className="sign-out"
                                      >
                                        <span onClick={this.isLogOut}>
                                          {i18n.t("global.logout")}
                                        </span>
                                      </div>
                                    </div>
                                  </ClickAwayListener>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              {this.props.location !== "/discover" ? (
                                <div
                                  className="search_bar-open"
                                  onClick={this.handleSearchBarControl}
                                >
                                  {this.state.searchOpen ? (
                                    <img
                                      src={WhiteCloseIcon}
                                      alt="search-close"
                                      className="searchIcon"
                                    />
                                  ) : (
                                    <img
                                      className="searchIcon"
                                      src={searchIcon}
                                      alt="search"
                                    />
                                  )}
                                </div>
                              ) : null}
                              <span
                                style={{ marginRight: "10px" }}
                                onClick={this.toLogin}
                                className={
                                  this.props.location === "/login"
                                    ? "activeMenu"
                                    : null
                                }
                              >
                                {i18n.t("header.login")}
                              </span>
                              <span
                                onClick={this.toSignUp}
                                className={
                                  this.props.location === "/sign-up"
                                    ? "activeMenu"
                                    : null
                                }
                              >
                                {i18n.t("header.signup")}
                              </span>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    )}
                    {matches.tablet ? (
                      <Fragment>
                        <div className="menuBoxRt">
                          {this.props.isLogined && (
                            <div className="notify_btns m_btns">
                              <div
                                className="msg_btn"
                                onClick={() =>
                                  this.props.history.push("/message")
                                }
                              >
                                <div className="msg_icon">
                                  <img
                                    className="msgIcon"
                                    src={whtieMsgIcon}
                                    alt="message"
                                  />
                                </div>
                                <div
                                  className={`unReadDot ${this.state.isMessageUnread}`}
                                ></div>
                              </div>

                              <div
                                className="notify_btn"
                                onClick={(e) => {
                                  this.props.history.push("/notification");
                                }}
                              >
                                <div className="notify-icon">
                                  <img src={notifyOnIcon} alt="iconsize"></img>
                                </div>
                                <div
                                  className={`unReadDot ${this.state.isUnread}`}
                                ></div>
                              </div>
                            </div>
                          )}

                          <div
                            className="search_bar-open"
                            onClick={this.handleSearchBarControl}
                          >
                            {this.state.searchOpen ? (
                              <img
                                src={WhiteCloseIcon}
                                className="searchIcon"
                                alt="search-close"
                              />
                            ) : (
                              <img
                                className="searchIcon"
                                src={searchIcon}
                                alt="search"
                              />
                            )}
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                    {url && url.includes("/admin") ? null : (
                      <ClickAwayListener
                        onClickAway={(e) => {
                          e.stopPropagation();
                          if (e.target.className !== "searchIcon") {
                            this.setState({ searchOpen: false });
                          }
                        }}
                      >
                        <div
                          className={`search-contaner ${
                            this.state.searchOpen ? "open" : "close"
                          }`}
                        >
                          <div className="search_bar-wrapper">
                            <div className="search-bar">
                              <img
                                className="search-icon"
                                src={blackSearch}
                                alt="searchIcon"
                              />
                              <input
                                placeholder={i18n.t("search.placeholder")}
                                type="text"
                                id="header-searchBar"
                                onKeyPress={(e) =>
                                  this.handleSearchList(e, false)
                                }
                                onClick={this.handleSearchHitory}
                                autoComplete="off"
                              />
                              {this.state.historyOpen ? (
                                <ClickAwayListener
                                  onClickAway={this.handleHistoryClose}
                                >
                                  <div className="search-history">
                                    {this.state.searchHistory.map(
                                      (item, key) => {
                                        return (
                                          <React.Fragment>
                                            <div className="search-history-row">
                                              <div className="search-history-Lbox">
                                                <SvgColor
                                                  svg={clockIcon}
                                                  // className="removeIcon"
                                                  width={12}
                                                  // colors={["#A6AAAF", "#A6AAAF"]}
                                                />
                                                <span
                                                  key={`historyList_${item}-${key}`}
                                                  onClick={() =>
                                                    this.handleHistoryClick(
                                                      item
                                                    )
                                                  }
                                                  // className="nowrap"
                                                >
                                                  {item}
                                                </span>
                                              </div>
                                              {/* <div className="searchDeleteBox"></div> */}
                                              {/* <img
                                                // onClick={this.handleSearchClear}
                                                className="removeIcon"
                                                src={blackClose}
                                                alt="closeIcon"
                                              /> */}
                                              <div
                                                onClick={() =>
                                                  this.handleRemoveSearchHistory(
                                                    key
                                                  )
                                                }
                                              >
                                                <SvgColor
                                                  svg={blackClose}
                                                  className="removeIcon"
                                                  width={10}
                                                  colors={[
                                                    "#A6AAAF",
                                                    "#A6AAAF",
                                                  ]}
                                                />
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </ClickAwayListener>
                              ) : null}
                              {/* <img
                                onClick={this.handleSearchClear}
                                className="removeIcon"
                                src={blackClose}
                                alt="closeIcon"
                              /> */}
                            </div>
                          </div>
                          <div
                            className="backgroud"
                            onClick={() => this.setState({ searchOpen: false })}
                          ></div>
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                </nav>
              </div>
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myInfo: state.myInfo.myInfo,
  isLogined: state.loginStatus.isLogined,
  lang: state.language.lang,
  searchTitle: state.searchKeyword.searchTitle,
  socket: state.socketStatus.socket,
});

const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (value) => dispatch(getMyInfo(value)),
  searchContent: (value) => dispatch(searchContent(value)),
  langChange: (value) => dispatch(langChange(value)),
  changeLoginStatus: (value) => dispatch(changeLoginStatus(value)),
  projectEdit: (value) => dispatch(projectEdit(value)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(Header);
