import { SketchPicker } from "react-color";
import React, { Component } from "react";
import "./MobileColorPicker.scss";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import { BottomSheet } from "react-spring-bottom-sheet";

import Media from "react-media";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};

class MobileColorPicker extends Component {
  state = {
    color: "#000000",
  };
  handleColorSet = (e) => {
    e.stopPropagation();
    this.props.handleColorSet(this.state.color);
  };
  handleChangeComplete = (color, e) => {
    e.stopPropagation();
    this.setState({ color: color.hex });
  };

  render() {
    const { onClose, open } = this.props;

    return (
      <>
        <Media
          queries={{
            mobile: "(max-width: 799px)",
            tablet: "(min-width: 800px)",
          }}
        >
          {(matches) => (
            <BottomSheet open={open} onDismiss={onClose}>
              <div className="one-button-dialog-container colorDialog">
                <div className="colorDialog-box">
                  <div className="picked-color">
                    <div className="colorCode">
                      <span>{this.state.color}</span>

                      <div
                        className="colorBox"
                        style={{ backgroundColor: `${this.state.color}` }}
                      ></div>
                    </div>
                    <button
                      className="black-border-radius-button"
                      onClick={this.handleColorSet}
                    >
                      {i18n.t("global.change")}
                    </button>
                  </div>
                  <div className="colorDialog-palette">
                    <SketchPicker
                      onClick={(e) => console.log("asdasda")}
                      color={this.state.color}
                      onChange={(color, e) =>
                        this.handleChangeComplete(color, e)
                      }
                      id="color-picker"
                    />
                  </div>
                </div>
              </div>
              {/* </Dialog> */}
            </BottomSheet>
          )}
        </Media>
      </>
    );
  }
}

export default withStyles(styles)(MobileColorPicker);
