import React, { Component, Fragment } from "react";
import "./FollowDialog.scss";
import { Dialog } from "@material-ui/core";
import Media from "react-media";
import closeImg from "../../../image/close-icon.svg";

import { Space, Row, Tabs } from "antd";
import FadeLoader from "react-spinners/BeatLoader";
import SvgColor from "react-svg-color";
import viewImg from "../../../image/view-icon.svg";
import likeImg from "../../../image/like-icon.svg";
import { nFormatter } from "../../../Component/functions";
import i18n from "../../../i18n";
import { css } from "@emotion/core";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
import { SizeMe, withSize } from "react-sizeme";
import { compose } from "redux";
const { TabPane } = Tabs;

class FollowDialog extends Component {
  state = {
    FollowingList: [],
    FollowerList: [],
    skip: 0,
    limit: 30,
    userInfo: {},
    hasMore: true,
    selectedTab: "1",
  };

  componentDidMount = async () => {
    this.setState({ userInfo: this.props.userInfo }, () =>
      console.log(this.props)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.FollowingList !== this.props.FollowingList &&
      this.props.FollowingList.length >= 1
    ) {
      let FollowingList = [
        ...this.state.FollowingList.concat(this.props.FollowingList),
      ];

      this.setState(
        {
          FollowingList,
        },
        () => console.log(this.state.FollowingList)
      );
    }
    if (
      (prevProps.FollowerList !== this.props.FollowerList) &
      (this.props.FollowerList.length >= 1)
    ) {
      let list = [...this.state.FollowerList];
      let addList = list.concat(this.props.FollowerList);

      this.setState(
        {
          FollowerList: addList,
        },
        () => console.log(this.state.FollowerList)
      );
    }
    if (prevProps.userInfo !== this.props.userInfo) {
      this.setState({ userInfo: this.props.userInfo });
    }
  }

  callback = async (key) => {
    console.log("탭 변경", key, this.state.selectedTab);
    const skip = 0;
    const limit = 30;
    // if (key !== this.state.selectedTab) {
    this.setState(
      { FollowerList: [], FollowingList: [], selectedTab: key },
      () => this.props.handleFollowLists(this.state.selectedTab, skip, limit)
    );
    // }
  };

  loadMoreFollowerData = async () => {
    console.log("load MOre");
    if (
      this.state.FollowerList.length + 1 >= this.state.userInfo.follower ||
      this.state.FollowerList.length < 10
    ) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({ skip: this.state.FollowerList.length }, () =>
      this.props.handleFollowLists("1", this.state.skip, this.state.limit)
    );
  };

  loadMoreFollowingData = async () => {
    if (
      this.state.FollowingList.length + 1 >= this.state.userInfo.following ||
      this.state.FollowingList.length < 10
    ) {
      this.setState({ hasMore: false });
      return;
    }

    this.setState({ skip: this.state.FollowingList.length }, () =>
      this.props.handleFollowLists("2", this.state.skip, this.state.limit)
    );
  };

  handleGoToUserPage = (linkUrl) => {
    this.props.history.push(`/@${linkUrl}`);
    window.location.reload();
  };
  handleGoToLoginPage = () => {
    this.props.history.push("/login");
    // window.location.reload();
  };

  handleFollowUser = async (type, id, isFollowing, index) => {
    this.props.handleFollowUser(id, isFollowing);

    let items =
      type === "1"
        ? [...this.state.FollowerList]
        : [...this.state.FollowingList];
    let item = { ...items[index] };
    item.isFollowing = !item.isFollowing;
    items[index] = item;

    this.setState(
      type === "1" ? { FollowerList: items } : { FollowingList: items }
    );
  };

  render() {
    const { onClose, open } = this.props;
    const override = css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
    `;
    return (
      <Fragment>
        <Media
          queries={{
            mobile: "(max-width: 599px)",
            tablet: "(min-width: 800px)",
          }}
        >
          {(matches) => (
            <Dialog
              className={"mypageFollowDialog"}
              maxWidth={"sm"}
              open={open}
              onClose={onClose}
            >
              <div className={"closeBtn"} onClick={onClose}>
                <img
                  style={{ width: "15px", height: "15px" }}
                  src={closeImg}
                  alt="close"
                />
              </div>

              <Tabs
                defaultActiveKey={this.props.FollowOrFollowing}
                onChange={this.callback}
              >
                <TabPane
                  tab={`${i18n.t("mypage.profile.follower")} ${nFormatter(
                    this.state.userInfo.follower
                  )}`}
                  key="1"
                >
                  <SizeMe monitorHeight>
                    {({ size }) => (
                      <div
                        id="scrollableDiv"
                        style={{
                          height:
                            size.width >= 497 ? "400px" : "calc(100vh - 48px)",
                          overflowY: "auto",
                        }}
                      >
                        <InfiniteScroll
                          dataLength={10}
                          next={this.loadMoreFollowerData}
                          hasMore={true}
                          loader={
                            <FadeLoader
                              loading={this.props.FollowLoading}
                              css={override}
                              size={6}
                            ></FadeLoader>
                          }
                          // height={"100%"}
                          scrollableTarget="scrollableDiv"
                        >
                          {this.state.FollowerList.length > 0
                            ? this.state.FollowerList.map((item, index) => {
                                if (Object.entries(item).length > 1) {
                                  return (
                                    <Row
                                      gutter={[16, 16]}
                                      key={index}
                                      justify="space-between"
                                      align="middle"
                                    >
                                      <div>
                                        <Space>
                                          <div className="profile-photo-follow">
                                            <ProfilePhoto
                                              size={40}
                                              src={item.profilePhoto}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "150px",
                                              }}
                                            >
                                              <div
                                                onClick={() =>
                                                  this.handleGoToUserPage(
                                                    item.linkURL
                                                  )
                                                }
                                                className="userName"
                                                style={{
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {item.userName}
                                              </div>
                                              <div className="field">
                                                @{item.linkURL}
                                              </div>
                                            </div>
                                            {!matches.mobile ? (
                                              <Space>
                                                {/* <div className="country bottom-descripton-div">
                                            <SvgColor
                                              svg={LocationIcon}
                                              width={12}
                                              colors={["#fff", "#070707"]}
                                            />
                                            {item.countryInfo.countryName}
                                          </div> */}
                                                <div className="appreciation bottom-descripton-div">
                                                  <SvgColor
                                                    svg={viewImg}
                                                    width={12}
                                                  />
                                                  {nFormatter(item.views)}
                                                </div>
                                                <div className="totalView  bottom-descripton-div">
                                                  <SvgColor
                                                    svg={likeImg}
                                                    width={12}
                                                    height={12}
                                                  />
                                                  {nFormatter(item.likeMembers)}
                                                </div>
                                              </Space>
                                            ) : null}
                                          </div>
                                        </Space>
                                      </div>
                                      <div>
                                        {item._id ===
                                        this.props.myInfo._id ? null : (
                                          <div
                                            className="followBtn-Wrap"
                                            style={{
                                              display: "flex",
                                              marginLeft: "10px",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <button
                                              className={
                                                item.isFollowing
                                                  ? "following-md"
                                                  : "follow-md"
                                              }
                                              onClick={
                                                this.props.isLogined
                                                  ? () =>
                                                      this.handleFollowUser(
                                                        "1",
                                                        item._id,
                                                        item.isFollowing,
                                                        index
                                                      )
                                                  : this.handleGoToLoginPage
                                              }
                                            >
                                              {item.isFollowing
                                                ? i18n.t(
                                                    "mypage.profile.unfollow"
                                                  )
                                                : i18n.t(
                                                    "mypage.profile.follow"
                                                  )}
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </Row>
                                  );
                                }
                              })
                            : null}
                        </InfiniteScroll>
                      </div>
                    )}
                  </SizeMe>
                </TabPane>
                {window.location.href.includes("/team/") ? null : (
                  <TabPane
                    tab={`${i18n.t("mypage.profile.following")} ${nFormatter(
                      this.state.userInfo.following
                    )}`}
                    key="2"
                  >
                    <SizeMe monitorHeight>
                      {({ size }) => (
                        <div
                          id="scrollableDiv"
                          style={{
                            height:
                              size.width >= 497
                                ? "400px"
                                : "calc(100vh - 48px)",
                            overflowY: "auto",
                          }}
                        >
                          <InfiniteScroll
                            dataLength={this.state.FollowingList.length}
                            next={this.loadMoreFollowingData}
                            hasMore={true}
                            scrollableTarget="scrollableDiv"
                            loader={
                              <FadeLoader
                                //   color={color}
                                loading={this.props.FollowLoading}
                                css={override}
                                size={6}
                              ></FadeLoader>
                            }
                            // height={300}
                            endMessage={
                              <p style={{ textAlign: "center" }}>
                                <b>{i18n.t("mypage.followlists")}</b>
                              </p>
                            }
                          >
                            {this.state.FollowingList.length > 0
                              ? this.state.FollowingList.map((item, index) => {
                                  if (Object.entries(item).length > 1) {
                                    return (
                                      <Row
                                        key={index}
                                        justify="space-between"
                                        align="middle"
                                      >
                                        <div>
                                          <Space>
                                            <div className="profile-photo-follow">
                                              <ProfilePhoto
                                                src={
                                                  item.profilePhoto ||
                                                  item.teamPhoto
                                                }
                                                size={40}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  maxWidth: "150px",
                                                }}
                                              >
                                                <div
                                                  onClick={() =>
                                                    this.handleGoToUserPage(
                                                      item.linkURL
                                                    )
                                                  }
                                                  className="userName"
                                                  style={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {item.userName ||
                                                    item.teamName}
                                                </div>
                                                <div className="field">
                                                  @{item.linkURL}
                                                </div>
                                              </div>
                                              {!matches.mobile ? (
                                                <Space>
                                                  <div className="appreciation bottom-descripton-div">
                                                    <SvgColor
                                                      svg={viewImg}
                                                      width={12}
                                                    />
                                                    {nFormatter(item.views)}
                                                  </div>
                                                  <div className="totalView  bottom-descripton-div">
                                                    <SvgColor
                                                      svg={likeImg}
                                                      width={12}
                                                      height={12}
                                                    />
                                                    {nFormatter(
                                                      item.likeMembers
                                                    )}
                                                  </div>
                                                </Space>
                                              ) : null}
                                            </div>
                                          </Space>
                                        </div>
                                        <div>
                                          {item._id ===
                                          this.props.myInfo._id ? null : (
                                            <div
                                              className="followBtn-Wrap"
                                              style={{
                                                display: "flex",
                                                marginLeft: "10px",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <button
                                                className={
                                                  item.isFollowing
                                                    ? "following-md"
                                                    : "follow-md"
                                                }
                                                onClick={
                                                  this.props.isLogined
                                                    ? () =>
                                                        this.handleFollowUser(
                                                          "2",
                                                          item._id,
                                                          item.isFollowing,
                                                          index
                                                        )
                                                    : this.handleGoToLoginPage
                                                }
                                              >
                                                {item.isFollowing &&
                                                this.props.isLogined
                                                  ? i18n.t(
                                                      "mypage.profile.unfollow"
                                                    )
                                                  : i18n.t(
                                                      "mypage.profile.follow"
                                                    )}
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </Row>
                                    );
                                  }
                                })
                              : null}
                          </InfiniteScroll>
                        </div>
                      )}
                    </SizeMe>
                  </TabPane>
                )}
              </Tabs>
              {/* {this.props.FollowLoading ? (
                <FadeLoader
                  //   color={color}
                  loading={true}
                  css={override}
                  size={10}
                ></FadeLoader>
              ) : null} */}
            </Dialog>
          )}
        </Media>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogined: state.loginStatus.isLogined,
  myInfo: state.myInfo.myInfo,
});
// export default connect(mapStateToProps)(FollowDialog);

export default compose(
  withSize({ monitorHeight: true }),
  connect(mapStateToProps)
)(FollowDialog);
// export default connect(mapStateToProps)(FollowDialog);
