import React, { Component } from "react";
import "./ReportDeleteDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../../../i18n";
import { PostDelteContent } from "../../../../../Api/Admin/Report/report";

const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
class ReportDeleteDialog extends Component {
  state = {
    reportedReason: "",
  };
  handleReasonChange = (e) => {
    this.setState({ reportedReason: e.target.value });
  };
  handleDelteReportedContent = async () => {
    try {
      await PostDelteContent(
        this.props.reportedId,
        this.state.reportedReason,
        true
      );
    } catch (e) {
      console.dir(e);
    }
  };
  render() {
    const { onClose, open } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={""}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            <div className="Report-detail-dialog-container">
              <div className="Report-detail-dialog-title">
                <span>Confirm Delete Post</span>
              </div>
              <div className="Report-detail-dialog-body">
                <div className="report-content-title">
                  <span>WARNING!</span>
                  <span>
                    Are you sure you want to delete reported projects or
                    comments? It cannot be recovered after it has been deleted.
                    If you want to delete it, please write the reason for the
                    deletion below.
                  </span>
                </div>
                <div className="report-content-textarea">
                  <span>Direct input</span>
                  <textarea
                    id="reported-reason"
                    wrap="hard"
                    cols="20"
                    onChange={this.handleReasonChange}
                  />
                </div>
                <div className="report-content-caution">
                  <span>
                    * The reason for deletion is sent through notification to
                    the author of the post. The status is also automatically
                    treated as Resolved.
                  </span>
                </div>
              </div>
              <div className="report-btns">
                <button
                  className="report-button-dialog-button"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  className="report-button-dialog-button"
                  onClick={() =>
                    this.props.handleDelteReportedContent(
                      this.props.reportedId,
                      this.state.reportedReason
                    )
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}
export default withStyles(styles)(ReportDeleteDialog);
