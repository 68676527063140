import React, { Component } from "react";
import { compose } from "redux";
import "./AddMember.scss";
import i18n from "../../../i18n";
import CheckBox from "../../../image/check-off-box-icon.svg";
import CheckedBox from "../../../image/check-on-box-icon.svg";
import RemoveIcon from "../../../image/close-icon.svg";
import { ClickAwayListener } from "@material-ui/core";
import { CheckOutlined } from "@ant-design/icons";
import { GetUserList } from "../../../Api/User/user";
import { withSnackbar } from "notistack";
import ProfilePhoto from "../../../Component/ProfilePhoto/ProfilePhoto";
import { TeamCreate_GAEvent } from "../../../modules/gaEvents";
class AddMember extends Component {
  state = {
    searchUser: "",
    // memberAuth: [
    //   {
    //     authName: i18n.t("team.create.addMember.auth.member"),
    //     auth: "member",
    //   },

    //   {
    //     authName: i18n.t("team.create.addMember.auth.manager"),
    //     auth: "owner",
    //   },
    // ],
    userListOpen: false,
    userList: [],
    checkedAuth: "member",
    selectedUser: [],
    members: [],
  };
  handleClickedAuth = (auth, index) => {
    let members = [...this.state.members];
    members[index].authority = auth;

    this.setState({ members }, () => this.props.setMembers(this.state.members));
  };

  hanldeSelecUser = (user, index) => {
    let selectedUser = [...this.state.selectedUser];
    let members = [...this.state.members];

    const DuplicateFind1 = selectedUser.find(
      (element) => element._id === user._id
    );
    const DuplicateFind2 = members.find((element) => element._id === user._id);

    if (DuplicateFind1 || DuplicateFind2) {
      this.props.enqueueSnackbar(i18n.t("이미 추가된 계정입니다."), {
        variant: "warning",
        key: "previous-added",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      this.handleuserListClose();
      return;
    } else {
      let userList = [...this.state.userList];
      userList[index].isSelected = true;
      this.setState({ userList: userList });
    }

    let input = document.getElementById("create-team-memberAdd");
    selectedUser.push(user);

    this.setState({ selectedUser, searchUser: "" }, () => {
      input.value = "";
    });
  };

  checkDuplicates = (id) => {
    let selectedUser = [...this.state.selectedUser];
    let members = [...this.state.members];
    const DuplicateFind1 = selectedUser.find((element) => element._id === id);
    const DuplicateFind2 = members.find((element) => element._id === id);

    if (DuplicateFind1 || DuplicateFind2) {
      return true;
    } else {
      return false;
    }
  };

  handleUserListLoad = async () => {
    try {
      const response = await GetUserList(this.state.searchUser);
      let userList = await response;
      let selectedUser = [...this.state.selectedUser];
      let members = [...this.state.members];

      await userList.forEach(function (element) {
        const DuplicateFind1 = selectedUser.find(
          (item) => item._id === element._id
        );
        const DuplicateFind2 = members.find((item) => item._id === element._id);

        if (DuplicateFind1 || DuplicateFind2) {
          element.isSelected = true;
        } else {
          element.isSelected = false;
        }
      });

      this.setState({ userList });
    } catch (e) {
      console.dir(e);
    }
  };
  componentDidMount = () => {
    TeamCreate_GAEvent("invite");
  };
  handleInviteMember = (data) => {
    const member = JSON.parse(JSON.stringify(data));
    let input = document.getElementById("create-team-memberAdd");
    for (let i = 0; i < member.length; i++) {
      member[i].authority = "member";
    }
    let members = [...this.state.members];
    if (member) {
      // console.log(members.concat(member));
      members = members.concat(member);
      this.setState(
        {
          members,
        },
        () => {
          input.value = "";
          this.setState({ selectedUser: [] }, () =>
            this.props.setMembers(this.state.members)
          );
        }
      );
    }
  };
  handleUserListOpen = () => {
    this.setState({ userListOpen: true });
  };
  handleuserListClose = () => {
    if (this.state.isInputFocused) {
      return;
    }
    this.setState({ userListOpen: false });
  };
  handleUserListSearch = (e) => {
    this.setState({ searchUser: e.target.value }, () => {
      if (e.target.value) {
        this.handleUserListLoad();
      }
    });
  };
  handleRemoveUser = (id) => {
    let members = [...this.state.members];
    members = members.filter((item) => item._id !== id);

    let userList = [...this.state.userList];
    userList[userList.findIndex((item) => item._id === id)].isSelected = false;
    this.setState({ members, userList: userList }, () =>
      this.props.handleMemberRemove(this.state.members)
    );
  };

  handleInputUsersRemove = (id, index) => {
    let selectedUser = [...this.state.selectedUser];
    selectedUser = selectedUser.filter((item) => item._id !== id);

    let userList = [...this.state.userList];
    userList[userList.findIndex((item) => item._id === id)].isSelected = false;
    this.setState({ userList: userList });

    this.setState({ selectedUser: selectedUser }, () =>
      this.props.handleMemberRemove(this.state.selectedUser)
    );
  };

  // handleRemoveUser = (id) => {
  //   console.log(id);
  //   this.props.handleMemberRemove(id);
  // };
  renderMemberlist = (members) => {
    if (members && members.length !== 0 && members[0]) {
      return (
        <div className="members_list-container">
          {/* <div className="headers-list">
            <div className="headers-column">
              <span>{i18n.t("indivi.signup.userName")}</span>
            </div>
            <div className="headers-column">
              <span>{i18n.t("team.create.addMember.authority")}</span>
            </div>
            <div className="headers-column">
              <span>{i18n.t("global.cancel")}</span>
            </div>
          </div> */}
          <div className="body-list">
            {members.map((item, index) => {
              return (
                <div className="body-items">
                  <div className="item-userInfo">
                    <ProfilePhoto src={item.profilePhoto} size={40} />
                    <span>{item.userName}</span>
                  </div>
                  <div className="item-userAuth">
                    {/* <span>{item.authority}</span> */}
                    <div className="auth-list" style={{ display: "flex" }}>
                      <div
                        className="auth-checkBox"
                        onClick={() => this.handleClickedAuth("member", index)}
                      >
                        <img
                          src={
                            item.authority === "member" ? CheckedBox : CheckBox
                          }
                          alt="checkBtn"
                        />
                        <span>
                          {i18n.t("team.create.addMember.auth.member")}
                        </span>
                      </div>
                      <div
                        className="auth-checkBox"
                        onClick={() => this.handleClickedAuth("admin", index)}
                      >
                        <img
                          src={
                            item.authority === "owner" ? CheckedBox : CheckBox
                          }
                          alt="checkBtn"
                        />
                        <span>
                          {i18n.t("team.create.addMember.auth.manager")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item-userRemove"
                    onClick={() => this.handleRemoveUser(item._id)}
                  >
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={RemoveIcon}
                      alt="invite-member-remove"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="members_nullBox">
          {/* <div className="null-box">
            <img src={NullMembers} alt="no-members" />
            <span>No members have been added.</span>
          </div> */}
        </div>
      );
    }
  };
  renderUserList = () => {
    return (
      <ClickAwayListener onClickAway={this.handleuserListClose}>
        {this.state.userList[0] ? (
          <div className="userList-container">
            {this.state.userList.map((item, index) => {
              return (
                <div
                  className="userList-box"
                  onClick={() => this.hanldeSelecUser(item, index)}
                >
                  <div style={{ display: "flex" }}>
                    <ProfilePhoto src={item.profilePhoto} size={40} />
                    <div className="userList-userInfo">
                      <span>{item.userName}</span>
                      <span>{item.email}</span>
                    </div>
                  </div>
                  <span>
                    {item.isSelected ? (
                      <>
                        <CheckOutlined width="10px" height="10px" /> {"추가됨"}
                      </>
                    ) : null}
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="userList-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            {this.state.searchUser ? (
              <div className="no-data">
                <span>검색 결과가 없습니다. 😢</span>
              </div>
            ) : (
              <div className="no-data">
                <span>검색어를 입력해 주세요 🔍</span>
              </div>
            )}
          </div>
        )}
      </ClickAwayListener>
    );
  };
  render() {
    return (
      <div className="container addInfo-container">
        <div className="addInfo-box">
          <div className="membersFind-input addInfo-columnBox">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span>{i18n.t("team.create.addMember.title")}</span>
            </div>
            <div className="membersFind-inputField">
              {this.state.selectedUser[0]
                ? this.state.selectedUser.map((user, index) => {
                    return (
                      <div className="selecUserBox">
                        <span>{user.userName}</span>
                        <img
                          onClick={() =>
                            this.handleInputUsersRemove(user._id, index)
                          }
                          src={RemoveIcon}
                          alt="selected-user-remove"
                        />
                      </div>
                    );
                  })
                : null}
              <input
                autocomplete="off"
                id="create-team-memberAdd"
                type="text"
                placeholder="유저명 또는 이메일을 검색해 주세요"
                onChange={this.handleUserListSearch}
                onClick={this.handleUserListOpen}
                onFocus={() =>
                  this.setState(
                    { isInputFocused: true },
                    () => this.handleUserListLoad
                  )
                }
                onBlur={() =>
                  this.setState(
                    { isInputFocused: false },
                    () => this.handleUserListLoad
                  )
                }
                style={{ marginTop: "5px" }}
              />
            </div>

            <div className="userSearchResultContainer">
              {this.state.userListOpen ? this.renderUserList() : null}
            </div>
            {this.state.userList ? (
              <>
                {/* <span>{i18n.t("team.create.addMember.auth")}</span> */}

                <div className="members-auth">
                  <div className="members_invite-btns">
                    <button
                      // disabled={true}
                      type="button"
                      style={{
                        marginTop: "10px",
                        borderRadius: "30px",
                        width: "60px",
                        height: "30px",
                      }}
                      onClick={() =>
                        this.handleInviteMember(this.state.selectedUser)
                      }
                    >
                      {i18n.t("global.add")}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {this.state.members &&
          this.state.members.length !== 0 &&
          this.state.members[0] ? (
            <div className="membersFind-auth addInfo-columnBox">
              <div className="members_selected-list">
                {/* <span>New member to invite</span> */}
                {this.renderMemberlist(this.state.members)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(withSnackbar)(AddMember);
