import React, { Component } from "react";
import "./CurationDialog.scss";
import { Dialog } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../../i18n";
import { Avatar, Space, Tooltip, Popconfirm } from "antd";

import WhiteCloseIcon from "../../../../image/close-white-icon.svg";

import Moment from "react-moment";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import {
  GetFlagList,
  PostCurate,
  PostWithDrawalFlag,
} from "../../../../Api/Admin/Curation/curaiton";
import { GetUserToken } from "../../../../modules/userAuth";
const styles = {
  maxWidthXs: {
    maxWidth: "520px",
  },
};
class ReportDialog extends Component {
  state = {
    contentsVisible: false,
    curatedFlags: [],
    availableFlags: [],
    curationDatas: [],
  };

  getFlagsList = async () => {
    // const selectedCateName = this.props.curationInfo.category.name.en;
    const curationInfo = { ...this.props.curationInfo };
    this.setState({ contentsVisible: false });
    try {
      const response = await GetFlagList();

      if (response) {
        const flagListAll = response;

        if (curationInfo.curationDatas) {
          const curatedFlagsIds = [
            ...this.props.curationInfo.curationDatas.map(
              (item) => item.flag // 큐레이션된 플래그 ID 만 추출
            ),
          ];
          // const defaultFlag = ["608fac06bc195f203a7070b6"]; // 모션 트렌드픽 ID

          // const filter = curatedFlagsIds.concat(defaultFlag);
          const availableFlags = flagListAll.filter(
            (item) => !curatedFlagsIds.includes(item._id)
          );
          const curatedFlags = flagListAll.filter((item) =>
            curatedFlagsIds.includes(item._id)
          );
          this.setState({ curatedFlags, availableFlags }, () =>
            this.setState({ contentsVisible: true })
          );
        } else {
          this.setState({ availableFlags: flagListAll }, () =>
            this.setState({ contentsVisible: true })
          );
        }
      }
    } catch (e) {
      console.dir(e);
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "warning",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const curationInfo = { ...this.props.curationInfo };
    this.getFlagsList();
    if (curationInfo) {
      this.setState({ isLoading: true }, () =>
        this.setState({ curationDatas: curationInfo.curationDatas }, () =>
          this.setState({ isLoading: false })
        )
      );
    }
  };

  handleCancelCuration = async (index, name, flagId) => {
    const projectId = await this.props.curationInfo._id;

    let newCuratedFlags = [...this.state.curatedFlags]; // 선정된 리스트 갱신
    newCuratedFlags.splice(index, 1);

    let newAvailableFlags = [...this.state.availableFlags]; // 선정 가능 리스트 갱신
    newAvailableFlags.push(this.state.curatedFlags[index]);

    const form = {};

    form.projectId = projectId;
    form.flagIds = [flagId];
    form.message = "000 때문에 선정 철회됨";

    const token = GetUserToken();

    if (!token) {
      this.props.history.push("/admin/curation");
      return;
    }

    try {
      const response = await PostWithDrawalFlag(
        form.projectId,
        form.flagIds,
        form.message
      );

      if (response) {
        this.setState(
          {
            curatedFlags: newCuratedFlags,
            availableFlags: newAvailableFlags,
          },
          () =>
            this.props.enqueueSnackbar(
              `${name} 갤러리 선정이 철회 되었습니다.`,
              {
                variant: "success",
                key: "curation-withdraw",
                autoHideDuration: 5000,
                preventDuplicate: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }
            )
          // this.props.onClose
        );
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.dir(e);
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      // this.props.onClose
    }
  };

  handleAddCuration = async (index, name, flagId) => {
    const projectId = await this.props.curationInfo._id;

    let newAvailableFlags = [...this.state.availableFlags]; // 선정된 리스트 갱신
    newAvailableFlags.splice(index, 1);

    let newCuratedFlags = [...this.state.curatedFlags]; // 선정 가능 리스트 갱신
    newCuratedFlags.push(this.state.availableFlags[index]);

    const form = {};

    form.projectId = projectId;
    form.flagIds = [flagId];

    const token = GetUserToken();

    if (!token) {
      this.props.history.push("/admin/curation");
      return;
    }
    try {
      const response = await PostCurate(form.projectId, form.flagIds);

      if (response) {
        this.setState(
          {
            curatedFlags: newCuratedFlags,
            availableFlags: newAvailableFlags,
          },
          () =>
            this.props.enqueueSnackbar(`${name} 갤러리에 선정 되었습니다.`, {
              variant: "success",
              key: "curation-curated",
              autoHideDuration: 5000,
              preventDuplicate: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            })
          // this.props.onClose
        );
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.dir(e);
      this.props.enqueueSnackbar(i18n.t("global.error.server.msg"), {
        variant: "error",
        key: "global.error.server.msg",
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  render() {
    const { onClose, open, curationInfo, selectedMenu } = this.props;

    const theme = createMuiTheme({
      overrides: {
        // Style sheet name ⚛️
        MuiDialog: {
          // Name of the rule
          // // your override styles here. for example, the following overrides dialog's "root" class
          // paperWidthSm: {
          // },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        },
      },
    });

    return (
      <>
        <ThemeProvider theme={theme}>
          <Dialog
            fullWidth={this.props.fullWidth}
            className={"curation-detail-dialog-container"}
            maxWidth={false}
            open={open}
            onClose={onClose}
          >
            {this.state.contentsVisible && !this.state.isLoading ? (
              <div className="Report-detail-dialog-container">
                <div className="Report-detail-dialog-title">
                  <span>큐레이션</span>
                </div>

                <div className="Report-detail-dialog-body">
                  <div className="reporter-userInfo-box">
                    <span>선정된 갤러리</span>

                    {/* 뱃지 리스트 */}
                    {this.state.curatedFlags.length >= 1 ? (
                      <div
                        className="flagImgsContainer"
                        style={{ display: "flex" }}
                      >
                        {this.state.curatedFlags.map((item, index) => {
                          return (
                            <div
                              key={`curation_${item._id}`}
                              className="flagImgBox"
                              style={{
                                position: "relative",
                                display: "flex",
                                maxWidht: "40px",
                                marginRight: "7px",
                                marginTop: "20px",
                              }}
                            >
                              <Tooltip
                                overlayClassName="curationTooltipContainer"
                                placement="bottomRight"
                                color="white"
                                title={
                                  <>
                                    {selectedMenu === 2 &&
                                    this.state.curationDatas[index] ? (
                                      <>
                                        {this.state.curationDatas[index]
                                          .curatorInfo.profilePhoto ? (
                                          <>
                                            <div className="flagTooltipFeature">
                                              큐레이터
                                            </div>
                                            <Space>
                                              <Avatar
                                                style={{
                                                  backgroundColor: "#87d068",
                                                }}
                                                src={
                                                  curationInfo.curationDatas[
                                                    index
                                                  ].curatorInfo.profilePhoto
                                                }
                                              />
                                              <div style={{ color: "black" }}>
                                                {
                                                  curationInfo.curationDatas[
                                                    index
                                                  ].curatorInfo.userName
                                                }
                                              </div>
                                            </Space>
                                          </>
                                        ) : (
                                          <>
                                            <div className="flagTooltipFeature">
                                              큐레이터
                                            </div>
                                            <Space>
                                              <Avatar
                                                style={{
                                                  backgroundColor: "#f56a00",
                                                }}
                                              >
                                                {curationInfo.curationDatas[
                                                  index
                                                ].curatorInfo.userName
                                                  .charAt(0)
                                                  .toUpperCase()}
                                              </Avatar>
                                              <div style={{ color: "black" }}>
                                                {
                                                  curationInfo.curationDatas[
                                                    index
                                                  ].curatorInfo.userName
                                                }
                                              </div>
                                            </Space>
                                          </>
                                        )}{" "}
                                      </>
                                    ) : null}

                                    <div className="flagTooltipFeature">
                                      {i18n.t("선정 분야")}
                                    </div>
                                    <div className="flagTooltipFeatureContent">
                                      <div className="flagTooltipTitle">
                                        {item.name}
                                      </div>
                                      <span>{" - "} </span>
                                      <Moment
                                        className="flagTooltipDate"
                                        locale={this.props.lang}
                                        format="YYYY/MM/DD"
                                      >
                                        {item.createdAt}
                                      </Moment>
                                    </div>
                                  </>
                                }
                                // color="white"
                              >
                                <img
                                  src={item.flagImg}
                                  style={{ width: "40px" }}
                                  alt={item.name + "img"}
                                ></img>
                              </Tooltip>
                              <Popconfirm
                                placement="top"
                                title={
                                  "정말로 선정을 취소 하시겠습니까 ? 즉시 적용됩니다."
                                }
                                onConfirm={() =>
                                  this.handleCancelCuration(
                                    index,
                                    item.name,
                                    item._id
                                  )
                                }
                                okText="선정 취소"
                                cancelText="아니오"
                                style={{ zIndex: 3200 }}
                                className="curationPopConfirm"
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-5px",
                                    top: "-10px",
                                  }}
                                  // onClick={() =>

                                  // }
                                >
                                  <img
                                    src={WhiteCloseIcon}
                                    alt="close"
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      padding: "2px",
                                      borderRadius: "50%",
                                      backgroundColor: "black",
                                      border: "unset",
                                      opacity: "0.7",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Popconfirm>
                              {/* <div className="closeBtn" onClick={onClose}>
                              <img src={closeIcon} alt="close" />
                            </div> */}
                            </div>
                          );
                        })}{" "}
                      </div>
                    ) : (
                      <div style={{ marginTop: "20px" }}>
                        🎖 선정된 뱃지가 없습니다.
                      </div>
                    )}
                  </div>
                  <div className="reporter-userInfo-box">
                    <span>선정 가능 분야</span>

                    {/* 뱃지 리스트 */}
                    {this.state.availableFlags.length >= 1 ? (
                      <div
                        className="flagImgsContainer"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {this.state.availableFlags.map((item, index) => {
                          return (
                            <div
                              key={`availableCuration_${item._id}`}
                              className="flagImgBox"
                              style={{
                                position: "relative",
                                display: "flex",
                                maxWidht: "40px",
                                marginRight: "7px",
                                marginTop: "20px",
                              }}
                            >
                              <Tooltip
                                overlayClassName="curationTooltipContainer"
                                placement="bottomRight"
                                color="white"
                                title={
                                  <>
                                    <div className="flagTooltipFeature">
                                      {i18n.t("선정 분야")}
                                    </div>
                                    <div className="flagTooltipFeatureContent">
                                      <div className="flagTooltipTitle">
                                        {item.name}
                                      </div>
                                      {/* <span>{" - "} </span>
                                      <Moment
                                        className="flagTooltipDate"
                                        locale={this.props.lang}
                                        format="YYYY/MM/DD"
                                      >
                                        {item.createdAt}
                                      </Moment> */}
                                    </div>
                                  </>
                                }
                                // color="white"
                              >
                                <img
                                  src={item.flagImg}
                                  style={{ width: "40px" }}
                                  alt={item.name + "img"}
                                ></img>
                              </Tooltip>
                              <Popconfirm
                                placement="top"
                                title={
                                  "정말로 작품을 선정 하시겠습니까 ? 즉시 적용됩니다."
                                }
                                onConfirm={() =>
                                  this.handleAddCuration(
                                    index,
                                    item.name,
                                    item._id
                                  )
                                }
                                okText="작품 선정"
                                cancelText="아니오"
                                style={{ zIndex: 3200 }}
                                className="curationPopConfirm"
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-5px",
                                    top: "-10px",
                                  }}
                                  // onClick={() =>

                                  // }
                                >
                                  <img
                                    src={WhiteCloseIcon}
                                    alt="close"
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      padding: "2px",
                                      borderRadius: "50%",
                                      backgroundColor: "black",
                                      border: "unset",
                                      opacity: "0.7",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Popconfirm>
                              {/* <div className="closeBtn" onClick={onClose}>
                              <img src={closeIcon} alt="close" />
                            </div> */}
                            </div>
                          );
                        })}{" "}
                      </div>
                    ) : (
                      <div style={{ marginTop: "20px" }}>
                        🌟 선정 가능한 뱃지가 없습니다.
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="report-btns"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <button
                    className="report-button-dialog-button"
                    onClick={onClose}
                  >
                    취소
                  </button> */}
                  <button
                    className="report-button-dialog-button"
                    onClick={onClose}
                  >
                    닫기
                  </button>
                </div>
              </div>
            ) : null}
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}
export default compose(withStyles(styles), withSnackbar)(ReportDialog);
