import React, { Component } from "react";
import RouterWrapper from "./Router/Router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { createBrowserHistory } from "history";
import i18n from "./i18n";
import { SnackbarProvider } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import { fire } from "./firebase";

require("dotenv").config();

const styles = {
  success: { backgroundColor: "#148f45", fontSize: 14 },
  error: { backgroundColor: "#ff2e43", fontSize: 14 },
  // warning: { backgroundColor: "#de8537" },
  info: {
    backgroundColor: "#070707",
    fontSize: 14,
  },
  base: {
    fontSize: 14,
  },
};
class App extends Component {
  constructor() {
    super();
    fire();
  }

  componentDidMount = async () => {
    Notification.requestPermission();
  };

  history = createBrowserHistory({ forceRefresh: true });

  render() {
    const { classes } = this.props;
    return (
      <Router>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider
            maxSnack={3}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              // variantWarning: classes.warning,
              variantInfo: classes.info,
              base: classes.base,
            }}
          >
            <Route path="/" component={RouterWrapper} />
            {/* <RouterWrapper history={this.history} /> */}
          </SnackbarProvider>
        </I18nextProvider>
      </Router>
    );
  }
}

export default withStyles(styles)(App);
